import React, { useState, useEffect } from 'react'
import { StyledTopNav } from './styles'
import {
  ChevronRIcon,
  HistoryIcon,
  InboxIcon,
  PhoneIcon
} from '../../views/PortfolioManagement/PortfolioDetails/svgs'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button } from 'antd'
import NotInterestedModal from '../../views/LeadManagement/NotInterested'
import SourceAlternateCar from '../../views/LeadManagement/SourceAlternateCar'
import LogActivity from '../../views/LeadManagement/LogActivity'
import { useTranslation } from 'react-i18next'
import api from '../../utils/api'
import { useSelector } from 'react-redux'
import { getUserToken } from '../../store/auth/selectors'
import { useQuery } from 'react-query'

interface IDetailParam {
  id: string
}

export default function TopNavigation() {
  const token = useSelector(getUserToken)

  let history = useHistory()
  const [notInterested, setNotInterested] = useState(false)
  const [visibleAlternateCar, setVisibleAlternateCar] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const { id } = useParams<IDetailParam>()
  const { t } = useTranslation()

  const showAlternateCarModal = () => {
    setVisibleAlternateCar(true)
  }

  const showNotInterested = () => {
    setNotInterested(true)
  }

  const showLogActivity = () => {
    setIsModalVisible(true)
  }

  const {
    data: singleLoan,
    isLoading: singleLoanLoading,
    refetch: loanRefresh
  } = useQuery(
    ['singleLoans', id],
    () => api.fetchSingleOriginationLoan(token, id),
    {
      keepPreviousData: true,
      staleTime: 5000
    }
  )

  //https://loans.staging.myautochek.com/dashboard/loans/car-details/WiIz05xeLx
  let linkToConsole =
    process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
      ? 'https://loans.staging.myautochek.com/dashboard/loans/car-details/'
      : 'https://loans.autochek.africa/dashboard/loans/car-details/'

  return (
    <StyledTopNav>
      <div className="navbarWrapper" id="navbarWrapper">
        <div className="firstColumn">
          <div className="title" onClick={() => history.goBack()}>
            {t('Loan leads')} {ChevronRIcon}
          </div>
          <div className="id">
            {t('loanID')}: <span>{id}</span>
          </div>
          <a href={`${linkToConsole}${id}`} target="_blank">
            {t('viewCreditManager')}:
          </a>
        </div>
        <div className="secondColumn">
          <div className="buttonGroup">
            <Button className="notInterested" onClick={showNotInterested}>
              {t('notInterested')}
            </Button>
            <Button className="carSold" onClick={showAlternateCarModal}>
              {t('carSold')}
            </Button>
          </div>
          <div className="actionButtonGroup">
            <Button size="large" className="inbox">
              <div className="btnWrap">
                <div className="btnImage">{InboxIcon}</div>
                <div>{t('inbox')}:</div>
              </div>
            </Button>
            <Button size="large" className="log" onClick={showLogActivity}>
              <div className="btnWrap">
                <div className="btnImage">{PhoneIcon}</div>
                <div>{t('logActivity')}:</div>
              </div>
            </Button>
            <Button size="large" className="email">
              <div className="btnWrap">
                <div className="btnImage">{InboxIcon}</div>
                <div>{t('sendEmail')}:</div>
              </div>
            </Button>
            <Button size="large" className="history">
              <div className="btnWrap">
                <div className="btnImage">{HistoryIcon}</div>
                <div>{t('history')}:</div>
              </div>
            </Button>
          </div>
        </div>
      </div>
      <NotInterestedModal
        notInterested={notInterested}
        setNotInterested={setNotInterested}
      />
      <SourceAlternateCar
        visibleAlternateCar={visibleAlternateCar}
        setVisibleAlternateCar={setVisibleAlternateCar}
        singleLoan={singleLoan}
        loanRefresh={loanRefresh}
        title={t('carSold')}
      />
      <LogActivity
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </StyledTopNav>
  )
}
