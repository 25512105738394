import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import WithSideMenu from '../../../../components/WithSideMenu'
import { SettingsContainer } from '../../styles'
import { ContentWrap, SettingsNavDummy, StyledWhatsappAgent } from '../styles'
import SettingsMenu from '../../SettingsMenu'
import useStyles from '../newUserStyle'
import { useSelector, useDispatch } from 'react-redux'
import { showSnackbar } from '../../../../store/snackbar/actions'
import {
  getUserToken,
  getSelectedCountry
} from '../../../../store/auth/selectors'
import api from '../../../../utils/api'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  FormContainer,
  FormWrapper,
  ServiceContainer,
  StyledAddAgent
} from './styles'
import ChevronLeftIcon from '../../../Loans/LoanDetails/svg/ChevronLeft'
import { Button, Form, Input, Select, Spin, Col, Row } from 'antd'

export default function CreateAgent() {
  const { t } = useTranslation()

  const serviceArray = [
    {
      name: t('Loan'),
      active: false,
      serviceKey: 'FINANCING'
    },
    {
      name: t('buyCar'),
      active: false,
      serviceKey: 'BUY_CAR'
    },
    {
      name: t('testDriveCar'),
      active: false,
      serviceKey: 'TEST_DRIVE_CAR'
    },
    {
      name: t('fixCar'),
      active: false,
      serviceKey: 'FIX_CAR'
    },

    {
      name: t('sellCar'),
      active: false,
      serviceKey: 'SELL_CAR'
    },

    {
      name: t('buyTruck'),
      active: false,
      serviceKey: 'BUY_TRUCK'
    },
    {
      name: t('testDriveTruck'),
      active: false,
      serviceKey: 'TEST_DRIVE_TRUCK'
    },
    {
      name: t('fixTruck'),
      active: false,
      serviceKey: 'FIX_TRUCK'
    },
    {
      name: t('sellTruck'),
      active: false,
      serviceKey: 'SELL_TRUCK'
    },
    {
      name: t('afterSales'),
      active: false,
      serviceKey: 'AFTER_SALES'
    }
  ]

  const { Option } = Select
  const classes = useStyles()
  const token = useSelector(getUserToken)
  const country = useSelector(getSelectedCountry)
  let history = useHistory()
  const dispatch = useDispatch()
  const [serviceList, setServiceList] = useState<Array<Object>>([])
  const [checked, setChecked] = useState(false)
  const [loading, setLoading] = useState(false)

  const validateMessages = {
    types: {
      email: '${label} is not a valid email address',
      number: '${label} is not a valid number'
    }
  }

  const handleServices = (e: any, id: any) => {
    if (serviceList.includes(id)) {
      const filteredService = serviceList.filter(
        (selectedId) => selectedId !== id
      )
      setServiceList(filteredService)
    } else {
      setServiceList([...serviceList, id])
    }
  }

  const handleAllServices = (e: any) => {
    const serviceId = serviceArray.map((el) => el.serviceKey)
    if (checked == false) {
      setServiceList(serviceId)
      setChecked(true)
    } else if (checked == true) {
      setServiceList([])
      setChecked(false)
    }
  }

  const onFinish = async (values: any) => {
    setLoading(true)
    // if (serviceList.length === 0) {
    //   dispatch(showSnackbar('Please choose a service', 'error'))
    //   return
    // }

    const payload = {
      email: values.email,
      phone: values.phone
      // service_areas: serviceList
    }

    try {
      const response = await api.createAgent(token, payload)
      if (response) {
        dispatch(showSnackbar('Agent created successfully', 'success'))
        history.push('/settings/agents')
        setLoading(false)
      }
    } catch (err) {
      dispatch(showSnackbar('Error adding agent user', 'error'))
    }
  }

  return (
    <WithSideMenu>
      <SettingsContainer>
        <SettingsNavDummy />
        <SettingsMenu />
        <ContentWrap>
          <StyledAddAgent>
            <Link
              to="/settings/agents/"
              style={{ marginLeft: '-7px' }}
              className={`${classes.headerSection} ${classes.backBtn}`}
            >
              <ChevronLeftIcon />

              {t('agent')}
            </Link>
            <div style={{ margin: '1rem 0rem' }}>
              <span style={{ fontWeight: 'bold', fontSize: '22px' }}>
                {t('createAgent')}
              </span>
            </div>

            <FormContainer>
              <FormWrapper>
                <div className="title-form">
                  {t('fillDetailsBelowCreateNewUser')}.
                </div>
                <header>{t('agentDetails')}</header>

                <Form
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                  validateMessages={validateMessages}
                >
                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item
                        label={t('email')}
                        name="email"
                        rules={[
                          {
                            required: true,
                            message: t('pleaseEnterEmailAddress')
                          },
                          { type: 'email' }
                        ]}
                      >
                        <Input type="email" />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={t('phoneNumber')}
                        name="phone"
                        rules={[
                          {
                            required: true,
                            message: t('pleaseEnterPhoneNumber')
                          },
                          {
                            min: 9,
                            max: 16,
                            message: t('phoneNumberShouldBeBetween9To16Characters')
                          }
                        ]}
                      >
                        <Input type="number" maxLength={16} />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row gutter={12}>
                    <Col span={12}>
                      <Form.Item name="country" label={t('country')}>
                        <Select defaultValue={country} disabled>
                          <Option value="GH">Ghana</Option>
                          <Option value="UG">Uganda</Option>
                          <Option value="KE">Kenya</Option>
                          <Option value="CI">Ivory Coast</Option>
                          <Option value="NG">Nigeria</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  {/* <ServiceContainer>
                    <div className="title-wrapper">
                      <div className="service-title">Services</div>
                      <div className="select-services">Select Services</div>
                    </div>

                    <div className="service-check-container">
                      {serviceArray.map((el, index) => (
                        <div className="service-check" key={index}>
                          <input
                            type="checkbox"
                            onChange={(e) => handleServices(e, el.serviceKey)}
                            checked={serviceList.includes(el.serviceKey)}
                          />
                          <div>
                            <span>{el.name}</span>
                          </div>
                        </div>
                      ))}
                    </div>

                    <div className="mt-2 mb-5">
                      <Checkbox
                        onChange={handleAllServices}
                        checked={checked && !(serviceList.length < 10)}
                      >
                        Select all
                      </Checkbox>
                    </div>
                  </ServiceContainer> */}

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {loading ? <Spin /> : t('save')}
                    </Button>
                  </Form.Item>
                </Form>
              </FormWrapper>
            </FormContainer>
          </StyledAddAgent>
        </ContentWrap>
      </SettingsContainer>
    </WithSideMenu>
  )
}
