import React from 'react'
import { AutochekThemeOptions } from '../../../theme/types'

type Props = {
  theme: AutochekThemeOptions
  active: boolean
}

const AnalyticsIcon = ({ active, theme }: Props) => {
  const color = active
    ? theme.palette.neutral.kournikova
    : theme.palette.common.white
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M0.5625 17.4375H16.875"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.0625 14.0625H4.12575V14.0625C4.33286 14.0625 4.50075 14.2304 4.50075 14.4375V17.4375H1.6875V14.4375V14.4375C1.6875 14.2304 1.85539 14.0625 2.0625 14.0625V14.0625Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.6875 7.3125H9.75075V7.3125C9.95786 7.3125 10.1257 7.48039 10.1257 7.6875V17.4375H7.3125V7.6875V7.6875C7.3125 7.48039 7.48039 7.3125 7.6875 7.3125V7.3125Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.3118 10.6875H15.375V10.6875C15.5821 10.6875 15.75 10.8554 15.75 11.0625V17.4375H12.9375V11.0625V11.0625C12.9375 10.8557 13.1049 10.6879 13.3118 10.6875V10.6875Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.15984 3.83984L2.15234 6.84734"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.733 4.73975L7.23047 3.80225"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.2944 2.16748L12.6094 4.26748"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.1875 4.50098C6.80882 4.50098 7.3125 3.9973 7.3125 3.37598C7.3125 2.75466 6.80882 2.25098 6.1875 2.25098C5.56618 2.25098 5.0625 2.75466 5.0625 3.37598C5.0625 3.9973 5.56618 4.50098 6.1875 4.50098Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3125 2.8125C16.9338 2.8125 17.4375 2.30882 17.4375 1.6875C17.4375 1.06618 16.9338 0.5625 16.3125 0.5625C15.6912 0.5625 15.1875 1.06618 15.1875 1.6875C15.1875 2.30882 15.6912 2.8125 16.3125 2.8125Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.8125 6.1875C12.4338 6.1875 12.9375 5.68382 12.9375 5.0625C12.9375 4.44118 12.4338 3.9375 11.8125 3.9375C11.1912 3.9375 10.6875 4.44118 10.6875 5.0625C10.6875 5.68382 11.1912 6.1875 11.8125 6.1875Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6875 9.00098C2.30882 9.00098 2.8125 8.4973 2.8125 7.87598C2.8125 7.25466 2.30882 6.75098 1.6875 6.75098C1.06618 6.75098 0.5625 7.25466 0.5625 7.87598C0.5625 8.4973 1.06618 9.00098 1.6875 9.00098Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={18} height={18} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default AnalyticsIcon
