import styled from 'styled-components'

export const StyledDetails = styled.div`
  background: #f8f8fb;
  min-height: 100vh;
  height: auto;
  a {
    text-decoration: none;
  }

  .statusWrapper {
    padding: 15px 30px 15px 30px;
    .progress {
      height: 25px;
      /* background-color: #e0e1e8; */
      border-radius: 18px;
      .progress-bar {
        background-color: #30355b;
        font-size: 13px;
        font-weight: 500;
      }
    }
    .progressActive {
      background-color: #30355b !important;
    }

    .progressLabels {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      height: 25px;
      align-items: center;
      position: absolute;
      z-index: 1;
      /* width: 100vw; */
      width: calc(100vw - 125px);

      /* max-width: 1316px; */
      /* flex-grow: 1; */
    }
    .progressBarActive {
      position: relative;
    }
  }

  .tabWrapper {
    margin: 25px 30px 20px 30px;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
    min-height: 592px;
    height: auto;

    .nav {
      display: flex;
      justify-content: space-between;
      width: 30%;
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #fff;
      background-color: transparent;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }

    .nav-pills .nav-link.active::after {
      content: '';
      display: block;
      margin: 0 auto;
      width: 50%;
      border-radius: 10px;
      border-bottom: 2px solid #30355b;
    }

    .nav-pills .nav-link {
      background: 0 0;
      border: 0;
      border-radius: 0.25rem;
      color: #696969;
      font-size: 14px;
    }

    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      color: #30355b;
      text-shadow: 0 0 0.25px currentColor;
      font-weight: 500;
      font-size: 16px;
    }

    .ant-tabs-tab {
      font-size: 16px;
      font-weight: 500;
      color: #696969;
    }
    .ant-tabs-ink-bar {
      position: absolute;
      background: #30355b;
      pointer-events: none;
    }
    .ant-tabs-nav-wrap {
      border: 1px solid #fff;
    }
  }
`

export const StyledNoUpload = styled.div`
  .leadsCarDocuments {
    position: relative;
    height: 90px;
    .leadCarWrap {
      .headTitle {
        font-weight: bold;
        font-size: 14px;
        color: #30355b;
      }
      .documents {
        display: flex;
        justify-content: space-between;
        .name {
          div {
            color: #000000;
            font-size: 12px;
          }
          .generateBtn,
          .uploadBtn {
            color: #30355b;
            font-weight: 600;
            font-size: 12px;
            background: #f6f7fb;
            border: 1px solid #30355b;
            box-sizing: border-box;
            border-radius: 4px;
          }
          .ppiBtn {
            color: #30355b;
            font-weight: 600;
            font-size: 12px;
            background: rgba(62, 123, 250, 0.1);
            border-radius: 85.5978px;
            border: 0;
            min-width: 87px;
            width: auto;
          }
        }
      }
    }
  }

  .uploadWrapper {
    background: #f8f8fb;
    border-radius: 10px;
    min-height: 398px;
    height: auto;
    width: 100%;
    padding: 25px;
    .title {
      color: #30355b;
      font-weight: bold;
      font-size: 16px;
      position: absolute;
    }
    .heads {
      color: #30355b;
      font-weight: bold;
      font-size: 14px;
      /* position: absolute; */
    }
    .content {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: auto;
      margin-bottom: auto;
      height: 398px;
      .text {
        color: #8c8f99;
        font-weight: 500;
        font-size: 16px;
      }
    }
  }
`

export const StyledDocumentUpload = styled.div`
  textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn:focus,
  .btn.active,
  select,
  .btn {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }
  .content {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
    height: 348px;
    .text {
      color: #8c8f99;
      font-weight: 500;
      font-size: 16px;
    }
  }
  .addedDocument {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

  .docTitle {
    font-weight: 600;
    font-size: 13px;
    color: #000000;
  }

  .docType {
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    .text {
      font-style: italic;
      font-weight: 500;
      font-size: 10px;
    }
  }

  .uploadBtn {
    color: #30355b;
    font-weight: 600;
    font-size: 12px;
    background: #f6f7fb;
    border: 1px solid #30355b;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .requestedDocuments {
    margin-top: -40px;
    .containers {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;
    }
    .reqDocTitle {
      font-weight: bolder;
      font-size: 14px;
      color: #30355b;
    }
    .reqDocContent {
      font-weight: 600;
      font-size: 13px;
      color: #30355b;
    }
    .reqDocDesc {
      font-style: italic;
      font-weight: 500;
      font-size: 10px;
      ul {
        padding-left: 0px;
        list-style-type: none;
        margin-bottom: 0px;
      }
    }
    .comment {
      font-style: italic;
      font-weight: 500;
      font-size: 10px;
      color: #d1373f;
    }
  }

  .documentUpload {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    padding-bottom: 80px;
    .fileTitle {
      color: #000000;
      font-weight: 500;
      font-size: 14px;
    }
    .fileWrapper {
      background: #fff;
      box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.05);
      border-radius: 6px;
      border:1px solid #bbb;
      padding: 15px;
      .fileType {
        background: rgba(209, 55, 63, 0.1);
        border-radius: 2px;
        width: 28px;
        height: 28px;
        font-size: 10px;
        color: #d1373f;
        font-weight: bolder;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .fileName {
        color: #30355b;
        font-weight: 450;
        font-size: 12px;
        line-height: 15px;
      }
      .fileSize {
        color: #787878;
        font-weight: 500;
        font-size: 9px;
      }
    }
    .downloadFile {
      a {
        color: #333;
        font-weight: 600;
        font-size: 12px;
        // text-decoration: underline;
        // font-style: italic;
        color:dodgerblue;
      }
      .comment {
        ul,
        li {
          margin: 0;
          padding: 0;
          list-style: none;
        }
        li {
          padding-left: 10px;
          color: #d1373f;
          font-size: 11px;
          font-style: italic;
          font-weight: 500;
        }
        li:before {
          content: '*';
        }
      }
    }

    .btn-group > .btn-group:not(:last-child) > .btn,
    .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
    .btn-check:active + .btn-primary,
    .btn-check:checked + .btn-primary,
    .btn-primary.active,
    .btn-primary:active,
    .show > .btn-primary.dropdown-toggle {
      color: #fff;
      background-color: #30355b;
      border-color: #30355b;
    }
    .radioButton {
      background-color: #ffffff;
      border: 1px solid #30355b;
      box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.05);
      border-radius: 6px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .accept,
    .reject {
      background-color: #ffffff;
      border: 1px solid #30355b;
      box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.05);
      border-radius: 6px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-radio-button-wrapper {
      /* background-color: #ffffff; */
      /* border: 1px solid #30355b; */
      /* box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.05); */
      border-radius: 6px;
      width: 25px;
      height: 25px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
      z-index: 1;
      color: #1890ff;
      background: #fff;
      border-color: #1890ff;
      /* border: 0; */
    }

    /* .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
      background-color: #1890ff;
      background-color: transparent;
    } */

    .leadsCarDocuments {
      position: relative;
      height: 90px;
      .leadCarWrap {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 70vw;
        .title {
          font-weight: bold;
          font-size: 14px;
          color: #30355b;
        }
        .documents {
          display: flex;
          justify-content: space-between;
          .name {
            div {
              color: #000000;
              font-size: 12px;
            }
            .generateBtn,
            .uploadBtn {
              color: #30355b;
              font-weight: 600;
              font-size: 12px;
              background: #f6f7fb;
              border: 1px solid #30355b;
              box-sizing: border-box;
              border-radius: 4px;
            }
            .ppiBtn {
              color: #30355b;
              font-weight: 600;
              font-size: 12px;
              background: rgba(62, 123, 250, 0.1);
              border-radius: 85.5978px;
              border: 0;
              min-width: 87px;
              width: auto;
            }
          }
        }
      }
    }
  }
  .additionalDoc {
    .head {
      font-weight: 600;
      font-size: 12px;
      color: #30355b;
    }
    .docs {
      .docName {
        color: #30355b;
        font-size: 12px;
        font-weight: 500;
      }
      .reason {
        font-style: italic;
        font-weight: 500;
        font-size: 9px;
        letter-spacing: 0px;
      }
    }
    .waiting {
      color: #d1373f;
      font-style: italic;
      font-weight: 600;
      font-size: 10px;
    }
  }

  .additionalDocButton {
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    .ant-btn {
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      border: 0;
      min-width: 220px;
      width: auto;
      height: 38px;
      span {
        color: #30355b;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }

  .docMarginRemoval {
    padding-bottom: 15px !important;
  }
`

export const StyledOffer = styled.div`
  textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn:focus,
  .btn.active,
  select {
    outline: 0px !important;
    -webkit-appearance: none !important;
    box-shadow: none !important;
  }
  .offersUpload {
    .header {
      font-weight: bold;
      font-size: 14px;
      color: #000;
    }
    .offerDetails {
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 0px;
      padding: 25px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.05);
      border-radius: 8px;
      .head {
        color: #30355b;
        font-size: 14px;
      }
      .value {
        color: #30355b;
        font-size: 14px;
        font-weight: bolder;
      }
    }
    .viewDocument {
      background: 0 0;
      padding: 0;
      border: 0;
      div {
        color: #30355b;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .collapseContent {
      padding: 25px 20px 30px 20px;
      background: #f6f7fb;
      box-shadow: inset 0px 0.5px 4px rgba(96, 97, 112, 0.32);
      border-radius: 4px;
    }
  }
`

export const StyledDocumentModal = styled.div`
  .modalWrapper {
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #30355b;
    }
    .labelWrapper {
      .label {
        color: #333333;
        font-size: 14px;
      }
      .addNew {
        color: #ffb619;
        font-size: 14px;
        font-weight: 600;
        background: rgba(255, 182, 25, 0.09);
        border-radius: 11px;
        text-decoration: underline;
        padding-left: 5px;
        padding-right: 5px;
        cursor: pointer;
      }
    }
    input {
      color: #a4a4a4;
      border-radius: 4px;
      font-size: 14px;
      height: 44px;
    }
    .ant-select-selector {
      color: #a4a4a4;
      border-radius: 4px;
      font-size: 14px;
    }
    .ant-form-item-control-input-content {
      textarea {
        color: #a4a4a4;
        border-radius: 4px;
      }
    }
    .ant-form-item-explain.ant-form-item-explain-error {
      color: #ff4d4f;
      font-size: 12px;
    }
    .cancelButton {
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 186px;
      width: auto;
      height: 44px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }
    .saveButton {
      background: #ffb619;
      border-radius: 5px;
      border: 0;
      min-width: 186px;
      width: auto;
      height: 44px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }
  }
  .addNewWrapper {
    .back {
      color: #333333;
      font-weight: 500;
      font-size: 12px;
      cursor: pointer;
    }
    .title {
      color: #30355b;
      font-weight: 600;
      font-size: 16px;
    }
    .labelWrapper {
      .label {
        color: #333333;
        font-size: 14px;
      }
    }
    .cancelButton {
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 186px;
      width: auto;
      height: 44px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }
    .saveButton {
      background: #ffb619;
      border-radius: 5px;
      border: 0;
      min-width: 186px;
      width: auto;
      height: 44px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }
  }
`

export const StyledGeneratePfi = styled.div`
  textarea:hover,
  input:hover,
  textarea:active,
  input:active,
  textarea:focus,
  input:focus,
  button:focus,
  button:active,
  button:hover,
  label:focus,
  .btn:active,
  .btn:focus,
  .btn.active,
  select,
  .btn {
    outline: 0px !important;
    -webkit-appearance: none;
    box-shadow: none !important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .title {
    text-align: center;
    color: #30355b;
    font-weight: bold;
    font-size: 18px;
  }

  .tableHeader {
    display: grid;
    grid-template-columns: 40px 160px 100px 150px 160px auto;
    grid-gap: 15px;
    background: #30345e;
    border-radius: 3px;
    height: 54px;
    align-items: center;
    div {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      text-align: center;
    }
  }

  .tableContent {
    display: grid;
    grid-template-columns: 160px 100px 150px 160px auto;
    margin-left: 22px;
    grid-gap: 15px;
    div {
      color: #30355b;
      font-weight: 500;
      text-align: center;
    }
    a {
      color: #c40000;
    }
  }

  .tableForm {
    display: grid;
    grid-template-columns: 160px 100px 150px 160px auto;
    margin-left: 22px;
    grid-gap: 15px;
  }

  ol {
    li {
      font-size: 14px;
      color: #505269;
    }
  }

  form {
    input {
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 0px;
      color: #30355b;
      font-size: 14px;
      height: 45px;
    }
  }

  .staticListButton {
    button {
      background-color: transparent;
      border: 0;
      color: #c40000;
      font-size: 14px;
      text-decoration: underline;
    }
  }

  .addNewSection {
    button {
      color: #003049;
      font-weight: 600;
      font-size: 14px;
      background: #d6dae5;
      border: 0;
      border-radius: 0;
    }
    .wrapper {
      margin-left: 250px;
      .total {
        font-weight: 500;
        font-size: 18px;
        color: #333333;
      }
      .amount {
        font-weight: 600;
        font-size: 18px;
        color: #30355b;
      }
    }
  }

  .cancelButton {
    background: #ffffff;
    border: 1px solid #30355b;
    border-radius: 5px;
    min-width: 186px;
    width: auto;
    height: 44px;
    color: #30355b;
    font-weight: 600;
    font-size: 14px;
  }
  .saveButton {
    background: #ffb619;
    border-radius: 5px;
    border: 0;
    min-width: 186px;
    width: auto;
    height: 44px;
    color: #30355b;
    font-weight: 600;
    font-size: 14px;
  }
`
