import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em',
    background: '#ffffff',
    color: '#A1AACE',
    boxShadow: 'inset 0px -1px 0px rgba(202, 208, 217, 0.4)'
  },
  backBtn: {
    textDecoration: 'none',
    border: 'none',
    color: '#A1AACE',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center'
  },
  nameInitials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '1.2em',
    width: '32px',
    height: '32px',
    color: '#ffffff',
    background: '#A1AACE',
    borderRadius: '50%'
  },
  userDetails: {
    background: '#ffffff',
    padding: '1em',
    boxShadow: '0 5px 25px -10px rgba(0, 0, 0, 0.06)',
    position:'sticky',
    top:'0px'
  },
  userDetailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      display: 'flex',
      alignItems: 'center'
    },
    '&:last-child': {
      marginTop: '1em'
    }
  },
  userDetailsTopLeft: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: '#0F1A4F',
      margin: '0 0 0 1.5em'
    }
  },
  userDetailsTopRight: {
    display: 'flex',
    alignItems: 'center'
  },
  userDetailsBottomRight: {
    display: 'flex',
    color: '#A1AACE',
    flexBasis:'74%',
    justifyContent:'flex-end'
  },
  fadeCheckBoxes: {
    opacity: '0.3',
    pointerEvents: 'none',
    
  },
  

  roleBtn: {
    display: 'flex',
   flexBasis:'32%',
    borderRadius: '7px',
    padding: '.2em .2em',
   
    border:'1px solid #31355B',
    justifyContent:'center',
    alignItems:'center',
    minHeight:'45px',
    color: '#003049',
    transition:'.3s',
    marginBottom:'1rem',
    "&:hover":
    
    {color: '#fff',
        cursor:'pointer',
            background:'#003049',
        transition:'.3s',
      }

  },

activeRole:{
    background:'#003049 !important',
    color: '#fff',
},

  saveBtn: {
    display: 'flex',

    borderRadius: '7px',
    padding: '.2em .2em',
    background:'#FFC107',
    border:'1px solid #FFC107',
    justifyContent:'center',
    alignItems:'center',
    minHeight:'45px',
    color: '#545454',
    transition:'.3s',
    marginBottom:'1rem',
    "&:hover":
    
    {
        cursor:'pointer',
            background:'#FFC107',
        transition:'.3s',
        border:'1px solid #FFC107',
      }

  },



})

export default useStyles
