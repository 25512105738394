import React, { useState } from 'react'
import { Modal, Button, Form, Input } from 'antd'
import { StyledDisbursedModal } from './styles'
import api from '../../../utils/api'
import { showSnackbar } from '../../../store/snackbar/actions'
import { getUserToken } from '../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IEquityModal {
  isModalVisible: boolean
  setIsModalVisible: any
  refetch: any
}

interface IDetailParam {
  id: string
}

export default function RequestEquityModal({
  isModalVisible,
  setIsModalVisible,
  refetch
}: IEquityModal) {
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const { t } = useTranslation()

  const onFinish = async (values: any) => {
    const amountRequested = parseInt(values.amount_requested)
    const data = { amount_requested: amountRequested, loan_id: id }

    try {
      const equityRequested = await api.requestEquity(token, data)
      if (equityRequested) {
        dispatch(showSnackbar('Equity requested successfully', 'success'))
        setIsModalVisible(false)
      }
    } catch (err) {
      dispatch(showSnackbar('Error requesting equity', 'error'))
    }
    refetch()
  }

  const onCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <Modal
      closable={false}
      width="440px"
      footer={null}
      visible={isModalVisible}
    >
      <StyledDisbursedModal>
        <div className="equityDisburseModal">
          <div className="header mb-3">{t('requestEquity')}</div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label={t('amount')}
              name="amount_requested"
              rules={[{ required: true, message: t('pleaseInputYourAmount') }]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-center mt-3">
                <Button className="cancelButton me-3" onClick={onCancel}>
                  {t('Cancel')}
                </Button>
                <Button
                  className="requestButton"
                  type="primary"
                  htmlType="submit"
                >
                  {t('request')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledDisbursedModal>
    </Modal>
  )
}
