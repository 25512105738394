import styled from 'styled-components'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import Box from '@material-ui/core/Box'
import Question from '../../../images/question.svg'
import LeftIcon from '../../../images/left.svg'
import DownIconI from '../../../images/down.svg'
import AgentI from '../../../images/agent_profile_icon.svg'
import AgentIC from '../../../images/agent_icon.svg'
import SearchI from '../../../images/search.svg'
import Grid from '@material-ui/core/Grid'
import { Link } from 'react-router-dom'
import PhoneIcon from '../../../images/phone.svg'
import MapPinIcon from '../../../images/map-pin.svg'
import EmailIcon from '../../../images/envelope.svg'

export const PageContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.palette.neutral.ghostWhite};
  min-height: 100vh;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 56px;
  padding-right: 12px;
  padding-left: 20px;
  box-shadow: inset 0px -1px 0px rgba(202, 208, 217, 0.4);
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const StyledBackLink = styled(Link)`
  text-decoration: none;
`
export const GoBack = styled.div`
  display: flex;
  justify-contnent: space-between;
  width: 96px;
  height: 22px;
  margin-top: 17px;
`

export const GoBackTitle = styled.div`
  height: 22px;
  color: #a1aace;
  line-height: 23px;
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const BackIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 11px;
  background-image: url(${LeftIcon});
`

export const GoBackIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 11px;
  background-image: url(${LeftIcon});
`

export const HeaderTopRight = styled.div`
  margin-top: 12px;
  display: flex;
`

export const Avatar = styled.div`
  width: 32px;
  height: 32px;

  & img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`

export const QuestionMark = styled.div`
  width: 20px;
  height: 20px;
  margin-top: 6px;
  margin-right: 24px;
  background-image: url(${Question});
  background-size: contain;
`

export const LeadInfoSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 113px;
  padding-left: 20px;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const LeadInfoSectionLeft = styled.div`
  width: 50%;
  padding-top: 21px;
  height: 100%;
`

export const LeadInfoSectionRight = styled.div`
  width: 50%;
  height: 100%;
`

export const LeadInfoNameType = styled.div`
  min-width: 277px;
  display: flex;
  height: 34px;
`
export const LeadInfoName = styled.div`
  font-size: ${({ theme }) => theme.font.size.H4};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.palette.secondary.main};
  line-height: 33px;
  height: 34px;
  margin-right: 16px;
  text-transform: capitalize;
`

export const AssignedAgentSection = styled.div`
  height: 32px;
  margin-top: 7px;
  padding-top: 5px;
  display: flex;
`

export const AssignedAgent = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  margin-right: 4px;
  color: #a1aace;
  height: 21px;
  line-height: 21px;
`

export const AgentName = styled.span`
  color: ${({ theme }) => theme.palette.common.black};
  text-transform: capitalize;
  cursor: pointer;
`

export const DownIcon = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${DownIconI});
  margin-top: 2px;
  cursor: pointer;
`

export const AgentIcon = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 4px;
  background-image: url(${AgentI});
`

export const AgentListOverlay = styled.div`
  width: 256px;
  height: 443px;
`

export const SearchInputContainer = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: center;
  margin: 9px 0px 16px;
`

export const SearchInput = styled.div`
  display: flex;
  width: 224px;
  height: 30px;
  background-color: ${({ theme }) => theme.palette.neutral.ghostWhite};
  border-radius: 4px;
`

export const SearchIcon = styled.div`
  width: 14px;
  height: 14px;
  margin: 8px 0px 0px 12px;
  background-image: url(${SearchI});
  background-size: contain;
`

export const LeadDetailsSectionTitle = styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: 22px;
  line-height: 22px;
  margin-bottom: 10px;
`

export const Agent = styled.div`
  width: 100%;
  padding-left: 12px;
  height: 36px;
  border-top: 1px solid #e8e9ed;
  display: flex;
  cursor: pointer;
`

export const AgentListIcon = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 16px;
  background-image: url(${AgentIC});
  margin-top: 6px;
`

export const AgentListText = styled.div`
  height: 20px;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
  line-height: 21px;
  margin-top: 7px;
  text-transform: capitalize;
`

export const StyledGrid = styled(Grid)`
  padding: 24px 21px;
`

export const StyledTabs = styled(Tabs)`
  height: 57px;
  box-shadow: inset 0px -1px 0px #e8e9ed;
`

export const StyledTab = styled(Tab)`
  height: 57px;
  font-size: ${({ theme }) => theme.font.size.sm};
  min-width: 90px;
  padding: 6px 7px;
`

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const StyledBox = styled(Box)`
  padding: 15px;
`

export const DetailItemTitle = styled.div`
  height: 20px;
  font-size: ${({ theme }) => theme.font.size.md};
  margin-right: 16px;
  color: #a1aace;
`

export const DetailItemValue = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.palette.secondary.main};
`

export const DetailItemMapIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${MapPinIcon});
  background-repeat: no-repeat;
`

export const DetailItemPhoneIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${PhoneIcon});
  background-repeat: no-repeat;
`

export const DetailItem = styled.div<{ isOwnerInfo?: boolean }>`
  height: 20px;
  margin-bottom: ${({ isOwnerInfo }) => (isOwnerInfo ? '5px' : '10px')};
  display: flex;
`

export const DetailItemEmailIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${EmailIcon});
  background-repeat: no-repeat;
`
