import React from 'react'
import TextInput from '../../../components/TextField'
import Checkbox from '../../../components/Checkbox'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { State, Props } from './types'
import { RememberMe, ForgotLink, CheckboxContainer } from './styles'
import {
  PageContainer,
  SignInBox,
  LogoTop,
  LogoTopContent,
  SignInBoxContent,
  AutoCRMText,
  BootingCar,
  InputLane,
  StyledButton
} from '../styles'

type LoginProps = Props & RouteComponentProps

class SignIn extends React.Component<LoginProps, State> {
  state = {
    checked: false,
    email: '',
    password: ''
  }

  handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      checked: event.target.checked
    })
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name

    this.setState({
      [key]: event.target.value
    })
  }

  isDetailsSet = () => {
    const { email, password } = this.state

    if (email && password) {
      return false
    }

    return true
  }

  doLogin = () => {
    const { email, password } = this.state
    const { login, history } = this.props
    const { getLocation } = this.props
    localStorage.removeItem('currentPagination')
    localStorage.removeItem('queriesInStorage')
    login(email, password, history)
    getLocation()
  }

  render() {
    const { checked, email, password } = this.state
    const { isAuth, userRoles } = this.props

    if (isAuth && userRoles) {
      if (userRoles.includes('ADMIN') || userRoles.includes('LEAD_MANAGER')) {
        return <Redirect to={{ pathname: '/lead-dashboard' }} />
      } else if (userRoles.includes('PORTFOLIO_MANAGER')) {
        return <Redirect to={{ pathname: '/portfolio-mgt' }} />
      } else {
        return <Redirect to={{ pathname: '/leads' }} />
      }
    }

    return (
      <PageContainer>
        <SignInBox>
          <LogoTop>
            <LogoTopContent>
              <BootingCar /> <AutoCRMText />
            </LogoTopContent>
          </LogoTop>
          <SignInBoxContent>
            <InputLane marginBottom="25px" bold>
              <TextInput
                name="email"
                label="Email address"
                value={email}
                onInputChange={this.handleInputChange}
              />
            </InputLane>
            <InputLane marginBottom="28px" bold>
              <TextInput
                name="password"
                label="Password"
                type="password"
                value={password}
                onInputChange={this.handleInputChange}
              />
            </InputLane>
            <InputLane marginBottom="26px" flex spaceBetween>
              <RememberMe>
                <CheckboxContainer>
                  <Checkbox
                    checked={checked}
                    handleChange={this.handleCheckBoxChange}
                  />
                </CheckboxContainer>
                Remember me
              </RememberMe>
              <ForgotLink to="/forgot-password">
                Forgot your password?
              </ForgotLink>
            </InputLane>
            <StyledButton
              disabled={this.isDetailsSet()}
              deactivated={this.isDetailsSet() ? 1 : 0}
              onClick={this.doLogin}
            >
              Sign In
            </StyledButton>
          </SignInBoxContent>
        </SignInBox>
      </PageContainer>
    )
  }
}

export default withRouter(SignIn)
