import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import api from '../../utils/api'
import { getUserToken } from '../auth/selectors'
import { showSnackbar } from '../snackbar/actions'
import { EventTypes, requestStatus } from './actions'

const ERROR_MSG = 'Unable to complete your request.'

export function* addSchedule({ payload }: any):any {


  try {
    const token = yield select(getUserToken)
    const response = yield call(api.createSchedule, payload, token)
    yield put(requestStatus(EventTypes.ADD_ACTIVITY_SUCCESS, response))
    yield put(showSnackbar('schedule added', 'success'))
  } catch (error) {
    yield put(requestStatus(EventTypes.ADD_ACTIVITY_FAILED, error.message))
  }
}

export function* getSchedule({ leadId, queries }: any):any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.getSchedule, leadId, queries, token)
    if(queries.completed) {
      yield put(requestStatus(EventTypes.GET_COMPLETED_SCHEDULE, response))
    }else {
      yield put(requestStatus(EventTypes.GET_PENDING_SCHEDULE, response))
    }
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}

export function* updateSchedule({ payload, id }: any):any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.updateSchedule, id, payload, token)
    yield put(requestStatus(EventTypes.UPDATE_SCHEDULE_SUCCESS, response))
    yield put(showSnackbar('schedule updated', 'success'))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}

export function* deleteSchedule({ id }: any):any {
  try {
    const token = yield select(getUserToken)
    yield call(api.deleteSchedule, id, token)
    yield put(requestStatus(EventTypes.DELETE_SCHEDULE_SUCCESS, id))
    yield put(showSnackbar('schedule deleted', 'success'))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}
export function* fetchServiceOptions():any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchServiceOptions, token)
    yield put(requestStatus(EventTypes.FETCH_SERVICE_OPTIONS_SUCCESS, response))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}
function* watchAddSchedule() {
  yield takeLatest(EventTypes.ADD_ACTIVITY, addSchedule)
}
function* watchGetSchedule() {
  yield takeEvery(EventTypes.GET_SCHEDULES, getSchedule)
}
function* watchUpdateSchedule() {
  yield takeLatest(EventTypes.UPDATE_SCHEDULE, updateSchedule)
}
function* watchDeleteSchedule() {
  yield takeEvery(EventTypes.DELETE_SCHEDULE, deleteSchedule)
}
function* watchServiceOptions() {
  yield takeLatest(EventTypes.FETCH_SERVICE_OPTIONS, fetchServiceOptions)
}

const eventSagas: any[] = [
  watchAddSchedule(),
  watchGetSchedule(),
  watchUpdateSchedule(),
  watchDeleteSchedule(),
  watchServiceOptions()
]

export default eventSagas
