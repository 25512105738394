import { StyledInspectionOutcome } from './styles'
import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { Tabs, Tab } from 'react-bootstrap'
import { ActiveIcon, InactiveIcon } from '../AvailableCar/svg'
import NoShow from './NoShow'
import NotAvailable from './NotAvailable'
import NotAcceptedCar from './NotAccepted'
import AcceptedModal from './Accepted'
import { useTranslation } from 'react-i18next'

export default function InspectionOutcomeModal({
  inspOutcome,
  setInspOutcome,
  modalData,
  refetch,
  singleLoan,
  loanRefresh
}: any) {
  const [key, setKey] = useState('noShow')
  const { t } = useTranslation()

  return (
    <Modal closable={false} visible={inspOutcome} width="696px" footer={null}>
      <StyledInspectionOutcome>
        <div className="bookInspectionWrapper">
          <div className="title mb-2">{t('whatWasTheOutcome')}?</div>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k: any) => setKey(k)}
            className="mb-3 nav-fill"
            variant="pills"
          >
            <Tab
              eventKey="noShow"
              title={
                <div className="tabName d-flex align-items-center">
                  {key == 'noShow' ? (
                    <span>{ActiveIcon}</span>
                  ) : (
                    <span>{InactiveIcon}</span>
                  )}
                  &nbsp;&nbsp; {t('NoShow')}
                </div>
              }
            >
              <NoShow
                setInspOutcome={setInspOutcome}
                modalData={modalData}
                refetch={refetch}
              />
            </Tab>
            <Tab
              eventKey="notAvailable"
              title={
                <div className="tabName d-flex align-items-center">
                  {key == 'notAvailable' ? (
                    <span>{ActiveIcon}</span>
                  ) : (
                    <span>{InactiveIcon}</span>
                  )}
                  &nbsp;&nbsp; {t('CarNotAvailable')}
                </div>
              }
            >
              <NotAvailable
                setInspOutcome={setInspOutcome}
                modalData={modalData}
                refetch={refetch}
                singleLoan={singleLoan}
                loanRefresh={loanRefresh}
              />
            </Tab>
            <Tab
              eventKey="notLiked"
              title={
                <div className="tabName d-flex align-items-center">
                  {key == 'notLiked' ? (
                    <span>{ActiveIcon}</span>
                  ) : (
                    <span>{InactiveIcon}</span>
                  )}
                  &nbsp;&nbsp; {t('DidNotLikeCar')}
                </div>
              }
            >
              <NotAcceptedCar
                setInspOutcome={setInspOutcome}
                modalData={modalData}
                refetch={refetch}
                singleLoan={singleLoan}
                loanRefresh={loanRefresh}
              />
            </Tab>
            <Tab
              eventKey="accepted"
              title={
                <div className="tabName d-flex align-items-center">
                  {key == 'accepted' ? (
                    <span>{ActiveIcon}</span>
                  ) : (
                    <span>{InactiveIcon}</span>
                  )}
                  &nbsp;&nbsp; {t('carAccepted')}
                </div>
              }
            >
              <AcceptedModal
                setInspOutcome={setInspOutcome}
                modalData={modalData}
                refetch={refetch}
                singleLoan={singleLoan}
                loanRefresh={loanRefresh}
              />
            </Tab>
          </Tabs>
        </div>
      </StyledInspectionOutcome>
    </Modal>
  )
}
