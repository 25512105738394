import React from 'react'

export const ChevronRIcon = (
  <svg
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1.5L6.5 7L1 12.5"
      stroke="#171717"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const InboxIcon = (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.00073"
      y="1.36328"
      width="17.9394"
      height="13.4545"
      rx="1.68182"
      stroke="#30355B"
      stroke-width="1.26136"
    />
    <path
      d="M1 4.72656L9.21757 8.83535C9.69105 9.07209 10.2484 9.07208 10.7218 8.83535L18.9394 4.72656"
      stroke="#30355B"
      stroke-width="1.26136"
    />
  </svg>
)

export const PhoneIcon = (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.29565 2.09173L3.53105 1.85633C3.85944 1.52794 4.39188 1.52794 4.72027 1.85633L7.45529 4.59135C7.78368 4.91974 7.78368 5.45218 7.45529 5.78057L6.35438 6.88148C6.19517 7.04069 6.1557 7.28392 6.25639 7.48531C7.42058 9.81368 9.30855 11.7016 11.6369 12.8658C11.8383 12.9665 12.0815 12.9271 12.2407 12.7678L13.3417 11.6669C13.67 11.3385 14.2025 11.3385 14.5309 11.6669L17.2659 14.402C17.5943 14.7303 17.5943 15.2628 17.2659 15.5912L17.0305 15.8266C15.255 17.602 12.4442 17.8018 10.4355 16.2953L8.29817 14.6922C6.83202 13.5926 5.5296 12.2902 4.42999 10.8241L2.82696 8.68668C1.32043 6.67798 1.52019 3.86719 3.29565 2.09173Z"
      stroke="#30355B"
      stroke-width="1.26136"
    />
  </svg>
)

export const HistoryIcon = (
  <svg
    width="11"
    height="14"
    viewBox="0 0 11 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.32324 12.9775L1.32324 1.20481"
      stroke="#30355B"
      stroke-width="1.68182"
      stroke-linecap="round"
    />
    <path
      d="M5.52783 12.9775L5.52783 4.56845"
      stroke="#30355B"
      stroke-width="1.68182"
      stroke-linecap="round"
    />
    <path
      d="M9.73218 12.9775L9.73218 7.93208"
      stroke="#30355B"
      stroke-width="1.68182"
      stroke-linecap="round"
    />
  </svg>
)

export const TaskIcon = (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_394_10988)">
      <path
        d="M23 31.0156C27.4183 31.0156 31 27.4339 31 23.0156C31 18.5973 27.4183 15.0156 23 15.0156C18.5817 15.0156 15 18.5973 15 23.0156C15 27.4339 18.5817 31.0156 23 31.0156Z"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 19.0156V27.0156"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 27.0156L20 24.0156"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M23 27.0156L26 24.0156"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 14.0156H14"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 19.0156H11"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 24.0156H11"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 31.0156H3C2.46957 31.0156 1.96086 30.8049 1.58579 30.4298C1.21071 30.0548 1 29.5461 1 29.0156V8.01563C1 7.48519 1.21071 6.97648 1.58579 6.60141C1.96086 6.22634 2.46957 6.01563 3 6.01563H8C8 4.68954 8.52678 3.41777 9.46447 2.48009C10.4021 1.54241 11.6739 1.01563 13 1.01562C14.3261 1.01563 15.5979 1.54241 16.5355 2.48009C17.4732 3.41777 18 4.68954 18 6.01563H23C23.5304 6.01563 24.0391 6.22634 24.4142 6.60141C24.7893 6.97648 25 7.48519 25 8.01563V11.0156"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M13 5.01562C13.0989 5.01562 13.1956 5.04495 13.2778 5.09989C13.36 5.15483 13.4241 5.23292 13.4619 5.32428C13.4998 5.41565 13.5097 5.51618 13.4904 5.61317C13.4711 5.71016 13.4235 5.79925 13.3536 5.86918C13.2836 5.9391 13.1945 5.98673 13.0975 6.00602C13.0006 6.02531 12.9 6.01541 12.8087 5.97756C12.7173 5.93972 12.6392 5.87563 12.5843 5.79341C12.5293 5.71119 12.5 5.61452 12.5 5.51563C12.5 5.38302 12.5527 5.25584 12.6464 5.16207C12.7402 5.0683 12.8674 5.01563 13 5.01562"
        stroke="#9F9F9F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_394_10988">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export const MarkBIcon = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75867 5.90511L5.92246 10.0689L17.819 1.14648"
      stroke="black"
      stroke-width="2.37931"
    />
  </svg>
)

export const MarkWIcon = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 19 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75867 5.90511L5.92246 10.0689L17.819 1.14648"
      stroke="white"
      stroke-width="2.37931"
    />
  </svg>
)

export const CancelBIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="14.5996"
      y="4.54785"
      width="2.48638"
      height="14.2521"
      transform="rotate(45 14.5996 4.54785)"
      fill="black"
    />
    <rect
      x="16.3574"
      y="14.626"
      width="2.48638"
      height="14.2521"
      transform="rotate(135 16.3574 14.626)"
      fill="black"
    />
  </svg>
)

export const CancelWIcon = (
  <svg
    width="22"
    height="22"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="14.5996"
      y="4.54785"
      width="2.48638"
      height="14.2521"
      transform="rotate(45 14.5996 4.54785)"
      fill="white"
    />
    <rect
      x="16.3574"
      y="14.626"
      width="2.48638"
      height="14.2521"
      transform="rotate(135 16.3574 14.626)"
      fill="white"
    />
  </svg>
)

export const NoticeIcon = (
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="21" height="21" rx="2" fill="#D1373F" />
    <path
      d="M11.625 4.81818H10.2727L10.3722 12.1364H11.5256L11.625 4.81818ZM10.9489 15.0795C11.4411 15.0795 11.8438 14.6768 11.8438 14.1847C11.8438 13.6925 11.4411 13.2898 10.9489 13.2898C10.4567 13.2898 10.054 13.6925 10.054 14.1847C10.054 14.6768 10.4567 15.0795 10.9489 15.0795Z"
      fill="white"
    />
  </svg>
)

export const ChevronTIcon = (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 0L11.1962 6.75L0.803848 6.75L6 0Z" fill="#6F6F6F" />
  </svg>
)

export const ChevronBIcon = (
  <svg
    width="12"
    height="7"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6 7L0.803847 0.25L11.1962 0.250001L6 7Z" fill="#6F6F6F" />
  </svg>
)

export const ChevronBack = (
  <svg
    width="8"
    height="13"
    viewBox="0 0 8 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 12L1 6.48847L6.5 1"
      stroke="#333333"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const ChevronDIcon = (
  <svg
    width="11"
    height="5"
    viewBox="0 0 11 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.109375 0L4.39252 4.29215C4.78264 4.68308 5.41581 4.68375 5.80674 4.29363L10.1094 0"
      fill="#30355B"
    />
  </svg>
)

export const DownloadIcon = (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.2793 11.9756V14.9756H2.2793V11.9756H0.279297V14.9756C0.279297 16.0756 1.1793 16.9756 2.2793 16.9756H14.2793C15.3793 16.9756 16.2793 16.0756 16.2793 14.9756V11.9756H14.2793ZM13.2793 7.97559L11.8693 6.56559L9.2793 9.14559V0.975586H7.2793V9.14559L4.6893 6.56559L3.2793 7.97559L8.2793 12.9756L13.2793 7.97559Z"
      fill="#30355B"
    />
  </svg>
)

export const PlusIcon = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="8" fill="#003049" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.67015 5.20605C8.67015 4.92991 8.44629 4.70605 8.17015 4.70605C7.89401 4.70605 7.67015 4.92991 7.67015 5.20605V7.67075L5.20508 7.67075C4.92894 7.67075 4.70508 7.89461 4.70508 8.17075C4.70508 8.4469 4.92894 8.67075 5.20508 8.67075L7.67015 8.67075V10.7943C7.67015 11.0704 7.89401 11.2943 8.17015 11.2943C8.44629 11.2943 8.67015 11.0704 8.67015 10.7943V8.67075L10.7933 8.67075C11.0695 8.67075 11.2933 8.4469 11.2933 8.17075C11.2933 7.89461 11.0695 7.67075 10.7933 7.67075L8.67015 7.67075V5.20605Z"
      fill="white"
    />
  </svg>
)
