import React from 'react';


import  dayjs from 'dayjs'
import { useDispatch, useSelector, connect } from 'react-redux'
import { addSchedule, setEvent } from '../../../store/events/actions'
import {
 
    getUserTimeZone,
  salesOfficersData

} from '../../../store/leads/selectors'

import useStylesFromFile from './styles'
import {  isAdmin, isManager, isAgent, getUserIdIfAgent, isFulfillmentAgent} from '../../../store/auth/selectors'
import calendar from '../../../images/calendar-dark.svg'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Typography,  FormHelperText,
  InputBase,
  TextField, Grid } from '@material-ui/core'
  import { showSnackbar } from '../../../store/snackbar/actions'
  
import {
  makeStyles
} from '@material-ui/core'

import Popover from '@material-ui/core/Popover';
import { usePhoneStyles } from '../../Leads/LeadDetails/LeadActions/styles'
import {
  
    PopOverDiv,
   
  } from '../../Activities/styles'


  const required = 'This field is required.'
  const validationSchema = Yup.object().shape({
    date: Yup.string().required(required),
    time: Yup.string().required(required),
    note: Yup.string().required(required)
  })
  const initialValues = {
    date: '',
    time: '',
    note: ''
  }

  const useStyles = makeStyles (theme => ({
    popoverRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));
  
let btnClass = {
    saveNote: {
        display: 'flex',
        justifyContent: 'flex-end',
        margin: '1em 0'
      },
}

    export default function RescheduleButton(prop:any){
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes3 = useStylesFromFile()
    const classes = usePhoneStyles()
 
  
    const userIsAdmin = useSelector(isAdmin)
    const [showSelectedValue, setShowSelectedValue] = React.useState('');
  const [appointment, setAppointment] = React.useState(false)
  const [showReschedule, setShowReschedule] = React.useState(false)
  const salesOfficers = useSelector(salesOfficersData)
  const [salesOfficerId, setSalesOfficerId] = React.useState('');
  const [salesOfficersFromHook, setSalesOfficers] = React.useState([] as any);

    const [showValue, setShowValue] = React.useState(false)





    
       const saveReminder = (note:string, time:string, date:string) => {
console.log(time, note, date, 'let s')

if(!note|| !date || !time){
    dispatch(showSnackbar('Please fill in all fields', 'error'))
    return;

}


        let dataForSchedule = {
            activityType:'LOAN_REMINDER',
            leadId:prop.id,
            completed:false,
            date,
            user_id:prop.userId,
            time:time+getUserTimeZone(),
            note
          }
          prop.saveNote()
          prop.clearText()

          dispatch(addSchedule(dataForSchedule))
          handleClose()
     };
    
    const handleClick = (event:any) => {
      setAnchorEl(event.currentTarget);
      setShowReschedule(true);
    }

  
  
    const handleClose = () => {
      setAnchorEl(null);
      
      setShowReschedule(false);
      setShowValue(false)
    }
  
  
    const {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      
      touched,
      errors
    } = useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: (values) => {
        const { date, time, note } = values
  
     
  // dispatch(createAppointment(AppointmentData))
  

  
      }
    })
    const classes2 = useStyles();


   

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
    
     <div style={{minWidth:'100%'}}>


            <div style={{display:'flex'}}>

            <div>
          <Typography variant="body2" className={classes.label}>
            Date
          </Typography>
          <TextField
            id="date"
            name="date"
            onBlur={handleBlur}
            error={!!(errors.date && touched.date)}
            helperText={touched.date && errors.date}
            value={values.date}
            onChange={handleChange}
            variant="outlined"
            type="date"
            inputProps={{
             
                min: dayjs(new Date()).format('YYYY-MM-DD')
            }}
          />
        </div>
        <div style={{marginLeft:'5px'}}>
          <Typography variant="body2" className={classes.label}>
            Time
          </Typography>
          <InputBase
            name="time"
            type="time"
            className={classes.timeInputWrapper}
            onBlur={handleBlur}
            error={!!(errors.time && touched.time)}
            value={values.time}
            onChange={handleChange}
          />
          {!!touched.time && (
            <FormHelperText error>{errors.time}</FormHelperText>
          )}
            </div>

        </div>
        <div style={{display:'flex', justifyContent:'flex-end'}}>
        <button style={{  border: '1px solid #4B9FF2',
    color: '#4B9FF2',
    background: 'transparent',
    borderRadius: '4px',
    padding: '1em',
    cursor: 'pointer', marginTop:'20px', marginBottom:'20px'}}  onClick={(event) => saveReminder(prop.loanNote, values.time, values.date)}>Save reminder</button>
        </div>
       
   
        <Popover
          id={id}
          open={open}
    
          onClose={handleClose}
          anchorReference={"none"}
          classes={{
            root: classes2.popoverRoot,
           }}
        >
            
<PopOverDiv>
<Grid item  xs={12}>

<Grid item  xs={12}>
          <Grid container className={classes.root} justify="center">
      <Grid item xs={12} className={classes.titleWrapper}>
        <img
    src={calendar}
          alt="calendar"
          className={classes.icon}
        />
{

   <Typography variant="body1" className={classes.title}>
        Add reminder 
        </Typography>
      
}

     
      </Grid>
    </Grid>
    <Grid item xs={12} className={classes.datetime}>
       
      </Grid> 






     </Grid>





      <Grid item xs={12} className={classes.text}>


      {


<Typography variant="body2" className={classes.label}>
Note
</Typography>

      }

    
       
        <TextField
          fullWidth
          multiline
          rows={3}
          onBlur={handleBlur}
          error={!!(errors.note && touched.note)}
          helperText={touched.note && errors.note}
          name="note"
          value={values.note}
          onChange={handleChange}
          id="note"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} className={classes.formFooter}>
      <Button variant="outlined" onClick={() => handleClose()}>
        Cancel
      </Button>
   
    </Grid>
    </Grid>
</PopOverDiv>

        </Popover>
      </div>
    )
    }