import React from 'react'
import clsx from 'clsx'
//@ts-ignore
import qs from 'stringquery'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import IconButton from '@material-ui/core/IconButton'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SettingsIcon from '@material-ui/icons/Settings'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import AnalyticsIcon from './svg/Analytics'
import PersonIcon from './svg/Person'
import MessagesIcon from './svg/Messages'
import RankingIcon from './svg/Ranking'

import CashIcon from './svg/Cash'
import { AutochekThemeOptions } from '../../theme/types'
import Popover from '@material-ui/core/Popover'

import {
  RouteComponentProps,
  withRouter,
  Link,
  useLocation
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  getSelectedCountry,
  isAgent,
  isManager,
  isAdmin,
  isPortfolio,
  isLead,
  getUserIdIfAgent,
  canViewLoans
} from '../../store/auth/selectors'
import { activityTypeFromTheStore } from '../../store/leads/selectors'

import { setCountry, getUserLocation } from '../../store/auth/actions'

import NigeriaI from '../../images/nigeria.svg'
import GhanaI from '../../images/ghana.svg'
import KenyaI from '../../images/kenya.svg'
import Civ from '../../images/civ.svg'
import Uganda from '../../images/uganda.svg'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  StyledIcon,
  LogoContainer,
  AutoCRMText,
  ToolBarContainer,
  ChildrenWrap,
  StyledLink,
  StyledListItemText,
  StyledListItem,
  ActiveBar,
  CountryPopOver,
  CountryItem,
  LogoutLink
} from './styles'
import {
  fetchConversations,
  fetchMessageStats
} from '../../store/conversations/actions'
import {
  getCurrentActivity,
  startActivityLoader,
  clearPrevState
} from '../../store/leads/actions'
import { fetchLoanLeads } from '../../store/loans/actions'
import { getUnreadConvoCount } from '../../store/conversations/selectors'

// import Select, { components, ActionMeta } from 'react-select'
import styled from 'styled-components'
import { Select } from 'antd'
import 'antd/dist/antd.css'
import { useTranslation } from 'react-i18next'

const drawerWidth = 196

const BoxWrap = styled.div`
  margin-left: 15px;
`

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 0
  },
  hide: {
    display: 'none'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  drawerOpen: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.dark,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    zIndex: 1
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 64,
    backgroundColor: theme.palette.primary.dark,
    zIndex: 1
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3)
  },
  listItem: {
    color: theme.palette.common.white
  },
  listIcon: {
    minWidth: '39px',
    marginLeft: '8px'
  },
  divider: {
    backgroundColor: '#404978'
  },
  popover: {
    backgroundColor: 'transparent',
    marginTop: 5,
    top: '375px!important;',
    left: '24px!important;'
  },
  dropDownIcon: {
    color: '#fff',
    width: '16px'
  }
}))

type Props = {
  children?: React.ReactChild | React.ReactChild[]
} & RouteComponentProps

const getActiveMenu = (match: { path: string }, menuItem: string) => {
  const { path } = match
  const currentPath = path.split('/')[1]

  return currentPath === menuItem
}

const getCountryFlag = (selectedCountry: string) => {
  if (selectedCountry === 'GH') {
    return GhanaI
  } else if (selectedCountry === 'KE') {
    return KenyaI
  } else if (selectedCountry === 'CI') {
    return Civ
  } else if (selectedCountry === 'UG') {
    return Uganda
  } else {
    return NigeriaI
  }
}

const MiniDrawer = ({ children, match }: Props) => {
  const { i18n } = useTranslation()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)
  const theme: AutochekThemeOptions = useTheme()
  const [open, setOpen] = React.useState(false)
  const isLeadsPath = getActiveMenu(match, 'leads')
  const isSettingsPath = getActiveMenu(match, 'settings')
  const isActivityPath = getActiveMenu(match, 'activities')
  const isAgentPath = getActiveMenu(match, 'agents')
  const isConversationsPath = getActiveMenu(match, 'conversations')
  const isLoansPath = getActiveMenu(match, 'loans')
  const isDsaPath = getActiveMenu(match, 'dsa')
  const isFranchisePath = getActiveMenu(match, 'franchise')
  const isPortfolioManagementPath = getActiveMenu(match, 'portfolio-mgt')
  const isLeadManagementPath = getActiveMenu(match, 'lead-dashboard')
  const selectedCountry = useSelector(getSelectedCountry)
  const userIsAgent = useSelector(isAgent)
  const userCanViewLoans = useSelector(canViewLoans)
  const userIsManager = useSelector(isManager)
  const userIsAdmin = useSelector(isAdmin)
  const userIsPortfolio = useSelector(isPortfolio)
  const userIsLead = useSelector(isLead)
  const activityTypeFromStore: any = useSelector(activityTypeFromTheStore)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const location = useLocation()
  const unreadConvos = useSelector(getUnreadConvoCount)
  const dispatch = useDispatch()
  let history = useHistory()

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleClick = (event: any) => {
    let target = event.currentTarget as HTMLInputElement
    if (userIsManager || userIsAdmin) {
      return setAnchorEl(target)
    }

    // if (userIsManager || userIsAgent) return
    // return setAnchorEl(target)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  // console.log('userIsManager', userIsManager)

  // console.log('location', location.pathname)

  const changeLanguage = (locale: any) => {
    const params = new URLSearchParams({ lg: locale })
    history.replace({ pathname: location.pathname, search: params.toString() })
    i18n.changeLanguage(locale)
    window.localStorage.setItem('lng', locale)
  }

  const setCountrySelection = (country: string, locale: string) => {
    changeLanguage(locale)
    // console.log(location.pathname)
    handleDrawerClose()
    const isConversationsPath = location.pathname.includes('conversations')
    const isLoans = location.pathname.includes('loans')
    const isWhatsApp = location.pathname.includes('whatsapp')
    const isActivities = location.pathname.includes('activities')
    const isAgents = location.pathname.includes('agents')
    const isPortfolio = location.pathname.includes('portfolio-mgt')
    const isLeads = location.pathname.includes('lead-dashboard')
    const isLeadsDetails = location.pathname.includes('completed-loans')
    const isPortfolioTable = location.pathname.includes('portfolio-loans')
    dispatch(clearPrevState())
    dispatch(getUserLocation())
    dispatch(setCountry(country))
    handleClose()

    if (isConversationsPath) {
      dispatch(fetchMessageStats())
      dispatch(fetchConversations('all'))
      history.push('/conversations/all')
    } else if (isAgents && !isWhatsApp) {
      history.push('/settings/agents')
    } else if (isWhatsApp) {
      history.push('/settings/agents/whatsapp')
    } else if (isPortfolio) {
      history.push('/portfolio-mgt')
    } else if (isLeads) {
      history.push('/lead-dashboard')
    } else if (isLeadsDetails) {
      history.push('/completed-loans')
    } else if (isPortfolioTable) {
      history.push('/portfolio-loans')
    } else if (isLoans) {
      dispatch(fetchLoanLeads(1, agentIdFromStore))

      localStorage.removeItem('currentPagination')
      history.push('/loans')
    } else if (isDsaPath) {
      dispatch(fetchLoanLeads(1, agentIdFromStore))

      localStorage.removeItem('currentPagination')
      history.push('/dsa')
    } else if (isFranchisePath) {
      dispatch(fetchLoanLeads(1, agentIdFromStore))

      localStorage.removeItem('currentPagination')
      history.push('/franchise')
    } else if (isActivities) {
      let query
      if (userIsAdmin || userIsManager) {
        query = {
          activity_type: activityTypeFromStore,
          completed: false,
          appointment_type: activityTypeFromStore
        }
      } else {
        query = {
          activity_type: activityTypeFromStore,
          completed: false,
          appointment_type: activityTypeFromStore,
          agentId: agentIdFromStore
        }
      }
      if (
        activityTypeFromStore == 'PHONE_CALL' ||
        activityTypeFromStore == 'LOAN_REMINDER'
      ) {
        delete query.appointment_type
      } else {
        delete query.activity_type
      }

      dispatch(startActivityLoader())
      dispatch(getCurrentActivity(query))
      history.push('/activities')
    } else {
      localStorage.removeItem('queriesInStorage')
      history.push('/')
    }
  }

  const popoverOpen = Boolean(anchorEl)
  const id = popoverOpen ? 'simple-popover' : undefined

  const { t } = useTranslation()

  const { Option } = Select

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.toolbar}>
          {open ? (
            <ToolBarContainer>
              <LogoContainer>
                <StyledIcon />
                <AutoCRMText />
              </LogoContainer>
              <IconButton
                style={{ color: 'white' }}
                onClick={handleDrawerClose}
              >
                <ChevronLeftIcon />
              </IconButton>
            </ToolBarContainer>
          ) : (
            <StyledIcon onClick={handleDrawerOpen} />
          )}
        </div>
        <List className={classes.listItem}>
          {/* <StyledLink to="/leads">
            <StyledListItem active={isLeadsPath} button>
              {isLeadsPath && <ActiveBar />}
              <ListItemIcon className={classes.listIcon}>
                <RankingIcon active={isLeadsPath} theme={theme} />
              </ListItemIcon>
              <StyledListItemText active={isLeadsPath} primary={t('leads')} />
            </StyledListItem>
          </StyledLink> */}

          {/* {userCanViewLoans ? (
            <StyledLink to="/loans">
              <StyledListItem active={isLoansPath} button>
                {isLoansPath && <ActiveBar />}
                <ListItemIcon className={classes.listIcon}>
                  <CashIcon active={false} theme={theme} />
                </ListItemIcon>
                <StyledListItemText primary={t('Loan leads')} />
              </StyledListItem>
            </StyledLink>
          ) : null} */}

          {userIsAdmin ||
          userIsManager ||
          (userIsLead && userIsAgent) ||
          (userIsAgent &&
            !userIsLead &&
            !userIsPortfolio &&
            !userIsManager &&
            !userIsAdmin) ? (
            <StyledLink to="/lead-dashboard">
              <StyledListItem active={isLeadManagementPath} button>
                {isLeadManagementPath && <ActiveBar />}
                <ListItemIcon className={classes.listIcon}>
                  <AnalyticsIcon active={false} theme={theme} />
                </ListItemIcon>
                <StyledListItemText primary={t('leads')} />
              </StyledListItem>
            </StyledLink>
          ) : null}

          {userIsAdmin ||
          userIsManager ||
          (userIsPortfolio && userIsAgent) ||
          ((userIsAdmin || userIsManager) && userIsAgent) ? (
            <StyledLink to="/portfolio-mgt">
              <StyledListItem active={isPortfolioManagementPath} button>
                {isPortfolioManagementPath && <ActiveBar />}
                <ListItemIcon className={classes.listIcon}>
                  <i
                    className="fas fa-file-alt"
                    style={{
                      color: 'white',
                      fontSize: '18px'
                    }}
                  ></i>
                </ListItemIcon>
                <StyledListItemText primary="Portfolio Management" />
              </StyledListItem>
            </StyledLink>
          ) : null}

          {userIsAdmin || userIsManager ? (
            <StyledLink to="/dsa">
              <StyledListItem active={isDsaPath} button>
                {isDsaPath && <ActiveBar />}
                <ListItemIcon className={classes.listIcon}>
                  <i
                    className="far fa-address-card 
                
                "
                    style={{
                      color: 'white',
                      fontSize: '18px'
                    }}
                  ></i>
                </ListItemIcon>
                <StyledListItemText primary={t('dsa_loans')} />
              </StyledListItem>
            </StyledLink>
          ) : null}

          {userIsAdmin || userIsManager ? (
            <StyledLink to="/franchise">
              <StyledListItem active={isFranchisePath} button>
                {isFranchisePath && <ActiveBar />}
                <ListItemIcon className={classes.listIcon}>
                  <i
                    className="fas fa-briefcase"
                    style={{
                      color: 'white',
                      fontSize: '18px'
                    }}
                  ></i>
                </ListItemIcon>
                <StyledListItemText primary={t('franchise_loans')} />
              </StyledListItem>
            </StyledLink>
          ) : null}

          <StyledLink to="/activities">
            <StyledListItem button>
              {isActivityPath && <ActiveBar />}
              <ListItemIcon className={classes.listIcon}>
                <PersonIcon active={false} theme={theme} />
              </ListItemIcon>
              <StyledListItemText primary={t('activities')} />
            </StyledListItem>
          </StyledLink>

          <StyledLink to="/conversations">
            <StyledListItem
              active={isConversationsPath}
              button
              notificationCount={unreadConvos}
            >
              {isConversationsPath && <ActiveBar />}
              <ListItemIcon className={classes.listIcon}>
                <MessagesIcon active={false} theme={theme} />
              </ListItemIcon>
              <StyledListItemText primary="Conversations" />
            </StyledListItem>
          </StyledLink>
        </List>
        <Divider className={classes.divider} />

        <List className={classes.listItem}>
          <StyledLink to="/settings">
            <StyledListItem active={isSettingsPath} button>
              {isSettingsPath && <ActiveBar />}
              <ListItemIcon className={classes.listIcon}>
                <SettingsIcon
                  style={{ color: isSettingsPath ? '#FFCC5B' : 'white' }}
                />
              </ListItemIcon>
              <StyledListItemText
                active={isSettingsPath}
                primary={t('settings')}
              />
            </StyledListItem>
          </StyledLink>
        </List>

        <BoxWrap>
          <Select
            onChange={changeLanguage}
            //@ts-ignore
            defaultValue={localStorage.getItem('lng') || 'en'}
            style={{
              width: 50,
              fontSize: '12px',
              fontWeight: 'bolder',
              color: '#fff'
            }}
            dropdownStyle={{
              backgroundColor: '#0A1133',
              boxShadow: 'none'
            }}
            bordered={false}
            showArrow={false}
          >
            <Option
              style={{
                width: 45,
                textAlign: 'center',
                fontSize: '12px',
                backgroundColor: '#0A1133',
                color: '#fff'
              }}
              value="en"
            >
              EN
            </Option>
            <Option
              style={{
                width: 45,
                textAlign: 'center',
                fontSize: '12px',
                backgroundColor: '#0A1133',
                color: '#fff'
              }}
              value="fr"
            >
              FR
            </Option>
          </Select>
        </BoxWrap>

        <List className={classes.listItem}>
          <StyledListItem button onClick={handleClick}>
            <ListItemIcon
              className={classes.listIcon}
              onClick={handleDrawerOpen}
            >
              <img
                src={getCountryFlag(selectedCountry)}
                alt="Selected country flag"
                style={{ marginTop: '3px' }}
              />
              <KeyboardArrowDownIcon
                classes={{
                  root: classes.dropDownIcon
                }}
              />
            </ListItemIcon>
          </StyledListItem>
        </List>
        <Popover
          id={id}
          open={popoverOpen}
          anchorEl={anchorEl}
          onClose={handleClose}
          classes={{
            paper: classes.popover
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <CountryPopOver>
            {selectedCountry !== 'NG' && (
              <CountryItem>
                <img
                  src={NigeriaI}
                  alt=""
                  onClick={() => setCountrySelection('NG', 'en')}
                />
                <span
                  style={{ paddingLeft: '8px' }}
                  onClick={() => setCountrySelection('NG', 'en')}
                >
                  Nigeria
                </span>
              </CountryItem>
            )}
            {selectedCountry !== 'GH' && (
              <CountryItem>
                <img
                  src={GhanaI}
                  alt=""
                  onClick={() => setCountrySelection('GH', 'en')}
                />
                <span
                  style={{ paddingLeft: '8px' }}
                  onClick={() => setCountrySelection('GH', 'en')}
                >
                  Ghana
                </span>
              </CountryItem>
            )}
            {selectedCountry !== 'KE' && (
              <CountryItem>
                <img
                  src={KenyaI}
                  alt=""
                  onClick={() => setCountrySelection('KE', 'en')}
                />
                <span
                  style={{ paddingLeft: '8px' }}
                  onClick={() => setCountrySelection('KE', 'en')}
                >
                  Kenya
                </span>
              </CountryItem>
            )}

            {selectedCountry !== 'CI' && (
              <CountryItem>
                <img
                  src={Civ}
                  alt=""
                  onClick={() => setCountrySelection('CI', 'fr')}
                />
                <span
                  style={{ paddingLeft: '8px' }}
                  onClick={() => setCountrySelection('CI', 'fr')}
                >
                  {' '}
                  Côte d'Ivoire
                </span>
              </CountryItem>
            )}

            {selectedCountry !== 'UG' && (
              <CountryItem>
                <img
                  src={Uganda}
                  alt=""
                  onClick={() => setCountrySelection('UG', 'en')}
                />
                <span
                  style={{ paddingLeft: '8px' }}
                  onClick={() => setCountrySelection('UG', 'en')}
                >
                  {' '}
                  Uganda
                </span>
              </CountryItem>
            )}
          </CountryPopOver>
        </Popover>
        <LogoutLink>
          <Link to="/logout">
            <PowerSettingsNewIcon style={{ color: 'white' }} />
          </Link>
        </LogoutLink>
      </Drawer>
      <ChildrenWrap>{children}</ChildrenWrap>
    </div>
  )
}

export default withRouter(MiniDrawer)
