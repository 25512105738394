import React, { useState, useEffect } from 'react'
import { Modal, Form, Input, InputNumber, Button, Upload, message } from 'antd'
import { StyledEquityModal } from './styles'
import api from '../../../utils/api'
import { getUserToken } from '../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IEquityModal {
  isModalVisible: boolean
  setIsModalVisible: any
  singleEquity: any
  refetch: any
  singleOffer: any
}
interface IDetailParam {
  id: string
}

const API_URL = process.env.REACT_APP_API_URL

export default function EquityModal({
  isModalVisible,
  setIsModalVisible,
  singleEquity,
  refetch,
  singleOffer
}: IEquityModal) {
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      amount_paid: singleOffer?.amountRequested
    })
  }, [singleOffer])

  const onFinish = async (values: any) => {
    const data = {
      id: singleEquity.id,
      amount_paid: values.amount_paid,
      file_url: values.upload[0].response.file.url
    }

    try {
      const response = await api.updateEquity(token, data, singleEquity.id)
      if (response) {
        dispatch(showSnackbar('Equity uploaded successfully', 'success'))
        setIsModalVisible(false)
      }
    } catch (err) {
      dispatch(showSnackbar('Error uploading equity', 'error'))
    }

    refetch()
  }

  const onCancel = () => {
    setIsModalVisible(false)
  }

  const props = {
    name: 'file',
    multiple: false,
    action: `${API_URL}/document/upload`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e: any) {
      // console.log('Dropped files', e.dataTransfer.files)
    }
  }

  const normFile = (e: any) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  return (
    <Modal
      footer={null}
      closable={false}
      visible={isModalVisible}
      width="440px"
    >
      <StyledEquityModal>
        <div className="equityHeader mb-2">{t('equityPayment')}</div>
        <Form
          form={form}
          name="basic"
          layout="vertical"
          onFinish={onFinish}
          requiredMark={false}
        >
          <Form.Item
            label={t('amountPaid')}
            name="amount_paid"
            rules={[
              { required: true, message: t('pleaseInputYourAmountPaid') }
            ]}
          >
            <InputNumber disabled />
          </Form.Item>

          <Form.Item label={t('uploadProofOfPayment')}>
            <Form.Item
              name="upload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
            >
              <Upload.Dragger {...props} name="file">
                <p className="ant-upload-text">{t('upload')}</p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>

          <Form.Item>
            <div className="d-flex justify-content-center">
              <Button
                className="buttonCancel me-3"
                type="primary"
                onClick={onCancel}
              >
                {t('Cancel')}
              </Button>
              <Button className="button" type="primary" htmlType="submit">
                {t('submit')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </StyledEquityModal>
    </Modal>
  )
}
