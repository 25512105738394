import React from 'react'

const EmailIcon = () => {
  return (
    <svg
      width={16}
      height={12}
      viewBox="0 0 16 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 1.16699H2C1.44772 1.16699 1 1.61471 1 2.16699V10.167C1 10.7193 1.44772 11.167 2 11.167H14C14.5523 11.167 15 10.7193 15 10.167V2.16699C15 1.61471 14.5523 1.16699 14 1.16699Z"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.7746 1.53369L9.34523 5.70969C8.95971 6.00626 8.48696 6.16706 8.00056 6.16706C7.51417 6.16706 7.04142 6.00626 6.6559 5.70969L1.22656 1.53369"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EmailIcon
