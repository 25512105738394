import React, { useEffect } from 'react'
import { getUserToken } from '../../../store/auth/selectors'
import api from '../../../utils/api'
import OffersUpload from '../../PortfolioManagement/PortfolioDetails/OffersUpload'
import { StyledNoUpload } from '../../PortfolioManagement/PortfolioDetails/styles'
import { StyledCustomerDocument } from './styles'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface IDetailParam {
  id: string
}

export default function OffersDocument() {
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: fetchOffer } = useQuery(
    'fetchOffer',
    () => api.fetchOffers(token, id),
    {
      onSuccess: () => {}
    }
  )

  const { data: singleLoan, status, refetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    { keepPreviousData: true, staleTime: 5000 }
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
    }
  }, [singleLoan, queryClient, id])

  const acceptedOffer = singleLoan?.offer == undefined ? 0 : 1

  return (
    <StyledNoUpload>
      <div className="uploadWrapper">
        {fetchOffer?.offers == undefined || fetchOffer?.offers.length == 0 ? (
          ''
        ) : (
          <div className="heads" style={{ position: 'absolute' }}>
            {`${t('offers')}(${fetchOffer?.offers?.length || 0})`} :{' '}
            {t('Accepted')}({acceptedOffer})
          </div>
        )}

        <OffersUpload />
      </div>
    </StyledNoUpload>
  )
}

{
}
