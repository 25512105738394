import { connect } from 'react-redux'
import { logout } from '../../../store/auth/actions'
import Logout from './Logout'
import { Props } from './types'

const mapDispatchToProps = (dispatch: any) => ({
  logout: (history: Object) => dispatch(logout(history))
})

export default connect<{}, Props>(null, mapDispatchToProps)(Logout)
