import React from 'react'
import styled from 'styled-components'
import { Switch, Redirect, Route } from 'react-router-dom'
import WithSideMenu from '../../components/WithSideMenu'
import Categories from './Categories'
import MessageList from './MessageList'
import ChatView from './ChatView'
import EmptyConversation from './EmptyConversation'

const ConversationsWrapper = styled.div`
  display: flex;
`

export interface IConversationsMatchParams {
  status: 'queue' | 'active' | 'closed' | 'all'
  recipientPhone: string
}

export default function Conversations() {
  return (
    <WithSideMenu>
      <ConversationsWrapper>
        <Categories />
        <Switch>
          <Route exact path="/conversations">
            <Redirect to="/conversations/all" />
          </Route>
          <Route exact path={`/conversations/:status`}>
            <MessageList />
            <EmptyConversation />
          </Route>
          <Route exact path={`/conversations/:status/:recipientPhone`}>
            <MessageList />
            <ChatView />
          </Route>
          <Route>
            <Redirect to={'/'} />
          </Route>
        </Switch>
      </ConversationsWrapper>
    </WithSideMenu>
  )
}
