import React, { useEffect, useState } from 'react'
import Popover from '@material-ui/core/Popover'
import {
  AgentInfo,
  AgentName,
  AvailableAgents,
  AvailableAgent,
  DropDownIcon,
  AvailableAgentDropDown
} from './styles'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../utils/api'
import { getSelectedCountry, getUserToken } from '../../../store/auth/selectors'
import { useHistory } from 'react-router'
import { showSnackbar } from '../../../store/snackbar/actions'

interface IAgentDetails {
  assignedAgent: {
    id: string
    AgentName: string
  }
  recipient: string
}

export default function AgentDetails({
  assignedAgent,
  recipient
}: IAgentDetails) {
  const dispatch = useDispatch()
  const [agents, setAgents] = useState([])
  const token = useSelector(getUserToken)
  const country = useSelector(getSelectedCountry)
  const history = useHistory()
  const [anchorEl, setAnchorEl] = React.useState(null)

  useEffect(() => {
    const fetchWhatsAppAgents = async () => {
      try {
        const agents = await api.fetchWhatsAppAgents(token, country)
        if (agents.length > 0) {
          setAgents(agents)
        }
      } catch (e) {
        dispatch(showSnackbar('Unable to fetch agents', 'error'))
      }
    }

    fetchWhatsAppAgents()
  }, [dispatch, token, country])

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleReAssignAgent = async (id: string) => {
    try {
      const response = await api.reassignConversation(token, recipient, id)

      if (response) {
        history.replace('/conversations/all')
      }
    } catch (e) {
      dispatch(showSnackbar('Unable to reassign conversation', 'error'))
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <AgentInfo>
        Agent:&nbsp;
        <AvailableAgentDropDown onClick={handleClick}>
          <AgentName>{assignedAgent?.AgentName}</AgentName>
          <DropDownIcon />
        </AvailableAgentDropDown>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <AvailableAgents>
            {agents
              .filter(
                (agent: { userId: string; name: string }) =>
                  agent.userId !== assignedAgent?.id
              )
              .map((agent: { userId: string; name: string }) => {
                return (
                  <AvailableAgent
                    key={agent.userId}
                    onClick={() => handleReAssignAgent(agent.userId)}
                  >
                    {agent?.name}
                  </AvailableAgent>
                )
              })}
          </AvailableAgents>
        </Popover>
      </AgentInfo>
    </div>
  )
}
