export const africanCountries = [
    { id: 4, country: "Algeria", countryCode: "DZ", currency: "DZD" },
    { id: 7, country: "Angola", countryCode: "AO", currency: "AOA" },
    { id: 24, country: "Benin", countryCode: "BJ", currency: "XOF" },
    { id: 29, country: "Botswana", countryCode: "BW", currency: "BWP" },
    { id: 35, country: "Burkina Faso", countryCode: "BF", currency: "XOF" },
    { id: 36, country: "Burundi", countryCode: "BI", currency: "BIF" },
    { id: 40, country: "Cape Verde", countryCode: "CV", currency: "CVE" },
    {
      id: 42,
      country: "Central African Republic",
      countryCode: "CF",
      currency: "XAF",
    },
    { id: 49, country: "Comoros", countryCode: "KM", currency: "KMF" },
    { id: 50, country: "Congo", countryCode: "CG", currency: "XAF" },
    {
      id: 51,
      country: "Congo The Democratic Republic Of The",
      countryCode: "CD",
      currency: "CDF",
    },
    {
      id: 54,
      country: "Cote D'Ivoire (Ivory Coast)",
      countryCode: "CI",
      currency: "XOF",
    },
    { id: 60, country: "Djibouti", countryCode: "DJ", currency: "DJF" },
    { id: 65, country: "Egypt", countryCode: "EG", currency: "EGP" },
    { id: 67, country: "Equatorial Guinea", countryCode: "GQ", currency: "XAF" },
    { id: 68, country: "Eritrea", countryCode: "ER", currency: "ERN" },
    { id: 70, country: "Ethiopia", countryCode: "ET", currency: "ETB" },
    { id: 79, country: "Gabon", countryCode: "GA", currency: "XAF" },
    { id: 80, country: "Gambia The", countryCode: "GM", currency: "GMD" },
    { id: 82, country: "Ghana", countryCode: "GH", currency: "GHS" },
    { id: 92, country: "Guinea", countryCode: "GN", currency: "GNF" },
    { id: 93, country: "Guinea-Bissau", countryCode: "GW", currency: "XOF" },
    { id: 113, country: "Kenya", countryCode: "KE", currency: "KES" },
    { id: 123, country: "Lesotho", countryCode: "LS", currency: "LSL" },
    { id: 124, country: "Liberia", countryCode: "LR", currency: "LRD" },
    { id: 125, country: "Libya", countryCode: "LY", currency: "LYD" },
    { id: 131, country: "Madagascar", countryCode: "MG", currency: "MGA" },
    { id: 132, country: "Malawi", countryCode: "MW", currency: "MWK" },
    { id: 135, country: "Mali", countryCode: "ML", currency: "XOF" },
    { id: 140, country: "Mauritania", countryCode: "MR", currency: "MRO" },
    { id: 141, country: "Mauritius", countryCode: "MU", currency: "MUR" },
    { id: 150, country: "Morocco", countryCode: "MA", currency: "MAD" },
    { id: 151, country: "Mozambique", countryCode: "MZ", currency: "MZN" },
    { id: 153, country: "Namibia", countryCode: "NA", currency: "NAD" },
    { id: 161, country: "Niger", countryCode: "NE", currency: "XOF" },
    { id: 162, country: "Nigeria", countryCode: "NG", currency: "NGN" },
    { id: 181, country: "Reunion", countryCode: "RE", currency: "EUR" },
    { id: 184, country: "Rwanda", countryCode: "RW", currency: "RWF" },
    { id: 185, country: "Saint Helena", countryCode: "SH", currency: "SHP" },
    {
      id: 194,
      country: "Sao Tome and Principe",
      countryCode: "ST",
      currency: "STD",
    },
    { id: 196, country: "Senegal", countryCode: "SN", currency: "XOF" },
    { id: 198, country: "Seychelles", countryCode: "SC", currency: "SCR" },
    { id: 199, country: "Sierra Leone", countryCode: "SL", currency: "SLL" },
    { id: 204, country: "Somalia", countryCode: "SO", currency: "SOS" },
    { id: 205, country: "South Africa", countryCode: "ZA", currency: "ZAR" },
    { id: 207, country: "South Sudan", countryCode: "SS", currency: "SSP" },
    { id: 210, country: "Sudan", countryCode: "SD", currency: "SDG" },
    { id: 213, country: "Swaziland", countryCode: "SZ", currency: "SZL" },
    { id: 219, country: "Tanzania", countryCode: "TZ", currency: "TZS" },
    { id: 221, country: "Togo", countryCode: "TG", currency: "XOF" },
    { id: 225, country: "Tunisia", countryCode: "TN", currency: "TND" },
    { id: 230, country: "Uganda", countryCode: "UG", currency: "UGX" },
    { id: 245, country: "Western Sahara", countryCode: "EH", currency: "MAD" },
    { id: 247, country: "Zambia", countryCode: "ZM", currency: "ZMW" },
    { id: 248, country: "Zimbabwe", countryCode: "ZW	", currency: "ZWL" },
  ];

export const getCountryCode = (country: string) => {
  if (country === 'GH') {
    return '+233'
  } 
  else if (country === 'KE') {
    return '+254'
  } 
  else if (country === 'CI') {
    return '+225'
  } 
  else if (country === 'UG') {
    return '+256'
  } 
  
  else {
    return '+234'
  }
}