import React, { useState } from 'react'
import { Modal } from 'antd'
import { Tabs, Tab } from 'react-bootstrap'
import NewCallForm from './NewCallForm'
import CommentForm from './CommentForm'
import { StyledLogActivity } from './styles'
import { useTranslation } from 'react-i18next'

interface ILModal {
  isModalVisible: boolean
  setIsModalVisible: any
}

export default function LogActivity({
  isModalVisible,
  setIsModalVisible
}: ILModal) {
  const { t } = useTranslation()
  const [key, setKey] = useState('new_call')

  return (
    <Modal
      width="440px"
      visible={isModalVisible}
      closable={false}
      footer={null}
    >
      <StyledLogActivity>
        <header>{t('logActivity')}</header>
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k: any) => setKey(k)}
          variant="pills"
          className="mb-3"
        >
          <Tab eventKey="new_call" title={t('newCall')}>
            <NewCallForm setIsModalVisible={setIsModalVisible} />
          </Tab>
          <Tab eventKey="comment" title={t('comment')}>
            <CommentForm setIsModalVisible={setIsModalVisible} />
          </Tab>
        </Tabs>
      </StyledLogActivity>
    </Modal>
  )
}
