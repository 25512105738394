import React from 'react'

export const GaugeIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.15693 3.88672C8.09414 3.89475 7.04333 4.11206 6.06452 4.52623C5.08572 4.9404 4.19808 5.54332 3.4523 6.30057C2.70653 7.05781 2.11722 7.95454 1.71803 8.93956C1.31885 9.92457 1.1176 10.9786 1.12579 12.0414V13.5241C1.12579 13.8081 1.23861 14.0805 1.43943 14.2813C1.64024 14.4821 1.91261 14.5949 2.19661 14.5949H16.1173C16.4013 14.5949 16.6736 14.4821 16.8744 14.2813C17.0753 14.0805 17.1881 13.8081 17.1881 13.5241V12.0392C17.204 9.89318 16.3669 7.82867 14.8608 6.29981C13.3547 4.77094 11.303 3.90294 9.15693 3.88672Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.0152 14.594V14.0586C7.0152 13.4906 7.24083 12.9459 7.64247 12.5443C8.0441 12.1426 8.58884 11.917 9.15684 11.917C9.72483 11.917 10.2696 12.1426 10.6712 12.5443C11.0728 12.9459 11.2985 13.4906 11.2985 14.0586V14.594"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.763 7.10059L9.15674 11.9193"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.07044 11.3818C4.01749 11.3818 3.96573 11.3975 3.92171 11.427C3.87769 11.4564 3.84337 11.4982 3.82311 11.5471C3.80285 11.596 3.79755 11.6498 3.80788 11.7018C3.81821 11.7537 3.8437 11.8014 3.88114 11.8388C3.91858 11.8763 3.96628 11.9018 4.01821 11.9121C4.07014 11.9224 4.12397 11.9171 4.17289 11.8969C4.2218 11.8766 4.26361 11.8423 4.29303 11.7983C4.32244 11.7542 4.33814 11.7025 4.33814 11.6495C4.33814 11.5785 4.30994 11.5104 4.25974 11.4602C4.20953 11.41 4.14144 11.3818 4.07044 11.3818Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.14118 8.70703C5.08823 8.70703 5.03647 8.72273 4.99245 8.75215C4.94843 8.78156 4.91411 8.82337 4.89385 8.87229C4.87359 8.92121 4.86829 8.97503 4.87862 9.02696C4.88895 9.07889 4.91444 9.12659 4.95188 9.16403C4.98932 9.20147 5.03702 9.22697 5.08895 9.2373C5.14088 9.24763 5.19471 9.24232 5.24362 9.22206C5.29254 9.2018 5.33435 9.16749 5.36377 9.12346C5.39318 9.07944 5.40888 9.02768 5.40888 8.97474C5.40888 8.90374 5.38068 8.83564 5.33047 8.78544C5.28027 8.73524 5.21218 8.70703 5.14118 8.70703Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.243 11.3818C14.1901 11.3818 14.1383 11.3975 14.0943 11.427C14.0503 11.4564 14.016 11.4982 13.9957 11.5471C13.9755 11.596 13.9702 11.6498 13.9805 11.7018C13.9908 11.7537 14.0163 11.8014 14.0538 11.8388C14.0912 11.8763 14.1389 11.9018 14.1908 11.9121C14.2427 11.9224 14.2966 11.9171 14.3455 11.8969C14.3944 11.8766 14.4362 11.8423 14.4656 11.7983C14.495 11.7542 14.5107 11.7025 14.5107 11.6495C14.5107 11.5785 14.4825 11.5104 14.4323 11.4602C14.3821 11.41 14.314 11.3818 14.243 11.3818Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.1723 8.70703C13.1194 8.70703 13.0676 8.72273 13.0236 8.75215C12.9796 8.78156 12.9452 8.82337 12.925 8.87229C12.9047 8.92121 12.8994 8.97503 12.9097 9.02696C12.9201 9.07889 12.9456 9.12659 12.983 9.16403C13.0204 9.20147 13.0682 9.22697 13.1201 9.2373C13.172 9.24763 13.2258 9.24232 13.2748 9.22206C13.3237 9.2018 13.3655 9.16749 13.3949 9.12346C13.4243 9.07944 13.44 9.02768 13.44 8.97474C13.44 8.90374 13.4118 8.83564 13.3616 8.78544C13.3114 8.73524 13.2433 8.70703 13.1723 8.70703Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.2829 6.56543C7.22996 6.56543 7.1782 6.58113 7.13417 6.61055C7.09015 6.63996 7.05584 6.68177 7.03558 6.73069C7.01531 6.7796 7.01001 6.83343 7.02034 6.88536C7.03067 6.93729 7.05617 6.98499 7.09361 7.02243C7.13105 7.05987 7.17875 7.08537 7.23068 7.09569C7.28261 7.10602 7.33643 7.10072 7.38535 7.08046C7.43427 7.0602 7.47608 7.02589 7.50549 6.98186C7.53491 6.93784 7.55061 6.88608 7.55061 6.83313C7.55061 6.76213 7.5224 6.69404 7.4722 6.64384C7.42199 6.59363 7.3539 6.56543 7.2829 6.56543Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const LocationIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.86094 7.63289C10.0437 7.63289 11.0026 6.67404 11.0026 5.49125C11.0026 4.30845 10.0437 3.34961 8.86094 3.34961C7.67814 3.34961 6.7193 4.30845 6.7193 5.49125C6.7193 6.67404 7.67814 7.63289 8.86094 7.63289Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.86097 1.20801C9.99697 1.20801 11.0864 1.65928 11.8897 2.46255C12.693 3.26582 13.1443 4.35529 13.1443 5.49128C13.1443 7.39877 10.3601 11.1031 9.28074 12.4666C9.23062 12.53 9.16678 12.5813 9.09402 12.6165C9.02126 12.6518 8.94146 12.6701 8.86062 12.6701C8.77977 12.6701 8.69997 12.6518 8.62721 12.6165C8.55445 12.5813 8.49062 12.53 8.4405 12.4666C7.36183 11.1024 4.5777 7.39877 4.5777 5.49128C4.5777 4.35529 5.02897 3.26582 5.83224 2.46255C6.63551 1.65928 7.72498 1.20801 8.86097 1.20801V1.20801Z"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M4.04233 10.668C2.72166 11.2519 1.9007 12.075 1.9007 12.9859C1.9007 14.7599 5.01678 16.1984 8.86102 16.1984H9.39643"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.686 10.6719C15.0024 11.2558 15.8212 12.0775 15.8212 12.987C15.8212 14.2263 14.3006 15.3021 12.0733 15.8375"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.3255 17.2705L9.39632 16.1997L8.3255 15.1289"
      stroke="#30355B"
      stroke-width="1.15414"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const ConditionIcon = (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1211 1.74414H15.7982C16.0822 1.74414 16.3545 1.85696 16.5554 2.05778C16.7562 2.25859 16.869 2.53096 16.869 2.81496V11.3815C16.869 12.8015 16.3049 14.1633 15.3008 15.1674C14.2967 16.1715 12.9349 16.7356 11.5149 16.7356H6.1608C4.7408 16.7356 3.37897 16.1715 2.37488 15.1674C1.37079 14.1633 0.806702 12.8015 0.806702 11.3815V2.81496C0.806702 2.53096 0.91952 2.25859 1.12034 2.05778C1.32115 1.85696 1.59352 1.74414 1.87752 1.74414H4.55457"
      stroke="#30355B"
      stroke-width="1.21184"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M6.16064 9.77441H7.23146"
      stroke="#30355B"
      stroke-width="1.21184"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.444 9.77441H11.5148"
      stroke="#30355B"
      stroke-width="1.21184"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.08966 11.916V12.9868"
      stroke="#30355B"
      stroke-width="1.21184"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12.5855 11.916V12.9868"
      stroke="#30355B"
      stroke-width="1.21184"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.4782 8.96974L12.5858 7.63336L11.7591 5.15334C11.6881 4.94008 11.5518 4.75459 11.3694 4.62313C11.1871 4.49167 10.9681 4.42092 10.7433 4.4209H6.93259C6.70782 4.42092 6.48875 4.49167 6.30643 4.62313C6.1241 4.75459 5.98777 4.94008 5.91674 5.15334L5.09007 7.63336L4.19772 8.96974C4.08044 9.14562 4.01785 9.35229 4.01782 9.56369V10.8487C4.01782 11.1327 4.13064 11.405 4.33146 11.6059C4.53228 11.8067 4.80464 11.9195 5.08864 11.9195H12.5844C12.8684 11.9195 13.1407 11.8067 13.3416 11.6059C13.5424 11.405 13.6552 11.1327 13.6552 10.8487V9.56369C13.656 9.35258 13.5944 9.14594 13.4782 8.96974V8.96974Z"
      stroke="#30355B"
      stroke-width="1.21184"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M5.0896 7.63281H12.5853"
      stroke="#30355B"
      stroke-width="1.21184"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)

export const InfoIcon = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#30355B" />
    <path
      d="M14.8736 8.81818H13.2379L13.3672 16.0717H14.7443L14.8736 8.81818ZM14.0533 19.0945C14.5952 19.0945 15.0476 18.652 15.0476 18.1001C15.0476 17.5582 14.5952 17.1108 14.0533 17.1108C13.5064 17.1108 13.0589 17.5582 13.0589 18.1001C13.0589 18.652 13.5064 19.0945 14.0533 19.0945Z"
      fill="white"
    />
  </svg>
)

export const RatingIcon = (
  <svg
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.84138 1.13023L9.59529 4.91884H13.0076C13.1796 4.91207 13.3496 4.95874 13.4942 5.05246C13.6389 5.14618 13.7511 5.28239 13.8155 5.44245C13.88 5.60252 13.8936 5.77864 13.8544 5.94674C13.8153 6.11484 13.7253 6.26672 13.5967 6.38161L10.6394 9.30259L12.2807 13.0809C12.3524 13.2539 12.3681 13.4451 12.3258 13.6275C12.2835 13.8099 12.1852 13.9745 12.0447 14.098C11.9043 14.2216 11.7287 14.2979 11.5428 14.3163C11.3568 14.3347 11.1698 14.2942 11.008 14.2005L7.04177 11.9648L3.07102 14.2005C2.9092 14.2942 2.72217 14.3347 2.53621 14.3163C2.35025 14.2979 2.17471 14.2216 2.03426 14.098C1.89381 13.9745 1.79551 13.8099 1.75318 13.6275C1.71085 13.4451 1.72661 13.2539 1.79825 13.0809L3.43955 9.30487L0.482248 6.38389C0.352697 6.26985 0.261547 6.1184 0.221318 5.95035C0.181088 5.7823 0.193752 5.60588 0.25757 5.44534C0.321388 5.28479 0.43323 5.14798 0.577735 5.05371C0.722239 4.95944 0.892317 4.91232 1.06461 4.91884H4.47688L6.22851 1.13023C6.3055 0.982577 6.42137 0.858882 6.56354 0.77257C6.7057 0.686258 6.86874 0.640625 7.03495 0.640625C7.20116 0.640625 7.36419 0.686258 7.50636 0.77257C7.64853 0.858882 7.76439 0.982577 7.84138 1.13023Z"
      fill="#FFB619"
    />
  </svg>
)

export const InactiveIcon = (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="26"
      height="26"
      rx="2.5"
      fill="white"
      stroke="black"
    />
  </svg>
)

export const ActiveIcon = (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="26"
      height="26"
      rx="2.5"
      fill="#30355B"
      stroke="#30355B"
    />
    <rect
      x="17.7998"
      y="7.14429"
      width="3.34867"
      height="15.069"
      transform="rotate(45 17.7998 7.14429)"
      fill="white"
    />
    <rect
      x="20.167"
      y="17.7988"
      width="3.34867"
      height="15.069"
      transform="rotate(135 20.167 17.7988)"
      fill="white"
    />
  </svg>
)

export const GreenTick = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#00AC61" />
    <path d="M8 14L11.5 17.5L21.5 10" stroke="white" stroke-width="2" />
  </svg>
)

export const PendingTick = (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="14" cy="14" r="14" fill="#FFB619" />
    <path
      d="M16.4986 13.0533V14.3409H11.9844V13.0533H16.4986Z"
      fill="#30355B"
    />
  </svg>
)

export const InfoTick = (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="10" cy="10" r="10" fill="#FFB800" />
    <path
      d="M10.3365 9.162C10.3081 9.01435 10.1943 8.92823 10.0047 8.92823C9.81517 8.92823 9.7109 9.00205 9.67299 9.162C9.50237 10.0232 9.36967 10.9214 9.28436 11.8688C9.19905 12.8161 9.15166 13.6159 9.15166 14.2556C9.15166 14.4525 9.21801 14.6124 9.33175 14.7478C9.4455 14.8708 9.58768 14.9569 9.75829 14.9815C9.92891 15.0062 10.09 15.0062 10.2607 14.9815C10.4313 14.9446 10.5735 14.8585 10.6872 14.7232C10.8009 14.5878 10.8578 14.4279 10.8578 14.2433C10.8578 13.6774 10.8104 12.9023 10.7156 11.9303C10.6209 10.946 10.4976 10.0232 10.3365 9.162ZM10.0047 6C9.72038 6 9.47393 6.09843 9.28436 6.29528C9.09479 6.49214 9 6.77512 9 7.15653C9 7.52563 9.09479 7.82092 9.28436 8.01777C9.47393 8.21463 9.72038 8.32536 10.0047 8.32536C10.2986 8.32536 10.5355 8.21463 10.7251 8.01777C10.9147 7.82092 11 7.52563 11 7.15653C11 6.77512 10.9147 6.49214 10.7251 6.29528C10.5355 6.09843 10.2986 6 10.0047 6Z"
      fill="white"
    />
  </svg>
)

export const CancelButton = (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.75 0.75L4.5 4.49935L8.25 0.75L0.75 8.25L4.50031 4.5L8.25 8.25"
      stroke="white"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
)
