export const SHOW_VEHICLE_MODAL = 'SHOW_VEHICLE_MODAL'
export const HIDE_VEHICLE_MODAL = 'HIDE_VEHICLE_MODAL'

export const SHOW_SELL_CAR_MODAL = 'SHOW_SELL_CAR_MODAL'
export const HIDE_SELL_CAR_MODAL = 'HIDE_SELL_CAR_MODAL'

export const showVehicleModal = () => ({
  type: SHOW_VEHICLE_MODAL
})

export const hideVehicleModal = () => ({
  type: HIDE_VEHICLE_MODAL
})


export const showSellCarModal = () => ({
  type: SHOW_SELL_CAR_MODAL
})

export const hideSellCarModal = () => ({
  type: HIDE_SELL_CAR_MODAL
})
