

export type ILoanActivitiesTypes =
  | 'carSourcing'
  | 'carsAwaitingPpi'
  | 'customerAwaitingOffer'
  | 'customerInspectionPending'
  | 'disbursedLoans'
  | 'disbursementPending'
  | 'documentReview'

export interface ILoanDetails {
  carSourcing: string
  carsAwaitingPpi: string
  customerAwaitingOffer: string
  customerInspectionPending: string
  disbursedLoans: string
  disbursementPending: string
  documentReview: string
}
export const LOANACTIVITIESENUM = {
  customerAwaitingOffer: 'APPLICATION_COMPLETED',
  documentReview: 'DOCUMENT_ACCEPTED',
  carSourcing: 'CAR_SOURCING',
  carsAwaitingPpi: 'PPI_IN_PROGRESS',
  customerInspectionPending: 'CUSTOMER_INSPECTION',
  disbursementPending: 'EQUITY_PAID',
  disbursedLoans: 'DISBURSED'
}
