import React, { useState, useEffect } from 'react'
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Checkbox,
  Select,
  message,
  Spin
} from 'antd'
import api from '../../../utils/api'
import { getUserToken, getSelectedCountry } from '../../../store/auth/selectors'
import { useMutation, useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { StyledNotAvailable } from './styles'
import { CAR_STATUS } from '../AvailableCar/constants'
import { useTranslation } from 'react-i18next'
import { getPartnerId } from '../../../utils/helpers'

export default function NotAvailable({
  setInspOutcome,
  modalData,
  refetch,
  singleLoan,
  loanRefresh
}: any) {
  const [form] = Form.useForm()
  const { Option } = Select
  const [checked, setChecked] = useState(false)
  const [submitting, setSubmitting] = useState<boolean>(false)
  const token = useSelector(getUserToken)
  const queryClient = useQueryClient()
  const country = useSelector(getSelectedCountry)
  const { t } = useTranslation()
  const partnerId = getPartnerId(process.env.REACT_APP_API_URL, country)

  const updateSchedule: any = useMutation(
    (payload) => api.updateScheduleActivity(token, modalData.id, payload),
    {
      onSuccess: (__data) => {
        queryClient.invalidateQueries('book inspection')
        setSubmitting(false)
        message.success('Inspection booking updated successfully')
        setInspOutcome(false)
        refetch()
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(
          error?.response.data.error ||
            'Uh oh, something went wrong, please try again'
        )
      }
    }
  )
  useEffect(() => {
    form.setFieldsValue({
      make: singleLoan?.car?.model?.make?.name,
      model: singleLoan?.car?.model?.name,
      year: singleLoan?.car?.year
    })
  }, [singleLoan])

  const createCarSourcing: any = useMutation(
    (payload) => api.createCarSourcing(token, payload),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('car-sourcing')
        setSubmitting(false)
        message.success('Car sourcing  successfully')
        setInspOutcome(false)
        handleUpdate(res?.id)
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(error?.response.data.error)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )
  const updateLoan: any = useMutation(
    (payload) => api.updateCarLoan(token, singleLoan?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loan-update')
        message.success('Loan updated')
        loanRefresh()
      },
      onError: (error: any) => {
        message.error(error?.response.data.error)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  const DECLINE_REASONS = [
    'The car not meeting expectation',
    'Issues of the vehicle',
    'Accidental history',
    'Engine issues',
    'Change in price of the vehicle'
  ]

  const handleUpdate = async (source_id: any) => {
    const payload = {
      status: CAR_STATUS.CAR_SOURCING,
      car_id: singleLoan?.carId,
      source_id: source_id
    }
    await updateLoan.mutateAsync(payload)
  }

  const onFinish = async (values: any) => {
    setSubmitting(true)

    const schedulePayloadData = {
      id: modalData.id,
      completed: true,
      show: true,
      country: country
    }
    const sourcingPayload = {
      min_price: values?.price,
      max_price: values?.price,
      min_year: singleLoan?.car?.year,
      max_year: values?.year,
      source_target: 1,
      model_id: singleLoan?.car?.model?.id,
      city: singleLoan?.car?.city,
      state: singleLoan?.car?.state,
      country: singleLoan?.car?.country,
      partner_id: partnerId, // fWYvOKJmBthis is autochek b2b partner id
      condition: values?.vehicleCondition
    }
    await updateSchedule.mutateAsync(schedulePayloadData)
    await createCarSourcing.mutateAsync(sourcingPayload)
  }

  const handleCancel = () => {
    setInspOutcome(false)
  }

  return (
    <StyledNotAvailable>
      <div className="header">{t('sourceAlternativeCar')}</div>
      <div className="text mb-3">{t('fillDetailsSourceCar')}</div>
      <div className="vehicleHeader mb-2">{t('vehicleDetails')}</div>

      <Form
        form={form}
        layout="vertical"
        name="horizontal_login"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="make"
              label={t('make')}
              rules={[{ required: true, message: t('pleaseEnterCarMake') }]}
            >
              <Input placeholder="Enter Car Make" disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="model"
              label={t('model')}
              rules={[{ required: true, message: t('pleaseEnterCarModel') }]}
            >
              <Input placeholder={t('enterCarModel')} disabled />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="year"
              label={t('year')}
              rules={[{ required: true, message: t('pleaseEnterCarYear') }]}
            >
              <Input placeholder={t('enterCarYear')} type="number" disabled />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="price"
              label={t('price')}
              rules={[{ required: true, message: t('pleaseEnterCarPrice') }]}
            >
              <Input placeholder={t('enterCarPrice')} type="number" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="vehicleCondition"
          label={t('vehicleCondition')}
          rules={[
            { required: true, message: t('pleaseEnterVehicleCondition') }
          ]}
        >
          <Input.TextArea rows={4} placeholder={t('comment')} />
        </Form.Item>

        {/* <Form.Item
          name="customerInterested"
          valuePropName="checked"
          // rules={[
          //   {
          //     validator: (_, value) =>
          //       value
          //         ? Promise.resolve()
          //         : Promise.reject(new Error('Please choose interest'))
          //   }
          // ]}
        >
          <Checkbox
            checked={checked}
            value={checked}
            onChange={(e) => setChecked(e.target.checked)}
          >
            Customer not interested
          </Checkbox>
        </Form.Item> */}

        {checked && (
          <>
            <Form.Item
              name="reason"
              label={t('reasonForNotInterested')}
              rules={[{ required: true, message: t('pleaseSelectReason') }]}
            >
              <Select placeholder={t('selectYourReason')}>
                {DECLINE_REASONS.map((reason, i) => (
                  <Option key={i} value={reason}>
                    {reason}
                  </Option>
                ))}
                <Option value="other">{t('others')}</Option>
              </Select>
            </Form.Item>

            <Form.Item name="comment" label={t('additionalCommentOptional')}>
              <Input.TextArea rows={4} placeholder={t('comment')} />
            </Form.Item>
          </>
        )}

        <Form.Item>
          <div className="d-flex justify-content-center">
            {submitting ? (
              <Spin />
            ) : (
              <>
                <Button
                  className="cancel me-3"
                  type="primary"
                  onClick={handleCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button className="submit" type="primary" htmlType="submit">
                  {t('save')}
                </Button>
              </>
            )}
          </div>
        </Form.Item>
      </Form>
    </StyledNotAvailable>
  )
}
