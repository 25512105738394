import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import PencilWriteI from '../../../images/pencil-write.svg'

export const MessageListWrapper = styled.div`
  min-width: 252px;
  height: 100vh;
  overflow: scroll;
  background: ${({ theme }) => theme.palette.background.default};
`

export const LoadingMoreConvos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  color: #474f58;
  font-weight: bold;
`

export const MessageListObserverTarget = styled.div`
  height: 5px;
`

export const MessageListTitle = styled.div`
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  padding: 0 20px;
  padding-top: 24px;
  padding-bottom: 10px;

  p {
    font-size: 20px;
    flex-grow: 1;
    margin: 0;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.palette.neutral.grayishBlue};
    background-clip: text;
    background: linear-gradient(to right, #474F58 40%, rgba(255, 255, 255, 0) 80% );
      no-repeat;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`

export const NoSearchResults = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #474f58;
`

export const MessageGroupDate = styled.div`
  margin-top: 13px;
  margin-bottom: 5px;
  padding-left: 24px;
  color: ${({ theme }) => theme.palette.neutral.heather2};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const MessageGroup = styled.ul`
  padding: 0;
  margin: 0;
`

export const MessageListItem = styled.li<{ active?: boolean }>`
  list-style: none;
  background: ${({ theme, active }) =>
    active
      ? theme.palette.neutral.whiteLilac
      : theme.palette.background.default};
  border-bottom: 1px solid #cad0d961;
  cursor: pointer;

  &:hover {
    background: ${({ active }) => (active ? '#C6C1D06b' : '#C6C1D01A')};
  }
`

export const StyledMessageLink = styled(Link)`
  display: flex;
  padding: 10px 16px;
  text-decoration: none;
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};

  p {
    font-size: ${({ theme }) => theme.font.size.sm};
    padding: 0;
    margin: 0;
  }
`

export const MessageListItemInitials = styled.div<{ active?: boolean }>`
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  align-items: center;
  color: ${({ theme }) => theme.palette.background.default};
  font-size: ${({ theme }) => theme.font.size.sm};
  background: ${({ theme, active }) =>
    active
      ? theme.palette.neutral.grayChateau
      : theme.palette.neutral.cyanBluishGray};
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
`

export const MessageListRight = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`

export const MessageListItemTop = styled.div<{
  active?: boolean
  read?: boolean
}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.md};
  ${({ read }) => (read ? '' : 'font-weight: bolder;')}
  padding-bottom: 5px;
  color: ${({ theme, active }) =>
    active ? theme.palette.common.black : theme.palette.neutral.grayishBlue};

  span:last-of-type {
    margin-left: 5px;
    font-size: ${({ theme }) => theme.font.size.xs};
  }
`

export const LastMessage = styled.div<{ read: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    ${({ read }) => (read ? '' : 'font-weight: bolder')}
  }
`

export const UnreadIndicator = styled.div`
  margin-left: 5px;
  width: 12px;
  height: 12px;
  background-color: #4b9ff2;
  border-radius: 50%;
`

export const EditNoteIcon = styled.div`
  height: 20px;
  width: 20px;
  background-image: url(${PencilWriteI});
  background-size: 20px 20px;
`
