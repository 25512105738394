import React from 'react'
import { StyledNotificationModal } from './styles'
import { Modal, Button } from 'antd'
import {
  InfoTick,
  CancelButton
} from '../../views/LeadManagement/AvailableCar/svg'
import api from '../../utils/api'
import { getUserToken, getUserId } from '../../store/auth/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { showSnackbar } from '../../store/snackbar/actions'

interface INotification {
  isNotificationVisible: any
  setIsNotificationVisible: any
  fetchNotifications: any
  refetch: any
}

export default function NotificationModal({
  isNotificationVisible,
  setIsNotificationVisible,
  fetchNotifications,
  refetch
}: INotification) {
  const token = useSelector(getUserToken)
  const userId = useSelector(getUserId)
  const dispatch = useDispatch()
  let history = useHistory()
  let location = useLocation()
  const { t } = useTranslation()

  function timeConvert(num: any) {
    let todayDate = new Date().getTime() / (1000 * 60)
    let createdDate = new Date(num).getTime() / (1000 * 60)
    let timeCreated = todayDate - createdDate

    let d = Math.floor(timeCreated / 1440) // 60*24
    let h = Math.floor((timeCreated - d * 1440) / 60)
    let m = Math.round(timeCreated % 60)

    if (d > 0) {
      return `${d || 0} days, ${h || 0} hours, ${m || 0} minutes`
    } else {
      return `${h || 0} hours, ${m || 0} minutes`
    }
  }

  const handleCancel = () => {
    setIsNotificationVisible(false)
  }

  const allUnread = fetchNotifications?.data?.filter(function (item: any) {
    return item?.read == false
  })

  const handleClick = async (i: any) => {
    let _newArray = { ...fetchNotifications?.data }
    _newArray[i].read = true

    const _data = {
      id: _newArray[i].id,
      read: _newArray[i].read
    }

    try {
      const response = await api.updateOriginationNotif(token, _data.id, _data)
      if (response) {
        dispatch(showSnackbar('Notification read', 'success'))
        if (
          location.pathname == '/lead-dashboard' ||
          location.pathname == '/completed-loans'
        ) {
          history.push(`completed-loans/${_newArray[i]?.leadId}`)
        }
        if (
          location.pathname == '/portfolio-mgt' ||
          location.pathname == '/portfolio-loans'
        ) {
          history.push(`portfolio-loans/${_newArray[i]?.leadId}`)
        }
      }
    } catch (e) {
      dispatch(showSnackbar('Error reading notification', 'error'))
    }
    refetch()
  }

  return (
    <Modal
      title={t('pendingNotification')}
      visible={isNotificationVisible}
      onCancel={handleCancel}
      footer={null}
      closeIcon={CancelButton}
    >
      <StyledNotificationModal>
        {fetchNotifications?.data?.length == 0 ? (
          <div className="contentContainer">{t('noPendingNotification')}</div>
        ) : (
          <>
            {allUnread?.map((el: any, index: any) => (
              <div className="contentContainer" key={index}>
                <div className="contentWrapper d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <div>{InfoTick}</div>
                    <div className="ms-2">
                      <div className="name">{el?.content}</div>
                      <div className="time">
                        {timeConvert(el?.createdAt)} {t('ago')}
                      </div>
                    </div>
                  </div>
                  <div className="view" onClick={() => handleClick(index)}>
                    {t('view')}
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </StyledNotificationModal>
    </Modal>
  )
}
