import styled from 'styled-components'

export const StyledCarAvailable = styled.div`
  form {
    label {
      font-size: 14px;
      font-weight: 500;
      color: #30355b;
    }
  }
  .thirdStep {
    background: #fff;
    border-radius: 10px;
    padding: 20px 20px 15px 20px;
    .title {
      color: #8c8f99;
      font-weight: 600;
      font-size: 12px;
    }
    .carImage {
      width: 186px;
      height: 120px;
      border-radius: 6px;
      background: transparent;
      overflow: hidden;
    }
    .carDetail {
      .name {
        color: #30355b;
        font-weight: 600;
        font-size: 16px;
      }
      .rate {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        .ant-rate {
          font-size: 16px;
        }
      }
      .detail {
        div {
          font-weight: 300;
          font-size: 11px;
          color: #30355b;
        }
      }
      .value {
        font-weight: 600;
        font-size: 16px;
        color: #30355b;
      }
    }
    .carAvailability {
      .options {
        font-size: 14px;
        color: #000000;
      }
      .yesOption,
      .noOption {
        font-weight: 500;
        font-size: 14px;
        color: #30355b;
      }
      .ant-switch-checked {
        background-color: #fff;
      }
      .ant-switch {
        background-color: #fff;
        border: 1px solid #30355b;
      }
      .ant-switch-handle::before {
        background-color: #30355b;
      }
      .ant-switch-handle {
        top: 1px;
      }
    }
    .franchiseDetail {
      .name {
        font-size: 14px;
        font-weight: 600;
        color: #30355b;
      }
      .content {
        font-size: 14px;
        font-weight: 500;
        color: #30355b;
      }
    }
    .centralizeButton {
      /* position: absolute; */
      position: relative;
      left: 0;
      right: 0;
      /* margin: 0 auto; */
      max-width: fit-content;
      /* width: 500px; */
    }
    .schedule {
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      min-width: 187px;
      width: auto;
      height: 44px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }
    .scheduleInProgress {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #e5f0ff;
      border-radius: 40px;
      min-width: 228px;
      width: auto;
      height: 44px;
      border: 0;
    }
    .sourceCar {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      min-width: 140px;
      width: auto;
      height: 44px;
      border: 0;
      background: #d6d7de;
      box-shadow: 0px 4px 8px rgba(96, 97, 112, 0.16);
      border-radius: 5px;
    }
    .carSourcingComplete {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: rgba(0, 172, 97, 0.1);
      border-radius: 40px;
      min-width: 218px;
      width: auto;
      height: 44px;
      border: 0;
    }
    .bookInspection,
    .inspectionOutcome {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      min-width: 187px;
      width: auto;
      height: 44px;
      border: 0;
      margin-top: 75px;
    }
    .customerInspComplete {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: rgba(0, 172, 97, 0.1);
      border-radius: 40px;
      min-width: 272px;
      width: auto;
      height: 44px;
      border: 0;
    }
  }

  .modalWrapper {
    .title {
      font-weight: 600;
      font-size: 16px;
      color: #30355b;
    }
    .text {
      color: #555770;
      font-size: 14px;
    }
    .content {
      color: #333333;
      font-size: 14px;
    }
    .cancel {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
    }
    .saveButton {
      background: #ffb619;
      border-radius: 5px;
      border: 0;
      min-width: 186px;
      width: auto;
      height: 44px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }
  }
`
