import styled from 'styled-components'

export const ResetPasswordText = styled.div`
  height: 24px;
  text-align: center;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 24px;
  margin-bottom: 41px;
`

export const ResetBoxContent = styled.div`
  padding: 40px 40px 62px;
`

export const PasswordMatchNotice = styled.div`
  color: red;
  font-size: ${({ theme }) => theme.font.size.xs};
  margin-top: 5px;
`
