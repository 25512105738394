import { IRootState } from '../reducer.interface'
import { IActivity, IPagination, IServiceRendered } from './reducer'

export const eventMap: any = {
  'Phone call': 'PHONE_CALL',
  'Log - Phone call': 'LOG_PHONE_CALL',
  Appointment: 'APPOINTMENT',
  Reminder: 'REMINDER',
  'Store visit': 'STORE_VISIT'
}

export const getEvents = (state: IRootState) => eventMap[state.events.event]
export const getTabValue = (state: IRootState) => state.events.tab
export const getSearchQuery = (state: IRootState) => state.events.search
export const getSchedules = (
  state: IRootState
): {
  completed: IActivity[]
  pending: IActivity[]
  pagination: IPagination
} => {
  const { completed, pending, pagination } = state.events
  return {
    pagination,
    completed,
    pending
  }
}
export const getLocationOptions = (state: IRootState) =>
  state.leads.locations.locations.map((location: any) => ({
    value: location.id,
    label: `${location.name}, ${location.displayAddress}, ${location.city}, ${location.state} State`
  }))

export const getServiceOptions = (state: IRootState) =>
  state.events.serviceRendered.services.map((service: IServiceRendered) => ({
    value: service.id,
    label: service.name
  }))
