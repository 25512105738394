import { EventTypes } from './actions'

export interface INote {
  id: number
  text: string
  authorId: string
  createdAt: string
  updatedAt: string
  comment:string
}
export interface carData {
  make: string
  year: string
  imageUrl: string
 
}

export interface IServiceRendered {
  id: string
  name: string
  updatedAt: string
  createdAt: string
}

export interface IActivity {
  note: INote[]
  id: string
  agentId: string
  leadId: string
  activityType: string
  date: string
  time: string
  completed: boolean
  createdAt: string
  updatedAt: string
  appointment:{
    carId:string
    type:string
    carData:{
      imageUrl:string
      make:string
      model:string
      year:string
      mileage:string
      fuelType:string
      gearType:string
      city:string
      state:string
      price:string
      owner: {
        id: string
        firstname: string
        lastname: string
        email: string
        primaryPhone: string
        createdBy: string
        country: string
        createdAt: string
        updatedAt: string
      },
      salesOfficer: {
        id: string
        firstname: string
        lastname: string
        email: string
        primaryPhone: string
        createdBy: string
        country: string
        cityId: string
        active: true
        createdAt: string
        updatedAt: string
      },
      franchise: {
       
        name: string
        owner: string
        email: string
        phonenumber: string
       
        ownerInfo: {
          id: string
          firstname: string
          lastname: string
          email: string
          primaryPhone: string
          country: string
    
          active: true
          createdAt: string
          updatedAt: string
        },
        displayAddress: string
        address: string
    }
    }
  }
  lead:{
    firstName:string
    lastName:string
    email:string
  }
  locationId: string
  serviceRenderedId: string
}

export interface IPagination {
  total: number
  currentPage: number
  pageSize: number
}

export interface IActivities {
  pending: IActivity[]
  completed: IActivity[]
  pagination: IPagination
  event: string
  tab: string
  search: string
  serviceRendered: {
    services: IServiceRendered[]
  }
  pauseScheduleEvent:boolean
}

interface ISetEvent {
  type: EventTypes.SET_EVENT
  payload: string
}

interface IAddActivity {
  type: EventTypes.ADD_ACTIVITY_SUCCESS
  payload: IActivity
}

interface IGetActivity {
  type: EventTypes.GET_COMPLETED_SCHEDULE | EventTypes.GET_PENDING_SCHEDULE
  payload: { schedule: IActivity[]; pagination: IPagination }
}

interface IUpdateActivity {
  type: EventTypes.UPDATE_SCHEDULE_SUCCESS
  payload: IActivity
}

interface ISetTab {
  type: EventTypes.SET_TAB
  payload: string
}

interface ISetSearch {
  type: EventTypes.SET_SEARCH_QUERY
  payload: string
}

interface IDeleteSchedule {
  type: EventTypes.DELETE_SCHEDULE_SUCCESS
  payload: string
}

interface IFetchServiceOptions {
  type: EventTypes.FETCH_SERVICE_OPTIONS_SUCCESS
  payload: { services: IServiceRendered[] }
}

export type TActions =
  | ISetEvent
  | IAddActivity
  | IGetActivity
  | IUpdateActivity
  | ISetTab
  | ISetSearch
  | IDeleteSchedule
  | IFetchServiceOptions

const initialState: IActivities = {
  completed: [],
  pending: [],
  pagination: {
    total: 0,
    currentPage: 1,
    pageSize: 10
  },
  event: '',
  tab: '',
  pauseScheduleEvent:true,
  search: '',
  serviceRendered: { services: [] }
}
const reducer = (
  state: IActivities = initialState,
  action: TActions
): IActivities => {
  switch (action.type) {
    case EventTypes.SET_EVENT: {
      return {
        ...state,
        event: action.payload
      }
    }
    case EventTypes.SET_TAB: {
      return {
        ...state,
        tab: action.payload
      }
    }
    case EventTypes.FETCH_SERVICE_OPTIONS_SUCCESS: {
      return {
        ...state,
        serviceRendered: action.payload
      }
    }
    case EventTypes.SET_SEARCH_QUERY: {
      return {
        ...state,
        search: action.payload
      }
    }
    case EventTypes.ADD_ACTIVITY_SUCCESS: {
      if (action.payload.completed) {
        return {
          ...state,
          completed: [action.payload, ...state.completed],
          pauseScheduleEvent:false
        }
      }
      return {
        ...state,
        pending: [action.payload, ...state.pending]
      }
    }
    case EventTypes.GET_COMPLETED_SCHEDULE: {
      return {
        ...state,
        completed: action.payload.schedule,
        pagination: action.payload.pagination
      }
    }
    case EventTypes.GET_PENDING_SCHEDULE: {
      return {
        ...state,
        pending: action.payload.schedule
      }
    }
    case EventTypes.DELETE_SCHEDULE_SUCCESS: {
      return {
        ...state,
        pending: state.pending.filter((item) => item.id !== action.payload)
      }
    }
    case EventTypes.UPDATE_SCHEDULE_SUCCESS: {
      let activity: any = state.pending.find(
        (item) => item.id === action.payload.id
      )
      activity = [
        {
          ...activity,
          completed: true,
          note: [
            ...(action.payload.note.length
              ? action.payload.note
              : activity.note)
          ],
          updatedAt: action.payload.updatedAt
        }
      ]
      return {
        ...state,
        pending: state.pending.filter((item) => item.id !== action.payload.id),
        completed: [...activity, ...state.completed],
        pagination: {
          ...state.pagination,
          total: Number(state.pagination.total) + 1
        }
      }
    }
    default:
      return state
  }
}

export default reducer
