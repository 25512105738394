import React from 'react'
import {
  FormHelperText,
  Grid,
  InputBase,
  TextField,
  Button,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import CustomInput from './CustomInput'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { usePhoneStyles } from './styles'
import FormFooter from './FormFooter'
import {
  addSchedule,
  fetchSchedule,
  setEvent
} from '../../../../store/events/actions'

import {
  getLeadDetails,
  carAppointmentData,
  getUserTimeZone,
  salesOfficersData
} from '../../../../store/leads/selectors'
import {
  getEvents,
  getLocationOptions
} from '../../../../store/events/selectors'
import calendar from '../../../../images/calendar-dark.svg'
import { getSelectedCountry } from '../../../../store/auth/selectors'
import Select from 'react-select'
import { useTranslation } from 'react-i18next'

import {
  createAppointment,
  saveScheduleObject,
  cancelCarAppointmentData
} from '../../../../store/leads/actions'
import { serviceTypeOptions } from './lead.utils'
import { SearchContainerDiv } from './styles'

interface IProps {
  log?: boolean
}

const required = 'This field is required.'
const validationSchema = Yup.object().shape({
  date: Yup.string().required(required),
  time: Yup.string().required(required),
  note: Yup.string().required(required)
})
const initialValues = {
  date: '',
  time: '',
  note: ''
}

const MakeCarAppointment: React.FC<IProps> = ({ log }) => {
  const classes = usePhoneStyles()
  const lead = useSelector(getLeadDetails)
  const countryCode = useSelector(getSelectedCountry)
  const carAppointment: any = useSelector(carAppointmentData)
  const salesOfficers = useSelector(salesOfficersData)
  const [salesOfficersFromHook, setSalesOfficers] = React.useState([] as any)
  const [salesOfficerId, setSalesOfficerId] = React.useState('')

  getUserTimeZone()

  React.useEffect(() => {
    if (salesOfficers) {
      let salesOfficersArray: any = []
      let users = salesOfficers.filter(function (data: any) {
        salesOfficersArray.push({
          label: data.firstname + ' ' + data.lastname,
          value: data.id
        })

        return data
      })
      setSalesOfficers(salesOfficersArray)
    }
  }, [])
  const handleSaleOfficerChange = (e: any) => {
    setSalesOfficerId(e.value)
  }

  const event = useSelector(getEvents)
  const dispatch = useDispatch()
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,

    touched,
    errors
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const { date, time, note } = values

      // dispatch(createAppointment(AppointmentData))

      dispatch(
        addSchedule({
          leadId: lead.id,
          activityType: 'APPOINTMENT',
          completed: false,
          date,
          time: time + getUserTimeZone(),
          note,
          locationId: carAppointment.dropOffLocationId,
          appointmentId: carAppointment.id,
          sales_officer_id: salesOfficerId
        })
      )

      setTimeout(() => {
        dispatch(
          fetchSchedule(lead.id, {
            lead_id: lead.id,
            completed: false,
            pageSize: 5
          })
        )
      }, 2000)

      handleReset()
    }
  })
  const handleReset = () => {
    dispatch(setEvent(''))
    dispatch(cancelCarAppointmentData())
  }

  const { t } = useTranslation()
  return (
    <Grid container item xs={12}>
      <Grid container className={classes.root} justify="center">
        <Grid item xs={12} className={classes.titleWrapper}>
          <img src={calendar} alt="calendar" className={classes.icon} />
          <Typography variant="body1" className={classes.title}>
            {t('scheduleBuyCar')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={7} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          {t('Appointment type')}
        </Typography>
        <SearchContainerDiv>
          <input
            type="text"
            placeholder={t('searchInventory')}
            disabled
            value={'Test Drive'}
          />
          {/* <TextInput
              name="search"
              type="text"
              
              placeholder="Search inventory"
              onInputChange={(event) => saveSearchQuery(event)}
           
              {...inputProps}
            /> */}
        </SearchContainerDiv>
      </Grid>
      <Grid item xs={7} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          {t('carName')}
        </Typography>
        <SearchContainerDiv>
          <input
            type="text"
            placeholder={t('searchInventory')}
            value={`${
              carAppointment.carData.make +
              ' ' +
              carAppointment.carData.model +
              ' ' +
              carAppointment.carData.year
            }`}
            disabled
          />
          {/* <TextInput
              name="search"
              type="text"
              
              placeholder="Search inventory"
              onInputChange={(event) => saveSearchQuery(event)}
           
              {...inputProps}
            /> */}
        </SearchContainerDiv>

        <Typography
          variant="body2"
          style={{ marginTop: '20px' }}
          className={classes.label}
        >
          {t('selectSalesOfficer')}
        </Typography>
        <Select
          onChange={handleSaleOfficerChange}
          options={salesOfficersFromHook}
        />
      </Grid>
      <Grid item xs={12} className={classes.datetime}>
        <div>
          <Typography variant="body2" className={classes.label}>
            {t('Date')}
          </Typography>
          <TextField
            id="date"
            name="date"
            onBlur={handleBlur}
            error={!!(errors.date && touched.date)}
            helperText={touched.date && errors.date}
            value={values.date}
            onChange={handleChange}
            variant="outlined"
            type="date"
            inputProps={{
              min: log
                ? dayjs(new Date('2000-01-01')).format('YYYY-MM-DD')
                : dayjs(new Date()).format('YYYY-MM-DD')
            }}
          />
        </div>
        <div>
          <Typography variant="body2" className={classes.label}>
            {t('Time')}
          </Typography>
          <InputBase
            name="time"
            type="time"
            className={classes.timeInputWrapper}
            onBlur={handleBlur}
            error={!!(errors.time && touched.time)}
            value={values.time}
            onChange={handleChange}
          />
          {!!touched.time && (
            <FormHelperText error>{errors.time}</FormHelperText>
          )}
        </div>
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          {t('AddNote')}
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          onBlur={handleBlur}
          error={!!(errors.note && touched.note)}
          helperText={touched.note && errors.note}
          name="note"
          value={values.note}
          onChange={handleChange}
          id="note"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} className={classes.formFooter}>
        <Button variant="outlined" onClick={() => handleReset()}>
          {t('Cancel')}
        </Button>
        <Button
          variant="contained"
          disableElevation
          onClick={() => handleSubmit()}
        >
          {t('save')}
        </Button>
      </Grid>
    </Grid>
  )
}

export default MakeCarAppointment
