import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useTranslation } from 'react-i18next'

type CustomStylesType = {
  labelColour: string
}

const useStyles = makeStyles((theme) => ({
  selectRoot: {
    height: 40,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 0,
    paddingTop: 0
  },
  label: {
    height: 20,
    lineHeight: '21px',
    color: (customStyles: CustomStylesType) => customStyles.labelColour,
    fontSize: 14,
    marginTop: 6,
    marginBottom: 10
  },
  formControl: {
    margin: 0,
    minWidth: '100%'
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}))

type Props = {
  label: string
  options: {
    label: string
    value: string
  }[]

  value: string
  name: string
  onSelect: (event: any) => void
  labelColor?: string
}

const NativeSelects = ({
  label,
  options,
  onSelect,
  value,
  name,
  labelColor
}: Props) => {
  const labelColour = labelColor ? labelColor : '#8F99A6'
  const classes = useStyles({ labelColour })

  const { t } = useTranslation()

  return (
    <div>
      <InputLabel
        classes={{
          root: classes.label
        }}
        htmlFor={`outlined-${label}`}
      >
        {label}
      </InputLabel>
      <FormControl variant="outlined" className={classes.formControl}>
        <Select
          native
          value={value}
          onChange={onSelect}
          inputProps={{
            name,
            id: `outlined-${label}`
          }}
          classes={{
            root: classes.selectRoot
          }}
        >
          <option aria-label="None" value="" />
          {options.map((option, index) => (
            <option key={index} value={option.value}>{t(`${option.label}`)}</option>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}

export default NativeSelects
