import axios from 'axios'
import { createHTTPHeader } from './utils'
import { bankDealerSearch } from '../../store/leads/selectors'

import queryString from 'query-string'
import { africanCountries } from '../country.util'
import reduxStore from '../../store'
import { paramsObjectToQueryString } from '../paramObjectToQuery'

const { store } = reduxStore

const API_URL = process.env.REACT_APP_API_URL
const WHATSAPP_API_URL = process.env.REACT_APP_CRM_API_URL

const api = {
  signIn: (email, password) => {
    const url = `${API_URL}/v1/auth/login`
    let body = {
      email,
      password
    }

    return api.post(url, body)
  },
  resetPassword: (token, password, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/auth/update-password`
    let body = {
      password,
      id
    }

    return api.put(url, body, headers)
  },
  sendOTP: (payload) => {
    const url = `${API_URL}/v1/auth/login/otp`
    let body = {
      payload
    }

    return api.post(url, body)
  },
  verifyOTP: (token, otp) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/auth/login/otp/verify`
    let body = {
      otp
    }

    return api.post(url, body, headers)
  },
  upload: (token, file) => {
    const headers = createHTTPHeader(token)
    const uploadUrl = `${API_URL}/document/upload`
    const data = new FormData()

    data.append('file', file, file.name)
    data.append('public', 'true')
    data.append('whatsApp', 'true')

    return api.post(uploadUrl, data, headers)
  },
  updateUser: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/user/update_profile`
    const { firstname, lastname, email, primaryPhone, image_url } = details
    let body = {
      firstname,
      lastname,
      email,
      primaryPhone,
      image_url
    }

    return api.put(url, body, headers)
  },
  fetchLeads: (token, queries) => {
    const headers = createHTTPHeader(token)
    queries.is_loan_applicant = false

    const query = queryString.stringify(queries)
    const url = `${API_URL}/v1/crm/lead?${query}`

    return api.get(url, headers)
  },
  fetchLeadsFromBulk: (token, queries) => {
    const headers = createHTTPHeader(token)

    const query = queryString.stringify(queries)
    const url = `${API_URL}/v1/crm/lead?${query}`

    return api.get(url, headers)
  },

  fetchLead: (token, id) => {
    const headers = createHTTPHeader(token)
    const leadsUrl = `${API_URL}/v1/crm/lead/${id}`

    return api.get(leadsUrl, headers)
  },

  getCarByAdmin: (token, id) => {
    const headers = createHTTPHeader(token)
    const leadsUrl = `${API_URL}/v1/inventory/admin/car/${id}`
    return api.get(leadsUrl, headers)
  },

  getBanks: (token, country) => {
    const headers = createHTTPHeader(token)
    const leadsUrl = `${API_URL}/v1/origination/banks?country=${country}`

    return api.get(leadsUrl, headers)
  },

  createLead: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/lead`

    let mutatedObject = { ...details }

    if (mutatedObject.source === 'Bank' || mutatedObject.source === 'Dealer') {
      mutatedObject.source =
        mutatedObject.source + `- ${store.getState().leads.bankDealerSearch}`
    }

    return api.post(url, mutatedObject, headers)
  },

  updateLead: (token, details, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/lead/${id}`
    const body = details

    return api.put(url, body, headers)
  },
  updateDocument: (token, details, loanId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/documents/${loanId}`
    const body = details

    return api.put(url, body, headers)
  },
  updateWhatsAppAgent: (token, details, id) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/agent/${id}`
    const body = details

    return api.put(url, body, headers)
  },
  updateServiceInterest: (token, details, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/service-interest/${id}`
    const body = details

    return api.put(url, body, headers)
  },
  deleteServiceInterestFromIndex: (token, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/service-interest/${id}`

    return api.delete(url, headers)
  },
  linkLeadUser: (token, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/lead/${id}/link-user`

    return api.put(url, {}, headers)
  },
  fetchAgents: (token, country) => {
    let loanType = localStorage.getItem('loanType')
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent?country=${country}&type=${loanType}`

    return api.get(url, headers)
  },
  fetchServiceInterests: (token, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/lead/${id}/service-interest`

    return api.get(url, headers)
  },

  createAppointment: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/lead/appointment`

    return api.post(url, details, headers)
  },

  sourceCars: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car-request`
    return api.post(url, details, headers)
  },

  createDisbursement: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/disbursment`
    return api.post(url, details, headers)
  },

  requestDocument: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/documents/request`
    return api.post(url, details, headers)
  },

  registerAgent: (token, details, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/${id}`

    return api.put(url, details, headers)
  },

  registerUser: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/auth/register`

    return api.post(url, details, headers)
  },

  createActivity: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/activity`
    return api.post(url, details, headers)
  },

  requestEquity: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/equity`
    return api.post(url, details, headers)
  },

  updateEquity: (token, details, loanId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/equity/${loanId}`
    const body = details

    return api.put(url, body, headers)
  },

  getProformaInvoice: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/proforma-invoice`
    return api.post(url, details, headers)
  },

  bulkAgentTransfer: (token, details) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/bulk-assign`

    return api.post(url, details, headers)
  },
  fetchAgentRole: (token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/auth/role?search=CRM_AGENT`

    return api.get(url, headers)
  },
  addAgentRole: (token, details, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/auth/user/${id}/update_roles`
    return api.put(url, details, headers)
  },
  fetchSalesOfficer: (token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/user?role_name=SALES_OFFICER`

    return api.get(url, headers)
  },
  fetchLocations: (token, country) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/franchise/location?pageSize=300&country=${country}`

    return api.get(url, headers)
  },

  fetchLoanProgress: (token, loan_id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/loans/${loan_id}/progress`
    return api.get(url, headers)
  },

  createSchedule: (data, token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/schedule-activity`
    return api.post(url, data, headers)
  },
  getSchedule: (leadId, queries, token) => {
    const headers = createHTTPHeader(token)
    const query = queryString.stringify(queries)
    const url = `${API_URL}/v1/crm/schedules?${query}`
    return api.get(url, headers)
  },
  updateSchedule: (id, data, token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/activity/${id}`
    return api.put(url, data, headers)
  },
  deleteSchedule: (id, token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/activity/${id}`
    return api.delete(url, headers)
  },
  fetchServiceOptions: (token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/service`
    return api.get(url, headers)
  },
  fetchOriginationLoans: (token, country, pageSize, currentPage) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/loans?country=${country}&page_size=${pageSize}&current_page=${currentPage}`
    return api.get(url, headers)
  },

  fetchOriginationLoans2: (token, query) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/loans${paramsObjectToQueryString(
      query
    )}`
    return api.get(url, headers)
  },

  fetchAllCarOffers: (token, query) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/admin/car-offer${paramsObjectToQueryString(
      query
    )}`
    return api.get(url, headers)
  },

  fetchCrmOriginationNotif: (token, query) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/notifications${paramsObjectToQueryString(
      query
    )}`
    return api.get(url, headers)
  },

  updateOriginationNotif: (token, id, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/notification/${id}`
    return api.put(url, payload, headers)
  },

  fetchOriginAgents: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/user${paramsObjectToQueryString(payload)}`
    return api.get(url, headers)
  },

  fetchDasboardActivities: (token, country) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/dashboard?country=${country}`
    return api.get(url, headers)
  },

  fetchCarMakeOptions: (token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/make?page_size=200`
    return api.get(url, headers)
  },
  fetchCarBodyTypes: (token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/body_type`
    return api.get(url, headers)
  },
  fetchCarModels: (token, makeId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/model?make_id=${makeId}&page_size=1000`
    return api.get(url, headers)
  },
  fetchCountryId: (token, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/country/${id}`
    return api.get(url, headers)
  },
  fetchStates: (token, countryId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/state?country_id=${countryId}&page_size=100`
    return api.get(url, headers)
  },
  fetchUserLocation: (token) => {
    const headers = createHTTPHeader(token)

    const url = `${API_URL}/get-my-location`
    return api.get(url, headers)
  },
  fetchCity: (token, stateId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/city?state_id=${stateId}&page_size=100`
    return api.get(url, headers)
  },
  fetchCarData: (token, carId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/admin/car/${carId}`
    return api.get(url, headers)
  },
  fetchMarketPlaceCarData: (query, token) => {
    const headers = createHTTPHeader(token)
    const queries = queryString.stringify(query)
    const url = `${API_URL}/v1/inventory/car/search?${queries}`
    return api.get(url, headers)
  },
  fetchCarMedia: (token, carId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car_media?car_id=${carId}`

    return api.get(url, headers)
  },
  fetchOneCar: (token, carId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car/${carId}`

    return api.get(url, headers)
  },
  fetchBankId: (token, bankId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/loan/bank_admin/${bankId}`

    return api.get(url, headers)
  },
  fetchDocuments: (token, loanId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/documents?loan_id=${loanId}`
    return api.get(url, headers)
  },
  fetchOffers: (token, loanId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/offers?loan_id=${loanId}`
    return api.get(url, headers)
  },
  fetchSingleOriginationLoan: (token, loanId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/loans/${loanId}`
    return api.get(url, headers)
  },

  fetchB2bUser: (token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/user?role_name=B2B_ADMIN`
    return api.get(url, headers)
  },

  loanReminder: (token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/loan/reminder`

    return api.get(url, headers)
  },
  fetchSimilarCars: (token, carId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car/${carId}/similar`
    return api.get(url, headers)
  },
  fetchSimilarCarsWithFinancing: (token, carId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car/${carId}/similar?has_financing=true`
    return api.get(url, headers)
  },
  fetchCarInspectionDetails: (token, carId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car/${carId}/inspection/marketplace?full=true`
    return api.get(url, headers)
  },
  fetchNotes: (leadId, type, currentPage, token) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/note?leadId=${leadId}&type=${type}&page_size=3&currentPage=${currentPage}`
    return api.get(url, headers)
  },
  addNote: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/note`
    return api.post(url, payload, headers)
  },
  fetchUser: (token, userId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/user/${userId}`
    return api.get(url, headers)
  },
  fetchCarDealer: (token, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/franchise/${id}`
    return api.get(url, headers)
  },
  fetchCarDealers: (token, country, search) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/franchise?country=${country}&search=${search}`
    return api.get(url, headers)
  },
  getActivityType: (queryPassed, token) => {
    const headers = createHTTPHeader(token)
    const query = queryString.stringify(queryPassed)
    const url = `${API_URL}/v1/crm/schedules?${query}`
    return api.get(url, headers)
  },
  getAgentNotifications: (queryPassed, token) => {
    const headers = createHTTPHeader(token)
    const query = queryString.stringify(queryPassed)
    const url = `${API_URL}/v1/crm/notifications?${query}`
    return api.get(url, headers)
  },
  updateCrmNotif: (payload, id, token) => {
    const headers = createHTTPHeader(token)

    const url = `${API_URL}/v1/crm/notification/${id}`
    return api.put(url, payload, headers)
  },
  getTasksForAgent: (queryPassed, token) => {
    const headers = createHTTPHeader(token)
    const query = queryString.stringify(queryPassed)
    const url = `${API_URL}/v1/crm/schedules?${query}`
    return api.get(url, headers)
  },
  fetchMessageStats: (token, agentId, country) => {
    const headers = createHTTPHeader(token)
    const query = queryString.stringify({ agentId, country })
    const url = `${WHATSAPP_API_URL}/v1/api/conversation/stats?${query}`
    return api.get(url, headers)
  },
  fetchConversations: (token, status, page = 1, agentId, country, phone) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/whatsapp/conversation?status=${status}&currentPage=${page}&pageSize=20${
      agentId ? `&agentId=${agentId}` : ''
    }${country ? `&country=${country}` : ''}${phone ? `&phone=${phone}` : ''}`
    return api.get(url, headers)
  },
  fetchChatMessages: (token, recipient, page = 1) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/whatsapp/conversation/${recipient}?sort=desc${
      page ? `&currentPage=${page}` : ''
    }`
    return api.get(url, headers)
  },
  replyConversation: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/outbound/wa-conversation`
    return api.post(url, payload, headers)
  },
  updateConversation: (token, recipient, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/whatsapp/conversations/${recipient}`
    return api.put(url, payload, headers)
  },
  readConversation: (token, recipient) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/whatsapp/conversation/${recipient}/read`
    return api.post(url, {}, headers)
  },
  fetchWhatsAppAgents: (token, country) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/agent${
      country ? `?country=${country}` : ''
    }`
    return api.get(url, headers)
  },
  reassignConversation: (token, recipient, agentId) => {
    const payload = {
      agentId
    }
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/conversation/${recipient}/reassign`
    return api.post(url, payload, headers)
  },
  closeConversation: (token, recipientPhone) => {
    const headers = createHTTPHeader(token)
    const url = `${WHATSAPP_API_URL}/v1/api/whatsapp/close/conversation/${recipientPhone}`
    return api.put(url, {}, headers)
  },

  fetchLoans: (token, country, pageNumber = 1, agentId) => {
    let loanType = localStorage.getItem('loanType')

    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/lead?country=${country}&current_page=${pageNumber}&is_loan_applicant=${true}&assigned_agent_id=${agentId}&type=${loanType}`
    return api.get(url, headers)
  },
  fetchFilterLoans: (query, token) => {
    const headers = createHTTPHeader(token)
    query.is_loan_applicant = true
    let loanType = localStorage.getItem('loanType')
    if (!query.type) {
      query.type = loanType
    }
    const queries = queryString.stringify(query)
    const url = `${API_URL}/v1/crm/lead?${queries}`
    return api.get(url, headers)
  },

  getLeadFromLoanId: (token, payload, user_id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/map-user/${user_id}`
    return api.post(url, payload, headers)
  },
  fetchSingleLoan: (token, loanId) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/loans/${loanId}`
    return api.get(url, headers)
  },
  fetchLoanUser: (token, userId,country) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/loans?user_id=${userId}&country=${country}`
    return api.get(url, headers)
  },
  fetchPortfolioManagers: (token, country) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/user?country=${country}&role_name=PORTFOLIO_MANAGER`

    return api.get(url, headers)
  },
  fetchOriginationPartners: (token, country) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/partners?country=${country}`
    return api.get(url, headers)
  },
  assignPortManagerToPartner: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/portfolio-managers/${payload.user_ids}/assign`

    return api.put(url, payload, headers)
  },
  createCarSourcing: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car-request`

    return api.post(url, payload, headers)
  },

  getCarOffers: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/admin/car-offer${paramsObjectToQueryString(
      payload
    )}`

    return api.get(url, headers)
  },

  getMappedPortfolioManagers: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/portfolio-managers${paramsObjectToQueryString(
      payload
    )}`

    return api.get(url, headers)
  },

  getCar: (token, query) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/car${paramsObjectToQueryString(query)}`

    return api.get(url, headers)
  },
  createBookInspection: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/lead/appointment`

    return api.post(url, payload, headers)
  },
  getCarinspection: (token, query) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/inspection_request${paramsObjectToQueryString(
      query
    )}`

    return api.get(url, headers)
  },

  updateCarLoan: (token, loan_id, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/loans/${loan_id}/status`

    return api.put(url, payload, headers)
  },

  assignLeadManager: (token, user_id, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/lead-managers/${user_id}/assign`
    return api.put(url, payload, headers)
  },

  getInspectionWorkshop: (token, query) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/franchise/inspection_workshop${paramsObjectToQueryString(
      query
    )}`

    return api.get(url, headers)
  },

  createWarrantyRequest: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/inventory/inspection_request`

    return api.post(url, payload, headers)
  },

  createChatAgent: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/chat-agent`
    return api.post(url, payload, headers)
  },

  createAgent: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent`
    return api.post(url, payload, headers)
  },

  deleteChatAgent: (token, user_id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/chat-agent/${user_id}`
    return api.delete(url, headers)
  },

  deleteAgent: (token, id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/${id}`
    return api.delete(url, headers)
  },

  updateScheduleActivity: (token, id, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/crm/agent/activity/${id}`

    return api.put(url, payload, headers)
  },

  updatePortfolioPartner: (token, payload) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/portfolio-managers/${payload.user_ids}/assign`

    return api.put(url, payload, headers)
  },
  deletePortfolioPartner: (token, user_id) => {
    const headers = createHTTPHeader(token)
    const url = `${API_URL}/v1/origination/portfolio-manager/${user_id}`

    return api.delete(url, headers)
  },

  post: (url, body, headers = createHTTPHeader()) => {
    return axios.post(url, body, { headers }).then((response) => response.data)
  },
  put: (url, body, headers) => {
    return axios.put(url, body, { headers }).then((response) => response.data)
  },
  get: (url, headers) => {
    return axios.get(url, { headers }).then((response) => response.data)
  },
  delete: (url, headers) => {
    return axios.delete(url, { headers }).then((response) => response.data)
  }
}

export default api
