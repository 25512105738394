import React from 'react'
import { Modal, Button, Form, Input } from 'antd'
import { StyledNewCall } from './styles'
import { useParams } from 'react-router-dom'
import api from '../../../utils/api'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getUserToken } from '../../../store/auth/selectors'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}
interface INewCall {
  setIsModalVisible: any
}

export default function CommentForm({ setIsModalVisible }: INewCall) {
  const [form] = Form.useForm()
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const { t } = useTranslation()

  const onFinish = async (values: any) => {
    const data = { ...values, type: 'COMMENT', loan_id: id }

    try {
      const newCall = await api.createActivity(token, data)
      if (newCall) {
        dispatch(showSnackbar('Activity logged successfully', 'success'))
      }
    } catch (err) {
      dispatch(showSnackbar('Activity failed to be logged', 'error'))
    }

    setTimeout(() => {
      setIsModalVisible(false)
    }, 500)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <StyledNewCall>
      <Form
        form={form}
        layout="vertical"
        name="horizontal_login"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item
          name="title"
          label={t('title')}
          rules={[{ required: true, message: t('pleaseInputTitle') }]}
        >
          <Input placeholder={t('enterTitle')} />
        </Form.Item>

        <Form.Item
          name="comment"
          label={t('addComment')}
          rules={[{ required: true, message: t('pleaseInputComment') }]}
        >
          <Input.TextArea
            placeholder={t('typeSomething')}
            rows={4}
            maxLength={100}
          />
        </Form.Item>

        <Form.Item>
          <div className="d-flex mt-2">
            <Button
              className="cancel me-3"
              type="primary"
              onClick={handleCancel}
            >
              {t('Cancel')}
            </Button>
            <Button className="submit" type="primary" htmlType="submit">
              {t('save')}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </StyledNewCall>
  )
}
