import phone from '../../../../images/phone-forwarded.svg'
import calendar from '../../../../images/calendar-dark.svg'
import bell from '../../../../images/bell.svg'
import box from '../../../../images/box-dark.svg'
import email from '../../../../images/mail-dark.svg'
import sms from '../../../../images/message-square.svg'
import whatsapp from '../../../../images/whatsapp.svg'
import twitter from '../../../../images/twitter.svg'
import facebook from '../../../../images/facebook.svg'

interface IEntries {
  value: string
  icon: any
  event?: boolean
}
export interface IOptions {
  title: string
  handleClick: (value: string, event?: boolean) => void
  entries: IEntries[]
}

export const scheduleOptions: IOptions = {
  title: 'Select an activity',
  handleClick: () => {},
  entries: [
    {
      value: 'Phone call',
      icon: phone
    },
    {
      value: 'Appointment',
      icon: calendar
    },
    {
      value: 'Reminder',
      icon: bell
    }
  ]
}

export const eventOptions: IOptions = {
  title: 'Select an event',
  handleClick: () => {},
  entries: [
    {
      value: 'Store visit',
      icon: box
    },
    {
      value: 'Phone call',
      icon: phone,
      event: true
    }
  ]
}

export const commOptions: IOptions = {
  title: 'Select a channel',
  handleClick: () => {},
  entries: [
    {
      value: 'Email',
      icon: email
    },
    {
      value: 'SMS',
      icon: sms
    },
    // {
    //   value: 'Whatsapp',
    //   icon: whatsapp
    // },
    {
      value: 'Twitter',
      icon: twitter
    },
    {
      value: 'Facebook',
      icon: facebook
    }
  ]
}

export const serviceTypeOptions = [
  { value: 'SELL_CAR', label: 'Car sale inspection' },
  // { value: 'BUY_CAR', label: 'Car purchase inspection' },
  { value: 'FIX_CAR', label: 'Car fix inspection' }
]

export const serviceDetailOptions = [
  { value: 'Scheduled Service', label: 'Scheduled Service' },
  { value: 'Denting & Painting', label: 'Denting & Painting' },
  { value: 'Battery Services', label: 'Battery Services' },
  { value: 'AC Service & Repair', label: 'AC Service & Repair' },
  { value: 'Tyres & Wheelcare', label: 'Tyres & Wheelcare' },
  { value: 'Cleaning & Detailing', label: 'Cleaning & Detailing' },
  { value: 'Brakes and Clutch', label: 'Brakes and Clutch' },
  { value: 'Engine Maintenance', label: 'Engine Maintenance' },
  { value: 'Overheating Issues', label: 'Overheating Issues' }
]

export const gearTypeOptions = [
  { value: 'manual', label: 'Manual' },
  { value: 'automatic', label: 'Automatic' },
  { value: 'duplex', label: 'Duplex' }
]

export const fuelTypeOptions = [
  { value: 'petrol', label: 'Petrol' },
  { value: 'diesel', label: 'Diesel' },
  { value: 'hybrid', label: 'Hybrid' }
]
export const sourceOptions = [
  { value: 'imported', label: 'Imported' },
  { value: 'local', label: 'Local' },
]
export const deliveryOptions = [
  { value: 'pick_up', label: 'Pick up' },
  { value: 'drop_off', label: 'Drop off' },
]

export const years = [
  2020,
  2019,
  2018,
  2017,
  2016,
  2015,
  2014,
  2013,
  2012,
  2011,
  2010,
  2009,
  2008,
  2007,
  2006,
  2005,
  2004,
  2003,
  2002,
  2001,
  2000,
  1999,
  1998,
  1997,
  1996,
  1995,
  1994,
  1993,
  1992,
  1991,
  1990,
  1989,
  1988,
  1987,
  1986,
  1985,
  1984,
  1983,
  1982,
  1981,
  1980,
  1979,
  1978,
  1977,
  1976,
  1975,
  1974,
  1973,
  1972,
  1971,
  1970,
  1969,
  1968,
  1967,
  1966,
  1965,
  1964,
  1963,
  1962,
  1961,
  1960,
  1959,
  1958,
  1957,
  1956,
  1955,
  1954,
  1953,
  1952,
  1951,
  1950,
  1949,
  1948,
  1947,
  1946,
  1945,
  1944,
  1943,
  1942,
  1941,
  1940,
  1939,
  1938,
  1937,
  1936,
  1935,
  1934,
  1933,
  1932,
  1931,
  1930,
  1929,
  1928,
  1927,
  1926,
  1925,
  1924,
  1923,
  1922
]
