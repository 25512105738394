import React, { useState, useEffect } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import { useDispatch, useSelector } from 'react-redux'
import { StyledDashboard, SwitchSelect } from './styles'
import { Input, Button, Select, Badge } from 'antd'
// import { Input, Button, Table, Select } from 'antd'
import { Table } from 'react-bootstrap'
import {
  BellIcon,
  ChevronIcon,
  CompletedTIcon,
  DisbursedIcon,
  ArrowIcon
} from './svgs'
import Piechart from './piechart'
import { Link, useHistory } from 'react-router-dom'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  getUserToken,
  isManager,
  isAdmin,
  getUserFirstName,
  getUserLastName,
  getUserId,
  isPortfolio,
  isLead
} from '../../../store/auth/selectors'
import { useMutation, useQuery } from 'react-query'
import api from '../../../utils/api'
import Loader from '../../../components/Loader'
import { LOANACTIVITIESENUM } from './constant'
import NotificationModal from '../../../components/NotificationModal/index'
import { useTranslation } from 'react-i18next'

export default function PortfolioDashboard() {
  const token = useSelector(getUserToken)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const firstName = useSelector(getUserFirstName)
  const lastName = useSelector(getUserLastName)
  const userId = useSelector(getUserId)
  const userIsPortfolio = useSelector(isPortfolio)
  const userIsLead = useSelector(isLead)
  const userIsManager = useSelector(isManager)
  const userIsAdmin = useSelector(isAdmin)
  let history = useHistory()
  const { Option } = Select
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)
  const { t } = useTranslation()

  const { data: fetchDashboard, status, isLoading: dataLoading } = useQuery(
    ['fetchDashboard', country],
    () => api.fetchDasboardActivities(token, country),
    { keepPreviousData: true, staleTime: 5000 }
  )

  function percentageCompleted(completed: any, disbursed: any) {
    let total
    if (completed) {
      total = (completed / (completed + disbursed)) * 100
      return Number(total.toFixed(1))
    }
  }

  function percentageDisbursed(completed: any, disbursed: any) {
    let total
    if (disbursed) {
      total = (disbursed / (completed + disbursed)) * 100
      return Number(total.toFixed(1))
    }
  }

  const totalCompleted = percentageCompleted(
    fetchDashboard?.dashboard?.completedApplications,
    fetchDashboard?.dashboard?.disbursedStat?.count
  )

  const totalDisbursed = percentageDisbursed(
    fetchDashboard?.dashboard?.completedApplications,
    fetchDashboard?.dashboard?.disbursedStat?.count
  )

  const totalPercentage = Number(totalCompleted) + (Number(totalDisbursed) || 0)

  const handleSelectDashboard = (value: string) => {
    if (value == 'LM') {
      history.push(`/lead-dashboard`)
    }
    if (value == 'PM') {
      history.push(`/portfolio-mgt`)
    }
  }

  function getInitials(name: any) {
    const hasTokens = name.indexOf(' ') !== -1
    return (
      name.substring(0, hasTokens ? 1 : 2) +
      (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
    )
  }

  const {
    data: fetchNotifications,
    status: notificationStatus,
    refetch
  } = useQuery(['notification'], () =>
    api.fetchCrmOriginationNotif(token, {
      agent_id: userId, //'byU7SkIg0'
      read: false,
      type: 'loan'
    })
  )

  const showNotificationModal = () => {
    setIsNotificationVisible(true)
  }

  return (
    <WithSideMenu>
      <StyledDashboard>
        {dataLoading ? (
          <Loader />
        ) : (
          <>
            <div className="headerWrapper">
              <div className="userWrapper">
                {userIsPortfolio && country == 'NG' && (
                  <SwitchSelect className="me-5">
                    <Select
                      defaultValue="PM"
                      suffixIcon={ChevronIcon}
                      size="large"
                      onChange={handleSelectDashboard}
                    >
                      <Option value="LM">{t('leadsDashboard')}</Option>
                      <Option value="PM">{t('portfolioDashboard')}</Option>
                    </Select>
                  </SwitchSelect>
                )}

                <div className="users">
                  <div style={{ textAlign: 'right' }}>
                    <div className="name">
                      {firstName} {lastName}
                    </div>
                    <div className="role">
                      {userIsPortfolio && t('portfolioManager')}
                      {userIsLead && t('leadManager')}
                      {(userIsAdmin || userIsManager) && t('CRMManager')}
                    </div>
                  </div>
                  <div className="initials">
                    {getInitials(`${firstName} ${lastName}`)}
                  </div>
                </div>

                <div className="bell" onClick={() => showNotificationModal()}>
                  <Badge count={fetchNotifications?.data?.length || 0} showZero>
                    <div>{BellIcon}</div>
                  </Badge>
                </div>
              </div>
            </div>

            <NotificationModal
              isNotificationVisible={isNotificationVisible}
              setIsNotificationVisible={setIsNotificationVisible}
              fetchNotifications={fetchNotifications}
              refetch={refetch}
            />

            <div className="subHeadersWrapper">
              <div className="title">
                {t('PORTFOLIO MANAGER')} <br /> {t('DASHBOARD')}
              </div>
              <div>
                <Select
                  defaultValue="today"
                  style={{ width: 120 }}
                  suffixIcon={ChevronIcon}
                  size="large"
                  // onChange={handleChange}
                >
                  <Option value="today">{t('today')}</Option>
                  <Option value="week">{t('thisWeek')}</Option>
                  <Option value="month">{t('thisMonth')}</Option>
                </Select>
              </div>
            </div>

            <div className="containerWrapper">
              <div>
                <div className="dashboardCards">
                  <div
                    className="cardWrapper"
                    onClick={() => history.push('/portfolio-loans')}
                    style={{ cursor: 'pointer' }}
                  >
                    <div>{CompletedTIcon}</div>
                    <div style={{ marginLeft: '25px' }}>
                      <div className="title">{t('completedApplications')}</div>
                      <div className="valueWrap">
                        <div className="figures">
                          {fetchDashboard?.dashboard?.completedApplications}/
                          {fetchDashboard?.dashboard?.total}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="cardWrapper">
                    <div>{DisbursedIcon}</div>
                    <div style={{ marginLeft: '25px' }}>
                      <div className="title">{t('disbursedLoans')}</div>
                      <div className="valueWrap">
                        <div className="figures">
                          {fetchDashboard?.dashboard?.disbursedStat?.count}/
                          {fetchDashboard?.dashboard?.disbursedStat?.total}
                        </div>
                        <div className="value">
                          {t('value')}:<br /> {currency}
                          {Number(
                            fetchDashboard?.dashboard?.disbursedStat?.amount
                          ).toLocaleString() || 0}
                          M
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tableWrapper">
                  <div className="wrap">
                    <Table hover size="lg" className="bg-white">
                      <thead>
                        <tr>
                          <th>{t('loanActivities')}</th>
                          <th>{t('today')}</th>
                          <th>{t('thisWeek')}</th>
                          <th>{t('thisMonth')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(fetchDashboard?.activities.today)
                          .filter((key) => key.toLowerCase() !== 'total')
                          .map((keyName, i) => {
                            return (
                              <>
                                <tr
                                  onClick={() =>
                                    // @ts-ignore
                                    history.push(
                                      // @ts-ignore
                                      `/portfolio-loans?status=${LOANACTIVITIESENUM[keyName]}`
                                    )
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  <td style={{ textTransform: 'capitalize' }}>
                                    {/* {keyName.replace(/([a-z])([A-Z])/g,'$1 $2')} */}
                                    {t(`${keyName}`)}
                                  </td>

                                  <td>
                                    {fetchDashboard?.activities?.today[keyName]}
                                    /{fetchDashboard?.activities?.today?.total}
                                  </td>
                                  <td>
                                    {
                                      fetchDashboard?.activities?.thisWeek[
                                        keyName
                                      ]
                                    }
                                    /
                                    {
                                      fetchDashboard?.activities?.thisWeek
                                        ?.total
                                    }
                                  </td>
                                  <td>
                                    {
                                      fetchDashboard?.activities?.thisMonth[
                                        keyName
                                      ]
                                    }
                                    /
                                    {
                                      fetchDashboard?.activities?.thisMonth
                                        ?.total
                                    }
                                  </td>
                                </tr>
                              </>
                            )
                          })}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>

              <div className="chartsCard">
                <div className="title">{t('MoMPerformance')}</div>
                <Piechart fetchDashboard={fetchDashboard} />
                <div className="statsWrapper">
                  <div className="stats">
                    <div className="nameType">
                      <div className="purple"></div>
                      <div className="complete">
                        {t('completedApplications')}
                      </div>
                    </div>
                    <div className="value">
                      {fetchDashboard?.dashboard?.completedApplications}{' '}
                      <span>
                        (
                        {percentageCompleted(
                          fetchDashboard?.dashboard?.completedApplications,
                          fetchDashboard?.dashboard?.disbursedStat?.count
                        )}
                        %)
                      </span>
                    </div>
                  </div>
                  <div className="stats">
                    <div className="nameType">
                      <div className="yellow"></div>
                      <div className="complete">{t('disbursedLoans')}</div>
                    </div>
                    <div className="value">
                      {fetchDashboard?.dashboard?.disbursedStat?.count}{' '}
                      <span>
                        (
                        {percentageDisbursed(
                          fetchDashboard?.dashboard?.completedApplications,
                          fetchDashboard?.dashboard?.disbursedStat?.count
                        ) || 0}
                        %)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="percentage">
                  <div className="number">
                    {totalPercentage.toFixed(0) || 0}%
                  </div>
                  <div style={{ display: 'flex' }}>{ArrowIcon}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </StyledDashboard>
    </WithSideMenu>
  )
}
