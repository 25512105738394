import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { useDispatch, useSelector, connect } from 'react-redux'
import VehicleDetails from './VehicleDetails'
import SellCarDetails from './SellCarDetails'
import TextInput from '../../../../components/TextField'
import { setEvent } from '../../../../store/events/actions'
import Popover from '@material-ui/core/Popover'
import { Button, Divider, Grid } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import {
  showVehicleModal,
  showSellCarModal
} from '../../../../store/modal/actions'
import {
  getAllBuyCar,
  getSellOrFixCar,
  getAllMarketPlaceCars,
  createCarBuyAppointmentLoader,
  searchQueryFromStore,
  marketCarPagination,
  queryObject,
  getLeadDetails
} from '../../../../store/leads/selectors'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  isAgent
} from '../../../../store/auth/selectors'
import {
  saveCarAppointmentData,
  getCarData,
  startBuyLoader,
  getMarketPlaceCars,
  saveSearchQueryFromAction,
  deleteServiceInterest,
  saveQueryObject,
  getServiceInterests
} from '../../../../store/leads/actions'

import SubMenuPic from '../../../../images/menu-vert.svg'
import {
  StyledTabs,
  StyledAppBar,
  StyledTab,
  StyledBox,
  LeadDetailsSectionTitle
} from '../styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import {
  Container,
  SearchContainer,
  NoItemsContainer,
  NoItemsText,
  SearchBarIcon,
  SearchBarIconFlex,
  BuyCarItem,
  BuyCarItemDetails,
  BuyCarItemBottom,
  BuyCarItemImage,
  BuyCarItemName,
  BuyCarItemPrice,
  ViewCarDetails,
  TestDriveIndicator,
  DetailItemGaugeIcon,
  GasPumpIcon,
  CarGearIcon,
  PaidDeposit,
  SellCarImage,
  Flex,
  CarRetroIcon,
  SellCarItem,
  ServiceType,
  SellCount,
  FixCount,
  CarSearchContainer,
  CarSearchContainerChild,
  CarSearchContainerChildLatter,
  PersonIconThree,
  CarSearchContainerChildImage,
  Separator,
  SubMenu,
  SubMenuPicDiv,
  DropDownParent,
  SearchContainerDiv,
  LoaderCover,
  CarSearchContainerChildLatterForButton,
  PaginationCover
} from './styles'
import Pagination from '@material-ui/lab/Pagination'
import { useTranslation } from 'react-i18next'

import {
  createAppointment,
  saveScheduleObject,
  cancelCarAppointmentData
} from '../../../../store/leads/actions'
type TabPanelProps = {
  children?: React.ReactChild | React.ReactChild[]
  value: number
  index: number
}

const inputProps = {
  width: '100%',
  height: '32px',
  noBgColor: true,
  borderColor: '#E5E5E5',
  marginTop: '1px'
}

const MakeAppointmentPopOver = (prop: any) => {
  const dispatchData = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const deleteService = (id: any, interest: any) => {
    let idArray = interest.filter((data: any) => {
      return data.carId === id
    })
    dispatchData(deleteServiceInterest(idArray[0].id))
  }

  const saveCarData = (data: any) => {
    let carData = prop.interest.filter((car: any) => {
      return car.carId === data.id
    })

    dispatchData(saveCarAppointmentData(carData[0]))
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <img
        src={SubMenuPic}
        style={{ cursor: 'pointer' }}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <SubMenu onClick={() => saveCarData(prop.car)}>
          <span>Create buy car appointment</span>
        </SubMenu>
        <Separator></Separator>
        <SubMenu onClick={() => deleteService(prop.car.id, prop.interest)}>
          <span>Delete Interest</span>
        </SubMenu>
      </Popover>
    </div>
  )
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledBox p={3}>
          <Typography>{children}</Typography>
        </StyledBox>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minHeight: 380,
    backgroundColor: theme.palette.background.paper
  }
}))

const ServiceInterest = (prop: any) => {
  const [value, setValue] = React.useState(0)
  const [selectedCarId, setSelectedCarId] = React.useState('')
  const [serviceType, setServiceType] = React.useState('')
  const [searchQuery, setsearchQuery] = React.useState('')
  const [loaderId, setLoaderId] = React.useState('')
  const [dropDown, setDropDown] = React.useState(false)
  const classes = useStyles()

  const dispatch = useDispatch()
  const buyCars: any = useSelector(getAllBuyCar)
  const queryObjectFromStore: any = useSelector(queryObject)
  const leadDetail: any = useSelector(getLeadDetails)
  const carPagination: any = useSelector(marketCarPagination)
  const pageCount: any = Math.floor(
    carPagination.total / carPagination.pageSize
  )
  const searchValueFromStore: boolean = useSelector(searchQueryFromStore)
  const createBuyCarAppointment: any = useSelector(
    createCarBuyAppointmentLoader
  )
  const carsFromMarket: any = useSelector(getAllMarketPlaceCars)
  const [pageNumber, setPage] = React.useState(1)
  const [inMemoryCars, setinMemoryCars] = React.useState(carsFromMarket)
  const sellCars: any = useSelector((state) => getSellOrFixCar(state, 'sell'))
  const fixCars: any = useSelector((state) => getSellOrFixCar(state, 'fix'))
  const selectedCountry = useSelector(getSelectedCountry)
  const currency = useSelector(getSelectedCountryCurrency)

  const userIsAgent = useSelector(isAgent)

  React.useEffect(() => {
    const handleEsc = (event: any) => {
      if (event.keyCode === 27) {
        setDropDown(false)
      }
    }
    window.addEventListener('keydown', handleEsc)

    return () => {
      window.removeEventListener('keydown', handleEsc)
    }
  }, [])

  React.useEffect(() => {
    const timeoutId = setTimeout(
      () => dispatch(getMarketPlaceCars(queryObjectFromStore)),
      1000
    )
    return () => clearTimeout(timeoutId)
  }, [searchValueFromStore])

  const saveSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
    setsearchQuery(event.target.value)

    dispatch(saveSearchQueryFromAction(event.target.value))

    let query = {
      query: event.target.value,
      country: selectedCountry,
      page_number: carPagination.currentPage,
      page_size: carPagination.pageSize
    }

    dispatch(saveQueryObject(query))

    setDropDown(true)
  }

  const showModal = (carId: string) => {
    setSelectedCarId(carId)

    setTimeout(() => {
      dispatch(showVehicleModal())
    }, 200)
  }

  const showSellModal = (carId: string, type: string) => {
    setSelectedCarId(carId)
    setServiceType(type)

    dispatch(showSellCarModal())
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue)
  }

  const hideDropDown = (event: React.ChangeEvent<{}>) => {
    setDropDown(false)

    setsearchQuery('')
  }

  const getBuyCars = (cars: any) => {
    return cars.length > 0 ? (
      cars.map((car: any) => {
        return (
          <BuyCarItem key={car.id}>
            <div
              style={{
                backgroundImage: `url(${car.image})`,
                flexBasis: '30%',
                backgroundSize: 'cover',
                height: '135px',
                marginRight: '10px'
              }}
            ></div>
            {/* <BuyCarItemImage>


              <img src={car.image} alt="" />
            </BuyCarItemImage> */}
            <BuyCarItemDetails>
              <Flex>
                <BuyCarItemName>{car.name}</BuyCarItemName>
                {car.isTestDrive && (
                  <TestDriveIndicator>{car.isTestDrive}</TestDriveIndicator>
                )}

                {userIsAgent ? (
                  <MakeAppointmentPopOver
                    lead={leadDetail}
                    car={car}
                    interest={prop.leadServiceInterest}
                  />
                ) : null}
              </Flex>
              <BuyCarItemPrice>
                {currency} {car.price && car.price.toLocaleString()}
              </BuyCarItemPrice>
              <BuyCarItemPrice>{car.location}</BuyCarItemPrice>

              <BuyCarItemBottom>
                <ViewCarDetails onClick={() => showModal(car.id)}>
                  {t('viewDetails')}
                </ViewCarDetails>
                {car.depositReceived && <PaidDeposit />}
              </BuyCarItemBottom>
            </BuyCarItemDetails>
          </BuyCarItem>
        )
      })
    ) : (
      <NoItemsContainer>
        <SearchBarIconFlex>
          <SearchBarIcon />
        </SearchBarIconFlex>
        <NoItemsText>{t('addItemSearchbox')}</NoItemsText>
      </NoItemsContainer>
    )
  }

  const getSellCars = (cars: any) => {
    return cars.length > 0 ? (
      cars.map((car: any) => {
        return (
          <BuyCarItem key={car.id} isSell>
            <SellCarImage />
            <BuyCarItemDetails>
              <BuyCarItemName>{car.name}</BuyCarItemName>
              <Flex width="220px" marginBottom="8px" marginTop="8px">
                <SellCarItem>
                  <CarRetroIcon />
                  {car.imported === true
                    ? t('Foreign Used')
                    : t('Locally Used')}
                </SellCarItem>
                <SellCarItem>
                  <DetailItemGaugeIcon />
                  {car.mileage}
                </SellCarItem>
              </Flex>
              <Flex width="220px">
                <SellCarItem>
                  <CarGearIcon />
                  {car.gearType}
                </SellCarItem>
                <SellCarItem>
                  <GasPumpIcon />
                  {car.fuelType}
                </SellCarItem>
              </Flex>
              <BuyCarItemBottom>
                <ViewCarDetails onClick={() => showSellModal(car.id, 'sell')}>
                  {t('viewDetails')}
                </ViewCarDetails>
              </BuyCarItemBottom>
            </BuyCarItemDetails>
          </BuyCarItem>
        )
      })
    ) : (
      <NoItemsContainer>
        <SearchBarIconFlex></SearchBarIconFlex>
        <NoItemsText>{t('No Items')}</NoItemsText>
      </NoItemsContainer>
    )
  }

  const getFixCars = (cars: any) => {
    return cars.length > 0 ? (
      cars.map((car: any) => {
        return (
          <BuyCarItem key={car.id} isSell>
            <SellCarImage />
            <BuyCarItemDetails>
              <BuyCarItemName>{car.name}</BuyCarItemName>
              <Flex width="220px" marginBottom="8px" marginTop="8px">
                <SellCarItem>
                  <CarRetroIcon />
                  {car.imported === true
                    ? t('Foreign Used')
                    : t('Locally Used')}
                </SellCarItem>
                <SellCarItem>
                  <DetailItemGaugeIcon />
                  {car.mileage}
                </SellCarItem>
              </Flex>
              <Flex width="220px">
                <SellCarItem>
                  <CarGearIcon />
                  {car.gearType}
                </SellCarItem>
                <SellCarItem>
                  <GasPumpIcon />
                  {car.fuelType}
                </SellCarItem>
              </Flex>
              <BuyCarItemBottom>
                <ViewCarDetails onClick={() => showSellModal(car.id, 'fix')}>
                  {t('viewDetails')}
                </ViewCarDetails>
                <ServiceType>
                  {t('service')}: <span>{car.serviceDetail}</span>
                </ServiceType>
              </BuyCarItemBottom>
            </BuyCarItemDetails>
          </BuyCarItem>
        )
      })
    ) : (
      <NoItemsContainer>
        <SearchBarIconFlex></SearchBarIconFlex>
        <NoItemsText>{t('No Items')}</NoItemsText>
      </NoItemsContainer>
    )
  }

  const sellExists = sellCars.length
  const fixExists = fixCars.length

  const handleChange = (event: any, value: number) => {
    setPage(value)

    let query = {
      query: searchValueFromStore,
      country: selectedCountry,
      page_number: value,
      page_size: 10
    }
    dispatch(getMarketPlaceCars(query))
  }
  const saveCarDataFromSearch = (data: any, e: any) => {
    e.stopPropagation()

    dispatch(getCarData(data.id))
    dispatch(startBuyLoader())
    setLoaderId(data.id)
  }
  const showDropDown = (data: any, e: any) => {
    e.stopPropagation()
    setDropDown(true)
  }

  const { t } = useTranslation()

  return (
    <Container>
      <LeadDetailsSectionTitle>{t('servicesInterest')}</LeadDetailsSectionTitle>
      <div className={classes.root}>
        <StyledAppBar position="static">
          <StyledTabs
            value={value}
            onChange={handleTabChange}
            aria-label="simple tabs"
            TabIndicatorProps={{
              style: { background: '#4B9FF2', height: '2px' }
            }}
          >
            <StyledTab label={t('BUY CAR')} {...a11yProps(0)} />
            <StyledTab label={t('SELL CAR')} {...a11yProps(1)} />
            {sellExists > 0 && <SellCount>{sellExists}</SellCount>}
            <StyledTab label={t('FIX CAR')} {...a11yProps(2)} />
            {fixExists > 0 && <FixCount>{fixExists}</FixCount>}
            <StyledTab label={t('AFTERMARKET')} {...a11yProps(2)} />
          </StyledTabs>
        </StyledAppBar>
        <TabPanel value={value} index={0}>
          <ClickAwayListener onClickAway={hideDropDown}>
            <div>
              <SearchContainerDiv>
                <input
                  type="text"
                  placeholder={t('searchInventory')}
                  onChange={(event) => saveSearchQuery(event)}
                />
                {/* <TextInput
              name="search"
              type="text"
              
              placeholder="Search inventory"
              onInputChange={(event) => saveSearchQuery(event)}
           
              {...inputProps}
            /> */}
              </SearchContainerDiv>
              {dropDown ? (
                <DropDownParent>
                  {carsFromMarket ? (
                    <p></p>
                  ) : (
                    <LoaderCover>
                      <CircularProgress size={20} />
                    </LoaderCover>
                  )}

                  {carsFromMarket ? (
                    <CarSearchContainer>
                      {carsFromMarket.map((car: any, index: any) => (
                        <CarSearchContainerChild
                          key={car.id}
                          onClick={() => showDropDown}
                        >
                          <div
                            style={{
                              backgroundImage: `url(${car.imageUrl})`,
                              flexBasis: '30%',
                              backgroundSize: 'cover',
                              height: '135px',
                              marginRight: '10px'
                            }}
                          ></div>

                          <CarSearchContainerChildLatter>
                            <h4>
                              {car.title} {car.year}
                            </h4>
                            <p>
                              {currency} {car.marketplacePrice.toLocaleString()}
                            </p>
                            <p>
                              {t('location')} : {car.state}, {car.city}
                            </p>
                            <p>
                              {t('mileage')} : {car.mileage.toLocaleString()} Km
                            </p>

                            {car.hasFinancing ? (
                              <p>
                                {t('financing')} : {currency}{' '}
                                {car.marketplacePrice.toLocaleString()}
                              </p>
                            ) : (
                              <p>
                                {t('financing')} : {t('No')}{' '}
                              </p>
                            )}
                          </CarSearchContainerChildLatter>
                          <CarSearchContainerChildLatterForButton>
                            {userIsAgent ? (
                              <Button
                                variant="contained"
                                disableElevation
                                onClick={(e) => saveCarDataFromSearch(car, e)}
                              >
                                {createBuyCarAppointment &&
                                loaderId == car.id ? (
                                  <CircularProgress
                                    size={20}
                                    style={{ color: 'white' }}
                                  />
                                ) : (
                                  'Add to interest'
                                )}
                              </Button>
                            ) : null}
                          </CarSearchContainerChildLatterForButton>
                        </CarSearchContainerChild>
                      ))}

                      {carsFromMarket.length < 1 ? (
                        <p>{t('sorrySearch')}</p>
                      ) : (
                        <p></p>
                      )}
                    </CarSearchContainer>
                  ) : null}
                  <PaginationCover>
                    <Pagination
                      count={pageCount}
                      page={pageNumber}
                      onChange={handleChange}
                    />
                  </PaginationCover>
                </DropDownParent>
              ) : null}
            </div>
          </ClickAwayListener>

          {getBuyCars(buyCars && buyCars)}
        </TabPanel>
        <TabPanel value={value} index={1}>
          {getSellCars(sellCars)}
        </TabPanel>
        <TabPanel value={value} index={sellExists > 0 ? 3 : 2}>
          {getFixCars(fixCars)}
        </TabPanel>
        <TabPanel
          value={value}
          index={sellExists > 0 && fixExists > 0 ? 5 : fixExists > 0 ? 4 : 3}
        ></TabPanel>
      </div>
      <VehicleDetails country={selectedCountry} carId={selectedCarId} />
      <SellCarDetails
        type={serviceType}
        country={selectedCountry}
        carId={selectedCarId}
        details={serviceType === 'sell' ? sellCars : fixCars}
      />
    </Container>
  )
}
const mapStateToProps = (state: any) => {
  return {
    leadServiceInterest: state.leads.leadServiceInterests.interests
  }
}
export default connect(mapStateToProps)(ServiceInterest)
