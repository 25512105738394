import { connect } from 'react-redux'
import SignInComponent from './SignIn'
import { getIsAuth, getRoles } from '../../../store/auth/selectors'
import { signIn, getUserLocation } from '../../../store/auth/actions'
import { StateProps, DispatchProps } from './types'

export const mapStateToProps = (state: Object) => ({
  isAuth: getIsAuth(state),
  userRoles: getRoles(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  login: (email: string, password: string, history: Object) => {
    return dispatch(signIn(email, password, history))
  },
  getLocation: () => {
    return dispatch(getUserLocation())
  }
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(SignInComponent)
