import get from 'lodash/get'
import * as dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'


const Services = {
  BUY_CAR: 'Buy Car',
  FIX_CAR: 'Fix Car',
  SELL_CAR: 'Sell Car',
  BUY_TRUCK: 'Buy Truck',
  SELL_TRUCK: 'Sell Truck',
  FIX_TRUCK: 'Fix Truck',
  TEST_DRIVE_TRUCK: 'Test Drive Truck',
  TEST_DRIVE_CAR: 'Test Drive Car',
  FINANCING: 'Financing'
}

const Statuses = {
  VERY_HOT: 'Very Hot',
  HOT: 'Hot',
  SCHEDULED_INSPECTION: 'Scheduled Inspection',
  COLD: 'Cold',
  ENQUIRY: 'Enquiry',
  FINANCING: 'Financing',
  FIRST_CONTACT: 'First Contact',
  NOT_INTERESTED: 'Not Interested'
}

export const getLatestServiceInterest = (serviceInterests) => {
  return serviceInterests.sort(function (a, b) {
    return new Date(b.updatedAt) - new Date(a.updatedAt)
  })
}

export const buildLeadsData = (leads) => {
  return leads.map((lead) => {
    return {
      id: lead.id,
      name: `${lead.firstName} ${lead.lastName}`,
      email: lead.email,
      phone: lead.phone,
      service: Services[getLatestServiceInterest(lead.serviceInterest)[0].type],
      status: Statuses[lead.status],
      source:
        getLatestServiceInterest(lead.serviceInterest)[0].source || lead.source,
      value: Number(
        getLatestServiceInterest(lead.serviceInterest)[0].value || 0
      ),
      lastSeen: dayjs(lead.lastSeen || lead.updatedAt).format(`DD/MM/YYYY`) + ' '+ new Date(lead.lastSeen).getHours() + ':' + new Date(lead.lastSeen).getMinutes() + ':' + new Date(lead.lastSeen).getSeconds(),
      firstSeen: dayjs(lead.firstSeen).format(`DD/MM/YYYY`) + ' '+ new Date(lead.firstSeen).getHours() + ':' + new Date(lead.firstSeen).getMinutes() + ':' + new Date(lead.firstSeen).getSeconds(),
    
      agentName: lead?.agent?.name,
      assignedAgent: lead.assignedAgentId,
      multipleAgents:lead.multipleAgents[0]?.name
    }
  })
}

export const getLeads = (state) => {
  const leads = get(state, 'leads.leads')
  return buildLeadsData(leads)
}

export const getTotalLeads = (state) => {
  const total = get(state, 'leads.pagination.total')
  return total
}

export const buildLead = (lead) => {
  return {
    id: lead.id,
    name: `${lead.firstName} ${lead.lastName}`,
    status: lead.status,
    firstName: lead.firstName,
    lastName: lead.lastName,
    email: lead.email,
    phone: lead.phone,
    lastSeen: dayjs(lead.lastSeen || lead.updatedAt).format('DD/MM/YYYY'),
    firstSeen: dayjs(lead.firstSeen).format('DD/MM/YYYY'),
    source:lead.source,
    ipLocation:lead.ipLocation
  }
}

export const carAppointmentData = (state) =>
  get(state, 'leads.carAppointmentData')
export const queryObject = (state) => get(state, 'leads.queryObject')
export const interestDataFromStore = (state) =>
  get(state, 'leads.leadServiceInterests.interests')
export const getShowSearchList = (state) => get(state, 'leads.showSearchList')
export const carAppointmentStatus = (state) =>
  get(state, 'leads.showCarAppointment')
export const marketCarPagination = (state) => get(state, 'leads.carPagination')
export const searchQueryFromStore = (state) =>
  get(state, 'leads.searchQueryFromStore')

export const getLeadDetails = (state) => {
  const leads = get(state, 'leads.lead')
  return buildLead(leads)
}
export const getUserTimeZone = () => {
  let offset = new Date().getTimezoneOffset();
  let extraZero;
  if(offset<0)
  {

      if(-offset%60<10)
        extraZero="0";
  
      return  "+0" + Math.ceil(offset/-60)+":"+extraZero+(-offset%60)
  }
  else
  {
     
      if(offset%60<10)
        extraZero="0";
  
  
      return  "+0" +  Math.floor(offset/60)+":"+extraZero+(offset%60)
  }




}

export const getLeadAssignedAgentId = (state) => {
  const assignedId = get(state, 'leads.lead.assignedAgentId')

  return assignedId ? assignedId : ''
}

export const buildAgentsData = (agents) => {
  return agents.map((agent) => {
    return {
      id: agent.id,
      name: `${agent.firstname} ${agent.lastname}`
    }
  })
}

export const getAllAgents = (state) => {
  const agents = get(state, 'leads.agents.agents')

  return agents ? buildAgentsData(agents) : []
}


export const getAssignedAgent = (state) => {
  const agents = getAllAgents(state)
  const assignedAgentId = getLeadAssignedAgentId(state)

  return assignedAgentId
    ? agents.find((agent) => agent.id === assignedAgentId)
    : { id: '', name: '' }
}




export const getLeadUserId = (state) => {
  const userId = get(state, 'leads.lead.userId')

  return userId ? userId : ''
}

export const getAllMarketPlaceCars = (state) =>
  get(state, 'leads.carsFromMarket')
export const getCurrentLeadId = (state) => get(state, 'leads.lead.id')
export const createCarBuyAppointmentLoader = (state) =>
  get(state, 'leads.createCarBuyAppointmentLoader')
export const scheduleObject = (state) => get(state, 'leads.scheduleObject')
export const getLocations = (state) => get(state, 'leads.locations.locations')
export const getActivityArray = (state) => get(state, 'leads.activityArray')
export const activityLoader = (state) => get(state, 'leads.activityLoader')
export const leadFromStore = (state) => get(state, 'leads.lead')
export const leadNotes = (state) => get(state, 'leads.leadNotes')
export const leadNotesSummary = (state) => get(state, 'leads.leadNotesSummary')
export const activityPagination = (state) =>
  get(state, 'leads.activityPagination')
export const loanNotePagination = (state) =>
  get(state, 'leads.loanNotePagination')
export const activityTypeFromTheStore = (state) =>
  get(state, 'leads.activityType')
export const agentsFromStore = (state) => get(state, 'leads.agents.agents')
export const activityFilterName = (state) =>
  get(state, 'leads.activityFilterName')
export const salesOfficersData = (state) =>
  get(state, 'leads.salesOfficers')
export const filteredAgentArray = (state) =>
  get(state, 'leads.filteredAgentArray')
export const filteredActivityDate = (state) =>
  get(state, 'leads.filteredActivityDate')
export const newTasks = (state) =>
  get(state, 'leads.newTasks')
export const crmNotifications = (state) =>
  get(state, 'leads.crmNotifications')
export const saveBtn = (state) =>
  get(state, 'leads.saveBtn')
export const carsFromStore = (state) =>
  get(state, 'leads.cars')
export const loanCar = (state) =>
  get(state, 'leads.loanCar')
export const bankDealerSearch = (state) =>
  get(state, 'leads.bankDealerSearch')
export const getLeadIdsToAgent = (state) =>
  get(state, 'leads.leadIdsToAgents')
export const getBanks = (state) =>
  get(state, 'leads.savedBanks')
export const getAgentIds = (state) =>
  get(state, 'leads.assignedAgent')


  export const getAssignedAgentFromStore = (state) => {
    const getUserId = get(state, 'auth.user.id')
    const lead = get(state, 'leads.lead')

    return getUserId
      ? lead.multipleAgents?.find((agent) => agent.userId === getUserId)
      : { user_id: '', name: '' }
  }
  export const getAllAgentsAssignedToLead = (state) => {

    const lead = get(state, 'leads.lead')
    

    return lead.multipleAgents
   
  }

  


const serviceTypes = {
  BUY_CAR: 'Buy a Car Inspection',
  FIX_CAR: 'Fix a Car Inspection',
  SELL_CAR: 'Sell a Car Inspection',
  BUY_TRUCK: 'Buy a Truck Inspection',
  SELL_TRUCK: 'Sell a Truck Inspection',
  FIX_TRUCK: 'Fix a Truck Inspection',
  TEST_DRIVE_TRUCK: 'Buy a Truck Inspection',
  TEST_DRIVE_CAR: 'Buy a Car Inspection'
}

export const buildAppointmentData = (interests, locations) => {
  
  return interests
    .filter((interes) => interes.service !== isBuy(interes.service))
    .map((interest) => {
      const { carData } = interest
      return {
        name: serviceTypes[interest.service],
        date: dayjs(interest.pickUpTimeStart).format('DD/MM/YYYY'),
        time: `${dayjs(interest.pickUpTimeStart).format('ha')} - ${dayjs(
          interest.pickUpTimeEnd
        ).format('ha')}`,
        location: locations.find(
          (location) => location.id === interest.dropOffLocationId
        ),
        vehicle: `${carData?.year} ${carData?.make} ${carData?.model}`
      }
    })
}

export const getAllServiceInterests = (state) => {
  const interests = get(state, 'leads.leadServiceInterests.interests')
  const locations = getLocations(state)

  return interests.length > 0 ? buildAppointmentData(interests, locations) : []
}

const isBuy = (service) => {
  if (
    service === 'BUY_CAR' ||
    service === 'TEST_DRIVE_CAR' ||
    service === 'BUY_TRUCK' ||
    service === 'TEST_DRIVE_TRUCK'
  ) {
    return service
  }

  return 'NOT'
}

const isSell = (service) => {
  if (service === 'SELL_CAR' || service === 'SELL_TRUCK') {
    return service
  }

  return 'NOT'
}

const isFix = (service) => {
  if (service === 'FIX_CAR' || service === 'FIX_TRUCK') {
    return service
  }

  return 'NOT'
}

export const getAllBuyCarId = (state) => {
  const interests = get(state, 'leads.leadServiceInterests.interests')
  let ids = []
  const carIds = interests.map((interest) => {
    if (interest.service === isBuy(interest.service)) {
      ids.push(interest.carId)
    }

    return ids
  })

  return ids
}

export const getCarData = (state, carId) => {
  const cars = get(state, 'leads.cars')
  const findCar = cars.find((car) => car.id === carId)
  return findCar
    ? {
        image: findCar.imageUrl,
        price: findCar.marketplacePrice,
        name: `${findCar.year} ${findCar.model.make.name} ${findCar.model.name}`,
        location: `${findCar.state}` + ', ' + `${findCar.city}`
      }
    : {}
}

export const interestData = (state, carId) => {
  const interestDataFromStore = (state) =>
    get(state, 'leads.leadServiceInterests.interests')

  return interestDataFromStore
}

const isTestDrive = (service) => {
  if (service === 'TEST_DRIVE_CAR') {
    return 'Test Drive Car'
  }
  if (service === 'TEST_DRIVE_TRUCK') {
    return 'Test Drive Truck'
  }
  return ''
}

export const getAllBuyCar = (state) => {
  const interests = get(state, 'leads.leadServiceInterests.interests')
  const cars = interests.filter(
    (interest) => interest.service === isBuy(interest.service)
  )

  return cars
    ? cars.map((car) => {
        const { image, price, name, location } = getCarData(state, car.carId)
        return {
          id: car.carId,
          image,
          price,
          name,
          location,
          isTestDrive: isTestDrive(car.service),
          depositReceived: car.depositReceived
        }
      })
    : []
}

export const getSellOrFixCar = (state, type) => {
  const interests = get(state, 'leads.leadServiceInterests.interests')
  let cars = []
  if (type === 'sell') {
    cars = interests.filter(
      (interest) => interest.service === isSell(interest.service)
    )
  }
  if (type === 'fix') {
    cars = interests.filter(
      (interest) => interest.service === isFix(interest.service)
    )
  }

  const locations = getLocations(state)

  return cars
    ? cars.map((car) => {
        const {
          dropOffLocationId,
          pickUpLocation,
          carData,
          serviceDetail,
          pickUpTimeStart,
          pickUpTimeEnd
        } = car
        const year = carData && carData.year.length > 3 ? carData.year : ''
        return {
          id: car.id,
          bodyType: carData? carData.bodyType : '',
          fuelType: carData? carData.fuelType : '',
          gearType: carData? carData.gearType : '',
          imported: carData? carData.imported : '',
          make: carData? carData.make : '',
          mileage: carData? carData.mileage : '',
          model: carData? carData.model : '',
          year: year,
          serviceDetail: serviceDetail ? serviceDetail : '',
          isDropOff: dropOffLocationId ? true : false,
          name: `${year} ${carData?.make} ${carData?.model}`,
          time: `${dayjs(pickUpTimeStart).format('ha')} - ${dayjs(
            pickUpTimeEnd
          ).format('ha')}`,
          location: dropOffLocationId
            ? locations.find((location) => location.id === dropOffLocationId)
            : undefined,
          pickUpLocation: pickUpLocation
            ? {
                state: pickUpLocation.state,
                city: pickUpLocation.city,
                street: pickUpLocation.street
              }
            : undefined
        }
      })
    : []
}

const getCarMedia = (state, carId) => {
  const media = get(state, 'leads.carMedia')

  const carMedia = media[carId] ? media[carId].carMediaList : []
  let mediaList = []
  carMedia.map((media) => {
    if (media.type === 'video/mp4') {
      return mediaList.push({ url: media.url, type: 'video' })
    }
    return mediaList.push({ url: media.url, type: 'image' })
  })

  return mediaList
}

const getSimilarCars = (state, carId) => {
  const similars = get(state, 'leads.similarCars')
  const cars = similars[carId] ? similars[carId].result : []

  return cars.map((car) => {
    return {
      id: car.id,
      location: car.state,
      image: car.imageUrl,
      year: car.year,
      name: car.title,
      utility: car.sellingCondition,
      mileage: car.mileage,
      price: car.marketplacePrice
    }
  })
}

export const getVehicleDetails = (state, carId) => {
  const cars = get(state, 'leads.cars')
  const ownerInfo = get(state, 'leads.carOwner')
  const dealerInfo = get(state, 'leads.carDealer')
  const inspectorInfo = get(state, 'leads.carInspector')
  const interests = get(state, 'leads.leadServiceInterests.interests')

  const findCar = cars.find((car) => car.id === carId)
  const interest = interests.find((interest) => interest.carId === carId)

  return findCar
    ? {
        id: carId,
        image: findCar.imageUrl,
        price: findCar.marketplacePrice,
        name: `${findCar.year} ${findCar.model.make.name} ${findCar.model.name}`,
        vin: findCar.vin,
        utility: findCar.sellingCondition,
        interiorColor: findCar.interiorColor,
        exteriorColor: findCar.exteriorColor,
        fuelType: findCar.fuelType,
        gearType: findCar.transmission,
        mileage: `${findCar.mileage} ${findCar.mileageUnit}`,
        carMedia: getCarMedia(state, carId),
        similarCars: getSimilarCars(state, carId),
        websiteUrl: findCar.websiteUrl,
        licensePlate: findCar.licensePlate || '',
        engineNumber: findCar.engineNumber || '',
        hasWarranty: findCar.hasWarranty,
        hasFinancing: findCar.hasFinancing,
        isInsured: findCar.insured,
        inspectionRatings: getInspectionRatings(state, carId),
        ownerDetails: ownerInfo[carId],
        dealerDetails: dealerInfo[carId],
        inspectorDetails: inspectorInfo[carId],
        interestId: interest.id
      }
    : {}
}

export const getInspectionRatings = (state, carId) => {
  const carInspections = get(state, 'leads.carInspection')
  const findCar = carInspections.find((car) => car.carId === carId) || null

  const ratings =
    findCar !== null
      ? findCar.result.sections.map((item) => {
          return {
            name: item.name,
            value: item.stars,
            items: item.inspectionItems.map((itm) => {
              return {
                name: itm.name,
                response: itm.response
              }
            })
          }
        })
      : []

  return ratings
}

export const getPrevState = (state) => get(state, 'leads.prevState')
