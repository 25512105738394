import React from 'react'

const ClipboardIcon = () => {
  return (
    <svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 19.1642H22"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 25.2112H22"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 18.6602C11.0989 18.6602 11.1956 18.6898 11.2778 18.7452C11.36 18.8005 11.4241 18.8792 11.4619 18.9713C11.4998 19.0634 11.5097 19.1647 11.4904 19.2625C11.4711 19.3602 11.4235 19.45 11.3536 19.5205C11.2836 19.591 11.1945 19.639 11.0975 19.6584C11.0006 19.6778 10.9 19.6679 10.8087 19.6297C10.7173 19.5916 10.6392 19.527 10.5843 19.4441C10.5293 19.3613 10.5 19.2638 10.5 19.1642C10.5 19.0305 10.5527 18.9023 10.6464 18.8078C10.7402 18.7133 10.8674 18.6602 11 18.6602"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M15 13.1171H22"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 12.6132C11.0989 12.6132 11.1956 12.6428 11.2778 12.6981C11.36 12.7535 11.4241 12.8322 11.4619 12.9243C11.4998 13.0164 11.5097 13.1177 11.4904 13.2154C11.4711 13.3132 11.4235 13.403 11.3536 13.4735C11.2836 13.5439 11.1945 13.5919 11.0975 13.6114C11.0006 13.6308 10.9 13.6208 10.8087 13.5827C10.7173 13.5446 10.6392 13.48 10.5843 13.3971C10.5293 13.3142 10.5 13.2168 10.5 13.1171C10.5 12.9835 10.5527 12.8553 10.6464 12.7608C10.7402 12.6663 10.8674 12.6132 11 12.6132"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 24.7073C11.0989 24.7073 11.1956 24.7368 11.2778 24.7922C11.36 24.8476 11.4241 24.9263 11.4619 25.0184C11.4998 25.1104 11.5097 25.2118 11.4904 25.3095C11.4711 25.4073 11.4235 25.4971 11.3536 25.5675C11.2836 25.638 11.1945 25.686 11.0975 25.7054C11.0006 25.7249 10.9 25.7149 10.8087 25.6768C10.7173 25.6386 10.6392 25.574 10.5843 25.4912C10.5293 25.4083 10.5 25.3109 10.5 25.2112C10.5 25.0776 10.5527 24.9494 10.6464 24.8549C10.7402 24.7604 10.8674 24.7073 11 24.7073"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M21 6.06226H26C26.5304 6.06226 27.0391 6.27462 27.4142 6.65264C27.7893 7.03065 28 7.54335 28 8.07794V29.2426C28 29.7772 27.7893 30.2898 27.4142 30.6679C27.0391 31.0459 26.5304 31.2582 26 31.2582H6C5.46957 31.2582 4.96086 31.0459 4.58579 30.6679C4.21071 30.2898 4 29.7772 4 29.2426V8.07794C4 7.54335 4.21071 7.03065 4.58579 6.65264C4.96086 6.27462 5.46957 6.06226 6 6.06226H11C11 4.72578 11.5268 3.44404 12.4645 2.49901C13.4021 1.55398 14.6739 1.02306 16 1.02306C17.3261 1.02306 18.5979 1.55398 19.5355 2.49901C20.4732 3.44404 21 4.72578 21 6.06226V6.06226Z"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16 5.05442C16.0989 5.05442 16.1956 5.08397 16.2778 5.13934C16.36 5.19472 16.4241 5.27342 16.4619 5.3655C16.4998 5.45758 16.5097 5.5589 16.4904 5.65665C16.4711 5.7544 16.4235 5.84419 16.3536 5.91466C16.2836 5.98514 16.1945 6.03313 16.0975 6.05258C16.0006 6.07202 15.9 6.06204 15.8087 6.0239C15.7173 5.98576 15.6392 5.92117 15.5843 5.8383C15.5293 5.75543 15.5 5.658 15.5 5.55834C15.5 5.42469 15.5527 5.29652 15.6464 5.20201C15.7402 5.10751 15.8674 5.05442 16 5.05442"
        stroke="#A1AACE"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default ClipboardIcon
