import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { usePhoneStyles } from './styles'
import { useSelector,  } from 'react-redux'
import {  isAgent,isFulfillmentAgent } from '../../../../store/auth/selectors'
import {  saveBtn } from '../../../../store/leads/selectors'
interface IProps {
  handleSubmit: (...args: any[]) => void
  handleReset: (...args: any[]) => void
}

const FormFooter: React.FC<IProps> = ({ handleSubmit, handleReset }) => {
  const classes = usePhoneStyles()
  const userIsFulfilment = useSelector(isFulfillmentAgent)
  const userIsAgent = useSelector(isAgent)
  const saveBtnStatus = useSelector(saveBtn)
  return (
    <Grid item xs={12} className={classes.formFooter}>
      <Button variant="outlined" onClick={() => handleReset()}>
        Cancel
      </Button>
      {

userIsAgent || userIsFulfilment  && saveBtnStatus? 
      <Button
        variant="contained"
        disableElevation
        onClick={() => handleSubmit()}
      >
        Save
      </Button> : null }
    </Grid>
  )
}

export default FormFooter
