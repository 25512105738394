import React, { useState, useEffect } from 'react'
import { Modal } from 'antd'
import { Form, Button } from 'react-bootstrap'
import { StyledGeneratePfi } from './styles'
import { PlusIcon } from './svgs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from '../../../utils/api'
import {
  getUserToken,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useTranslation } from 'react-i18next'

interface IGeneratePfi {
  generatePfi: any
  setGeneratePfi: any
  loanRefetch: any
}
interface IDetailParam {
  id: string
}
interface IProps {
  item: string
  quantity: string
  price: string
  total: any
}

export default function GeneratePfiModal({
  generatePfi,
  setGeneratePfi,
  loanRefetch
}: any) {
  const [values, setValues] = useState<IProps>({
    item: '',
    quantity: '',
    price: '',
    total: ''
  })
  const [isPfiDocument, setIsPfiDocument] = useState<any[]>([])
  const [open, setOpen] = useState(false)
  const { id } = useParams<IDetailParam>()
  const token = useSelector(getUserToken)
  const currency = useSelector(getSelectedCountryCurrency)
  const dispatch = useDispatch()
  const [isList, setIsList] = useState<Array<any>>([])
  const [carId, setCarId] = useState<string>('')
  const [user, setUser] = useState<any>()
  const [getPfi, setGetPfi] = useState<any>({})
  const [total, setTotal] = useState<any>()
  const { t } = useTranslation()

  const { data: singleLoan, refetch } = useQuery(['singleLoans', id], () =>
    api.fetchSingleOriginationLoan(token, id)
  )

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    if (singleLoan) {
      setUser(singleLoan)
      setCarId(singleLoan?.carId)
    }
  }, [singleLoan])

  // useEffect(() => {
  //   if (singleLoan) {
  //     setCarId(singleLoan?.carId)
  //   }
  // }, [singleLoan])

  useEffect(() => {
    if (singleLoan) {
      const PfiLists = [
        {
          item: t('salesPrice'),
          quantity: '1',
          price: singleLoan?.car?.marketplacePrice.toString() || '0',
          total: 1 * (singleLoan?.car?.marketplacePrice || 0)
        }
      ]
      setIsList([...PfiLists])
    }
  }, [singleLoan])

  const isOpen = () => {
    if (open == false) {
      setOpen(true)
    }
    if (open == true) {
      setOpen(false)
    }
  }

  const price = Number(values.price)
  const quantity = Number(values.quantity)
  const totalValue = price * quantity

  const handleInput = (name: any) => (e: any) => {
    setValues({
      ...values,
      [name]: e.target.value
    })
    setIsPfiDocument(isPfiDocument)
  }

  const submitPfiDocument = () => {
    if (values.item == '') {
      return
    }
    if (values.quantity == '') {
      return
    }
    if (values.price == '') {
      return
    }
    //@ts-ignore
    setIsPfiDocument([...isPfiDocument, { ...values, total: totalValue }])
    Array.from(document.querySelectorAll('input')).forEach(
      (input) => (input.value = '')
    )
    //@ts-ignore
    setValues({})
  }

  const _combined = [...isList, ...isPfiDocument]
  const getTotal = _combined
    .map((item) => item.total)
    .reduce((prev, curr) => prev + curr, 0)

  const handleRemove = (i: any) => {
    const items = isPfiDocument.filter((item, index) => index !== i)
    setIsPfiDocument(items)
  }

  const handleRemoveList = (i: number) => {
    const items = isList.filter((item, index) => index !== i)
    setIsList(items)
  }

  const handleCancel = () => {
    setGeneratePfi(false)
  }

  const handleSave = async () => {
    const _combined = [...isList, ...isPfiDocument]
    const _items = _combined.map((item) => ({
      item: item.item,
      unit_price: item.price,
      quantity: Number(item.quantity),
      display: true,
      include_vat: true
    }))

    const data = {
      car_id: carId,
      bank_id: '78vEj6VEi',
      items: _items,
      name: `${user?.firstName} ${user?.lastName}`,
      phone: user?.phone,
      email: user?.email,
      state: 'N/A',
      city: 'N/A',
      address: 'N/A'
    }

    try {
      const response = await api.getProformaInvoice(token, data)
      if (response) {
        dispatch(showSnackbar('PFI generated successfully', 'success'))
        setGetPfi(response)
        setGeneratePfi(false)
        handleUpdate(response.generatedPdf)
      }
    } catch (err) {
      dispatch(showSnackbar('Error generating PFI', 'error'))
    }
    loanRefetch()
  }
  //getPfi?.generatedPdf

  const handleUpdate = async (pfiUrl: any) => {
    const _data = {
      loan_id: id,
      pfi_url: pfiUrl
    }

    try {
      const response = await api.updateCarLoan(token, id, _data)
      if (response) {
        dispatch(showSnackbar('Updated successfully', 'success'))
      }
    } catch (err) {}
  }

  return (
    <Modal closable={false} visible={generatePfi} width="800px" footer={null}>
      <StyledGeneratePfi>
        <div className="title mb-4">{t('generatePFI')}</div>
        <div className="tableHeader mb-3">
          <div>S/N</div>
          <div>{t('Items')}</div>
          <div>{t('Quantity')}</div>
          <div>{t('UnitPrice')}</div>
          <div>{t('Total')}</div>
          <div></div>
        </div>

        <ol>
          {isList.map((li, index) => (
            <li key={index}>
              <div className="tableContent mb-3">
                <div>{li.item}</div>
                <div>{li.quantity}</div>
                <div>{Number(li.price)?.toLocaleString()}</div>
                <div>{li.total?.toLocaleString()}</div>
                <div className="staticListButton">
                  <Button
                    className="p-0"
                    disabled
                    onClick={() => handleRemoveList(index)}
                  >
                    {t('Remove')}
                  </Button>
                </div>
              </div>
            </li>
          ))}

          {Object.keys(isPfiDocument).map((el: any, index) => (
            <li key={index}>
              <div className="tableContent mb-3">
                <div>{isPfiDocument[el].item}</div>
                <div>{isPfiDocument[el].quantity}</div>
                <div>{Number(isPfiDocument[el].price).toLocaleString()}</div>
                <div>{Number(isPfiDocument[el].total).toLocaleString()}</div>
                <div>
                  <a onClick={() => handleRemove(index)}>{t('Remove')}</a>
                </div>
              </div>
            </li>
          ))}

          {open && (
            <li>
              <Form
                onKeyPress={(e) => e.key === 'Enter' && submitPfiDocument()}
                onSubmit={submitPfiDocument}
                className="tableForm mb-4"
              >
                <Form.Group controlId="validationCustom01">
                  <Form.Control
                    required
                    type="text"
                    size="sm"
                    onChange={handleInput('item')}
                    value={values.item}
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom02">
                  <Form.Control
                    required
                    type="number"
                    size="sm"
                    onChange={handleInput('quantity')}
                    value={values.quantity}
                  />
                </Form.Group>
                <Form.Group controlId="validationCustom03">
                  <Form.Control
                    required
                    type="number"
                    size="sm"
                    onChange={handleInput('price')}
                    value={values.price}
                  />
                </Form.Group>
              </Form>
            </li>
          )}
        </ol>

        <div className="addNewSection d-flex align-items-center mb-5">
          <Button onClick={isOpen}>
            {PlusIcon} {t('addNew')}
          </Button>
          <div className="wrapper">
            <span className="total me-3">{t('Total')}:</span>
            <span className="amount">
              {currency}
              {getTotal.toLocaleString()}
            </span>
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <Button className="cancelButton me-3" onClick={handleCancel}>
            {t('Cancel')}
          </Button>
          <Button className="saveButton" onClick={handleSave}>
            {t('save')}
          </Button>
        </div>
      </StyledGeneratePfi>
    </Modal>
  )
}
