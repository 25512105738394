import { extractFirstLetter } from './string'

export const getProfileUrlOrDefault = (profileUrl, firstname, lastname) => {
  const firstNameInitial = extractFirstLetter(firstname)
  const lastNameInitial = extractFirstLetter(lastname)
  const value = firstNameInitial + lastNameInitial

  return profileUrl
    ? profileUrl
    : generateProfilePictureUrl(value)
}

export const generateProfilePictureUrl = initials => {

  const initial = initials ? initials : 'AB'
  const url = `https://i2.wp.com/cdn.auth0.com/avatars/${initial.toLowerCase()}.png?ssl=1`

  return url
}
