import React, { useEffect, useState } from 'react'
import { StyledSimilarCar } from './styles'
import { Button } from 'antd'
import { RatingIcon, GaugeIcon, LocationIcon } from '../AvailableCar/svg'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import api from '../../../utils/api'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  getSelectedCountryCurrency,
  getUserToken
} from '../../../store/auth/selectors'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function SimilarCar({ singleLoan, loanRefresh }: any) {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true
  }

  const { id } = useParams<IDetailParam>()
  const token = useSelector(getUserToken)
  const dispatch = useDispatch()
  const currency = useSelector(getSelectedCountryCurrency)
  const { t } = useTranslation()

  const car_ID = singleLoan?.carId

  const { data: fetchSimilarCars, status, refetch } = useQuery(
    ['similarCars', car_ID],
    () => api.fetchSimilarCarsWithFinancing(token, car_ID),
    {
      onSuccess: () => {}
    }
  )

  const handleSubmit = async (i: any) => {
    const _newArray = { ...fetchSimilarCars?.result }

    const _data = {
      loan_id: id,
      car_id: _newArray[i].id
    }

    try {
      const response = await api.updateCarLoan(token, id, _data)
      if (response) {
        dispatch(showSnackbar('Updated successfully', 'success'))
        loanRefresh()
      }
    } catch (err) {}
  }

  // console.log(fetchSimilarCars?.result)

  return (
    <StyledSimilarCar>
      <div className="similarCarsWrapper mt-4">
        <div className="title mb-3">{t('similarCars')}</div>

        <div className="slickSlider">
          {status == 'loading' ? (
            'Loading...'
          ) : (
            <Slider {...settings} className="col-md">
              {fetchSimilarCars?.result?.map((el: any, index: any) => (
                <div key={index}>
                  <div className="sliderWrapper">
                    <div className="carImage mb-1">
                      <img
                        src={el?.imageUrl}
                        className="img-fluid"
                        alt="car-image"
                      />
                    </div>
                    <div className="carName mb-1">
                      {el?.year} {el?.title}
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div className="price">
                        {currency}
                        {el?.marketplacePrice?.toLocaleString()}
                      </div>
                      <div className="rate">
                        {RatingIcon} ({el?.gradeScore?.toFixed(1) || 0})
                      </div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div className="distance">
                        {GaugeIcon} {el?.mileage?.toLocaleString()}
                        {el?.mileageUnit}
                      </div>
                      <div className="location">
                        {LocationIcon} {el?.city}
                      </div>
                    </div>
                    <div className="phone d-none">
                      N/A <br /> N/A
                    </div>
                    <div className="mt-3 d-flex justify-content-center">
                      <Button onClick={() => handleSubmit(index)}>
                        {t('substituteCar')}
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </StyledSimilarCar>
  )
}
