import React from 'react'
import TextInput from '../../../components/TextField'
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom'
import { State, Props } from './types'
import { ResetPasswordText, ResetBoxContent } from '../ResetPassword/styles'
import {
  PageContainer,
  SignInBox,
  LogoTop,
  LogoTopContent,
  AutoCRMText,
  BootingCar,
  InputLane,
  StyledButton
} from '../styles'

type ForgotPasswordProps = Props & RouteComponentProps

class ForgotPassword extends React.Component<ForgotPasswordProps, State> {
  state = {
    email: '',
    otp: '',
    isOTPView: false
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name

    this.setState({
      [key]: event.target.value
    })
  }

  sendOTPCode = () => {
    const { sendOTP } = this.props
    const { email } = this.state

    sendOTP(email)

    this.setState({
      isOTPView: true
    })
  }

  verifySentOTP = () => {
    const { verifyOTP, history } = this.props
    const { otp } = this.state

    verifyOTP(otp, history)
  }

  render() {
    const { email, otp, isOTPView } = this.state
    const { isAuth } = this.props

    if (isAuth) return <Redirect to={{ pathname: '/leads' }} />

    return (
      <PageContainer>
        <SignInBox>
          <LogoTop>
            <LogoTopContent>
              <BootingCar /> <AutoCRMText />
            </LogoTopContent>
          </LogoTop>
          <ResetBoxContent>
            {isOTPView ? (
              <div>
                <ResetPasswordText>Verification</ResetPasswordText>
                <InputLane marginBottom="25px" bold>
                  <TextInput
                    name="otp"
                    label="Verification Code"
                    placeholder="Enter verification code sent to you"
                    value={otp}
                    onInputChange={this.handleInputChange}
                  />
                </InputLane>
              </div>
            ) : (
              <div>
                <ResetPasswordText>Forgot Password</ResetPasswordText>
                <InputLane marginBottom="25px" bold>
                  <TextInput
                    name="email"
                    label="Email Address"
                    value={email}
                    onInputChange={this.handleInputChange}
                  />
                </InputLane>
              </div>
            )}
            <StyledButton
              disabled={false}
              onClick={isOTPView ? this.verifySentOTP : this.sendOTPCode}
            >
              {isOTPView ? 'Verify' : 'Submit'}
            </StyledButton>
          </ResetBoxContent>
        </SignInBox>
      </PageContainer>
    )
  }
}

export default withRouter(ForgotPassword)
