import { ENotificationTypes, INewMessage } from './actions'
import dayjs from 'dayjs'

interface INewMessageAction {
  type: ENotificationTypes.ADD_NEW_MESSAGE_NOTIFICATION
  payload: INewMessage
}

interface ISetOlderNotificationAction {
  type: ENotificationTypes.SET_OLDER_NOTIFICATIONS
  payload: INewMessage
}

type TNotificationActions = INewMessageAction | ISetOlderNotificationAction

export interface INotifications {
  whatsApp: INewMessage[]
}

const initialState: INotifications = {
  whatsApp: []
}

const reducer = (
  state: INotifications = initialState,
  action: TNotificationActions
): INotifications => {
  switch (action.type) {
    case ENotificationTypes.ADD_NEW_MESSAGE_NOTIFICATION:
      return {
        ...state,
        whatsApp: [
          ...state.whatsApp,
          { ...action.payload, time: dayjs(), new: true }
        ]
      }
    case ENotificationTypes.SET_OLDER_NOTIFICATIONS: {
      const olderNotifications = state.whatsApp.map((item) => ({
        ...item,
        new: false
      }))
      return {
        ...state,
        whatsApp: olderNotifications
      }
    }
    default:
      return state
  }
}

export default reducer
