import React from 'react'
import { Button, ButtonProps, Popover, Typography } from '@material-ui/core'
import clsx from 'clsx'
import dropDown from '../../../../images/drop_down.svg'
import { useActionBtnStyles } from './styles'
import { IOptions } from './lead.utils'
import { useTranslation } from 'react-i18next'

interface IProps extends ButtonProps {
  title: string
  icon: any
  options: IOptions
}

const ActionButton: React.FC<IProps> = (props) => {
  const { variant, className, title, icon, options } = props
  const classes = useActionBtnStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const id = open ? 'dropdown' : undefined

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLink = (
    event: React.MouseEvent,
    value: string,
    logEvent = false
  ) => {
    event.preventDefault()
    options.handleClick(value, logEvent)
    handleClose()
  }
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Button
        {...props}
        variant={variant || 'outlined'}
        className={clsx(classes.actionBtn, className)}
        startIcon={<img src={icon} alt={title} />}
        aria-describedby={id}
        onClick={handleClick}
        endIcon={
          <img
            src={dropDown}
            alt="dropdown"
            className={clsx(classes.dropdownIcon, open && classes.rotate)}
          />
        }
      >
        {title}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popupWrapper}
        classes={{
          paper: classes.popupPaper
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div className={classes.popup}>
          <Typography variant="body1" className={classes.title}>
            {t(`${options.title}`)}
          </Typography>
          {options.entries.map((item) => (
            <Button
              variant="text"
              key={item.value}
              fullWidth
              onClick={(e) => handleLink(e, item.value, item.event)}
              className={classes.linkBtn}
              startIcon={<img src={item.icon} alt={item.value} />}
            >
              {t(`${item.value}`)}
            </Button>
          ))}
        </div>
      </Popover>
    </React.Fragment>
  )
}

export default ActionButton
