import React from 'react'
import TextInput from '../../../../components/TextField'
import { withTheme } from 'styled-components'
import { State, Props } from './types'
import {
  Avatar,
  FileInput,
  UploadNotice,
  SaveButton,
  InputLane,
  AccountPrefContainer,
  SectionTitle,
  AvatarUpload,
  Divider,
  SelectImage,
  PasswordMatchNotice,
  UploadTooltip
} from './styles'
import { withTranslation } from 'react-i18next'

class Account extends React.Component<Props, State> {
  state = {
    email: this.props.email,
    firstname: this.props.firstname,
    lastname: this.props.lastname,
    phone: this.props.phone,
    password: '',
    password2: '',
    image: null
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name

    this.setState({
      [key]: event.target.value
    })
  }

  handleImageSelect = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) return
    const image = event.currentTarget.files[0]
    if (image) {
      this.setState({
        image
      })
    }
  }

  uploadProfileImage = () => {
    const { image } = this.state

    this.props.uploadPhoto(image)
  }

  updateUserDetails = () => {
    const { profileImage } = this.props
    const { email, phone, firstname, lastname } = this.state

    const details = {
      email,
      firstname,
      lastname,
      primaryPhone: phone,
      imageUrl: profileImage
    }

    this.props.updateUser(details)
    this.setState({
      image: null
    })
  }

  updatePassword = () => {
    const { password } = this.state
    const { resetPassword } = this.props

    resetPassword(password)
    this.setState({
      password: '',
      password2: ''
    })
  }

  isDetailsSet = () => {
    const { password2, password } = this.state

    if (password2 && password) {
      return this.checkPasswordMatch()
    }

    return false
  }

  checkPasswordMatch = () => {
    const { password2, password } = this.state

    if (password2 === '' || password === '') return true
    if (password2 === password) return true
    return false
  }

  render() {
    // @ts-ignore
    const { t } = this.props

    const { theme, profileImage } = this.props
    const {
      email,
      phone,
      firstname,
      lastname,
      image,
      password,
      password2
    } = this.state
    const enableUploadBtn = image ? false : true

    const inputProps = {
      width: '400px',
      height: '36px',
      color: theme.palette.neutral.black,
      borderRadius: '3px',
      transparent: true,
      borderColor: theme.palette.neutral.pattensBlue,
      labelMarginBottom: '4px'
    }

    return (
      <AccountPrefContainer>
        <SectionTitle>{t('personalDetails')}</SectionTitle>
        <Avatar data-tooltip="click to change">
          <img src={profileImage} alt="avatar" />
          <SelectImage htmlFor="photoUpload">.</SelectImage>
          <UploadTooltip>{t('clickChange')}</UploadTooltip>
        </Avatar>
        <AvatarUpload
          deactivated={enableUploadBtn}
          disabled={enableUploadBtn}
          onClick={this.uploadProfileImage}
        >
          {t('uploadPicture')}
        </AvatarUpload>
        <FileInput
          id="photoUpload"
          type="file"
          name="img"
          accept=".jpg,.png"
          onChange={this.handleImageSelect}
        />
        <UploadNotice>{t('uploadJpgPng')}.</UploadNotice>
        <InputLane>
          <TextInput
            name="firstname"
            value={firstname}
            label={t('firstName')}
            onInputChange={this.handleInputChange}
            {...inputProps}
          />
        </InputLane>
        <InputLane>
          <TextInput
            name="lastname"
            value={lastname}
            label={t('lastName')}
            onInputChange={this.handleInputChange}
            {...inputProps}
          />
        </InputLane>
        <InputLane>
          <TextInput
            name="email"
            value={email}
            label={t('emailAddress')}
            onInputChange={this.handleInputChange}
            {...inputProps}
          />
        </InputLane>
        <InputLane>
          <TextInput
            name="phone"
            value={phone}
            label={t('phoneNumberOptional')}
            onInputChange={this.handleInputChange}
            {...inputProps}
          />
        </InputLane>
        <SaveButton onClick={this.updateUserDetails}>
          {t('saveChanges')}
        </SaveButton>
        <Divider />
        <SectionTitle>{t('changePassword')}</SectionTitle>
        <InputLane>
          <TextInput
            name="password"
            label={t('newPassword')}
            type="password"
            value={password}
            placeholder={t('enterNewPassword')}
            onInputChange={this.handleInputChange}
            {...inputProps}
          />
        </InputLane>
        <InputLane>
          <TextInput
            name="password2"
            label={t('confirmPassword')}
            type="password"
            value={password2}
            placeholder={t('reenterNewPassword')}
            onInputChange={this.handleInputChange}
            {...inputProps}
          />
        </InputLane>
        {!this.checkPasswordMatch() && (
          <PasswordMatchNotice>{t('passwordsDontmatch')}</PasswordMatchNotice>
        )}
        <SaveButton
          deactivated={!this.isDetailsSet()}
          disabled={!this.isDetailsSet()}
          onClick={this.updatePassword}
        >
          {t('saveChanges')}
        </SaveButton>
      </AccountPrefContainer>
    )
  }
}

export default withTranslation()(withTheme(Account))
