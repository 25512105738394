import React from 'react'
import styled from 'styled-components'
import ChatBackgroundSvg from '../../../images/conversation-chat-bg.svg'
import SearchI from '../../../images/search.svg'
import SettingsI from '../../../images/settings.svg'
import WhatsAppI from '../../../images/logos_whatsapp.svg'
import DownIconI from '../../../images/down.svg'

export const ChatViewWrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-grow: 1;
`

export const ChatViewBox = styled.div`
  min-width: 620px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.palette.neutral.zircon};
`

export const ChatHeader = styled.div`
  position: sticky;
  top: 0;
`

export const ReciepientInfo = styled.div`
  display: flex;
  align-content: center;
  height: 60px;
  padding: 15px;
  background: ${({ theme }) => theme.palette.neutral.ghostWhite};
`

export const RecipientInitials = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.palette.background.default};
  font-size: ${({ theme }) => theme.font.size.sm};
  background: ${({ theme }) => theme.palette.neutral.cyanBluishGray};
  width: 32px;
  height: 32px;
  margin-right: 12px;
  border-radius: 50%;
`

export const RecipientName = styled.h6`
  margin: 0;
  display: flex;
  flex-grow: 1;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral.lightSlateGrey};
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: 600;
`

export const ChatToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.background.default};
  color: ${({ theme }) => theme.palette.neutral.lightSlateGrey};
  padding: 12px 15px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
`

export const AgentInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const ToolbarOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const AgentName = styled.h6`
  display: inline;
  align-self: center;
  margin: 0;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  cursor: pointer;
`

export const AvailableAgents = styled.div`
  min-width: 300px;
  padding: 0 10px;
`

export const AvailableAgent = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ebecf0;
  color: #778699;
  cursor: pointer;
`

export const DropDownIcon = styled.div`
  width: 16px;
  height: 16px;
  background-image: url(${DownIconI});
  margin-top: 2px;
  cursor: pointer;
`

export const AvailableAgentDropDown = styled.div`
  display: flex;
`

export const ChatContainer = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 0 15px;
  padding-bottom: 30px;
  overflow: hidden;

  &:after {
    content: '';
    background-image: url(${ChatBackgroundSvg});
    opacity: 0.05;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`

export const ObserverTarget = styled.div`
  height: 2px;
`

export const ChatMessages = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 10px 0;
  overflow: scroll;
`

export const ClosedConvo = styled.div`
  max-width: 100%;
  padding: 10px;
  width: fit-content;
  background: #e2f2fb;
  color: ##1d1e1e;
  border-radius: 5px;
  margin-bottom: 10px;

  p {
    margin: 0;
    text-align: center;
  }
`

export const Chat = styled.div<{ right: boolean }>`
  display: flex;
  ${({ right }) => right && 'flex-direction: row-reverse;'}
  margin-bottom: 10px;

  ${({ theme, right }) =>
    right &&
    `
    & > div:first-of-type { 
      margin-right: 0;
      margin-left: 12px;
      background: ${theme.palette.background.default};
      color: #8F99A6;
      border: 1px solid ${theme.palette.neutral.cyanBluishGray};
      box-shadow: 1px 1px 2px ${theme.palette.neutral.cyanBluishGray};
    }`}
`

export const ChatBox = styled.div<{ showColor?: boolean }>`
  max-width: 50%;
  padding: 12px;
  background: ${({ theme, showColor }) =>
    showColor ? '#fafff5' : theme.palette.background.default};
  border: 1px solid ${({ theme }) => theme.palette.neutral.cyanBluishGray};
  border-radius: 5px;

  div {
    width: 100%;
  }
`

export const ChatImageAttachment = styled.div<{ imgUrl: string }>`
  min-width: 200px;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: ${({ imgUrl }) => `url(${imgUrl})`};
  margin-bottom: 5px;
  cursor: pointer;

  a {
    display: none;
  }
`

export const ChatBoxMessage = styled.p`
  text-align: left;
  word-break: break-word;
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
  margin: 0;
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.font.size.md};
`

export const ChatBoxSubText = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.palette.neutral.grayChateau};
  font-size: ${({ theme }) => theme.font.size.sm};

  span:first-of-type {
    margin-right: 20px;
  }
`

export const ChatMessagesEnd = styled.div`
  display: hidden;
`

export const InputBox = styled.div`
  margin-top: auto;
`

export const InputBoxHeader = styled.div`
  display: flex;
  background: ${({ theme }) => theme.palette.neutral.ghostWhite};
  border: 1px solid ${({ theme }) => theme.palette.neutral.cyanBluishGray};
  border-radius: 6px 6px 0px 0px;
  padding: 10px 12px;
`

export const MediumTitle = styled.h6`
  margin: 0;
  color: ${({ theme }) => theme.palette.neutral.regentGrey};
  font-size: ${({ theme }) => theme.font.size.md};
`

export const InputBoxBody = styled.div`
  position: relative;
  background: ${({ theme }) => theme.palette.background.default};
  padding: 12px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.cyanBluishGray};
  border-top: none;
  border-radius: 0px 0px 6px 6px;

  .emoji-picker-react {
    position: absolute;
    top: -250px;
  }
`

export const AttachmentBox = styled.div`
  padding: 5px 10px;
  width: fit-content;
  height: fit-content;
  background: #f6f7fb;
  border-radius: 8px;
  margin-bottom: 5px;
  border: 1px solid #cad0d9;

  a {
    color: #757f8a;
  }

  span {
    margin-left: 10px;
    cursor: pointer;
  }
`

export const ChatInput = styled.textarea`
  width: 100%;
  height: 60px;
  font-family: inherit;
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
  resize: none;
  border: none;
  outline: none;

  ::placeholder {
    color: ${({ theme }) => theme.palette.neutral.cyanBluishGray};
  }
`

export const ChatOptions = styled.div`
  display: flex;
  align-items: center;
`

export const Integrations = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 257px;
  height: 100vh;
  background: ${({ theme }) => theme.palette.background.default};
`

export const IntegrationsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  padding-top: 24px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e6e7ea;
`

export const IntegrationsTitle = styled.h5`
  font-size: ${({ theme }) => theme.font.size.H5};
  font-weight: 600;
  margin: 0;
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
`

export const IntegrationsBody = styled.div`
  display: flex;
`

export const CreateLeadButton = styled.button`
  min-width: 150px;
  margin: 60px auto;
  background: #4b9ff2;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  border: 1px solid #4b9ff2;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  outline: none;

  &:hover {
    background: #4b9ff2cc;
    border: 1px solid #4b9ff2cc;
  }

  a {
    text-decoration: none;
    color: inherit;
  }
`

export const LeadDetails = styled.div`
  padding: 20px;

  button {
    margin-top: 20px;
  }
`

export const LeadDetailItem = styled.div`
  margin-bottom: 10px;
`

export const LeadDetailItemLabel = styled.h6`
  color: #474f58;
  margin: 0;
  font-size: 14px;
`

export const LeadDetailItemValue = styled.p`
  margin: 0;
  font-size: 14px;
`

export const SearchIcon = styled.div`
  align-self: center;
  margin-left: auto;
  background-fill-color: red;
  height: 18px;
  width: 18px;
  background-size: 18px 18px;
  background-image: url(${SearchI});
  background-repeat: no-repeat;
`

export const SettingsIcon = styled.div`
  align-self: center;
  height: 16px;
  width: 16px;
  background-image: url(${SettingsI});
  background-repeat: no-repeat;
`

export const ToolbarIcon = styled.div<{ icon: string }>`
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin: 0 5px;
  background-image: ${({ icon }) => `url(${icon})`};
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-color: #f6f7fb;
  }
`

export const ToolbarPopoverContent = styled.div`
  padding: 20px;

  div {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin: 0 auto;

    button {
      background-color: #f24b4b;
      padding: 10px 5px;
      color: white;
      border-radius: 5px;
      border: none;
      width: 100px;
      cursor: pointer;
      outline: none;
    }

    button:last-of-type {
      background-color: #4b9ff2;
    }
  }
`

export const ChatOptionIcon = styled.div<{ icon: string; sendBtn?: boolean }>`
  display: flex;
  justify-content: center;
  align-self: center;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  margin-right: 20px;
  background-image: ${({ icon }) => `url(${icon})`};
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  ${({ sendBtn }) => (sendBtn ? `margin-left: auto;` : '')}

  &:hover {
    background-color: #f6f7fb;
  }
`

export const WhatsAppIcon = styled.div`
  align-self: center;
  height: 20px;
  width: 20px;
  margin-right: 8px;
  background-image: url(${WhatsAppI});
  background-repeat: no-repeat;
`
