import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useTodoStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '1.5rem'
    },
    title: {
      color: '#A1AACE',
      fontWeight: 800
    },
    noTask: {
      textAlign: 'center',
      color: '#A1AACE',
      fontWeight: 800,
      width: '100%',
      marginTop: '1.875rem'
    },
    cardWrapper: {
      maxWidth: '48.25rem',
      marginTop: '1.2rem',
      background: '#fff',
      border: '1px solid #E8E9ED',
      borderRadius: 4,
      padding: '1rem 0.875rem',
      borderLeft: '7px solid #FFCC5B'
    },
    cardWrapperInner: {
      display: 'flex'
    },
    iconWrapper: {
      width: '2rem'
    },
    cardTitle: {
      fontWeight: 800,
      fontSize: '0.875rem',
      marginBottom: '0.625rem'
    },
    cardStatus: {
      color: '#EDB333',
      fontWeight: 800,
      fontSize: '0.75rem',
      marginBottom: '0.5rem'
    },
    cardStatusText: {
      color: '#A1AACE',
      marginLeft: '1.125rem',
      fontWeight: 800,
      fontSize: '0.75rem'
    },
    cardNote: {
      color: '#0A1133',
      fontWeight: 500,
      marginBottom: '1.25rem'
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    label: {
      color: '#0A1133',
      fontWeight: 800,
      margin: '.3rem 0'
    },
    text: {
     
      fontSize:'15px',
      paddingTop:'5px',
      paddingBottom:'15px',
    },
    carText: {
     
      fontSize:'14px',
      paddingTop:'5px',
     
    },
    carCover:{
      padding:'10px',
      borderRadius:'5px',
      border:'1px solid #CAD0D9',
      display:'flex',
      marginBottom:'10px'
    },
    carCoverChildImage:{
      flexBasis:'50%',
     
    },
    formFooter: {
      marginTop: '1.2rem',
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        margin: '.5rem',
        '&:last-of-type': {
          marginRight: 0
        }
      }
    },
    textBlue : {
      color: '#4B9FF2'
    },
    carImage:{
      borderRadius:'5px',
     
    },
    carCoverChild:{
      flexBasis:'50%',
     
    },
    carName:{
     fontWeight: 'bold',
     fontSize:'15px'
     
    },
    carStatusText: {
      color: '#A1AACE',
     
      fontWeight: 800,
      fontSize: '14px'
    },
    cardNoteTitle: {
      color: '#A1AACE',
      fontWeight: 800,
      marginBottom: '0.375rem',
    },
    capitalize: {
      textTransform: 'capitalize'
    },
  })
)
