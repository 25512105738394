import React, { useState, useEffect } from 'react'
import { StyledDocumentUpload, StyledNoUpload } from './styles'
import {
  CancelBIcon,
  MarkBIcon,
  MarkWIcon,
  CancelWIcon,
  NoticeIcon,
  DownloadIcon,
  TaskIcon
} from './svgs'
import { saveAs } from 'file-saver'
import { Button, Upload, message } from 'antd'
import AddedDocModal from './AddedDocModal'
import { Link, useLocation, useParams, useHistory } from 'react-router-dom'
import GeneratePfiModal from './GeneratePfiModal'
import api from '../../../utils/api'
import {
  getUserToken,
  getUserId,
  getSelectedCountry,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import UpdateRequest from '../UpdateDocumentRequest'
import UploadDocumentRequest from '../UpdateDocumentRequest/UploadDocumentReq'
import UploadedContract from '../UpdateDocumentRequest/UploadContract'
import { useTranslation } from 'react-i18next'
import { CAR_STATUS } from '../../LeadManagement/AvailableCar/constants'
import ReplaceDocument from '../UpdateDocumentRequest/ReplaceDocument'

interface IDetailParam {
  id: string
}

const API_URL = process.env.REACT_APP_API_URL

export default function ReviewedDocument() {
  const [visible, setVisible] = React.useState(false)
  const [generatePfi, setGeneratePfi] = React.useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isUploadVisible, setIsUploadVisible] = useState(false)
  const [isContractVisible, setIsContractVisible] = useState(false)
  const location = useLocation()
  const { id } = useParams<IDetailParam>()
  const token = useSelector(getUserToken)
  const [isPfi, setIsPfi] = useState<any>({})
  const [singleRequest, setSingleRequest] = useState<any>({})
  const [uploadRequest, setUploadRequest] = useState<any>({})
  const [isReplaceVisible, setIsReplaceVisible] = useState<any>(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: fetchDocument, refetch } = useQuery(
    'document',
    () => api.fetchDocuments(token, id),
    {
      onSuccess: () => {}
    }
  )

  const { data: singleLoan, status, refetch: loanRefetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    {
      keepPreviousData: true,
      staleTime: 5000
    }
  )

  const { data: fetchCarDocs } = useQuery(
    ['document', singleLoan],
    () =>
      api.fetchAllCarOffers(token, {
        car_id: singleLoan?.carId
      }),
    {
      onSuccess: () => {}
    }
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
    }
  }, [singleLoan, queryClient, id])

  const showModal = () => {
    setVisible(true)
  }

  const showPfi = () => {
    setGeneratePfi(true)
  }

  const showUpdateRequestModal = (req: any) => {
    setSingleRequest(req)
    setIsModalVisible(true)
  }

  const showUploadRequestModal = (req: any) => {
    setUploadRequest(req)
    setIsUploadVisible(true)
  }

  const showReplaceRequestModal = (req: any) => {
    setIsReplaceVisible(req)
  }

  const showUploadContractModal = () => {
    setIsContractVisible(true)
  }

  //@ts-ignore
  const saveFile = (url) => {
    saveAs(url, 'example.pdf')
  }

  function getFileNameFromUrl(url: any) {
    var el = document.createElement('a')
    el.href = url
    return decodeURIComponent(el.pathname).split('/').pop()
  }

  useEffect(() => {
    let pfiDocPdf = sessionStorage.getItem('downloadPfi')
    //@ts-ignore
    let generatedPfi = JSON.parse(pfiDocPdf)
    setIsPfi(generatedPfi)
  }, [])

  const requestedDocuments = fetchDocument?.documents?.filter(function (
    item: any
  ) {
    return item?.status == 'REQUESTED'
  })

  const AllDocuments = fetchDocument?.documents?.filter(function (item: any) {
    return (
      item?.status == 'IN-REVIEW' ||
      item?.status == 'APPROVED' ||
      item?.status == 'REJECTED'
    )
  })

  return (
    <StyledDocumentUpload>
      {fetchDocument?.documents.length === 0 ? (
        <div className="content">
          <div>{TaskIcon}</div>
          <div className="text ms-3">
            {t('thisCustomerDoesNotDocumentYet')}.
          </div>
        </div>
      ) : (
        <>
          {location.hash === '#offers' && (
            <div className="mb-4">
              {requestedDocuments?.length == 0 ? (
                ''
              ) : (
                <div className="docTitle mb-2">{t('additionalDocuments')}</div>
              )}
              <div className="addedDocument">
                {requestedDocuments?.map((el: any, index: any) => (
                  <div key={index}>
                    <div className="docType mb-1">
                      {el?.name}{' '}
                      <span className="text">* {el?.description}</span>
                    </div>
                    <Button
                      onClick={() => showUpdateRequestModal(el)}
                      className="uploadBtn"
                    >
                      {t('upload')}
                    </Button>
                  </div>
                ))}
              </div>
            </div>
          )}
          <UpdateRequest
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            singleRequest={singleRequest}
            refetch={refetch}
            source={'reviewedDocuments'}
          />

          <div className="documentUpload">
            {AllDocuments?.map((item: any, index: any) =>
              !item || !item.fileUrl ? (
                <div key={index}>
                  <div className="fileTitle mb-2">
                    {item.name || 'Item Name'}
                  </div>
                  <div className="fileWrapper">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="fileType me-2">FILE</div>
                        <div>
                          <a
                            id="file"
                            onClick={() => showUploadRequestModal(item)}
                          >
                            <div style={{ fontWeight: 600, fontSize: 16 }}>
                              {t('Upload File')}
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={index}>
                  <div className="fileTitle mb-2">{item.name}</div>
                  <div className="fileWrapper">
                    <div className="d-flex justify-content-between">
                      <div className="d-flex">
                        <div className="fileType me-2">FILE</div>
                        <div>
                          <div className="fileName">
                            {getFileNameFromUrl(item.fileUrl)}
                          </div>
                          <div className="fileSize">N/A</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="downloadFile d-flex justify-content-between mt-2">
                    <div>
                      <a
                        href={item.fileUrl}
                        target="_blank"
                        className="me-2"
                        style={{
                          borderRight: '1px solid gray',
                          paddingRight: 10
                        }}
                      >
                        {t('view')}
                      </a>
                      {location.pathname === `/completed-loans/${id}` ? (
                        <>
                          {item.status == 'REJECTED' ? (
                            <a
                              id="file"
                              onClick={() => showUploadRequestModal(item)}
                            >
                              {t('upload')}
                            </a>
                          ) : (
                            <a href={item?.fileUrl} download>
                              {t('download')}
                            </a>
                          )}
                        </>
                      ) : (
                        <a href={item?.fileUrl} download>
                          {t('download')}
                        </a>
                      )}
                    </div>
                    <div
                      className=""
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        flex: 0.5,
                        alignItems: 'center'
                      }}
                    >
                      {item && item.fileUrl ? (
                        <a
                          id="file"
                          onClick={() => showReplaceRequestModal(item)}
                          style={{ color: 'red', textDecoration: 'none' }}
                        >
                          {t('Replace')}
                        </a>
                      ) : null}
                      {item.status == 'REJECTED' && (
                        <div className="d-flex">
                          <div>{NoticeIcon}</div>
                          <div className="comment">
                            <ul>
                              <li>{item.description}</li>
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
          <UploadDocumentRequest
            isUploadVisible={isUploadVisible}
            setIsUploadVisible={setIsUploadVisible}
            uploadRequest={uploadRequest}
            refetch={refetch}
          />
          <ReplaceDocument
            setIsModalVisible={setIsReplaceVisible}
            setIsUploadVisible={showUpdateRequestModal}
            refetch={refetch}
            isModalVisible={isReplaceVisible}
          />
          {/* additional document list */}
          {location.pathname === `/portfolio-loans/${id}` && (
            <>
              {requestedDocuments?.length == 0 ? (
                ''
              ) : (
                <div className="requestedDocuments">
                  <div className="reqDocTitle mb-3">
                    {t('additionalDocuments')}
                  </div>
                  <div className="containers">
                    {requestedDocuments?.map((el: any, index: any) => (
                      <div key={index}>
                        <div className="d-flex align-items-center mb-1">
                          <div className="reqDocContent me-2">{el?.name}</div>
                          <div className="reqDocDesc d-flex align-items-center">
                            <ul>
                              <li>* {el?.description}</li>
                            </ul>
                          </div>
                        </div>
                        <div className="comment">
                          {NoticeIcon}
                          <span className="ms-2">
                            {t('waitingForCustomer')}
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </>
          )}

          {/* request additional document button */}
          {location.pathname === `/portfolio-loans/${id}` && (
            <div className="additionalDocButton mt-5">
              <Button onClick={showModal}>
                {t('requestAdditionalDocument')}
              </Button>
              <AddedDocModal
                visible={visible}
                setVisible={setVisible}
                refetch={refetch}
              />
            </div>
          )}

          {/* generate pfi document */}
          {location.hash === '#documents' && (
            <div className="documentUpload docMarginRemoval">
              <div className="leadsCarDocuments">
                <div className="leadCarWrap">
                  <div className="title mb-2">{t('carDocuments')}</div>
                  <div className="documents">
                    <div className="name">
                      <div className="mb-1">PFI</div>
                      {singleLoan?.pfiUrl == undefined &&
                      fetchCarDocs?.carOffers[0]?.pfiDocumentUrl ==
                        undefined ? (
                        <Button
                          className="generateBtn"
                          onClick={showPfi}
                          // disabled={singleLoan?.status !== CAR_STATUS.PPI_PASS}
                        >
                          {t('generate')}
                        </Button>
                      ) : (
                        <a
                          href={
                            fetchCarDocs?.carOffers[0]?.pfiDocumentUrl !==
                            undefined
                              ? fetchCarDocs?.carOffers[0]?.pfiDocumentUrl
                              : singleLoan?.pfiUrl
                          }
                          target="_blank"
                        >
                          <Button className="ppiBtn">
                            PFI&nbsp;&nbsp; {DownloadIcon}
                          </Button>
                        </a>
                      )}
                    </div>
                    <GeneratePfiModal
                      generatePfi={generatePfi}
                      setGeneratePfi={setGeneratePfi}
                      loanRefetch={loanRefetch}
                    />
                    <div className="name">
                      <div className="mb-1 text-center">PPI</div>
                      <a
                        href={fetchCarDocs?.carOffers[0]?.ppiDocumentUrl}
                        target="_blank"
                      >
                        <Button className="ppiBtn">
                          PPI&nbsp;&nbsp; {DownloadIcon}
                        </Button>
                      </a>
                    </div>
                    <div className="name">
                      <div className="mb-1">
                        {t('inspectionAgreementContract')}
                      </div>
                      <Button
                        className="uploadBtn"
                        onClick={showUploadContractModal}
                      >
                        {t('upload')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      <UploadedContract
        refetch={refetch}
        isContractVisible={isContractVisible}
        setIsContractVisible={setIsContractVisible}
      />
    </StyledDocumentUpload>
  )
}
