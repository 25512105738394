import React, { useRef } from 'react'
import DOMPurify from 'dompurify'
import {
  RecipientInitials,
  Chat,
  ChatBox,
  ChatImageAttachment,
  AttachmentBox,
  ChatBoxMessage,
  ChatBoxSubText,
  ClosedConvo
} from './styles'

const URL_MATCH = /^(https:\/\/)?(www\.)?autochek.africa/i

interface ISingleChatProps {
  initials: string
  text: string
  type: 'TEXT' | 'IMAGE' | 'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'VOICE'
  url?: string
  direction: 'INBOUND' | 'OUTBOUND'
  time: string
}

export default function SingleChat({
  initials,
  text,
  url,
  type,
  direction,
  time
}: ISingleChatProps) {
  const imageAnchorRef = useRef<HTMLAnchorElement>(null)

  const openImageInNewTab = () => {
    imageAnchorRef?.current?.click()
  }

  if (!text && !url) {
    return null
  }

  const getSanitizedText = (text: string) => {
    const parts = text?.split(/[\n\r\t\s]/i)

    const processedParts = parts.map((part) => {
      const isLink = URL_MATCH.test(part)

      if (isLink) {
        const href =
          part.startsWith('https://') || part.startsWith('http://')
            ? part
            : `https://${part}`

        return `<a href="${href}" target='_blank'
        rel='noreferrer noopenner'>${part}</a>`
      } else {
        return part
      }
    })

    return DOMPurify.sanitize(processedParts.join(' '), {
      USE_PROFILES: { html: true },
      ADD_ATTR: ['href', 'rel', 'target']
    })
  }

  return (
    <Chat right={direction === 'OUTBOUND'}>
      <RecipientInitials>{initials}</RecipientInitials>
      <ChatBox showColor={direction === 'OUTBOUND'}>
        {type === 'IMAGE' && url && (
          <ChatImageAttachment imgUrl={url} onClick={openImageInNewTab}>
            <a
              target="_blank"
              rel="noreferrer noopenner"
              href={url}
              ref={imageAnchorRef}
            >
              {type}
            </a>
          </ChatImageAttachment>
        )}

        {type !== 'IMAGE' && type !== 'TEXT' && type !== 'VOICE' ? (
          <AttachmentBox>
            <a target="_blank" rel="noreferrer noopenner" href={url}>
              <img src={url} alt="whatsapp-images" width="100%" />
            </a>
          </AttachmentBox>
        ) : type == 'VOICE' ? (
          <AttachmentBox>
            <audio controls style={{ width: '100%' }}>
              <source src={url} type="audio/ogg" />
              <source src={url} type="audio/mpeg" />
            </audio>
          </AttachmentBox>
        ) : (
          ''
        )}

        {text && (
          <ChatBoxMessage
            dangerouslySetInnerHTML={{ __html: getSanitizedText(text) }}
          />
        )}
        <ChatBoxSubText>
          <span>Via: Whatsapp</span>
          <span>{time}</span>
        </ChatBoxSubText>
      </ChatBox>
    </Chat>
  )
}

interface IClosedConvoAlert {
  text: string
}

export const ClosedConvoAlert = ({ text }: IClosedConvoAlert) => {
  return (
    <ClosedConvo>
      <p>{text}</p>
    </ClosedConvo>
  )
}
