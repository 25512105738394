import { ECarTypes } from './actions'

export interface ICarRes {
  id: number
  imageUrl: string
  name: string
}
export interface ICarModel extends ICarRes {
  make: ICarRes
  makeId: number
  modelFeatures: any[]
  popular: boolean
  wheelType: string
}
export interface IPagination {
  total: number
  currentPage: number
  pageSize: number
}
export interface IMake {
  makeList: ICarRes[]
  pagination: IPagination
}
export interface IModel {
  modelList: ICarModel[]
  pagination: IPagination
}
export interface IBodyType {
  bodyTypeList: ICarRes[]
  pagination: IPagination
}
export interface ICar {
  make: IMake
  model: IModel
  body: IBodyType
}

interface IGetMake {
  type: ECarTypes.GET_CAR_MAKE_SUCCESS
  payload: IMake
}
interface IGetModel {
  type: ECarTypes.GET_CAR_MODEL_SUCCESS
  payload: IModel
}
interface IGetBody {
  type: ECarTypes.GET_CAR_BODY_SUCCESS
  payload: IBodyType
}

export type TActions = IGetMake | IGetModel | IGetBody
const initPagination = {
  total: 0,
  currentPage: 1,
  pageSize: 10
}

const initialState: ICar = {
  make: { makeList: [], pagination: initPagination },
  model: { modelList: [], pagination: initPagination },
  body: { bodyTypeList: [], pagination: initPagination }
}

const reducer = (state = initialState, action: TActions): ICar => {
  switch (action.type) {
    case ECarTypes.GET_CAR_MAKE_SUCCESS: {
      return {
        ...state,
        make: action.payload
      }
    }
    case ECarTypes.GET_CAR_MODEL_SUCCESS: {
      return {
        ...state,
        model: action.payload
      }
    }
    case ECarTypes.GET_CAR_BODY_SUCCESS: {
      return {
        ...state,
        body: action.payload
      }
    }

    default:
      return state
  }
}

export default reducer
