import get from 'lodash/get'
import { getUploadedImage } from '../settingsSection/selectors'
import { getProfileUrlOrDefault } from '../../utils/image'

export const getIsAuth = (state: Object) => get(state, 'auth.isAuth')

export const getRoles = (state: Object) => get(state, 'auth.userRoles')

export const getUserId = (state: Object) => get(state, 'auth.user.id')

export const getUserEmail = (state: Object) => get(state, 'auth.user.email')

export const getUserPhone = (state: Object) =>
  get(state, 'auth.user.primaryPhone')

export const getUserFirstName = (state: Object) =>
  get(state, 'auth.user.firstname')

export const getUserLastName = (state: Object) =>
  get(state, 'auth.user.lastname')

export const getUserToken = (state: Object) => get(state, 'auth.token')
export const getUserLocation = (state: Object) =>
  get(state, 'auth.userLocation')

export const getOtpToken = (state: Object) => get(state, 'auth.otpToken')

export const getUserProfileImage = (state: Object) => {
  const uploadedImage = getUploadedImage(state)
  const profileImage = get(state, 'auth.user.imageUrl')
  const firstname = getUserFirstName(state)
  const lastname = getUserLastName(state)
  const profileImageUrl = getProfileUrlOrDefault(
    profileImage,
    firstname,
    lastname
  )

  return uploadedImage ? uploadedImage : profileImageUrl
}

export const getSelectedCountry = (state: Object) => get(state, 'auth.country')

export const getSelectedCountryCurrency = (state: Object) => {
  const country = get(state, 'auth.country')

  if (country === 'GH') {
    return 'GH₵'
  } else if (country === 'KE') {
    return 'KSh'
  } else if (country === 'CI') {
    return 'CFA'
  } else if (country === 'UG') {
    return 'USh'
  } else {
    return '₦'
  }
}

export const getUserIdIfAgent = (state: Object) => {
  const userId = get(state, 'auth.user.id')
  const isAgent = get(state, 'auth.authority.roles').includes('CRM_AGENT')

  return isAgent ? userId : ''
}

export const isAgent = (state: Object) => {
  const isAgentUser = get(state, 'auth.authority.roles').includes('CRM_AGENT')

  return isAgentUser
}
export const isFulfillmentAgent = (state: Object) => {
  const isFulfillmentUser = get(state, 'auth.authority.roles').includes(
    'CRM_FULFILLMENT_AGENT'
  )

  return isFulfillmentUser
}

export const isManager = (state: Object) => {
  const isManagerUser = get(state, 'auth.authority.roles')?.includes(
    'CRM_MANAGER'
  )

  return isManagerUser
}

export const isAdmin = (state: Object) => {
  const isAdminUser = get(state, 'auth.authority.roles')?.includes('CRM_ADMIN')

  return isAdminUser
}

export const isPortfolio = (state: Object) => {
  const isPortfolioUser = get(state, 'auth.authority.roles')?.includes(
    'PORTFOLIO_MANAGER'
  )
  return isPortfolioUser
}

export const isLead = (state: Object) => {
  const isLeadUser = get(state, 'auth.authority.roles')?.includes(
    'LEAD_MANAGER'
  )
  return isLeadUser
}

export const canViewLoans = (state: Object) => {
  const canViewLoanUser = get(state, 'auth.authority.permissions')?.includes(
    'CAN_GET_ALL_LOANS'
  )
  const canViewLoanUserOnStaging = get(
    state,
    'auth.authority.permissions'
  )?.includes('GET_ALL_LOAN')

  return process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
    ? canViewLoanUserOnStaging
    : canViewLoanUser
}
