import React, { useState, useEffect } from 'react'
import OffersUpload from '../../PortfolioManagement/PortfolioDetails/OffersUpload'
import { StyledDisbursement } from './styles'
import { Collapse, Button } from 'react-bootstrap'
import ReviewedDocument from '../../PortfolioManagement/PortfolioDetails/ReviewedDocument'
import {
  ChevronTIcon,
  ChevronBIcon,
  TaskIcon
} from '../../PortfolioManagement/PortfolioDetails/svgs'
import {
  StyledDocumentUpload,
  StyledOffer
} from '../../PortfolioManagement/PortfolioDetails/styles'
import api from '../../../utils/api'
import {
  getUserToken,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
interface IDetailParam {
  id: string
}

export default function DisbursementDocument({ title }: any) {
  const [open, setOpen]: any = useState(false)
  const [state, setState] = useState(true)
  const queryClient = useQueryClient()
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const currency = useSelector(getSelectedCountryCurrency)
  const [isEquityPaid, setIsEquityPaid] = useState<any>([])
  const [getOffers, setGetOffers] = useState<any>([])
  const { t } = useTranslation()

  //@ts-ignore
  function handleClick(id) {
    //@ts-ignore
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }))
    setState((prevState) => !prevState)
  }

  const { data: singleLoan, status, refetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    { keepPreviousData: true, staleTime: 5000 }
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
      setIsEquityPaid(singleLoan)
      setGetOffers(singleLoan)
    }
  }, [singleLoan, queryClient, id])

  const onlyEquityPaid = isEquityPaid?.equities?.filter(
    //@ts-ignore
    (paid) => paid.amountPaid > 0
  )

  function getFileNameFromUrl(url: any) {
    var el = document.createElement('a')
    el.href = url
    return decodeURIComponent(el.pathname).split('/').pop()
  }

  // console.log(onlyEquityPaid)

  return (
    <StyledDisbursement>
      <div className="disbursementDocument">
        <div className="title mb-4">{title}</div>
        {getOffers?.offer === undefined ? (
          <StyledDocumentUpload>
            <div className="content">
              <div>{TaskIcon}</div>
              <div className="text ms-3">
                {t('thisCustomerDoesNotHaveAcceptedOffers')}.
              </div>
            </div>
          </StyledDocumentUpload>
        ) : (
          <StyledOffer>
            <div className="">
              <div className="offersUpload d-grid gap-4">
                <div>
                  <div className="header mb-2">
                    {getOffers?.offer?.partner?.name || 'N/A'}
                  </div>
                  <div className="offerDetails">
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('LoanAmount')}</div>
                      <div className="value">
                        {currency}
                        {getOffers?.offer?.approvedAmount.toLocaleString() ||
                          'N/A'}
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('Downpayment')}</div>
                      <div className="value">
                        {currency}
                        {Number(getOffers?.offer?.equity).toLocaleString() ||
                          'N/A'}
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('Tenure')}</div>
                      <div className="value">
                        {getOffers?.offer?.tenure || 'N/A'} mos.
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('InterestRate')}</div>
                      <div className="value">
                        {getOffers?.offer?.interest || 'N/A'}%
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('MonthlyEMI')}</div>
                      <div className="value">
                        {currency}
                        {Number(
                          getOffers?.offer?.monthlyRepayment
                        ).toLocaleString() || 'N/A'}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="head">{t('customerAcceptance')}</div>
                      <div className="value">{t('Yes')}</div>
                    </div>
                  </div>

                  <Button
                    onClick={() => setOpen(!open)}
                    aria-controls="example-collapse-text"
                    className="mt-3 mb-2 viewDocument"
                  >
                    <div className="d-flex align-items-center">
                      <div className="me-1">{t('viewDocuments')}</div>
                      <div>
                        {open ? (
                          <div>{ChevronTIcon}</div>
                        ) : (
                          <div>{ChevronBIcon}</div>
                        )}
                      </div>
                    </div>
                  </Button>

                  <Collapse in={open}>
                    <div id="example-collapse-text">
                      <div className="collapseContent">
                        <ReviewedDocument />
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className="disbursementWrapper d-grid gap-5">
                  <div className="d-flex carSourcing">
                    {onlyEquityPaid.length == 0 ? (
                      ''
                    ) : (
                      <div className="completed me-5 px-2">
                        {t('carSourcingComplete')}
                      </div>
                    )}

                    <div className="d-grid gap-3">
                      {onlyEquityPaid?.map((el: any, index: any) => (
                        <div className="carSourcing d-flex" key={index}>
                          <div className="amountPaid me-5">
                            <div>
                              {t('amountPaid')}:{' '}
                              <span>
                                {currency}
                                {el?.amountPaid.toLocaleString()}
                              </span>
                            </div>
                            <div>
                              {t('Date')}:{' '}
                              {new Date(el?.datePaid).toDateString()}
                            </div>
                          </div>
                          <div className="me-5">
                            <div className="pdfFile d-flex">
                              <div className="image me-2"></div>
                              <div>
                                <div className="name">
                                  {getFileNameFromUrl(el?.fileUrl)}
                                </div>
                                <div className="size">N/A</div>
                              </div>
                            </div>
                            <div className="links">
                              <a
                                href={el?.fileUrl}
                                target="_blank"
                                className="me-3"
                              >
                                {t('view')}
                              </a>
                              <a href={el?.fileUrl} download>
                                {t('download')}
                              </a>
                            </div>
                          </div>
                          <div className="result">{t('equityPaid')}</div>
                        </div>
                      ))}
                    </div>
                  </div>

                  {getOffers?.disbursment !== undefined && (
                    <div className="carSourcing d-flex">
                      <div className="completed me-5">{t('disbursement')}</div>
                      <div className="amountPaid me-5">
                        <div>
                          {t('amountDisbursed')}:{' '}
                          <span>
                            {currency}
                            {getOffers?.disbursment?.amount.toLocaleString()}
                          </span>
                        </div>
                        <div>
                          {t('Bank')}: {getOffers?.offer?.partner?.name}
                        </div>
                        <div>
                          {t('Date')}:{' '}
                          {new Date(
                            getOffers?.disbursment?.dateDisbursed
                          ).toDateString()}
                        </div>
                      </div>
                      <div className="me-5">
                        <div className="pdfFile d-flex">
                          <div className="image me-2"></div>
                          <div>
                            <div className="name">
                              {getFileNameFromUrl(
                                getOffers?.disbursment?.fileUrl
                              )}
                            </div>
                            <div className="size">N/A</div>
                          </div>
                        </div>
                        <div className="links">
                          <a
                            href={getOffers?.disbursment?.fileUrl}
                            target="_blank"
                            className="me-3"
                          >
                            {t('view')}
                          </a>
                          <a href={getOffers?.disbursment?.fileUrl} download>
                            {t('download')}
                          </a>
                        </div>
                      </div>
                      <div className="result">{t('loanDisbursed')}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </StyledOffer>
        )}
      </div>
    </StyledDisbursement>
  )
}
