import styled from 'styled-components'

export const StyledDisbursement = styled.div`
  .disbursementDocument {
    background: #f8f8fb;
    border-radius: 10px;
    min-height: 400px;
    height: auto;
    padding: 25px;

    .title {
      color: #30355b;
      font-weight: bold;
      font-size: 14px;
    }

    .disbursementWrapper {
      .carSourcing {
        .completed {
          color: #05a660;
          font-weight: 600;
          font-size: 13px;
          background: rgba(5, 166, 96, 0.1);
          border-radius: 34px;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 182px;
          min-width: 182px;
          width: auto;
          height: 38px;
        }
        .amountPaid {
          .div {
            color: #000000;
            font-size: 14px;
          }
          span {
            font-weight: 700;
          }
        }
        .pdfFile {
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.19);
          border-radius: 8px;
          min-width: 217px;
          width: auto;
          height: 52.05px;
          padding: 10px;
          .image {
            background: rgba(209, 55, 63, 0.1);
            border-radius: 2px;
            width: 24px;
            height: 24px;
            overflow: hidden;
          }
          .name {
            color: #30355b;
            font-weight: 600;
            font-size: 11px;
          }
          .size {
            color: #787878;
            font-weight: 500;
            font-size: 9px;
          }
        }
        .links {
          a {
            color: #000000;
            font-weight: 500;
            font-size: 11px;
            text-decoration: underline;
          }
        }
        .result {
          background: #05a660;
          border-radius: 45px;
          min-width: 167px;
          width: auto;
          height: 30px;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 13px;
        }
      }
    }
  }
`
