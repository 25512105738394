export const CREATE_LEAD = 'CREATE_LEAD'
export const CREATE_LEAD_SUCCESS = 'CREATE_LEAD_SUCCESS'
export const CREATE_LEAD_FAILURE = 'CREATE_LEAD_FAILURE'

export const FETCH_LEADS = 'FETCH_LEADS'
export const FETCH_LEADS_SUCCESS = 'FETCH_LEADS_SUCCESS'
export const FETCH_LEADS_FAILURE = 'FETCH_LEADS_FAILURE'

export const FETCH_LEAD = 'FETCH_LEAD'
export const FETCH_LEAD_SUCCESS = 'FETCH_LEAD_SUCCESS'
export const FETCH_LEAD_FAILURE = 'FETCH_LEAD_FAILURE'

export const UPDATE_LEAD = 'UPDATE_LEAD'
export const SAVE_MARKET_CAR = 'SAVE_MARKET_CAR'
export const UPDATE_LEAD_SUCCESS = 'UPDATE_LEAD_SUCCESS'
export const UPDATE_LEAD_FAILURE = 'UPDATE_LEAD_FAILURE'
export const DELETE_SERVICE_INTEREST = 'DELETE_SERVICE_INTEREST'

export const FETCH_AGENTS = 'FETCH_AGENTS'
export const FETCH_AGENTS_SUCCESS = 'FETCH_AGENTS_SUCCESS'
export const FETCH_AGENTS_FAILURE = 'FETCH_AGENTS_FAILURE'

export const LINK_USER = 'LINK_USER'
export const LINK_USER_SUCCESS = 'LINK_USER_SUCCESS'
export const LINK_USER_ERROR = 'LINK_USER_ERROR'

export const FETCH_APPOINTMENTS = 'LINK_USER'
export const FETCH_APPOINTMENTS_SUCCESS = 'FETCH_APPOINTMENTS_SUCCESS'
export const FETCH_APPOINTMENTS_ERROR = 'FETCH_APPOINTMENTS_ERROR'

export const CREATE_APPOINTMENT = 'CREATE_APPOINTMENT'
export const CREATE_BUY_CAR_APPOINTMENT = 'CREATE_BUY_CAR_APPOINTMENT'
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS'
export const CREATE_APPOINTMENT_FAILURE = 'CREATE_APPOINTMENT_FAILURE'

export const GET_SERVICE_INTERESTS = 'GET_SERVICE_INTERESTS'
export const GET_SERVICE_INTERESTS_SUCCESS = 'GET_SERVICE_INTERESTS_SUCCESS'
export const GET_SERVICE_INTERESTS_EERROR = 'GET_SERVICE_INTERESTS_EERROR'
export const SAVE_QUERY_OBJECT = 'SAVE_QUERY_OBJECT'

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS'
export const FETCH_LOCATIONS_SUCCESS = 'FETCH_LOCATIONS_SUCCESS'
export const FETCH_LOCATIONS_EERROR = 'FETCH_LOCATIONS_EERROR'

export const GET_CAR_DATA = 'GET_CAR_DATA'
export const GET_CAR_DATA_SUCCESS = 'GET_CAR_DATA_SUCCESS'
export const GET_CAR_DATA_ERROR = 'GET_CAR_DATA_ERROR'

export const GET_CAR_MEDIA = 'GET_CAR_MEDIA'
export const SAVE_ACTIVITY_FILTER_NAME = 'SAVE_ACTIVITY_FILTER_NAME'
export const GET_CAR_MEDIA_SUCCESS = 'GET_CAR_MEDIA_SUCCESS'
export const GET_CAR_MEDIA_ERROR = 'GET_CAR_MEDIA_ERROR'

export const GET_CAR_INSPECTION = 'GET_CAR_INSPECTION'
export const GET_CAR_INSPECTION_SUCCESS = 'GET_CAR_INSPECTION_SUCCESS'
export const GET_CAR_INSPECTION_ERROR = 'GET_CAR_INSPECTION_ERROR'

export const GET_SIMILAR_CAR = 'GET_SIMILAR_CAR'
export const GET_SIMILAR_CAR_SUCCESS = 'GET_SIMILAR_CAR_SUCCESS'
export const GET_SIMILAR_CAR_ERROR = 'GET_SIMILAR_CAR_ERROR'

export const GET_ALL_VEHICLE_DETAILS = 'GET_ALL_VEHICLE_DETAILS'
export const GET_ALL_VEHICLE_DETAILS_SUCCESS = 'GET_ALL_VEHICLE_DETAILS_SUCCESS'
export const GET_ALL_VEHICLE_DETAILS_ERROR = 'GET_ALL_VEHICLE_DETAILS_ERROR'

export const SAVE_SEARCH_QUERY = 'SAVE_SEARCH_QUERY'
export const GET_CAR_OWNER = 'GET_CAR_OWNER'
export const GET_CAR_OWNER_SUCCESS = 'GET_CAR_OWNER_SUCCESS'
export const GET_CAR_OWNER_ERROR = 'GET_CAR_OWNER_ERROR'

export const GET_CAR_DEALER = 'GET_CAR_DEALER'
export const GET_CAR_DEALER_SUCCESS = 'GET_CAR_DEALER_SUCCESS'
export const GET_CAR_DEALER_ERROR = 'GET_CAR_DEALER_ERROR'

export const PERSIST_PREV_STATE = 'PERSIST_PREV_STATE'
export const CLEAR_PREV_STATE = 'CLEAR_PREV_STATE'
export const GET_CARS_FROM_MARKET = 'GET_CARS_FROM_MARKET'
export const GET_SALES_OFFICERS = 'GET_SALES_OFFICERS'

export const GET_CAR_INSPECTOR = 'GET_CAR_INSPECTOR'
export const GET_CAR_INSPECTOR_SUCCESS = 'GET_CAR_INSPECTOR_SUCCESS'
export const GET_CAR_INSPECTOR_ERROR = 'GET_CAR_INSPECTOR_ERROR'

export const UPDATE_SERVICE_INTEREST = 'UPDATE_SERVICE_INTEREST '
export const UPDATE_SERVICE_INTEREST_SUCCESS = 'UPDATE_SERVICE_INTEREST_SUCCESS'
export const UPDATE_SERVICE_INTEREST_FAILURE = 'UPDATE_SERVICE_INTEREST_FAILURE'
export const SAVE_SCHEDULE_OBJECT = 'SAVE_SCHEDULE_OBJECT'
export const SAVE_CAR_APPOINTMENT_DATA = 'SAVE_CAR_APPOINTMENT_DATA'
export const CANCEL_CAR_APPOINTMENT_DATA = 'CANCEL_CAR_APPOINTMENT_DATA'
export const START_ADD_BUY_CAR_INTEREST_LOADER =
  'START_ADD_BUY_CAR_INTEREST_LOADER'
export const STOP_ADD_BUY_CAR_INTEREST_LOADER =
  'STOP_ADD_BUY_CAR_INTEREST_LOADER'
export const SAVE_MARKET_CAR_PAGINATION = 'SAVE_MARKET_CAR_PAGINATION'
export const GET_CURRENT_ACTIVITY = 'GET_CURRENT_ACTIVITY'
export const SAVE_CURRENT_ACTIVITY = 'SAVE_CURRENT_ACTIVITY'
export const STOP_ACTIVITY_LOADER = 'STOP_ACTIVITY_LOADER'
export const START_ACTIVITY_LOADER = 'START_ACTIVITY_LOADER'
export const SAVE_ACTIVITY_PAGINATION = 'SAVE_ACTIVITY_PAGINATION'
export const SAVE_ACTIVITY_TYPE = 'SAVE_ACTIVITY_TYPE'
export const SAVE_SALES_OFFICERS = 'SAVE_SALES_OFFICERS'
export const SAVE_AGENT_FILTERED_ARRAY = 'SAVE_AGENT_FILTERED_ARRAY'
export const CLEAR_AGENT_FILTERED_ARRAY = 'CLEAR_AGENT_FILTERED_ARRAY'
export const SAVE_AGENT_DATE_OBJECT = 'SAVE_AGENT_DATE_OBJECT'
export const CLEAR_AGENT_DATE_OBJECT = 'CLEAR_AGENT_DATE_OBJECT'
export const GET_AGENT_TASKS = 'GET_AGENT_TASKS'
export const SAVE_AGENT_TASKS = 'SAVE_AGENT_TASKS'
export const GET_CRM_NOTIFICATIONS = 'GET_CRM_NOTIFICATIONS'
export const SAVE_CRM_NOTIFICATIONS = 'SAVE_CRM_NOTIFICATIONS'
export const UPDATE_CRM_NOTIFICATION = 'UPDATE_CRM_NOTIFICATION'
export const ADD_SAVE_BTN = 'ADD_SAVE_BTN'
export const HIDE_SAVE_BTN = 'HIDE_SAVE_BTN'
export const ADD_LEAD_NOTE = 'ADD_LEAD_NOTE'
export const GET_LEAD_NOTES = 'GET_LEAD_NOTES'
export const SAVE_LEAD_NOTES = 'SAVE_LEAD_NOTES'
export const SAVE_SUMMARY_NOTES = 'SAVE_SUMMARY_NOTES'
export const SAVE_LOAN_CAR = 'SAVE_LOAN_CAR'
export const SAVE_LOAN_NOTE_PAGINATION = 'SAVE_LOAN_NOTE_PAGINATION'
export const SAVE_BANK_DEALER_SEARCH = 'SAVE_BANK_DEALER_SEARCH'
export const SAVE_AGENT_IDS = 'SAVE_AGENT_IDS'
export const ASSIGN_LEADS_TO_AGENT = 'ASSIGN_LEADS_TO_AGENT'
export const SAVE_ASSIGNED_AGENT = 'SAVE_ASSIGNED_AGENT'
export const EMPTY_ASSIGNED_LEADS = 'EMPTY_ASSIGNED_LEADS'
export const SAVE_BANKS = 'SAVE_BANKS'

export const emptyAssignedList = () => ({
  type: EMPTY_ASSIGNED_LEADS,
  


})
export const saveAssignedAgent = (payload) => ({
  type: SAVE_ASSIGNED_AGENT,
  payload,


})
export const sendAssignedLeadsToAgent = () => ({
  type: ASSIGN_LEADS_TO_AGENT,
})
export const saveSummaryNotes = (payload) => ({
  type: SAVE_SUMMARY_NOTES,
  payload,


})
export const getLeadNotes = (payload,typeOfNote, currentPage) => ({
  type: GET_LEAD_NOTES,
  payload,
  typeOfNote,
  currentPage

})
export const addLeadNote = (payload) => ({
  type: ADD_LEAD_NOTE,
  payload

})
export const saveLeadNotes = (payload) => ({
  type: SAVE_LEAD_NOTES,
  payload

})
export const activateUserIsFulfilmentAgent = () => ({
  type: ADD_SAVE_BTN,

})
export const deactivateUserIsFulfilmentAgent = () => ({
  type: HIDE_SAVE_BTN,

})
export const updateCrmNotification = (payload, id) => ({
  type: UPDATE_CRM_NOTIFICATION,
  payload,
  id
})
export const saveCrmNotifications = (payload) => ({
  type: SAVE_CRM_NOTIFICATIONS,
  payload
})
export const getCrmNotifications = (payload) => ({
  type: GET_CRM_NOTIFICATIONS,
  payload
})
export const saveAgentsTasks = (payload) => ({
  type: SAVE_AGENT_TASKS,
  payload
})

export const getAgentTasks = (queryType) => ({
  type: GET_AGENT_TASKS,
  queryType
})
export const saveAgentFilterDate = (payload) => ({
  type: SAVE_AGENT_DATE_OBJECT,
  payload
})
export const clearAgentFilterDate = (payload) => ({
  type: CLEAR_AGENT_DATE_OBJECT,
  payload
})
export const clearAgentArray = (payload) => ({
  type: CLEAR_AGENT_FILTERED_ARRAY,
  payload
})
export const saveAgentFilteredArray = (payload) => ({
  type: SAVE_AGENT_FILTERED_ARRAY,
  payload
})
export const saveSalesOfficerSuccess = (payload) => ({
  type: SAVE_SALES_OFFICERS,
  payload
})
export const getSalesOfficer = (data) => ({
  type: GET_SALES_OFFICERS,
  data
})
export const saveCarAppointmentData = (data) => ({
  type: SAVE_CAR_APPOINTMENT_DATA,
  data
})
export const saveActivityType = (payload) => ({
  type: SAVE_ACTIVITY_TYPE,
  payload
})
export const saveActivityPagination = (payload) => ({
  type: SAVE_ACTIVITY_PAGINATION,
  payload
})
export const saveLoanNotePagination = (payload) => ({
  type: SAVE_LOAN_NOTE_PAGINATION,
  payload
})
export const stopActivityLoader = (data) => ({
  type: STOP_ACTIVITY_LOADER,
  data
})
export const startActivityLoader = (data) => ({
  type: START_ACTIVITY_LOADER,
  data
})
export const saveActivitySuccess = (payload) => ({
  type: SAVE_CURRENT_ACTIVITY,
  payload
})
export const getCurrentActivity = (queryType) => ({
  type: GET_CURRENT_ACTIVITY,
  queryType,
})

export const cancelCarAppointmentData = () => ({
  type: CANCEL_CAR_APPOINTMENT_DATA
})
export const startBuyLoader = () => ({
  type: START_ADD_BUY_CAR_INTEREST_LOADER
})
export const stoptBuyLoader = () => ({
  type: STOP_ADD_BUY_CAR_INTEREST_LOADER
})
export const createLead = (details, queries) => ({
  type: CREATE_LEAD,
  details,
  queries
})

export const createLeadSuccess = (payload) => ({
  type: CREATE_LEAD_SUCCESS,
  payload
})

export const saveMarketCarPagination = (payload) => ({
  type: SAVE_MARKET_CAR_PAGINATION,
  payload
})
export const saveSearchQueryFromAction = (payload) => ({
  type: SAVE_SEARCH_QUERY,
  payload
})

export const createLeadFailure = () => ({
  type: CREATE_LEAD_FAILURE
})
export const saveScheduleObject = (payload) => ({
  type: SAVE_SCHEDULE_OBJECT,
  payload
})
export const saveBankDealerSearch = (payload) => ({
  type: SAVE_BANK_DEALER_SEARCH,
  payload
})

export const fetchLeads = (queries) => ({
  type: FETCH_LEADS,
  queries
})

export const fetchLeadsSuccess = (payload) => ({
  type: FETCH_LEADS_SUCCESS,
  payload
})

export const fetchLeadsFailure = () => ({
  type: FETCH_LEADS_FAILURE
})

export const fetchLead = (id) => ({
  type: FETCH_LEAD,
  id
})

export const fetchLeadSuccess = (payload) => ({
  type: FETCH_LEAD_SUCCESS,
  payload
})

export const fetchLeadFailure = () => ({
  type: FETCH_LEAD_FAILURE
})

export const updateLead = (details, id) => ({
  type: UPDATE_LEAD,
  details,
  id
})

export const updateLeadSuccess = (payload) => ({
  type: UPDATE_LEAD_SUCCESS,
  payload
})

export const updateLeadFailure = () => ({
  type: UPDATE_LEAD_FAILURE
})

export const fetchAgents = (queries) => ({
  type: FETCH_AGENTS,
  queries
})
export const saveMultiAgents = (agentIds) => ({
  type: SAVE_AGENT_IDS,
  agentIds
})

export const fetchAgentsSuccess = (payload) => ({
  type: FETCH_AGENTS_SUCCESS,
  payload
})

export const fetchAgentsFailure = () => ({
  type: FETCH_AGENTS_FAILURE
})

export const linkUser = () => ({
  type: LINK_USER
})

export const linkUserSucces = (payload) => ({
  type: LINK_USER_SUCCESS,
  payload
})

export const linkUserError = () => ({
  type: LINK_USER_ERROR
})

export const fetchAppointments = () => ({
  type: FETCH_APPOINTMENTS
})

export const fetchAppointmentsSuccess = (payload) => ({
  type: FETCH_APPOINTMENTS_SUCCESS,
  payload
})

export const fetchAppointmentsFailure = () => ({
  type: FETCH_APPOINTMENTS
})

export const createAppointment = (payload = undefined) => ({
  type: CREATE_APPOINTMENT,
  payload
})

export const createAppointmentsSuccess = (payload) => ({
  type: CREATE_APPOINTMENT_SUCCESS,
  payload
})

export const createAppointmentsFailure = () => ({
  type: CREATE_APPOINTMENT_FAILURE
})

export const getServiceInterests = (id) => ({
  type: GET_SERVICE_INTERESTS,
  id
})
export const saveQueryObject = (payload) => ({
  type: SAVE_QUERY_OBJECT,
  payload
})

export const getServiceInterestsSuccess = (payload) => ({
  type: GET_SERVICE_INTERESTS_SUCCESS,
  payload
})
export const deleteServiceInterest = (id) => ({
  type: DELETE_SERVICE_INTEREST,
  id
})
export const createBuyCarApp = (payload) => ({
  type: CREATE_BUY_CAR_APPOINTMENT,
  payload
})
export const saveFilterName = (payload) => ({
  type: SAVE_ACTIVITY_FILTER_NAME,
  payload
})

export const getServiceInterestsError = () => ({
  type: GET_SERVICE_INTERESTS_EERROR
})

export const fetchLocations = () => ({
  type: FETCH_LOCATIONS
})

export const fetchLocationsSucces = (payload) => ({
  type: FETCH_LOCATIONS_SUCCESS,
  payload
})

export const fetchLocationsError = () => ({
  type: FETCH_LOCATIONS_EERROR
})

export const getCarData = (carId) => ({
  type: GET_CAR_DATA,
  carId
})
export const getMarketPlaceCars = (query) => ({
  type: GET_CARS_FROM_MARKET,
  query
})

export const getCarSuccess = (payload) => ({
  type: GET_CAR_DATA_SUCCESS,
  payload
})
export const saveBanksToStore = (payload) => ({
  type: SAVE_BANKS,
  payload
})

export const getCarError = () => ({
  type: GET_CAR_DATA_ERROR
})

export const getCarMediaData = (carId) => ({
  type: GET_CAR_MEDIA,
  carId
})

export const getCarMediaSuccess = (payload, carId) => ({
  type: GET_CAR_MEDIA_SUCCESS,
  payload,
  carId
})

export const getCarMediaError = () => ({
  type: GET_CAR_MEDIA_ERROR
})

export const getCarInspectionDetails = (carId) => ({
  type: GET_CAR_INSPECTION,
  carId
})

export const getCarInspectionSuccess = (payload) => ({
  type: GET_CAR_INSPECTION_SUCCESS,
  payload
})

export const getCarInspectionError = () => ({
  type: GET_CAR_INSPECTION_ERROR
})

export const getSimilarCarDetails = (carId) => ({
  type: GET_SIMILAR_CAR,
  carId
})

export const getSimilarCarSuccess = (payload, carId) => ({
  type: GET_SIMILAR_CAR_SUCCESS,
  payload,
  carId
})

export const getSimilarCarError = () => ({
  type: GET_SIMILAR_CAR_ERROR
})

export const getAllVehicleDetails = (carIds) => ({
  type: GET_ALL_VEHICLE_DETAILS,
  carIds
})

export const getAllVehicleDetailsSuccess = () => ({
  type: GET_ALL_VEHICLE_DETAILS_SUCCESS
})
export const saveLoanCarId = (payload) => ({
  type: SAVE_LOAN_CAR,
  payload
})

export const getAllVehicleDetailsError = () => ({
  type: GET_ALL_VEHICLE_DETAILS_ERROR
})

export const getCarOwner = (userId, carId) => ({
  type: GET_CAR_OWNER,
  userId,
  carId
})

export const getCarOwnerSuccess = (payload, carId) => ({
  type: GET_CAR_OWNER_SUCCESS,
  payload,
  carId
})

export const getCarOwnerError = () => ({
  type: GET_CAR_OWNER_ERROR
})

export const getCarDealer = (userId, carId) => ({
  type: GET_CAR_DEALER,
  userId,
  carId
})

export const getCarDealerSuccess = (payload, carId) => ({
  type: GET_CAR_DEALER_SUCCESS,
  payload,
  carId
})

export const getCarDealerError = () => ({
  type: GET_CAR_DEALER_ERROR
})

export const savePrevState = (payload) => ({
  type: PERSIST_PREV_STATE,
  payload
})

export const clearPrevState = () => ({
  type: PERSIST_PREV_STATE
})

export const getCarInspector = (id, carId) => ({
  type: GET_CAR_INSPECTOR,
  id,
  carId
})

export const getCarInspectorSuccess = (payload, carId) => ({
  type: GET_CAR_INSPECTOR_SUCCESS,
  payload,
  carId
})

export const getCarInspectorError = () => ({
  type: GET_CAR_INSPECTOR_ERROR
})
export const getMarketPlaceCarsSuccess = (payload) => ({
  type: SAVE_MARKET_CAR,
  payload
})

export const updateServiceInterest = (details, id, leadId) => ({
  type: UPDATE_SERVICE_INTEREST,
  details,
  id,
  leadId
})

export const updateServiceInterestSuccess = (payload) => ({
  type: UPDATE_SERVICE_INTEREST_SUCCESS,
  payload
})

export const updateServiceInterestFailure = () => ({
  type: UPDATE_SERVICE_INTEREST_FAILURE
})
