import React from 'react'

const ServiceIcon = () => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 8V14"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 12L9 14L7 12"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 5.5H2.5C1.94772 5.5 1.5 5.94772 1.5 6.5V15.5C1.5 16.0523 1.94772 16.5 2.5 16.5H15.5C16.0523 16.5 16.5 16.0523 16.5 15.5V6.5C16.5 5.94772 16.0523 5.5 15.5 5.5Z"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.63672 6L3.23672 2.094C3.31532 1.91708 3.44358 1.76677 3.60595 1.66133C3.76831 1.55589 3.95779 1.49985 4.15139 1.5H13.8514C14.0449 1.49998 14.2342 1.55608 14.3964 1.66151C14.5587 1.76695 14.6868 1.91718 14.7654 2.094L16.3281 5.93733"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9 5.5V1.5"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ServiceIcon
