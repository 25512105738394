import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles'
import { Props } from './types'

type AlertProps = {
  onClose: () => void
  severity: 'success' | 'info' | 'warning' | 'error'
  children?: React.ReactChild | React.ReactChild[]
}

function Alert({ onClose, severity, ...props }: AlertProps) {
  return (
    <MuiAlert
      elevation={6}
      variant="filled"
      onClose={onClose}
      severity={severity}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2)
    }
  }
}))

const CustomizedSnackbars = ({
  message,
  severity,
  show,
  hideSnackbar
}: Props) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={show}
        autoHideDuration={16000}
        onClose={hideSnackbar}
      >
        <Alert onClose={hideSnackbar} severity={severity}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  )
}

export default CustomizedSnackbars
