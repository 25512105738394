import React, { useState } from 'react'
import { StyledOffer } from './styles'
import { Collapse, Button } from 'react-bootstrap'
import { ChevronTIcon, ChevronBIcon, TaskIcon } from './svgs'
import ReviewedDocument from './ReviewedDocument'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from '../../../utils/api'
import {
  getUserToken,
  getSelectedCountry,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function OffersUpload() {
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const [open, setOpen]: any = useState({})
  const [state, setState] = useState(true)
  const { t } = useTranslation()

  const { data: fetchOffer } = useQuery('fetchOffer', () =>
    api.fetchOffers(token, id)
  )

  function handleClick(id: any) {
    //@ts-ignore
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }))
    setState((prevState) => !prevState)
  }

  return (
    <StyledOffer>
      {fetchOffer?.offers == undefined || fetchOffer?.offers.length == 0 ? (
        <div className="content">
          <div>{TaskIcon}</div>
          <div className="text ms-3">{t('thisCustomerDoesNotOffersYet')}.</div>
        </div>
      ) : (
        <div className="offersUpload d-grid gap-4 mt-5">
          {fetchOffer?.offers?.map((el: any, index: any) => (
            <div key={index}>
              <div className="header mb-2">{el?.partner?.name || 'N/A'}</div>
              <div className="offerDetails">
                <div
                  className="text-center"
                  style={{ borderRight: '1px solid #e9e9e9' }}
                >
                  <div className="head">{t('LoanAmount')}</div>
                  <div className="value">
                    {currency}
                    {el?.approvedAmount.toLocaleString() || 'N/A'}
                  </div>
                </div>
                <div
                  className="text-center"
                  style={{ borderRight: '1px solid #e9e9e9' }}
                >
                  <div className="head">{t('Downpayment')}</div>
                  <div className="value">
                    {currency}
                    {el.equity.toLocaleString() || 'N/A'}
                  </div>
                </div>
                <div
                  className="text-center"
                  style={{ borderRight: '1px solid #e9e9e9' }}
                >
                  <div className="head">{t('Tenure')}</div>
                  <div className="value">{el.tenure || 'N/A'} mos.</div>
                </div>
                <div
                  className="text-center"
                  style={{ borderRight: '1px solid #e9e9e9' }}
                >
                  <div className="head">{t('InterestRate')}</div>
                  <div className="value">{el?.interest} %</div>
                </div>
                <div
                  className="text-center"
                  style={{ borderRight: '1px solid #e9e9e9' }}
                >
                  <div className="head">{t('MonthlyEMI')}</div>
                  <div className="value">
                    {currency}
                    {Number(el?.monthlyRepayment).toLocaleString() || 'N/A'}
                  </div>
                </div>
                <div className="text-center">
                  <div className="head">{t('customerAcceptance')}</div>
                  <div className="value">
                    {el?.status == 'pending' ? t('Yes') : t('No')}
                  </div>
                </div>
              </div>

              <Button
                onClick={() => handleClick(index)}
                aria-controls="example-collapse-text"
                className="mt-3 mb-2 viewDocument"
              >
                <div className="d-flex align-items-center">
                  <div className="me-1">{t('viewDocuments')}</div>
                  <div>
                    {open[index] ? (
                      <div>{ChevronTIcon}</div>
                    ) : (
                      <div>{ChevronBIcon}</div>
                    )}
                  </div>
                </div>
              </Button>

              <Collapse in={open[index]}>
                <div key={index} id="example-collapse-text">
                  <div className="collapseContent">
                    <ReviewedDocument />
                  </div>
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      )}
    </StyledOffer>
  )
}
