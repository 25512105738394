import styled from 'styled-components'
import Modal from '@material-ui/core/Modal'
import SearchI from '../../images/search.svg'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import CreateLeadSvg from '../../images/create_lead.svg'
import CarlendarIcon from '../../images/calendar2.svg'
import BellIcon from '../../images/bell2.svg'

export const CreateLeadBtn = styled.div`
  width: 156px;
  height: 40px;
  background-size: contain;
  background-image: url(${CreateLeadSvg});
  background-repeat: no-repeat;
  margin-top: 24px;
  margin-left: 16px;
  cursor: pointer;
`
export const StyledSelect = styled.div`
  label {
    height: 21px;
    line-height: 21px;
    display: block;
    font-size: 14px;
    margin-bottom: 4px;
    margin-top: 16px;
    color: #8f99a6;
  }
  select {
    height: 45px;
  }
`

export const CarlendarIconBtn = styled.div`
  width: 32px;
  height: 30px;
  background-size: contain;
  background-image: url(${CarlendarIcon});
  background-repeat: no-repeat;
  margin-top: 28px;
  margin-left: 36px;
  cursor: pointer;
`
export const BellIconBtn = styled.div<{ newNotificationCount: number }>`
  position: relative;
  width: 30px;
  height: 30px;
  background-size: contain;
  background-image: url(${BellIcon});
  background-repeat: no-repeat;
  margin-top: 28px;
  margin-left: 30px;
  cursor: pointer;

  &::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    height: fit-content;
    padding: 3px;
    font-size: 10px;
    border-radius: 50%;
    ${({ newNotificationCount }) =>
      newNotificationCount
        ? `content: '${
            newNotificationCount > 99 ? '99+' : newNotificationCount
          }'`
        : ''};
    color: #ffffff;
    font-weight: bold;
    background: red;
    top: -2px;
    right: -8px;
  }
`

export const CreateLeadFormContainer = styled.form`
  width: 800px;
  padding: 15px;
  height: auto;
  background-color: ${({ theme }) => theme.palette.common.white};
  @media (max-width: 768px){
    width: 400px
  }
`
export const LeadFormGridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  @media (max-width: 768px){
  grid-template-columns: repeat(1, 1fr);
  }
`

export const StyledModal = styled(Modal)`
  overflow: scroll;
`

export const LeadsContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.palette.neutral.ghostWhite};
`

export const LeadsTopSection = styled.div`
  width: 100%;
  height: 81px;
  display: flex;
`

export const LeadsPageTitile = styled.div`
  height: 36px;
  margin-top: 27px;
  margin-right: 24px;
  font-size: ${({ theme }) => theme.font.size.H4};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
`

export const SearchContainer = styled.div`
  width: 811px;
  height: 42px;
  box-sizing: border-box;
  margin-top: 24px;
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.whisper}`};
  cursor: pointer;
`

export const SearchContentInactive = styled.span`
  height: 20px;
  line-height: 20px;
  margin-top: 11px;
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.palette.neutral.heather};
`

export const SearchIcon = styled.div`
  width: 16px;
  height: 16px;
  margin: 13px 19px 0px 18px;
  background-image: url(${SearchI});
`
export const SearchIcon2 = styled.div`
  width: 16px;
  height: 16px;
  margin: 13px 19px 0px 18px;
  background-image: url(${SearchI});
  position: absolute;
  top: 14px;
  right: 1px;
`
export const LoaderCover = styled.div`
  padding: 10px;
  min-height: 139px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.neutral.gray93};
`

export const LeadsMiddleSection = styled.div`
  width: 100%;
  height: 61px;
  display: flex;
  justify-content: space-between;
`

export const LeadsMiddleSectionText = styled.div`
  height: 21px;
  line-height: 21px;
  margin-top: 19px;
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.palette.neutral.manatee};
`

export const LeadsMiddleSectionButtons = styled.div`
  width: 250px;
  height: 31px;
  margin-top: 14px;
`

export const Table = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  width: 100%;

  & a {
    color: ${({ theme }) => theme.palette.common.black};
    text-decoration: none;
  }
`

export const TableHeader = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.someGray};
  border: 1px solid #e9e9e9;
  width: 100%;
  min-width: 600px;
  height: 48px;
  padding: 16px 25px;
  display: flex;
`

export const HeaderIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 10px;
`

export const HeaderText = styled.div`
  height: 16px;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.palette.neutral.heather};
`

export const HeaderIconText = styled.div<{ long?: boolean; short?: boolean }>`
  display: flex;
  line-height: 16px;
  margin-right: 31px;
  width: ${({ long, short }) => (long ? '230px' : short ? '90px' : '150px')};
`

export const CheckBoxWrap = styled.div`
  margin-right: 34px;
  height: 16px;
  line-height: 10px;
`
export const CheckBoxWrapTwo = styled.div`
  margin-right: 34px;
  display: 'flex';
  align-items: 'center';
  min-height: '100px';
`

export const TableRow = styled.div`
  background-color: ${({ theme }) => theme.palette.common.white};
  border-bottom: 1px solid #ebecf0;
  height: 56px;
  padding: 20px 0px 16px 25px;
  display: flex;

  &:hover {
    background-color: #f8f8f8;
  }
`

export const TableCell = styled.div<{
  long?: boolean
  capitalize?: boolean
  short?: boolean
}>`
  margin-right: 32px;
  word-break: break-word;
  width: ${({ long, short }) => (long ? '230px' : short ? '90px' : '150px')};
  text-transform: ${({ capitalize }) => capitalize && 'capitalize'};
`

export const SearchDropDown = styled.div`
  width: 811px;
  height: 653px;
  background-color: ${({ theme }) => theme.palette.common.white};
  display: flex;
`

export const SearchLeftFilters = styled.div`
  width: 240px;
  height: 100%;
  background: #f8faf9;
`

export const SearchRight = styled.div`
  width: 571px;
  padding: 0 16px;
`

export const FiltersTitle = styled.div`
  width: 100%;
  height: 100%;
  font-size: ${({ theme }) => theme.font.size.xs};
  color: #ababab;
  text-align: center;
  height: 46px;
  padding: 16px 0px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const FiltersDivider = styled.div`
  height: 1px;
  width: 218px;
  margin: 0 auto;
  background-color: #e9e9e9;
`

export const FilterTitle = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: #818487;
  margin-left: 17px;
  height: 51px;
  padding: 16px 0px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const ButtonsContainer = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 124px;
`

export const SubmitButton = styled(Button)`
  width: 125px;
  height: 40px;
  text-transform: capitalize;
  border-radius: 4px;
  color: #31355b;
  margin-left: 15px;
  background-color: ${({ theme }) => theme.palette.neutral.kournikova};

  &:hover {
    background-color: ${({ theme }) => theme.palette.neutral.kournikova};
  }
`

export const ClearButton = styled(Button)`
  width: 140px;
  height: 40px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.common.black};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  background-color: ${({ theme }) => theme.palette.common.white};

  &:hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`

export const FilterTag = styled(Chip)`
  text-align: center;
  flexwrap: wrap;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: 32px;
  margin: 5px 10px 5px 5px;
  min-width: 100px;
  padding: 6px;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.secondary.main};
  background: #edeffc;
`

export const ShowNameFilters = styled.div`
  width: 539px;
  height: 271px;
  padding: 16px;
  border: 1px solid #e6eaf0;
`

export const SelectFilterOperatorText = styled.div`
  font-size: ${({ theme }) => theme.font.size.xs};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  line-height: 14px;
  color: #aeb6be;
  margin-bottom: 12px;
`

export const FilterOperatorContainer = styled.div`
  display: flex;
  margin-bottom: 12px;
`

export const FilterOperatorTitle = styled.div`
  margin-left: 12px;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const PaginationContainer = styled.div`
  position: absolute;
  width: 100%;
  padding-right: 20px;
  display: flex;
  justify-content: center;
  padding: 15px 63px 15px 0px;
`

export const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export const InputError = styled.div`
  color: #e84a29;
  font-size: 10px;
  margin-bottom: 5px;
`
