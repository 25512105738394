import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1em',
    background: '#ffffff',
    color: '#A1AACE',
    boxShadow: 'inset 0px -1px 0px rgba(202, 208, 217, 0.4)'
  },
  backBtn: {
    textDecoration: 'none',
    border: 'none',
    color: '#A1AACE',
    fontSize: '16px',
    fontWeight: 'bold'
  },
  headerSection: {
    display: 'flex',
    alignItems: 'center'
  },
  nameInitials: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: '1.2em',
    width: '32px',
    height: '32px',
    color: '#ffffff',
    background: '#A1AACE',
    borderRadius: '50%'
  },
  userDetails: {
    background: '#ffffff',
    padding: '1em',
    boxShadow: '0 5px 25px -10px rgba(0, 0, 0, 0.06)',
    position:'sticky',
    top:'0px'
  },
  userDetailsSection: {
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      display: 'flex',
      alignItems: 'center'
    },
    '&:last-child': {
      marginTop: '1em'
    }
  },
  userDetailsTopLeft: {
    display: 'flex',
    alignItems: 'center',
    '& p': {
      color: '#0F1A4F',
      margin: '0 0 0 1.5em'
    }
  },
  userDetailsTopRight: {
    display: 'flex',
    alignItems: 'center'
  },
  userDetailsBottomRight: {
    display: 'flex',
    color: '#A1AACE',
    flexBasis:'74%',
    justifyContent:'flex-end'
  },
  loanUserName: {
    margin: '0',
    color: '#0F1A4F',
    fontSize: '24px'
  },
  currentPhase: {
    padding: '0.5em 1.2em',
    background: 'rgba(239, 200, 62, 0.5)',
    borderRadius: '2px',
    color: '#31355B',
    fontWeight: 'bold'
  },
  currentPhaseInfo: {
    color: '#31355B',
    margin: '0 2.5em 0 0.8em',
    fontWeight: 'bold'
  },
  agentName: {
    fontWeight: 'bold',
    color: '#A1AACE',
    '& span': {
      fontWeight: 'normal',
      marginLeft: '0.5em',
      color: '#0F1A4F'
    },
    margin: '0'
  },
  location: {
    marginRight: '1.2em',
    flexBasis:'50%',
    display: 'flex',
    '& p': {
      margin: '0 1em 0 0'
    },
   
    '& select:last-child': {
      marginLeft: '1em'
    }
  },

  locationChild:{
display:'flex',

  },
  carLoansDiv: {
    background: '#fff',
   display:'flex',
    padding: '1em 1em',
    flexWrap:'wrap',
    flexDirection:'row',

    // justifyContent: 'space-between',
  
  },
  loanStatusContainer: {
    background: '#F6F7FB',
    flexGrow: 1,
    padding: '1em 1em'
  },
  loanStatus: {
    background: '#ffffff',
    height: '100%',
    '& > p': {
      display: 'flex',
      alignItems: 'center',
      color: '#0A1133',
      margin: '0',
      padding: '1em',
      fontWeight: 'bold',
      fontSize: '16px'
    }
  },
  phases: {
    display: 'flex',
    margin: '1em 0',
    paddingRight: '1em',
    borderBottom: '1px solid #E5E5E5'
  },
  phaseHeader: {
    '&.active': {
      borderBottom: '5px solid #4B9FF2'
    },
    marginLeft: '1em',
    width: '25%',
    cursor: 'pointer',
    color: '#A1AACE',
    '& > p': {
      margin: '0.5em 0 0.5em 0'
    }
  },
  phaseDetails: {
    display: 'flex',
    paddingRight: '1em'
  },
  phaseDetailsItem: {
    width: '30%',
    marginLeft: '1em',
    '& > h4': {
      // marginBottom: '0.8em',
      fontWeight: 'normal',
      color: '#A1AACE'
    }
  },
  noteTextArea: {
    minHeight: '6em',
    padding: '0.5em',
    // marginBottom: '1em',
    width: '100%',
    border: '1px solid #A1AACE',
    borderRadius: '4px',
    resize: 'none'
  },
  authorDate: {
    display: 'flex',
    alignItems: 'center',
    padding: '1em 0.9em',
    color: '#A1AACE'
  },
  authorDateChild:{
flexBasis:'50%',
color:'#0F1A4F'
  },
  noteText:{
flexBasis:'50%',
fontSize:'12px',
padding: '1em 0.9em',
  },
  authorDateChildTwo:{
flexBasis:'50%',
display:'flex',
justifyContent:'flex-end',
color:'#0F1A4F'
  },
  emptyContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2em 0',
    background: 'rgba(241, 243, 244, 0.4)',
    borderRadius: '4px',
    color: '#A1AACE'
  },
  depositDetailBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em .8em',
    background: 'rgba(241, 243, 244, 0.4)',
    borderRadius: '4px',
    color: '#A1AACE'
  },
  offersBlock: {
    display: 'flex',
    flexDirection: 'column',
    padding: '1em .8em',
    border: '1px solid #E5E5E5',
    borderRadius: '4px',
    color: '#A1AACE'
  },
  bankBlock: {
    background: 'rgba(151, 162, 213, 0.3)',
    borderRadius: '2px',
    padding: '.5em .5em',
    width:'10em',
    justifyContent:'center',
    minHeight:'20px',
    color: '#A1AACE'
  },
  pendingBlock: {
    display: 'flex',
    background: '#FFE7B2',
    borderRadius: '2px',
    padding: '.5em .5em',
    width:'8em',
    justifyContent:'center',
    minHeight:'20px',
    color: '#fff'
  },
  declinedBlock: {
    display: 'flex',
    background: '#FF8484',
    borderRadius: '2px',
    padding: '.2em .2em',
    width:'8em',
    justifyContent:'center',
    minHeight:'10px',
    color: '#fff'
  },
  acceptedBlock: {
    display: 'flex',
    background: '#3ED24D',
    borderRadius: '2px',
    padding: '.2em .2em',
    width:'8em',
    justifyContent:'center',
    minHeight:'10px',
    color: '#fff'
  },
  approvedBlock: {
    display: 'flex',
    background: '#3e72d2',
    borderRadius: '2px',
    padding: '.2em .2em',
    width:'8em',
    justifyContent:'center',
    minHeight:'10px',
    color: '#fff'
  },
  savedNotesBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  
    padding: '1em 0.9em',
    background: 'rgba(241, 243, 244, 0.4)',
    borderRadius: '4px',
    color: '#A1AACE'
  },
  saveNote: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '1em 0'
  },
  ButtonsContainer
  : {
    width: '100%',
    height: '80px',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '24px',
    marginBottom:'124px'
  },
  customerReminder: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '1em',
    paddingRight: '1em'
  },
  saveNoteBtn: {
    border: '1px solid #4B9FF2',
    color: '#4B9FF2',
    background: 'transparent',
    borderRadius: '4px',
    padding: '1em',
    cursor: 'pointer'
  },
  reminderBtn: {
    color: '#4B9FF2',
    fontWeight: 'bold',
    border: 'none',
    background: 'transparent',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})

export default useStyles
