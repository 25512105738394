import React from 'react'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useTlStyles } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import { getLeadDetails } from '../../../../../store/leads/selectors'
import { fetchSchedule } from '../../../../../store/events/actions'
import { Divider, Typography } from '@material-ui/core'
import {
  getSearchQuery,
  getTabValue
} from '../../../../../store/events/selectors'
import { useTranslation } from 'react-i18next'

export default function SortMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const classes = useTlStyles()
  const dispatch = useDispatch()
  const lead = useSelector(getLeadDetails)
  const activityType = useSelector(getTabValue)
  const searchQuery = useSelector(getSearchQuery)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSort = (order: string) => {
    handleClose()
    dispatch(
      fetchSchedule(lead.id, {
        lead_id: `${lead.id}`,
        completed: true,
        sortBy: 'activityType',
        order,
        activityType,
        searchQuery,
        pageSize: 5
      })
    )
  }

  const { t } = useTranslation()

  return (
    <div>
      <Button
        aria-controls="sort-menu"
        aria-haspopup="true"
        className={classes.sortBtn}
        onClick={handleClick}
      >
        {t('sortBy')}
      </Button>
      <Menu
        id="sort-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <Typography
          variant="body2"
          className={classes.sortBtn}
          style={{ padding: '.5rem' }}
        >
          {t('Type')}
        </Typography>
        <Divider />
        <MenuItem onClick={() => handleSort('ASC')}>{t('ASC')}</MenuItem>
        <MenuItem onClick={() => handleSort('DESC')}>{t('DESC')}</MenuItem>
      </Menu>
    </div>
  )
}
