/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import {
  FormHelperText,
  Grid,
  InputAdornment,
  InputBase,
  TextField,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import dayjs from 'dayjs'
import * as Yup from 'yup'
import { usePhoneStyles } from './styles'
import CustomInput from './CustomInput'
import FormFooter from './FormFooter'
import { useDispatch, useSelector } from 'react-redux'
import { addSchedule,fetchSchedule, setEvent } from '../../../../store/events/actions'
import {
  getEvents,
  getLocationOptions
} from '../../../../store/events/selectors'

import { connect } from 'react-redux'
import { getLeadDetails } from '../../../../store/leads/selectors'
import {
  deliveryOptions,
  fuelTypeOptions,
  gearTypeOptions,
  serviceDetailOptions,
  serviceTypeOptions,
  sourceOptions
} from './lead.utils'
import {
  getCarBodyOptions,
  getCarMakeOptions,
  getCarModelOptions,
  getCarYears
} from '../../../../store/car/selectors'
import { getCarModel } from '../../../../store/car/actions'
import { createAppointment, saveScheduleObject } from '../../../../store/leads/actions'
import { getCity, getStates } from '../../../../store/location/actions'
import {
  getAllCities,
  getAllStates
} from '../../../../store/location/selectors'
import { getSelectedCountry } from '../../../../store/auth/selectors'

const required = Yup.string().required('This field is required.')
const string = Yup.string()
const validationSchema = Yup.object().shape({
  date: required,
  time: required,
  note: required,
  service: required,
  delivery: required,
  locationId: string,
  serviceDetail: string,
  state: string,
  city: string,
  street: string,
  make: required,
  model: required,
  year: required,
  gearType: required,
  bodyType: required,
  fuelType: required,
  mileage: required,
  imported: required,
  email: Yup.string().email()
})
const initialValues = {
  date: '',
  time: '',
  note: '',
  service: '',
  serviceDetail: '',
  state: '',
  city: '',
  street: '',
  make: '',
  model: '',
  year: '',
  mileage: '',
  gearType: '',
  bodyType: '',
  fuelType: '',
  delivery: '',
  locationId: '',
  imported: ''
}


const AppointmentForm: React.FC = (props:any) => {

  const classes = usePhoneStyles()
  const dispatch = useDispatch()
  const lead = useSelector(getLeadDetails)
  const event = useSelector(getEvents)
  const locationOptions = useSelector(getLocationOptions)
  const carMakeOptions = useSelector(getCarMakeOptions)
  const carBodyOptions = useSelector(getCarBodyOptions)
  const carModelOptions = useSelector(getCarModelOptions)
  const carYearsOptions = getCarYears()
  const states = useSelector(getAllStates)
  const cities = useSelector(getAllCities)
  const countryCode = useSelector(getSelectedCountry)

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    touched,
    errors
  } = useFormik({
    initialValues: {
      ...initialValues,
      email: lead.email,
      phone: lead.phone
    },
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const { date, time, note, service, locationId } = values
      const data: any = {
        name: `${lead.firstName} ${lead.lastName}`,
        email: values.email,
        phone: values.phone,
        carData: {
          make: carMakeOptions.find((make) => make.value === values.make)
            ?.label,
          model: values.model,
          year: values.year,
          bodyType: values.bodyType,
          mileage: values.mileage,
          gearType: values.gearType,
          fuelType: values.fuelType,
          imported: values.imported === 'imported'
        },
        service: service,
        serviceDetail: values.serviceDetail,
        pickUpTime: time,
        pickUpDate: date,
        dropOffLocationId: locationId,
        country: countryCode
      }
      if (values.city && values.state) {
        data.pickUpLocation = {
          city: values.city,
          state: states.find((state) => state.value === values.state)?.label,
          street: values.street
        }
      }
      dispatch(createAppointment(data))
      dispatch(saveScheduleObject({
        leadId: lead.id,
        activityType: event,
        completed: false,
        locationId: locationId,
        date,
        time,
        note,
      }))


      setTimeout(()=>{

        dispatch(fetchSchedule(lead.id, {lead_id:lead.id, completed: false, pageSize: 5 }))
    }, 2000)
      handleReset()
    }
  })


  React.useEffect(() => {

    if (values.make) {
      dispatch(getCarModel(values.make))
    }
  }, [values.make])
  React.useEffect(() => {
    if (values.state) {
      dispatch(getCity(values.state))
    }
  }, [values.state])
  React.useEffect(() => {
    dispatch(getStates())
  }, [])
  const handleReset = () => {
    dispatch(setEvent(''))
  }
  const sellCar = values.service === 'SELL_CAR'
  const fixCar = values.service === 'FIX_CAR'
  const dropOff = values.delivery === 'drop_off'
  const pickup = values.delivery === 'pick_up'
  return (
    <Grid container item xs={12}>
      <Grid item xs={7} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          Appointment type
        </Typography>
        <CustomInput
          name="service"
          handleBlur={setFieldTouched}
          handleChange={setFieldValue}
          width="26.5rem"
          error={!!(errors.service && touched.service)}
          helperText={touched.service && errors.service}
          value={values.service}
          options={serviceTypeOptions}
        />
      </Grid>
      {!!fixCar && (
        <Grid item xs={7} className={classes.text}>
          <Typography variant="body2" className={classes.label}>
            Service detail
          </Typography>
          <CustomInput
            handleBlur={setFieldTouched}
            handleChange={setFieldValue}
            name="serviceDetail"
            width="26.5rem"
            error={!!(errors.serviceDetail && touched.serviceDetail)}
            helperText={touched.serviceDetail && errors.serviceDetail}
            value={values.serviceDetail}
            options={serviceDetailOptions}
          />
        </Grid>
      )}
      {!!(sellCar || fixCar) && (
        <Grid container item xs={7} className={classes.section}>
          <Typography variant="body1" className={classes.sectionTitle}>
            Car detail
          </Typography>
          <Grid item xs={12} className={classes.datetime}>
            <div>
              <Typography variant="body2" className={classes.label}>
                Car make
              </Typography>
              <CustomInput
                name="make"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.3rem"
                error={!!(errors.make && touched.make)}
                helperText={touched.make && errors.make}
                value={values.make}
                options={carMakeOptions}
              />
            </div>
            <div>
              <Typography variant="body2" className={classes.label}>
                Car model
              </Typography>
              <CustomInput
                name="model"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.2rem"
                error={!!(errors.model && touched.model)}
                helperText={touched.model && errors.model}
                value={values.model}
                options={carModelOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.datetime}>
            <div>
              <Typography variant="body2" className={classes.label}>
                Year
              </Typography>
              <CustomInput
                name="year"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.3rem"
                error={!!(errors.year && touched.year)}
                helperText={touched.year && errors.year}
                value={values.year}
                options={carYearsOptions}
              />
            </div>
            <div>
              <Typography variant="body2" className={classes.label}>
                Car body type
              </Typography>
              <CustomInput
                name="bodyType"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.2rem"
                error={!!(errors.bodyType && touched.bodyType)}
                helperText={touched.bodyType && errors.bodyType}
                value={values.bodyType}
                options={carBodyOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.datetime}>
            <div>
              <Typography variant="body2" className={classes.label}>
                Mileage
              </Typography>
              <TextField
                onBlur={handleBlur}
                error={!!(errors.mileage && touched.mileage)}
                helperText={touched.mileage && errors.mileage}
                name="mileage"
                value={values.mileage}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">km</InputAdornment>
                  )
                }}
                id="mileage"
                variant="outlined"
              />
            </div>
            <div>
              <Typography variant="body2" className={classes.label}>
                Gear type
              </Typography>
              <CustomInput
                name="gearType"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.2rem"
                error={!!(errors.gearType && touched.gearType)}
                helperText={touched.gearType && errors.gearType}
                value={values.gearType}
                options={gearTypeOptions}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.datetime}>
            <div>
              <Typography variant="body2" className={classes.label}>
                Fuel type
              </Typography>
              <CustomInput
                name="fuelType"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.3rem"
                error={!!(errors.fuelType && touched.fuelType)}
                helperText={touched.fuelType && errors.fuelType}
                value={values.fuelType}
                options={fuelTypeOptions}
              />
            </div>
            <div>
              <Typography variant="body2" className={classes.label}>
                Imported or bought locally?
              </Typography>
              <CustomInput
                name="imported"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.2rem"
                error={!!(errors.imported && touched.imported)}
                helperText={touched.imported && errors.imported}
                value={values.imported}
                options={sourceOptions}
              />
            </div>
          </Grid>
        </Grid>
      )}
      <Grid item xs={7} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          Delivery
        </Typography>
        <CustomInput
          handleBlur={setFieldTouched}
          handleChange={setFieldValue}
          name="delivery"
          width="26.5rem"
          error={!!(errors.delivery && touched.delivery)}
          helperText={touched.delivery && errors.delivery}
          value={values.delivery}
          options={deliveryOptions}
        />
      </Grid>
      {!!dropOff && (
        <Grid item xs={7} className={classes.text}>
          <Typography variant="body2" className={classes.label}>
            Drop off location
          </Typography>
          <CustomInput
            handleBlur={setFieldTouched}
            handleChange={setFieldValue}
            name="locationId"
            width="26.5rem"
            error={!!(errors.locationId && touched.locationId)}
            helperText={touched.locationId && errors.locationId}
            value={values.locationId}
            options={locationOptions}
          />
        </Grid>
      )}
      {!!pickup && (
        <React.Fragment>
          <Grid item xs={12} className={classes.datetime}>
            <div>
              <Typography variant="body2" className={classes.label}>
                State
              </Typography>
              <CustomInput
                name="state"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.3rem"
                error={!!(errors.state && touched.state)}
                helperText={touched.state && errors.state}
                value={values.state}
                options={states}
              />
            </div>
            <div>
              <Typography variant="body2" className={classes.label}>
                City
              </Typography>
              <CustomInput
                name="city"
                handleBlur={setFieldTouched}
                handleChange={setFieldValue}
                width="12.2rem"
                error={!!(errors.city && touched.city)}
                helperText={touched.city && errors.city}
                value={values.city}
                options={cities}
              />
            </div>
          </Grid>
          <Grid item xs={7} className={classes.text}>
            <Typography variant="body2" className={classes.label}>
              Street address
            </Typography>
            <TextField
              fullWidth
              multiline
              rows={2}
              error={!!(errors.street && touched.street)}
              helperText={touched.street && errors.street}
              name="street"
              value={values.street}
              onChange={handleChange}
              id="street"
              variant="outlined"
            />
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={7} className={classes.datetime}>
        <div>
          <Typography variant="body2" className={classes.label}>
            Pick up date
          </Typography>
          <TextField
            id="date"
            name="date"
            onBlur={handleBlur}
            error={!!(errors.date && touched.date)}
            helperText={touched.date && errors.date}
            value={values.date}
            onChange={handleChange}
            variant="outlined"
            type="date"
            inputProps={{
              min: dayjs(new Date()).format('YYYY-MM-DD')
            }}
          />
        </div>
        <div>
          <Typography variant="body2" className={classes.label}>
            Pick up time
          </Typography>
          <InputBase
            name="time"
            type="time"
            className={classes.timeInputWrapper}
            onBlur={handleBlur}
            error={!!(errors.time && touched.time)}
            value={values.time}
            onChange={handleChange}
          />
          {!!touched.time && (
            <FormHelperText error>{errors.time}</FormHelperText>
          )}
        </div>
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          Add a note  
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          onBlur={handleBlur}
          error={!!(errors.note && touched.note)}
          helperText={touched.note && errors.note}
          name="note"
          value={values.note}
          onChange={handleChange}
          id="note"
          variant="outlined"
        />
      </Grid>
      <FormFooter handleReset={handleReset} handleSubmit={handleSubmit} />
    </Grid>
  )
}
const mapStateToProps = (state: any)  => {
  return {
    leadServiceInterest: state.leads.leadServiceInterests.interests,
    startSchedule: state.leads.startSchedule,
    pauseScheduleEvent: state.events.pauseScheduleEvent,
  };
};
export default connect(mapStateToProps)(AppointmentForm); 
