import {
  FETCH_LEADS_SUCCESS,
  FETCH_LEAD_SUCCESS,
  UPDATE_LEAD_SUCCESS,
  FETCH_AGENTS_SUCCESS,
  LINK_USER_SUCCESS,
  GET_SERVICE_INTERESTS_SUCCESS,
  FETCH_LOCATIONS_SUCCESS,
  CREATE_APPOINTMENT_SUCCESS,
  GET_CAR_MEDIA_SUCCESS,
  GET_CAR_DATA_SUCCESS,
  PERSIST_PREV_STATE,
  CLEAR_PREV_STATE,
  GET_CAR_INSPECTION_SUCCESS,
  GET_SIMILAR_CAR_SUCCESS,
  GET_CAR_INSPECTOR_SUCCESS,
  GET_CAR_DEALER_SUCCESS,
  GET_CAR_OWNER_SUCCESS,
  SAVE_SCHEDULE_OBJECT,
  GET_CARS_FROM_MARKET,
  SAVE_CAR_APPOINTMENT_DATA,
  CANCEL_CAR_APPOINTMENT_DATA,
  START_ADD_BUY_CAR_INTEREST_LOADER,
  STOP_ADD_BUY_CAR_INTEREST_LOADER,
  SAVE_MARKET_CAR,
  CREATE_BUY_CAR_APPOINTMENT,
  SAVE_MARKET_CAR_PAGINATION,
  SAVE_SEARCH_QUERY,
  SAVE_QUERY_OBJECT,
  SAVE_CURRENT_ACTIVITY,
  STOP_ACTIVITY_LOADER,
  START_ACTIVITY_LOADER,
  SAVE_ACTIVITY_PAGINATION,
  SAVE_ACTIVITY_FILTER_NAME,
  SAVE_ACTIVITY_TYPE,
  SAVE_SALES_OFFICERS,
  SAVE_AGENT_FILTERED_ARRAY,
  CLEAR_AGENT_FILTERED_ARRAY,
  SAVE_AGENT_DATE_OBJECT,
  CLEAR_AGENT_DATE_OBJECT,
  SAVE_AGENT_TASKS,
  SAVE_CRM_NOTIFICATIONS,
  ADD_SAVE_BTN,
  HIDE_SAVE_BTN,
  SAVE_SUMMARY_NOTES,
  SAVE_LEAD_NOTES,
  SAVE_LOAN_CAR,
  SAVE_LOAN_NOTE_PAGINATION,
  SAVE_BANK_DEALER_SEARCH,
  SAVE_AGENT_IDS,
  SAVE_ASSIGNED_AGENT,
  EMPTY_ASSIGNED_LEADS,
  SAVE_BANKS
} from './actions'

const initialState = {
  agents: [],
  leads: [],
  lead: {},
  leadServiceInterests: { interests: [] },
  locations: { locations: [] },
  pagination: {},
  cars: [],
  carMedia: {},
  carInspection: [],
  carsFromMarket: [],
  similarCars: [],
  carOwner: {},
  carInspector: {},
  carDealer: {},
  startSchedule: false,
  appointMentId: undefined,
  prevState: undefined,
  scheduleObject: {},
  carAppointmentData: {},
  showCarAppointment: false,
  createCarBuyAppointmentLoader: false,
  showSearchList: false,
  carPagination: {
    currentPage: 1,
    pageSize: 10,
    total: 24
  },
  searchQueryFromStore: '',
  queryObject: {},
  currentActivity: '',
  activityArray: [],
  activityLoader: false,
  activityPagination: {
    currentPage: 1,
    pageSize: '',
    total: ''
  },
  activityType: '',
  activityFilterName: 'All',
  salesOfficers: [],
  filteredAgentArray: [],
  filteredActivityDate: {},
  newTasks:[],
  crmNotifications:[],
  saveBtn:false,
  leadNotes:[],
  leadNotesSummary:[],
  loanCar:'',
  loanNotePagination: {
    currentPage: 1,
    pageSize: '',
    total: ''
  },
bankDealerSearch:'',
leadIdsToAgents:[],
assignedAgent:'',
savedBanks:[],
dealers:[]
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LEADS_SUCCESS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case FETCH_LEAD_SUCCESS:
    case UPDATE_LEAD_SUCCESS:
    case LINK_USER_SUCCESS: {
      return {
        ...state,
        lead: action.payload
      }
    }
    case FETCH_AGENTS_SUCCESS: {
      return {
        ...state,
        agents: action.payload
      }
    }
    case SAVE_BANKS: {
      return {
        ...state,
        savedBanks: action.payload
      }
    }
    case SAVE_AGENT_TASKS: {
      return {
        ...state,
        newTasks: action.payload
      }
    }
    case SAVE_AGENT_FILTERED_ARRAY: {
      return {
        ...state,
        filteredAgentArray: action.payload
      }
    }
    case SAVE_ASSIGNED_AGENT: {
      return {
        ...state,
        assignedAgent: action.payload
      }
    }
    case SAVE_AGENT_DATE_OBJECT: {
      return {
        ...state,
        filteredActivityDate: action.payload
      }
    }
    case EMPTY_ASSIGNED_LEADS: {
      return {
        ...state,
        leadIdsToAgents: []
      }
    }
    case SAVE_AGENT_IDS: {
      return {
        ...state,
        leadIdsToAgents: action.agentIds
      }
    }
    case SAVE_LEAD_NOTES: {

      return {
        ...state,
        leadNotes: action.payload
      }
    }
    case SAVE_LOAN_CAR: {

      return {
        ...state,
        loanCar: action.payload
      }
    }
    case SAVE_BANK_DEALER_SEARCH: {

      return {
        ...state,
        bankDealerSearch: action.payload
      }
    }
    case SAVE_SUMMARY_NOTES: {

      return {
        ...state,
        leadNotesSummary: action.payload
      }
    }
    case CLEAR_AGENT_DATE_OBJECT: {
      return {
        ...state,
        filteredActivityDate: action.payload
      }
    }
    case CLEAR_AGENT_FILTERED_ARRAY: {
      return {
        ...state,
        filteredAgentArray: []
      }
    }
    case SAVE_ACTIVITY_FILTER_NAME: {
      return {
        ...state,
        activityFilterName: action.payload
      }
    }
    case SAVE_CAR_APPOINTMENT_DATA: {
      return {
        ...state,
        carAppointmentData: action.data,
        showCarAppointment: true
      }
    }
    case ADD_SAVE_BTN: {
      return {
        ...state,
        saveBtn:true,

      }
    }
    case HIDE_SAVE_BTN: {
      return {
        ...state,
        saveBtn:false,

      }
    }
    case SAVE_CRM_NOTIFICATIONS: {
      return {
        ...state,
        crmNotifications: action.payload,
      }
    }
    case SAVE_CURRENT_ACTIVITY: {
      return {
        ...state,
        activityArray: action.payload,
        activityLoader: false
      }
    }
    case SAVE_ACTIVITY_PAGINATION: {
      return {
        ...state,
        activityPagination: action.payload
      }
    }
    case SAVE_LOAN_NOTE_PAGINATION: {
      return {
        ...state,
        loanNotePagination: action.payload
      }
    }
    case SAVE_ACTIVITY_TYPE: {
      return {
        ...state,
        activityType: action.payload
      }
    }
    case STOP_ACTIVITY_LOADER: {
      return {
        ...state,

        activityLoader: false
      }
    }
    case START_ACTIVITY_LOADER: {
      return {
        ...state,

        activityLoader: true
      }
    }
    case CANCEL_CAR_APPOINTMENT_DATA: {
      return {
        ...state,

        showCarAppointment: false
      }
    }
    case SAVE_SEARCH_QUERY: {
      return {
        ...state,

        searchQueryFromStore: action.payload
      }
    }
    case SAVE_QUERY_OBJECT: {
      return {
        ...state,

        queryObject: action.payload
      }
    }
    case SAVE_SCHEDULE_OBJECT: {
      return {
        ...state,
        scheduleObject: action.payload
      }
    }
    case SAVE_SALES_OFFICERS: {
      return {
        ...state,
        salesOfficers: action.payload
      }
    }
    case GET_CARS_FROM_MARKET: {
      return {
        ...state,
        carsFromMarket: action.payload
      }
    }
    case SAVE_MARKET_CAR: {
      return {
        ...state,
        carsFromMarket: action.payload,
        showSearchList: true
      }
    }
    case CREATE_BUY_CAR_APPOINTMENT: {
      return {
        ...state
      }
    }
    case SAVE_MARKET_CAR_PAGINATION: {
      return {
        ...state,
        carPagination: action.payload
      }
    }

    case GET_SERVICE_INTERESTS_SUCCESS: {
      return {
        ...state,
        leadServiceInterests: action.payload,
        serviceLoader: false
      }
    }
    case CREATE_APPOINTMENT_SUCCESS: {
      return {
        ...state,
        leadServiceInterests: {
          interests: [...state.leadServiceInterests.interests, action.payload]
        },
        appointMentId: action.payload.id,
        startSchedule: true,
        createCarBuyAppointmentLoader: false
      }
    }
    case FETCH_LOCATIONS_SUCCESS: {
      return {
        ...state,
        locations: action.payload
      }
    }
    case GET_CAR_DATA_SUCCESS: {
      return {
        ...state,
        cars: [...state.cars, action.payload]
      }
    }
    case START_ADD_BUY_CAR_INTEREST_LOADER: {
      return {
        ...state,

        createCarBuyAppointmentLoader: true
      }
    }
    case STOP_ADD_BUY_CAR_INTEREST_LOADER: {
      return {
        ...state,

        createCarBuyAppointmentLoader: false
      }
    }
    case GET_CAR_MEDIA_SUCCESS: {
      return {
        ...state,
        carMedia: { ...state.carMedia, [action.carId]: action.payload }
      }
    }
    case GET_CAR_INSPECTION_SUCCESS: {
      return {
        ...state,
        carInspection: [...state.carInspection, action.payload]
      }
    }
    case GET_SIMILAR_CAR_SUCCESS: {
      return {
        ...state,
        similarCars: { ...state.similarCars, [action.carId]: action.payload }
      }
    }
    case GET_CAR_OWNER_SUCCESS: {
      return {
        ...state,
        carOwner: { ...state.carOwner, [action.carId]: action.payload }
      }
    }
    case GET_CAR_INSPECTOR_SUCCESS: {
      return {
        ...state,
        carInspector: { ...state.carInspector, [action.carId]: action.payload }
      }
    }
    case GET_CAR_DEALER_SUCCESS: {
      return {
        ...state,
        carDealer: { ...state.carDealer, [action.carId]: action.payload }
      }
    }
    case PERSIST_PREV_STATE: {
      return {
        ...state,
        prevState: action.payload
      }
    }
    case CLEAR_PREV_STATE: {
      return {
        ...state,
        prevState: undefined
      }
    }
    default:
      return state
  }
}

export default reducer
