import React, { useState, useEffect } from 'react'
import { StyledEquity } from './styles'
import { Switch, Button } from 'antd'
import EquityModal from './EquityModal'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import api from '../../../utils/api'
import {
  getSelectedCountryCurrency,
  getUserToken
} from '../../../store/auth/selectors'
import { useParams } from 'react-router-dom'
import { TaskIcon } from '../../PortfolioManagement/PortfolioDetails/svgs'
import { StyledDocumentUpload } from '../../PortfolioManagement/PortfolioDetails/styles'
import { useTranslation } from 'react-i18next'
interface IDetailParam {
  id: string
}

export default function Equity({ title }: any) {
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const [checked, setChecked] = useState(false)
  const currency = useSelector(getSelectedCountryCurrency)
  const [getOffers, setGetOffers] = useState<any>([])
  const [singleEquity, setSingleEquity] = useState({})
  const [singleOffer, setSingleOffer] = useState({})
  const [activeIndex, setActiveIndex] = useState(null)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: singleLoan, status, refetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    { keepPreviousData: true, staleTime: 5000 }
  )

  useEffect(() => {
    if (singleLoan) {
      setGetOffers(singleLoan)
    }
  }, [singleLoan])

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
    }
  }, [singleLoan, queryClient, id])

  function onChange(i: any) {
    if (checked == false) {
      setChecked(true)
      setActiveIndex(i)
    } else if (checked == true) {
      setChecked(false)
      setActiveIndex(i)
    }
  }

  const showEquityModal = (equity: object) => {
    setIsModalVisible(true)
    setSingleEquity(equity)
    setSingleOffer(equity)
  }

  function getFileNameFromUrl(url: any) {
    var el = document.createElement('a')
    el.href = url
    return decodeURIComponent(el.pathname).split('/').pop()
  }

  return (
    <StyledEquity>
      <div className="equityWrapper">
        <div className="contentWrap">
          <div className="header mb-4">{title}</div>
          {getOffers?.offer == undefined ? (
            <StyledDocumentUpload>
              <div className="content">
                <div>{TaskIcon}</div>
                <div className="text ms-3">
                  {t('thisCustomerDoesNotHaveReceivedOffersYet')}.
                </div>
              </div>
            </StyledDocumentUpload>
          ) : (
            <div className="">
              <div className="offersUpload d-grid gap-4">
                <div>
                  <div className="header mb-2">
                    {getOffers?.offer?.partner?.name || 'N/A'}
                  </div>
                  <div className="offerDetails">
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('LoanAmount')}</div>
                      <div className="value">
                        {currency}
                        {getOffers?.offer?.approvedAmount.toLocaleString() ||
                          'N/A'}
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('Downpayment')}</div>
                      <div className="value">
                        {currency}
                        {Number(getOffers?.offer?.equity).toLocaleString() ||
                          'N/A'}
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('Tenure')}</div>
                      <div className="value">
                        {getOffers?.offer?.tenure || 'N/A'} mos.
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('InterestRate')}</div>
                      <div className="value">
                        {getOffers?.offer?.interest || 'N/A'}%
                      </div>
                    </div>
                    <div
                      className="text-center"
                      style={{ borderRight: '1px solid #e9e9e9' }}
                    >
                      <div className="head">{t('MonthlyEMI')}</div>
                      <div className="value">
                        {currency}
                        {Number(
                          getOffers?.offer?.monthlyRepayment
                        ).toLocaleString() || 'N/A'}
                      </div>
                    </div>
                    <div className="text-center">
                      <div className="head">{t('customerAcceptance')}</div>
                      <div className="value">{t('Yes')}</div>
                    </div>
                  </div>
                </div>

                <div className="container">
                  {getOffers?.equities?.length == 0 ? (
                    ''
                  ) : (
                    <h5 className="mb-3">{t('equityRequested')}</h5>
                  )}

                  <div className="equityRequested mt-4">
                    {getOffers?.equities?.map((el: any, index: any) => (
                      <div className="d-flex mb-4" key={index}>
                        <div className="equityDetails me-4">
                          {el?.amountPaid > 0 ? (
                            <>
                              <div>
                                {t('amountRequested')}:{' '}
                                <span>
                                  {currency}
                                  {el.amountRequested.toLocaleString()}
                                </span>
                              </div>
                              <div className="mb-2">
                                {t('Date')}:{' '}
                                <span>
                                  {new Date(el?.dateRequested).toDateString()}
                                </span>
                              </div>
                              <div className="mb-0">
                                {t('amountPaid')}:{' '}
                                <span>
                                  {currency}
                                  {el.amountPaid.toLocaleString()}
                                </span>
                              </div>
                            </>
                          ) : (
                            <div className="mb-1">
                              {t('amountToBePaid')}:{' '}
                              <span>
                                {currency}
                                {el.amountRequested.toLocaleString()}
                              </span>
                            </div>
                          )}

                          {el?.amountPaid > 0 ? (
                            <div className="mb-1">
                              {t('Date')}:{' '}
                              <span>
                                {new Date(el?.datePaid).toDateString()}
                              </span>
                            </div>
                          ) : (
                            <div className="mb-1">
                              {t('Date')}:{' '}
                              <span>
                                {new Date(el?.dateRequested).toDateString()}
                              </span>
                            </div>
                          )}

                          {el?.amountPaid > 0 ? (
                            <div className="paid">{t('equityPaid')}</div>
                          ) : (
                            <div className="pending px-2">
                              {t('pendingEquityPayment')}
                            </div>
                          )}
                        </div>

                        {el?.amountPaid > 0 ? (
                          <div className="equityPaid">
                            <div className="equityPayment">
                              <div className="pdfWrapper">
                                <div className="image me-2"></div>
                                <div>
                                  <div className="name">
                                    {getFileNameFromUrl(el?.fileUrl)}
                                  </div>
                                  <div className="size">N/A</div>
                                </div>
                              </div>
                              <div className="view">
                                <a
                                  href={el?.fileUrl}
                                  target="_blank"
                                  className="me-3"
                                >
                                  {t('view')}
                                </a>
                                <a href={el?.fileUrl} download>
                                  {t('download')}
                                </a>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="equityPaid">
                            <div className="">
                              <div className="d-flex">
                                <div className="me-3">{t('equityPaid')}?</div>
                                <div>
                                  {t('No')}{' '}
                                  <Switch
                                    defaultChecked={false}
                                    onClick={() => onChange(index)}
                                  />
                                  &nbsp;{t('Yes')}
                                </div>
                              </div>
                              {checked == true && activeIndex == index ? (
                                <div className="mt-3 uploadButton text-center">
                                  <Button onClick={() => showEquityModal(el)}>
                                    {t('uploadPayment')}
                                  </Button>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>

                <EquityModal
                  isModalVisible={isModalVisible}
                  setIsModalVisible={setIsModalVisible}
                  singleEquity={singleEquity}
                  refetch={refetch}
                  singleOffer={singleOffer}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </StyledEquity>
  )
}
