import React, { useState, useEffect } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import { useDispatch, useSelector } from 'react-redux'
import { StyledLoanTable } from './styles'
import { Input, Button, Table, Tag, Badge } from 'antd'
import {
  useHistory,
  withRouter,
  useParams,
  useLocation
} from 'react-router-dom'
import { BellIcon, SearchIcon } from '../Dashboard/svgs'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  getUserToken,
  isManager,
  isAdmin,
  getUserFirstName,
  getUserLastName,
  getUserId,
  isLead,
  isPortfolio
} from '../../../store/auth/selectors'
import { getCountryCode } from '../../../utils/country.util'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from '../../../utils/api'
import { showSnackbar } from '../../../store/snackbar/actions'
import Loader from '../../../components/Loader'
import queryString from 'query-string'
import { paramsObjectToQueryString } from '../../../utils/paramObjectToQuery'
import NotificationModal from '../../../components/NotificationModal'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function LoanTable() {
  let history = useHistory()
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const firstName = useSelector(getUserFirstName)
  const lastName = useSelector(getUserLastName)
  const [activePage, setActivePage] = useState({
    currentPage: 1,
    pageSize: 10
  })
  const [searchValue, setSearchValue] = useState('')
  const [loanTable, setLoanTable] = useState<Array<object>>([])
  const userId = useSelector(getUserId)
  const queryClient = useQueryClient()
  const userIsAdmin = useSelector(isAdmin)
  const userIsManager = useSelector(isManager)
  const userIsPortfolio = useSelector(isPortfolio)
  const userIsLead = useSelector(isLead)
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)
  const { t } = useTranslation()
  const { search } = useLocation()
  const query = queryString.parse(search)

  const { data: fetchLoans, status } = useQuery(
    ['fetchLoans', query, activePage],
    () =>
      api.fetchOriginationLoans2(token, {
        ...query,
        country: country,
        pageSize: activePage.pageSize,
        currentPage: activePage.currentPage
      })
  )

  useEffect(() => {
    if (query.current_page && typeof query.current_page == 'string') {
      setActivePage({
        ...activePage,
        currentPage: parseInt(query.current_page)
      })
    }
  }, [])

  useEffect(() => {
    setLoanTable(fetchLoans?.loanLeads)
  }, [fetchLoans])

  const onChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page.current
    })
    history.push(
      `/portfolio-loans${paramsObjectToQueryString({
        ...query,
        current_page: page.current,
        page_size: page.pageSize
      })}`
    )
  }

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  const handleSearch = () => {
    history.push(
      `/portfolio-loans${paramsObjectToQueryString({
        ...query,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        first_name: searchValue
      })}`
    )
  }

  function getInitials(name: any) {
    const hasTokens = name.indexOf(' ') !== -1
    return (
      name.substring(0, hasTokens ? 1 : 2) +
      (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
    )
  }

  function capitalFirstLetter(str: any) {
    const arr = str?.split(' ')
    for (var i = 0; i < arr?.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr?.join(' ')
  }

  const data = loanTable?.map((el: any) => ({
    customerDetails: `${el?.firstName}, ${el?.lastName} ${el?.phone} ${el?.email}`,
    car: `${el?.carName || 'N/A'}`,
    loanValue: (
      <span>
        {currency}
        {el?.loanValue?.toLocaleString() || 0}
      </span>
    ),
    agent: <span>{capitalFirstLetter(el?.leadManager?.name) || 'N/A'}</span>,
    status: (
      <Tag
        color="rgba(88, 135, 255, 0.1)"
        style={{
          color: '#30355B',
          border: '1px solid #30355B',
          borderRadius: '100px',
          fontWeight: 500
        }}
      >
        {/* {el.status?.replace('_', ' ') || 'N/A'} */}
        {t(`${el?.status}`)}
      </Tag>
    ),
    id: `${el?.id}`,
    carId: `${el?.carId}`,
    country: `${el?.country}`
  }))

  const columns = [
    {
      title: t('customerDetails'),
      dataIndex: 'customerDetails'
      // width: 420
    },
    {
      title: t('car'),
      dataIndex: 'car'
      // width: 400
    },
    {
      title: t('loanValue'),
      dataIndex: 'loanValue'
    },
    {
      title: t('agent'),
      dataIndex: 'agent'
    },
    {
      title: t('status'),
      dataIndex: 'status'
    }
  ]

  const {
    data: fetchNotifications,
    status: notificationStatus,
    refetch
  } = useQuery(['notification'], () =>
    api.fetchCrmOriginationNotif(token, {
      agent_id: userId, //'byU7SkIg0'
      read: false,
      type: 'loan'
    })
  )

  const showNotificationModal = () => {
    setIsNotificationVisible(true)
  }

  return (
    <WithSideMenu>
      <StyledLoanTable>
        {status == 'loading' ? (
          <Loader />
        ) : (
          <>
            <div className="headerWrapper">
              <div className="searchButton">
                <Input
                  placeholder={t('searchByCustomerDetailsCar')}
                  bordered={false}
                  prefix={SearchIcon}
                  style={{ width: '486px' }}
                  onChange={onSearchChange}
                  value={searchValue}
                />
                <Button type="primary" onClick={handleSearch}>
                  {t('search')}
                </Button>
              </div>
              <div className="userWrapper">
                <div className="users">
                  <div style={{ textAlign: 'right' }}>
                    <div className="name">
                      {firstName} {lastName}
                    </div>
                    <div className="role">
                      {userIsPortfolio && t('portfolioManager')}
                      {userIsLead && t('leadManager')}
                      {(userIsAdmin || userIsManager) && t('CRMManager')}
                      {/* {t('portfolioManager')} */}
                    </div>
                  </div>
                  <div className="initials">
                    {getInitials(`${firstName} ${lastName}`)}
                  </div>
                </div>
                <div className="bell" onClick={() => showNotificationModal()}>
                  <Badge count={fetchNotifications?.data?.length || 0} showZero>
                    <div>{BellIcon}</div>
                  </Badge>
                </div>
              </div>
            </div>

            <NotificationModal
              isNotificationVisible={isNotificationVisible}
              setIsNotificationVisible={setIsNotificationVisible}
              fetchNotifications={fetchNotifications}
              refetch={refetch}
            />

            <div className="tableWrapper">
              <h3>{t('LOAN LEADS')}</h3>
              <Table
                dataSource={data}
                columns={columns}
                pagination={{
                  current: activePage.currentPage,
                  pageSize: activePage.pageSize,
                  total: fetchLoans?.pagination?.total,
                  position: ['bottomCenter']
                }}
                onChange={onChange}
                rowKey="id"
                onRow={(data, rowIndex) => {
                  return {
                    onClick: (event) => {
                      history.push(`/portfolio-loans/${data.id}`)
                    } // click row
                  }
                }}
              />
            </div>
          </>
        )}
      </StyledLoanTable>
    </WithSideMenu>
  )
}
