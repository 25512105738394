import React from 'react'
import { AutochekThemeOptions } from '../../../theme/types'

type Props = {
  theme: AutochekThemeOptions
  active: boolean
}

const PersonIcon = ({ active, theme }: Props) => {
  const color = active
    ? theme.palette.neutral.kournikova
    : theme.palette.common.white
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9 8.4375C11.1746 8.4375 12.9375 6.67462 12.9375 4.5C12.9375 2.32538 11.1746 0.5625 9 0.5625C6.82538 0.5625 5.0625 2.32538 5.0625 4.5C5.0625 6.67462 6.82538 8.4375 9 8.4375Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.61719 2.5127V2.5127C6.8483 3.78344 8.54213 4.50058 10.3114 4.50019V4.50019C11.1954 4.5012 12.0703 4.32259 12.8832 3.97519"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.6875 17.4377V17.4387C1.6875 14.2666 3.73141 11.4558 6.74904 10.478L8.99904 12.3755L11.249 10.478H11.249C14.2675 11.4545 16.3124 14.2655 16.3123 17.438"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.2405 11.4795V11.4795C12.6323 11.0454 11.961 10.7074 11.25 10.4775L9 12.375L12.375 14.625L13.2405 11.4795Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.00147 12.375L6.75147 10.4775L6.75147 10.4775C6.04083 10.7077 5.36975 11.0456 4.76172 11.4795L5.62647 14.625L9.00147 12.375Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9 17.4375V12.375"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.25 16.875H13.5"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={18} height={18} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default PersonIcon
