import React, { useState, useEffect } from 'react'
import { StyledBookInspection } from './styles'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  TimePicker,
  message,
  Spin
} from 'antd'
import moment from 'moment'
import api from '../../../utils/api'
import {
  getUserToken,
  getUserId,
  getSelectedCountry
} from '../../../store/auth/selectors'
import { useSelector } from 'react-redux'
import { CAR_STATUS } from '../AvailableCar/constants'
import { useTranslation } from 'react-i18next'

export default function BookInspectionModal({
  visibleBookInsp,
  setVisibleBookInsp,
  modalData,
  singleLoan,
  loanRefresh
}: any) {
  const [form] = Form.useForm()
  const token = useSelector(getUserToken)
  const userId = useSelector(getUserId)
  const country = useSelector(getSelectedCountry)
  const queryClient = useQueryClient()
  const [submitting, setSubmitting] = useState(false)
  const [formData, setFormData] = useState<any>()
  const { t } = useTranslation()

  const handleCancel = () => {
    setVisibleBookInsp(false)
  }

  const { data: car, isLoading: carLoading } = useQuery(
    ['car', modalData],
    () =>
      api.getCar(token, {
        country: country,
        // vin: modalData?.vin
        vin: 'fgghhjjjgfdsgjkgf'
      })
  )

  const bookInspection: any = useMutation(
    (payload) => api.createBookInspection(token, payload),
    {
      onSuccess: (__data) => {
        queryClient.invalidateQueries('book inspection')
        // setSubmitting(false)
        message.success('Inspection booking successfully')
        // setVisibleBookInsp(false)
        handleScheduleCreate(__data)
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(
          error?.response.data.error ||
            'Uh oh, something went wrong, please try again'
        )
      }
    }
  )

  const createSchedule: any = useMutation(
    (payload) => api.createSchedule(payload, token),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('book inspection')

        message.success('Inspection booking successfully')
        setVisibleBookInsp(false)
        form.resetFields()
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(
          error?.response.data.error ||
            'Uh oh, something went wrong, please try again'
        )
      }
    }
  )

  const updateLoan: any = useMutation(
    () =>
      api.updateCarLoan(token, singleLoan?.id, {
        status: CAR_STATUS.CUSTOMER_INSPECTION
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loan-update')
        message.success('Loan updated')
        setSubmitting(false)
        loanRefresh()
      },
      onError: (error: any) => {
        message.error(error?.response.data.error)
        setSubmitting(false)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  const onFinish = async (fieldsValue: any) => {
    setSubmitting(true)
    const values = {
      ...fieldsValue,
      date: fieldsValue['date'].format('YYYY-MM-DD'),
      time: fieldsValue['time'].format('HH:mm')
    }
    setFormData(fieldsValue)

    const appointmentPayload = {
      user_id: userId,
      name: modalData?.franchiseName || modalData?.ownerDetails?.name || 'N/A',
      email:
        modalData?.franchiseEmail || modalData?.ownerDetails?.email || 'N/A',
      phone:
        modalData?.franchisePhoneNumber ||
        modalData?.ownerDetails?.phone ||
        'N/A',
      car_data: {
        make: singleLoan?.car?.model?.make?.name, //modalData?.makeId,
        model: singleLoan?.car?.model?.name, //modalData?.carModel,
        year:
          modalData?.year || car?.carList.length > 0
            ? car?.carList[0]?.year
            : 0,
        body_type: '',
        mileage: '',
        gear_type: '',
        fuel_type: '',
        imported: true,
        price: modalData?.b2bPrice,
        vin: modalData?.vin,
        model_id: modalData?.makeId
      },
      service: 'BUY_CAR',
      pick_up_location: {
        state: fieldsValue.state,
        city: fieldsValue.city,
        street: fieldsValue.street
      },
      country: country,
      source: 'leads',
      car_id: singleLoan?.carId, //car?.carList.length > 0 ? car?.carList[0]?.id : 0,
      pick_up_date: values.date,
      pick_up_time: values.time,
      lead_status: '',
      loan_id: singleLoan?.id
    }
    await bookInspection.mutateAsync(appointmentPayload)
  }

  const handleScheduleCreate = async (_data: any) => {
    const payload = {
      lead_id: singleLoan.id,
      date: _data.pickUpDate,
      time: _data.pickUpTime,
      note: formData.comment,
      activity_type: 'APPOINTMENT',
      // "location_id": "string",
      // service_rendered_id: _data.id,
      completed: false,
      appointment_id: _data.id,
      // "sales_officer_id": "string",
      user_id: userId
    }
    await createSchedule.mutateAsync(payload)
    await updateLoan.mutateAsync()
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }

  return (
    <Modal
      closable={false}
      visible={visibleBookInsp}
      width="598px"
      footer={null}
    >
      <StyledBookInspection>
        <div className="bookInspectionWrapper">
          <div className="title mb-1">{t('bookingInspection')}</div>
          <div className="text mb-4">
            {t('fillTheDetailsBelowBookInspection')}
          </div>

          <Form
            form={form}
            layout="vertical"
            name="horizontal_login"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="date"
                  label={t('Date')}
                  rules={[
                    {
                      type: 'object' as const,
                      required: true,
                      message: t('pleaseEnterDate')
                    }
                  ]}
                >
                  <DatePicker disabledDate={disabledDate} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="time"
                  label={t('Time')}
                  rules={[{ required: true, message: t('pleaseEnterTime') }]}
                >
                  <TimePicker />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="state"
                  label={t('State')}
                  rules={[
                    {
                      // type: 'object' as const,
                      required: true,
                      message: t('pleaseEnterTheState')
                    }
                  ]}
                >
                  <Input placeholder={t('enterStreetState')} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="city"
                  label={t('City')}
                  rules={[{ required: true, message: t('pleaseEnterTheCity') }]}
                >
                  <Input placeholder={t('enterCity')} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="street"
              label={t('Street')}
              rules={[{ required: true, message: t('pleaseEnterStreet') }]}
            >
              <Input placeholder={t('enterStreetName')} />
            </Form.Item>

            <Form.Item
              name="comment"
              label={t('comment')}
              rules={[{ required: true, message: t('pleaseEnterComments') }]}
            >
              <Input.TextArea placeholder={t('comment')} rows={3} />
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-center">
                {submitting ? (
                  <Spin />
                ) : (
                  <>
                    <Button
                      className="cancel me-3"
                      type="primary"
                      onClick={handleCancel}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button className="submit" type="primary" htmlType="submit">
                      {t('save')}
                    </Button>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledBookInspection>
    </Modal>
  )
}
