import React from 'react'

const EnvelopeIcon = () => {
  return (
    <svg
      width={18}
      height={16}
      viewBox="0 0 18 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.5 3H2.5C1.94772 3 1.5 3.44772 1.5 4V12C1.5 12.5523 1.94772 13 2.5 13H15.5C16.0523 13 16.5 12.5523 16.5 12V4C16.5 3.44772 16.0523 3 15.5 3Z"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.458 6.65005L14 9.00005"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.542 6.65005L4 9.00005"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.2534 3.34277L9.91137 7.71611C9.64346 7.90108 9.3256 8.00015 9.00004 8.00015C8.67447 8.00015 8.35662 7.90108 8.0887 7.71611L1.7467 3.34277"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default EnvelopeIcon
