import React from 'react'
import {
  FormHelperText,
  Grid,
  InputBase,
  TextField,
  Button,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import CustomInput from './CustomInput'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { usePhoneStyles } from './styles'
import FormFooter from './FormFooter'
import {
  addSchedule,
  fetchSchedule,
  setEvent
} from '../../../../store/events/actions'
import {
  getAllCities,
  getAllStates,
  getAllHomeCities
} from '../../../../store/location/selectors'
import {
  getLeadDetails,
  leadFromStore
} from '../../../../store/leads/selectors'
import {
  getEvents,
  getLocationOptions
} from '../../../../store/events/selectors'
import calendar from '../../../../images/calendar-dark.svg'
import { useActionStyles } from './styles'
import Select from 'react-select'

import {
  getCity,
  getStates,
  getHomeCity
} from '../../../../store/location/actions'
import { getSalesOfficer, updateLead } from '../../../../store/leads/actions'
import { LeadDetailsSectionTitle } from '../ServiceInterest/styles'

import { isAgent } from '../../../../store/auth/selectors'
import { useTranslation } from 'react-i18next'

const MakeCarAppointment = () => {
  const classes = useActionStyles()
  // dispatch(createAppointment(AppointmentData))
  const dispatch = useDispatch()
  const lead = useSelector(leadFromStore)
  const userIsAgent = useSelector(isAgent)
  const [officeState, setOfficeState] = React.useState('')
  const [officeCity, setofficeCity] = React.useState('')
  const [homeState, setHomeState] = React.useState('')
  const [homeCity, setHomeCity] = React.useState('')
  const [budgetOfLead, setBudget] = React.useState('')
  const [editLocation, setEditLocation] = React.useState(true)
  const [errorMess, setErrorMess] = React.useState(false)
  const cities = useSelector(getAllCities)
  const cities2 = useSelector(getAllHomeCities)
  const states = useSelector(getAllStates)
  const states2 = useSelector(getAllStates)

  const stateValue = {
    label: lead.officeLocation ? lead.officeLocation.state : 'state',
    value: 'state'
  }
  const officeCityValue = {
    label: lead.officeLocation ? lead.officeLocation.city : 'city',
    value: 'city'
  }
  const homeStateValue = {
    label: lead.homeLocation ? lead.homeLocation.state : 'state',
    value: 'state'
  }
  const homeCityValue = {
    label: lead.homeLocation ? lead.homeLocation.city : 'city',
    value: 'city'
  }

  const updateLeadLocation = () => {
    if (
      (!lead.officeLocation && !officeState) ||
      (lead.budget == 0 && budgetOfLead == '')
    ) {
      setErrorMess(true)
      return
    }

    let data = {
      office_location: {
        city: officeCity ? officeCity : lead.officeLocation.city,
        state: officeState
          ? states.find((state) => state.value === officeState)?.label
          : lead.officeLocation.state
      },

      home_location: {
        city: homeCity ? homeCity : lead.homeLocation.city,
        state: homeState
          ? states.find((state) => state.value === homeState)?.label
          : lead.homeLocation.state
      },

      budget: budgetOfLead ? budgetOfLead : lead.budget
    }
    setErrorMess(false)
    dispatch(updateLead(data, lead.id))
    cancelEditLocation()
  }
  const handleOfficeStateChange = (e: any) => {
    setOfficeState(e.value)
  }

  const handleHomeStateChange = (e: any) => {
    setHomeState(e.value)
  }
  const handleHomeCityChange = (e: any) => {
    setHomeCity(e.value)
  }
  const handleOfficeCityChange = (e: any) => {
    setofficeCity(e.value)
  }
  const handleBudgetChange = (e: any) => {
    setBudget(e.target.value)
  }
  const updateEditLocation = () => {
    setEditLocation(false)
  }
  const cancelEditLocation = () => {
    setEditLocation(true)
  }

  React.useEffect(() => {
    dispatch(getSalesOfficer())
  }, [])
  React.useEffect(() => {
    dispatch(getStates())
  }, [])

  React.useEffect(() => {
    dispatch(getCity(officeState))
  }, [officeState])

  const { t } = useTranslation()

  React.useEffect(() => {
    if (homeState) {
      dispatch(getHomeCity(homeState))
    }
  }, [homeState])
  return (
    <Grid item xs={12} style={{ marginBottom: '30px' }}>
      <LeadDetailsSectionTitle>{t('budgetLocation')}</LeadDetailsSectionTitle>
      <Grid item xs={12} className={classes.container}>
        {errorMess ? (
          <p style={{ color: 'red', textAlign: 'center' }}>
            {t('pleaseFillAllFields')}
          </p>
        ) : null}

        <Grid item xs={12} className={classes.selectArea}>
          <Grid className={classes.selectAreaChild}>
            <p>{t('customerOfficeLocation')}</p>

            {lead.officeLocation ? (
              <Select
                isDisabled={editLocation}
                defaultValue={stateValue}
                onChange={handleOfficeStateChange}
                options={states}
              />
            ) : (
              <Select
                onChange={handleOfficeStateChange}
                placeholder={t('state')}
                options={states}
              />
            )}
          </Grid>
          <Grid className={classes.selectAreaChild}>
            <p style={{ opacity: '0' }}>{t('customerOfficeLocation')}</p>

            {lead.officeLocation ? (
              <Select
                defaultValue={officeCityValue}
                onChange={handleOfficeCityChange}
                isDisabled={editLocation}
                options={cities}
              />
            ) : (
              <Select
                onChange={handleOfficeCityChange}
                placeholder={t('city')}
                options={cities}
              />
            )}
          </Grid>

          <Grid className={classes.selectAreaChild}>
            <p>{t('enterCustomersBudget')}</p>

            {lead.budget ? (
              <TextField
                id="date"
                name="date"
                disabled={editLocation}
                defaultValue={lead.budget}
                variant="outlined"
                type="text"
                onInput={handleBudgetChange}
              />
            ) : (
              <TextField
                id="date"
                name="date"
                variant="outlined"
                type="text"
                onInput={handleBudgetChange}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.selectArea}>
          <Grid className={classes.selectAreaChild}>
            <p>{t('customerHomeLocation')}</p>

            {lead.officeLocation ? (
              <Select
                defaultValue={homeStateValue}
                onChange={handleHomeStateChange}
                isDisabled={editLocation}
                options={states2}
              />
            ) : (
              <Select
                onChange={handleHomeStateChange}
                placeholder={t('state')}
                options={states2}
              />
            )}
          </Grid>
          <Grid className={classes.selectAreaChild}>
            <p style={{ opacity: '0' }}>{t('customerOfficeLocation')}</p>

            {lead.officeLocation ? (
              <Select
                defaultValue={homeCityValue}
                onChange={handleHomeCityChange}
                isDisabled={editLocation}
                options={cities2}
              />
            ) : (
              <Select
                onChange={handleHomeCityChange}
                placeholder={t('city')}
                options={cities2}
              />
            )}
          </Grid>
          <Grid className={classes.selectAreaChild}>
            <p style={{ opacity: '0' }}>{t('enterCustomersBudget')}</p>

            {lead.officeLocation && !editLocation ? (
              <Button
                variant="outlined"
                onClick={() => cancelEditLocation()}
                disableElevation
                style={{ marginRight: '20px' }}
              >
                {t('cancel')}
              </Button>
            ) : null}

            {(!lead.officeLocation && userIsAgent) ||
            (!editLocation && userIsAgent) ? (
              <Button
                variant="contained"
                onClick={() => updateLeadLocation()}
                disableElevation
              >
                {t('save')}
              </Button>
            ) : null}

            {lead.officeLocation && editLocation && userIsAgent ? (
              <Button
                variant="contained"
                onClick={() => updateEditLocation()}
                disableElevation
              >
                {t('Edit')}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default MakeCarAppointment
