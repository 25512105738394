import styled from 'styled-components'
import Input from '@material-ui/core/Input'

export const StyledTextField = styled(Input)<{
  width?: string
  height?: string
  borderRadius?: string
  transparent?: boolean
  noBgColor?: boolean
  borderColor?: string
  marginTop?: string
}>`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '40px')};
  color: ${({ theme }) => theme.palette.neutral.black};
  font-size: ${({ theme }) => theme.font.size.md};
  box-sizing: border-box;
  border-radius: ${({ borderRadius }) => (borderRadius ? borderRadius : '4px')};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  background-color: ${({ theme, noBgColor, transparent }) =>
    noBgColor
      ? 'transparent'
      : transparent
      ? theme.palette.common.white
      : theme.palette.neutral.ghostWhite};
  border: ${({ theme, borderColor }) =>
    `1px solid ${
      borderColor ? borderColor : theme.palette.neutral.cyanBluishGray
    }`};
  padding-left: 10px;

  .input-field-focused {
    outline: none;
    box-shadow: none;
  }

  &:before {
    border-bottom: none;
    display: none;
  }

  &:after {
    border-bottom: none;
    display: none;
  }
`

export const StyledLabel = styled.label<{
  labelColor?: string
  labelMarginBottom?: string
  labelMarginTop?: string
}>`
  height: 21px;
  line-height: 21px;
  display: block;
  font-size: ${({ theme }) => theme.font.size.md};
  margin-bottom: ${({ labelMarginBottom }) =>
    labelMarginBottom && labelMarginBottom};
  margin-top: ${({ labelMarginTop }) => labelMarginTop && labelMarginTop};
  color: ${({ theme, labelColor }) =>
    labelColor ? labelColor : theme.palette.neutral.grayishBlue};
`

export const Container = styled.div<{
  width?: string
}>`
  width: ${({ width }) => width && width};
`
