import React from 'react'
import { useDispatch, useSelector, connect } from 'react-redux'
import { getCurrentActivity, getAgentTasks } from '../../../store/leads/actions'

import {
  isAdmin,
  isManager,
  isAgent,
  getUserIdIfAgent
} from '../../../store/auth/selectors'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import { activityTypeFromTheStore } from '../../../store/leads/selectors'
import { Popover, Button } from '@material-ui/core'

import { usePhoneStyles } from '../../Leads/LeadDetails/LeadActions/styles'
import { Link } from 'react-router-dom'
import { deleteSchedule } from '../../../store/events/actions'

import { SubMenuPicDiv, SubMenu, Separator } from '../styles'
import { useTranslation } from 'react-i18next'

export default function ViewLead(prop: any) {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const userIsAdmin = useSelector(isAdmin)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const userIsManager = useSelector(isManager)
  const activityTypeFromStore: any = useSelector(activityTypeFromTheStore)
  const [dialogOpenDelete, setOpen] = React.useState(false)
  const userIsAgent = useSelector(isAgent)
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  let dataForTasks = {
    agentId: agentIdFromStore,
    completed: false
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleDialogClose = () => {
    setOpen(false)
  }

  const deleteScheduleFromPop = (
    id: any,
    activityType: string,
    appointmentType: string
  ) => {
    dispatch(deleteSchedule(id))
    if (activityType == 'PHONE_CALL' || appointmentType == 'null') {
      setTimeout(() => {
        let query = {
          activity_type: 'PHONE_CALL',
          agentId: agentIdFromStore,
          completed: false
        }
        if (userIsAdmin || userIsManager) {
          delete query.agentId
        }
        dispatch(getCurrentActivity(query))
        dispatch(getAgentTasks(dataForTasks))
      }, 100)
    } else {
      setTimeout(() => {
        let query = {
          appointment_type:
            activityTypeFromStore == 'BUY_CAR'
              ? 'BUY_CAR,TEST_DRIVE_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK'
              : activityTypeFromStore,
          agentId: agentIdFromStore,
          completed: false
        }
        if (userIsAdmin || userIsManager) {
          delete query.agentId
        }

        dispatch(getAgentTasks(dataForTasks))

        dispatch(getCurrentActivity(query))
      }, 200)
    }
  }

  // dispatch(createAppointment(AppointmentData))

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const { t } = useTranslation()

  return (
    <div>
      <Dialog
        open={dialogOpenDelete}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>{t('AreYouDeleteSchedule')}?</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            {t('Cancel')}
          </Button>
          <Button
            onClick={() =>
              deleteScheduleFromPop(
                prop.activity.id,
                prop.activityType,
                prop.activity.appointment && prop.activity.appointment.type
                  ? prop.activity.appointment.type
                  : 'null'
              )
            }
            color="primary"
            autoFocus
          >
            {t('Yes')}
          </Button>
        </DialogActions>
      </Dialog>

      <SubMenuPicDiv onClick={handleClick} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Link
          to={`/leads/${prop.activity.leadId}`}
          style={{ textDecoration: 'none', color: '#000' }}
        >
          <SubMenu>
            <span>{t('viewLead')}</span>
          </SubMenu>
        </Link>

        <Separator></Separator>

        {userIsAgent && prop.activity.agent.userId == agentIdFromStore ? (
          <SubMenu onClick={handleClickOpen}>
            <span>{t('deleteSchedule')}</span>
          </SubMenu>
        ) : null}
      </Popover>
    </div>
  )
}
