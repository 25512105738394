import { connect } from 'react-redux'
import LeadDetails from './LeadDetails'
import { StateProps } from './types'
import { isFetching } from '../../../store/isFetching/selectors'
import { fetchSchedule, fetchServiceOptions, IScheduleQueries } from '../../../store/events/actions'
import { getUserProfileImage, isAgent } from '../../../store/auth/selectors'
import {
  fetchLead,
  updateLead,
  fetchAgents,
  linkUser,
  createAppointment,
  fetchLocations,
  getServiceInterests,
  getMarketPlaceCars,
  getLeadNotes
} from '../../../store/leads/actions'
import {
  getLeadDetails,
  getAssignedAgent,
  getAllAgents,
  getAssignedAgentFromStore,
  getLeadUserId,
  getAllServiceInterests,
  getAllMarketPlaceCars,
  getAllAgentsAssignedToLead
} from '../../../store/leads/selectors'
import { getCarBody, getCarMake } from '../../../store/car/actions'


export const mapStateToProps = (state: Object) => ({
  details: getLeadDetails(state),
  assignedAgent: getAssignedAgent(state),
  agents: getAllAgents(state),
  userProfileImg: getUserProfileImage(state),
  assignedAgentFromStore: getAssignedAgentFromStore(state),
  getAllAgentsAssignedToLead: getAllAgentsAssignedToLead(state),
  isFetchingLead: isFetching(state, 'lead'),
  isFetchingAgents: isFetching(state, 'agents'),
  isFetchingLocations: isFetching(state, 'locations'),
  carsFromMarket: getAllMarketPlaceCars(state),
  isFetchingInterests: isFetching(state, 'interests'),
  isFetchingVehicles: isFetching(state, 'allCarDetails'),
  leadUserId: getLeadUserId(state),
  serviceInterests: getAllServiceInterests(state),
  userIsAgent: isAgent(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  getLead: (id: string) => dispatch(fetchLead(id)),
  getLeadNotesFromStore: (id: string,type:string, pageNumber:any) => dispatch(getLeadNotes(id, type, pageNumber)),
  getAgents: () => dispatch(fetchAgents()),
  createAppointment: () => dispatch(createAppointment()),
  getSchedules: (id: string, queries: IScheduleQueries) =>
    dispatch(fetchSchedule(id, queries)),
  updateDetails: (details: Object, id: string) =>
    dispatch(updateLead(details, id)),
  linkUser: () => dispatch(linkUser()),
  fetchLocations: () => dispatch(fetchLocations()),
  fetchServiceOptions: () => dispatch(fetchServiceOptions()),
  getServiceInterests: (id: string) => dispatch(getServiceInterests(id)),
  fetchCarMake: () => dispatch(getCarMake()),
  fetchCarBodyType: () => dispatch(getCarBody()),
})

export default connect<StateProps>(
  mapStateToProps,
  mapDispatchToProps
)(LeadDetails)
