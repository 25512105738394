import styled from 'styled-components'

export const StyledPartnerMapping = styled.div`
  flex-grow: 1;
  padding: 15px;

  .editButton {
    background: #ffffff;
    border: 1px solid #30355b;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    min-width: 96px;
    width: auto;
    height: 37px;
    color: #30355b;
    font-weight: 500;
    font-size: 14px;
  }

  .removeButton {
    background: #ffffff;
    border: 1px solid #d1373f;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    min-width: 96px;
    width: auto;
    height: 37px;
    color: #d1373f;
    font-weight: 500;
    font-size: 14px;
  }

  .partnerMapping {
    background: #f6f7fb;
    min-height: 100vh;
    height: auto;
    padding: 20px;

    .headerSection {
      padding-bottom: 25px;
      border-bottom: 1px solid #eeeeee;
      .head {
        color: #30355b;
        font-weight: 700;
        font-size: 16px;
      }
      .ant-input-affix-wrapper-lg {
        padding: 6.5px 11px;
        font-size: 14px;
        height: 47px;
        background: #fff;
      }
      input {
        width: 560px;
      }
      button {
        height: 47px;
        background: #ffb619;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        min-width: 179px;
        width: auto;
      }
    }

    th {
      color: #30355b;
      font-size: 13px;
      background: #eff0f6;
      box-shadow: 0px 1px 0px #dadbe4;
      font-weight: 600;
    }
    tbody td {
      color: #30355b;
      font-size: 13px;
    }

    .noPartner {
      min-height: 100vh;
      height: auto;
      .title {
        color: #9f9f9f;
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
`

export const StyledMapModal = styled.div`
  .selectedValue {
    .email {
      color: #30345e;
      font-size: 14px;
      font-weight: 400;
    }
    .name {
      color: #30345e;
      font-size: 14px;
      font-weight: 600;
    }
  }

  form {
    label {
      color: #30355b;
      font-weight: 500;
      font-size: 14px;
    }

    .cancel {
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      height: 47px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      min-width: 180px;
      width: auto;
    }

    .submit {
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      height: 47px;
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      border: 0;
      min-width: 180px;
      width: auto;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      position: relative;
      background-color: #fff;
      background: #f8f8fb;
      border: 1px solid #f8f8fb;
      border-radius: 2px;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      font-size: 14px;
      color: #30355b;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 47px;
      padding: 7px 11px;
    }

    .ant-select-single:not(.ant-select-customize-input)
      .ant-select-selector
      .ant-select-selection-search-input {
      height: 47px;
    }

    .ant-select-selection-placeholder {
      color: #a6acbe;
      font-weight: 500;
      font-size: 14px;
    }

    .ant-select-selection-overflow {
      min-height: 47px;
      height: auto;
    }

    .ant-select-multiple .ant-select-selection-item {
      max-width: 100%;
      height: 24px;
      margin-top: 2px;
      margin-bottom: 2px;
      line-height: 22px;
      background: #f5f5f5;
      border: 1px solid #f0f0f0;
      border-radius: 2px;
      cursor: default;
      transition: font-size 0.3s, line-height 0.3s, height 0.3s;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-margin-end: 4px;
      margin-inline-end: 4px;
      -webkit-padding-start: 8px;
      padding-inline-start: 8px;
      -webkit-padding-end: 4px;
      padding-inline-end: 4px;

      background: rgba(49, 53, 91, 0.11);
      border-radius: 29px;
    }
  }
`
