/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, InputBase } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import dayjs from 'dayjs'
import {
  getSchedules,
  getSearchQuery,
  getTabValue
} from '../../../../../store/events/selectors'
import { getLeadDetails, leadNotes } from '../../../../../store/leads/selectors'
import SortMenu from './SortMenu'
import { useTlStyles } from './styles'
import TimelineCard from './TimelineCard'
import TLTabs from './TlTabs'
import TitleWrapper from '../TitleWrapper'
import PaginationRow from './Pagination'
import { useTranslation } from 'react-i18next'

const Timeline: React.FC = () => {
  const classes = useTlStyles()
  const dispatch = useDispatch()
  const lead = useSelector(getLeadDetails)
  const [value, setValue] = React.useState('')
  const { completed, pagination } = useSelector(getSchedules)
  const activityType = useSelector(getTabValue)
  const storeSearchQuery = useSelector(getSearchQuery)
  const notesForLead = useSelector(leadNotes)
  const [page, setPage] = React.useState(false)
  const { t } = useTranslation()

  const getAuthor = (data: string) => {
    try {
      return JSON.parse(data)
    } catch { }
  }
  React.useEffect(() => {
    if (notesForLead.length > 0) {
      setPage(true)
    }
  }, [notesForLead])

  return (
    <TitleWrapper title={t('Notes')}>
      <React.Fragment>
        {page ? (
          <div className={classes.cardWrapper} style={{ minWidth: '100%' }}>
            {notesForLead.map((note: any) => (
              <div
                style={{
                  border: '1px solid #CAD0D9',
                  padding: '.5rem',
                  marginBottom: '1.25rem',
                  borderRadius: '0.25rem'
                }}
              >
                <span>{note.text}</span>
                <span style={{ color: '#A1AACE', display: 'block' }}>
                  {t('Authored by')} :{' '}
                  {getAuthor(note.authorId).firstname +
                    ' ' +
                    getAuthor(note.authorId).lastname}
                </span>


                <span style={{ color: '#A1AACE', display: 'block' }}>
                  {t('Created at')} :
                  {dayjs(note.createdAt).format('DD/MM/YYYY') +
                    ' ' +
                    new Date(note.createdAt).getMinutes() +
                    ':' +
                    new Date(note.createdAt).getSeconds()}
                </span>
                <span style={{ color: '#A1AACE', display: 'block' }}>
                  {`${t('Comment: ')} ${note.comment}`}
                </span>
              </div>
            ))}
          </div>
        ) : (
          <span
            style={{ color: '#A1AACE', textAlign: 'center', marginTop: '20px' }}
          >
            {t('SorryNotesLead')}
          </span>
        )}
      </React.Fragment>
    </TitleWrapper>
  )
}

export default Timeline
