import React from 'react'
import AccountPreference from './AccountPreference'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

const General = () => {
  return (
    <Router>
      <div>
        <Switch>
        <Route path="/settings">
            <AccountPreference />
          </Route>
          <Route path="/settings/agents">
           'kello'
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default General
