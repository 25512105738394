import React, { useState, useRef, useEffect } from 'react'
import Picker, { IEmojiData } from 'emoji-picker-react'
import { getUserId, getUserToken } from '../../../store/auth/selectors'
import api from '../../../utils/api'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../../../store/reducer.interface'
import { fetchChatMessages } from '../../../store/conversations/actions'
import EmojiI from '../../../images/smile.svg'
import StackedImageI from '../../../images/paginate-filter-picture.svg'
import AttachmentI from '../../../images/attachment-1.svg'
import SendI from '../../../images/send-email.svg'
import {
  InputBox,
  InputBoxHeader,
  MediumTitle,
  InputBoxBody,
  AttachmentBox,
  ChatInput,
  ChatOptions,
  ChatOptionIcon,
  WhatsAppIcon
} from './styles'

interface IInputBoxContainer {
  recipient: string
  phoneNumber: string
}

const getMessageTypeBasedOnFile = (mimeType: string) => {
  const mainType = mimeType.split('/')[0]

  switch (mainType) {
    case 'image':
      return 'IMAGE'
    case 'audio':
      return 'AUDIO'
    case 'video':
      return 'VIDEO'
    default:
      return 'DOCUMENT'
  }
}

interface IReplyDataPayload {
  text: string
  type: string
  imageUrl?: string
  audioUrl?: string
  videoUrl?: string
  fileUrl?: string
}

const getMessagePayload = (text: string, fileType?: string, url?: string) => {
  let payload: IReplyDataPayload = {
    text,
    type: 'TEXT'
  }

  switch (fileType) {
    case 'IMAGE':
      payload = { ...payload, type: fileType, imageUrl: url }
      break
    case 'AUDIO':
      payload = { ...payload, type: fileType, audioUrl: url }
      break
    case 'VIDEO':
      payload = { ...payload, type: fileType, videoUrl: url }
      break
    case 'DOCUMENT':
      payload = { ...payload, type: fileType, fileUrl: url }
      break
    default:
      break
  }

  return payload
}

export default function InputBoxContainer({
  recipient,
  phoneNumber
}: IInputBoxContainer) {
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const agentId = useSelector(getUserId)
  const ChatInputRef = useRef<HTMLTextAreaElement>(null)
  const ImageInputRef = useRef<HTMLInputElement>(null)
  const FileInputRef = useRef<HTMLInputElement>(null)
  const messageId = useSelector(
    (state: IRootState) =>
      state.conversations.conversationMessages[phoneNumber]?.data[0]?.messageId
  )
  const [message, setMessage] = useState('')
  const [showPicker, setShowPicker] = useState(false)
  const [attachment, setAttachment] = useState<File | null>(null)
  const [uploadedUrl, setUploadedUrl] = useState('')
  const [filePreviewUrl, setFilePreviewUrl] = useState('')

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(filePreviewUrl)
    }
  }, [filePreviewUrl])

  const typeMessage = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget
    setMessage(value)
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleReply()
      event.preventDefault()
    }
  }

  const pasteIntoInput = (element: HTMLTextAreaElement, text: string) => {
    const { selectionStart, selectionEnd } = element
    if (
      typeof selectionStart === 'number' &&
      typeof selectionEnd === 'number'
    ) {
      const newCaretPosition = selectionStart + text.length
      const value =
        message.slice(0, selectionStart) + text + message.slice(selectionEnd)
      setMessage(value)
      element.setSelectionRange(newCaretPosition, newCaretPosition)
    } else {
      const value = `${message} ${text}`
      setMessage(value)
    }
  }

  const handleReply = async () => {
    if (attachment) {
      try {
        let url
        if (!uploadedUrl) {
          const response = await api.upload(token, attachment)
          url = response.file.url
        } else {
          url = uploadedUrl
        }
        replyMessage(url)
      } catch {
        dispatch(showSnackbar('File upload failed', 'error'))
      }
    } else {
      replyMessage()
    }
  }

  const replyMessage = async (url?: string) => {
    let fileType

    try {
      if (url && attachment) {
        setUploadedUrl(url)
        fileType = getMessageTypeBasedOnFile(attachment.type)
      }

      const msgData = getMessagePayload(message, fileType, url)

      const payload = {
        agentId,
        whatsApp: msgData,
        destinations: [
          {
            messageId,
            to: {
              phoneNumber
            }
          }
        ]
      }

      const response = await api.replyConversation(token, payload)
      if (response) {
        dispatch(fetchChatMessages(phoneNumber, true))
        dispatch(showSnackbar('Sent!', 'success'))
        setMessage('')
        setFilePreviewUrl('')
        setUploadedUrl('')
        setAttachment(null)
      }
    } catch {
      dispatch(showSnackbar('Unable to send message!', 'error'))
    }
  }

  const selectEmoji = (
    event: React.MouseEvent<Element>,
    emojiObject: IEmojiData
  ) => {
    const element = ChatInputRef.current as HTMLTextAreaElement
    pasteIntoInput(element, emojiObject.emoji)
    setShowPicker(false)
  }

  const attachFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]

    if (file) {
      const fileUrl = URL.createObjectURL(file)
      setAttachment(file)
      setFilePreviewUrl(fileUrl)
    }
  }

  const beginImageInput = () => {
    ImageInputRef.current?.click()
  }

  const beginAttachment = () => {
    FileInputRef.current?.click()
  }

  return (
    <InputBox>
      <InputBoxHeader>
        <WhatsAppIcon />
        <MediumTitle>Whatsapp</MediumTitle>
      </InputBoxHeader>
      <InputBoxBody>
        {filePreviewUrl && (
          <AttachmentBox>
            <a target="_blank" rel="noreferrer noopenner" href={filePreviewUrl}>
              {(attachment?.name?.length as number) > 20
                ? attachment?.name.slice(0, 20) + '...'
                : attachment?.name}
            </a>
            <span onClick={() => setFilePreviewUrl('')}>x</span>
          </AttachmentBox>
        )}

        <ChatInput
          ref={ChatInputRef}
          value={message}
          onChange={typeMessage}
          onKeyPress={handleKeyPress}
          placeholder={`Send a Message to ${recipient}`}
        />
        {showPicker && <Picker onEmojiClick={selectEmoji} preload={false} />}
        <input
          type="file"
          hidden
          disabled={filePreviewUrl ? true : false}
          ref={ImageInputRef}
          onChange={attachFile}
          accept="image/*"
        />
        <input
          type="file"
          hidden
          disabled={filePreviewUrl ? true : false}
          ref={FileInputRef}
          onChange={attachFile}
          accept=".doc,.docx,.csv,.xls,.xlsx,.pdf,audio/*,video/*"
        />
        <ChatOptions>
          <ChatOptionIcon
            icon={EmojiI}
            onClick={() => setShowPicker((showPicker) => !showPicker)}
          />
          <ChatOptionIcon icon={StackedImageI} onClick={beginImageInput} />
          <ChatOptionIcon icon={AttachmentI} onClick={beginAttachment} />
          <ChatOptionIcon icon={SendI} onClick={handleReply} sendBtn />
        </ChatOptions>
      </InputBoxBody>
    </InputBox>
  )
}
