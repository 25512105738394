import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  Form,
  Input,
  Select,
  Upload,
  message,
  InputNumber
} from 'antd'
import { StyledDisbursedModal } from './styles'
import { useParams } from 'react-router-dom'
import { showSnackbar } from '../../../store/snackbar/actions'
import api from '../../../utils/api'
import { getUserToken } from '../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

const API_URL = process.env.REACT_APP_API_URL

export default function RecordModal({
  isRecordVisible,
  setIsRecordVisible
}: any) {
  const { Option } = Select
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const [getOffers, setGetOffers] = useState<any>([])
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: fetchOffer } = useQuery('fetchOffer', () =>
    api.fetchOffers(token, id)
  )

  const { data: singleLoan, status, refetch } = useQuery('singleLoans', () =>
    api.fetchSingleOriginationLoan(token, id)
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
    }
  }, [singleLoan, queryClient, id])

  useEffect(() => {
    if (singleLoan) {
      setGetOffers(singleLoan)
    }
  }, [singleLoan])

  const [form] = Form.useForm()

  useEffect(() => {
    form.setFieldsValue({
      bank: getOffers?.offer?.partner?.name
    })
  }, [getOffers])

  const props = {
    name: 'file',
    multiple: false,
    action: `${API_URL}/document/upload`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e: any) {
      // console.log('Dropped files', e.dataTransfer.files)
    }
  }

  const normFile = (e: any) => {
    // console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const onFinish = async (values: any) => {
    const data = {
      partner_id: getOffers?.offer?.partner?.id,
      amount: values.amount,
      file_url: values.upload[0].response.file.url,
      loan_id: id
    }

    try {
      const response = await api.createDisbursement(token, data)
      if (response) {
        dispatch(showSnackbar('Disbursement recorded successfully', 'success'))
        setIsRecordVisible(false)
      }
    } catch (err) {
      dispatch(
        showSnackbar('Error occurred while recording disbursement', 'error')
      )
    }
    refetch()
  }

  const onCancel = () => {
    setIsRecordVisible(false)
  }

  return (
    <Modal
      closable={false}
      width="440px"
      footer={null}
      visible={isRecordVisible}
    >
      <StyledDisbursedModal>
        <div className="equityDisburseModal">
          <div className="header mb-3">{t('recordDisbursment')}</div>
          <Form
            form={form}
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            // autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              name="bank"
              label={t('Bank')}
              rules={[{ required: true, message: t('pleaseInputYourBank') }]}
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label={t('amountPaid')}
              name="amount"
              rules={[{ required: true, message: t('pleaseInputYourAmount') }]}
            >
              <InputNumber />
            </Form.Item>

            <Form.Item label={t('uploadProofOfPayment')}>
              <Form.Item
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger {...props} name="file">
                  <p className="ant-upload-text">{t('upload')}</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-center mt-3">
                <Button className="cancelButton me-3" onClick={onCancel}>
                  {t('Cancel')}
                </Button>
                <Button
                  className="requestButton"
                  type="primary"
                  htmlType="submit"
                >
                  {t('request')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledDisbursedModal>
    </Modal>
  )
}
