import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Popover from '@material-ui/core/Popover'
import styled from 'styled-components'
import dayjs from 'dayjs'
import {
  getCrmNotifications,
  updateCrmNotification
} from '../store/leads/actions'
import { getUserIdIfAgent } from '../store/auth/selectors'
import { newTasks, crmNotifications } from '../store/leads/selectors'
import Badge from '@material-ui/core/Badge'

import BellIcon from '../../src/images/bell2.svg'
import box from '../../src/images/box-dark.svg'
import { useDispatch, useSelector } from 'react-redux'
import PhoneIcon from '../../src/images/phone_act.svg'
import DummyCar from '../../src/images/dummy-car.svg'
import Pagination from '@material-ui/lab/Pagination'
import { CarlendarIconBtn } from '../views/Leads/styles'

import { withStyles } from '@material-ui/core/styles'

import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: 3,
    top: 29,
    color: theme.palette.background.paper,
    padding: '0 4px'
  }
}))(Badge)

export const DetailItemPhoneIcon = styled.div`
  width: 60px;
  height: 35px;
  margin-right: 10px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${PhoneIcon});
  background-repeat: no-repeat;
`
export const DetailItemBellIcon = styled.div`
  width: 60px;
  height: 35px;
  margin-right: 10px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${BellIcon});
  background-repeat: no-repeat;
`
export const DummyCarDiv = styled.div`
  width: 60px;
  height: 35px;
  margin-right: 10px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${DummyCar});
  background-repeat: no-repeat;
`
export const BoxDiv = styled.div`
  width: 60px;
  height: 35px;
  margin-right: 10px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${box});
  background-repeat: no-repeat;
`
const Notifications = styled.div`
  min-width: 300px;

  padding-top: 20px;
`
const NewNotificationsHeader = styled.div`
  padding: 10px 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  background: #6973a9;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
`

const NotificationsItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 10px;
  border-bottom: 1px solid #ebecf0;
  &:hover {
    background: #e6eaf0;
    cursor: pointer;
  }
`

const ItemContent = styled.div`
  flex-grow: 1;
  width: 100%;
`

const ItemContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`

const NotificationMedium = styled.span`
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: #aeb6be;
  text-transform: uppercase;
`

const NotificationTime = styled.span`
  font-size: 10px;
  line-height: 14px;
  color: #7c7e88;
`

const NotificationMsg = styled.p`
  font-size: 12px;
  color: #000;
  margin: 0;
`
export const PaginationCover = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  position: sticky;
  bottom: -1px;
  background-color: #e6eaf0;
`

export default function SimplePopover() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const tasks: any = useSelector(newTasks)
  const history = useHistory()
  const dispatch = useDispatch()
  const [pageNumber, setPage] = React.useState(1)
  const [pageCount, setPageCount] = React.useState(1)
  const crmNotification: any = useSelector(crmNotifications)

  const { t } = useTranslation()

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  interface IContent {
    activityType: string
    date: any
    time: string
    appointment: any
    lead: any
    read: boolean
    note: any
  }

  const gotoActivities = (item: any) => {
    history.push(`/leads/${item.leadId}`)
    let data = {
      read: true,
      id: item.id
    }
    dispatch(updateCrmNotification(data, item.id))
  }

  const handleChange = (event: any, value: number) => {
    setPage(value)
    let query = {
      current_page: value,
      page_size: 10,
      read: false,
      agentId: agentIdFromStore
    }
    dispatch(getCrmNotifications(query))
  }
  React.useEffect(() => {
    if (tasks.pagination) {
      setPageCount(
        Math.ceil(tasks.pagination.total / tasks.pagination.pageSize)
      )
    }
  }, [tasks])
  React.useEffect(() => {
    let data = {
      agentId: agentIdFromStore,
      read: false
    }
    dispatch(getCrmNotifications(data))
  }, [])

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div>
      {/* <CarlendarIconBtn /> */}

      {tasks.pagination && tasks.pagination.total > 0 ? (
        <StyledBadge badgeContent={tasks.pagination.total} color="secondary">
          <CarlendarIconBtn onClick={handleClick} />
        </StyledBadge>
      ) : (
        <CarlendarIconBtn onClick={handleClick} />
      )}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {crmNotification && crmNotification.length > 0 ? (
          <Notifications>
            <NewNotificationsHeader>{t('newTask')}</NewNotificationsHeader>

            {crmNotification.map((item: any) => {
              return (
                <NotificationsItem
                  key={item.id}
                  onClick={() => gotoActivities(item)}
                >
                  {item.type === 'reminder' ? <DetailItemBellIcon /> : null}
                  {item.type === 'phone_call' ? <DetailItemPhoneIcon /> : null}
                  {item.type == 'appointment' ? <DummyCarDiv /> : null}
                  {item.type == 'store_visit' ? <BoxDiv /> : null}

                  {/* {
  item.activityType === 'REMINDER' ? 
            <DetailItemBellIcon /> : null }
{
  item.activityType === 'PHONE_CALL' ? 
            <DetailItemPhoneIcon /> : null }
{
 item.appointment && item.appointment.type == 'TEST_DRIVE_CAR' ?
            <DummyCarDiv /> : null }
{
 item.appointment && item.appointment.type == 'SELL_CAR' ?
            <DummyCarDiv /> : null }
{
 item.appointment && item.appointment.type == 'FIX_CAR' ?
            <DummyCarDiv /> : null }
{
 item.appointment && item.appointment.type == 'BUY_CAR' ?
 <div style={{backgroundImage : `url(${item.appointment.carData.imageUrl})`, width:'60px', backgroundSize:'cover', borderRadius:'5px', height:'35px', marginRight:'10px'}}>

   </div>: null } */}

                  <ItemContent>
                    <ItemContentTop>
                      {item.type === 'phone_call' ? (
                        <NotificationMedium>
                          {t('phoneCall')}
                        </NotificationMedium>
                      ) : null}
                      {item.type === 'store_visit' ? (
                        <NotificationMedium>
                          {t('storeVisit')}
                        </NotificationMedium>
                      ) : null}
                      {item.type === 'appointment' ? (
                        <NotificationMedium>
                          {t('appointment')}
                        </NotificationMedium>
                      ) : null}
                      {item.type === 'reminder' ? (
                        <NotificationMedium>{t('reminder')}</NotificationMedium>
                      ) : null}

                      <NotificationTime>
                        {dayjs(item.createdAt).fromNow()}
                      </NotificationTime>
                    </ItemContentTop>
                    {/* <NotificationSender>{item.lead.firstName +' '+ item.lead.lastName}</NotificationSender> */}

                    {item.type !== 'appointment' ? (
                      <NotificationMsg>
                        {item.content.lead ? (
                          <NotificationMsg>
                            {item.content.lead.firstName +
                              ' ' +
                              item.content.lead.lastName}
                          </NotificationMsg>
                        ) : null}
                      </NotificationMsg>
                    ) : null}

                    {item.type == 'appointment' ? (
                      <NotificationMsg>
                        {item.content.lead ? (
                          <NotificationMsg>
                            {item.content.lead.firstName +
                              ' ' +
                              item.content.lead.lastName}
                          </NotificationMsg>
                        ) : null}
                        {item.content.appointment.carData ? (
                          <NotificationMsg>
                            {item.content.appointment.carData.year +
                              ' ' +
                              item.content.appointment.carData.make +
                              ' ' +
                              item.content.appointment.carData.model}
                          </NotificationMsg>
                        ) : null}
                      </NotificationMsg>
                    ) : null}
                  </ItemContent>
                </NotificationsItem>
              )
            })}
          </Notifications>
        ) : (
          <div style={{ padding: 10 }}>
            <h4>{t('noNotificationsTime')}</h4>
          </div>
        )}

        {/* <CrmNoty/> */}

        <PaginationCover>
          <Pagination
            count={pageCount}
            page={pageNumber}
            onChange={handleChange}
          />
        </PaginationCover>
      </Popover>
    </div>
  )
}
