export const SOURCING_STATUS = {
  TITLE_PENDING: 'title pending',
  TITLE_ACCEPTED: 'title accepted',
  TITLE_REJECTED: 'title rejected',
  DOCUMENT_PENDING: 'document pending',
  DOCUMENT_REJECTED: 'document rejected',
  DOCUMENT_ACCEPTED: 'document accepted',
  PPI_PASS: 'ppi pass',
  PPI_FAIL: 'ppi fail',
  PPI_PENDING: 'ppi pending',
  CUSTOMER_PENDING: 'customer pending',
  CUSTOMER_ACCEPTED: 'customer accepted',
  CUSTOMER_REJECTED: 'customer rejected'
}

export const SOURCING_TITLE_STATUS = [
  {
    id: 'title pending',
    name: 'TITLE PENDING'
  },
  {
    id: 'title accepted',
    name: 'TITLE ACCEPTED'
  },
  {
    id: 'title rejected',
    name: 'TITLE REJECTED'
  }
]
export const SOURCING_CUSTOMER_STATUS = [
  {
    id: 'customer pending',
    name: 'CUSTOMER PENDING'
  },
  {
    id: 'customer accepted',
    name: 'CUSTOMER ACCEPTED'
  },
  {
    id: 'customer rejected',
    name: 'CUSTOMER REJECTED'
  }
]
export const SOURCING_DOCUMENT_STATUS = [
  {
    id: 'document pending',
    name: 'DOCUMENT PENDING'
  },
  {
    id: 'document accepted',
    name: 'DOCUMENT ACCEPTED'
  },
  {
    id: 'document rejected',
    name: 'DOCUMENT REJECTED'
  }
]
export const SOURCING_PPI_STATUS = [
  {
    id: 'ppi pending',
    name: 'PPI PENDING'
  },
  {
    id: 'ppi pass',
    name: 'PPI PASS'
  },
  {
    id: 'ppi fail',
    name: 'PPI FAIL'
  }
]
export const AutocheckB2BPartner = {
  staging_partner_id: 'fWYvOKJmB',
  ng_prod_partner_id: 'TrIdvrtE3',
  gh_prod_partner_id: 'p3c0ZsGL6',
  ug_prod_partner_id: 'E7OuN2H9K',
  ci_prod_partner_id: 'sTiLHMwrn',
  ke_prod_partner_id: 'p9tdTyhYy'
}
