import styled from 'styled-components'
import {keyframes} from 'styled-components'
import Slider from 'react-slick'
import CancelIconI from '../../../../images/close_modal.svg'
import MenuIconI from '../../../../images/icons-menu-vertical.svg'
import PersonIconI from '../../../../images/person-3.svg'
import CheckCircleIcon from '../../../../images/check-circle.svg'
import BadSignIcon from '../../../../images/bad.svg'
import Modal from '@material-ui/core/Modal'
import NotesIconI from '../../../../images/notes-icon.svg'
import CarRetroI from '../../../../images/car-retro.svg'
import PersonIconIC from '../../../../images/person-5.svg'
import SubMenuPic from '../../../../images/menu-vert.svg'
import GaugeIcon from '../../../../images/gauge-dashboard.svg'
import UsedIcon from '../../../../images/car-insurance.svg'
import LocationIcon from '../../../../images/sync-location.svg'
import PrevIcon from '../../../../images/prev.svg'
import NxtIcon from '../../../../images/next.svg'
import VinIconI from '../../../../images/vin.svg'
import FlagconI from '../../../../images/flag.svg'
import GasPumpI from '../../../../images/gas-pump.svg'
import ColorIconI from '../../../../images/color.svg'
import CarGearIconI from '../../../../images/car-gear.svg'
import SearchBarIconI from '../../../../images/search-bar.svg'
import PaidDepositI from '../../../../images/paid-deposit.svg'
import WarrantyI from '../../../../images/warranty.svg'
import DummyCar from '../../../../images/dummy-car.svg'
import TimeClockIcon from '../../../../images/time-clock-circle.svg'





export const LeadDetailsSectionTitle = styled.div`
  color: ${({ theme }) => theme.palette.primary.dark};
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: 22px;
  line-height: 22px;
  margin-bottom: 10px;
`











export const Container = styled.div`
  margin-top: 24px;
`

export const ModalHeader = styled.div`
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 56px;
`
export const SubMenuPicDiv = styled.span`
width: 22px;
height: 22px;
background-image: url(${SubMenuPic});
cursor: pointer;
`

const rotate = keyframes`
0% {
  background: #f4f4f4;
}
50% {
  background: #f4f4f4b3;
}
100% {
  background: #f4f4f4b3;
}
`;

export const PulseDiv = styled.div`
 
  animation: ${rotate} 2s linear .3s;
  padding: 2rem 1rem;
  
`;













export const ModalTitle = styled.div`
  color: #000000;
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  height: 24px;
  margin-top: 16px;
  line-height: 24px;
`

export const CancelIcon = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  right: 17px;
  background-size: contain;
  background-image: url(${CancelIconI});
  background-repeat: no-repeat;
  margin-top: 16px;
  cursor: pointer;
`

export const ModalBody = styled.div`
  width: 100%;
  padding: 24px 20px 0px;
`

export const Body = styled.div`
  width: 100%;
  padding: 0px 20px 16px;
`

export const ModalVehicleNameAmount = styled.div`
  font-size: 20px;
  height: 28px;
  line-height: 28px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const IconMenuVertical = styled.div`
  width: 24px;
  height: 24px;
  background-size: contain;
  background-image: url(${MenuIconI});
  background-repeat: no-repeat;
`

export const Flex = styled.div<{
  marginTop?: string
  marginBottom?: string
  marginRight?: string
  noJustify?: boolean
  paddingTop?: string
  width?: string
}>`
  display: flex;
  justify-content: ${({ noJustify }) => !noJustify && 'space-between'};
  margin-top: ${({ marginTop }) => marginTop && marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  margin-right: ${({ marginRight }) => marginRight && marginRight};
  padding-top: ${({ paddingTop }) => paddingTop && paddingTop};
  width: ${({ width }) => width && width};
`

export const ModalVehicleAssignedTo = styled.div`
  display: flex;
  background: #eeeeee;
  padding: 4px 5px;
  margin-top: 13px;
`

export const ModalVehicleAssignedToText = styled.div`
  color: #474f58;
  height: 21px;
  line-height: 21px;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const FullVehicleDetailsTitle = styled.div`
  color: #a1aace;
  height: 24px;
  line-height: 24px;
  margin-top: 14px;
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const VinIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${VinIconI});
  background-repeat: no-repeat;
`

export const ColorIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${ColorIconI});
  background-repeat: no-repeat;
`

export const FlagIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${FlagconI});
  background-repeat: no-repeat;
`

export const CarGearIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${CarGearIconI});
  background-repeat: no-repeat;
`

export const GasPumpIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${GasPumpI});
  background-repeat: no-repeat;
`

export const FullVehicleDetailsItemTitle = styled.div`
  color: #a1aace;
  height: 17px;
  line-height: 16px;
  margin-right: 8px;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const FullVehicleDetailsItemValue = styled.div`
  color: #a1aace;
  height: 17px;
  line-height: 16px;
  text-transform: capitalize;
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`

export const PersonIconThree = styled.div`
  width: 16px;
  height: 16px;
  background-size: contain;
  background-image: url(${PersonIconI});
  background-repeat: no-repeat;
  margin-right: 10px;
  margin-top: 2px;
`

export const ImageSlide = styled.div`
  width: 200px;
  height: 220px;
  display: flex;
  background-color: #000;
  justify-content: center;

  & img {
    height: 220px;
  }
`
export const Separator = styled.div`
 border-top:1px solid #f4f4f4b3;
`
export const SubMenu = styled.div`
  padding-left:5px;
  cursor:pointer;
  padding:6px;
  :hover {
    background: #f4f4f4b3;
  }
`

export const ImgWrap = styled.div`
  width: 100%;
  height: 220px;
  display: flex;
  justify-content: center;
`

export const StyledSlider = styled(Slider)`
  margin-top: 24px;

  .slick-slide {
    margin-right: 4px;
    width: auto !important;
  }

  .slick-list {
    height: 220px;
  }

  .slick-prev {
    left: 25px;
    z-index: 1;
  }

  .slick-next {
    right: 25px;
    z-index: 1;
  }
`

export const DetailsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  max-height: 400px;
`

export const DetailsSectionLeft = styled.div`
  width: 262px;
  max-height: 390px;
`

export const DetailsSectionRight = styled.div`
  width: 550px;
  max-height: 380px;
  overflow-y: scroll;
`

export const DetailsSectionLeftItem = styled.div<{ active?: boolean }>`
  width: 100%;
  height: 42px;
  padding: 0px 16px;
  color: #000000;
  background: ${({ active }) => (active ? '#EDF0FF' : '#f8f8fb')};
  cursor: pointer;
  box-shadow: inset 0px -1px 0px rgba(163, 163, 163, 0.25);
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const DetailsSectionRightItem = styled.div`
  width: 100%;
  height: 42px;
  padding: 0px 16px;
  color: #7c7e88;
  line-height: 19px;
  text-transform: capitalize;
  box-shadow: inset 0px -1px 0px rgba(163, 163, 163, 0.25);
  font-size: ${({ theme }) => theme.font.size.md};
`

export const CheckCircle = styled.div`
  width: 16px;
  height: 16px;
  background-size: contain;
  background-image: url(${CheckCircleIcon});
  background-repeat: no-repeat;
  margin-top: 2px;
`

export const BadSign = styled.div`
  width: 16px;
  height: 16px;
  background-size: contain;
  background-image: url(${BadSignIcon});
  background-repeat: no-repeat;
  margin-top: 2px;
`

export const StyledModal = styled(Modal)`
  overflow: scroll;
`

export const InspectionDocumentContainer = styled.div`
  height: 55px;
  padding-top: 10px;
`

export const InspectionDocumentLink = styled.div`
  height: 17px;
  color: #000000;
  line-height: 14px;
  text-decoration: underline;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const NotesIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 11px;
  background-size: contain;
  background-image: url(${NotesIconI});
  background-repeat: no-repeat;
`

export const CarDealerOwnerInfo = styled.div`
  display: flex;
  min-height: 139px;
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
`

export const CarInfoLeft = styled.div`
  width: 50%;
  padding-left: 20px;
  border-right: 1px solid #ededed;
`

export const CarInfoRight = styled.div`
  width: 50%;
  padding-left: 28px;
`

export const CarInfoTitle = styled.div`
  height: 24px;
  line-height: 24px;
  color: #a1aace;
  margin: 16px 0;
  width: 100%;
`

export const CarRetroIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${CarRetroI});
  background-repeat: no-repeat;
`

export const PersonIconFive = styled.div`
  width: 16px;
  height: 16px;
  background-size: contain;
  background-image: url(${PersonIconIC});
  background-repeat: no-repeat;
  margin-right: 10px;
  margin-top: 2px;
`

export const AlternativeCarCard = styled.div`
  width: 296px;
  height: 257px;
  margin-right: 16px;
`

export const AlternativeCarCardImg = styled.div<{ image: string }>`
  width: 296px;
  height: 102px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: ${({ image }) => image && `url(${image})`};
  background-position: center;
  background-color: #000;
`

export const AlternativeCardImgWrap = styled.div`
  width: 296px;
  height: 102px;
`

export const AlternativeCarCardMetaLane = styled.div`
  width: 100%;
  height: 22px;
  padding: 0px 8px;
  position: relative;
  bottom: 27px;
`

export const AlternativeCarCardMetaYear = styled.span`
  width: 28px;
  height: 16px;
  padding: 0px 8px;
  background-color: ${({ theme }) => theme.palette.common.white};
  margin-right: 10px;
`

export const AlternativeCarCardMetaTitle = styled.span`
  height: 22px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.font.size.H6};
`

export const AlternativeCarCardInfoSection = styled.div`
  height: 123px;
  padding-left: 12px;
  padding-top: 12px;
  padding-bottom: 17px;
  text-transform: capitalize;
  background-color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.font.size.H6};
`

export const AlternativeCarCardAmount = styled.div`
  font-size: ${({ theme }) => theme.font.size.H6};
  height: 22px;
  line-height: 22px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  margin-bottom: 8px;
`

export const DetailItemGaugeIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${GaugeIcon});
  background-repeat: no-repeat;
`

export const DetailItemLocationIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${LocationIcon});
  background-repeat: no-repeat;
`

export const DetailItemTimeIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${TimeClockIcon});
  background-repeat: no-repeat;
`

export const DetailItemUsedIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  background-size: contain;
  background-image: url(${UsedIcon});
  background-repeat: no-repeat;
`

export const AlternativeCarCardItemTitle = styled.div`
  height: 16px;
  color: #7c7e88;
  line-height: 16px;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const AlternativeCarCardButton = styled.div`
  width: 100%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.common.white};
  font-size: ${({ theme }) => theme.font.size.sm};
  background-color: ${({ theme }) => theme.palette.secondary.main};
`

export const AlternativeCarSectionFlex = styled(Flex)`
  overflow-x: scroll;
  background: #f8f8f8;
`

export const ModalFooter = styled.div`
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 66px;
  padding: 13px 20px;
`

export const ModalCloseButton = styled.div`
  background-color: #fff;
  border: 1px solid #cbcccd;
  border-radius: 4px;
  width: 93px;
  height: 40px;
  color: #31355b;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const PreviousIcon = styled.div`
  width: 32px;
  height: 32px;
  background-size: contain;
  background-image: url(${PrevIcon});
  background-repeat: no-repeat;
  z-index: 1;
  left: 13px;
  position: absolute;
  top: 95px;
`

export const NextIcon = styled.div`
  width: 32px;
  height: 32px;
  z-index: 1;
  background-size: contain;
  background-image: url(${NxtIcon});
  background-repeat: no-repeat;
  right: 13px;
  position: absolute;
  top: 95px;
`

export const SearchContainer = styled.div`
  width: 100%;
  height: 63px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e5e5e5;
`

export const SearchContainerDiv = styled.div`
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e5e5e5;
  & input{
    min-width:100%;
    padding:10px;
    border: 1px solid #E5E5E5;
  }
`


export const NoItemsText = styled.div`
  width: 100%;
  height: 19px;
  display: flex;
  line-height: 19px;
  margin-top: 9px;
  justify-content: center;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const NoItemsContainer = styled.div`
  width: 100%;
  height: 100%;
  height: 60px;
  display: flex;
  flex-direction: column;
  margin-top: 69px;
  color: #a1aace;
  justify-content: center;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const SearchBarIcon = styled.div`
  width: 32px;
  height: 32px;
  background-size: contain;
  background-image: url(${SearchBarIconI});
  background-repeat: no-repeat;
`

export const SearchBarIconFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`

export const BuyCarItem = styled.div<{ isSell?: boolean }>`
  min-height: ${({ isSell }) => (isSell ? '137px' : '132px')};
  padding: 16px 0;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ededed;
`

export const BuyCarItemImage = styled.div`
  width: 120px;
  height: 80px;
  margin-right: 16px;

  & img {
    width: 120px;
    height: 80px;
  }
`

export const SellCarImage = styled.div`
  width: 120px;
  height: 80px;
  margin-right: 16px;
  background-size: contain;
  background-image: url(${DummyCar});
  background-repeat: no-repeat;
`

export const BuyCarItemDetails = styled.div`
  width: 387px;
  height: 80px;
`

export const BuyCarItemName = styled.div`
  
  line-height: 22px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.font.weight.normal};
  font-size: ${({ theme }) => theme.font.size.H6};
`

export const BuyCarItemPrice = styled.div`
  height: 22px;
  line-height: 22px;
  margin-bottom: 3px;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.H6};
`

export const BuyCarItemBottom = styled.div`
  height: 29px;
  display: flex;
  justify-content: space-between;
`

export const ViewCarDetails = styled.div`
  height: 16px;
  color: #4b9ff2;
  line-height: 16px;
  margin-top: 3px;
  margin-bottom: 10px;
  color: #4b9ff2;
  cursor: pointer;
  text-decoration-line: underline;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const ServiceType = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  margin-top: 13px;
  margin-right: 20px;
  height: 16px;
  line-height: 16px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.secondary.main};

  span {
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

export const MarketPlaceLink = styled.a`
  color: ${({ theme }) => theme.palette.secondary.main};
  cursor: pointer;
  line-height: 16px;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const TestDriveIndicator = styled.span`
  height: 22px;
  line-height: 22px;
  margin-bottom: 2px;
  margin-left: 5px;
  color: red;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: ${({ theme }) => theme.font.size.xs};
`

export const PaidDeposit = styled.div`
  width: 101px;
  height: 29px;
  background-size: contain;
  background-image: url(${PaidDepositI});
  background-repeat: no-repeat;
`

export const WarrantyIcon = styled.div`
  width: 131px;
  height: 20px;
  background-size: contain;
  background-image: url(${WarrantyI});
  background-repeat: no-repeat;
`

export const ActiveBar = styled.div`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  position: relative;
  margin-left: 239px;
  width: 6px;
  height: 42px;
  margin-top: -33px;
`

export const SellCarItem = styled.div`
  display: flex;
  width: 105px;
  line-height: 16px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.palette.secondary.main};
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.normal};
`

export const SellCount = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #4B9FF2;
  color: white;
  position: relative;
  margin-left: -10px;
  margin-top: 20px;
  text-align: center;
  line-height: 16px;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`

export const FixCount = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #4B9FF2;
  color: white;
  position: relative;
  margin-left: -15px;
  margin-right: 5px;
  margin-top: 20px;
  text-align: center;
  line-height: 16px;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`


export const CarSearchContainer = styled.div`
  display: flex;
  line-height: 16px;
  margin-bottom:20px;
  margin-top:1px;
  height:190px;
  overflow-x:auto;
  padding:10px;
  flex-flow:column;
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 6px;
  background:#fff;
  &h4, p{

    margin-top:5px;
    margin-bottom:5px
  }
`
export const DropDownParent = styled.div`

  box-shadow: rgb(0 0 0 / 16%) 0px 2px 6px;
  background:#fff;
  display:flex;
  flex-flow:column;
  
`
export const PaginationCover = styled.div`

display:flex;
justify-content:center;
box-shadow: rgb(0 0 0 / 16%) 0px 2px 6px;
padding:5px;
`
export const LoaderCover = styled.div`


  padding:10px;
 min-height:239px;
 display:flex;
 align-items:center;
 justify-content:center;
`

export const CarSearchContainerChild = styled.div`
  display: flex;
  padding-bottom:120px;
 border-bottom:1px solid #f4f4f4b3;
  margin-top:20px;
  min-height:150px;
  cursor:pointer;
  &.image-class {
   width:130px;
   height:80px;
  }

`
export const CarSearchContainerChildImage = styled.div`


flex-basis:40%;
 margin-right:20px;
  height:100px;
  overflow:hidden;
  &img{

    
    height:auto;
  }

`
export const CarSearchContainerChildLatter = styled.div`


flex-basis:50%;

  min-height:100px;

`
export const CarSearchContainerChildLatterForButton = styled.div`

min-height:100px;
flex-basis:30%;
display:flex;
justify-content:flex-end;
align-items:center;
`
