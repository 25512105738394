import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'

import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { useDispatch, useSelector, connect } from 'react-redux'
import {
  getCurrentActivity,
  startActivityLoader,
  saveFilterName,
  saveActivityType,
  fetchLocations,
  fetchAgents,
  saveAgentFilteredArray,
  getSalesOfficer,
  clearAgentArray,
  saveAgentFilterDate,
  clearAgentFilterDate,
  getAgentTasks
} from '../../../store/leads/actions'
import {
  getActivityArray,
  activityLoader,
  activityPagination,
  activityTypeFromTheStore,
  activityFilterName,
  filteredAgentArray,
  filteredActivityDate
} from '../../../store/leads/selectors'
import {
  isAdmin,
  isManager,
  isAgent,
  getUserIdIfAgent,
  isFulfillmentAgent,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'

import { Button, TextField } from '@material-ui/core'

import CircularProgress from '@material-ui/core/CircularProgress'

import Pagination from '@material-ui/lab/Pagination'
import AssignedAgents from './FilterAgents'
import ViewLeadPopUp from './ViewLead'
import RescheduleBtn from './Reschedule'
import { getLocationOptions } from '../../../store/events/selectors'
import {
  StyledTabs,
  StyledTab,
  TabParent,
  DataContainer,
  DetailParent,
  LeadName,
  LeadNameDetail,
  DetailItemPersonIcon,
  DetailItemPhoneIcon,
  DataContainerChildInitialDiv,
  DataContainerChildWithAction,
  DataContainerActionFooter,
  LoaderCover,
  FloatingFooter,
  DetailParentForLead,
  PaginationCover,
  NoDataDiv,
  DateCover,
  HideNoteDiv,
  HideSaleFixNoteDiv,
  ShowSaleFixNoteDivForPhone
} from '../styles'

import { useHistory, withRouter } from 'react-router-dom'
interface IAuthor {
  firstname: string
  lastname: string
}
const getAuthor = (data: string): IAuthor => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return { firstname: '', lastname: '' }
  }
}

let linKtoConsole =
  process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
    ? 'https://console.staging.myautochek.com/inventory/'
    : 'https://console.autochek.africa/inventory/'

function TabPanel(props: any) {
  const { children, value, index, ...other } = props
  const { t } = useTranslation()

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps(index: number) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: '#fff'
  },
  tab: {
    '& .MuiBox-root': {
      padding: '0px !important'
    }
  },
  orange: {
    color: ' theme.palette.getContrastText(deepOrange[500])',
    backgroundColor: ' #FFCC5B'
  }
}))

const ScrollableTabsButtonAuto = (props: any) => {
  let tabIndex: any

  tabIndex = localStorage.getItem('tabIndex')
  tabIndex = JSON.parse(tabIndex)

  const classes = useStyles()
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)
  const activityArray: any = useSelector(getActivityArray)
  const activityDate: any = useSelector(filteredActivityDate)
  const loader: any = useSelector(activityLoader)
  const userIsAgent = useSelector(isAgent)
  const userIsAdmin = useSelector(isAdmin)
  const userIsFulfilment = useSelector(isFulfillmentAgent)
  const userIsManager = useSelector(isManager)
  const filteredAgents: any = useSelector(filteredAgentArray)
  const locations = useSelector(getLocationOptions)
  let history = useHistory()
  const adminUser: any = useSelector(isAdmin)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const currency = useSelector(getSelectedCountryCurrency)

  const ActionaBleDiv =
    userIsAgent || userIsFulfilment
      ? HideSaleFixNoteDiv
      : ShowSaleFixNoteDivForPhone

  React.useEffect(() => {
    dispatch(saveActivityType('PHONE_CALL'))
    dispatch(startActivityLoader())
    dispatch(getSalesOfficer())
    let query
    if (userIsAdmin || userIsManager) {
      query = {
        activity_type: 'PHONE_CALL',
        completed: false
      }
    } else {
      query = {
        activity_type: 'PHONE_CALL',
        completed: false,
        agentId: agentIdFromStore
      }
    }

    let dataForTasks = {
      agentId: agentIdFromStore,
      completed: false
    }
    dispatch(getCurrentActivity(query))
    dispatch(fetchAgents())
    dispatch(fetchLocations())
    dispatch(getAgentTasks(dataForTasks))

    if (tabIndex) {
      if (tabIndex == 0) {
        getPhoneApp()
      } else if (tabIndex == 1) {
        getBuyApp()
      } else if (tabIndex == 2) {
        getSellApp()
      } else if (tabIndex == 3) {
        getFixApp()
      }

      setValue(tabIndex)

      localStorage.removeItem('tabIndex')
    }
  }, [])

  const gotoLeadView = (leadId: string) => {
    history.push(`/leads/${leadId}`)
  }
  const gotoLoanDetail = (userId: string, leadId: string) => {
    history.push(`/loans/${userId}?lead_id=${leadId}`)
  }
  const handleChange = (event: object, newValue: any) => {
    setValue(newValue)
  }
  const getPhoneApp = () => {
    dispatch(saveActivityType('PHONE_CALL'))
    dispatch(startActivityLoader())

    let query
    if (adminUser || userIsManager) {
      query = {
        activity_type: 'PHONE_CALL',
        completed: false,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    } else {
      query = {
        activity_type: 'PHONE_CALL',
        completed: false,
        agentId: agentIdFromStore,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    }

    if (filteredAgents.length > 0) {
      query.agentId = filteredAgents.join(',').toString()
    }
    if (
      (userIsAdmin && filteredAgents.length < 1) ||
      (userIsManager && filteredAgents.length < 1)
    ) {
      delete query.agentId
    }
    if (!activityDate.startDate || !activityDate.endDate) {
      delete query.start_date
      delete query.end_date
    }

    dispatch(getCurrentActivity(query))
  }
  const getBuyApp = () => {
    dispatch(saveActivityType('BUY_CAR'))
    dispatch(startActivityLoader())

    let query
    if (adminUser || userIsManager) {
      query = {
        appointment_type: 'BUY_CAR,TEST_DRIVE_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK',
        completed: false,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    } else {
      query = {
        appointment_type: 'BUY_CAR,TEST_DRIVE_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK',
        completed: false,
        agentId: agentIdFromStore,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    }

    if (filteredAgents.length) {
      query.agentId = filteredAgents.join(',').toString()
    }
    if (!activityDate.startDate || !activityDate.endDate) {
      delete query.start_date
      delete query.end_date
    }

    dispatch(getCurrentActivity(query))
  }
  const getSellApp = () => {
    dispatch(startActivityLoader())
    dispatch(saveActivityType('SELL_CAR'))

    let query
    if (adminUser || userIsManager) {
      query = {
        appointment_type: 'SELL_CAR',
        completed: false,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    } else {
      query = {
        appointment_type: 'SELL_CAR',
        completed: false,
        agentId: agentIdFromStore,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    }

    if (filteredAgents.length) {
      query.agentId = filteredAgents.join(',').toString()
    }

    if (!activityDate.startDate || !activityDate.endDate) {
      delete query.start_date
      delete query.end_date
    }
    dispatch(getCurrentActivity(query))
  }
  const getFixApp = () => {
    dispatch(startActivityLoader())
    dispatch(saveActivityType('FIX_CAR'))
    let query
    if (adminUser || userIsManager) {
      query = {
        appointment_type: 'FIX_CAR',
        completed: false,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    } else {
      query = {
        appointment_type: 'FIX_CAR',
        completed: false,
        agentId: agentIdFromStore,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    }

    if (filteredAgents.length) {
      query.agentId = filteredAgents.join(',').toString()
    }

    if (!activityDate.startDate || !activityDate.endDate) {
      delete query.start_date
      delete query.end_date
    }
    dispatch(getCurrentActivity(query))
  }
  const getFinanceApp = () => {
    dispatch(startActivityLoader())
    dispatch(saveActivityType('LOAN_REMINDER'))
    let query
    if (adminUser || userIsManager) {
      query = {
        activity_type: 'LOAN_REMINDER',
        completed: false,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    } else {
      query = {
        activity_type: 'LOAN_REMINDER',
        completed: false,
        agentId: agentIdFromStore,
        start_date: activityDate.startDate,
        end_date: activityDate.endDate
      }
    }

    if (filteredAgents.length) {
      query.agentId = filteredAgents.join(',').toString()
    }

    if (!activityDate.startDate || !activityDate.endDate) {
      delete query.start_date
      delete query.end_date
    }
    dispatch(getCurrentActivity(query))
  }

  const { t } = useTranslation()

  return (
    <TabParent>
      <div className={classes.root}>
        <AppBar position="static" style={{ background: '#fff' }}>
          <StyledTabs
            value={value}
            onChange={handleChange}
            classes={{ root: classes.tab }}
            TabIndicatorProps={{
              style: { background: '#FFCC5B', height: '5px' }
            }}
          >
            <StyledTab
              label={t('phoneCalls')}
              {...a11yProps(0)}
              onClick={getPhoneApp}
            />

            <StyledTab
              label={t('testDrives')}
              {...a11yProps(1)}
              onClick={getBuyApp}
            />
            <StyledTab
              label={t('sellerInspection')}
              {...a11yProps(2)}
              onClick={getSellApp}
            />

            <StyledTab label={t('Fix')} {...a11yProps(3)} onClick={getFixApp} />
            <StyledTab
              label={t('finance')}
              {...a11yProps(4)}
              onClick={getFinanceApp}
            />
          </StyledTabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          {activityArray?.length < 1 && !loader ? (
            <NoDataDiv>
              <h4>{t('noPendingTasks')}</h4>
            </NoDataDiv>
          ) : null}

          {loader ? (
            <LoaderCover>
              <CircularProgress />
            </LoaderCover>
          ) : (
            <DataContainer>
              {activityArray?.map((activity: any) => (
                <DataContainerChildWithAction key={activity.id}>
                  <DataContainerChildInitialDiv>
                    <DetailParentForLead>
                      <DetailItemPersonIcon />
                      <LeadName
                        className="nameDiv"
                        style={{ cursor: 'pointer' }}
                        onClick={() => gotoLeadView(activity.leadId)}
                      >
                        {activity.lead?.firstName} {activity.lead?.lastName}
                      </LeadName>
                      <ViewLeadPopUp activity={activity} />
                    </DetailParentForLead>
                    <DetailParent>
                      <DetailItemPhoneIcon />
                      <span style={{ color: '#A1AACE' }}>
                        {activity.lead?.phone}
                      </span>
                    </DetailParent>

                    <DetailParent>
                      <LeadNameDetail>
                        <div>
                          <span style={{ color: '#EDB333' }}>
                            {t('openTask')} :
                            <span style={{ color: '#A1AACE' }}>
                              {`${t('scheduled')} ${activity.time} ${dayjs(
                                activity.date
                              ).format('DD/MM/YYYY')}`}
                            </span>
                          </span>
                        </div>
                      </LeadNameDetail>
                    </DetailParent>

                    <ActionaBleDiv>
                      {activity.note?.map((item: any) => (
                        <div key={item.id}>
                          <DetailParent>
                            <LeadNameDetail>
                              {`${getAuthor(item.authorId).firstname} ${
                                getAuthor(item.authorId).lastname
                              }`}
                              {` ${dayjs(item.updatedAt).format(
                                'h:mm A DD/MM/YYYY'
                              )} ${t('noted')}:`}
                            </LeadNameDetail>
                          </DetailParent>

                          <DetailParent
                            style={{
                              border: '1px solid #f2f2f2',
                              padding: '10px'
                            }}
                          >
                            <LeadNameDetail>{item.text}</LeadNameDetail>
                          </DetailParent>
                        </div>
                      ))}
                    </ActionaBleDiv>

                    {/* <DetailParentWithInitial>

        <DataContainerChildLatterDivAction>
        <Avatar className={classes.orange}>{activity.agent.name.match(/\b\w/g).join('').toUpperCase()}</Avatar>
</DataContainerChildLatterDivAction>
    </DetailParentWithInitial> */}
                  </DataContainerChildInitialDiv>

                  {userIsAgent ? (
                    <DataContainerActionFooter>
                      <RescheduleBtn activity={activity} />
                    </DataContainerActionFooter>
                  ) : null}
                </DataContainerChildWithAction>
              ))}
            </DataContainer>
          )}
        </TabPanel>

        <TabPanel value={value} index={1}>
          {activityArray?.length < 1 && !loader ? (
            <NoDataDiv>
              <h4>{t('noPendingTasks')}</h4>
            </NoDataDiv>
          ) : null}

          {loader ? (
            <LoaderCover>
              <CircularProgress />
            </LoaderCover>
          ) : (
            <DataContainer>
              {activityArray?.map((activity: any) => (
                <DataContainerChildWithAction key={activity.id}>
                  <DataContainerChildInitialDiv>
                    <DetailParentForLead>
                      <DetailItemPersonIcon />
                      <LeadName
                        className="nameDiv"
                        style={{ cursor: 'pointer' }}
                        onClick={() => gotoLeadView(activity.leadId)}
                      >
                        {activity.lead?.firstName} {activity.lead?.lastName}
                      </LeadName>
                      <ViewLeadPopUp activity={activity} />
                    </DetailParentForLead>
                    <DetailParent>
                      <DetailItemPhoneIcon />
                      <span style={{ color: '#A1AACE' }}>
                        {activity.lead?.phone}
                      </span>
                    </DetailParent>
                    <DetailParent>
                      <LeadNameDetail>
                        <div>
                          <span style={{ color: '#EDB333' }}>
                            {t('openTask')} :
                            <span style={{ color: '#A1AACE' }}>
                              {`${t('scheduled')} ${activity.time} ${dayjs(
                                activity.date
                              ).format('DD/MM/YYYY')}`}
                            </span>
                          </span>
                        </div>
                      </LeadNameDetail>
                    </DetailParent>

                    <DetailParent>
                      <LeadNameDetail>
                        <span style={{ color: '#4B9FF2' }}>
                          {t('franchiseName')} :
                          {activity.appointment &&
                          activity.appointment.carData.franchise ? (
                            <span style={{ color: '#A1AACE' }}>
                              {activity.appointment.carData.franchise.name}
                            </span>
                          ) : (
                            <span style={{ color: '#A1AACE' }}>N/A</span>
                          )}
                        </span>
                      </LeadNameDetail>
                    </DetailParent>
                    <DetailParent>
                      <LeadNameDetail>
                        <span style={{ color: '#4B9FF2' }}>
                          {' '}
                          {t('franchiseLocation')} :
                          {activity.appointment &&
                          activity.appointment.carData.franchise ? (
                            <span style={{ color: '#A1AACE' }}>
                              {activity.appointment.carData.franchise.address}
                            </span>
                          ) : (
                            <span style={{ color: '#A1AACE' }}>N/A</span>
                          )}
                        </span>
                      </LeadNameDetail>
                    </DetailParent>
                    <DetailParent>
                      <LeadNameDetail>
                        <span style={{ color: '#4B9FF2' }}>
                          {' '}
                          {t('salesOfficer')} :
                          {activity.appointment &&
                          activity.appointment.carData.salesOfficer ? (
                            <span style={{ color: '#A1AACE' }}>
                              {activity.appointment.carData.salesOfficer
                                .firstname +
                                ' ' +
                                activity.appointment.carData.salesOfficer
                                  .lastname}
                            </span>
                          ) : (
                            <span style={{ color: '#A1AACE' }}>N/A</span>
                          )}
                        </span>
                      </LeadNameDetail>
                    </DetailParent>

                    <DetailParent>
                      <div
                        style={{
                          margin: '10px 10px 10px 0px',
                          minWidth: '180px'
                        }}
                      >
                        {activity.appointment &&
                        activity.appointment.carData ? (
                          <div
                            style={{
                              backgroundImage: `url(${activity.appointment.carData.imageUrl})`,
                              flexBasis: '30%',
                              backgroundSize: 'cover',
                              borderRadius: '5px',
                              height: '115px',
                              marginRight: '10px'
                            }}
                          ></div>
                        ) : null}
                      </div>

                      {activity.appointment ? (
                        <div>
                          <LeadNameDetail>
                            {activity.appointment.carData.make + ' '}{' '}
                            {activity.appointment.carData.model + ' '}{' '}
                            {activity.appointment.carData.year}
                          </LeadNameDetail>

                          {activity.appointment.carData?.city ? (
                            <div>
                              <LeadNameDetail style={{ marginTop: '5px' }}>
                                <span style={{ color: '#4B9FF2' }}>
                                  {' '}
                                  {t('location')} :{' '}
                                </span>
                                {' ' + activity.appointment.carData?.city + ''}{' '}
                                , {activity.appointment.carData?.state + ' '}
                              </LeadNameDetail>
                              <LeadNameDetail>
                                <span style={{ color: '#4B9FF2' }}>
                                  {' '}
                                  {t('price')} :{' '}
                                </span>
                                <span> {currency}</span>

                                {'' +
                                  parseFloat(
                                    activity.appointment.carData?.price
                                  ).toLocaleString()}
                              </LeadNameDetail>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                    </DetailParent>
                    <DetailParent>
                      <LeadNameDetail>
                        {activity.appointment && activity.appointment.carId ? (
                          <a
                            href={`${
                              linKtoConsole + activity.appointment.carId
                            }`}
                            target="/blank"
                          >
                            <span style={{ marginBottom: '13px' }}>
                              {t('viewConsole')}
                            </span>
                          </a>
                        ) : null}
                      </LeadNameDetail>
                    </DetailParent>

                    <HideNoteDiv>
                      {activity.note?.map((item: any) => (
                        <div key={item.id}>
                          <DetailParent>
                            <LeadNameDetail>
                              {`${getAuthor(item.authorId).firstname} ${
                                getAuthor(item.authorId).lastname
                              }`}
                              {` ${dayjs(item.updatedAt).format(
                                'h:mm A DD/MM/YYYY'
                              )} ${t('noted')}:`}
                            </LeadNameDetail>
                          </DetailParent>

                          <DetailParent
                            style={{
                              border: '1px solid #f2f2f2',
                              padding: '10px'
                            }}
                          >
                            <LeadNameDetail>{item.text}</LeadNameDetail>
                          </DetailParent>
                        </div>
                      ))}
                    </HideNoteDiv>

                    {/*   
    <DetailParentWithInitial>

        <DataContainerChildLatterDivAction>
        <Avatar className={classes.orange}>{activity.agent.name.match(/\b\w/g).join('').toUpperCase()}</Avatar>
</DataContainerChildLatterDivAction>
    </DetailParentWithInitial> */}
                  </DataContainerChildInitialDiv>

                  {userIsAgent || userIsFulfilment ? (
                    <DataContainerActionFooter>
                      <RescheduleBtn activity={activity} />
                    </DataContainerActionFooter>
                  ) : null}
                </DataContainerChildWithAction>
              ))}
            </DataContainer>
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {activityArray?.length < 1 && !loader ? (
            <NoDataDiv>
              <h4>{t('noPendingTasks')}</h4>
            </NoDataDiv>
          ) : null}

          {loader ? (
            <LoaderCover>
              <CircularProgress />
            </LoaderCover>
          ) : (
            <DataContainer>
              {activityArray?.map((activity: any) => (
                <DataContainerChildWithAction key={activity.id}>
                  <DataContainerChildInitialDiv>
                    <DetailParentForLead>
                      <DetailItemPersonIcon />
                      <LeadName
                        className="nameDiv"
                        style={{ cursor: 'pointer' }}
                        onClick={() => gotoLeadView(activity.leadId)}
                      >
                        {activity.lead?.firstName} {activity.lead?.lastName}
                      </LeadName>
                      <ViewLeadPopUp activity={activity} />
                    </DetailParentForLead>
                    <DetailParent>
                      <DetailItemPhoneIcon />
                      <span style={{ color: '#A1AACE' }}>
                        {activity.lead?.phone}
                      </span>
                    </DetailParent>
                    <DetailParent>
                      <LeadNameDetail>
                        <div>
                          <span style={{ color: '#EDB333' }}>
                            {t('openTask')} :
                            <span style={{ color: '#A1AACE' }}>
                              {`${t('scheduled')} ${activity.time} ${dayjs(
                                activity.date
                              ).format('DD/MM/YYYY')}`}
                            </span>
                          </span>
                        </div>
                      </LeadNameDetail>
                    </DetailParent>

                    <ActionaBleDiv>
                      <DetailParent>
                        <LeadNameDetail>
                          {!!activity.locationId && (
                            <span style={{ color: '#4B9FF2' }}>
                              {t('location')}:
                              <span style={{ color: '#A1AACE' }}>
                                {
                                  locations.find(
                                    (location: any) =>
                                      location.value === activity.locationId
                                  )?.label
                                }
                              </span>
                            </span>
                          )}
                        </LeadNameDetail>
                      </DetailParent>

                      <DetailParent>
                        <LeadNameDetail>
                          <span style={{ color: '#4B9FF2' }}>
                            {' '}
                            {t('carDetails')}:
                          </span>
                        </LeadNameDetail>
                      </DetailParent>

                      <DetailParent>
                        {activity.appointment ? (
                          <span>
                            <span>
                              {activity.appointment.carData &&
                                activity.appointment.carData.year + ' '}
                            </span>
                            <span>
                              {activity.appointment.carData &&
                                activity.appointment.carData.make + ' '}
                            </span>
                            <span>
                              {activity.appointment.carData &&
                                activity.appointment.carData.model}
                            </span>
                          </span>
                        ) : null}
                      </DetailParent>

                      <DetailParent>
                        <LeadNameDetail>
                          {activity.appointment &&
                          activity.appointment.carData ? (
                            <span>
                              {t('mileage')} :{' '}
                              {activity.appointment.carData &&
                                activity.appointment.carData.mileage}{' '}
                              {t('Km')}
                            </span>
                          ) : null}
                        </LeadNameDetail>
                      </DetailParent>
                      <DetailParent>
                        <LeadNameDetail>
                          {activity.appointment &&
                          activity.appointment.carData ? (
                            <span>
                              {t('gearType')} :{' '}
                              {activity.appointment.carData &&
                                activity.appointment.carData.gearType}
                            </span>
                          ) : null}
                        </LeadNameDetail>
                      </DetailParent>
                      <DetailParent>
                        <LeadNameDetail>
                          {activity.appointment &&
                          activity.appointment.carData ? (
                            <span>
                              {t('fuelType')} :{' '}
                              {activity.appointment.carData &&
                                activity.appointment.carData.fuelType}
                            </span>
                          ) : null}
                        </LeadNameDetail>
                      </DetailParent>

                      {activity.note?.map((item: any) => (
                        <div key={item.id}>
                          <DetailParent>
                            <LeadNameDetail>
                              {`${getAuthor(item.authorId).firstname} ${
                                getAuthor(item.authorId).lastname
                              }`}
                              {` ${dayjs(item.updatedAt).format(
                                'h:mm A DD/MM/YYYY'
                              )} ${t('noted')}:`}
                            </LeadNameDetail>
                          </DetailParent>

                          <DetailParent
                            style={{
                              border: '1px solid #f2f2f2',
                              padding: '10px'
                            }}
                          >
                            <LeadNameDetail>{item.text}</LeadNameDetail>
                          </DetailParent>
                        </div>
                      ))}
                    </ActionaBleDiv>

                    {/* <DetailParentWithInitial>

        <DataContainerChildLatterDivAction>
        <Avatar className={classes.orange}>{activity.agent.name.match(/\b\w/g).join('').toUpperCase()}</Avatar>
</DataContainerChildLatterDivAction>
    </DetailParentWithInitial> */}
                  </DataContainerChildInitialDiv>
                  {userIsAgent || userIsFulfilment ? (
                    <DataContainerActionFooter>
                      <RescheduleBtn activity={activity} />
                    </DataContainerActionFooter>
                  ) : null}
                </DataContainerChildWithAction>
              ))}
            </DataContainer>
          )}
        </TabPanel>

        <TabPanel value={value} index={3}>
          {activityArray?.length < 1 && !loader ? (
            <NoDataDiv>
              <h4>{t('noPendingTasks')}</h4>
            </NoDataDiv>
          ) : null}

          {loader ? (
            <LoaderCover>
              <CircularProgress />
            </LoaderCover>
          ) : (
            <DataContainer>
              {activityArray?.map((activity: any) => (
                <DataContainerChildWithAction key={activity.id}>
                  <DataContainerChildInitialDiv>
                    <DetailParentForLead>
                      <DetailItemPersonIcon />
                      <LeadName
                        className="nameDiv"
                        style={{ cursor: 'pointer' }}
                        onClick={() => gotoLeadView(activity.leadId)}
                      >
                        {activity.lead?.firstName} {activity.lead?.lastName}
                      </LeadName>
                      <ViewLeadPopUp activity={activity} />
                    </DetailParentForLead>
                    <DetailParent>
                      <DetailItemPhoneIcon />
                      <span style={{ color: '#A1AACE' }}>
                        {activity.lead?.phone}
                      </span>
                    </DetailParent>
                    <DetailParent>
                      <LeadNameDetail>
                        <div>
                          <span style={{ color: '#EDB333' }}>
                            {t('openTask')} :
                            <span style={{ color: '#A1AACE' }}>
                              {`${t('scheduled')} ${activity.time} ${dayjs(
                                activity.date
                              ).format('DD/MM/YYYY')}`}
                            </span>
                          </span>
                        </div>
                      </LeadNameDetail>
                    </DetailParent>

                    <ActionaBleDiv>
                      {!activity.locationId ? (
                        <DetailParent>
                          <LeadNameDetail>
                            <span style={{ color: '#4B9FF2' }}>
                              {t('location')} :
                              <span style={{ color: '#A1AACE' }}>N/A</span>
                            </span>
                          </LeadNameDetail>
                        </DetailParent>
                      ) : (
                        <DetailParent>
                          <LeadNameDetail>
                            {!!activity.locationId && (
                              <span style={{ color: '#4B9FF2' }}>
                                {t('location')} :
                                <span style={{ color: '#A1AACE' }}>
                                  {
                                    locations.find(
                                      (location: any) =>
                                        location.value === activity.locationId
                                    )?.label
                                  }
                                </span>
                              </span>
                            )}
                          </LeadNameDetail>
                        </DetailParent>
                      )}

                      <DetailParent>
                        <LeadNameDetail>
                          <span style={{ color: '#4B9FF2' }}>
                            {' '}
                            {t('carDetails')}:
                          </span>
                        </LeadNameDetail>
                      </DetailParent>

                      <DetailParent>
                        {activity.appointment ? (
                          <span>
                            <span>
                              {activity.appointment.carData &&
                                activity.appointment.carData.year + ' '}
                            </span>
                            <span>
                              {activity.appointment.carData &&
                                activity.appointment.carData.make + ' '}
                            </span>
                            <span>
                              {activity.appointment.carData &&
                                activity.appointment.carData.model}
                            </span>
                          </span>
                        ) : null}
                      </DetailParent>

                      <DetailParent>
                        <LeadNameDetail>
                          {activity.appointment &&
                          activity.appointment.carData ? (
                            <span>
                              {t('mileage')} :{' '}
                              {activity.appointment.carData &&
                                activity.appointment.carData.mileage}{' '}
                              Km
                            </span>
                          ) : null}
                        </LeadNameDetail>
                      </DetailParent>
                      <DetailParent>
                        <LeadNameDetail>
                          {activity.appointment &&
                          activity.appointment.carData ? (
                            <span>
                              {t('gearType')} :{' '}
                              {activity.appointment.carData &&
                                activity.appointment.carData.gearType}
                            </span>
                          ) : null}
                        </LeadNameDetail>
                      </DetailParent>
                      <DetailParent>
                        <LeadNameDetail>
                          {activity.appointment &&
                          activity.appointment.carData ? (
                            <span>
                              {t('fuelType')} :{' '}
                              {activity.appointment.carData &&
                                activity.appointment.carData.fuelType}
                            </span>
                          ) : null}
                        </LeadNameDetail>
                      </DetailParent>

                      {activity.note?.map((item: any) => (
                        <div key={item.id}>
                          <DetailParent>
                            <LeadNameDetail>
                              {`${getAuthor(item.authorId).firstname} ${
                                getAuthor(item.authorId).lastname
                              }`}
                              {` ${dayjs(item.updatedAt).format(
                                'h:mm A DD/MM/YYYY'
                              )} ${t('noted')}:`}
                            </LeadNameDetail>
                          </DetailParent>

                          <DetailParent
                            style={{
                              border: '1px solid #f2f2f2',
                              padding: '10px'
                            }}
                          >
                            <LeadNameDetail>{item.text}</LeadNameDetail>
                          </DetailParent>
                        </div>
                      ))}
                    </ActionaBleDiv>
                  </DataContainerChildInitialDiv>

                  {userIsAgent ? (
                    <DataContainerActionFooter>
                      <RescheduleBtn activity={activity} />
                    </DataContainerActionFooter>
                  ) : null}
                </DataContainerChildWithAction>
              ))}
            </DataContainer>
          )}
        </TabPanel>

        {/* Finance Tab */}
        <TabPanel value={value} index={4}>
          {activityArray?.length < 1 && !loader ? (
            <NoDataDiv>
              <h4>{t('noPendingTasks')}</h4>
            </NoDataDiv>
          ) : null}

          {loader ? (
            <LoaderCover>
              <CircularProgress />
            </LoaderCover>
          ) : (
            <DataContainer>
              {activityArray?.map((activity: any) => (
                <DataContainerChildWithAction key={activity.id}>
                  <DataContainerChildInitialDiv>
                    <DetailParentForLead>
                      <DetailItemPersonIcon />
                      <LeadName
                        className="nameDiv"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                          gotoLoanDetail(activity.userId, activity.leadId)
                        }
                      >
                        {activity.lead?.firstName} {activity.lead?.lastName}
                      </LeadName>
                      <ViewLeadPopUp activity={activity} />
                    </DetailParentForLead>
                    <DetailParent>
                      <DetailItemPhoneIcon />
                      <span style={{ color: '#A1AACE' }}>
                        {activity.lead?.phone}
                      </span>
                    </DetailParent>

                    <DetailParent>
                      <LeadNameDetail>
                        <div>
                          <span style={{ color: '#EDB333' }}>
                            {t('openTask')} :
                            <span style={{ color: '#A1AACE' }}>
                              {`${t('scheduled')} ${activity.time} ${dayjs(
                                activity.date
                              ).format('DD/MM/YYYY')}`}
                            </span>
                          </span>
                        </div>
                      </LeadNameDetail>
                    </DetailParent>

                    <ActionaBleDiv>
                      {activity.note?.map((item: any) => (
                        <div key={item.id}>
                          <DetailParent>
                            <LeadNameDetail>
                              {`${getAuthor(item.authorId).firstname} ${
                                getAuthor(item.authorId).lastname
                              }`}
                              {` ${dayjs(item.updatedAt).format(
                                'h:mm A DD/MM/YYYY'
                              )} ${t('noted')}:`}
                            </LeadNameDetail>
                          </DetailParent>

                          <DetailParent
                            style={{
                              border: '1px solid #f2f2f2',
                              padding: '10px'
                            }}
                          >
                            <LeadNameDetail>{item.text}</LeadNameDetail>
                          </DetailParent>
                        </div>
                      ))}
                    </ActionaBleDiv>

                    {/* <DetailParentWithInitial>

        <DataContainerChildLatterDivAction>
        <Avatar className={classes.orange}>{activity.agent.name.match(/\b\w/g).join('').toUpperCase()}</Avatar>
</DataContainerChildLatterDivAction>
    </DetailParentWithInitial> */}
                  </DataContainerChildInitialDiv>

                  {userIsAgent ? (
                    <DataContainerActionFooter>
                      <RescheduleBtn activity={activity} />
                    </DataContainerActionFooter>
                  ) : null}
                </DataContainerChildWithAction>
              ))}
            </DataContainer>
          )}
        </TabPanel>
      </div>
    </TabParent>
  )
}

function TabView(props: any) {
  const dispatch = useDispatch()
  const filterName: any = useSelector(activityFilterName)
  const filteredAgents: any = useSelector(filteredAgentArray)
  const activityTypeFromStore: any = useSelector(activityTypeFromTheStore)
  const paginationOfActivity: any = useSelector(activityPagination)
  const userIsManager: any = useSelector(isManager)
  const adminUser: any = useSelector(isAdmin)
  const [pageNumber, setPage] = React.useState(1)
  const pageCount: any = Math.round(
    paginationOfActivity.total / paginationOfActivity.pageSize
  )
  const activityDate: any = useSelector(filteredActivityDate)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const [selectedStartDate, setSelectedStartDate] = React.useState('')
  const [selectedEndDate, setSelectedEndDate] = React.useState('')

  React.useEffect(() => {
    dispatch(
      saveAgentFilterDate({
        startDate: selectedStartDate,
        endDate: selectedEndDate
      })
    )

    if (selectedStartDate && selectedEndDate) {
      let queryTypeForPhone = {
        activityType: activityTypeFromStore
      }
      let queryTypeBuySaleFix = {
        appointmentType: activityTypeFromStore
      }
      let queryData =
        activityTypeFromStore == 'PHONE_CALL'
          ? queryTypeForPhone
          : queryTypeBuySaleFix

      let query = {
        ...queryData,
        current_page: pageNumber,
        page_size: 10,
        completed: false,
        start_date: selectedStartDate !== '' ? selectedStartDate : '',
        end_date: selectedEndDate !== '' ? selectedEndDate : '',
        agentId:
          filteredAgents.length < 1
            ? agentIdFromStore
            : filteredAgents.join(',').toString()
      }

      if (
        (adminUser && filteredAgents.length < 1) ||
        (userIsManager && filteredAgents.length < 1)
      ) {
        delete query.agentId
      }
      dispatch(startActivityLoader())
      dispatch(getCurrentActivity(query))
    }
  }, [selectedEndDate, selectedStartDate])

  const handleChange = (event: any, value: number) => {
    let queryTypeForPhone = {
      activityType: activityTypeFromStore
    }
    let queryTypeBuySaleFix = {
      appointmentType: activityTypeFromStore
    }
    let queryData =
      activityTypeFromStore == 'PHONE_CALL'
        ? queryTypeForPhone
        : queryTypeBuySaleFix

    setPage(value)

    let query = {
      ...queryData,
      current_page: value,
      page_size: 10,
      completed: false,
      start_date: activityDate.startDate,
      end_date: activityDate.endDate,
      agentId: filteredAgents.join(',').toString()
    }

    if (filteredAgents.length < 1) {
      delete query.agentId
    }
    if (!activityDate.startDate || !activityDate.endDate) {
      delete query.start_date
      delete query.end_date
    }

    dispatch(startActivityLoader())
    dispatch(getCurrentActivity(query))
  }
  const handleDateChange = (date: any) => {
    setSelectedStartDate(date.target.value)

    if (selectedEndDate !== '') {
      handleChange(date, 1)
    }
  }
  const clearDate = (e: any) => {
    handleChange(e, 1)
    dispatch(clearAgentFilterDate({}))
    setSelectedStartDate('')
    setSelectedEndDate('')

    let queryTypeForPhone = {
      activityType: activityTypeFromStore
    }
    let queryTypeBuySaleFix = {
      appointmentType: activityTypeFromStore
    }
    let queryData =
      activityTypeFromStore == 'PHONE_CALL'
        ? queryTypeForPhone
        : queryTypeBuySaleFix

    let query = {
      ...queryData,
      current_page: pageNumber,
      page_size: 10,
      completed: false,
      agentId: filteredAgents.join(',').toString()
    }

    if (filteredAgents.length < 1) {
      delete query.agentId
    }

    if (!adminUser && !userIsManager) {
      query.agentId = agentIdFromStore
    }

    dispatch(startActivityLoader())
    dispatch(getCurrentActivity(query))
  }

  const handleDateChangeTwo = (date: any) => {
    setSelectedEndDate(date.target.value)

    if (selectedStartDate !== '') {
      handleChange(date, 1)
    }
  }

  const { t } = useTranslation()

  return (
    <div>
      <DateCover>
        <form noValidate style={{ marginRight: '15px' }}>
          <TextField
            id="date"
            label={t('startDate')}
            type="date"
            onChange={handleDateChange}
            value={selectedStartDate}
            InputLabelProps={{
              shrink: true
            }}
          />
        </form>
        <form noValidate style={{ marginRight: '15px' }}>
          <TextField
            id="date"
            label={t('endDate')}
            type="date"
            value={selectedEndDate}
            onChange={handleDateChangeTwo}
            InputLabelProps={{
              shrink: true
            }}
          />
        </form>

        {selectedEndDate && selectedStartDate ? (
          <Button
            variant="contained"
            color="secondary"
            disableElevation
            style={{ minWidth: '98px', marginRight: '10px' }}
            onClick={(e) => clearDate(e)}
          >
            {t('clearDate')}
          </Button>
        ) : null}

        {userIsManager || adminUser ? (
          <AssignedAgents page={pageNumber} handleChange={handleChange} />
        ) : null}
      </DateCover>

      <ScrollableTabsButtonAuto />

      <FloatingFooter>
        <PaginationCover>
          <Pagination
            count={pageCount}
            page={pageNumber}
            onChange={handleChange}
          />
        </PaginationCover>
      </FloatingFooter>
    </div>
  )
}

export default withRouter(TabView)
