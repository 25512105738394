import React from 'react'
import TextInput from '../../../components/TextField'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { State, Props } from './types'
import {
  ResetPasswordText,
  ResetBoxContent,
  PasswordMatchNotice
} from './styles'
import {
  PageContainer,
  SignInBox,
  LogoTop,
  LogoTopContent,
  AutoCRMText,
  BootingCar,
  InputLane,
  StyledButton
} from '../styles'

type ResetProps = Props & RouteComponentProps

class ResetPassword extends React.Component<ResetProps, State> {
  state = {
    passwordAgain: '',
    password: ''
  }

  handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name

    this.setState({
      [key]: event.target.value
    })
  }

  checkPasswordMatch = () => {
    const { passwordAgain, password } = this.state

    if (passwordAgain === '' || password === '') return true
    if (passwordAgain === password) return true
    return false
  }

  isDetailsSet = () => {
    const { passwordAgain, password } = this.state

    if (passwordAgain && password) {
      return this.checkPasswordMatch()
    }

    return false
  }

  resetPassword = () => {
    const { password } = this.state
    const { resetPassword, history } = this.props

    resetPassword(password, history)
  }

  render() {
    const { password, passwordAgain } = this.state
    return (
      <PageContainer>
        <SignInBox>
          <LogoTop>
            <LogoTopContent>
              <BootingCar /> <AutoCRMText />
            </LogoTopContent>
          </LogoTop>
          <ResetBoxContent>
            <ResetPasswordText>Setup your account password</ResetPasswordText>
            <InputLane marginBottom="25px" bold>
              <TextInput
                name="password"
                label="Enter new password"
                type="password"
                value={password}
                onInputChange={this.handleInputChange}
              />
            </InputLane>
            <InputLane marginBottom="28px" bold>
              <TextInput
                name="passwordAgain"
                label="Re-enter password"
                type="password"
                value={passwordAgain}
                onInputChange={this.handleInputChange}
              />
              {!this.checkPasswordMatch() && (
                <PasswordMatchNotice>Passwords don't match</PasswordMatchNotice>
              )}
            </InputLane>
            <StyledButton
              disabled={!this.isDetailsSet()}
              deactivated={!this.isDetailsSet() ? 1 : 0}
              onClick={this.resetPassword}
            >
              Confirm
            </StyledButton>
          </ResetBoxContent>
        </SignInBox>
      </PageContainer>
    )
  }
}

export default withRouter(ResetPassword)
