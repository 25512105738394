import React from 'react'
import { Modal, Button, Form, Input, Select, Spin, message, Upload } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useParams } from 'react-router-dom'
import { getUserToken } from '../../../store/auth/selectors'
import api from '../../../utils/api'
import { StyledEquityModal } from '../../LeadManagement/Equity/styles'
import { useTranslation } from 'react-i18next'

interface IUpdateModal {
  isModalVisible: any
  setIsModalVisible: any
  refetch: any
  setIsUploadVisible: any
}

interface IDetailParam {
  id: string
}

const API_URL = process.env.REACT_APP_API_URL

export default function ReplaceDocument({
  isModalVisible,
  refetch,
  setIsModalVisible,
  setIsUploadVisible
}: IUpdateModal) {
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const props = {
    name: 'file',
    multiple: false,
    action: `${API_URL}/document/upload`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e: any) {
      // console.log('Dropped files', e.dataTransfer.files)
    }
  }

  const onProceed = () => {
    setIsUploadVisible(isModalVisible)
    setIsModalVisible(false)
  }

  const onCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        width="440px"
      >
        <StyledEquityModal>
          <Form
            name="basic"
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item
              label={t(
                `You Are About To Replace A File (${isModalVisible?.name})`
              )}
            >
              <div>
                <p className="ant-upload-text">
                  {t('Would You Like To Proceed?')}
                </p>
              </div>
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-center">
                <Button
                  className="buttonCancel me-3"
                  type="primary"
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button
                  className="button"
                  type="primary"
                  htmlType="submit"
                  onClick={onProceed}
                >
                  {t('Proceed')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </StyledEquityModal>
      </Modal>
    </div>
  )
}
