export enum ELocationTypes {
  GET_STATES = 'GET_STATES',
  GET_STATES_SUCCESS = 'GET_STATES_SUCCESS',
  GET_CITY = 'GET_CITY',
  GET_CITY_SUCCESS = 'GET_CITY_SUCCESS',
  GET_CITY_HOME_SUCCESS = 'GET_CITY_HOME_SUCCESS',
  GET_HOME_CITY = 'GET_HOME_CITY'
}

export const getStates = () => ({
  type: ELocationTypes.GET_STATES
})

export const getCity = (stateId: string | number) => ({
  type: ELocationTypes.GET_CITY,
  stateId
})
export const getHomeCity = (stateId: string | number) => ({
  type: ELocationTypes.GET_HOME_CITY,
  stateId
})
