import styled from 'styled-components'

export const StyledTopNav = styled.div`
  .navbarWrapper,
  #navbarWrapper {
    background: #fff;
    padding: 25px 30px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 10px 6px -6px #ededf6;

    .firstColumn {
      display: flex;
      align-items: center;
      .title {
        color: #0a1133;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        margin-right: 8px;
      }
      .id {
        font-weight: 500;
        font-size: 16px;
        color: #555555;
        margin-right: 8px;
        span {
          text-decoration: underline;
        }
      }
      a {
        color: #30355b;
        font-weight: 600;
        font-size: 12px;
        background: rgba(48, 53, 91, 0.15);
        border-radius: 30px;
        width: 165px;
        min-height: 25px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
    }

    .secondColumn {
      display: flex;
      align-items: center;
      .buttonGroup {
        margin-right: 15px;
        [ant-click-animating-without-extra-node='true']::after {
          display: none;
        }
        .notInterested {
          border: 1px solid #30355b;
          border-radius: 5px;
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          margin-right: 10px;
        }
        .carSold {
          color: #30355b;
          font-weight: 500;
          background: #ffb619;
          box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
          border-radius: 5px;
          font-size: 14px;
          border: 0;
        }
      }
      .actionButtonGroup {
        background: rgba(187, 189, 205, 0.22);
        border-radius: 8px;
        [ant-click-animating-without-extra-node='true']::after {
          display: none;
        }
        .inbox {
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          border: none;
          border: 0;
          border-right: 1px solid #dddddd;
          border-radius: 0px !important;
          background: transparent;
          overflow-wrap: break-word;
          white-space: normal;
          min-width: 99.39px;
          max-width: 120.39px;
          height: auto;
          .btnWrap {
            display: flex;
            align-items: center;
          }
          .btnWrap div {
            display: flex;
          }
          .btnImage {
            margin-right: 8px;
          }
        }
        .log {
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          border: none;
          border-right: 1px solid #dddddd;
          border-radius: 0px !important;
          background: transparent;
          overflow-wrap: break-word;
          white-space: normal;
          width: 140.27px;
          height: auto;
          .btnWrap {
            display: flex;
            align-items: center;
          }
          .btnWrap div {
            display: flex;
          }
          .btnImage {
            margin-right: 8px;
          }
        }
        .email {
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          border: none;
          border-right: 1px solid #dddddd;
          border-radius: 0px !important;
          background: transparent;
          overflow-wrap: break-word;
          white-space: normal;
          width: 136.34px;
          height: auto;
          .btnWrap {
            display: flex;
            align-items: center;
          }
          .btnWrap div {
            display: flex;
          }
          .btnImage {
            margin-right: 8px;
          }
        }
        .history {
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          border: 0;
          border-radius: 0px !important;
          background: transparent;
          width: 101.18px;
          height: auto;
          .btnWrap {
            display: flex;
            align-items: center;
          }
          .btnWrap div {
            display: flex;
          }
          .btnImage {
            margin-right: 8px;
          }
        }
      }
    }
  }
`
