import styled from 'styled-components'

export const StyledPayDealer = styled.div`
  .pay-dealer-wrapper {
    .content-wrap {
      background: #f8f8fb;
      border-radius: 10px;
      min-height: 400px;
      height: auto;
      padding: 25px;

      .header {
        color: #30355b;
        font-weight: bold;
        font-size: 14px;
      }
      .franchise {
        color: #000000;
        font-size: 14px;
        font-weight: 400;
      }
      form {
        label {
          font-size: 14px;
          font-weight: 500;
          color: #30355b;
        }
        input {
          background: #ffffff;
          border: 1px solid rgba(18, 52, 77, 0.38);
          box-sizing: border-box;
          border-radius: 4px;
          min-width: 249px;
          width: auto;
          height: 42px;
        }
        button {
          color: #30355b;
          font-weight: 600;
          font-size: 14px;
          height: 42px;
          background: #ffb619;
          box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
          border-radius: 5px;
          min-width: 179px;
          width: auto;
          border: 0;
          margin-top: 30px;
        }
      }
    }
  }
`
