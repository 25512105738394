/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, IconButton, Typography } from '@material-ui/core'
import React from 'react'
import clsx from 'clsx'
import caret from '../../../../images/caret-down.svg'
import { useTlStyles } from './Timeline/styles'
import { useTranslation } from 'react-i18next'

interface IProps {
  children: React.ReactChild
  title: string
}
const TitleWrapper: React.FC<IProps> = ({ children, title }) => {
  const classes = useTlStyles()
  const [isCollapsed, setCollapse] = React.useState(false)
  const handleCollapse = (event: React.MouseEvent) => {
    event.preventDefault()
    setCollapse(!isCollapsed)
  }

  const { t } = useTranslation()
  return (
    <Grid
      container
      item
      xs={12}
      className={clsx(classes.root, isCollapsed && classes.collapse)}
    >
      <Grid
        item
        xs={12}
        className={classes.titleWrapper}
        onClick={handleCollapse}
      >
        <IconButton
          disableRipple
          className={clsx(classes.dropdown, isCollapsed && classes.rotate)}
        >
          <img src={caret} alt="drop down" />
        </IconButton>
        <Typography variant="body1" className={classes.title}>
          {t(title)}
        </Typography>
      </Grid>
      {children}
    </Grid>
  )
}

export default TitleWrapper
