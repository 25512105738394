import styled from 'styled-components'

export const StyledNotificationModal = styled.div`
  .contentContainer {
    background: #ffffff;
    box-shadow: 0px 4px 34px rgba(49, 49, 49, 0.05);
    border-radius: 3px;
    padding: 15px;
    .contentWrapper {
      border-left: 2px solid #ffb800;
      padding-left: 10px;
      border-radius: 2px;
      .name {
        color: rgba(48, 53, 91, 0.89);
        font-weight: 400;
        font-size: 14px;
      }
      .time {
        color: rgba(48, 53, 91, 0.63);
        font-weight: 400;
        font-size: 11px;
      }
      .view {
        color: #30345e;
        font-weight: 600;
        font-size: 13px;
        cursor: pointer;
      }
    }
  }
`
