import React, { useCallback } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextInput from '../../components/TextField'
import CheckboxMaterialUi from '@material-ui/core/Checkbox'
import Select from '../../components/CustomizedSelect'
import {
  validateEmail,
  validateName,
  validateEmpty,
  validatePhone
} from './validate'
import NigeriaI from '../../images/nigeria.svg'
import GhanaI from '../../images/ghana.svg'
import KenyaI from '../../images/kenya.svg'
import Civ from '../../images/civ.svg'
import Uganda from '../../images/uganda.svg'
import api from '../../utils/api'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import ReactSelect from 'react-select'
import {
  ModalHeader,
  ModalTitle,
  CancelIcon
} from './LeadDetails/ServiceInterest/styles'
import {
  CreateLeadFormContainer,
  StyledModal,
  SubmitButton,
  SearchIcon2,
  LoaderCover,
  SubmitContainer,
  LeadFormGridContainer
} from './styles'
import { useSelector, useDispatch } from 'react-redux'
import CircularProgress from '@material-ui/core/CircularProgress'
import { getSelectedCountry, getUserToken } from '../../store/auth/selectors'
import {
  saveBankDealerSearch,
  saveBanksToStore
} from '../../store/leads/actions'
import { getCountryCode } from '../../utils/country.util'
import { useTranslation } from 'react-i18next'

const getCountryPlaceholder = (country: string) => {
  if (country === 'GH') {
    return '8031234567'
  } else if (country === 'KE') {
    return '702456789'
  } else {
    return '7061234567'
  }
}

const getCountryFlag = (selectedCountry: string) => {
  if (selectedCountry === 'GH') {
    return GhanaI
  } else if (selectedCountry === 'KE') {
    return KenyaI
  } else if (selectedCountry === 'CI') {
    return Civ
  } else if (selectedCountry === 'UG') {
    return Uganda
  } else {
    return NigeriaI
  }
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '4px',
    padding: 0,
    top: 5,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 0,
    overflowY: 'scroll',
    overflowX: 'hidden',
    [theme.breakpoints.down("sm")]: {
      width: 400
    },
  },
  countryCode: {
    width: '30%',
    height: '40px',
    padding: '10px',
    border: '1px solid #E6EAF0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginRight: '10px',
    '& > img': {
      height: '100%'
    }
  },
  phoneLabel: {
    margin: '8px 0 2px 0'
  },
  phoneInputWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}))

type CreateLeadProps = {
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  open: boolean
  handleClose: () => void
  handleCreate: () => void
  handleCheck: () => void
  checked: boolean
  values: {
    first_name: string
    last_name: string
    email: string
    phone: string
    service: string
    status: string
    value: string
    lead_type: string
    country: string
    source: string
    car_id?: string
    assign_lead_to_self?: boolean
  }
}

const CreateLead = ({
  handleChange,
  open,
  handleClose,
  handleCreate,
  values,
  handleCheck,
  checked
}: CreateLeadProps) => {
  const classes = useStyles()
  const country = useSelector(getSelectedCountry)

  const hideModal = () => {
    handleClose()
  }

  const token = useSelector(getUserToken)
  const inputProps = {
    width: '100%',
    height: '40px',
    color: '#000',

    borderRadius: 'none',
    transparent: true,
    borderColor: '#E6EAF0',
    labelMarginBottom: '2px',
    labelMarginTop: '8px'
  }
  const [banks, setBanks] = React.useState([])
  const [dealers, setDealers] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [dropDown, setDropDown] = React.useState(false)
  const dispatch = useDispatch()
  const hideDropDown = () => {
    setDropDown(false)
  }
  const showDropDown = (value: any) => {
    setSearch(value)

    setDropDown(true)
  }
  const handleBankChange = (e: any) => {
    setSearch(e.value)
    dispatch(saveBankDealerSearch(e.value))
  }

  const getBanks = useCallback(async () => {
    try {
      const response = await api.getBanks(token, country)
      //  console.log(response, 'bank details')

      let bankArray: any = []

      response?.banks.filter(function (data: any) {
        bankArray.push({
          label: data?.name,
          value: data?.name,
          icon: data?.logo
        })

        return data
      })
      setBanks(bankArray)
      dispatch(saveBanksToStore(bankArray))
    } catch (e) {}
  }, [token])

  React.useEffect(() => {
    const timeoutId = setTimeout(() => getCarDealers(search), 1000)
    return () => clearTimeout(timeoutId)
  }, [search])
  React.useEffect(() => {
    setSearch('')
  }, [values.source])

  const getCarDealers = useCallback(
    async (search) => {
      setLoader(true)
      try {
        const response = await api.fetchCarDealers(token, country, search)
        setDealers(response.result)
        setLoader(false)
      } catch (e) {
        setLoader(false)
      }
    },
    [token]
  )

  React.useEffect(() => {
    getBanks()
    getCarDealers(search)
  }, [country])

  const fNameError = validateName(values.first_name)
  const lNameError = validateName(values.last_name)
  const emailError = validateEmail(values.email)
  const phoneError = validatePhone(values.phone, country)
  const serviceError = validateEmpty(values.service)

  const isError = () => {
    if (fNameError || lNameError || emailError || phoneError) return true
    return false
  }

  // console.log('values', values)

  const { t } = useTranslation()

  const body = (
    <div className={classes.paper}>
      <ModalHeader>
        <ModalTitle>{t('createLead')}</ModalTitle>
        <CancelIcon onClick={hideModal} />
      </ModalHeader>
      <CreateLeadFormContainer noValidate>
        <LeadFormGridContainer>

        
        <TextInput
          name="first_name"
          value={values.first_name}
          labelColor={fNameError ? 'red' : '#8F99A6'}
          label={`${t('firstName')} ${fNameError ? fNameError : ''}`}
          onInputChange={handleChange}
          {...inputProps}
        />
        <TextInput
          name="last_name"
          value={values.last_name}
          labelColor={lNameError ? 'red' : '#8F99A6'}
          label={`${t('lastName')} ${lNameError ? lNameError : ''}`}
          onInputChange={handleChange}
          {...inputProps}
        />

        <TextInput
          name="email"
          value={values.email}
          labelColor={emailError ? 'red' : '#8F99A6'}
          label={`${t('email')} ${emailError ? emailError : ''}`}
          type="email"
          onInputChange={handleChange}
          {...inputProps}
        />

        <div>
          <p
            className={classes.phoneLabel}
            style={{ color: phoneError ? 'red' : '#8F99A6' }}
          >
            {t('phone')} {phoneError ? phoneError : ''}
          </p>
          <div className={classes.phoneInputWrapper}>
            <div className={classes.countryCode}>
              <img src={getCountryFlag(country)} alt="Selected country flag" />
              <span>{getCountryCode(country)}</span>
            </div>
            <TextInput
              name="phone"
              value={values.phone}
              placeholder={getCountryPlaceholder(country)}
              onInputChange={handleChange}
              {...inputProps}
            />
          </div>
        </div>

        <TextInput
          name="value"
          value={values.value || '0'}
          label={t('value')}
          min="0"
          type="number"
          labelColor="#8F99A6"
          onInputChange={handleChange}
          {...inputProps}
        />

        <Select
          value={values.source}
          name="source"
          label={t('source')}
          options={[
            { label: t('email'), value: 'Email' },
            { label: t('whatsapp'), value: 'Whatsapp' },
            { label: t('inboundCall'), value: 'Inbound Call' },
            { label: t('twitter'), value: 'Twitter' },
            { label: t('facebook'), value: 'Facebook' },
            { label: t('bank'), value: 'Bank' },
            { label: t('dealer'), value: 'Dealer' },
            { label: 'Crm', value: 'Crm' },
            { label: t('website'), value: 'Website' },
            { label: t('instagram'), value: 'Instagram' },
            { label: t('walkIn'), value: 'Walk In' },
            { label: t('DSA'), value: 'DSA' },
            { label: t('loanDesk'), value: 'Loan Desk' }
          ]}
          onSelect={handleChange}
        />

        {values.source === 'Dealer' ? (
          <div style={{ position: 'relative' }}>
            <input
              type="text"
              placeholder={t('searchDealers')}
              onChange={(event) => showDropDown(event.target.value)}
              value={search}
              style={{
                width: '100%',
                border: '1px solid #E6EAF0',
                marginTop: '1em',
                borderRadius: '5px',
                padding: '5px',
                paddingLeft: '10px',
                height: '3.1876em'
              }}
            />
            <SearchIcon2 />

            {dropDown ? (
              <ClickAwayListener onClickAway={hideDropDown}>
                <div
                  style={{
                    background: '#fff',
                    border: '1px solid #E6EAF0',
                    marginBottom: '20px',
                    overflow: 'hidden',
                    overflowY: 'scroll',
                    height: '150px'
                  }}
                >
                  {loader ? (
                    <LoaderCover>
                      <CircularProgress size={20} />
                    </LoaderCover>
                  ) : (
                    <div>
                      {dealers?.map((dealer: any) => {
                        return (
                          <p
                            style={{
                              cursor: 'pointer',
                              padding: '5px',
                              paddingLeft: '10px'
                            }}
                            onClick={() => {
                              setSearch(dealer.name)
                              dispatch(saveBankDealerSearch(dealer.name))
                              hideDropDown()
                            }}
                          >
                            {dealer?.name}
                          </p>
                        )
                      })}
                    </div>
                  )}

                  {dealers.length < 1 ? (
                    <p style={{ paddingLeft: '10px' }}>{t('noResultsFound')}</p>
                  ) : null}
                </div>
              </ClickAwayListener>
            ) : null}
          </div>
        ) : null}

        {values.source === 'Bank' ? (
          <div style={{ marginTop: '20px' }}>
            <ReactSelect
              placeholder={t('selectBank')}
              options={banks}
              onChange={handleBankChange}
            />
          </div>
        ) : null}

        <Select
          value={values.service}
          name="service"
          label={`${t('service')} ${serviceError ? serviceError : ''}`}
          labelColor={serviceError ? 'red' : '#8F99A6'}
          options={[
            { label: t('buyCar'), value: 'BUY_CAR' },
            { label: t('sellCar'), value: 'SELL_CAR' },
            { label: t('fixCar'), value: 'FIX_CAR' },
            { label: t('testDriveCar'), value: 'TEST_DRIVE_CAR' },
            { label: t('buyTruck'), value: 'BUY_TRUCK' },
            { label: t('sellTruck'), value: 'SELL_TRUCK' },
            { label: t('fixTruck'), value: 'FIX_TRUCK' },
            { label: t('testDriveTruck'), value: 'TEST_DRIVE_TRUCK' },
            { label: t('financing'), value: 'FINANCING' },
          ]}
          onSelect={handleChange}
        />
        {Object.keys(values).includes('car_id') && (
          <TextInput
            name="car_id"
            value={values.car_id}
            label={t('carId')}
            labelColor={'#8F99A6'}
            onInputChange={handleChange}
            {...inputProps}
          />
        )}
        <Select
          value={values.status}
          name="status"
          label={t('status')}
          options={[
            { label: t('veryHot'), value: 'VERY_HOT' },
            { label: t('hot'), value: 'HOT' },
            { label: t('scheduledInspection'), value: 'SCHEDULED_INSPECTION' },
            { label: t('cold'), value: 'COLD' },
            { label: t('enquiry'), value: 'ENQUIRY' },
            { label: t('financing'), value: 'FINANCING' },
            { label: t('firstContact'), value: 'FIRST_CONTACT' },
            { label: t('notInterested'), value: 'NOT_INTERERSTED' }
          ]}
          onSelect={handleChange}
        />
        <Select
          value={values.country}
          name="country"
          label={t('country')}
          options={[
            { label: 'Nigeria', value: 'NG' },
            { label: 'Ghana', value: 'GH' },
            { label: 'Kenya', value: 'KE' },
            { label: 'Uganda', value: 'UG' },
            { label: 'Ivory Coast', value: 'CI' }
          ]}
          onSelect={handleChange}
        />
</LeadFormGridContainer>
        <div>
          <CheckboxMaterialUi
            className="checkedBox"
            onChange={handleCheck}
            checked={checked}
            value={checked}
          />
          {t('assignToMe')}
        </div>

        <SubmitContainer>
          <SubmitButton disabled={isError()} onClick={handleCreate}>
            {t('submit')}
          </SubmitButton>
        </SubmitContainer>
      </CreateLeadFormContainer>
    </div>
  )

  return (
    <div>
      <StyledModal
        open={open}
        onClose={hideModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </StyledModal>
    </div>
  )
}

export default CreateLead
