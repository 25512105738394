import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Rating from '../../../../components/Rating'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { getShowVehicleModal } from '../../../../store/modal/selectors'
import { hideVehicleModal } from '../../../../store/modal/actions'
import { updateServiceInterest } from '../../../../store/leads/actions'
import {
  getVehicleDetails,
  getCurrentLeadId
} from '../../../../store/leads/selectors'
import {
  DetailItemTitle,
  DetailItemValue,
  DetailItemPhoneIcon,
  DetailItemMapIcon,
  DetailItem,
  DetailItemEmailIcon
} from '../styles'
import {
  ModalHeader,
  ModalTitle,
  CancelIcon,
  ModalBody,
  IconMenuVertical,
  Flex,
  ModalVehicleAssignedTo,
  ModalVehicleAssignedToText,
  VinIcon,
  ColorIcon,
  CarGearIcon,
  GasPumpIcon,
  FlagIcon,
  ModalVehicleNameAmount,
  PersonIconThree,
  FullVehicleDetailsItemTitle,
  FullVehicleDetailsItemValue,
  FullVehicleDetailsTitle,
  InspectionDocumentContainer,
  InspectionDocumentLink,
  NotesIcon,
  ImageSlide,
  StyledSlider,
  MarketPlaceLink,
  BadSign,
  Body,
  ImgWrap,
  StyledModal,
  CheckCircle,
  DetailsSection,
  DetailsSectionLeft,
  DetailsSectionRight,
  DetailsSectionLeftItem,
  DetailsSectionRightItem,
  CarDealerOwnerInfo,
  CarInfoLeft,
  CarInfoRight,
  CarInfoTitle,
  CarRetroIcon,
  AlternativeCarCard,
  AlternativeCarCardImg,
  AlternativeCarCardMetaLane,
  AlternativeCarCardMetaYear,
  AlternativeCarCardMetaTitle,
  AlternativeCarCardInfoSection,
  AlternativeCarCardAmount,
  AlternativeCarCardItemTitle,
  AlternativeCarCardButton,
  DetailItemGaugeIcon,
  DetailItemLocationIcon,
  DetailItemUsedIcon,
  AlternativeCarSectionFlex,
  AlternativeCardImgWrap,
  ModalFooter,
  ModalCloseButton,
  PreviousIcon,
  WarrantyIcon,
  NextIcon,
  ActiveBar
} from './styles'
import { getSelectedCountryCurrency } from '../../../../store/auth/selectors'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 1100,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '4px',
    padding: 0,
    top: 10,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 0,
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
}))

const NextArrow = (props: any) => {
  const { onClick } = props

  return <NextIcon onClick={onClick} />
}

const PrevArrow = (props: any) => {
  const { onClick } = props

  return <PreviousIcon onClick={onClick} />
}

const getSlider = (carMedia: any) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  }

  return (
    <StyledSlider {...settings}>
      {carMedia
        ? carMedia.map((media: any) => {
            return media.type === 'video' ? (
              <ImageSlide>
                <video height="220" controls>
                  <source src={media.url} type="video/mp4" />
                </video>
              </ImageSlide>
            ) : (
              <ImageSlide>
                <ImgWrap>
                  <img alt="" src={media.url} />
                </ImgWrap>
              </ImageSlide>
            )
          })
        : null}
    </StyledSlider>
  )
}

type RatingsType = {
  name: string
  value: number
  items: {
    name: string
    response: string
  }[]
}

type Details = {
  carId: string
  country: string
}

const VehicleDetails = ({ carId, country }: Details) => {
  const classes = useStyles()
  const open = useSelector(getShowVehicleModal)
  const details = useSelector((state) => getVehicleDetails(state, carId))
  const leadId = useSelector(getCurrentLeadId)
  const dispatch = useDispatch()
  const currency = useSelector(getSelectedCountryCurrency)

  const hideModal = () => {
    dispatch(hideVehicleModal())
  }

  const {
    name,
    carMedia,
    price,
    utility,
    vin,
    fuelType,
    gearType,
    mileage,
    interiorColor,
    exteriorColor,
    similarCars,
    websiteUrl,
    engineNumber,
    licensePlate,
    hasWarranty,
    inspectionRatings,
    ownerDetails,
    dealerDetails,
    inspectorDetails,
    hasFinancing,
    isInsured,
    interestId
  } = details

  const updateLeadInterest = (newCarId: string) => {
    const details = {
      carId: newCarId
    }
    hideModal()
    dispatch(updateServiceInterest(details, interestId, leadId))
  }

  const [activeRating, setActiveRating] = React.useState('')

  const getInspectionRatings = (
    inspectionRatings: RatingsType[],
    activeRating: string
  ) => {
    return inspectionRatings
      ? inspectionRatings.map((item: RatingsType, index) => {
          const active = activeRating ? activeRating === item.name : index === 0
          return (
            <DetailsSectionLeftItem
              active={active}
              onClick={() => setActiveRating(item.name)}
            >
              <Flex paddingTop="15px">
                {item.name}
                <Flex>
                  <Rating value={item.value} />
                </Flex>
              </Flex>
              {active && <ActiveBar />}
            </DetailsSectionLeftItem>
          )
        })
      : null
  }
  const getInspectionItems = (
    inspectionRatings: RatingsType[],
    activeRating: string
  ) => {
    return inspectionRatings
      ? inspectionRatings.map((item: RatingsType, index) => {
          const active = activeRating
            ? activeRating === item.name
              ? item.name
              : 'false'
            : inspectionRatings[0].name
          return active === item.name
            ? item.items.map((iItem) => {
                return (
                  <DetailsSectionRightItem>
                    <Flex paddingTop="12px">
                      {iItem.name}
                      {iItem.response === 'good' ? (
                        <CheckCircle />
                      ) : (
                        <BadSign />
                      )}
                    </Flex>
                  </DetailsSectionRightItem>
                )
              })
            : null
        })
      : null
  }

  const { t } = useTranslation()

  const body = (
    <div className={classes.paper}>
      <ModalHeader>
        <ModalTitle>{t('VEHICLE DETAILS')}</ModalTitle>
        <CancelIcon onClick={hideModal} />
      </ModalHeader>
      <ModalBody>
        <Flex>
          <ModalVehicleNameAmount>{name}</ModalVehicleNameAmount>
          <IconMenuVertical />
        </Flex>
        <ModalVehicleNameAmount>
          {currency} {price && price.toLocaleString()}
        </ModalVehicleNameAmount>
        <Flex>
          <ModalVehicleAssignedTo>
            <PersonIconThree />
            <ModalVehicleAssignedToText>
              {t('assignedTo')}:{' '}
              {inspectorDetails &&
                `${inspectorDetails.firstname} ${inspectorDetails.lastname} - ${inspectorDetails.primaryPhone}`}
            </ModalVehicleAssignedToText>
          </ModalVehicleAssignedTo>
        </Flex>
        <FullVehicleDetailsTitle>{t('vehicleDetails')}</FullVehicleDetailsTitle>
        <Flex>
          <Flex noJustify>
            <VinIcon />
            <FullVehicleDetailsItemTitle>
              {t('VIN')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>{vin}</FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify>
            <FlagIcon />
            <FullVehicleDetailsItemTitle>
              {t('Utility')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>{utility}</FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify>
            <GasPumpIcon />
            <FullVehicleDetailsItemTitle>
              {t('fuelType')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {fuelType}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify>
            <CarGearIcon />
            <FullVehicleDetailsItemTitle>
              {t('gearType')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {gearType}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify>
            <DetailItemGaugeIcon />
            <FullVehicleDetailsItemTitle>
              {t('mileage')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>{mileage}</FullVehicleDetailsItemValue>
          </Flex>
        </Flex>
        <Flex marginTop="10px">
          <Flex noJustify>
            <ColorIcon />
            <FullVehicleDetailsItemTitle>
              {t('interiorColor')}:
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {interiorColor}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify>
            <ColorIcon />
            <FullVehicleDetailsItemTitle>
              {t('exteriorColor')}:
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {exteriorColor}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify>
            <FlagIcon />
            <FullVehicleDetailsItemTitle>
              {t('License no')}:
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {licensePlate}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify>
            <FlagIcon />
            <FullVehicleDetailsItemTitle>
              {t('Engine no')}:
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {engineNumber}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify marginRight="25px">
            <FlagIcon />
            <FullVehicleDetailsItemTitle>
              {t('Insured')}:
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {isInsured ? t('Yes') : t('No')}
            </FullVehicleDetailsItemValue>
          </Flex>
        </Flex>
        <Flex>
          <Flex noJustify marginTop="10px">
            <Flex noJustify>
              <FlagIcon />
              <FullVehicleDetailsItemTitle>
                {t('hasFinancing')}:
              </FullVehicleDetailsItemTitle>
              <FullVehicleDetailsItemValue>
                {hasFinancing ? t('Yes') : t('No')}
              </FullVehicleDetailsItemValue>
            </Flex>
          </Flex>
          <Flex marginTop="10px">{hasWarranty && <WarrantyIcon />}</Flex>
        </Flex>
        <Flex marginTop="15px" noJustify>
          <FullVehicleDetailsItemTitle>
            {t('marketplaceURL')}:
          </FullVehicleDetailsItemTitle>
          <MarketPlaceLink
            href={websiteUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            {websiteUrl}
          </MarketPlaceLink>
        </Flex>
        {getSlider(carMedia)}
        <DetailsSection>
          <DetailsSectionLeft>
            {getInspectionRatings(inspectionRatings, activeRating)}
          </DetailsSectionLeft>
          <DetailsSectionRight>
            {getInspectionItems(inspectionRatings, activeRating)}
          </DetailsSectionRight>
        </DetailsSection>
        <InspectionDocumentContainer>
          <Flex noJustify>
            <NotesIcon />
            <InspectionDocumentLink>
              {t('viewComprehensiveDocument')}
            </InspectionDocumentLink>
          </Flex>
        </InspectionDocumentContainer>
      </ModalBody>
      <CarDealerOwnerInfo>
        <CarInfoLeft>
          <CarInfoTitle>{t('dealerInfo')}</CarInfoTitle>
          {dealerDetails && (
            <>
              <DetailItem isOwnerInfo>
                <CarRetroIcon />
                <DetailItemTitle>{t('dealershipName')}</DetailItemTitle>
                <DetailItemValue>{dealerDetails.name} </DetailItemValue>
              </DetailItem>
              <DetailItem isOwnerInfo>
                <DetailItemPhoneIcon />
                <DetailItemTitle>{t('phone')}</DetailItemTitle>
                <DetailItemValue>{dealerDetails.phonenumber}</DetailItemValue>
              </DetailItem>
              <DetailItem isOwnerInfo>
                <DetailItemMapIcon />
                <DetailItemTitle>{t('location')}</DetailItemTitle>
                <DetailItemValue>
                  {dealerDetails.displayAddress}
                </DetailItemValue>
              </DetailItem>
            </>
          )}
        </CarInfoLeft>
        <CarInfoRight>
          <CarInfoTitle>{t('ownerInfo')}</CarInfoTitle>
          {ownerDetails && (
            <>
              <DetailItem isOwnerInfo>
                <DetailItemMapIcon />
                <DetailItemTitle>{t('ownerName')}: </DetailItemTitle>
                <DetailItemValue>
                  {ownerDetails.firstname} {ownerDetails.lastname}
                </DetailItemValue>
              </DetailItem>
              <DetailItem isOwnerInfo>
                <DetailItemPhoneIcon />
                <DetailItemTitle>{t('phone')}:</DetailItemTitle>
                <DetailItemValue>{ownerDetails.primaryPhone}</DetailItemValue>
              </DetailItem>
              <DetailItem isOwnerInfo>
                <DetailItemEmailIcon />
                <DetailItemTitle>{t('email')}:</DetailItemTitle>
                <DetailItemValue>{ownerDetails.email}</DetailItemValue>
              </DetailItem>
            </>
          )}
        </CarInfoRight>
      </CarDealerOwnerInfo>
      <Body>
        <CarInfoTitle>{t('recommendedAlternatives')}</CarInfoTitle>
        <AlternativeCarSectionFlex noJustify>
          {similarCars
            ? similarCars.map((car: any) => {
                return (
                  <AlternativeCarCard>
                    <AlternativeCardImgWrap>
                      <AlternativeCarCardImg image={car.image} />
                      <AlternativeCarCardMetaLane>
                        <AlternativeCarCardMetaYear>
                          {car.year}
                        </AlternativeCarCardMetaYear>
                        <AlternativeCarCardMetaTitle>
                          {car.name}
                        </AlternativeCarCardMetaTitle>
                      </AlternativeCarCardMetaLane>
                    </AlternativeCardImgWrap>
                    <AlternativeCarCardInfoSection>
                      <AlternativeCarCardAmount>
                        {currency} {car.price && car.price.toLocaleString()}
                      </AlternativeCarCardAmount>
                      <Flex noJustify marginBottom="8px">
                        <DetailItemGaugeIcon />
                        <AlternativeCarCardItemTitle>
                          {car.mileage} km
                        </AlternativeCarCardItemTitle>
                      </Flex>
                      <Flex noJustify marginBottom="8px">
                        <DetailItemLocationIcon />
                        <AlternativeCarCardItemTitle>
                          {car.location}
                        </AlternativeCarCardItemTitle>
                      </Flex>
                      <Flex noJustify marginBottom="17px">
                        <DetailItemUsedIcon />
                        <AlternativeCarCardItemTitle>
                          {car.utility}
                        </AlternativeCarCardItemTitle>
                      </Flex>
                    </AlternativeCarCardInfoSection>
                    <AlternativeCarCardButton
                      onClick={() => updateLeadInterest(car.id)}
                    >
                      {t('substituteWithCar')}
                    </AlternativeCarCardButton>
                  </AlternativeCarCard>
                )
              })
            : null}
        </AlternativeCarSectionFlex>
      </Body>

      <ModalFooter>
        <ModalCloseButton onClick={hideModal}>{t('Close')}</ModalCloseButton>
      </ModalFooter>
    </div>
  )

  return (
    <div>
      <StyledModal
        open={open}
        onClose={hideModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </StyledModal>
    </div>
  )
}

export default VehicleDetails
