import React from 'react'
import OffersUpload from './OffersUpload'
import { StyledNoUpload } from './styles'
import { TaskIcon } from './svgs'

interface IUpload {
  title: string
}

export default function Offers({ title }: IUpload) {
  return (
    <StyledNoUpload>
      <div className="uploadWrapper mt-3">
        <div className="title">{title}</div>

        <OffersUpload />
      </div>
    </StyledNoUpload>
  )
}
