import React from 'react'
import { Modal, Button, Form, Input, Select, Spin, message, Upload } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useParams } from 'react-router-dom'
import { getUserToken } from '../../../store/auth/selectors'
import api from '../../../utils/api'
import { StyledEquityModal } from '../../LeadManagement/Equity/styles'
import { useTranslation } from 'react-i18next'

interface IUpdateModal {
  isModalVisible: any
  setIsModalVisible: any
  singleRequest: any
  refetch: any
  source:any
}

interface IDetailParam {
  id: string
}

const API_URL = process.env.REACT_APP_API_URL

export default function UpdateRequest({
  isModalVisible,
  setIsModalVisible,
  singleRequest,
  refetch,
  source
}: IUpdateModal) {
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  let extraProps = {}
  if(source==='reviewedDocuments'){
    extraProps= {maxCount : 1}
  }
  const props = {
    name: 'file',
    multiple: false,
    action: `${API_URL}/document/upload`,
    headers: {
      authorization: `Bearer ${token}`
    },
    onChange(info: any) {
      const { status } = info.file
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    onDrop(e: any) {
      // console.log('Dropped files', e.dataTransfer.files)
    },
    ...extraProps
  }

  const normFile = (e: any) => {
    console.log('Upload event:', e)
    if (Array.isArray(e)) {
      return e
    }
    return e && e.fileList
  }

  const onFinish = async (values: any) => {
    let newArray = { ...singleRequest }
    newArray.status = 'IN-REVIEW'
    newArray.description = ''

    const data = {
      ...newArray,
      file_url: values.upload[0].response.file.url
    }

    try {
      const response = await api.updateDocument(token, data, data.id)
      if (response) {
        dispatch(showSnackbar('Document updated successfully', 'success'))
        setIsModalVisible(false)
        refetch()
      }
    } catch (e) {
      dispatch(showSnackbar('Error updating document', 'error'))
    }
  }

  const onCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <Modal
        visible={isModalVisible}
        footer={null}
        closable={false}
        width="440px"
      >
        <StyledEquityModal>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
            requiredMark={false}
          >
            <Form.Item label={t('uploadAdditionalDocument')}>
              <Form.Item
                name="upload"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger {...props} name="file">
                  <p className="ant-upload-text">{t('upload')}</p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-center">
                <Button
                  className="buttonCancel me-3"
                  type="primary"
                  onClick={onCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button className="button" type="primary" htmlType="submit">
                  {t('submit')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </StyledEquityModal>
      </Modal>
    </div>
  )
}
