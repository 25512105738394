export enum EConversationTypes {
  ADD_NEW_MESSAGE_ALERT = 'ADD_NEW_MESSAGE_ALERT',
  UPDATE_WEBSOCKET_CONNECTION = 'UPDATE_WEBSOCKET_CONNECTION',
  FETCH_MESSAGE_STATS = 'FETCH_MESSAGE_STATS',
  FETCH_MESSAGE_STATS_SUCCESS = 'FETCH_MESSAGE_STATS_SUCCESS',
  FETCH_CONVERSATIONS = 'FETCH_CONVERSATIONS',
  FETCH_CONVERSATIONS_SUCCESS = 'FETCH_CONVERSATIONS_SUCCESS',
  FETCH_CHAT_MESSAGES = 'FETCH_CHAT_MESSAGES',
  FETCH_CHAT_MESSAGES_SUCCESS = 'FETCH_CHAT_MESSAGES_SUCCESS',
  READ_CONVERSATION = 'READ_CONVERSATION',
  READ_CONVERSATION_SUCCESS = 'READ_CONVERSATION_SUCCESS',
  SEARCH_CONVERSATIONS = 'SEARCH_CONVERSATIONS',
  SEARCH_CONVERSATIONS_SUCCESS = 'SEARCH_CONVERSATIONS_SUCCESS',
  CLEAR_SEARCH_RESULTS = 'CLEAR_SEARCH_RESULTS'
}

export type TCategory = 'queue' | 'active' | 'closed' | 'all'

export interface INewMessageAlert {
  agentId: string
  phone: string
  message?: {
    text: string
    type: 'TEXT' | 'IMAGE' | 'AUDIO' | 'VIDEO' | 'DOCUMENT'
  }
  status: 'new message'
}

export const addNewMessageAlert = (newAlert: INewMessageAlert) => ({
  type: EConversationTypes.ADD_NEW_MESSAGE_ALERT,
  payload: newAlert
})

export const updateWebSocketConnection = (status: boolean) => ({
  type: EConversationTypes.UPDATE_WEBSOCKET_CONNECTION,
  status
})

export const fetchMessageStats = () => ({
  type: EConversationTypes.FETCH_MESSAGE_STATS
})

export const fetchConversations = (
  category: TCategory,
  replace: boolean = true
) => ({
  type: EConversationTypes.FETCH_CONVERSATIONS,
  category,
  replace
})

export const fetchChatMessages = (recipient: string, replace?: boolean) => ({
  type: EConversationTypes.FETCH_CHAT_MESSAGES,
  recipient,
  replace
})

export const readConversation = (recipient: string, status: string) => ({
  type: EConversationTypes.READ_CONVERSATION,
  recipient,
  status
})

export const searchConversations = (
  phoneNumber: string,
  replace?: boolean
) => ({
  type: EConversationTypes.SEARCH_CONVERSATIONS,
  phoneNumber,
  replace
})

export const clearSearchResults = () => ({
  type: EConversationTypes.CLEAR_SEARCH_RESULTS
})
