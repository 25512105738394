import React, { useState } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import { useDispatch, useSelector } from 'react-redux'
import { StyledLeadMgt } from './styles'
import {
  BellIcon,
  ChevronIcon,
  CompletedTIcon,
  DisbursedIcon,
  IncompleteTIcon,
  ArrowIcon
} from '../../PortfolioManagement/Dashboard/svgs'
import { Button, Select, Badge, Avatar } from 'antd'
import { Table } from 'react-bootstrap'
import Piechart from './Piechart'
import { Link, useHistory } from 'react-router-dom'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  getUserToken,
  isManager,
  isAdmin,
  isLead,
  isPortfolio,
  isAgent,
  getUserFirstName,
  getUserLastName,
  getUserId
} from '../../../store/auth/selectors'
import { useQuery, useQueryClient } from 'react-query'
import api from '../../../utils/api'
import Loader from '../../../components/Loader'
import { CAR_STATUS } from '../AvailableCar/constants'
import { LOANACTIVITIESENUM } from '../../PortfolioManagement/Dashboard/constant'
import NotificationModal from '../../../components/NotificationModal'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useTranslation } from 'react-i18next'
import { SwitchSelect } from '../../PortfolioManagement/Dashboard/styles'
import mixpanel from 'mixpanel-browser'

export default function LeadManagement() {
  const token = useSelector(getUserToken)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const firstName = useSelector(getUserFirstName)
  const lastName = useSelector(getUserLastName)
  const userIsAgent = useSelector(isAgent)
  const userIsPortfolio = useSelector(isPortfolio)
  const userIsManager = useSelector(isManager)
  const userIsAdmin = useSelector(isAdmin)
  const userIsLead = useSelector(isLead)
  const userId = useSelector(getUserId)
  let history = useHistory()
  const { Option } = Select
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)
  const { t } = useTranslation()

  const { data: fetchDashboard, status } = useQuery(
    ['fetchDashboard', token, country],
    () => api.fetchDasboardActivities(token, country)
  )

  const {
    data: fetchNotifications,
    status: notificationStatus,
    refetch
  } = useQuery(
    ['notification'],
    () =>
      api.fetchCrmOriginationNotif(token, {
        agent_id: userId, //'byU7SkIg0'userId
        read: false,
        type: 'loan'
      }),
    {
      // onSuccess: () => {
      //   const allUnread = fetchNotifications?.data?.filter(function (
      //     item: any
      //   ) {
      //     return item?.read == false
      //   })
      //   if (allUnread) {
      //     dispatch(showSnackbar('You have a new notification', 'success'))
      //   }
      // }
    }
  )

  const showNotificationModal = () => {
    mixpanel.track('Notifications Viewed')
    setIsNotificationVisible(true)
    refetch()
  }

  function percentageIncompleted(
    incompleted: any,
    completed: any,
    disbursed: any
  ) {
    let total
    if (incompleted) {
      total = (incompleted / (incompleted + completed + disbursed)) * 100
      return Number(total.toFixed(1))
    }
  }

  function percentageCompleted(
    incompleted: any,
    completed: any,
    disbursed: any
  ) {
    let total
    if (completed) {
      total = (completed / (incompleted + completed + disbursed)) * 100
      return Number(total.toFixed(1))
    }
  }

  function percentageDisbursed(
    incompleted: any,
    completed: any,
    disbursed: any
  ) {
    let total
    if (disbursed) {
      total = (disbursed / (incompleted + completed + disbursed)) * 100
      return Number(total.toFixed(1))
    }
  }

  const totalIncompleted = percentageIncompleted(
    fetchDashboard?.dashboard?.incompleteApplications,
    fetchDashboard?.dashboard?.completedApplications,
    fetchDashboard?.dashboard?.disbursedStat?.count
  )

  const totalCompleted = percentageCompleted(
    fetchDashboard?.dashboard?.incompleteApplications,
    fetchDashboard?.dashboard?.completedApplications,
    fetchDashboard?.dashboard?.disbursedStat?.count
  )

  const totalDisbursed = percentageDisbursed(
    fetchDashboard?.dashboard?.incompleteApplications,
    fetchDashboard?.dashboard?.completedApplications,
    fetchDashboard?.dashboard?.disbursedStat?.count
  )

  const totalPercentage =
    (Number(totalIncompleted) || 0) +
    (Number(totalCompleted) || 0) +
    (Number(totalDisbursed) || 0)

  const handleSelectDashboard = (value: string) => {
    if (value == 'LM') {
      history.push(`/lead-dashboard`)
    }
    if (value == 'PM') {
      history.push(`/portfolio-mgt`)
    }
  }

  function getInitials(name: any) {
    const hasTokens = name.indexOf(' ') !== -1
    return (
      name.substring(0, hasTokens ? 1 : 2) +
      (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
    )
  }

  return (
    <WithSideMenu>
      <StyledLeadMgt>
        {status == 'loading' ? (
          <Loader />
        ) : (
          <>
            <div className="headerWrapper">
              <div className="userWrapper">
                {userIsPortfolio && country == 'NG' && (
                  <SwitchSelect className="me-5">
                    <Select
                      defaultValue="LM"
                      suffixIcon={ChevronIcon}
                      size="large"
                      onChange={handleSelectDashboard}
                    >
                      <Option value="LM">{t('leadsDashboard')}</Option>
                      <Option value="PM">{t('portfolioDashboard')}</Option>
                    </Select>
                  </SwitchSelect>
                )}

                <div className="users">
                  <div style={{ textAlign: 'right' }}>
                    <div className="name">
                      {firstName} {lastName}
                    </div>
                    <div className="role">
                      {userIsPortfolio && t('portfolioManager')}
                      {userIsLead && t('leadManager')}
                      {(userIsAdmin || userIsManager) && t('CRMManager')}
                    </div>
                  </div>
                  <div className="initials">
                    {getInitials(`${firstName} ${lastName}`)}
                  </div>
                </div>
                <div className="bell" onClick={() => showNotificationModal()}>
                  <Badge count={fetchNotifications?.data?.length || 0} showZero>
                    <div>{BellIcon}</div>
                  </Badge>
                </div>
              </div>
            </div>
            <NotificationModal
              isNotificationVisible={isNotificationVisible}
              setIsNotificationVisible={setIsNotificationVisible}
              fetchNotifications={fetchNotifications}
              refetch={refetch}
            />

            <div className="subHeadersWrapper">
              <div className="title">
                {t('LEADS MANAGER')} <br /> {t('DASHBOARD')}
              </div>
              <div>
                <Select
                  defaultValue="today"
                  style={{ minWidth: 120 }}
                  suffixIcon={ChevronIcon}
                  size="large"
                  // onChange={handleChange}
                >
                  <Option value="today">{t('today')}</Option>
                  <Option value="week">{t('thisWeek')}</Option>
                  <Option value="month">{t('thisMonth')}</Option>
                </Select>
              </div>
            </div>

            <div
              className={
                userIsLead || userIsPortfolio || userIsAdmin || userIsManager
                  ? 'dashboardCards'
                  : 'dashboardAgentCards'
              }
            >
              <div
                className="cardWrapper"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/leads')}
              >
                <div>{IncompleteTIcon}</div>
                <div style={{ marginLeft: '15px' }}>
                  <div className="title">{t('leads')}</div>
                  <div className="valueWrap">
                    {/* <div className="figures">89/289</div> */}
                  </div>
                </div>
              </div>
              <div
                className="cardWrapper"
                style={{ cursor: 'pointer' }}
                onClick={() => history.push('/loans')}
              >
                <div>{IncompleteTIcon}</div>
                <div style={{ marginLeft: '15px' }}>
                  <div className="title">{t('incompletedApplications')}</div>
                  <div className="valueWrap">
                    <div className="figures">
                      {fetchDashboard?.dashboard?.incompleteApplications}/
                      {fetchDashboard?.dashboard?.total}
                    </div>
                  </div>
                </div>
              </div>
              {userIsLead || userIsPortfolio || userIsAdmin || userIsManager ? (
                <div
                  className="cardWrapper"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/completed-loans')}
                >
                  <div>{CompletedTIcon}</div>
                  <div style={{ marginLeft: '15px' }}>
                    <div className="title">{t('completedApplications')}</div>
                    <div className="valueWrap">
                      <div className="figures">
                        {fetchDashboard?.dashboard?.completedApplications}/
                        {fetchDashboard?.dashboard?.total}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {/* {userIsAgent ? (
                ''
              ) : (
                <div
                  className="cardWrapper"
                  style={{ cursor: 'pointer' }}
                  onClick={() => history.push('/completed-loans')}
                >
                  <div>{CompletedTIcon}</div>
                  <div style={{ marginLeft: '15px' }}>
                    <div className="title">{t('completedApplications')}</div>
                    <div className="valueWrap">
                      <div className="figures">
                        {fetchDashboard?.dashboard?.completedApplications}/
                        {fetchDashboard?.dashboard?.total}
                      </div>
                    </div>
                  </div>
                </div>
              )} */}

              <div className="cardWrapper">
                <div>{DisbursedIcon}</div>
                <div style={{ marginLeft: '15px' }}>
                  <div className="title">{t('disbursedLoans')}</div>
                  <div className="valueWrap">
                    <div className="figures">
                      {fetchDashboard?.dashboard?.disbursedStat?.count}/
                      {fetchDashboard?.dashboard?.disbursedStat?.total}
                    </div>
                    <div className="value">
                      {t('value')}: <br /> {currency}
                      {Number(
                        fetchDashboard?.dashboard?.disbursedStat?.amount
                      ).toLocaleString() || 0}
                      M
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="tableWrapper">
              <div className="wrap">
                <div className="tableCover">
                  <Table hover size="lg" className="bg-white">
                    <thead>
                      <tr>
                        <th>{t('loanActivities')}</th>
                        <th>{t('today')}</th>
                        <th>{t('thisWeek')}</th>
                        <th>{t('thisMonth')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(fetchDashboard?.activities.today)
                        .filter((key) => key.toLowerCase() !== 'total')
                        .map((keyName, i) => {
                          return (
                            <>
                              <tr
                                onClick={() =>
                                  // @ts-ignore
                                  history.push(
                                    //@ts-ignore
                                    `/completed-loans?status=${LOANACTIVITIESENUM[keyName]}`
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                              >
                                <td style={{ textTransform: 'capitalize' }}>
                                  {/* {keyName.replace(/([a-z])([A-Z])/g, '$1 $2')} */}
                                  {t(`${keyName}`)}
                                </td>

                                <td>
                                  {fetchDashboard?.activities?.today[keyName]}/
                                  {fetchDashboard?.activities?.today?.total}
                                </td>
                                <td>
                                  {
                                    fetchDashboard?.activities?.thisWeek[
                                      keyName
                                    ]
                                  }
                                  /{fetchDashboard?.activities?.thisWeek?.total}
                                </td>
                                <td>
                                  {
                                    fetchDashboard?.activities?.thisMonth[
                                      keyName
                                    ]
                                  }
                                  /
                                  {fetchDashboard?.activities?.thisMonth?.total}
                                </td>
                              </tr>
                            </>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
                <div className="mt-5 d-flex justify-content-center">
                  {userIsAdmin || userIsManager || userIsLead ? (
                    <Button onClick={() => history.push('/completed-loans')}>
                      {t('showCustomerDetails')}
                    </Button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="chartsCard">
                <div className="title">{t('MoMPerformance')}</div>
                <Piechart fetchDashboard={fetchDashboard} />
                <div className="statsWrapper">
                  <div className="stats mb-2">
                    <div className="nameType">
                      <div className="blue"></div>
                      <div className="complete">
                        {t('incompletedApplications')}
                      </div>
                    </div>
                    <div className="value">
                      {fetchDashboard?.dashboard?.incompleteApplications}{' '}
                      <span>
                        (
                        {percentageIncompleted(
                          fetchDashboard?.dashboard?.incompleteApplications,
                          fetchDashboard?.dashboard?.completedApplications,
                          fetchDashboard?.dashboard?.disbursedStat?.count
                        ) || 0}
                        %)
                      </span>
                    </div>
                  </div>
                  <div className="stats mb-2">
                    <div className="nameType">
                      <div className="purple"></div>
                      <div className="complete">
                        {t('completedApplications')}
                      </div>
                    </div>
                    <div className="value">
                      {fetchDashboard?.dashboard?.completedApplications}{' '}
                      <span>
                        (
                        {percentageCompleted(
                          fetchDashboard?.dashboard?.incompleteApplications,
                          fetchDashboard?.dashboard?.completedApplications,
                          fetchDashboard?.dashboard?.disbursedStat?.count
                        ) || 0}
                        %)
                      </span>
                    </div>
                  </div>
                  <div className="stats">
                    <div className="nameType">
                      <div className="yellow"></div>
                      <div className="complete">{t('disbursedLoans')}</div>
                    </div>
                    <div className="value">
                      {fetchDashboard?.dashboard?.disbursedStat?.count}{' '}
                      <span>
                        (
                        {percentageDisbursed(
                          fetchDashboard?.dashboard?.incompleteApplications,
                          fetchDashboard?.dashboard?.completedApplications,
                          fetchDashboard?.dashboard?.disbursedStat?.count
                        ) || 0}
                        %)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="percentage">
                  <div className="number">
                    {totalPercentage.toFixed(0) || 0}%
                  </div>
                  <div style={{ display: 'flex' }}>{ArrowIcon}</div>
                </div>
              </div>
            </div>
          </>
        )}
      </StyledLeadMgt>
    </WithSideMenu>
  )
}
