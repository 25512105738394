import React from 'react'
import { Button, Form, Input, Row, Col, DatePicker, TimePicker } from 'antd'
import { StyledNewCall } from './styles'
import moment from 'moment'
import { getUserToken } from '../../../store/auth/selectors'
import api from '../../../utils/api'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}
interface INewCall {
  setIsModalVisible: any
}

export default function NewCallForm({ setIsModalVisible }: INewCall) {
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const { t } = useTranslation()
  const [form] = Form.useForm()

  const onFinish = async (fieldsValue: any) => {
    const values = {
      ...fieldsValue,
      date: fieldsValue['date'].format('YYYY-MM-DD'),
      time: fieldsValue['time'].format('HH:mm:ss')
    }

    let dateObj = `${values.date} ${values.time}`

    const data = {
      callDate: moment(dateObj).toISOString(),
      type: 'PHONE_CALL',
      comment: values.note,
      loan_id: id
    }

    try {
      const newCall = await api.createActivity(token, data)
      if (newCall) {
        dispatch(showSnackbar('Activity logged successfully', 'success'))
      }
    } catch (err) {
      dispatch(showSnackbar('Activity failed to be logged', 'error'))
    }

    setTimeout(() => {
      setIsModalVisible(false)
    }, 500)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <StyledNewCall>
      <Form
        form={form}
        layout="vertical"
        name="horizontal_login"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="date"
              label={t('Date')}
              rules={[
                {
                  type: 'object' as const,
                  required: true,
                  message: t('pleaseEnterDate')
                }
              ]}
            >
              <DatePicker placeholder={t('selectDate')} />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="time"
              label={t('Time')}
              rules={[{ required: true, message: t('pleaseEnterTime') }]}
            >
              <TimePicker use12Hours placeholder={t('selectTime')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="note"
          label={t('Note')}
          rules={[{ required: true, message: t('pleaseInputNote') }]}
        >
          <Input.TextArea
            placeholder={t('typeSomething')}
            rows={4}
            maxLength={100}
          />
        </Form.Item>

        <Form.Item>
          <div className="d-flex mt-2">
            <Button
              className="cancel me-3"
              type="primary"
              onClick={handleCancel}
            >
              {t('Cancel')}
            </Button>
            <Button className="submit" type="primary" htmlType="submit">
              {t('save')}
            </Button>
          </div>
        </Form.Item>
      </Form>
    </StyledNewCall>
  )
}
