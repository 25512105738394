import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import debounce from 'lodash/debounce'
import { deriveInitials, getDateTime } from '../Conversations.utils'
import { IConversationsMatchParams } from '../Conversations'
import {
  getRecipientMessages,
  getAssignedAgentData,
  getRecipientLeadId,
  getRecipientName,
  getConvoReadStatus,
  getNewMessageAlert,
  getConvoOpenStatus
} from '../../../store/conversations/selectors'
import { IRootState } from '../../../store/reducer.interface'
import {
  fetchChatMessages,
  readConversation,
  fetchMessageStats,
  fetchConversations
} from '../../../store/conversations/actions'
import {
  getSelectedCountry,
  getUserIdIfAgent,
  getUserToken
} from '../../../store/auth/selectors'
import CreateLead from '../../Leads/CreateLead'
import api from '../../../utils/api'
import { showSnackbar } from '../../../store/snackbar/actions'
import InputBoxContainer from './InputBoxContainer'
import AgentDetails from './AgentDetails'
import TagI from '../../../images/tags-1.svg'
import CollapseI from '../../../images/collapse.svg'
import CheckMarkI from '../../../images/check-mark-3.svg'
import {
  ChatViewWrapper,
  ChatViewBox,
  ChatHeader,
  ReciepientInfo,
  RecipientInitials,
  RecipientName,
  ChatToolbar,
  ToolbarOptions,
  ChatContainer,
  ChatMessages,
  ChatMessagesEnd,
  SearchIcon,
  ToolbarIcon,
  ObserverTarget,
  ToolbarPopoverContent
} from './styles'
import dayjs from 'dayjs'
import Loader from '../../../components/Loader'
import SingleChat, { ClosedConvoAlert } from './SingleChat'
import IntegrationsContainer from './IntegrationsContainer'
import { Popover, Typography } from '@material-ui/core'
import { getCountryCode } from '../../../utils/country.util'
import EmptyConversation from '../EmptyConversation'

export interface IChatMessage {
  messageId: string
  read: boolean
  recievedAt: string
  contact: string
  text: string
  direction: 'INBOUND' | 'OUTBOUND'
}

const initialLead = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  service: '',
  status: '',
  value: '',
  lead_type: '',
  country: '',
  source: '',
  car_id: '',
  assign_lead_to_self: undefined
}

export default function ChatView() {
  const dispatch = useDispatch()
  const chatMessagesEndRef = useRef<HTMLDivElement | null>(null)
  const { status, recipientPhone } = useParams<IConversationsMatchParams>()
  const messages = useSelector((state: IRootState) =>
    getRecipientMessages(state, recipientPhone)
  )
  const readStatus = useSelector((state: IRootState) =>
    getConvoReadStatus(state, recipientPhone)
  )
  const token = useSelector(getUserToken)
  const assignedAgent = useSelector((state: IRootState) =>
    getAssignedAgentData(state, recipientPhone)
  )
  const recipientName = useSelector((state: IRootState) =>
    getRecipientName(state, recipientPhone)
  )
  const recipientLeadId = useSelector((state: IRootState) =>
    getRecipientLeadId(state, recipientPhone)
  )
  const convoOpenStatus = useSelector((state: IRootState) =>
    getConvoOpenStatus(state, recipientPhone)
  )
  const country = useSelector(getSelectedCountry)
  const [checked, setChecked] = React.useState(false)
  const newMessageAlert = useSelector(getNewMessageAlert)
  const agentUserId = useSelector(getUserIdIfAgent)
  const [openModal, setOpenModal] = useState(false)
  const [newLead, setNewLead] = useState(initialLead)
  const [loadingMore, setLoadingMore] = useState(false)
  const observerRoot = useRef<HTMLDivElement>(null)
  const observerTarget = useRef<HTMLDivElement>(null)
  useEffect(() => {
    dispatch(fetchChatMessages(recipientPhone, true))
    setNewLead({
      ...initialLead,
      phone: recipientPhone,
      source: 'Whatsapp',
      status: 'FIRST_CONTACT'
    })
  }, [dispatch, recipientPhone])

  useEffect(() => {
    if (recipientPhone === newMessageAlert.phone) {
      dispatch(fetchChatMessages(recipientPhone, true))
    }
  }, [
    dispatch,
    recipientPhone,
    newMessageAlert.message?.text,
    newMessageAlert.phone
  ])

  useEffect(() => {
    if (messages?.length > 0 && !readStatus && agentUserId) {
      dispatch(readConversation(recipientPhone, status))
    }
  }, [dispatch, messages, readStatus, agentUserId, recipientPhone, status])

  const prevScrollHeight = useRef(0)

  useEffect(() => {
    if (observerRoot.current) {
      const currentScrollHeight = observerRoot.current.scrollHeight
      const position = currentScrollHeight - prevScrollHeight.current
      setLoadingMore(false)
      // observerRoot.current.scrollTo({
      //   top: position
      // })
      prevScrollHeight.current = currentScrollHeight
    }
  }, [messages])

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name
    let value: string | number = event.target.value
    if (key === 'value') {
      value = parseInt(value)
    }

    setNewLead((lead) => ({ ...lead, [key]: value }))
  }

  const handleCheck = () => {
    if (checked == false) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  const handleCreateLead = async () => {
    setOpenModal(false)
    dispatch(showSnackbar('Creating lead...'))

    const phoneNumberWithCode = getCountryCode(country) + newLead.phone
    const leadInfo = {
      ...newLead,
      phone: phoneNumberWithCode,
      assign_lead_to_self: checked
    }

    try {
      let createdLeadId

      if (newLead.car_id) {
        const car = await api.fetchCarData(token, newLead.car_id)
        const appointmentDetails = {
          car_data: {
            body_type: car.bodyType.name,
            fuel_type: car.fuelType,
            gear_type: car.model.wheelType,
            imported: false,
            make: car.model.make.name,
            mileage: car.mileage,
            model: car.model.name,
            year: car.year
          },
          car_id: car.id,
          country: car.country,
          details: {
            car: `${car.model.make.name} ${car.model.name} ${car.year}`,
            car_id: car.id,
            market_place_url: car.websiteUrl
          },
          email: newLead.email,
          firm_name: 'Autochek',
          id: '',
          name: `${newLead.first_name} ${newLead.last_name}`,
          phone: phoneNumberWithCode,
          pick_up_date: dayjs(),
          pick_up_location: {
            city: car.city,
            state: car.state,
            street: ''
          },
          pick_up_time: dayjs(),
          pick_up_time_end: dayjs(),
          pick_up_time_start: dayjs(),
          service: 'BUY_CAR',
          service_detail: 'buy-car',
          service_type_slug: 'buy-car',
          source: 'website',
          value: car.marketplacePrice
        }

        const appointment = await api.createAppointment(
          token,
          appointmentDetails
        )
        createdLeadId = appointment.leadId
      } else {
        const createdLead = await api.createLead(token, leadInfo)
        createdLeadId = createdLead.id
      }

      const updatePayload = {
        engaged: true,
        leadId: createdLeadId
      }

      const updatedConvo = await api.updateConversation(
        token,
        recipientPhone,
        updatePayload
      )

      if (updatedConvo) {
        dispatch(fetchChatMessages(recipientPhone, true))
        dispatch(showSnackbar('Successfully created lead', 'success'))
      }
    } catch (err) {
      dispatch(showSnackbar('Error occured creating lead', 'error'))
    }
  }

  const initiateLeadCreation = () => {
    setOpenModal(true)
  }

  useEffect(() => {
    let isInitialMount = true
    let observer: IntersectionObserver
    const target = observerTarget.current
    const debouncedMessagesFetch = debounce(() => {
      dispatch(fetchChatMessages(recipientPhone, false))
    }, 200)

    if (target && observerRoot.current) {
      const observerOptions: IntersectionObserverInit = {
        root: observerRoot.current,
        threshold: 1.0
      }

      observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (isInitialMount) {
            isInitialMount = false
          } else {
            if (entry.intersectionRatio === 1.0) {
              setLoadingMore(true)
              debouncedMessagesFetch()
            }
          }
        })
      }, observerOptions)

      observer.observe(target)
    }

    return () => {
      if (target && observer) {
        observer.unobserve(target)
      }
    }
  }, [dispatch, recipientPhone])

  return (
    <>
      <ChatViewWrapper>
        <ChatViewBox>
          <ChatHeader>
            <ReciepientInfo>
              <RecipientInitials>
                {deriveInitials(recipientName)}
              </RecipientInitials>
              <RecipientName>
                {recipientName} - {recipientPhone}
              </RecipientName>
              <SearchIcon />
            </ReciepientInfo>

            <ChatToolbar>
              <AgentDetails
                assignedAgent={assignedAgent}
                recipient={recipientPhone}
              />

              <ToolbarOptionsContainer convoStatus={convoOpenStatus} />
            </ChatToolbar>
          </ChatHeader>
          <ChatContainer>
            <ChatMessages ref={observerRoot}>
              <ObserverTarget ref={observerTarget} />
              {loadingMore && <Loader />}
              {messages
                ?.map(
                  (
                    {
                      messageId,
                      contact,
                      text,
                      caption,
                      type,
                      url,
                      direction,
                      createdAt
                    },
                    i
                  ) => {
                    if (type === 'CLOSED') {
                      return <ClosedConvoAlert text={text} />
                    }
                    return (
                      <SingleChat
                        key={i + messageId}
                        initials={deriveInitials(contact)}
                        type={type}
                        url={url}
                        text={text || caption}
                        direction={direction}
                        time={getDateTime(createdAt)}
                      />
                    )
                  }
                )
                .reverse()}
              <ChatMessagesEnd ref={chatMessagesEndRef} />
            </ChatMessages>

            {agentUserId &&
              agentUserId === assignedAgent?.id &&
              convoOpenStatus !== 'closed' && (
                <InputBoxContainer
                  recipient={recipientName}
                  phoneNumber={recipientPhone}
                />
              )}
          </ChatContainer>
        </ChatViewBox>

        <IntegrationsContainer
          initiateLeadCreation={initiateLeadCreation}
          leadId={recipientLeadId?.trim()}
        />

        <CreateLead
          open={openModal}
          values={newLead}
          handleChange={handleInputChange}
          handleClose={() => setOpenModal(false)}
          handleCreate={handleCreateLead}
          handleCheck={handleCheck}
          checked={checked}
        />
      </ChatViewWrapper>
    </>
  )
}

interface IToolBarOptionsProps {
  convoStatus: string
}

const ToolbarOptionsContainer = ({ convoStatus }: IToolBarOptionsProps) => {
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const { status, recipientPhone } = useParams<IConversationsMatchParams>()
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null)

  const handleButtonClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (convoStatus === 'closed') {
      return
    }
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const closeConversation = async () => {
    try {
      const response = await api.closeConversation(token, recipientPhone)
      if (response) {
        setAnchorEl(null)
        dispatch(fetchChatMessages(recipientPhone, true))
        dispatch(fetchMessageStats())
        dispatch(fetchConversations(status, true))
      }
    } catch (e) {
      dispatch(showSnackbar('Unable to close this conversation', 'error'))
    }
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <ToolbarOptions>
      <ToolbarIcon icon={TagI} />
      <ToolbarIcon icon={CollapseI} />
      <ToolbarIcon icon={CheckMarkI} onClick={handleButtonClick} />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <ToolbarPopoverContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to close this conversation?
          </Typography>
          <div>
            <button onClick={closeConversation}>Proceed</button>
            <button onClick={handleClose}>Cancel</button>
          </div>
        </ToolbarPopoverContent>
      </Popover>
    </ToolbarOptions>
  )
}
