import React, { useEffect } from 'react'
import { InfoIcon, GreenTick, PendingTick } from '../AvailableCar/svg'
import CarAvailable from '../AvailableCar'
import SimilarCar from '../SimilarCar'
import { StyledLoanDetails } from './styles'
import ResponsesTable from '../Responses'
import { useQuery, useQueryClient } from 'react-query'
import { getSelectedCountry, getUserToken } from '../../../store/auth/selectors'
import { useSelector } from 'react-redux'
import api from '../../../utils/api'
import AppointmentTable from '../Appointment'
import { useParams } from 'react-router-dom'
import { CAR_STATUS } from '../AvailableCar/constants'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function CarSourcing() {
  const country = useSelector(getSelectedCountry)
  const token = useSelector(getUserToken)
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(10)
  const { id } = useParams<IDetailParam>()
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: fetchOffer } = useQuery(
    'fetchOffer',
    () => api.fetchOffers(token, id),
    {
      onSuccess: () => {}
    }
  )

  const {
    data: singleLoan,
    isLoading: singleLoanLoading,
    refetch: loanRefresh
  } = useQuery(
    ['singleLoans', id],
    () => api.fetchSingleOriginationLoan(token, id),
    {
      keepPreviousData: true,
      staleTime: 5000
    }
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
    }
  }, [singleLoan, queryClient, id])

  const acceptedOffer = singleLoan?.offer == undefined ? 0 : 1

  const {
    data: carRequests,
    isLoading: carRequestsLoading,
    refetch: refresh
  } = useQuery(
    ['car-sourcing', country, page, pageSize, id],
    () =>
      api.getCarOffers(token, {
        country: country,
        page_number: page,
        page_size: pageSize,
        // request_id: `IKYV4Jcxn`
        request_id: singleLoan?.sourceId
      }),
    {
      enabled: singleLoan?.sourceId ? true : false
    }
  )

  const { data: fetchLoanProgess } = useQuery(
    ['fetchLoanProgress', id],
    () => api.fetchLoanProgress(token, id),
    {
      onSuccess: () => {}
    }
  )

  const allCustomerInspections = fetchLoanProgess?.loanProgess?.filter(
    function (item: any) {
      return (
        item?.status == 'CUSTOMER_INSPECTION' || item?.status == 'CAR_ACCEPTED'
      )
    }
  )
  const totalCustomerInspection = allCustomerInspections
    ?.map((item: any) => item.timeElapsed)
    ?.reduce((prev: any, curr: any) => prev + curr, 0)

  const allEquity = fetchLoanProgess?.loanProgess?.filter(function (item: any) {
    return item?.status == 'EQUITY_REQUESTED' || item?.status == 'EQUITY_PAID'
  })
  const totalEquity = allEquity
    ?.map((item: any) => item.timeElapsed)
    ?.reduce((prev: any, curr: any) => prev + curr, 0)

  const allCarAvailable = fetchLoanProgess?.loanProgess?.filter(function (
    item: any
  ) {
    return item?.status == 'CAR_SOURCING' || item?.status == 'PPI_IN_PROGRESS'
  })
  const totalCarAvailable = allCarAvailable
    ?.map((item: any) => item.timeElapsed)
    ?.reduce((prev: any, curr: any) => prev + curr, 0)

  const allDisbursed = fetchLoanProgess?.loanProgess?.filter(function (
    item: any
  ) {
    return item?.status == 'DISBURSED'
  })
  const totalDisbursed = allDisbursed
    ?.map((item: any) => item.timeElapsed)
    ?.reduce((prev: any, curr: any) => prev + curr, 0)

  const allDocuments = fetchLoanProgess?.loanProgess?.filter(function (
    item: any
  ) {
    return (
      item?.status == 'DOCUMENT_ACCEPTED' || item?.status == 'DOCUMENT_REJECTED'
    )
  })
  const totalDocuments = allDocuments
    ?.map((item: any) => item.timeElapsed)
    ?.reduce((prev: any, curr: any) => prev + curr, 0)

  const allCompletedApplications = fetchLoanProgess?.loanProgess?.filter(
    function (item: any) {
      return item?.status == 'APPLICATION_COMPLETED'
    }
  )
  const totalCompleted = allCompletedApplications
    ?.map((item: any) => item.timeElapsed)
    ?.reduce((prev: any, curr: any) => prev + curr, 0)

  function timeConvert(num: any) {
    let d = Math.floor(num / 1440) // 60*24
    let h = Math.floor((num - d * 1440) / 60)
    let m = Math.round(num % 60)

    if (d > 0) {
      return `${d || 0} ${t('days')}, ${h || 0} ${t('hours')}, ${m || 0} ${t(
        'minutes'
      )}`
    } else {
      return `${h || 0} ${t('hours')}, ${m || 0} ${t('minutes')}`
    }
  }

  return (
    <StyledLoanDetails>
      <div className="carSourcingWrapper">
        <div>
          <CarAvailable
            singleLoan={singleLoan}
            loanRefresh={() => loanRefresh()}
            singleLoanLoading={singleLoanLoading}
            carOffers={carRequests?.carOffers}
          />
          {singleLoan?.status === CAR_STATUS.APPLICATION_COMPLETED && (
            <SimilarCar
              singleLoan={singleLoan}
              loanRefresh={() => loanRefresh()}
            />
          )}

          {singleLoan?.status === CAR_STATUS.CAR_SOURCING && (
            <ResponsesTable
              tableData={carRequests?.carOffers}
              dataLoading={carRequestsLoading}
              refreshLoanData={() => loanRefresh()}
              singleLoan={singleLoan}
            />
          )}

          {singleLoan?.status === CAR_STATUS.CUSTOMER_INSPECTION && (
            <AppointmentTable
              // @ts-ignore
              singleLoan={singleLoan}
              loanRefresh={() => loanRefresh()}
            />
          )}
        </div>
        <div>
          <div className="completedStages">
            <div className="title mb-3">{t('completedStages')}</div>
            <>
              {allCompletedApplications?.length == 0 ? (
                ''
              ) : (
                <div className="stage mb-2">
                  <div className="d-flex">
                    <div>{InfoIcon}</div>
                    <div className="ms-3">
                      <span>{t('completedApplications')}</span>
                      <br />
                      <span className="timeElapsed">
                        {timeConvert(totalCompleted) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {allDocuments?.length == 0 ? (
                ''
              ) : (
                <div className="stage mb-2">
                  <div className="d-flex">
                    <div>{InfoIcon}</div>
                    <div className="ms-3">
                      <span>{t('Documents')}</span>
                      <br />
                      <span className="timeElapsed">
                        {timeConvert(totalDocuments) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {allCarAvailable?.length == 0 ? (
                ''
              ) : (
                <div className="stage mb-2">
                  <div className="d-flex">
                    <div>{InfoIcon}</div>
                    <div className="ms-3">
                      <span>{t('carAvailability')}</span>
                      <br />
                      <span className="timeElapsed">
                        {timeConvert(totalCarAvailable) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {allCustomerInspections?.length == 0 ? (
                ''
              ) : (
                <div className="stage mb-2">
                  <div className="d-flex">
                    <div>{InfoIcon}</div>
                    <div className="ms-3">
                      <span>{t('customerInspection')}</span>
                      <br />
                      <span className="timeElapsed">
                        {timeConvert(totalCustomerInspection) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {allEquity?.length == 0 ? (
                ''
              ) : (
                <div className="stage mb-2">
                  <div className="d-flex">
                    <div>{InfoIcon}</div>
                    <div className="ms-3">
                      <span>{t('Equity')}</span>
                      <br />
                      <span className="timeElapsed">
                        {timeConvert(totalEquity) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {allDisbursed?.length == 0 ? (
                ''
              ) : (
                <div className="stage mb-2">
                  <div className="d-flex">
                    <div>{InfoIcon}</div>
                    <div className="ms-3">
                      <span>{t('loanDisbursed')}</span>
                      <br />
                      <span className="timeElapsed">
                        {timeConvert(totalDisbursed) || 0}
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    </StyledLoanDetails>
  )
}
