import dayjs from 'dayjs'
import { ISingleConversation } from '../../../store/conversations/reducer'

interface IConversationGroup {
  [key: string]: {
    dateInMilliseconds: number
    displayString: string
    convos: ISingleConversation[]
  }
}

export function groupConvosByDate(conversations: ISingleConversation[]) {
  let groups: IConversationGroup = {}

  conversations.forEach((convo) => {
    const time = convo.last_message[0].createdAt
    const date = dayjs(time).format('DD-MM-YYYY')

    if (groups[date]) {
      groups[date].convos = [...groups[date].convos, convo]
    } else {
      groups = {
        ...groups,
        [date]: {
          dateInMilliseconds: dayjs(time).valueOf(),
          displayString: dayjs(time).format('MMMM D[,] YYYY'),
          convos: [convo]
        }
      }
    }
  })

  return groups
}
