import styled from 'styled-components'
import PencilEditIcon from '../../../../images/pencil-edit.svg'
import CheckMarkIcon from '../../../../images/check_mark.svg'
import SeenIcon from '../../../../images/seen_book_icon.svg'
import CarIcon from '../../../../images/car-retro-2.svg'
import TimeClockIcon from '../../../../images/time-clock-circle.svg'
import CalendarIcon from '../../../../images/calendar-1.svg'
import MapPinIcon from '../../../../images/map-pin.svg'

export const NameLane = styled.div`
  height: 29px;
  width: 100%;
  display: flex;
  margin-bottom: 19px;
`

export const ProfileName = styled.div`
  height: 25px;
  font-size: ${({ theme }) => theme.font.size.H5};
  color: ${({ theme }) => theme.palette.secondary.main};
  text-transform: capitalize;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  display: flex;
`

export const EditPencil = styled.div`
  width: 16px;
  height: 16px;
  display: none;
  background-image: url(${PencilEditIcon});
  background-repeat: no-repeat;
  margin-top: 5px;
  margin-left: 4px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.common.white};

  ${ProfileName}:hover & {
    display: block;
  }
`

export const CheckMark = styled.div`
  width: 20px;
  height: 20px;
  background-image: url(${CheckMarkIcon});
  background-repeat: no-repeat;
  margin-top: 6px;
  margin-left: 5px;
  cursor: pointer;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const UserStatus = styled.div`
  width: 111px;
  height: 29px;
  line-height: 29px;
  text-align: center;
  margin-left: 11px;
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
  font-size: ${({ theme }) => theme.font.size.sm};
  background-color: #eeeeee;
  border-radius: 4px;
`

export const NameInputs = styled.div`
  display: flex;
`

export const DetailItemSeenIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${SeenIcon});
  background-repeat: no-repeat;
`
export const DetailItemMapIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${MapPinIcon});
  background-repeat: no-repeat;
`

export const DetailItemCarIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${CarIcon});
  background-repeat: no-repeat;
`

export const DetailItemTimeIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${TimeClockIcon});
  background-repeat: no-repeat;
`

export const DetailItemTCalendarIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${CalendarIcon});
  background-repeat: no-repeat;
`



export const CreateUser = styled.div`
  height: 29px;
  line-height: 29px;
  text-align: center;
  margin-left: 11px;
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: ${({ theme }) => theme.font.size.sm};
  background-color: ${({ theme }) => theme.palette.common.white};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    font-weight: ${({ theme }) => theme.font.weight.bold};
  }
`

export const TabTitle = styled.div`
  height: 25px;
  margin-bottom: 10px;
  font-size: ${({ theme }) => theme.font.size.H5};
  color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  text-transform: capitalize;
`

