import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import {
  getMessageStats,
  getConnectionStatus,
  getMode,
  getRecipientMessages,
  getAssignedAgentData,
  getRecipientLeadId,
  getRecipientName,
  getConvoReadStatus,
  getNewMessageAlert,
  getConvoOpenStatus
} from '../../../store/conversations/selectors'
import {
  clearSearchResults,
  fetchMessageStats,
  searchConversations,
  readConversation,
  fetchChatMessages
} from '../../../store/conversations/actions'
import PersonProfileI from '../../../images/person_profile.svg'
import ConvoSyncI from '../../../images/conversation-sync.svg'
import CheckMarkI from '../../../images/check-mark-2.svg'
import ConvoChatI from '../../../images/conversation-chat.svg'
import { IConversationsMatchParams } from '../Conversations'
import {
  CategoriesWrapper,
  CategoriesTitle,
  ConnectionIndicator,
  CategoriesSearchBar,
  CategoriesList,
  CategoriesListItem,
  StyledCategoryLink,
  CategoryName,
  MessagesPerCategory,
  SearchIcon,
  CategoryIcon,
  SearchQuery
} from './styles'
import { useTranslation } from 'react-i18next'
import {
  getSelectedCountry,
  getUserIdIfAgent,
  getUserToken
} from '../../../store/auth/selectors'
import { IRootState } from '../../../store/reducer.interface'
import { stat } from 'fs'
import api from '../../../utils/api'
import { getLeadIdsToAgent, getAllAgents } from '../../../store/leads/selectors'

const CategorySearch = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [query, setQuery] = useState('')
  const mode = useSelector(getMode)

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    setQuery(value.slice(0, 20))
    if (value.trim() === '') {
      clearSearch()
    }
  }

  const clearSearch = () => {
    setQuery('')
    dispatch(clearSearchResults())
  }

  const handleSearch = () => {
    if (query === '') return
    dispatch(searchConversations(query, true))
    history.push('/conversations/all')
  }

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleSearch()
      event.preventDefault()
    }
  }

  return (
    <CategoriesSearchBar>
      {mode === 'search' ? (
        <SearchQuery>
          {query} &nbsp;<span onClick={clearSearch}>x</span>
        </SearchQuery>
      ) : (
        <input
          type="number"
          placeholder="e.g 2341235678021"
          value={query}
          onChange={handleInput}
          onKeyPress={handleKeyPress}
        />
      )}

      <SearchIcon onClick={handleSearch} />
    </CategoriesSearchBar>
  )
}

type TCategorySlug = 'queue' | 'active' | 'closed' | 'all'

interface CategoryProps {
  name: string
  numOfConvos: number
  iconUrl: string
  active?: boolean
  slug: TCategorySlug
}

const Category = ({
  name,
  numOfConvos,
  iconUrl,
  active,
  slug
}: CategoryProps) => {
  return (
    <CategoriesListItem active={active}>
      <StyledCategoryLink to={`/conversations/${slug}`}>
        <CategoryIcon icon={iconUrl} />
        <CategoryName>{name}</CategoryName>
        <MessagesPerCategory>{numOfConvos}</MessagesPerCategory>
      </StyledCategoryLink>
    </CategoriesListItem>
  )
}

export default function Categories() {
  const dispatch = useDispatch()
  const stats = useSelector(getMessageStats)
  const isConnected = useSelector(getConnectionStatus)
  const { status, recipientPhone } = useParams<IConversationsMatchParams>()
  const convoOpenStatus = useSelector((state: IRootState) =>
    getConvoOpenStatus(state, recipientPhone)
  )
  const readStatus = useSelector((state: IRootState) =>
    getConvoReadStatus(state, recipientPhone)
  )
  const token = useSelector(getUserToken)
  const country = useSelector(getSelectedCountry)
  const agentIds = useSelector(getLeadIdsToAgent)
  const agentId: any = useSelector(getUserIdIfAgent)

  useEffect(() => {
    dispatch(fetchMessageStats())
  }, [dispatch])

  const { t } = useTranslation()

  return (
    <CategoriesWrapper>
      <CategoriesTitle>
        {t('conversations')} <ConnectionIndicator connected={isConnected} />
      </CategoriesTitle>
      <CategorySearch />
      <CategoriesList>
        {/* <Category
          name={t('Queue')}
          numOfConvos={stats.queue}
          iconUrl={ConvoSyncI}
          slug="queue"
          active={status === 'queue'}
        /> */}
        <Category
          name={t('Active')}
          numOfConvos={stats.active}
          iconUrl={PersonProfileI}
          slug="active"
          active={status === 'active'}
        />
        <Category
          name={t('Closed')}
          numOfConvos={stats.closed}
          iconUrl={CheckMarkI}
          slug="closed"
          active={status === 'closed'}
        />
        <Category
          name={t('allConversations')}
          numOfConvos={stats.all}
          iconUrl={ConvoChatI}
          slug="all"
          active={status === 'all'}
        />
      </CategoriesList>
    </CategoriesWrapper>
  )
}
