import React, { useState } from 'react'
import { Modal, Button, Form, Input } from 'antd'
import { StyledDocumentModal } from './styles'
import { showSnackbar } from '../../../store/snackbar/actions'
import api from '../../../utils/api'
import { useSelector, useDispatch } from 'react-redux'
import { getUserToken } from '../../../store/auth/selectors'

export default function CommentBoxModal({
  visible,
  setVisible,
  refetch,
  documentObject
}: any) {
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)

  const handleSubmit = async (values: any) => {
    let newData = { ...documentObject }
    newData.status = 'REJECTED'
    newData.description = values.description

    try {
      const response = await api.updateDocument(token, newData, newData.id)
      if (response) {
        dispatch(showSnackbar('Document rejected', 'success'))
        setVisible(false)
      }
    } catch (e) {}
    refetch()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Modal closable={false} visible={visible} width="440px" footer={null}>
      <StyledDocumentModal>
        <div className="modalWrapper">
          <div className="title mb-3">{documentObject?.name}</div>
          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={handleSubmit}
            autoComplete="off"
          >
            <Form.Item
              name="description"
              label="Reason"
              rules={[
                { required: true, message: 'Please input a description' }
              ]}
            >
              <Input.TextArea rows={4} maxLength={100} autoComplete="none" />
            </Form.Item>

            <div className="d-flex justify-content-between">
              <Button className="cancelButton" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="saveButton" htmlType="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </StyledDocumentModal>
    </Modal>
  )
}
