import React from 'react'

const PersonIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.2587 11.2797V13.2797C14.2595 13.4654 14.2215 13.6492 14.1471 13.8193C14.0727 13.9894 13.9636 14.1421 13.8268 14.2676C13.69 14.3932 13.5285 14.4887 13.3526 14.5482C13.1767 14.6077 12.9903 14.6298 12.8054 14.6131C10.754 14.3902 8.78341 13.6892 7.05208 12.5664C5.4413 11.5428 4.07564 10.1772 3.05208 8.56641C1.9254 6.82721 1.22424 4.84707 1.00541 2.78641C0.988751 2.60205 1.01066 2.41625 1.06974 2.24082C1.12883 2.0654 1.22379 1.9042 1.34859 1.76749C1.47339 1.63077 1.62528 1.52155 1.79461 1.44675C1.96393 1.37196 2.14697 1.33325 2.33208 1.33307H4.33208C4.65562 1.32989 4.96927 1.44446 5.21459 1.65543C5.4599 1.8664 5.62013 2.15937 5.66541 2.47974C5.74983 3.11978 5.90638 3.74822 6.13208 4.35307C6.22177 4.59169 6.24119 4.85102 6.18802 5.10033C6.13485 5.34964 6.01132 5.57848 5.83208 5.75974L4.98541 6.60641C5.93445 8.27544 7.31638 9.65737 8.98541 10.6064L9.83208 9.75974C10.0133 9.5805 10.2422 9.45697 10.4915 9.4038C10.7408 9.35063 11.0001 9.37004 11.2387 9.45974C11.8436 9.68544 12.472 9.84199 13.1121 9.92641C13.4359 9.97209 13.7317 10.1352 13.9431 10.3847C14.1545 10.6343 14.2669 10.9528 14.2587 11.2797Z"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PersonIcon
