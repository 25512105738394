import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getSelectedCountry,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import {
  showVehicleModal,
  showSellCarModal
} from '../../../store/modal/actions'
import { useTranslation } from 'react-i18next'

import {
  getAllVehicleDetails,
  saveLoanCarId
} from '../../../store/leads/actions'
import { getAllBuyCarId } from '../../../store/leads/selectors'
import VehicleDetails from '../../Leads/LeadDetails/ServiceInterest/VehicleDetails'

let linKtoConsole =
  process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
    ? 'https://console.staging.myautochek.com/inventory/'
    : 'https://console.autochek.africa/inventory/'

const DUMMY_URL =
  'https://storage.googleapis.com/ng.img.autochek.africa/xxl/6_inventory2248780_1601107482.jpg'

const useStyles = makeStyles({
  carItem: {
    display: 'flex',
    padding: '0.5em',
    marginBottom: '1em',
    width: '100%',
    border: '1px solid #A1AACE',
    borderRadius: '4px'
  },
  carImage: {
    flexShrink: 0,
    width: '35%',
    borderRadius: '4px',
    objectFit: 'cover'
  },
  carSummary: {
    color: '#0F1A4F',
    width: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '0.5em',
    '& p': {
      margin: '0'
    }
  },
  carSummaryTop: {
    overflow: 'hidden',
    '& > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  carSummaryBottom: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  carPrice: {
    fontWeight: 'bold'
  },
  carId: {
    fontSize: '12px',
    flexBasis: '70%'
  },
  viewDetailsBtn: {
    background: 'transparent',
    border: 'none',
    color: '#4B9FF2',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0
  }
})

const CarItem = (props: any) => {
  const classes = useStyles()
  const currency = useSelector(getSelectedCountryCurrency)
  const selectedCountry = useSelector(getSelectedCountry)
  const allCarId = useSelector(getAllBuyCarId)
  const carIds = useSelector(getSelectedCountry)
  const [selectedCarId, setSelectedCarId] = React.useState('')
  const dispatch = useDispatch()

  const showModal = (carId: string) => {
    setSelectedCarId(carId)

    setTimeout(() => {
      dispatch(showVehicleModal())
    }, 600)
  }

  //   React.useEffect(() => {

  //   if(props.car.id){
  // let carId = [props.car.id]

  // dispatch(saveLoanCarId(props.car.id))
  // setTimeout(()=>{

  //   console.log('set time',props.car.id)
  // }, 3000)
  // dispatch(getAllVehicleDetails(carIds))
  // console.log('get',props.car.id)

  //   }

  //     },[props.car.id])

  const { t } = useTranslation()

  return (
    <div className={classes.carItem}>
      <div
        style={{
          backgroundImage: `url(${props.car.imageUrl})`,
          flexBasis: '30%',
          backgroundSize: 'cover',
          height: '135px',
          marginRight: '10px'
        }}
      ></div>

      {/* <img src={props.car.imageUrl} alt="Selected car" className={classes.carImage} /> */}
      <div className={classes.carSummary}>
        <div className={classes.carSummaryTop}>
          <p className={classes.carPrice}>{props.carName}</p>
          <p>
            {currency}
            {props.car?.price?.toLocaleString()}
          </p>
        </div>

        <div className={classes.carSummaryBottom}>
          <p className={classes.carId}>
            {t('Car ID')}: {props.car.id}
          </p>
        </div>
        <div className={classes.carSummaryBottom}>
          <p className={classes.carId}>
            {t('location')}: {props.car.city}, {props.car.country}
          </p>
        </div>
        <div className={classes.carSummaryBottom}>
          <a href={`${linKtoConsole + props.car.id}`} target="/blank">
            <button className={classes.viewDetailsBtn}>
              {t('viewConsole')}
            </button>
          </a>
        </div>
      </div>
      {/* <VehicleDetails country={selectedCountry} carId={props.car.id} /> */}
    </div>
  )
}

export default CarItem
