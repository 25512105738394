import { Grid, Typography } from '@material-ui/core'
import React from 'react'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { useSelector } from 'react-redux'
import RelativeTime from 'dayjs/plugin/relativeTime'
import { useTlStyles } from './styles'
import phone from '../../../../../images/phone-forwarded.svg'
import box from '../../../../../images/box-dark.svg'
import bell from '../../../../../images/bell.svg'
import calendar from '../../../../../images/calendar-dark.svg'
import { IActivity } from '../../../../../store/events/reducer'
import { getSelectedCountryCurrency } from '../../../../../store/auth/selectors'
import {
  getLocationOptions,
  getServiceOptions
} from '../../../../../store/events/selectors'
import { useTranslation } from 'react-i18next'
dayjs.extend(RelativeTime)

const mapper: any = {
  PHONE_CALL: {
    title: 'Phone call',
    icon: phone
  },
  APPOINTMENT: {
    title: 'Appointment',
    icon: calendar
  },
  REMINDER: {
    title: 'Reminder',
    icon: bell
  },
  STORE_VISIT: {
    title: 'Store visit',
    icon: box
  }
}

interface IProps {
  activity: IActivity
  index: number
}

interface IAuthor {
  firstname: string
  lastname: string
  id: string
}

const TimelineCard: React.FC<IProps> = ({ activity, index }) => {
  const classes = useTlStyles()
  const serviceRendered = useSelector(getServiceOptions)
  const currency = useSelector(getSelectedCountryCurrency)
  const locations = useSelector(getLocationOptions)
  const {
    activityType,
    date,
    time,
    updatedAt,
    note,
    locationId,
    serviceRenderedId,
    appointment,
    lead
  } = activity

  let linKtoConsole =
    process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
      ? 'https://console.staging.myautochek.com/inventory/'
      : 'https://console.autochek.africa/inventory/'

  const getAuthor = (data: string): IAuthor => {
    try {
      return JSON.parse(data)
    } catch (error) {
      return { firstname: '', lastname: '', id: '' }
    }
  }

  const { t } = useTranslation()

  return (
    <Grid container item xs={12} className={classes.cardWrapper}>
      {!!index && <div className={classes.line} />}
      <Grid item xs={9} className={classes.cardWrapperInner}>
        <Grid item className={classes.iconWrapper}>
          {mapper[activityType] ? (
            <img src={mapper[activityType].icon} alt={activityType} />
          ) : null}
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body2" className={classes.cardTitle}>
            {appointment && appointment.type == 'BUY_CAR'
              ? t('Buy Car Appointment')
              : null}
            {appointment && appointment.type == 'SELL_CAR'
              ? t('Sell Car Appointment')
              : null}
            {appointment && appointment.type == 'FIX_CAR'
              ? t('Fix Car Appointment')
              : null}
            {appointment && appointment.type == 'TEST_DRIVE_CAR'
              ? t('Test Drive Car Appointment')
              : null}

            {appointment && appointment.type == 'TEST_DRIVE_TRUCK'
              ? t('Test Drive Truck Appointment')
              : null}
            {appointment && appointment.type == 'BUY_TRUCK'
              ? t('Buy Truck Appointment')
              : null}
            {appointment && appointment.type == 'SELL_TRUCK'
              ? t('Sell Truck Appointment')
              : null}

            {/* {`${t('TRANSACTION OVERVIEW')}`} */}

            {mapper[activityType] ? (
              <Typography>
                {appointment ? null : mapper[activityType].title}
              </Typography>
            ) : null}
          </Typography>
          <Typography variant="body2" className={classes.cardStatus}>
            {t('Completed')}
            <Typography component="span" className={classes.cardStatusText}>
              {`${t('scheduled')} ${time} ${dayjs(date).format('DD/MM/YYYY')}`}
            </Typography>
          </Typography>

          {appointment &&
          appointment.carData &&
          appointment.carData.franchise ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseName')}:
                <Typography component="span" className={classes.cardStatusText}>
                  {appointment.carData.franchise.name}
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseLocation')}:
                <Typography component="span" className={classes.cardStatusText}>
                  {appointment.carData.franchise.address}
                </Typography>
              </Typography>
            </Grid>
          ) : null}
          {appointment &&
          appointment.carData &&
          appointment.carData.salesOfficer ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('salesOfficer')} :
                <Typography component="span" className={classes.cardStatusText}>
                  {appointment.carData.salesOfficer.firstname +
                    ' ' +
                    appointment.carData.salesOfficer.lastname}
                </Typography>
              </Typography>
            </Grid>
          ) : null}

          {appointment &&
          appointment.type == 'BUY_CAR' &&
          !appointment.carData.salesOfficer ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('salesOfficer')} :
                <Typography component="span" className={classes.cardStatusText}>
                  N/A
                </Typography>
              </Typography>
            </Grid>
          ) : null}

          {appointment &&
          (appointment.type == 'BUY_CAR' ||
            appointment.type == 'TEST_DRIVE_CAR' ||
            appointment.type == 'BUY_TRUCK' ||
            appointment.type == 'TEST_DRIVE_TRUCK') &&
          !appointment.carData.franchise ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseName')}:
                <Typography component="span" className={classes.cardStatusText}>
                  N/A
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseLocation')}:
                <Typography component="span" className={classes.cardStatusText}>
                  N/A
                </Typography>
              </Typography>
            </Grid>
          ) : null}

          {!!serviceRenderedId && (
            <Typography
              variant="body2"
              className={clsx(classes.cardStatus, classes.textBlue)}
            >
              {t('serviceRendered')}:
              <Typography component="span" className={classes.cardStatusText}>
                {
                  serviceRendered.find(
                    (service) => service.value === serviceRenderedId
                  )?.label
                }
              </Typography>
            </Typography>
          )}

          {!!locationId && (
            <Typography
              variant="body2"
              className={clsx(classes.cardStatus, classes.textBlue)}
            >
              {t('location')}:
              <Typography component="span" className={classes.cardStatusText}>
                {
                  locations.find(
                    (location: any) => location.value === locationId
                  )?.label
                }
              </Typography>
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid item xs={3} className={classes.menuWrapper}>
        <Typography component="span" className={classes.cardStatusText}>
          {t('Updated')} {dayjs(updatedAt).fromNow()}
        </Typography>
      </Grid>

      {appointment && appointment.carId ? (
        <Grid item xs={12} className={classes.carCover}>
          <Grid item className={classes.carCoverChildImage}>
            <div
              style={{
                backgroundImage: `url(${appointment.carData.imageUrl})`,
                flexBasis: '30%',
                backgroundSize: 'cover',
                borderRadius: '5px',
                height: '135px',
                marginRight: '10px'
              }}
            ></div>
          </Grid>
          <Grid item className={classes.carCoverChild}>
            <Typography component="span" className={classes.carName}>
              {appointment.carData.year} {appointment.carData.make}{' '}
              {appointment.carData.model}
            </Typography>
            <Typography component="p">
              {appointment.carData.city} , {appointment.carData.state}
            </Typography>

            {appointment.carData.price ? (
              <Typography component="p">
                {currency}
                {parseFloat(appointment.carData.price).toLocaleString()}
              </Typography>
            ) : null}

            {appointment.carData && appointment.carId ? (
              <a href={`${linKtoConsole + appointment.carId}`} target="/blank">
                <Typography component="p" className={classes.carText}>
                  {t('viewConsole')}
                </Typography>
              </a>
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      <Grid item xs={12} className={classes.text}>
        {note.map((item) => (
          <React.Fragment key={item.id}>
            <Typography
              variant="body2"
              className={clsx(classes.cardNoteTitle, classes.capitalize)}
            >
              {`${getAuthor(item.authorId).firstname} ${
                getAuthor(item.authorId).lastname
              }`}
              <Typography
                component="span"
                variant="body2"
                style={{ textTransform: 'none' }}
                className={classes.cardNoteTitle}
              >
                {` ${dayjs(item.updatedAt).format('h:mm A DD/MM/YYYY')} ${t(
                  'noted'
                )}:`}
              </Typography>
            </Typography>
            <Typography variant="body2" className={classes.cardNote}>
              {item.text}
            </Typography>
          </React.Fragment>
        ))}
      </Grid>
    </Grid>
  )
}

export default TimelineCard
