import React, { useState, useEffect } from 'react'
import { StyledDocumentUpload } from './styles'
import {
  CancelBIcon,
  MarkBIcon,
  MarkWIcon,
  CancelWIcon,
  NoticeIcon,
  TaskIcon,
  DownloadIcon
} from './svgs'
import { saveAs } from 'file-saver'
import CommentBoxModal from './CommentBoxModal'
import { Button } from 'react-bootstrap'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from '../../../utils/api'
import { useSelector, useDispatch } from 'react-redux'
import {
  getUserToken,
  getUserId,
  getSelectedCountry,
  getSelectedCountryCurrency,
  isAgent
} from '../../../store/auth/selectors'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useTranslation } from 'react-i18next'
import GeneratePfiModal from './GeneratePfiModal'
import { CAR_STATUS } from '../../LeadManagement/AvailableCar/constants'

interface IDetailParam {
  id: string
}

export default function DocumentUpload() {
  const dispatch = useDispatch()
  const [visible, setVisible] = React.useState(false)
  const { id } = useParams<IDetailParam>()
  const token = useSelector(getUserToken)
  const [documentObject, setDocumentObject] = useState<any>({})
  const [generatePfi, setGeneratePfi] = React.useState(false)
  const { t } = useTranslation()

  const { data: fetchDocument, refetch } = useQuery(
    'document',
    () => api.fetchDocuments(token, id),
    {
      onSuccess: () => {}
    }
  )

  const { data: singleLoan, status, refetch: loanRefetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    {
      keepPreviousData: true
    }
  )

  const { data: fetchCarDocs } = useQuery(
    ['document', singleLoan],
    () =>
      api.fetchAllCarOffers(token, {
        car_id: singleLoan?.carId
      }),
    {
      onSuccess: () => {}
    }
  )

  const AllDocuments = fetchDocument?.documents?.filter(function (item: any) {
    return (
      item?.status == 'IN-REVIEW' ||
      item?.status == 'APPROVED' ||
      item?.status == 'REJECTED'
    )
  })

  const showPfi = () => {
    setGeneratePfi(true)
  }

  let file =
    'https://storage.googleapis.com/inventory-inspection-report/9Yeoa-z8F-inspection-report.pdf'

  function getFileNameFromUrl(url: any) {
    var el = document.createElement('a')
    el.href = url
    return decodeURIComponent(el.pathname).split('/').pop()
  }

  // function downloadBlob(data: any, name) {
  //   // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  //   const blob = new Blob([data], { type: 'application/pdf' })
  //   const blobUrl = window.URL.createObjectURL(blob)

  //   // Create a link element
  //   const link = document.createElement('a')

  //   // Set link's href to point to the Blob URL
  //   link.href = blobUrl
  //   link.download = name

  //   // Append link to the body
  //   document.body.appendChild(link)

  //   // Dispatch click event on the link
  //   // This is necessary as link.click() does not work on the latest firefox
  //   link.dispatchEvent(
  //     new MouseEvent('click', {
  //       bubbles: true,
  //       cancelable: true,
  //       view: window
  //     })
  //   )

  //   // Remove link from body
  //   document.body.removeChild(link)
  // }

  function downloadBlob(data: any) {
    if (window !== undefined) {
      const blob = new Blob([data], { type: 'application/pdf' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = `report.pdf`
      link.click()
    }

    // window.open(data, '_blank')
  }

  const handleReject = (data: any) => {
    setDocumentObject(data)
    setVisible(true)
  }

  const handleAccept = async (i: any) => {
    let newArr = [...fetchDocument?.documents]
    newArr[i].status = 'APPROVED'
    newArr[i].description = ''
    const data = newArr[i]

    try {
      const response = await api.updateDocument(token, data, data.id)
      if (response) {
        dispatch(showSnackbar('Document approved', 'success'))
      }
    } catch (e) {
      dispatch(showSnackbar('Error updating document', 'error'))
    }
    refetch()
  }

  return (
    <StyledDocumentUpload>
      {fetchDocument?.documents == undefined ||
      fetchDocument?.documents?.length == 0 ? (
        <div className="content">
          <div>{TaskIcon}</div>
          <div className="text ms-3">
            {t('thisCustomerDoesNotDocumentYet')}.
          </div>
        </div>
      ) : (
        <>
          <div className="documentUpload mt-5">
            {AllDocuments?.map((item: any, i: any) => (
              <div key={item?.id}>
                <div className="fileTitle mb-2">{item?.name}</div>
                <div className="fileWrapper">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex">
                      <div className="fileType me-2">FILE</div>
                      <div>
                        <div className="fileName">
                          {getFileNameFromUrl(item?.fileUrl)}
                        </div>
                        <div className="fileSize">N/A</div>
                      </div>
                    </div>
                    <div className="checks d-flex">
                      {item.status == 'APPROVED' ? (
                        <Button
                          active
                          className="accept me-3"
                          onClick={() => handleAccept(i)}
                        >
                          <div>{MarkWIcon}</div>
                        </Button>
                      ) : (
                        <Button
                          className="accept me-3"
                          onClick={() => handleAccept(i)}
                        >
                          <div>{MarkBIcon}</div>
                        </Button>
                      )}

                      {item.status == 'REJECTED' ? (
                        <Button
                          active
                          className="reject"
                          onClick={() => handleReject(item)}
                        >
                          <div>{CancelWIcon}</div>
                        </Button>
                      ) : (
                        <Button
                          className="reject"
                          onClick={() => handleReject(item)}
                        >
                          <div>{CancelBIcon}</div>
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
                <div className="downloadFile d-flex justify-content-between mt-2">
                  <div>
                    <a href={item?.fileUrl} target="_blank" className="me-2">
                      {t('view')}
                    </a>
                    <a href={item?.fileUrl} download>
                      {t('download')}
                    </a>
                    {/* <a onClick={() => downloadBlob(item?.fileUrl)}>Download</a> */}
                  </div>
                  <div className="">
                    {item.status == 'REJECTED' && (
                      <div className="d-flex">
                        <div>{NoticeIcon}</div>
                        <div className="comment">
                          <ul>
                            <li>{item.description}</li>
                          </ul>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="leadsCarDocuments">
            <div className="leadCarWrap">
              <div className="headTitle mb-2">{t('carDocuments')}</div>
              <div className="documents">
                <div className="name">
                  <div className="mb-1">PFI</div>
                  {singleLoan?.pfiUrl == undefined &&
                  fetchCarDocs?.carOffers[0]?.pfiDocumentUrl == undefined ? (
                    <Button
                      className="generateBtn"
                      onClick={showPfi}
                      // disabled={singleLoan?.status !== CAR_STATUS.PPI_PASS}
                    >
                      {t('generate')}
                    </Button>
                  ) : (
                    <a
                      href={
                        fetchCarDocs?.carOffers[0]?.pfiDocumentUrl !== undefined
                          ? fetchCarDocs?.carOffers[0]?.pfiDocumentUrl
                          : singleLoan?.pfiUrl
                      }
                      target="_blank"
                    >
                      <Button className="ppiBtn">
                        PFI&nbsp;&nbsp; {DownloadIcon}
                      </Button>
                    </a>
                  )}
                </div>
                <GeneratePfiModal
                  generatePfi={generatePfi}
                  setGeneratePfi={setGeneratePfi}
                  loanRefetch={loanRefetch}
                />
              </div>
            </div>
          </div>
        </>
      )}

      <CommentBoxModal
        documentObject={documentObject}
        visible={visible}
        setVisible={setVisible}
        refetch={refetch}
      />
    </StyledDocumentUpload>
  )
}
