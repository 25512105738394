import createMuiTheme from '@material-ui/core/styles/createMuiTheme'
import colors from './colors'
import { AutochekThemeOptions } from './types'

const theme = createMuiTheme({
  palette: {
    common: {
      white: colors.common.white,
      black: colors.common.black
    },
    primary: {
      main: colors.primary.lightBlue,
      dark: colors.primary.deepBlue
    },
    secondary: {
      main: colors.secondary.main
    },
    background: {
      paper: colors.background.default,
      default: colors.background.default
    },
    neutral: {
      ghostWhite: colors.neutral.ghostWhite,
      grayishBlue: colors.neutral.grayishBlue,
      lighGray: colors.neutral.lighGray,
      whiteLilac: colors.neutral.whiteLilac,
      cyanBluishGray: colors.neutral.cyanBluishGray,
      cyanBluishWhite: colors.neutral.cyanBluishWhite,
      grayChateau: colors.neutral.grayChateau,
      kournikova: colors.neutral.kournikova,
      metallicBlue: colors.neutral.metallicBlue,
      raven: colors.neutral.raven,
      aliceBlue: colors.neutral.aliceBlue,
      vividBlue: colors.neutral.vividBlue,
      grayTint: colors.neutral.grayTint,
      black: colors.neutral.black,
      pattensBlue: colors.neutral.pattensBlue,
      whisper: colors.neutral.whisper,
      gray93: colors.neutral.gray93,
      manatee: colors.neutral.manatee,
      heather: colors.neutral.heather,
      heather2: colors.neutral.heather2,
      someGray: colors.neutral.someGray,
      zircon: colors.neutral.zircon,
      lightSlateGrey: colors.neutral.lightSlateGrey,
      regentGrey: colors.neutral.regentGrey,
      solitude: colors.neutral.solitude
    }
  },
  font: {
    size: {
      xxs: '8px',
      xs: '10px',
      sm: '12px',
      md: '14px',
      H6: '16px',
      H5: '18px',
      H4: '24px',
      H3: '36px',
      H2: '48px',
      H1: '64px'
    },
    weight: {
      light: 300,
      regular: 500,
      bold: 700,
      bolder: 900
    }
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none'
      }
    },
    MuiTab: {
      root: {
        '&.Mui-selected': {
          color: colors.primary.lightBlue,
          fontWeight: 700
        }
      }
    }
  }
} as AutochekThemeOptions)

theme.overrides = {
  MuiTypography: {
    body1: {
      fontSize: 14
    },
    body2: {
      fontSize: 12
    }
  },
  MuiAppBar: {
    root: {
      boxShadow: 'none'
    }
  },
  MuiTab: {
    root: {
      '&.Mui-selected': {
        color: colors.primary.lightBlue,
        fontWeight: 700
      }
    }
  },
  MuiButton: {
    root: {
      minHeight: '2.125rem',
      outline: '0 !important',
      borderRadius: '0.25rem',
      fontSize: '0.875rem',
      textTransform: 'none',
      fontWeight: 'bold'
    },
    contained: {
      backgroundColor: '#4B9FF2',
      color: '#fff',
      boxShadow: 'none',
      paddingRight: 12,
      paddingLeft: 12,
      '&:hover': {
        background: '#4B9FF2 !important',
        color: '#fff',
        boxShadow: 'none'
      }
    },
    outlined: {
      border: 'solid 1px #4B9FF2',
      background: '#fff',
      color: '#4B9FF2',
      boxShadow: 'none',
      '&:hover': {
        background: 'rgba(75, 159, 242, .1) !important',
        color: '#4B9FF2'
      }
    },
    text: {},
    disabled: {}
  },
  MuiInputBase: {
    root: {
      height: '2.5rem',
      boxSizing: 'border-box'
    },
    multiline: {
      height: 'auto'
    }
  }
}

export default theme
