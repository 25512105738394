import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchLead } from '../../../store/leads/actions'
import { getLeadDetails } from '../../../store/leads/selectors'
import { Link } from 'react-router-dom'
import {
  Integrations,
  IntegrationsHeader,
  IntegrationsTitle,
  IntegrationsBody,
  CreateLeadButton,
  LeadDetails,
  LeadDetailItem,
  LeadDetailItemLabel,
  LeadDetailItemValue,
  SettingsIcon
} from './styles'
import { useTranslation } from 'react-i18next'

interface IIntegrationsContainerProps {
  initiateLeadCreation: () => void
  leadId?: string
}

export default function IntegrationsContainer({
  initiateLeadCreation,
  leadId
}: IIntegrationsContainerProps) {
  const dispatch = useDispatch()
  const lead = useSelector(getLeadDetails)

  useEffect(() => {
    if (leadId) {
      dispatch(fetchLead(leadId))
    }
  }, [dispatch, leadId])

  const { t } = useTranslation()

  return (
    <Integrations>
      <IntegrationsHeader>
        <IntegrationsTitle>{t('Integrations')}</IntegrationsTitle>
        <SettingsIcon />
      </IntegrationsHeader>
      <IntegrationsBody>
        {leadId ? (
          <LeadDetails>
            <LeadDetailItem>
              <LeadDetailItemLabel>{t('firstName')}</LeadDetailItemLabel>
              <LeadDetailItemValue>{lead.firstName}</LeadDetailItemValue>
            </LeadDetailItem>
            <LeadDetailItem>
              <LeadDetailItemLabel>{t('lastName')}</LeadDetailItemLabel>
              <LeadDetailItemValue>{lead.lastName}</LeadDetailItemValue>
            </LeadDetailItem>
            <LeadDetailItem>
              <LeadDetailItemLabel>{t('email')}</LeadDetailItemLabel>
              <LeadDetailItemValue>{lead.email}</LeadDetailItemValue>
            </LeadDetailItem>
            <LeadDetailItem>
              <LeadDetailItemLabel>{t('phoneNumber')}</LeadDetailItemLabel>
              <LeadDetailItemValue>{lead.phone}</LeadDetailItemValue>
            </LeadDetailItem>
            <CreateLeadButton>
              <Link to={`/leads/${leadId}`}>{t('viewDetails')}</Link>
            </CreateLeadButton>
          </LeadDetails>
        ) : (
          <CreateLeadButton onClick={() => initiateLeadCreation()}>
            {t('Create Lead')}
          </CreateLeadButton>
        )}
      </IntegrationsBody>
    </Integrations>
  )
}
