import React, { useState } from 'react'
import { Modal, Button, Form, Select, message, Spin } from 'antd'
import { StyledMapModal } from './styles'
import { SearchIcon } from '../../../PortfolioManagement/Dashboard/svgs'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from '../../../../utils/api'
import { useSelector } from 'react-redux'
import {
  getSelectedCountry,
  getUserToken,
  getUserId
} from '../../../../store/auth/selectors'
import { LoadingOutlined } from '@ant-design/icons'
import { useForm } from 'antd/lib/form/Form'

interface IModalProp {
  isModalVisible: boolean
  setIsModalVisible: any
  handleRefresh: any
  modalData: any
}

interface IUser {
  firstname: string
  lastname: string
  email: string
  primaryPhone: string
  id: string
  receiveNotification: boolean
  referrerCode: string
}

interface IPartner {
  id: string
  name: string
}

export default function EditAgentModal({
  isModalVisible,
  setIsModalVisible,
  handleRefresh,
  modalData
}: IModalProp) {
  const { Option } = Select
  const [selectedValue, setSelectedValue] = useState('')
  const [selectedLabelValue, setSelectedLabelValue] = useState([])
  const country = useSelector(getSelectedCountry)
  const token = useSelector(getUserToken)
  const userId = useSelector(getUserId)
  const [form] = useForm()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 36, color: '#30355a' }} spin />
  )

  const queryClient = useQueryClient()

  const { data: fetchManagers, status } = useQuery('fetchManagers', () =>
    api.fetchPortfolioManagers(token, country)
  )
  const { data: fetchPartners, status: fetchStatus } = useQuery(
    'fetchPartners',
    () => api.fetchOriginationPartners(token, country)
  )

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  function onChange(value: any, labelInValue: any) {
    setSelectedValue(labelInValue.children.props.children[0].props.children)
    setSelectedLabelValue(
      labelInValue.children.props.children[1].props.children
    )
  }

  React.useEffect(() => {
    form.setFieldsValue({
      agents: modalData?.userId,
      assign_banks: modalData?.partnerIds
    })
  }, [form, modalData])

  const updatePartnerSubmit: any = useMutation(
    (payload) => api.updatePortfolioPartner(token, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('assign-manager-to-partner')
        setSubmitting(false)
        message.success('Partner updated successfully')
        setIsModalVisible(false)
        handleRefresh()
        form.resetFields()
        setSelectedValue('')
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(
          error?.response.data.error ||
            'Uh oh, something went wrong, please try again'
        )
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  function onSearch(val: any) {
    // console.log('search:', val)
  }

  const onFinish = async (values: any) => {
    setSubmitting(true)
    const payload = {
      partner_ids: values?.assign_banks,
      user_ids: values?.agents
    }
    await updatePartnerSubmit.mutateAsync(payload)
  }

  return (
    <div>
      <Modal
        title="Edit Portfolio Manager"
        footer={null}
        visible={isModalVisible}
        closable={false}
        width="634px"
      >
        <StyledMapModal>
          <Form
            name="validate_other"
            layout="vertical"
            form={form}
            requiredMark={false}
            onFinish={onFinish}
          >
            <Form.Item
              name="agents"
              label="Search Agents:"
              rules={[
                {
                  required: true,
                  message: 'Please search agents'
                }
              ]}
            >
              <Select
                showSearch
                // suffixIcon={SearchIcon}
                placeholder="Search agents..."
                optionFilterProp="children"
                dropdownClassName="dropdown"
                onChange={onChange}
                onSearch={onSearch}
                filterOption={(input, option) =>
                  // @ts-ignore
                  option.value.toLowerCase().includes(input.toLowerCase())
                }
              >
                {fetchManagers?.users?.map((el: IUser, i: number) => (
                  <Option key={i} value={el.id}>
                    <div className="optionValue">
                      <div className="name">{`${el.firstname} ${el.lastname}`}</div>
                      <div className="email">{el.email}</div>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {selectedValue && (
              <div className="selectedValue mb-3">
                <div className="name">{selectedValue}</div>
                <div className="email">Email: {selectedLabelValue}</div>
              </div>
            )}

            <Form.Item
              name="assign_banks"
              label="Assign Banks:"
              rules={[
                {
                  required: true,
                  message: 'Please select banks',
                  type: 'array'
                }
              ]}
            >
              <Select mode="multiple" placeholder="Search banks...">
                {fetchPartners?.partners?.map(
                  (partner: IPartner, i: number) => (
                    <Option key={i} value={partner.id}>
                      {partner.name}
                    </Option>
                  )
                )}
              </Select>
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-center">
                {submitting ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <>
                    <Button className="cancel mt-3 me-3" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button className="submit mt-3" htmlType="submit">
                      Submit
                    </Button>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </StyledMapModal>
      </Modal>
    </div>
  )
}
