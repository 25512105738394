import React, { useEffect } from 'react'
import styled from 'styled-components'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import WhatsAppIcon from '../images/bi_whatsapp.svg'
import { BellIconBtn } from '../views/Leads/styles'
import { useDispatch, useSelector } from 'react-redux'
import { IRootState } from '../store/reducer.interface'
import dayjs from 'dayjs'
import {
  INewMessage,
  setOlderNotifications
} from '../store/notifications/actions'
import { useTranslation } from 'react-i18next'

const Notifications = styled.div`
  min-width: 300px;
  padding: 0 10px;
  padding-top: 20px;
`

const NewNotificationsHeader = styled.div`
  width: 100%;
  padding: 10px;
  background: #6973a9;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
`

const OldNotificationsHeader = styled.div`
  width: 100%;
  padding: 10px;
  background: #e6eaf0;
  color: #31355b;
  font-size: 12px;
  font-weight: bold;
`

const NotificationsItem = styled.div`
  width: 100%;
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid #ebecf0;
`

const ItemIcon = styled.div`
  margin-right: 10px;
  height: 24px;
  width: 24px;
  background-image: url(${WhatsAppIcon});
  background-position: center;
  background-repeat: no-repeat;
`

const ItemContent = styled.div`
  flex-grow: 1;
  width: 100%;
`

const ItemContentTop = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
`

const NotificationMedium = styled.span`
  font-size: 12px;
  font-weight: 800;
  line-height: 16px;
  color: #aeb6be;
  text-transform: uppercase;
`

const NotificationTime = styled.span`
  font-size: 10px;
  line-height: 14px;
  color: #7c7e88;
`

const NotificationSender = styled.p`
  font-size: 14px;
  color: #31355b;
  margin: 0;
`

const NotificationMsg = styled.p`
  font-size: 12px;
  color: #b9b9bc;
  margin: 0;
`

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2)
  }
}))

export default function SimplePopover() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)

  const { t } = useTranslation()

  const newNotifications = useSelector((state: IRootState) =>
    state.notifications.whatsApp.filter((msg) => msg.new)
  )
  const oldNotifications = useSelector((state: IRootState) =>
    state.notifications.whatsApp.filter((msg) => !msg.new)
  )

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <div>
      <BellIconBtn
        newNotificationCount={newNotifications.length}
        onClick={handleClick}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {newNotifications.length > 0 && (
          <NotificationsPopover
            notifications={newNotifications.reverse()}
            newNotifications={true}
          />
        )}
        {oldNotifications.length > 0 && (
          <NotificationsPopover
            notifications={oldNotifications.reverse()}
            newNotifications={false}
          />
        )}
        {oldNotifications.length === 0 && newNotifications.length === 0 && (
          <Typography className={classes.typography}>
            {t('noNotificationsTime')}
          </Typography>
        )}
      </Popover>
    </div>
  )
}

interface INotificationsPopoverProps {
  newNotifications: boolean
  notifications: INewMessage[]
}

const NotificationsPopover = ({
  notifications,
  newNotifications
}: INotificationsPopoverProps) => {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    return () => {
      if (newNotifications && notifications.length > 0) {
        dispatch(setOlderNotifications())
      }
    }
  }, [dispatch, notifications, newNotifications])

  return (
    <Notifications>
      {newNotifications ? (
        <NewNotificationsHeader>{t('newNotifications')}</NewNotificationsHeader>
      ) : (
        <OldNotificationsHeader>
          {t('olderNotifications')}
        </OldNotificationsHeader>
      )}
      {notifications.map((item, i) => {
        return (
          <NotificationsItem key={`${item.message?.text}-${i}`}>
            <ItemIcon />
            <ItemContent>
              <ItemContentTop>
                <NotificationMedium>{t('whatsapp')}</NotificationMedium>
                <NotificationTime>
                  {dayjs(item.time).fromNow()}
                </NotificationTime>
              </ItemContentTop>
              <NotificationSender>{item.phone}</NotificationSender>
              <NotificationMsg>
                {item.message?.type === 'TEXT'
                  ? item.message.text
                  : `Sent you ${item.message?.type.toLowerCase()}`}
              </NotificationMsg>
            </ItemContent>
          </NotificationsItem>
        )
      })}
    </Notifications>
  )
}
