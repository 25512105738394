import { select, call, put, takeLatest } from 'redux-saga/effects'
import api from '../../utils/api'
import {
  getUserToken,
  getUserIdIfAgent,
  getSelectedCountry,
  isAdmin,
  isManager
} from '../auth/selectors'
import { requestStatus } from '../events/actions'
import { doneFetching, isFetching } from '../isFetching/actions'
import { IRootState } from '../reducer.interface'
import { showSnackbar } from '../snackbar/actions'
import {
  EConversationTypes,
  fetchConversations as fetchConversationsAction,
  fetchMessageStats as fetchMessageStatsAction
} from './actions'
import { IPagination } from './reducer'
import {
  getAgentConversations,
  getRecipientConvoMeta,
  getConvoPaginationData,
  getPaginationData,
  getRecipientMessages,
  getSearchPaginationData,
  getSearchResults
} from './selectors'

function* fetchMessageStats(): any {
  try {
    const token = yield select(getUserToken)
    const agentUserId = yield select(getUserIdIfAgent)
    const selectedCountry = yield select(getSelectedCountry)
    const isAdminUser = yield select(isAdmin)
    const isManagerUser = yield select(isManager)

    const agentId = !isAdminUser && !isManagerUser ? agentUserId : null

    const response = yield call(
      api.fetchMessageStats,
      token,
      agentId,
      selectedCountry
    )
    yield put(
      requestStatus(EConversationTypes.FETCH_MESSAGE_STATS_SUCCESS, response)
    )
  } catch (error) {
    yield put(showSnackbar('Unable to load message stats', 'error'))
  }
}

function* fetchConversations({ category, replace }: any): any {
  const pagination: IPagination = yield select((state: IRootState) =>
    getConvoPaginationData(state, category)
  )
  const categoryMessages = yield select((state: IRootState) => getAgentConversations(state, category))
  if (
    replace ||
    categoryMessages.length === 0 ||
    categoryMessages.length < pagination.total
  ) {
    let page = replace ? 1 : pagination.currentPage + 1

    try {
      const token = yield select(getUserToken)
      const agentUserId = yield select(getUserIdIfAgent)
      const selectedCountry = yield select(getSelectedCountry)
      const isAdminUser = yield select(isAdmin)
      const isManagerUser = yield select(isManager)

      const agentId = !isAdminUser && !isManagerUser ? agentUserId : null

      if (categoryMessages.length === 0 && replace) {
        yield put(isFetching('convos'))
      }

      const response = yield call(
        api.fetchConversations,
        token,
        category,
        page,
        agentId,
        selectedCountry
      )
      yield put({
        type: EConversationTypes.FETCH_CONVERSATIONS_SUCCESS,
        payload: response,
        category,
        replace
      })
    } catch {
      yield put(showSnackbar('Unable to fetch conversations', 'error'))
    } finally {
      yield put(doneFetching('convos'))
    }
  } else {
    yield put({
      type: EConversationTypes.FETCH_CONVERSATIONS_SUCCESS,
      payload: {
        data: [],
        pagination
      },
      category,
      replace
    })
  }
}

function* fetchChatMessages({ recipient, replace }: any): any {
  const pagination: IPagination = yield select((state: IRootState) =>
    getPaginationData(state, recipient)
  )
  const recipientMessages = yield select((state: IRootState) => getRecipientMessages(state, recipient))

  const receivedMsgs = recipientMessages || []
  const total = pagination?.total || 0

  if (replace || receivedMsgs.length === 0 || receivedMsgs.length < total) {
    let page = replace ? 1 : pagination.currentPage + 1

    try {
      const token = yield select(getUserToken)
      const response = yield call(api.fetchChatMessages, token, recipient, page)
      yield put({
        type: EConversationTypes.FETCH_CHAT_MESSAGES_SUCCESS,
        payload: response,
        recipient,
        replace
      })
    } catch {
      yield put(showSnackbar('Unable to fetch chat messages', 'error'))
    }
  } else {
    const recipientConvoMeta = yield select((state: IRootState) =>
      getRecipientConvoMeta(state, recipient)
    )

    yield put({
      type: EConversationTypes.FETCH_CHAT_MESSAGES_SUCCESS,
      payload: {
        data: [],
        metaData: recipientConvoMeta,
        pagination
      },
      recipient,
      replace
    })
  }
}

function* readConversation({ recipient, status }: any): any {
  try {
    const token = yield select(getUserToken)

    const response = yield call(api.readConversation, token, recipient)
    if (response) {
      yield put({
        type: EConversationTypes.READ_CONVERSATION_SUCCESS,
        recipient: recipient
      })
      yield put(fetchMessageStatsAction())
      yield put(fetchConversationsAction(status))
    }
  } catch {
    yield put(showSnackbar('Unable to set read status', 'error'))
  }
}

function* searchConversations({ phoneNumber, replace }: any): any {
  const pagination: IPagination = yield select((state: IRootState) =>
    getSearchPaginationData(state)
  )
  const searchResults = yield select(getSearchResults)
  if (
    replace ||
    searchResults.length === 0 ||
    searchResults.length < pagination.total
  ) {
    let page = replace ? 1 : pagination.currentPage + 1
    try {
      const token = yield select(getUserToken)
      const selectedCountry = yield select(getSelectedCountry)

      if (searchResults.length === 0 && replace) {
        yield put(isFetching('convos'))
      }
  
      const response = yield call(
        api.fetchConversations,
        token,
        'all',
        page,
        null,
        selectedCountry,
        phoneNumber
      )
  
      yield put({
        type: EConversationTypes.SEARCH_CONVERSATIONS_SUCCESS,
        payload: response,
        replace,
        query: phoneNumber
      })
    } catch {
      yield put(showSnackbar('Unable to search conversations', 'error'))
    } finally {
      yield put(doneFetching('convos'))
    }
  } else {
    yield put({
      type: EConversationTypes.SEARCH_CONVERSATIONS_SUCCESS,
      payload: {
        data: [],
        pagination
      },
      replace,
      query: phoneNumber
    })
  }

  
}

function* watchFetchMessageStats() {
  yield takeLatest(EConversationTypes.FETCH_MESSAGE_STATS, fetchMessageStats)
}

function* watchFetchConversations() {
  yield takeLatest(EConversationTypes.FETCH_CONVERSATIONS, fetchConversations)
}

function* watchFetchChatMessages() {
  yield takeLatest(EConversationTypes.FETCH_CHAT_MESSAGES, fetchChatMessages)
}

function* watchReadConversation() {
  yield takeLatest(EConversationTypes.READ_CONVERSATION, readConversation)
}

function* watchSearchConversations() {
  yield takeLatest(EConversationTypes.SEARCH_CONVERSATIONS, searchConversations)
}

const conversationSagas: any[] = [
  watchFetchMessageStats(),
  watchFetchConversations(),
  watchFetchChatMessages(),
  watchReadConversation(),
  watchSearchConversations()
]

export default conversationSagas
