export enum EventTypes {
  SET_EVENT = 'SET_EVENT',
  ADD_ACTIVITY = 'ADD_ACTIVITY',
  ADD_ACTIVITY_FAILED = 'ADD_ACTIVITY_FAILED',
  ADD_ACTIVITY_SUCCESS = 'ADD_ACTIVITY_SUCCESS',
  GET_SCHEDULES = 'GET_SCHEDULES',
  GET_COMPLETED_SCHEDULE = 'GET_COMPLETED_SCHEDULE',
  GET_PENDING_SCHEDULE = 'GET_PENDING_SCHEDULE',
  UPDATE_SCHEDULE = 'UPDATE_SCHEDULE',
  DELETE_SCHEDULE = 'DELETE_SCHEDULE',
  DELETE_SCHEDULE_SUCCESS = 'DELETE_SCHEDULE_SUCCESS',
  UPDATE_SCHEDULE_SUCCESS = 'UPDATE_SCHEDULE_SUCCESS',
  SET_TAB = 'SET_TAB',
  SET_SEARCH_QUERY = 'SET_SEARCH_QUERY',
  FETCH_SERVICE_OPTIONS = 'FETCH_SERVICE_OPTIONS',
  FETCH_SERVICE_OPTIONS_SUCCESS = 'FETCH_SERVICE_OPTIONS_SUCCESS'
}

export interface IScheduleQueries {

  completed?: boolean
  searchQuery?: string
  sortBy?: string
  pageSize?: number
  currentPage?: number
  order?: string
  activityType?: string
  startDate?: string
  endDate?: string
  lead_id?: string
}

export interface IAddActivity {
  leadId: string
  date: string
  time: string
  activityType: string
  locationId?: string
  serviceRenderedId?: string
  note: string
  completed: boolean
  appointmentId?: string
  sales_officer_id?: string
  comment?: string
}

export const addSchedule = (payload: IAddActivity) => ({
  type: EventTypes.ADD_ACTIVITY,
  payload
})

export const updateSchedule = (
  payload: {
    note: string
    completed: boolean
    show?: boolean
    date?: any
    time?: any
    sales_officer_id?: any
  },
  id: string
) => ({
  type: EventTypes.UPDATE_SCHEDULE,
  payload,
  id
})

export const updateReschedule = (
  payload: {
    note: string
    completed: boolean
    show?: boolean
    date?: any
    time?: any
    sales_officer_id?: any,
    comment:any,
  },
  id: string
) => ({
  type: EventTypes.UPDATE_SCHEDULE,
  payload,
  id
})

export const deleteSchedule = (id: string) => ({
  type: EventTypes.DELETE_SCHEDULE,
  id
})

export const fetchSchedule = (leadId: string, queries: IScheduleQueries) => ({
  type: EventTypes.GET_SCHEDULES,
  leadId,
  queries
})

export const requestStatus = (type: string, payload: any) => ({
  type,
  payload
})

export const setEvent = (payload: string) => ({
  type: EventTypes.SET_EVENT,
  payload
})

export const setTab = (payload: string) => ({
  type: EventTypes.SET_TAB,
  payload
})

export const setSearchQuery = (payload: string) => ({
  type: EventTypes.SET_SEARCH_QUERY,
  payload
})

export const fetchServiceOptions = () => ({
  type: EventTypes.FETCH_SERVICE_OPTIONS,
})
