import React from 'react'
import {
  Box,
  FormHelperText,
  Grid,
  InputBase,
  TextField,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import { usePhoneStyles } from './styles'
import FormFooter from './FormFooter'
import { addSchedule, setEvent } from '../../../../store/events/actions'
import { getLeadDetails } from '../../../../store/leads/selectors'
import { getEvents } from '../../../../store/events/selectors'
import Select from '../../../../components/CustomizedSelect/Select'
import { useTranslation } from 'react-i18next'

interface IProps {
  log?: boolean
}

const required = 'This field is required.'
const validationSchema = Yup.object().shape({
  date: Yup.string().required(required),
  time: Yup.string().required(required),
  note: Yup.string().required(required)
})
const initialValues = {
  date: '',
  time: '',
  note: '',
  comment: ''
}



const PhoneForm: React.FC<IProps> = ({ log }) => {
  const { t } = useTranslation()
  const phoneOptions = [
    { label: t('Unanswered'), value: 'Unanswered' },
    { label: t('Unreachable'), value: 'Unreachable' },
    { label: t('Busy'), value: 'Busy' },
    { label: t('Scheduled to sell'), value: 'Scheduled to sell' },
    { label: t('Scheduled to buy'), value: 'Scheduled to buy' },
    { label: t('Scheduled to fix'), value: 'Scheduled to fix' },
    { label: t('Incorrect contact: To email'), value: 'Incorrect contact: To email' },
    { label: 'Loan Lead: CP', value: 'Loan lead: CP' },
    { label: 'Car Unavailable: Customer not interested', value: 'Car Unavailable: Customer not interested' },
    { label: t('Car Unavailable: Customer requested for a replacement'), value: 'Car Unavailable: Customer requested for a replacement' },
    { label: t('Client not contacted'), value: 'Client not contacted' },
    { label: t('Successful Conversion'), value: 'Successfull Conversion' },
    { label: t('Completed Profile'), value: 'Completed Profile' },
    { label: t('Test'), value: 'Test' },
    { label: t('Potential Client'), value: 'Potential Client' },
    { label: t('Window Shopping'), value: 'Window Shopping' },
    { label: t('Unavailable client'), value: 'Unavailable client' },
    { label: t('Got a Unit'), value: 'Got a Unit' },
    { label: t('Contacted via Whatsapp: Scheduled to buy'), value: 'Contacted via Whatsapp: Scheduled to buy' },
    { label: t('Contacted by LM'), value: 'Contacted by LM' },
    { label: t('Other'), value: 'Other' }
  ]
  const classes = usePhoneStyles()
  const lead = useSelector(getLeadDetails)
  const event = useSelector(getEvents)
  const dispatch = useDispatch()
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const { date, time, note, comment } = values
      dispatch(
        addSchedule({
          leadId: lead.id,
          activityType: log ? 'PHONE_CALL' : event,
          completed: !!log,
          date,
          time,
          note,
          comment
        })
      )
      handleReset()
    }
  })
  const handleReset = () => {
    dispatch(setEvent(''))
  }
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={classes.datetime}>
        <div>
          <Typography variant="body2" className={classes.label}>
            Date
          </Typography>
          <TextField
            id="date"
            name="date"
            onBlur={handleBlur}
            error={!!(errors.date && touched.date)}
            helperText={touched.date && errors.date}
            value={values.date}
            onChange={handleChange}
            variant="outlined"
            type="date"
            inputProps={{
              min: log
                ? dayjs(new Date('2000-01-01')).format('YYYY-MM-DD')
                : dayjs(new Date()).format('YYYY-MM-DD')
            }}
          />
        </div>
        <div>
          <Typography variant="body2" className={classes.label}>
            Time
          </Typography>
          <InputBase
            name="time"
            type="time"
            className={classes.timeInputWrapper}
            onBlur={handleBlur}
            error={!!(errors.time && touched.time)}
            value={values.time}
            onChange={handleChange}
          />
          {!!touched.time && (
            <FormHelperText error>{errors.time}</FormHelperText>
          )}
        </div>
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          Add a note
        </Typography>
        <Box style={{
          marginBottom: '8px'
        }}>
          <Select
            value={values.note}
            name="note"
            label={t('Add a Note')}
            options={phoneOptions}
            onSelect={handleChange}
          />
        </Box>
        <Typography variant="body2" className={classes.label}>
          Add a comment
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          onBlur={handleBlur}
          name="comment"
          value={values.comment}
          onChange={handleChange}
          id="comment"
          variant="outlined"
        />
      </Grid>
      <FormFooter handleReset={handleReset} handleSubmit={handleSubmit} />
    </Grid>
  )
}

export default PhoneForm
