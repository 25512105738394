import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { usePhoneStyles } from './styles'
import phone from '../../../../images/phone-forwarded.svg'
import box from '../../../../images/box-dark.svg'
import bell from '../../../../images/bell.svg'
import calendar from '../../../../images/calendar-dark.svg'
import PhoneForm from './PhoneForm'
import StoreVisitForm from './StoreVisit'
import { useSelector } from 'react-redux'
import { getEvents } from '../../../../store/events/selectors'
import AppointmentForm from './AppointmentForm'

const Component: any = {
 LOG_PHONE_CALL: {
    component: <PhoneForm log />,
    title: 'Log a phone call',
    icon: phone
  },
  PHONE_CALL: {
    component: <PhoneForm />,
    title: 'Schedule a phone call',
    icon: phone
  },
  APPOINTMENT: {
    component: <AppointmentForm />,
    title: 'Schedule an appointment',
    icon: calendar
  },
  REMINDER: {
    component: <PhoneForm />,
    title: 'Schedule a reminder',
    icon: bell
  },
  STORE_VISIT: {
    component: <StoreVisitForm />,
    title: 'Log a store visit',
    icon: box
  }
}

const ScheduleForm: React.FC = () => {
  const classes = usePhoneStyles()
  const event = useSelector(getEvents)

  return (
    <Grid container className={classes.root} justify="center">
      <Grid item xs={12} className={classes.titleWrapper}>
        <img
          src={Component[event]?.icon}
          alt="phone"
          className={classes.icon}
        />
        <Typography variant="body1" className={classes.title}>
          {Component[event]?.title}
        </Typography>
      </Grid>
      {Component[event]?.component}
    </Grid>
  )
}

export default ScheduleForm
