import styled from 'styled-components'

export const StyledCreateAgent = styled.div`
  background: #f6f7fb;
  min-height: 100vh;
  padding: 1em 1.5em;
`

export const WhatsappFormContainer = styled.div`
  background: #fff;
  min-height: 85vh;
  border-radius: 10px;
  padding: 2em;
  border: 1px solid #e8e8e8;
`
export const FormWrapper = styled.div`
  .title-form {
    font-size: 14px;
    color: rgb(130, 133, 148);
  }
  header {
    font-size: 18px;
    color: rgb(0, 48, 73);
    margin-top: 2em;
    margin-bottom: 16px;
  }
  .ant-select-selector {
    height: 50px !important;
    border-radius: 5px !important;
  }
  .ant-select {
    height: 50px !important;
    border-radius: 5px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 45px;
    transition: all 0.3s;
  }
  form {
    width: 500px;
    label {
      color: rgb(52, 56, 97);
      font-size: 14px;
    }
    .ant-input {
      height: 50px;
      background: rgba(12, 40, 70, 0.01);
      border-radius: 5px;
    }
    .ant-picker {
      width: 100%;
      height: 50px;
      background: rgba(12, 40, 70, 0.01);
      border-radius: 5px;
    }
    button {
      color: rgb(0, 48, 73) !important;
      border: 0;
      display: flex;
      min-width: 200px;
      width: auto;
      background: #ffc107 !important;
      height: 48px;
      transition: 0.3s;
      align-items: center;
      border-radius: 5px;
      margin-top: 1.5rem;
      justify-content: center;
      font-weight: bolder;
    }
  }
`
