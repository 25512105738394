import { all } from 'redux-saga/effects'
import authSagas from './auth/sagas'
import settingsSagas from './settingsSection/sagas'
import leadsSagas from './leads/sagas'
import eventSaga from './events/sagas'
import carSaga from './car/sagas'
import locationSaga from './location/sagas'
import conversationSaga from './conversations/sagas'
import loanSagas from './loans/sagas'

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...settingsSagas,
    ...leadsSagas,
    ...eventSaga,
    ...carSaga,
    ...locationSaga,
    ...conversationSaga,
    ...loanSagas
  ])
}
