import React from 'react'

const ProfileIcon = () => {
  return (
    <svg
      width={21}
      height={20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.375 0.625H3.625C2.93464 0.625 2.375 1.18464 2.375 1.875V18.125C2.375 18.8154 2.93464 19.375 3.625 19.375H17.375C18.0654 19.375 18.625 18.8154 18.625 18.125V1.875C18.625 1.18464 18.0654 0.625 17.375 0.625Z"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10.5 10.9942C12.3915 10.9942 13.925 9.46074 13.925 7.56917C13.925 5.67759 12.3915 4.14417 10.5 4.14417C8.60838 4.14417 7.07495 5.67759 7.07495 7.56917C7.07495 9.46074 8.60838 10.9942 10.5 10.9942Z"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.125 15.8558C15.5852 14.8316 14.7761 13.9742 13.7848 13.3761C12.7935 12.778 11.6577 12.4619 10.5 12.4619C9.34226 12.4619 8.20649 12.778 7.21521 13.3761C6.22393 13.9742 5.41477 14.8316 4.875 15.8558"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default ProfileIcon
