import React, { useCallback, useEffect, useState } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import useStyles from './newUserStyle'
import Popover from '@material-ui/core/Popover'
import ChevronLeftIcon from '../../Loans/LoanDetails/svg/ChevronLeft'
import CircularProgress from '@material-ui/core/CircularProgress'
import Loader from '../../../components/Loader'
import { showSnackbar } from '../../../store/snackbar/actions'

import api from '../../../utils/api'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  getUserToken,
  getUserId,
  getSelectedCountry
} from '../../../store/auth/selectors'

import ReactSelect from 'react-select'

import Checkbox from '@material-ui/core/Checkbox'

import { Link } from 'react-router-dom'
import {
  addLeadNote,
  getLeadNotes,
  getAllVehicleDetails,
  getCarData,
  updateLead,
  getCurrentActivity
} from '../../../store/leads/actions'
import Select from 'react-select'
import { makeStyles } from '@material-ui/core'
import SettingsMenu from '../SettingsMenu'
import { useTranslation } from 'react-i18next'
import {
  LoaderCover,
  SettingsContainer,
  ContentWrap,
  SettingsNavDummy
} from './styles'

const LoanDetails = (props: any) => {
  const classes = useStyles()

  const { t } = useTranslation()

  const country = useSelector(getSelectedCountry)
  const token = useSelector(getUserToken)
  const [showCheck, setShowCheck] = React.useState(true)
  const [allCheckBoxes, setAllCheckBoxes] = React.useState(false)
  const [services, setSevices] = React.useState([])
  const [loader, setLoader] = React.useState(false)
  const [saveUser, setSaveUser] = React.useState(false)
  const [status, setStatus] = React.useState('')
  const [agentId, setAssignedAgentId] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [fromDate, setFromDate] = React.useState('')
  const [agents, setAgents] = useState([])
  const [assigneeAgents, setAssigneeAgents] = useState([])
  const [loan, setLoan] = useState(false)
  const [assignArray, setAssignArray] = useState([])
  const selectedCountry = useSelector(getSelectedCountry)
  let history = useHistory()
  const dispatch = useDispatch()

  const allServices =
    'BUY_CAR,FIX_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK,TEST_DRIVE_CAR,SELL_CAR'

  const checkLoan = (e: any) => {
    if (e.target.checked) {
      setLoan(true)
    } else {
      setLoan(false)
    }
  }
  const checkOtherService = (e: any, service: string) => {
    let serviceArray = [] as any
    if (e.target.checked) {
      serviceArray.push(...services, service)
    } else {
      serviceArray = services.filter((data: string) => {
        return data !== service
      })
    }

    // console.log(services, 'ogoo')
    setSevices(serviceArray)
  }

  const saveAssigneeAgents = (e: any) => {
    let agentArray = [] as any
    agentArray.push({ id: e.value, name: e.label }, ...assigneeAgents)

    let newAssigneeAgent = [] as any

    assignArray.filter((agent: any) => {
      if (agent.value !== e.value) {
        newAssigneeAgent.push(agent)
      }
    })

    setAssignArray(newAssigneeAgent)

    function onlyUnique(value: any, index: any, self: any) {
      return self.indexOf(value) === index
    }

    let uniqueValues

    uniqueValues = agentArray.filter(onlyUnique)

    //  console.log(assigneeAgents, 'unique val')
    setAssigneeAgents(uniqueValues)
  }

  const removeAgent = (agentName: any) => {
    // console.log(agentName, 'name')

    let newAssignArray = [...assignArray] as any

    newAssignArray.push({
      label: agentName.name,
      value: agentName.id
    })

    let removeAgentsArray = assigneeAgents.filter((agent) => {
      return agent !== agentName
    })

    // console.log(removeAgentsArray, 'idss')

    setAssigneeAgents(removeAgentsArray)

    setAssignArray(newAssignArray)
  }

  const handleCheck = () => {
    setShowCheck(false)
    setTimeout(() => {
      setShowCheck(true)
    }, 100)
  }
  const checkAllCheckBoxes = () => {
    setAllCheckBoxes(!allCheckBoxes)
    handleCheck()
  }
  const saveFromDate = (e: any) => {
    setFromDate(e.target.value)
    // console.log(e.target.value)
  }
  const saveToDate = (e: any) => {
    setEndDate(e.target.value)
  }
  const saveStatus = (e: any) => {
    setStatus(e.target.value)
    // console.log(e.target.value)
  }

  const handleAgentChange = (e: any) => {
    // console.log(e)
    setAssignedAgentId(e.value)
    setAssigneeAgents([])
    let newAssigneeAgent = [] as any

    agents.filter((agent: any) => {
      if (agent.value !== e.value) {
        newAssigneeAgent.push(agent)
      }
    })
    // console.log(newAssigneeAgent, 'new')
    setAssignArray(newAssigneeAgent)
  }
  const checkAllServices = (e: any) => {
    if (e.target.checked) {
      setAllCheckBoxes(true)
      handleCheck()
    } else {
      setAllCheckBoxes(false)
    }
  }

  const getAllAgents = useCallback(async () => {
    try {
      const response = await api.fetchAgents(token, country)

      let agentArray: any = []

      response.agents.filter(function (data: any) {
        agentArray.push({
          label: data.firstname + ' ' + data.lastname,
          value: data.id
        })

        return data
      })

      // console.log(agentArray)

      setAgents(agentArray)
    } catch (e) {}
  }, [token])

  const saveUserMethod = () => {
    setSaveUser(true)
  }

  const getAllLeads = useCallback(
    async (query, assigneeAgents, loanArgument) => {
      try {
        let cleanObject = Object.entries(query).reduce(
          (a: any, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        )

        cleanObject.is_loan_applicant = allCheckBoxes ? true : loanArgument

        // console.log(cleanObject, 'clean')

        const response = await api.fetchLeadsFromBulk(token, cleanObject)

        if (response.leads.length > 0) {
          bulkAgentTransfer(response.leads, assigneeAgents)
        } else {
          setSaveUser(false)
          dispatch(
            showSnackbar(
              'Sorry there are no leads to be re-assigned for your filter',
              'error'
            )
          )
        }
      } catch (e) {
        setSaveUser(false)
      }
    },
    [token]
  )

  const bulkAgentTransfer = useCallback(
    async (leads, assigneeAgents) => {
      try {
        let lead_ids = [] as any
        let agent_ids = [] as any

        leads.filter((lead: any) => {
          lead_ids.push(lead.id)
        })
        assigneeAgents.filter((agent: any) => {
          agent_ids.push(agent.id)
        })

        let bulkObject = {
          lead_ids,
          agent_ids
        }

        const bulkResponse = await api.bulkAgentTransfer(token, bulkObject)

        //   console.log(bulkResponse, 'bulk response')
        setSaveUser(false)
        setAssigneeAgents([])
        dispatch(showSnackbar('leads transfered successsfully', 'success'))
        //   history.push('/settings/agents')
      } catch (e) {
        setSaveUser(false)
      }
    },
    [token]
  )

  useEffect(() => {
    getAllAgents()
  }, [])
  useEffect(() => {
    if (saveUser) {
      if (!agentId || assigneeAgents.length < 1) {
        dispatch(
          showSnackbar(
            'Please select at least one agent and one assignee agent filter',
            'error'
          )
        )
        setSaveUser(false)
        return
      }

      // console.log(loan)
      // return
      let queries = {
        service: allCheckBoxes ? allServices : services.join(',').toString(),
        assigned_agent_id: agentId,
        last_seen_start_date: fromDate,
        last_seen_end_date: endDate,
        status: status,
        country: country,
        page_size: 100,
        is_loan_applicant: allCheckBoxes ? true : loan
      }
      getAllLeads(queries, assigneeAgents, loan)
    }
  }, [saveUser])

  return (
    <WithSideMenu>
      <SettingsContainer>
        <SettingsNavDummy />
        <SettingsMenu />
        <ContentWrap>
          <div
            style={{
              background: '#F6F7FB',
              minHeight: '100vh',
              padding: '1em 1.5em'
            }}
          >
            <Link
              to="/settings/agents"
              style={{ marginLeft: '-7px' }}
              className={`${classes.headerSection} ${classes.backBtn}`}
            >
              <ChevronLeftIcon />
              {t('Agents')}
            </Link>
            <div style={{ margin: '1rem 0rem' }}>
              <span style={{ fontWeight: 'bold', fontSize: '22px' }}>
                {t('Bulk Assign')}
              </span>
            </div>

            <div
              style={{
                background: '#fff',
                minHeight: '85vh',
                borderRadius: '10px',
                padding: '2em',
                border: '1px solid #E8E8E8'
              }}
            >
              <p style={{ fontSize: '18px', color: '#003049' }}>
                {t('selectService')}
              </p>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  minHeight: '6em',
                  marginRight: '40rem'
                }}
              >
                <div>
                  <Checkbox
                    checked={allCheckBoxes}
                    onChange={(e) => checkAllServices(e)}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                  <span
                    onClick={() => checkAllCheckBoxes()}
                    style={{ cursor: 'pointer' }}
                  >
                    {' '}
                    {t('allServices')}
                  </span>
                </div>

                {showCheck ? (
                  <>
                    <div
                      className={allCheckBoxes ? classes.fadeCheckBoxes : ''}
                    >
                      <Checkbox
                        onChange={(e) => checkOtherService(e, 'BUY_CAR')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <span>{t('buyCar')}</span>
                    </div>
                    <div
                      className={allCheckBoxes ? classes.fadeCheckBoxes : ''}
                    >
                      <Checkbox
                        onChange={(e) => checkOtherService(e, 'TEST_DRIVE_CAR')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <span>{t('testDriveCar')}</span>
                    </div>
                    <div
                      className={allCheckBoxes ? classes.fadeCheckBoxes : ''}
                    >
                      <Checkbox
                        onChange={(e) => checkOtherService(e, 'FIX_CAR')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />

                      <span>{t('Fix')}</span>
                    </div>
                    <div
                      className={allCheckBoxes ? classes.fadeCheckBoxes : ''}
                    >
                      <Checkbox
                        checked={loan}
                        onChange={(e) => checkLoan(e)}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <span>{t('Loan')}</span>
                    </div>
                    <div
                      className={allCheckBoxes ? classes.fadeCheckBoxes : ''}
                    >
                      <Checkbox
                        onChange={(e) => checkOtherService(e, 'BUY_TRUCK')}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />

                      <span>{t('buyTruck')}</span>
                    </div>
                    <div
                      className={allCheckBoxes ? classes.fadeCheckBoxes : ''}
                    >
                      <Checkbox
                        onChange={(e) => e}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <span>{t('sellCar')}</span>
                    </div>
                    <div
                      className={allCheckBoxes ? classes.fadeCheckBoxes : ''}
                    >
                      <Checkbox
                        onChange={(e) => e}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                      <span>{t('testDriveTruck')}</span>
                    </div>
                  </>
                ) : null}
              </div>

              <p
                style={{ fontSize: '18px', color: '#003049', marginTop: '2em' }}
              >
                {t('Assigned Agent')}
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginRight: '30rem'
                }}
              >
                <div style={{ flexBasis: '48%', marginBottom: '1rem' }}>
                  <div style={{ marginBottom: '7px' }}>
                    <span style={{ color: '#343861' }}>{t('searchAgent')}</span>
                  </div>
                  <div>
                    <ReactSelect
                      placeholder={t('selectAgent')}
                      options={agents}
                      onChange={handleAgentChange}
                    />
                  </div>
                </div>
                <div style={{ flexBasis: '48%' }}>
                  <div style={{ marginBottom: '7px' }}>
                    <span style={{ color: '#343861' }}>{t('leadStatus')}</span>
                  </div>
                  <div>
                    <select
                      placeholder={t('selectStatus')}
                      onChange={(e) => saveStatus(e)}
                      value={status}
                      style={{
                        minWidth: '100%',
                        borderRadius: '3px',
                        height: '40px',
                        border: '1px solid rgba(49, 53, 91, 0.29)'
                      }}
                    >
                      <option value=""></option>
                      <option value="COLD">{t('Cold leads')}</option>
                      <option value="HOT">{t('Hot leads')}</option>
                      <option value="VERY_HOT">{t('Very hot leads')}</option>
                      <option value="FINANCING">{t('Loans')}</option>

                      <option value="SCHEDULED_INSPECTION">
                        {t('scheduledInspection')}
                      </option>
                      <option value="ENQUIRY">{t('enquiry')}</option>
                      <option value="FIRST_CONTACT">
                        {t('notInterested')}
                      </option>
                      <option value="NOT_INTERESTED">
                        {t('notInterested')}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <p
                style={{
                  fontSize: '18px',
                  color: '#003049',
                  marginTop: '.5em'
                }}
              >
                {t('duration')}:
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginRight: '30rem'
                }}
              >
                <div style={{ flexBasis: '48%', marginBottom: '1rem' }}>
                  <div style={{ marginBottom: '7px' }}>
                    <span style={{ color: '#343861' }}>{t('from')}</span>
                  </div>
                  <div>
                    <input
                      type="date"
                      placeholder={t('searchAgent')}
                      onChange={(e) => saveFromDate(e)}
                      value={fromDate}
                      style={{
                        minWidth: '100%',
                        borderRadius: '3px',
                        height: '40px',
                        border: '1px solid rgba(49, 53, 91, 0.29)',
                        padding: '1em'
                      }}
                    />
                  </div>
                </div>
                <div style={{ flexBasis: '48%' }}>
                  <div style={{ marginBottom: '7px' }}>
                    <span style={{ color: '#343861' }}>{t('to')}</span>
                  </div>
                  <div>
                    <input
                      type="date"
                      onChange={(e) => saveToDate(e)}
                      value={endDate}
                      style={{
                        minWidth: '100%',
                        borderRadius: '3px',
                        height: '40px',
                        border: '1px solid rgba(49, 53, 91, 0.29)',
                        padding: '1em'
                      }}
                    />
                  </div>
                </div>
              </div>
              <p
                style={{
                  fontSize: '18px',
                  color: '#003049',
                  marginTop: '.5em'
                }}
              >
                {t('Assignee agent')}
              </p>

              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginRight: '30rem'
                }}
              >
                <div style={{ flexBasis: '48%', marginBottom: '2rem' }}>
                  <div>
                    <ReactSelect
                      placeholder={t('selectAgent')}
                      options={assignArray}
                      onChange={saveAssigneeAgents}
                    />

                    <div>
                      {assigneeAgents?.map((agent: any) => (
                        <div
                          style={{
                            margin: '5px 0px',
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <span style={{}}>{agent.name}</span>
                          <span
                            style={{ color: '#C30000', cursor: 'pointer' }}
                            onClick={() => removeAgent(agent)}
                          >
                            {t('remove')}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div style={{ flexBasis: '20%', marginBottom: '2rem' }}>
                  {saveUser ? (
                    <LoaderCover>
                      <CircularProgress size={20} />
                    </LoaderCover>
                  ) : (
                    <div>
                      <div
                        className={classes.saveBtn}
                        onClick={() => saveUserMethod()}
                      >
                        {t('save')}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ContentWrap>
      </SettingsContainer>
    </WithSideMenu>
  )
}

export default LoanDetails
