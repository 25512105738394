import styled from 'styled-components'

export const StyledSimilarCar = styled.div`
  .similarCarsWrapper {
    background: #ffffff;
    border-radius: 10px;
    /* padding: 20px 20px 20px 20px; */
    padding: 20px 0px 20px 20px;
    position: relative;

    .title {
      color: #30355b;
      font-weight: bolder;
      font-size: 12px;
    }

    .slickSlider {
      .slick-slider {
        width: calc(100vw - 580px);
        /* width: calc(100vw - 556px); */
        /* width: 61vw; */
      }
      .slick-prev,
      .slick-next {
        font-size: 0;
        line-height: 0;
        position: absolute;
        top: 120%;
        display: block;
        width: 72px;
        height: 32px;
        padding: 0;
        transform: translate(0, -50%);
        cursor: pointer;
        color: transparent;
        outline: none;
        background: #fff;
        border: 1px solid #30345e;
        box-sizing: border-box;
        border-radius: 4px;
      }
      .slick-prev {
        left: 40%;
      }

      .slick-next {
        right: 40%;
      }
      /* .slick-prev:before {
        content: '← Prev';
      }
      .slick-next:before {
        content: 'Next →';
      } */
      .slick-prev:before {
        content: '←';
      }
      .slick-next:before {
        content: '→';
      }
      .slick-prev:before,
      .slick-next:before {
        font-family: 'slick';
        font-size: 20px;
        line-height: 1.7;
        opacity: 1;
        color: #30345e;
        font-weight: 600;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    .sliderWrapper {
      width: 184px;
      .carImage {
        width: 184px;
        height: 94px;
        background: aliceblue;
        overflow: hidden;
        border-radius: 6px;
      }
      .carName {
        color: #30355b;
        font-weight: 600;
        font-size: 12px;
      }
      .price,
      .rate {
        font-weight: bold;
        font-size: 14px;
        color: #30355b;
      }
      .distance,
      .location {
        font-weight: 300;
        font-size: 10.2799px;
        color: #30355b;
      }
      .phone {
        font-size: 12px;
        color: #30355b;
        letter-spacing: 0px;
      }
      button {
        min-width: 184px;
        width: auto;
        height: 30px;
        background: #30355b;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        color: #fff;
        font-weight: 500;
        font-size: 12px;
      }
    }
  }
`
