import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'

export const useTlStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: '1.5rem',
      transition: '400ms',
      height: 'auto',
      overflow: 'hidden'
    },
    title: {
      color: '#A1AACE',
      fontWeight: 800
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer'
    },
    collapse: {
      height: '2rem',
      transition: '400ms'
    },
    rotate: {
      transform: 'rotate(-90deg)',
      transition: '400ms'
    },
    dropdown: {
      padding: 0,
      marginRight: '1rem',
      transition: '400ms',
      '&:hover': {
        background: 'transparent'
      }
    },
    noTask: {
      textAlign: 'center',
      color: '#A1AACE',
      fontWeight: 800,
      width: '100%',
      marginTop: '1.875rem'
    },
    cardWrapper: {
      maxWidth: '48.25rem',
      marginTop: '1.2rem',
      background: '#fff',
      border: '1px solid #E8E9ED',
      borderRadius: 4,
      padding: '1rem 0.875rem',
      borderLeft: '7px solid #00A010',
      position: 'relative',
    },
    cardWrapperInner: {
      display: 'flex'
    },
    iconWrapper: {
      width: '2rem'
    },
    cardTitle: {
      fontWeight: 800,
      fontSize: '0.875rem',
      marginBottom: '0.625rem'
    },
    capitalize: {
      textTransform: 'capitalize'
    },
    cardStatus: {
      color: '#00A010',
      fontWeight: 800,
      fontSize: '0.75rem',
      marginBottom: '0.5rem'
    },
    textBlue : {
      color: '#4B9FF2'
    },
    cardStatusText: {
      color: '#A1AACE',
      marginLeft: '1.125rem',
      fontWeight: 800,
      fontSize: '0.75rem'
    },
    cardNoteTitle: {
      color: '#A1AACE',
      fontWeight: 800,
      marginBottom: '0.375rem',
    },
    cardNote: {
      color: '#0A1133',
      fontWeight: 500,
      marginBottom: '1.25rem',
      border: '1px solid #CAD0D9',
      padding: '.5rem',
      borderRadius: '0.25rem'
    },
    menuWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end'
    },
    label: {
      color: '#0A1133',
      fontWeight: 800,
      margin: '.3rem 0'
    },
    text: {
      marginLeft: '2rem',
      marginTop: '1rem'
    },
    searchWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },
    searchInput: {
      borderRadius: '2rem',
      width: '90%',
      background: '#fff',
      paddingLeft: '1rem',
      paddingRight: '2rem',
      border: '1px solid #DEE1ED'
    },
    underlined: {
      '&:hover': {
        border: 'none'
      },
      '&:before': {
        border: 'none'
      }
    },
    sortBtn: {
      fontSize: '0.75rem',
      color: '#A1AACE',
      textAlign: 'center',
      fontWeight: 500
    },
    line: {
      position: 'absolute',
      background: '#A1AACE',
      height: '1.3rem',
      left: '1.3rem',
      width: '0.25rem',
      top: '-1.3rem',
    },
    carCover:{
      padding:'10px',
      borderRadius:'5px',
      border:'1px solid #CAD0D9',
      marginLeft:'2rem',
      display:'flex'
    },
    carCoverChildImage:{
      flexBasis:'50%',
     
    },
    carCoverChild:{
      flexBasis:'50%',
     
    },
    carImage:{
      borderRadius:'5px',
     
    },
    carName:{
      fontWeight: 'bold',
      fontSize:'15px'
      
     },
     carText: {
     
      fontSize:'14px',
      paddingTop:'5px',
     
    },
  })
)
