import { connect } from 'react-redux'
import ResetPasswordComponent from './ResetPassword'
import { getIsAuth } from '../../../store/auth/selectors'
import { resetAccountPassword } from '../../../store/auth/actions'
import { StateProps, DispatchProps } from './types'

export const mapStateToProps = (state: Object) => ({
  isAuth: getIsAuth(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  resetPassword: (password: string, history: Object) => {
    return dispatch(resetAccountPassword(password, history))
  }
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordComponent)
