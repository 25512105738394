import React from 'react'
import styled from 'styled-components'
import ChatI from '../../images/conversation-sync-2.svg'
import { useTranslation } from 'react-i18next'

const EmptyConversationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100vh;
  background: ${({ theme }) => theme.palette.neutral.ghostWhite};
  color: ${({ theme }) => theme.palette.neutral.grayChateau};
`

const EmptyConversationText = styled.p`
  font-size: ${({ theme }) => theme.font.size.H6};
  font-weight: 600;
  width: 250px;
  text-align: center;
  padding: 0;
  margin: 0;
`

const ChatIcon = styled.div`
  height: 36px;
  width: 36px;
  margin-bottom: 15px;
  background-image: url(${ChatI});
  background-repeat: no-repeat;
`

export default function EmptyConversation() {
  const { t } = useTranslation()

  return (
    <EmptyConversationWrapper>
      <ChatIcon />
      <EmptyConversationText>
        {t('acceptConversationQueue')}.
      </EmptyConversationText>
    </EmptyConversationWrapper>
  )
}
