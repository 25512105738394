import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import TextInput from '../../../../components/TextField'
import {
  StyledTabs,
  StyledAppBar,
  StyledTab,
  StyledBox,
  DetailItemTitle,
  DetailItemValue,
  DetailItemPhoneIcon,
  DetailItem,
  DetailItemEmailIcon,
  LeadDetailsSectionTitle
} from '../styles'
import {
  NameLane,
  ProfileName,
  UserStatus,
  EditPencil,
  NameInputs,
  CheckMark,
  DetailItemSeenIcon,
  DetailItemMapIcon,
  DetailItemTCalendarIcon,
  DetailItemCarIcon,
  DetailItemTimeIcon,
  CreateUser,
  TabTitle
} from './styles'
import { leadFromStore } from '../../../../store/leads/selectors'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

type TabPanelProps = {
  children?: React.ReactChild | React.ReactChild[]
  value: number
  index: number
}

interface ServiceInterest {
  name: string
  date: string
  time: string
  location: string
  vehicle: string
}

type DetailsProps = {
  details: {
    name: string
    status: string
    firstName: string
    lastName: string
    email: string
    phone: string
    lastSeen: string
    firstSeen: string
    source: string
    ipLocation: {
      region: string
      city: string
      countryName: String
    }
  }
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  updateDetails: () => void
  firstName: undefined | string
  lastName: undefined | string
  userId: string
  linkUser: () => void
  serviceInterests: ServiceInterest[]
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <StyledBox p={3}>
          <Typography>{children}</Typography>
        </StyledBox>
      )}
    </div>
  )
}

const getServiceInterests = (serviceInterests: any) => {
  return serviceInterests.map((interest: any) => {
    return (
      <>
        <TabTitle>{interest.name}</TabTitle>
        <DetailItem>
          <DetailItemTCalendarIcon />
          <DetailItemTitle>Date</DetailItemTitle>
          <DetailItemValue>{interest.date}</DetailItemValue>
        </DetailItem>
        <DetailItem>
          <DetailItemTimeIcon />
          <DetailItemTitle>Time</DetailItemTitle>
          <DetailItemValue>{interest.time}</DetailItemValue>
        </DetailItem>
        <DetailItem>
          <DetailItemMapIcon />
          <DetailItemTitle>Location</DetailItemTitle>
          <DetailItemValue>{interest?.location?.name}</DetailItemValue>
        </DetailItem>
        <DetailItem>
          <DetailItemCarIcon />
          <DetailItemTitle>Vehicle</DetailItemTitle>
          <DetailItemValue>{interest.vehicle}</DetailItemValue>
        </DetailItem>
      </>
    )
  })
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

const Details = ({
  details,
  handleChange,
  firstName,
  lastName,
  userId,
  updateDetails,
  serviceInterests,
  linkUser
}: DetailsProps) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const [showFields, setShowFields] = React.useState({
    nameField: false,
    emailField: false
  })
  const {
    name,
    email,
    phone,
    lastSeen,
    firstSeen,
    source,
    ipLocation
  } = details

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue)
  }

  const showEditMode = (field: string) => {
    if (field === 'nameField') {
      setShowFields({
        nameField: !showFields.nameField,
        emailField: false
      })
    }
    if (field === 'emailField') {
      setShowFields({
        nameField: false,
        emailField: !showFields.emailField
      })
    }
  }

  const updateLeadDetails = (field: string) => {
    showEditMode(field)
    updateDetails()
  }
  const leadUser: any = useSelector(leadFromStore)
  const inputProps = {
    width: '110px',
    height: '30px',
    noBgColor: true,
    borderColor: '#C1E0FF',
    marginTop: '1px'
  }

  const { t } = useTranslation()

  return (
    <div>
      <LeadDetailsSectionTitle>{t('leadDetails')}</LeadDetailsSectionTitle>
      <div className={classes.root}>
        <StyledAppBar position="static">
          <StyledTabs
            value={value}
            onChange={handleTabChange}
            aria-label="simple tabs"
            TabIndicatorProps={{
              style: { background: '#4B9FF2', height: '2px' }
            }}
          >
            <StyledTab label={t('PROFILE')} {...a11yProps(0)} />
            <StyledTab label={t('TRANSACTION OVERVIEW')} {...a11yProps(1)} />
            <StyledTab label={t('APPOINTMENTS')} {...a11yProps(2)} />
            <StyledTab label={t('FAVOURITES')} {...a11yProps(2)} />
          </StyledTabs>
        </StyledAppBar>
        <TabPanel value={value} index={0}>
          <NameLane>
            {showFields.nameField ? (
              <>
                <NameInputs>
                  <TextInput
                    name="firstname"
                    type="text"
                    value={firstName}
                    onInputChange={handleChange}
                    {...inputProps}
                  />
                  <TextInput
                    name="lastname"
                    type="text"
                    value={lastName}
                    onInputChange={handleChange}
                    {...inputProps}
                  />
                </NameInputs>
                <CheckMark onClick={() => updateLeadDetails('nameField')} />
              </>
            ) : (
              <ProfileName>
                {name} <EditPencil onClick={() => showEditMode('nameField')} />
              </ProfileName>
            )}
            {!userId && (
              <>
                <UserStatus>{t('Unregistered user')}</UserStatus>{' '}
                <CreateUser onClick={linkUser}>{t('Create User')}</CreateUser>
              </>
            )}
          </NameLane>
          <DetailItem>
            <DetailItemPhoneIcon />
            <DetailItemTitle>{t('phone')}</DetailItemTitle>
            <DetailItemValue>{phone}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemEmailIcon />
            <DetailItemTitle>{t('email')}</DetailItemTitle>
            <DetailItemValue>{email}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemSeenIcon />
            <DetailItemTitle>{t('firstSeen')}</DetailItemTitle>
            <DetailItemValue>{firstSeen}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemSeenIcon />
            <DetailItemTitle>{t('lastSeen')}</DetailItemTitle>
            <DetailItemValue>{lastSeen}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemSeenIcon />
            <DetailItemTitle>{t('source')}</DetailItemTitle>

            {leadUser.serviceInterest && leadUser.serviceInterest[0].source ? (
              <DetailItemValue>
                {leadUser.serviceInterest[0].source}
              </DetailItemValue>
            ) : (
              <DetailItemValue>{source}</DetailItemValue>
            )}
          </DetailItem>
          <DetailItem>
            <DetailItemMapIcon />
            <DetailItemTitle>{t('Region')}</DetailItemTitle>
            <DetailItemValue>{ipLocation?.region}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemMapIcon />
            <DetailItemTitle>{t('City')}</DetailItemTitle>
            <DetailItemValue>{ipLocation?.city}</DetailItemValue>
          </DetailItem>
          <DetailItem>
            <DetailItemMapIcon />
            <DetailItemTitle>{t('country')}</DetailItemTitle>
            <DetailItemValue>{ipLocation?.countryName}</DetailItemValue>
          </DetailItem>
        </TabPanel>
        <TabPanel value={value} index={1}>
          {`${t('TRANSACTION OVERVIEW')}`}
        </TabPanel>
        <TabPanel value={value} index={2}>
          {getServiceInterests(serviceInterests)}
        </TabPanel>
        <TabPanel value={value} index={3}>
          {`${t('FAVOURITES')}`}
        </TabPanel>
      </div>
    </div>
  )
}

export default Details
