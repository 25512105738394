import { IRootState } from '../reducer.interface'

export const getAllStates = (state: IRootState) =>
  state.location.state.states.map(({ name, id }) => ({
    label: name,
    value: id.toString()
  }))
export const getAllCities = (state: IRootState) =>
  state.location.city.cities.map(({ name }) => ({
    label: name,
    value: name
  }))
export const getAllHomeCities = (state: IRootState) =>
  state.location.homeCity.cities.map(({ name }) => ({
    label: name,
    value: name
  }))
