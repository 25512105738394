import styled from 'styled-components'
import Button from '@material-ui/core/Button'

export const AccountPrefContainer = styled.div`
  margin-top: 21px;
  margin-right: 24px;
  padding-left: 24px;
  border-radius: 5px;
  padding-bottom: 41px;
  border: 1px solid rgba(215, 225, 234, 0.4);
`

export const SectionTitle = styled.div`
  width: 100%;
  margin-top: 24px;
  height: 27px;
  line-height: 27px;
  margin-bottom: 22px;
  font-size: ${({ theme }) => theme.font.size.H5};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.palette.common.black};
`

export const PhotoUploadSection = styled.div`
  margin-bottom: 24px;
`

export const UploadNotice = styled.div`
  color: ${({ theme }) => theme.palette.neutral.grayTint};
  font-size: ${({ theme }) => theme.font.size.sm};
  height: 18px;
  line-height: 18px;
  margin-bottom: 24px;
  margin-top: 7px;
`

export const SaveButton = styled(Button)<{ deactivated?: boolean }>`
  width: 240px;
  height: 36px;
  text-transform: capitalize;
  border-radius: 5px;
  line-height: 21px;
  margin-top: 8px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme, deactivated }) =>
    deactivated
      ? theme.palette.neutral.cyanBluishGray
      : theme.palette.neutral.vividBlue};

  &:hover {
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.neutral.vividBlue};
  }
`

export const InputLane = styled.div`
  margin-bottom: 16px;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.neutral.pattensBlue};
  margin: 24px 0px;
`

export const AvatarUpload = styled(Button)<{ deactivated?: boolean }>`
  width: 143px;
  height: 29px;
  text-transform: none;
  border-radius: 5px;
  line-height: 21px;
  color: ${({ theme }) => theme.palette.common.white};

  background-color: ${({ theme, deactivated }) =>
    deactivated
      ? theme.palette.neutral.cyanBluishGray
      : theme.palette.neutral.vividBlue};

  &:hover {
    color: ${({ theme }) => theme.palette.common.white};
    background-color: ${({ theme }) => theme.palette.neutral.vividBlue};
  }
`

export const FileInput = styled.input`
  display: none;
`

export const PasswordMatchNotice = styled.div`
  color: red;
  font-size: ${({ theme }) => theme.font.size.xs};
  margin-top: 5px;
`

export const SelectImage = styled.label`
  width: 60px;
  height: 60px;
  position: relative;
  opacity: 0;
  padding: 25px 30px;
  bottom: 40px;
`

export const UploadTooltip = styled.div`
  display: none;
  position: relative;
  left: 54px;
  bottom: 50px;
  width: 100px;
  font-size: ${({ theme }) => theme.font.size.sm};
`

export const Avatar = styled.div`
  width: 60px;
  height: 60px;

  margin-bottom: 15px;

  & img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }

  &:hover ${UploadTooltip} {
    display: block;
  }
`
