import React, { useState } from 'react'
import { Modal, Button, Form, Input, Select, Spin } from 'antd'
import { StyledDocumentModal } from './styles'
import './globalstyles.css'
import { ChevronBack } from './svgs'
import api from '../../../utils/api'
import { getUserToken } from '../../../store/auth/selectors'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

interface IAddModal {
  visible: any
  setVisible: any
  refetch: any
}
interface IDetailParam {
  id: string
}

export default function AddedDocModal({
  visible,
  setVisible,
  refetch
}: IAddModal) {
  const [step, setStep] = useState<number>(0)
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const handleSubmit = async (values: any) => {
    const data = { ...values, loan_id: id }

    try {
      const response = await api.requestDocument(token, data)
      if (response) {
        dispatch(
          showSnackbar('Additional document requested successfully', 'success')
        )
        setVisible(false)
      }
    } catch (err) {}
    refetch()
  }

  const handleCancel = () => {
    setVisible(false)
  }

  return (
    <Modal closable={false} visible={visible} width="440px" footer={null}>
      <StyledDocumentModal>
        {step == 0 && (
          <div className="modalWrapper">
            <div className="title mb-3">{t('requestAdditionalDocument')}</div>
            <Form
              layout="vertical"
              requiredMark={false}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="name"
                label={
                  <div className="d-flex labelWrapper">
                    <div className="me-2 label">{t('documentName')}</div>
                    <div className="addNew d-none" onClick={() => setStep(1)}>
                      +Add New
                    </div>
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: t('pleaseInputDocument')
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item name="description" label={t('Description(Optional)')}>
                <Input.TextArea rows={4} />
              </Form.Item>

              <div className="d-flex justify-content-between">
                <Button className="cancelButton" onClick={handleCancel}>
                  {t('Cancel')}
                </Button>
                <Button className="saveButton" htmlType="submit">
                  {t('save')}
                </Button>
              </div>
            </Form>
          </div>
        )}

        {step !== 0 && <AddNew setStep={setStep} setVisible={setVisible} />}
      </StyledDocumentModal>
    </Modal>
  )
}

function AddNew({ setStep, setVisible }: any) {
  const handleSubmit = (values: any) => {
    setTimeout(() => {
      // console.log('Finish:', values)
      setVisible(false)
    }, 2000)
  }

  const handleCancel = () => {
    // console.log('Clicked cancel button')
    setVisible(false)
  }

  return (
    <StyledDocumentModal>
      <div className="addNewWrapper">
        <div
          className="back d-flex align-items-center mb-2"
          onClick={() => setStep(0)}
        >
          {ChevronBack} &nbsp;&nbsp;&nbsp;Back
        </div>
        <div className="title mb-3">Add New Document</div>
        <Form layout="vertical" requiredMark={false} onFinish={handleSubmit}>
          <Form.Item
            name="document"
            label="Document Name"
            rules={[
              {
                required: true,
                message: 'Please input the document name'
              }
            ]}
          >
            <Input size={'large'} />
          </Form.Item>
          <Form.Item name="description" label="Description(Optional)">
            <Input.TextArea rows={4} maxLength={100} />
          </Form.Item>

          <div className="d-flex justify-content-between">
            <Button className="cancelButton" onClick={handleCancel}>
              Cancel
            </Button>
            <Button className="saveButton" htmlType="submit">
              Save
            </Button>
          </div>
        </Form>
      </div>
    </StyledDocumentModal>
  )
}
