import React from 'react'
import Rating from '@material-ui/lab/Rating'

type RatingProps = {
  value: number
}

const SimpleRating = ({ value }: RatingProps) => {
  return <Rating name="read-only" value={value} size="small" precision={0.1} readOnly />
}

export default SimpleRating
