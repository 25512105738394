import { connect } from 'react-redux'
import Leads from './Leads'
import {
  getLeads,
  getTotalLeads,
  getPrevState
} from '../../store/leads/selectors'
import {
  fetchLeads,
  savePrevState,
  createLead,
  saveAssignedAgent,
  saveMultiAgents,
  fetchAgents,
  sendAssignedLeadsToAgent,
  emptyAssignedList,
  saveBankDealerSearch
} from '../../store/leads/actions'
import { isFetching } from '../../store/isFetching/selectors'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  isAdmin,
  isManager,
  getUserToken,
  getUserLocation
} from '../../store/auth/selectors'

import {
  getAllAgents,
  bankDealerSearch,
  getLeadIdsToAgent,
  getBanks
} from '../../store/leads/selectors'

import { StateProps, DispatchProps, Queries, State, LeadDetails } from './types'

export const mapStateToProps = (state: Object) => ({
  leads: getLeads(state),
  total: getTotalLeads(state),
  isFetching: isFetching(state, 'leads'),
  selectedCountry: getSelectedCountry(state),
  currency: getSelectedCountryCurrency(state),
  agentId: getUserIdIfAgent(state),
  agents: getAllAgents(state),
  bankDealerString: bankDealerSearch(state),
  isAdminUser: isAdmin(state),
  isManagerUser: isManager(state),
  prevState: getPrevState(state),
  agentIds: getLeadIdsToAgent(state),
  bankNames: getBanks(state),
  token: getUserToken(state),
  location: getUserLocation(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  getAllLeads: (queries: Queries) => dispatch(fetchLeads(queries)),
  savePrevState: (state: State) => dispatch(savePrevState(state)),
  saveMultiAgents: (agentIds: any) => dispatch(saveMultiAgents(agentIds)),
  createLead: (details: LeadDetails, queries: Queries) =>
    dispatch(createLead(details, queries)),
  getAgents: () => dispatch(fetchAgents()),
  sendAssignedLeadsToAgent: () => dispatch(sendAssignedLeadsToAgent()),
  emptyAssignedList: () => dispatch(emptyAssignedList()),
  saveAssignedAgent: (agent: any) => dispatch(saveAssignedAgent(agent)),
  saveBankDealerSearch: (bankNameorDealer: any) =>
    dispatch(saveBankDealerSearch(bankNameorDealer))
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Leads)
