import styled from 'styled-components'

export const StyledResponsesTable = styled.div`
  .responsesTableWrapper {
    background: #ffffff;
    border-radius: 10px;
    min-height: 346px;
    height: auto;
    padding: 20px;

    .header {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
    }

    th {
      color: #767676;
      font-size: 13px;
      background: #eff0f6;
      box-shadow: 0px 1px 0px #dadbe4;
    }
    tbody td {
      color: #30355b;
      font-size: 13px;
    }
  }
  .schedule {
    background: #ffb619;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    min-width: 187px;
    width: auto;
    height: 44px;
    color: #30355b;
    font-weight: 600;
    font-size: 14px;
  }

  .tableButton {
    color: #30355b;
    font-weight: 500;
    font-size: 12px;
    background: rgba(62, 123, 250, 0.1);
    border-radius: 85.5978px;
  }
  .sourcing__download-btn {
    background-color: rgba(62, 123, 250, 0.1);
    padding: 8px 16px;
    border-radius: 100px;
    width: fit-content;
    margin-right: 8px;
    img {
      margin-left: 12px;
    }
  }
  .badge {
    &.--passed {
      color: #009c3f;
      background: rgba(0, 156, 63, 0.1);
      font-weight: bold;
      padding: 6px 12px;
      border-radius: 100px;
      font-size: 18px;
      margin: auto 0 12px;
    }
  }
  .generate__pfi-table {
    thead {
      background-color: #30345e;
      color: #fff;
      td {
        padding: 8px 20px;
      }
    }
  }

  .sourcing__status-tag {
    text-transform: capitalize;
    padding: 4px 14px;
    border-radius: 100px;
    &.--pending {
      color: #e19a00;
      background: rgba(255, 182, 25, 0.1);
    }
    &.--pass {
      color: #00692a;
      background: rgba(0, 105, 42, 0.1);
    }
    &.--rejected {
      color: #9c0000;
      background: rgba(156, 0, 0, 0.1);
    }
    &.--scheduled {
      color: #a3610e;
      background: #fe981740;
    }
  }
  .sourcing__unv-modal-image-wrapper {
    .--images {
      width: 60%;
    }
  }
  .sourcing__create-btn {
    background: #ffb618;
    height: fit-content;
    padding: 10px;
    margin: auto 0;
    border-radius: 3px;
    cursor: pointer;
    span {
      margin: auto;
    }
  }
  .sourcing {
    .sourcing__all-table {
      tbody {
        tr {
          cursor: pointer;
        }
      }
    }
  }
  .sourcing__ppi-tag {
    text-transform: capitalize;
    padding: 4px 14px;
    border-radius: 100px;
    width: fit-content;
    margin: auto;
    margin-bottom: 4px;

    &.--pending {
      color: #e19a00;
      background: rgba(255, 182, 25, 0.1);
    }
    &.--pass {
      color: #00692a;
      background: rgba(0, 105, 42, 0.1);
    }
    &.--rejected {
      color: #9c0000;
      background: rgba(156, 0, 0, 0.1);
    }
    &.--scheduled {
      color: #a3610e;
      background: #fe981740;
    }
  }
  .sourcing__workshop-dropDown-card {
    .name {
      font-weight: 600;
    }
  }
  .sourcing__selected-workshop {
    box-shadow: 0px 0.776px 4px #0000003d;
    border-radius: 5px;
    padding: 8px;
    justify-content: space-between;
    .info {
      .name {
        font-weight: 600;
      }
    }
    .remove-btn {
      margin: auto 0;
      color: red;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .sourcing__close-modal {
    justify-content: flex-end;
    svg {
      height: 20px;
      width: 20px;
    }
  }
  .sourcing__filter-btn {
    border: 1px solid #30355b;
    border-radius: 5px;
    padding: 8px;
    cursor: pointer;
    span {
      margin: auto;
    }
    .btn-name {
      margin: auto;
    }
  }
  .sourcing__filter-overlay-dropdown {
    width: 40%;
    .--sourcing_filter-form {
      padding: 26px;
      border-radius: 8px;
    }
  }
  .sourcing__active-filter-tag {
    border-radius: 100px;
    background-color: #f2f2f0;
    margin: auto 8px auto;
    padding: 4px 16px;
    img {
      width: 12px;
      cursor: pointer;
    }
  }

  .generate_pfi-form {
    &.--visible-none {
      visibility: collapse;
    }
  }
  .sourcing__geberatePFI-remove {
    color: red;
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600;
    &.--disabled {
      color: grey;
      cursor: not-allowed;
    }
  }
  .sourcing__add-item-btn {
    color: #003049;
    background: #d6dae5;
    padding: 6px 8px;
    cursor: pointer;
  }
  .sourcing__generate-item-input {
    &.--error {
      border: 1px solid red;
    }
  }
`
