import styled from 'styled-components'
import SearchI from '../../images/search.svg'
import CreateLeadSvg from '../../images/create_lead.svg'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import AppBar from '@material-ui/core/AppBar'
import PersonIcon from '../../images/person_act.svg'
import CarIcon from '../../images/car_act.svg'
import PhoneIcon from '../../images/phone_act.svg'
import MailIcon from '../../images/mail_act.svg'
import ClockIcon from '../../images/clock.svg'
import SubMenuPic from '../../images/menu-vert.svg'

export const ActivityContainer = styled.div`
  width: 100%;
  padding: 0 20px;

  background-color: ${({ theme }) => theme.palette.neutral.ghostWhite};
`
export const TabParent = styled.div`
  width: 100%;
  padding: 30px 30px;
  min-height: 700px;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const LeadsContainer = styled.div`
  width: 100%;
  padding: 0 20px;
  background-color: ${({ theme }) => theme.palette.neutral.ghostWhite};
`

export const HideNoteDiv = styled.div`
  height: 95px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`
export const HideSaleFixNoteDiv = styled.div`
  height: 150px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`
export const ShowSaleFixNoteDivForPhone = styled.div`
  min-height: 80px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  overflow-y: scroll;
  &::-webkit-scrollbar {
    /* WebKit */
    width: 0;
    height: 0;
  }
`

export const LeadsTopSection = styled.div`
  width: 100%;
  height: 81px;
  display: flex;
`

export const LeadsPageTitile = styled.div`
  height: 36px;
  margin-top: 27px;
  margin-right: 24px;
  font-size: ${({ theme }) => theme.font.size.H4};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
`

export const SearchContainer = styled.div`
  width: 811px;
  height: 42px;
  box-sizing: border-box;
  margin-top: 24px;
  display: flex;
  background-color: ${({ theme }) => theme.palette.common.white};
  border: ${({ theme }) => `1px solid ${theme.palette.neutral.whisper}`};
  cursor: pointer;
`

export const SearchContentInactive = styled.span`
  height: 20px;
  line-height: 20px;
  margin-top: 11px;
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.palette.neutral.heather};
`

export const SearchIcon = styled.div`
  width: 16px;
  height: 16px;
  margin: 13px 19px 0px 18px;
  background-image: url(${SearchI});
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.palette.neutral.gray93};
`

export const CreateLeadBtn = styled.div`
  width: 156px;
  height: 40px;
  background-size: contain;
  background-image: url(${CreateLeadSvg});
  background-repeat: no-repeat;
  margin-top: 24px;
  margin-left: 16px;
  cursor: pointer;
`

export const NewCreateLeadBtn = styled.button`
  width: auto;
  padding: 0 10px 0 10px;
  height: 40px;
  margin-top: 24px;
  margin-left: 16px;
  border-radius: 5px;
  background-color: #ffcc5b;
  font-weight: 600;
  font-size: 14px;
  border: none;
  color: #31355b;
  cursor: pointer;
`

export const NewLeadBtn = styled.button`
  width: 130px;
  height: 40px;
  margin-top: 24px;
  margin-left: 16px;
  border-radius: 5px;
  background-color: #ffcc5b;
  font-weight: bolder;
  font-size: 11px;
  border: none;
  color: #31355b;
  cursor: pointer;
`

export const DataContainer = styled.div`
  background-color: #f6f7fb;
  padding: 20px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`
export const PopOverDiv = styled.div`
  min-height: 450px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  min-width: 700px;
  align-items: center;
`
export const SubMenuPicDiv = styled.div`
  width: 22px;
  height: 22px;

  background-image: url(${SubMenuPic});
  display: flex;
  justify-content: flex-end;

  cursor: pointer;
`

export const Separator = styled.div`
  border-top: 1px solid #f4f4f4b3;
`
export const NoteCover = styled.div`
  border: 1px solid #f4f4f4b3;
  padding: 10px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
`
export const SubMenu = styled.div`
  padding-left: 5px;
  cursor: pointer;
  padding: 10px;
  :hover {
    background: #f4f4f4b3;
  }
  &a {
    text-decoration: none;
  }
`
export const AssignNameDiv = styled.div`
  padding-left: 5px;
  border-bottom: 1px solid #f4f4f4b3;
  cursor: pointer;
  padding: 10px;
  max-width: 100px;
  min-width: 250px;
`
export const DateCover = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: -78px;
  z-index: 200;
  padding: 10px;
  padding-right: 30px;
  align-items: flex-end;
  position: absolute;
  max-width: 400px;
  right: 0px;
`
export const DetailParentForLead = styled.div`
  display: flex;

  margin-bottom: 5px;
  & div:last-child {
    flex-basis: 26%;
    display: flex;
    justify-content: flex-end;
  }
`
export const PaginationCover = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`
export const AssigneeeDiv = styled.div`
  display: flex;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0px 10px 0px;
  z-index: 3000;
  width: 100%;
  :hover {
    background-color: #f2f2f2;
  }
  &img {
    padding-right: 5px;
  }
`

export const FloatingFooter = styled.div`
  min-height: 70px;
  display: flex;
  position: sticky;
  align-items: center;
  bottom: 0px;
  justify-content: center;
  box-shadow: rgb(0 0 0 / 16%) 0px 2px 6px;
  padding: 5px;
  background-color: #fff;
`

export const DataContainerCover = styled.div`
  background-color: #fff;
  display: flex;
`

export const DataContainerActionFooter = styled.div`
  background-color: #fff;
  display: flex;
  padding-top: 10px;
  min-height: 92px;
  align-items: flex-end;
`
export const DataContainerChildWithActionCover = styled.div``
export const DataContainerChildWithAction = styled.div`
  flex-flow: column;
  min-height: 100px;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  flex-basis: 24%;
  margin-top: 10px;
  padding: 10px;
  display: flex;
`
export const LoaderCover = styled.div`
  min-height: 150px;
  background-color: #f6f7fb;
  width: 100%;
  padding: 10px;
  min-height: 239px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const NoDataDiv = styled.div`
  min-height: 550px;
  background-color: #f6f7fb;
  width: 100%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const DataContainerChild = styled.div`
  min-height: 140px;
  background-color: #fff;
  border-radius: 5px;
  margin-right: 10px;
  flex-basis: 24%;
  margin-top: 10px;
  padding: 10px;
  display: flex;
`
export const DataContainerChildInitialDiv = styled.div``
export const DataContainerChildLatterDiv = styled.div`
  flex-basis: 10%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`
export const DataContainerChildLatterDivAction = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-basis: 100%;
`
export const StyledTabs = styled(Tabs)`
  height: 57px;
  box-shadow: inset 0px -1px 0px #e8e9ed;
`

export const StyledTab = styled(Tab)`
  height: 57px;
  font-size: ${({ theme }) => theme.font.size.sm};
  min-width: 150px;
  padding: 0px 0px;
`

export const StyledAppBar = styled(AppBar)`
  background-color: ${({ theme }) => theme.palette.common.white};
  background-color: #fff;
`

export const CountBadge = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #6973a9;
  color: white;
  position: relative;
  margin-left: -17px;
  margin-right: 12px;
  margin-top: 20px;
  text-align: center;
  line-height: 16px;
  font-size: ${({ theme }) => theme.font.size.sm};
  font-weight: ${({ theme }) => theme.font.weight.bold};
`
export const DetailParent = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`
export const DetailParentWithInitial = styled.div`
  height: 20px;
  display: flex;
`
export const DetialInitial = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #fff;
  background: #f57046;
`
export const LeadName = styled.div`
  font-size: ${({ theme }) => theme.font.size.H5};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  display: flex;
  flex-basis: 70%;
`
export const LeadNameDetail = styled.div`
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: #8f99a6;

  &p {
    margin: 0px;
  }
`
export const DetailItemPersonIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${PersonIcon});
  background-repeat: no-repeat;
`
export const DetailItemCarIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${CarIcon});
  background-repeat: no-repeat;
`
export const DetailItemPhoneIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${PhoneIcon});
  background-repeat: no-repeat;
`
export const DetailItemMailIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${MailIcon});
  background-repeat: no-repeat;
`
export const DetailItemClockIcon = styled.div`
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: 4px;
  background-size: contain;
  background-image: url(${ClockIcon});
  background-repeat: no-repeat;
`
