export enum ELoansActionTypes {
  FETCH_LOAN_LEADS = 'FETCH_LOAN_LEADS',
  FETCH_FILTER_LOAN_LEADS = 'FETCH_FILTER_LOAN_LEADS',
  FETCH_LOAN_LEADS_SUCCESS = 'FETCH_LOAN_LEADS_SUCCESS'
}

export const fetchLoanLeads = (page?: number, agentId?:string) => ({
  type: ELoansActionTypes.FETCH_LOAN_LEADS,
  page
})
export const fetchFilterLoanLeads = (query?: any) => ({
  type: ELoansActionTypes.FETCH_FILTER_LOAN_LEADS,
  query
})
