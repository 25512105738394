import styled from 'styled-components'

export const StyledAddAgent = styled.div`
  background: #f6f7fb;
  min-height: 100vh;
  padding: 1em 1.5em;
`

export const FormContainer = styled.div`
  background: #fff;
  min-height: 85vh;
  border-radius: 10px;
  padding: 2em;
  border: 1px solid #e8e8e8;
`
export const FormWrapper = styled.div`
  .title-form {
    font-size: 14px;
    color: rgb(130, 133, 148);
  }
  header {
    font-size: 18px;
    color: rgb(0, 48, 73);
    margin-top: 2em;
    margin-bottom: 16px;
  }
  .ant-select-selector {
    height: 50px !important;
    border-radius: 5px !important;
  }
  .ant-select {
    height: 50px !important;
    border-radius: 5px !important;
  }
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding: 0;
    line-height: 45px;
    transition: all 0.3s;
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #003049;
    border-color: #003049;
  }

  form {
    width: 700px;
    label {
      color: rgb(52, 56, 97);
      font-size: 14px;
    }
    .ant-input {
      height: 50px;
      background: rgba(12, 40, 70, 0.01);
      border-radius: 5px;
    }
    .ant-picker {
      width: 100%;
      height: 50px;
      background: rgba(12, 40, 70, 0.01);
      border-radius: 5px;
    }
    button {
      color: rgb(0, 48, 73) !important;
      border: 0;
      display: flex;
      min-width: 200px;
      width: auto;
      background: #ffc107 !important;
      height: 48px;
      transition: 0.3s;
      align-items: center;
      border-radius: 5px;
      margin-top: 1.5rem;
      justify-content: center;
      font-weight: bolder;
    }
  }
`

export const ServiceContainer = styled.div`
  .title-wrapper {
    .service-title {
      font-size: 18px;
      color: rgb(0, 48, 73);
      margin-top: 1em;
      margin-bottom: 16px;
    }
    .select-services {
      color: rgb(52, 56, 97);
      margin-bottom: 7px;
    }
  }

  .service-check-container {
    animation-duration: 3s;
    animation-name: slidein;
    animation-iteration-count: 1;
    .service-check {
      position: relative;
      width: 168px;
      height: 45px;
      margin-right: 15px;
      margin-bottom: 15px;
      display: inline-block;
      box-sizing: border-box;
      border-radius: 7px;
    }
    .service-check div {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 25px;
      transition: 0.5s ease;
      border-radius: 7px;
      color: #003049;
      border: 1px solid #31355b;
    }
    .service-check input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;
    }
    input[type='checkbox']:checked ~ div {
      color: #fff;
      background: #003049;
    }
  }
`
