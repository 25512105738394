import { ELocationTypes } from './actions'

export interface IPagination {
  total: number
  currentPage: number
  pageSize: number
}
export interface IState {
  id: number
  name: string
}
export interface IStateRes {
  states: IState[]
  pagination: IPagination
}
export interface ICityRes {
  cities: IState[]
  pagination: IPagination
}
export interface IHomeCityRes {
  cities: IState[]
  pagination: IPagination
}
export interface ILocation {
  city: ICityRes
  state: IStateRes
  homeCity: IHomeCityRes
}

interface IGetState {
  type: ELocationTypes.GET_STATES_SUCCESS
  payload: IStateRes
}
interface IGetCity {
  type: ELocationTypes.GET_CITY_SUCCESS
  payload: ICityRes
}
interface IGetCityHome {
  type: ELocationTypes.GET_CITY_HOME_SUCCESS
  payload: ICityRes
}

export type TActions = IGetState | IGetCity | IGetCityHome
const initPagination = {
  total: 0,
  currentPage: 1,
  pageSize: 10
}
const initialState: ILocation = {
  state: { states: [], pagination: initPagination },
  city: { cities: [], pagination: initPagination },
  homeCity: { cities: [], pagination: initPagination }
}

const reducer = (state = initialState, action: TActions): ILocation => {
  switch (action.type) {
    case ELocationTypes.GET_CITY_SUCCESS: {
      return {
        ...state,
        city: action.payload
      }
    }
    case ELocationTypes.GET_CITY_HOME_SUCCESS: {
      return {
        ...state,
        homeCity: action.payload
      }
    }
    case ELocationTypes.GET_STATES_SUCCESS: {
      return {
        ...state,
        state: action.payload
      }
    }

    default:
      return state
  }
}

export default reducer
