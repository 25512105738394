import {
  Button,
  Grid,
  TextField,
  Typography,
  FormHelperText,
  InputBase,
  Box
} from '@material-ui/core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import dayjs from 'dayjs'
import RelativeTime from 'dayjs/plugin/relativeTime'
import { useTodoStyles } from './styles'
import phone from '../../../../../images/phone-forwarded.svg'
import box from '../../../../../images/box-dark.svg'
import bell from '../../../../../images/bell.svg'
import calendar from '../../../../../images/calendar-dark.svg'
import check from '../../../../../images/check.svg'
import calendarlight from '../../../../../images/calendar_1.svg'
import { IActivity } from '../../../../../store/events/reducer'
import FormFooter from '../FormFooter'
import {
  updateSchedule,
  fetchSchedule,
  updateReschedule
} from '../../../../../store/events/actions'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  isAgent,
  isFulfillmentAgent
} from '../../../../../store/auth/selectors'
import Select from 'react-select'
import CustomSelect from '../../../../../components/CustomizedSelect/Select'
import clsx from 'clsx'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import { getLocationOptions } from '../../../../../store/events/selectors'
import DeleteMenu from './DeleteMenu'

import { showVehicleModal } from '../../../../../store/modal/actions'
import {
  salesOfficersData,
  getLeadDetails,
  getUserTimeZone
} from '../../../../../store/leads/selectors'
import { activateUserIsFulfilmentAgent } from '../../../../../store/leads/actions'
import VehicleDetails from '../../ServiceInterest/VehicleDetails'
import { useTranslation } from 'react-i18next'

dayjs.extend(RelativeTime)

const mapper: any = {
  PHONE_CALL: {
    title: 'Phone call',
    icon: phone
  },
  APPOINTMENT: {
    title: 'Appointment',
    icon: calendar
  },
  REMINDER: {
    title: 'Reminder',
    icon: bell
  },
  STORE_VISIT: {
    title: 'Store visit',
    icon: box
  }
}

const validationSchema = Yup.object().shape({
  note: Yup.string().required('This field is required.')
})

const initialValues = {
  note: ''
}
interface IAuthor {
  firstname: string
  lastname: string
  id: string
}
interface IProps {
  activity: IActivity
}
const getAuthor = (data: string): IAuthor => {
  try {
    return JSON.parse(data)
  } catch (error) {
    return { firstname: '', lastname: '', id: '' }
  }
}

const showPreselectedValue = [
  {
    label: 'Car condition different from inspection report',
    value: 'Car condition different from inspection report'
  },
  { label: 'Not interested', value: 'Not interested' },
  { label: 'Price Changed', value: 'Price Changed' },
  { label: 'Show me more options', value: 'Show me more options' },
  { label: 'Low Budget', value: 'Low Budget' },
  { label: ' Car Loan needed', value: ' Car Loan needed' },
  { label: 'Ready to buy', value: ' Dealer was rude' },
  {
    label: ' Need more time - Proxy Inspection',
    value: ' Need more time - Proxy Inspection'
  },
  {
    label: '  Vehicle. was not. available',
    value: ' Vehicle. was not. available'
  },
  { label: 'Others', value: 'Others' }
]

const TodoCard: React.FC<IProps> = ({ activity }) => {
  let linKtoConsole =
    process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
      ? 'https://console.staging.myautochek.com/inventory/'
      : 'https://console.autochek.africa/inventory/'
  const locations = useSelector(getLocationOptions)
  const classes = useTodoStyles()

  const [showValue, setShowValue] = React.useState(false)
  const leadDetails = useSelector(getLeadDetails)
  const salesOfficers = useSelector(salesOfficersData)
  const userIsAgent = useSelector(isAgent)
  const userIsFulfilment = useSelector(isFulfillmentAgent)

  const [timeFromReschedule, setTime] = React.useState('')
  const [dateFromReschedule, setDate] = React.useState('')
  const [noteFromReschedule, setRescheduleNote] = React.useState('')
  const [commentFromReschedule, setCommentFromReschedule] = React.useState('')

  const dispatch = useDispatch()
  const [selectedCarId, setSelectedCarId] = React.useState('')
  const [rescheduleState, setRescheduleState] = React.useState(false)
  const selectedCountry = useSelector(getSelectedCountry)
  const currency = useSelector(getSelectedCountryCurrency)
  const [salesOfficersFromHook, setSalesOfficers] = React.useState([] as any)
  const [salesOfficerId, setSalesOfficerId] = React.useState('')
  const [showSelectedValue, setShowSelectedValue] = React.useState('')
  const { t } = useTranslation()

  const showModal = (carId: string) => {
    setSelectedCarId(carId)
    dispatch(showVehicleModal())
  }
  const handleReschedule = () => {
    setRescheduleState(true)
    handleReset()
    dispatch(activateUserIsFulfilmentAgent())
  }
  const closeReschedule = () => {
    setRescheduleState(false)
  }
  const handleSaleOfficerChange = (e: any) => {
    setSalesOfficerId(e.value)
  }

  const handlePreSelectedValue = (e: any) => {
    setShowSelectedValue(e.value)
  }

  const handleShow = (e: any) => {
    e.target.value == 'no_show' ? setShowValue(false) : setShowValue(true)
  }
  const setReschedule = (e: any) => {
    setRescheduleNote(e.target.value)
  }
  const setRescheduleDate = (e: any) => {
    setDate(e.target.value)
  }

  const setRescheduleTime = (e: any) => {
    setTime(e.target.value)
  }

  const onSelectComment = (e: any) => {
    setCommentFromReschedule(e.target.value)
  }

  React.useEffect(() => {
    if (salesOfficers) {
      let salesOfficersArray: any = []
      let users = salesOfficers.filter(function (data: any) {
        salesOfficersArray.push({
          label: data.firstname + ' ' + data.lastname,
          value: data.id
        })

        return data
      })
      setSalesOfficers(salesOfficersArray)
    }
  }, [])

  const {
    activityType,
    date,
    time,
    updatedAt,
    note,
    id,
    locationId,
    appointment,
    lead
  } = activity

  const saveReschedule = () => {
    dispatch(
      updateReschedule(
        {
          note: commentFromReschedule,
          show: false,
          date: dateFromReschedule,
          time: timeFromReschedule + getUserTimeZone(),
          sales_officer_id: salesOfficerId,
          completed: false,
          comment: noteFromReschedule
        },
        id
      )
    )

    setTimeout(() => {
      dispatch(
        fetchSchedule(leadDetails.id, {
          lead_id: leadDetails.id,
          completed: false,
          pageSize: 5
        })
      )
      dispatch(
        fetchSchedule(leadDetails.id, {
          lead_id: leadDetails.id,
          completed: true,
          pageSize: 5
        })
      )
    }, 500)
  }

  const [complete, setComplete] = useState(false)
  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    touched,
    errors
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const { note } = values
      let showStatus = showValue == false ? false : showValue
      let noteValue = showStatus ? showSelectedValue : ''
      noteValue = noteValue + ' : ' + note
      handleReset()
      if (id)
        dispatch(
          updateSchedule(
            { note: noteValue, show: showStatus, completed: true },
            id
          )
        )
    }
  })
  const phoneOptions = [
    { label: t('Unanswered'), value: 'Unanswered' },
    { label: t('Unreachable'), value: 'Unreachable' },
    { label: t('Busy'), value: 'Busy' },
    { label: t('Scheduled to sell'), value: 'Scheduled to sell' },
    { label: t('Scheduled to buy'), value: 'Scheduled to buy' },
    { label: t('Scheduled to fix'), value: 'Scheduled to fix' },
    { label: t('Incorrect contact: To email'), value: 'Incorrect contact: To email' },
    { label: 'Loan Lead: CP', value: 'Loan lead: CP' },
    { label: 'Car Unavailable: Customer not interested', value: 'Car Unavailable: Customer not interested' },
    { label: t('Car Unavailable: Customer requested for a replacement'), value: 'Car Unavailable: Customer requested for a replacement' },
    { label: t('Client not contacted'), value: 'Client not contacted' },
    { label: t('Successful Conversion'), value: 'Successfull Conversion' },
    { label: t('Completed Profile'), value: 'Completed Profile' },
    { label: t('Test'), value: 'Test' },
    { label: t('Potential Client'), value: 'Potential Client' },
    { label: t('Window Shopping'), value: 'Window Shopping' },
    { label: t('Unavailable client'), value: 'Unavailable client' },
    { label: t('Got a Unit'), value: 'Got a Unit' },
    { label: t('Contacted via Whatsapp: Scheduled to buy'), value: 'Contacted via Whatsapp: Scheduled to buy' },
    { label: t('Contacted by LM'), value: 'Contacted by LM' },
    { label: t('Other'), value: 'Other' }
  ]
  const handleReset = () => {
    setComplete(false)
    setShowValue(false)
  }
  
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault()
    setComplete(true)
    closeReschedule()
    dispatch(activateUserIsFulfilmentAgent())
  }
  return (
    <Grid container item xs={12} className={classes.cardWrapper}>
      <Grid item xs={9} className={classes.cardWrapperInner}>
        <Grid item className={classes.iconWrapper}>
          {mapper[activityType] ? (
            <img src={mapper[activityType].icon} alt={activityType} />
          ) : null}
        </Grid>
        <Grid item xs={11}>
          <Typography variant="body2" className={classes?.cardTitle}>
            {appointment && appointment.type == 'BUY_CAR'
              ? t('Buy Car Appointment')
              : null}
            {appointment && appointment.type == 'SELL_CAR'
              ? t('Sell Car Appointment')
              : null}
            {appointment && appointment.type == 'FIX_CAR'
              ? t('Fix Car Appointment')
              : null}
            {appointment && appointment.type == 'TEST_DRIVE_CAR'
              ? t('Test Drive Car Appointment')
              : null}
            {appointment && appointment.type == 'TEST_DRIVE_TRUCK'
              ? t('Test Drive Truck Appointment')
              : null}
            {appointment && appointment.type == 'BUY_TRUCK'
              ? t('Buy Truck Appointment')
              : null}
            {appointment && appointment.type == 'SELL_TRUCK'
              ? t('Sell Truck Appointment')
              : null}

            {appointment ? null : t(`${mapper[activityType]?.title}`)}
          </Typography>
          <Typography variant="body2" className={classes.cardStatus}>
            {t('openTask')}
            <Typography component="span" className={classes.cardStatusText}>
              {`${t('scheduled')} ${time} ${dayjs(date).format('DD/MM/YYYY')}`}
            </Typography>
          </Typography>

          {!!locationId && (
            <Typography
              variant="body2"
              className={clsx(classes.cardStatus, classes.textBlue)}
            >
              {t('location')}:
              <Typography component="span" className={classes.cardStatusText}>
                {
                  locations.find(
                    (location: any) => location.value === locationId
                  )?.label
                }
              </Typography>
            </Typography>
          )}

          {appointment &&
          appointment.carData &&
          appointment.carData.franchise ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseName')}:
                <Typography component="span" className={classes.cardStatusText}>
                  {appointment.carData.franchise.name}
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseLocation')}:
                <Typography component="span" className={classes.cardStatusText}>
                  {appointment.carData.franchise.address}
                </Typography>
              </Typography>
            </Grid>
          ) : null}
          {appointment &&
          appointment.carData &&
          appointment.carData.salesOfficer ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('salesOfficer')} :
                <Typography component="span" className={classes.cardStatusText}>
                  {appointment.carData.salesOfficer.firstname +
                    ' ' +
                    appointment.carData.salesOfficer.lastname}
                </Typography>
              </Typography>
            </Grid>
          ) : null}

          {appointment &&
          appointment.type == 'BUY_CAR' &&
          !appointment.carData.salesOfficer ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('salesOfficer')} :
                <Typography component="span" className={classes.cardStatusText}>
                  N/A
                </Typography>
              </Typography>
            </Grid>
          ) : null}

          {appointment &&
          (appointment.type == 'BUY_CAR' ||
            appointment.type == 'TEST_DRIVE_CAR' ||
            appointment.type == 'BUY_TRUCK' ||
            appointment.type == 'TEST_DRIVE_TRUCK') &&
          !appointment.carData.franchise ? (
            <Grid>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseName')}:
                <Typography component="span" className={classes.cardStatusText}>
                  N/A
                </Typography>
              </Typography>
              <Typography
                variant="body2"
                className={clsx(classes.cardStatus, classes.textBlue)}
              >
                {t('franchiseLocation')}:
                <Typography component="span" className={classes.cardStatusText}>
                  N/A
                </Typography>
              </Typography>
            </Grid>
          ) : null}

          <Grid item xs={12} className={classes.text}>
            {note.map((item) => (
              <React.Fragment key={item.id}>
                <Typography
                  variant="body2"
                  className={clsx(classes.cardNoteTitle, classes.capitalize)}
                >
                  {`${getAuthor(item.authorId).firstname} ${
                    getAuthor(item.authorId).lastname
                  }`}
                  <Typography
                    component="span"
                    variant="body2"
                    style={{ textTransform: 'none' }}
                    className={classes.cardNoteTitle}
                  >
                    {` ${dayjs(item.updatedAt).format('h:mm A DD/MM/YYYY')} ${t(
                      'noted'
                    )}:`}
                  </Typography>
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    border: '1px solid #CAD0D9',
                    padding: '.5rem',
                    borderRadius: '0.25rem',
                    marginBottom: '0.3rem'
                  }}
                  className={classes.cardNote}
                >
                  {item.text}
                </Typography>
                {item.comment && item.comment.length ? (
                  <Typography
                    variant="body2"
                    style={{
                      // border: '1px solid #CAD0D9',
                      padding: '.5rem',
                      paddingLeft:0,
                      paddingTop:5,
                      borderRadius: '0.25rem',
                      marginBottom: '0.3rem'
                    }}
                    className={clsx(classes.cardNoteTitle, classes.capitalize)}
                  >
                    {`comment: ${item.comment}`}
                  </Typography>
                ) : null}
              </React.Fragment>
            ))}
          </Grid>

          {appointment && appointment.carId && appointment.carData ? (
            <Grid item xs={12} className={classes.carCover}>
              <Grid item className={classes.carCoverChildImage}>
                <div
                  style={{
                    backgroundImage: `url(${appointment.carData.imageUrl})`,
                    flexBasis: '30%',
                    backgroundSize: 'cover',
                    borderRadius: '5px',
                    height: '135px',
                    marginRight: '10px'
                  }}
                ></div>
              </Grid>
              <Grid item className={classes.carCoverChild}>
                <Typography component="span" className={classes.carName}>
                  {appointment.carData.year} {appointment.carData.make}{' '}
                  {appointment.carData.model}
                </Typography>
                <Typography component="p">
                  {appointment.carData.city} , {appointment.carData.state}
                </Typography>

                {appointment.carData.price ? (
                  <Typography component="p">
                    {currency}
                    {parseFloat(appointment.carData.price).toLocaleString()}
                  </Typography>
                ) : null}

                <a
                  href={`${linKtoConsole + appointment.carId}`}
                  target="/blank"
                >
                  <Typography
                    component="p"
                    style={{ marginBottom: '13px' }}
                    className={classes.carText}
                  >
                    {t('viewConsole')}
                  </Typography>
                </a>
              </Grid>
            </Grid>
          ) : null}

          {appointment &&
          appointment.carData &&
          appointment.type !== 'BUY_CAR' &&
          appointment.type !== 'TEST_DRIVE_CAR' &&
          appointment.type !== 'BUY_TRUCK' &&
          appointment.type !== 'TEST_DRIVE_TRUCK' ? (
            <Grid>
              <Typography component="span" className={classes.carStatusText}>
                {`Car details`}
              </Typography>
              <Typography component="p" className={classes.carText}>
                {appointment.carData && appointment.carData.year}{' '}
                {appointment.carData.make} {appointment.carData.model}
              </Typography>
              <Typography component="p" className={classes.carText}>
                {t('mileage')} : {appointment.carData.mileage} {t('Km')}
              </Typography>
              <Typography component="p" className={classes.carText}>
                {t('gearType')} : {appointment.carData.gearType}
              </Typography>
              <Typography
                component="p"
                style={{ marginBottom: '13px' }}
                className={classes.carText}
              >
                {t('fuelType')} : {appointment.carData.fuelType}
              </Typography>
            </Grid>
          ) : null}

          {/* {note.map((item) => (
            <Typography
              key={item.id}
              variant="body2"
              className={classes.text}
            >
              {item.text}
            </Typography>
          ))} */}

          <Grid style={{ display: 'flex' }}>
            {userIsAgent ||
            (userIsFulfilment && appointment?.type == 'BUY_CAR') ||
            (userIsFulfilment && appointment?.type == 'TEST_DRIVE_CAR') ||
            (userIsFulfilment && appointment?.type == 'BUY_TRUCK') ||
            (userIsFulfilment && appointment?.type == 'TEST_DRIVE_TRUCK') ||
            (!rescheduleState &&
              userIsFulfilment &&
              appointment?.type == 'SELL_CAR') ? (
              <Grid>
                {!complete && (
                  <Button
                    startIcon={<img src={check} alt="check" />}
                    variant="outlined"
                    onClick={handleClick}
                    style={{ marginRight: '20px' }}
                  >
                    {t('markCompleted')}
                  </Button>
                )}
              </Grid>
            ) : null}
            {(appointment && appointment?.type == 'BUY_CAR') ||
            (appointment && appointment?.type == 'SELL_CAR') ||
            (appointment && appointment?.type == 'FIX_CAR') ||
            activityType == 'PHONE_CALL' ? (
              <Grid>
                {(!rescheduleState && userIsAgent) ||
                (!rescheduleState &&
                  userIsFulfilment &&
                  appointment?.type == 'BUY_CAR') ||
                (!rescheduleState &&
                  userIsFulfilment &&
                  appointment?.type == 'TEST_DRIVE_CAR') ||
                (!rescheduleState &&
                  userIsFulfilment &&
                  appointment?.type == 'BUY_TRUCK') ||
                (!rescheduleState &&
                  userIsFulfilment &&
                  appointment?.type == 'TEST_DRIVE_TRUCK') ||
                (!rescheduleState &&
                  userIsFulfilment &&
                  appointment?.type == 'SELL_CAR') ? (
                  <Button
                    startIcon={<img src={calendarlight} alt="check" />}
                    variant="contained"
                    onClick={() => handleReschedule()}
                  >
                    {t('Reschedule')}
                  </Button>
                ) : null}
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={3} className={classes.menuWrapper}>
        <Typography component="span" className={classes.cardStatusText}>
          {t('Updated')} {dayjs(updatedAt).fromNow()}
        </Typography>
        {userIsAgent ? <DeleteMenu id={id} /> : null}
      </Grid>

      {!!complete && (
        <Grid
          item
          xs={8}
          className={classes.text}
          style={{ marginLeft: '2rem' }}
        >
          {}

          {(appointment && appointment.type == 'BUY_CAR') ||
          (appointment && appointment.type == 'SELL_CAR') ||
          (appointment && appointment.type == 'FIX_CAR') ? (
            <FormControl component="fieldset" style={{ marginTop: '20px' }}>
              <RadioGroup
                row
                aria-label="position"
                name="position"
                onChange={handleShow}
                defaultValue="no"
              >
                <FormControlLabel
                  value="show"
                  control={<Radio color="secondary" />}
                  label="SHOW"
                />
                <FormControlLabel
                  value="no_show"
                  control={<Radio color="secondary" />}
                  label="NO SHOW"
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {showValue ? (
            <Grid style={{ marginTop: 10, marginBottom: 10, zIndex: 3000 }}>
              <Typography variant="body2" className={classes.label}>
                {t('outcomeAppointment')}?
              </Typography>
              <Select
                onChange={handlePreSelectedValue}
                menuPortalTarget={document.body}
                options={showPreselectedValue}
              />
            </Grid>
          ) : null}

          {(appointment && appointment.type == 'BUY_CAR') ||
          (appointment && appointment.type == 'SELL_CAR') ||
          (appointment && appointment.type == 'FIX_CAR') ? (
            <Typography variant="body2" className={classes.label}>
              {t('Note')}
            </Typography>
          ) : null}
          {!appointment ? (
            <Typography variant="body2" className={classes.label}>
              {t('What was the outcome of your')} {mapper[activityType].title}?
            </Typography>
          ) : null}
          <TextField
            fullWidth
            multiline
            rows={2}
            onBlur={handleBlur}
            error={!!(errors.note && touched.note)}
            helperText={touched.note && errors.note}
            name="note"
            value={values.note}
            onChange={handleChange}
            id="note"
            variant="outlined"
          />
          <FormFooter handleReset={handleReset} handleSubmit={handleSubmit} />
        </Grid>
      )}

      {rescheduleState ? (
        <Grid
          item
          xs={8}
          className={classes.text}
          style={{ marginLeft: '2rem', marginTop: '2rem' }}
        >
          <Grid item xs={12} style={{ display: 'flex', marginBottom: '20px' }}>
            <div style={{ marginRight: '2rem' }}>
              <Typography variant="body2" className={classes.carStatusText}>
                {t('Date')}
              </Typography>
              <TextField
                id="date"
                name="date"
                onBlur={handleBlur}
                onChange={setRescheduleDate}
                variant="outlined"
                type="date"
                inputProps={{
                  min: dayjs(new Date()).format('YYYY-MM-DD')
                }}
              />
            </div>
            <div>
              <Typography variant="body2" className={classes.carStatusText}>
                {t('Time')}
              </Typography>
              <InputBase
                name="time"
                type="time"
                style={{
                  border: '1px solid #CAD0D9',
                  padding: '5px',
                  borderRadius: '5px'
                }}
                onChange={setRescheduleTime}
              />
            </div>
          </Grid>

          {activityType !== 'PHONE_CALL' ? (
            <div style={{ marginBottom: '20px' }}>
              <Typography variant="body2" className={classes.carStatusText}>
                {t('selectFieldOfficer')}
              </Typography>
              <Select
                menuPortalTarget={document.body}
                onChange={handleSaleOfficerChange}
                options={salesOfficersFromHook}
              />
            </div>
          ) : null}

          {/* {
appointment && appointment.type == 'SELL_CAR' || appointment && appointment.type == 'FIX_CAR' ? 

      <div style={{ marginBottom:'20px'}}>
      <Typography variant="body2" className={classes.carStatusText}>
          Select Location
          </Typography>
<Select onChange={handleSaleOfficerChange}  options={locations}/>

      </div>
     
: null} */}

          <Typography variant="body2" className={classes.carStatusText}>
            {t('whyRescheduling')}?
          </Typography>
          <Box
            style={{
              marginBottom: '20px'
            }}
          >
            <CustomSelect
              value={commentFromReschedule}
              name="comment"
              label={t('Add a comment')}
              options={phoneOptions}
              onSelect={onSelectComment}
            />
          </Box>
          <TextField
            fullWidth
            multiline
            rows={2}
            name="note"
            onChange={setReschedule}
            id="note"
            variant="outlined"
          />
          <Grid item xs={12} className={classes.formFooter}>
            <Button variant="outlined" onClick={() => closeReschedule()}>
              {t('Cancel')}
            </Button>
            {userIsAgent || userIsFulfilment ? (
              <Button
                variant="contained"
                disableElevation
                onClick={() => saveReschedule()}
              >
                {t('save')}
              </Button>
            ) : null}
          </Grid>
        </Grid>
      ) : null}

      <VehicleDetails country={selectedCountry} carId={selectedCarId} />
    </Grid>
  )
}

export default TodoCard
