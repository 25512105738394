import React, { useState, useEffect } from 'react'
import {
  Modal,
  Button,
  Form,
  Input,
  Row,
  Col,
  DatePicker,
  TimePicker,
  message,
  Spin
} from 'antd'
import { useMutation, useQueryClient } from 'react-query'
import { StyledNoShow } from './styles'
import api from '../../../utils/api'
import { getUserToken, getSelectedCountry } from '../../../store/auth/selectors'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { useTranslation } from 'react-i18next'

export default function NoShow({ setInspOutcome, modalData, refetch }: any) {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const token = useSelector(getUserToken)
  const queryClient = useQueryClient()
  const country = useSelector(getSelectedCountry)
  const { t } = useTranslation()

  const updateSchedule: any = useMutation(
    (payload) => api.updateScheduleActivity(token, modalData.id, payload),
    {
      onSuccess: (__data) => {
        queryClient.invalidateQueries('book inspection')
        setSubmitting(false)
        message.success('Inspection booking updated successfully')
        setInspOutcome(false)
        refetch()
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(
          error?.response.data.error ||
            'Uh oh, something went wrong, please try again'
        )
      }
    }
  )

  const onFinish = async (fieldsValue: any) => {
    setSubmitting(true)
    const values = {
      ...fieldsValue,
      date: fieldsValue['date'].format('YYYY-MM-DD'),
      time: fieldsValue['time'].format('HH:mm')
    }
    const payloadData = {
      id: modalData.id,
      completed: false,
      date: values.date,
      time: values.time,
      show: false,
      country: country
    }
    await updateSchedule.mutateAsync(payloadData)
  }

  const handleCancel = () => {
    setInspOutcome(false)
  }

  function disabledDate(current: any) {
    // Can not select days before today and today
    return current && current < moment().endOf('day')
  }

  return (
    <StyledNoShow>
      <div className="header mb-2">{t('Reschedule')}</div>
      <Form
        form={form}
        layout="vertical"
        name="horizontal_login"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="date"
              label={t('Date')}
              rules={[
                {
                  type: 'object' as const,
                  required: true,
                  message: t('pleaseEnterDate')
                }
              ]}
            >
              <DatePicker
                disabledDate={disabledDate}
                placeholder={t('selectDate')}
              />
            </Form.Item>
          </Col>
          <Col className="gutter-row" span={12}>
            <Form.Item
              name="time"
              label={t('Time')}
              rules={[{ required: true, message: t('pleaseEnterTime') }]}
            >
              <TimePicker placeholder={t('selectTime')} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          {submitting ? (
            <div className="d-flex mt-4" style={{ justifyContent: 'center' }}>
              <Spin />
            </div>
          ) : (
            <div className="d-flex mt-4">
              <Button
                className="cancel me-3"
                type="primary"
                onClick={handleCancel}
              >
                {t('Cancel')}
              </Button>
              <Button className="submit" type="primary" htmlType="submit">
                {t('save')}
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </StyledNoShow>
  )
}
