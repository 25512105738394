import styled from 'styled-components'
import BootingImg from '../../images/booting.svg'
import AutoCRMImage from '../../images/autocrm.svg'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { Link } from 'react-router-dom'

export const StyledIcon = styled.div`
  height: 22px;
  width: 27px;
  background-image: url(${BootingImg});
  background-size: 27px 22px;
  margin-right: 7px;
  color: red;
`

export const AutoCRMText = styled.div`
  width: 80px;
  height: 19px;
  background-image: url(${AutoCRMImage});
  background-repeat: no-repeat;
  background-size: 80px 21px;
  display: flex;
`

export const LogoContainer = styled.div`
  width: 117px;
  height: 29px;
  display: flex;
  justify-content: space-between;
  margin-left: 13px;
  margin-top: 13px;
`

export const ToolBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const ChildrenWrap = styled.div`
  flex-grow: 1;
`

export const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.palette.common.white};
  text-decoration: none;
`

export const StyledListItemText = styled(ListItemText)<{ active?: boolean }>`
  color: ${({ theme, active }) =>
    active ? theme.palette.neutral.kournikova : theme.palette.common.white};

  span {
    font-size: 13px;
  }
`

export const StyledListItem = styled(ListItem)<{
  active?: boolean
  notificationCount?: number
}>`
  position: relative;
  background-color: ${({ theme, active }) =>
    active && theme.palette.secondary.main};
  height: 40px;

  &::after {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 20px;
    height: fit-content;
    padding: 3px;
    font-size: 10px;
    border-radius: 50%;
    ${({ notificationCount }) =>
      notificationCount
        ? `content: '${notificationCount > 99 ? '99+' : notificationCount}'`
        : ''};
    color: #ffffff;
    font-weight: bold;
    background: red;
    top: 0;
    right: 0;
  }
`

export const ActiveBar = styled.div`
  background-color: ${({ theme }) => theme.palette.neutral.kournikova};
  position: absolute;
  left: 0;
  width: 4px;
  height: 40px;
`

export const CountryPopOver = styled.div`
  width: 150px;
  margin-top: 90px;
`

export const CountryItem = styled.div`
  margin-bottom: 9px;
  display: flex;
  color: #fff;
  cursor: pointer;
`

export const LogoutLink = styled.div`
  position: absolute;
  width: 36px;
  bottom: 30px;
  left: 20px;
  display: flex;
  justify-content: spcae-between;
`
