import styled from 'styled-components'

export const StyledBookInspection = styled.div`
  .bookInspectionWrapper {
    .title {
      font-size: 18px;
      color: #30355b;
      font-weight: bold;
    }
    .text {
      color: #555770;
      font-size: 14px;
    }
    form {
      label {
        color: #30355b;
        font-size: 14px;
      }
      .ant-picker {
        width: 100%;
        background: #ffffff;
        border: 1px solid #c7c9d9;
        box-sizing: border-box;
        border-radius: 4px;
        height: 46px;
      }
      .cancel {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        background: #ffffff;
        border: 1px solid #30355b;
        border-radius: 5px;
        min-width: 170px;
        width: auto;
        height: 40px;
      }
      .submit {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        background: #ffb619;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        min-width: 170px;
        width: auto;
        height: 40px;
        border: 0;
      }
    }
  }
`
