import React from 'react'
import Select from 'react-select'

interface IProps {
  value: string
  name: string
  options: { value: string; label: string }[]
  handleChange: (...args: any[]) => void
  handleBlur: (...args: any[]) => void
  error?: boolean
  helperText?: string | boolean
  width?: string
  loading?: boolean
}

const CustomSelectInput: React.FC<IProps> = (props) => {
  const {
    value,
    options,
    name,
    handleChange,
    handleBlur,
    
    error,
    helperText,
    loading,
    width
  } = props
  
  return (
    <React.Fragment>
      <Select
        value={options.find((option) => option.value === value)}
        isLoading={loading}
        isSearchable={true}
        onBlur={() => handleBlur(name, true)}
        onChange={(value) => handleChange(name, value?.value)}
        style={{ width: width ?? '12rem' }}
        name={name}
        options={options}
      />
      {!!error && (
        <small
          style={{ color: '#f44336', display: 'block', marginTop: '.2rem' }}
        >
          {helperText}
        </small>
      )}
    </React.Fragment>
  )
}

export default CustomSelectInput
