import React from 'react'

const PersonIcon = () => {
  return (
    <svg
      width={14}
      height={14}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00099 8.06057C9.01867 8.06057 10.6543 6.42492 10.6543 4.40724C10.6543 2.38956 9.01867 0.753906 7.00099 0.753906C4.98331 0.753906 3.34766 2.38956 3.34766 4.40724C3.34766 6.42492 4.98331 8.06057 7.00099 8.06057Z"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13 13.2466C12.4242 12.1541 11.5611 11.2396 10.5038 10.6016C9.44641 9.96364 8.23492 9.62646 7 9.62646C5.76508 9.62646 4.55359 9.96364 3.49623 10.6016C2.43886 11.2396 1.57575 12.1541 1 13.2466"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PersonIcon
