import styled from 'styled-components'
import BootingImg from '../../images/booting.svg'
import AutoCRMImage from '../../images/autocrm.svg'
import Button from '@material-ui/core/Button'

export const PageContainer = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.neutral.cyanBluishWhite};
`

export const SignInBox = styled.div`
  width: 436px;
  height: 439px;
  align-self: center;
  background-color: ${({ theme }) => theme.palette.common.white};
  border-radius: 4px 4px 0px 0px;
  overflow: hidden;
`

export const LogoTop = styled.div`
  width: 436px;
  height: 40px;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.dark};
`

export const LogoTopContent = styled.div`
  width: auto;
  display: flex;
`

export const BootingCar = styled.div`
  width: 31px;
  height: 25px;
  margin-top: 6px;
  margin-right: 12px;
  background-image: url(${BootingImg});
`

export const AutoCRMText = styled.div`
  width: 92px;
  height: 16px;
  background-image: url(${AutoCRMImage});
  background-repeat: no-repeat;
  display: flex;
  align-self: center;
`

export const SignInBoxContent = styled.div`
  padding: 55px 40px 77px;
`

export const InputLane = styled.div<{
  marginBottom?: string
  flex?: boolean
  spaceBetween?: boolean
  bold?: boolean
}>`
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  display: ${({ flex }) => flex && 'flex'};
  justify-content: ${({ spaceBetween }) => spaceBetween && 'space-between'};
  font-weight: ${({ bold }) => bold && 800};
`

export const StyledButton = styled(Button)<{ deactivated?: number }>`
  width: 100%;
  height: 40px;
  text-transform: capitalize;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.common.white};
  background-color: ${({ theme, deactivated }) =>
    deactivated
      ? theme.palette.neutral.cyanBluishGray
      : theme.palette.primary.dark};

  &:hover {
    background-color: ${({ theme, deactivated }) =>
      deactivated
        ? theme.palette.neutral.cyanBluishGray
        : theme.palette.primary.dark};
  }
`
