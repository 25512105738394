import React from 'react'
import ReviewedDocument from '../../PortfolioManagement/PortfolioDetails/ReviewedDocument'
import { StyledCustomerDocument } from './styles'

export default function CustomerDocument({ title }: any) {
  return (
    <StyledCustomerDocument>
      <div className="documentWrap">
        <div className="header mb-4">{title}</div>
        <ReviewedDocument />
      </div>
    </StyledCustomerDocument>
  )
}
