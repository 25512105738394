export const CAR_STATUS = {
  DRAFT: 'DRAFT',
  APPLICATION_COMPLETED: 'APPLICATION_COMPLETED',
  OFFERS: 'OFFERS',
  OFFER_ACCEPTED: 'OFFER_ACCEPTED',
  DOCUMENT_REQUESTED: 'DOCUMENT_REQUESTED',
  DOCUMENT_REJECTED: 'DOCUMENT_REJECTED',
  DOCUMENT_ACCEPTED: 'DOCUMENT_ACCEPTED',
  CAR_SOURCING: 'CAR_SOURCING',
  PPI_IN_PROGRESS: 'PPI_IN_PROGRESS',
  PPI_PASS: 'PPI_PASS',
  PPI_FAILS: 'PPI_FAILS',
  CUSTOMER_INSPECTION: 'CUSTOMER_INSPECTION',
  CAR_ACCEPTED: 'CAR_ACCEPTED',
  EQUITY_REQUESTED: 'EQUITY_REQUESTED',
  EQUITY_PAID: 'EQUITY_PAID',
  DISBURSED: 'DISBURSED',
  DECLINED: 'DECLINED'
}