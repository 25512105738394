import React from 'react'

const SourceIcon = () => {
  return (
    <svg
      width={17}
      height={18}
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 5V13"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5 10L8.5 13L5.5 10"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 16.5H15V16.5C15.5523 16.5 16 16.0523 16 15.5V2.49999C16 1.94771 15.5523 1.49999 15 1.49999H12.5"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.49934 1.49999H2V1.49999C1.44772 1.49999 1 1.94771 1 2.49999V15.5V15.5C1 16.0523 1.44772 16.5 2 16.5H4.49934"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SourceIcon
