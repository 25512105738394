import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Input, Select, Spin } from 'antd'
import WithSideMenu from '../../../../components/WithSideMenu'
import { SettingsContainer } from '../../styles'
import { ContentWrap, SettingsNavDummy } from '../styles'
import SettingsMenu from '../../SettingsMenu'
import useStyles from '../newUserStyle'
import ChevronLeftIcon from '../../../Loans/LoanDetails/svg/ChevronLeft'
import { FormWrapper, StyledCreateAgent, WhatsappFormContainer } from './styles'
import {
  getUserToken,
  getSelectedCountry
} from '../../../../store/auth/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { showSnackbar } from '../../../../store/snackbar/actions'
import api from '../../../../utils/api'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function CreateWhatsappAgent() {
  const { Option } = Select
  const classes = useStyles()
  const token = useSelector(getUserToken)
  const country = useSelector(getSelectedCountry)
  let history = useHistory()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const onFinish = async (values: any) => {
    setLoading(true)
    const payload = {
      email: values.email,
      phone: values.phone
    }

    try {
      const response = await api.createChatAgent(token, payload)
      if (response) {
        dispatch(showSnackbar('Chat agent created successfully', 'success'))
        history.push('/settings/agents/whatsapp')
        setLoading(false)
      }
    } catch (err) {
      dispatch(showSnackbar('Error adding chat agent user', 'error'))
    }
  }

  return (
    <WithSideMenu>
      <SettingsContainer>
        <SettingsNavDummy />
        <SettingsMenu />
        <ContentWrap>
          <StyledCreateAgent>
            <Link
              to="/settings/agents/whatsapp"
              style={{ marginLeft: '-7px' }}
              className={`${classes.headerSection} ${classes.backBtn}`}
            >
              <ChevronLeftIcon />
              {t('agent')}
            </Link>
            <div style={{ margin: '1rem 0rem' }}>
              <span style={{ fontWeight: 'bold', fontSize: '22px' }}>
                {t('createWhatsappAgent')}
              </span>
            </div>

            <WhatsappFormContainer>
              <FormWrapper>
                <div className="title-form">
                  {t('fillDetailsBelowCreateNewUser')}.
                </div>
                <header>{t('whatsappAgentDetails')}</header>

                <Form
                  name="basic"
                  onFinish={onFinish}
                  autoComplete="off"
                  layout="vertical"
                >
                  <Form.Item
                    label={t('email')}
                    name="email"
                    rules={[
                      { required: true, message: t('pleaseEnterEmailAddress') }
                    ]}
                  >
                    <Input type="email" />
                  </Form.Item>

                  <Form.Item
                    label={t('phoneNumber')}
                    name="phone"
                    rules={[
                      {
                        required: true,
                        message: t('pleaseEnterPhoneNumber')
                      },
                      {
                        min: 11,
                        max: 11,
                        message: t('phoneNumberShouldBe11Character')
                      }
                    ]}
                  >
                    <Input type="number" maxLength={11} />
                  </Form.Item>

                  <Form.Item name="country" label={t('country')}>
                    <Select defaultValue={country} disabled>
                      <Option value="GH">Ghana</Option>
                      <Option value="UG">Uganda</Option>
                      <Option value="KE">Kenya</Option>
                      <Option value="CI">Ivory Coast</Option>
                      <Option value="NG">Nigeria</Option>
                    </Select>
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {loading ? <Spin /> : t('save')}
                    </Button>
                  </Form.Item>
                </Form>
              </FormWrapper>
            </WhatsappFormContainer>
          </StyledCreateAgent>
        </ContentWrap>
      </SettingsContainer>
    </WithSideMenu>
  )
}
