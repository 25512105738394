export const statusOptions = [
  { label: 'VERY HOT LEAD', value: 'VERY_HOT' },
  { label: 'HOT LEAD', value: 'HOT' },
  { label: 'SCHEDULED INSPECTION', value: 'SCHEDULED_INSPECTION' },
  { label: 'COLD LEAD', value: 'COLD' },
  { label: 'ENQUIRY', value: 'ENQUIRY' },
  { label: 'FINANCING', value: 'FINANCING' },
  { label: 'FIRST CONTACT', value: 'FIRST_CONTACT' },
  { label: 'NOT INTERESTED', value: 'NOT_INTERESTED' }
]
