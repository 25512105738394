import React from 'react'
import {
  makeStyles,
  withStyles,
  Theme,
  createStyles
} from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { fetchSchedule, setTab } from '../../../../../store/events/actions'
import { useDispatch, useSelector } from 'react-redux'
import { getLeadDetails } from '../../../../../store/leads/selectors'
import { getSearchQuery } from '../../../../../store/events/selectors'
import { useTranslation } from 'react-i18next'

const CustomTabs = withStyles({
  root: {},
  indicator: {
    backgroundColor: '#4B9FF2'
  }
})(Tabs)

const CustomTab = withStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: 'none',
      minWidth: 72,
      fontWeight: 800,
      color: '#A1AACE',
      fontSize: '0.75rem',
      paddingBottom: 0,
      marginRight: theme.spacing(4),
      '&:hover': {
        color: '#4B9FF2',
        opacity: 1
      },
      '&$selected': {
        color: '#4B9FF2',
        fontWeight: 800
      },
      '&:focus': {
        color: '#4B9FF2'
      }
    },
    selected: {}
  })
)((props: StyledTabProps) => <Tab disableRipple {...props} />)

interface StyledTabProps {
  label: string
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    marginBottom: '1rem'
  }
}))

const mapper: any = {
  0: undefined,
  1: 'PHONE_CALL',
  2: 'APPOINTMENT',
  3: 'REMINDER',
  4: 'STORE_VISIT'
}

export default function TlTabs() {
  const classes = useStyles()
  const dispatch = useDispatch()
  const lead = useSelector(getLeadDetails)
  const searchQuery = useSelector(getSearchQuery)
  const [value, setValue] = React.useState(0)

  const { t } = useTranslation()

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue)
    dispatch(setTab(mapper[newValue]))
    dispatch(
      fetchSchedule(lead.id, {
        lead_id: `${lead.id}`,
        completed: true,
        searchQuery,
        activityType: mapper[newValue],
        pageSize: 5
      })
    )
  }

  return (
    <div className={classes.root}>
      <CustomTabs
        value={value}
        onChange={handleChange}
        aria-label="timeline tab"
      >
        <CustomTab label={t('all')} />
        <CustomTab label={t('phoneCalls')} />
        <CustomTab label={t('appointments')} />
        <CustomTab label={t('reminders')} />
        <CustomTab label={t('visits')} />
      </CustomTabs>
    </div>
  )
}
