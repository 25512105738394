import dayjs from 'dayjs'

export function deriveInitials(name: string) {
  if (!name) return ''
  const nameSlices = name.split(' ')
  const initials = nameSlices.map((slice) => slice.charAt(0).toUpperCase())

  return initials.join('')
}

type TDateTimeOption = 'time' | 'date'

export function getDateTime(timestamp: string, option?: TDateTimeOption) {
  if (option === 'date') {
    return dayjs(timestamp).format('D MMM YY')
  } else if (option === 'time') {
    return dayjs(timestamp).format('H:mm')
  } else {
    return dayjs(timestamp).format('D MMM YY [-] H:mm')
  }
}
