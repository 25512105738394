import styled from 'styled-components'

export const StyledLeadMgt = styled.div`
  background: #f8f8fb;
  /* min-height: 100vh;
  height: auto; */
  padding-bottom: 38px;
  .headerWrapper {
    background: #fff;
    padding: 25px 40px 20px 40px;
    display: flex;
    justify-content: flex-end;
    .userWrapper {
      display: flex;
      align-items: center;
      .users {
        display: flex;
        border-right: 1px solid #eff4f7;
        padding: 5px 20px 5px 0px;
        .name {
          color: #30355b;
          font-size: 14px;
          font-weight: bold;
        }
        .role {
          color: #a6acbe;
          font-size: 14px;
        }
        .initials {
          background: rgba(113, 59, 219, 0.05);
          width: 48px;
          height: 48px;
          border-radius: 50%;
          color: #000000;
          font-weight: bolder;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
        }
      }
    }
    .bell {
      padding: 5px 0px 5px 20px;
      cursor: pointer;
    }
  }

  .subHeadersWrapper {
    padding: 25px 40px 40px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      color: #0a1133;
      font-weight: bold;
      font-size: 17px;
    }
    .ant-select-selector {
      border: 1px solid #dadce5;
      border-radius: 5px;
    }
    .ant-select-selection-item {
      color: #30355b;
      font-weight: 500;
      font-size: 13px;
    }
  }

  .dashboardAgentCards {
    padding: 0px 40px 20px 40px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
    .cardWrapper {
      background: #fff;
      height: 157px;
      display: flex;
      /* align-items: center; */
      padding: 50px 15px;
      border-radius: 10px;
      /* width: 392px; */
      box-shadow: 0 10px 6px -6px #ededf6;
      .title {
        color: #8c8f99;
        font-size: 16px;
      }
      .valueWrap {
        display: flex;
        align-items: center;
      }
      .figures {
        color: #30355b;
        font-weight: bold;
        font-size: 16px;
        margin-right: 15px;
      }
      .value {
        color: #109d60;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .dashboardCards {
    padding: 0px 40px 20px 40px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    .cardWrapper {
      background: #fff;
      height: 157px;
      display: flex;
      /* align-items: center; */
      padding: 50px 15px;
      border-radius: 10px;
      /* width: 392px; */
      box-shadow: 0 10px 6px -6px #ededf6;
      .title {
        color: #8c8f99;
        font-size: 16px;
      }
      .valueWrap {
        display: flex;
        align-items: center;
      }
      .figures {
        color: #30355b;
        font-weight: bold;
        font-size: 16px;
        margin-right: 15px;
      }
      .value {
        color: #109d60;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }

  .tableWrapper {
    display: grid;
    grid-template-columns: auto 415px;
    grid-gap: 40px;
    padding: 25px 40px 25px 40px;

    .tableCover {
      background: transparent;
      border-radius: 10px;
      overflow: hidden;
      .table {
        margin-bottom: 0px;
      }
    }

    th {
      color: #30355b;
      font-size: 14px;
      font-weight: 500;
      background: #fff;
      box-shadow: 0px 1px 0px #dadbe4;
      border-bottom: 2px solid #ccc;
      padding-top: 18px;
      padding-bottom: 18px;
    }
    tbody td {
      color: #505050;
      font-size: 14px;
      padding-top: 18px;
      padding-bottom: 18px;
    }
    .wrap {
      button {
        background: #ffb619;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        border: 0;
        min-width: 458px;
        width: auto;
        height: 57px;
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
      }
    }
    .chartsCard {
      width: 415px;
      height: 420px;
      background: #ffffff;
      border-radius: 10px;
      overflow: hidden;
      position: relative;
      .title {
        color: #30355b;
        font-weight: 600;
        font-size: 15px;
        border-bottom: 1px solid #eef0f7;
        text-align: center;
        position: absolute;
        z-index: 10;
        width: 100%;
        padding: 15px 0px;
      }
      .statsWrapper {
        position: absolute;
        z-index: 12;
        bottom: 0px;
        left: 0;
        right: 0;
        width: 100%;
        padding-left: 40px;
        padding-right: 40px;
        padding-bottom: 15px;
        .stats {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .nameType {
            display: flex;
            align-items: center;
            .purple {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #6f52ed;
              margin-right: 8px;
            }
            .yellow {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #ffb800;
              margin-right: 8px;
            }
            .blue {
              width: 8px;
              height: 8px;
              border-radius: 50%;
              background: #4cb8ff;
              margin-right: 8px;
            }
            .complete {
              color: #8c8f99;
              font-weight: 500;
              font-size: 14px;
            }
          }
          .value {
            color: #000000;
            font-size: 15px;
            font-weight: 500;
            span {
              color: #8c8f99;
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }
      .percentage {
        display: flex;
        align-items: center;
        position: absolute;
        top: 36%;
        left: 35%;
        z-index: 10;
        .number {
          color: #30355b;
          font-weight: bold;
          font-size: 34px;
          margin-right: 5px;
        }
      }
    }
  }
`
