import { EConversationTypes, INewMessageAlert, TCategory } from './actions'

export interface IPagination {
  total: number
  currentPage: number
  pageSize: number
}
interface IConversationStats {
  // queue: number
  // assigned: number
  closed: number
  all: number
  unread: number
  active: number
}

interface ILastMessage {
  text: string
  caption: string
  createdAt: string
  read: boolean
}

export interface ISingleConversation {
  _id: string
  last_message: ILastMessage[]
  name: string
  read: boolean
  status: 'open' | 'closed'
  engaged: boolean
}

export interface IMessages {
  messageId: string
  read: boolean
  createdAt: string
  contact: string
  text: string
  caption: string
  url?: string
  type: 'TEXT' | 'IMAGE' | 'AUDIO' | 'VIDEO' | 'DOCUMENT' | 'CLOSED'
  direction: 'INBOUND' | 'OUTBOUND'
}

export interface IAgentConversations {
  data: ISingleConversation[]
  pagination: IPagination
}

export interface IConversationMessages {
  data: IMessages[]
  metaData: {
    recipient: string
    contact: string
    country: string
    engaged: boolean
    read: boolean
    leadId?: string
    status: string
    agentData: {
      id: string
      AgentName: string
    }
  }
  pagination: IPagination
}

interface INewMessageAction {
  type: EConversationTypes.ADD_NEW_MESSAGE_ALERT
  payload: INewMessageAlert
}

interface IUpdateWebSocketConnectionAction {
  type: EConversationTypes.UPDATE_WEBSOCKET_CONNECTION
  status: boolean
}

interface IFetchStatsAction {
  type: EConversationTypes.FETCH_MESSAGE_STATS_SUCCESS
  payload: IConversationStats
}

interface IFetchConversationsAction {
  type: EConversationTypes.FETCH_CONVERSATIONS_SUCCESS
  category: TCategory
  payload: IAgentConversations
  replace: boolean
}

interface IFetchChatMessagesAction {
  type: EConversationTypes.FETCH_CHAT_MESSAGES_SUCCESS
  payload: IConversationMessages
  recipient: string
  replace?: boolean
}

interface IReadConversationAction {
  type: EConversationTypes.READ_CONVERSATION_SUCCESS
  recipient: string
}

interface ISearchConversationsAction {
  type: EConversationTypes.SEARCH_CONVERSATIONS_SUCCESS
  payload: IAgentConversations
  replace: boolean
  query: string
}

interface IClearSearchResults {
  type: EConversationTypes.CLEAR_SEARCH_RESULTS
}

type TConversationActions =
  | INewMessageAction
  | IFetchStatsAction
  | IFetchConversationsAction
  | IFetchChatMessagesAction
  | IUpdateWebSocketConnectionAction
  | IReadConversationAction
  | ISearchConversationsAction
  | IClearSearchResults

export interface IConversations {
  newMessageAlert: INewMessageAlert
  webSocketConnected: boolean
  stats: IConversationStats
  queue: IAgentConversations
  active: IAgentConversations
  closed: IAgentConversations
  all: IAgentConversations
  conversationMessages: {
    [recipientPhone: string]: IConversationMessages
  }
  search: IAgentConversations & { query: string }
  mode: 'normal' | 'search'
}

const initPagination = {
  total: 0,
  currentPage: 1,
  pageSize: 10
}

const initialState: IConversations = {
  newMessageAlert: {
    agentId: '',
    phone: '',
    status: 'new message'
  },
  webSocketConnected: false,
  stats: {
    // assigned: 0,
    all: 0,
    closed: 0,
    unread: 0,
    // queue: 0,
    active: 0,
  },
  queue: {
    data: [],
    pagination: initPagination
  },
  active: {
    data: [],
    pagination: initPagination
  },
  closed: {
    data: [],
    pagination: initPagination
  },
  all: {
    data: [],
    pagination: initPagination
  },
  conversationMessages: {},
  search: {
    data: [],
    pagination: initPagination,
    query: ''
  },
  mode: 'normal'
}

const reducer = (
  state: IConversations = initialState,
  action: TConversationActions
): IConversations => {
  switch (action.type) {
    case EConversationTypes.ADD_NEW_MESSAGE_ALERT: {
      return { ...state, newMessageAlert: action.payload }
    }
    case EConversationTypes.UPDATE_WEBSOCKET_CONNECTION:
      return { ...state, webSocketConnected: action.status }
    case EConversationTypes.FETCH_MESSAGE_STATS_SUCCESS: {
      return {
        ...state,
        stats: action.payload
      }
    }
    case EConversationTypes.FETCH_CONVERSATIONS_SUCCESS: {
      return {
        ...state,
        [action.category]: {
          data: action.replace
            ? action.payload.data
            : [...(state[action.category]?.data || []), ...action.payload.data],
          pagination: action.payload.pagination
        }
      }
    }
    case EConversationTypes.FETCH_CHAT_MESSAGES_SUCCESS: {
      return {
        ...state,
        conversationMessages: {
          ...state.conversationMessages,
          [action.recipient]: {
            data: action.replace
              ? action.payload.data
              : [
                  ...(state.conversationMessages[action.recipient]?.data || []),
                  ...action.payload.data
                ],
            metaData: action.payload.metaData,
            pagination: action.payload.pagination
          }
        }
      }
    }
    case EConversationTypes.READ_CONVERSATION_SUCCESS: {
      return {
        ...state,
        conversationMessages: {
          ...state.conversationMessages,
          [action.recipient]: {
            ...state.conversationMessages[action.recipient],
            metaData: {
              ...state.conversationMessages[action.recipient]?.metaData,
              read: true
            }
          }
        }
      }
    }
    case EConversationTypes.SEARCH_CONVERSATIONS_SUCCESS: {
      return {
        ...state,
        search: {
          data: action.replace
            ? action.payload.data
            : [...(state.search.data || []), ...action.payload.data],
          pagination: action.payload.pagination,
          query: action.query
        },
        mode: 'search'
      }
    }
    case EConversationTypes.CLEAR_SEARCH_RESULTS: {
      return {
        ...state,
        search: {
          data: [],
          pagination: initPagination,
          query: ''
        },
        mode: 'normal'
      }
    }
    default:
      return state
  }
}

export default reducer
