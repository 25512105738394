import React from 'react'
import { Chart } from 'react-google-charts'

interface IPieChart {
  fetchDashboard: any
}

export default function Piechart({ fetchDashboard }: IPieChart) {
  const value1 = fetchDashboard?.dashboard?.completedApplications
  const value2 = fetchDashboard?.dashboard?.disbursedStat?.count

  const data = [
    ['Task', 'Hours per Day'],
    ['Completed Applications', value1],
    ['Disbursed Loans', value2] // CSS-style declaration
  ]

  const options = {
    title: '',
    pieHole: 0.7,
    is3D: false,
    legend: {
      position: 'none',
      maxLines: 2,
      alignment: 'start',
      textStyle: { color: '#8C8F99', fontSize: 14 }
    },
    pieSliceText: 'none',
    slices: {
      0: { color: '#6F52ED' },
      1: { color: '#FFB800' }
    }
  }

  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="100%"
      data={data}
      options={options}
    />
  )
}
