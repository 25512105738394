export const UPLOAD_PHOTO = 'UPLOAD_PHOTO'
export const UPLOAD_PHOTO_SUCCESS = 'UPLOAD_PHOTO_SUCCESS'
export const UPLOAD_PHOTO_FAILURE = 'UPLOAD_PHOTO_FAILURE'

export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'

export const uploadPhoto = (file) => ({
  type: UPLOAD_PHOTO,
  file
})

export const uploadPhotoSuccess = (payload) => ({
  type: UPLOAD_PHOTO_SUCCESS,
  payload
})

export const uploadPhotofailure = () => ({
  type: UPLOAD_PHOTO_FAILURE
})

export const updateUser = (details) => ({
  type: UPDATE_USER,
  details
})

export const updateUserSuccess = (payload) => ({
  type: UPDATE_USER_SUCCESS,
  payload
})

export const updateUserFailure = () => ({
  type: UPDATE_USER_FAILURE
})
