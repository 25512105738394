import { combineReducers } from 'redux'
import auth from './auth/reducer'
import snackbar from './snackbar/reducer'
import settings from './settingsSection/reducer'
import leads from './leads/reducer'
import cars from './car/reducer'
import conversations from './conversations/reducer'
import loans from './loans/reducer'
import notifications from './notifications/reducer'
import isFetching from './isFetching/reducer'
import location from './location/reducer'
import events from './events/reducer'
import modal from './modal/reducer'

export default combineReducers({
  auth,
  events,
  isFetching,
  cars,
  location,
  leads,
  modal,
  settings,
  snackbar,
  conversations,
  notifications,
  loans
})
