import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WithSideMenu from '../../components/WithSideMenu'
import {
  LeadsTopSection,
  ActivityContainer,
  LeadsContainer,
  LeadsPageTitile,
  SearchContainer,
  SearchContentInactive,
  SearchIcon,
  CreateLeadBtn
} from './styles'
import api from '../../utils/api'
import dayjs from 'dayjs'
import TaskDropDown from '../../components/taskDropdown'
import ReminderDropDown from '../../components/reminderDropdown'
import ActivityTabs from './Sections/Tabs'

import CreateLead from '../Leads/CreateLead'
import { getSelectedCountry, getUserToken } from '../../store/auth/selectors'
import { showSnackbar } from '../../store/snackbar/actions'
import { getCountryCode } from '../../utils/country.util'
import { useTranslation } from 'react-i18next'
import { NewCreateLeadBtn } from '../Activities/styles'
import PlusIcon from '../../images/Plus Icon.svg'

const initialLead = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  service: '',
  status: '',
  value: '',
  lead_type: '',
  country: '',
  source: '',
  car_id: '',
  assign_lead_to_self: undefined
}

export default function ActivityView() {
  const dispatch = useDispatch()
  const country = useSelector(getSelectedCountry)
  const [checked, setChecked] = React.useState(false)
  const { t } = useTranslation()
  const [openModal, setOpenModal] = React.useState(false)
  const token = useSelector(getUserToken)
  const [newLead, setNewLead] = React.useState(initialLead)

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name
    let value: string | number = event.target.value
    if (key === 'value') {
      value = parseInt(value)
    }

    setNewLead((lead: any) => ({ ...lead, [key]: value }))
  }
  const initiateLeadCreation = () => {
    setOpenModal(true)
  }

  const handleCheck = () => {
    if (checked == false) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  const handleCreateLead = async () => {
    setOpenModal(false)

    const phoneNumberWithCode = getCountryCode(country) + newLead.phone
    const leadInfo = {
      ...newLead,
      phone: phoneNumberWithCode,
      assign_lead_to_self: checked
    }

    try {
      let createdLeadId

      if (newLead.car_id) {
        const car = await api.fetchCarData(token, newLead.car_id)
        const appointmentDetails = {
          car_data: {
            body_type: car.bodyType.name,
            fuel_type: car.fuelType,
            gear_type: car.model.wheelType,
            imported: false,
            make: car.model.make.name,
            mileage: car.mileage,
            model: car.model.name,
            year: car.year
          },
          car_id: car.id,
          country: car.country,
          details: {
            car: `${car.model.make.name} ${car.model.name} ${car.year}`,
            car_id: car.id,
            market_place_url: car.websiteUrl
          },
          email: newLead.email,
          firm_name: 'Autochek',
          id: '',
          name: `${newLead.first_name} ${newLead.last_name}`,
          phone: phoneNumberWithCode,
          pick_up_date: dayjs(),
          pick_up_location: {
            city: car.city,
            state: car.state,
            street: ''
          },
          pick_up_time: dayjs(),
          pick_up_time_end: dayjs(),
          pick_up_time_start: dayjs(),
          service: 'BUY_CAR',
          service_detail: 'buy-car',
          service_type_slug: 'buy-car',
          source: 'website',
          value: car.marketplacePrice
        }

        const appointment = await api.createAppointment(
          token,
          appointmentDetails
        )
        createdLeadId = appointment.leadId
      } else {
        const createdLead = await api.createLead(token, leadInfo)
        createdLeadId = createdLead.id
      }

      dispatch(showSnackbar('Successfully created lead', 'success'))
    } catch (err) {
      dispatch(showSnackbar('Error occured creating lead', 'error'))
    }
  }
  return (
    <WithSideMenu>
      <LeadsContainer>
        <LeadsTopSection>
          <LeadsPageTitile>{t('ACTIVITIES')}</LeadsPageTitile>
          <SearchContainer>
            <SearchIcon />
            <SearchContentInactive>
              {t('searchActivities')}
            </SearchContentInactive>
          </SearchContainer>

          <NewCreateLeadBtn onClick={() => initiateLeadCreation()}>
            <img src={PlusIcon} alt="plus-icon" /> {t('Create new lead')}
          </NewCreateLeadBtn>
          {/* <CreateLeadBtn onClick={() => initiateLeadCreation()} /> */}
          <TaskDropDown />
          <ReminderDropDown />
        </LeadsTopSection>
      </LeadsContainer>

      <ActivityContainer>
        <ActivityTabs />
        <CreateLead
          open={openModal}
          values={newLead}
          handleChange={handleInputChange}
          handleClose={() => setOpenModal(false)}
          handleCreate={handleCreateLead}
          handleCheck={handleCheck}
          checked={checked}
        />
      </ActivityContainer>
    </WithSideMenu>
  )
}
