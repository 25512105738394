import { StyledNotInterested } from './styles'
import React, { useState } from 'react'
import { Modal, Button, Form, Input, Select } from 'antd'
import api from '../../../utils/api'
import { showSnackbar } from '../../../store/snackbar/actions'
import { useParams } from 'react-router-dom'
import { getUserToken } from '../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}
interface INotInterested {
  notInterested: any
  setNotInterested: any
}

export default function NotInterestedModal({
  notInterested,
  setNotInterested
}: INotInterested) {
  const [form] = Form.useForm()
  const { Option } = Select
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const { t } = useTranslation()

  const onFinish = async (values: any) => {
    const data = { ...values, type: 'NOT_INTERESTED', loan_id: id }

    try {
      const notInterested = await api.createActivity(token, data)
      if (notInterested) {
        dispatch(showSnackbar('Reasons added successfully', 'success'))
      }
    } catch (err) {
      dispatch(showSnackbar('Reasons failed to be added', 'error'))
    }

    setTimeout(() => {
      setNotInterested(false)
    }, 500)
  }

  const handleCancel = () => {
    setNotInterested(false)
  }

  return (
    <Modal closable={false} visible={notInterested} width="550px" footer={null}>
      <StyledNotInterested>
        <div className="">
          <div className="header mb-1">{t('notInterested')}</div>
          <div className="text mb-4">
            {t('provideReasonCustomerNotInterested')}
          </div>

          <Form
            form={form}
            layout="vertical"
            name="horizontal_login"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Form.Item
              name="title"
              label={t('reasonForNotInterested')}
              rules={[{ required: true, message: t('pleaseSelectReason') }]}
            >
              <Select placeholder={t('selectYourReason')}>
                <Option value="Price_changed">{t('priceChanged')}</Option>
                <Option value="Car_different_from_inspected">
                  {t('carDifferentFromInspected')}
                </Option>
                <Option value="The_vehicle_was_no_more_available">
                  {t('vehicleNoMoreAvailable')}
                </Option>
                <Option value="Not_just_interested">
                  {t('notJustInterested')}
                </Option>
              </Select>
            </Form.Item>

            <Form.Item name="comment" label={t('additionalCommentOptional')}>
              <Input.TextArea rows={4} placeholder={t('comment')} />
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-start mt-3">
                <Button
                  className="cancel me-3"
                  type="primary"
                  onClick={handleCancel}
                >
                  {t('Cancel')}
                </Button>
                <Button className="submit" type="primary" htmlType="submit">
                  {t('save')}
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledNotInterested>
    </Modal>
  )
}
