import { select, call, put, takeLatest } from 'redux-saga/effects'
import { ELoansActionTypes } from './actions'
import { getUserToken, getSelectedCountry , isManager, getUserIdIfAgent} from '../auth/selectors'
import { showSnackbar } from '../snackbar/actions'
import api from '../../utils/api'
import { isFetching, doneFetching } from '../isFetching/actions'

function* fetchLoanLeadsSaga({ page }: any): any {

  // console.log(page, 'this')
  try {
    const token = yield select(getUserToken)
    const selectedCountry = yield select(getSelectedCountry)
   
    const agentId = yield select(getUserIdIfAgent)

    yield put(isFetching('loans'))
    const response = yield call(api.fetchLoans, token, selectedCountry, page, agentId )
    //  console.log(response, 'response from server')
    yield put({
      type: ELoansActionTypes.FETCH_LOAN_LEADS_SUCCESS,
      payload: response
    })
  } catch (error) {
    // yield put(showSnackbar('Unable to fetch loans', 'error'))
  } finally {
    yield put(doneFetching('loans'))
  }
}
function* fetchFilterLoanLeadsSaga({ query }: any): any {

  try {
    const token = yield select(getUserToken)
    const selectedCountry = yield select(getSelectedCountry)
    query.country = selectedCountry
    yield put(isFetching('loans'))
    const response = yield call(api.fetchFilterLoans,query, token, )

    yield put({
      type: ELoansActionTypes.FETCH_LOAN_LEADS_SUCCESS,
      payload: response
    })
  } catch (error) {
    // yield put(showSnackbar('Unable to fetch loans', 'error'))
  } finally {
    yield put(doneFetching('loans'))
  }
}

function* watchFetchLoans() {
  yield takeLatest(ELoansActionTypes.FETCH_LOAN_LEADS, fetchLoanLeadsSaga)
}
function* watchFetchFilterLoans() {
  yield takeLatest(ELoansActionTypes.FETCH_FILTER_LOAN_LEADS, fetchFilterLoanLeadsSaga)
}

const loanSagas: any[] = [watchFetchFilterLoans(),watchFetchLoans()]

export default loanSagas
