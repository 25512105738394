import { call, put, select, takeLatest } from 'redux-saga/effects'
import api from '../../utils/api'
import { getUserToken } from '../auth/selectors'
import { showSnackbar } from '../snackbar/actions'
import {
  uploadPhotoSuccess,
  uploadPhotofailure,
  updateUserSuccess,
  updateUserFailure,
  UPLOAD_PHOTO,
  UPDATE_USER
} from './actions'

function* uploadPhoto({ file }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.upload, token, file)

    yield put(uploadPhotoSuccess(response))
  } catch (error) {
    yield put(uploadPhotofailure())
    yield put(showSnackbar(error.response.data.message, 'error'))
  }
}

function* updateUser({ details }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.updateUser, token, details)

    yield put(showSnackbar('Updated Successfuly','success' ))
    yield put(updateUserSuccess(response))
  } catch (error) {
    yield put(updateUserFailure())
    yield put(showSnackbar(error.response.data.message, 'error'))
  }
}

function* watchUploadPhotoSaga() {
  yield takeLatest(UPLOAD_PHOTO, uploadPhoto)
}

function* watchUpdateUser() {
  yield takeLatest(UPDATE_USER, updateUser)
}

const settingsSagas = [watchUploadPhotoSaga(), watchUpdateUser()]

export default settingsSagas
