import React, { useState } from 'react'
import { StyledCarNotLiked } from './styles'
import { Button, Form, message, Spin } from 'antd'
import api from '../../../utils/api'
import { getUserToken, getSelectedCountry } from '../../../store/auth/selectors'
import { useSelector } from 'react-redux'
import { useMutation, useQueryClient } from 'react-query'
import { CAR_STATUS } from '../AvailableCar/constants'
import { useTranslation } from 'react-i18next'

export default function AcceptedModal({
  setInspOutcome,
  modalData,
  refetch,
  singleLoan,
  loanRefresh
}: any) {
  const [form] = Form.useForm()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const token = useSelector(getUserToken)
  const queryClient = useQueryClient()
  const country = useSelector(getSelectedCountry)
  const { t } = useTranslation()

  const updateSchedule: any = useMutation(
    (payload) => api.updateScheduleActivity(token, modalData.id, payload),
    {
      onSuccess: (__data) => {
        queryClient.invalidateQueries('book inspection')
        setSubmitting(false)
        message.success('Inspection booking updated successfully')
        setInspOutcome(false)
        refetch()
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(
          error?.response.data.error ||
            'Uh oh, something went wrong, please try again'
        )
      }
    }
  )

  const updateLoan: any = useMutation(
    (payload) => api.updateCarLoan(token, singleLoan?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loan-update')
        message.success('Loan updated')
        loanRefresh()
      },
      onError: (error: any) => {
        message.error(error?.response.data.error)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )
  const onFinish = async (fieldsValue: any) => {
    setSubmitting(true)

    const payloadData = {
      id: modalData.id,
      completed: true,
      show: true,
      country: country
    }
    const loanUpdatePayload = {
      status: CAR_STATUS.CAR_ACCEPTED,
      car_id: singleLoan?.carId
    }

    await updateSchedule.mutateAsync(payloadData)
    await updateLoan.mutateAsync(loanUpdatePayload)
  }

  const handleCancel = () => {
    setInspOutcome(false)
  }

  return (
    <StyledCarNotLiked>
      <Form
        form={form}
        layout="vertical"
        name="horizontal_login"
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item>
          {submitting ? (
            <div className="d-flex mt-4" style={{ justifyContent: 'center' }}>
              <Spin />
            </div>
          ) : (
            <div className="d-flex justify-content-start mt-3">
              <Button
                className="cancel me-3"
                type="primary"
                onClick={handleCancel}
              >
                {t('Cancel')}
              </Button>
              <Button className="submit" type="primary" htmlType="submit">
                {t('save')}
              </Button>
            </div>
          )}
        </Form.Item>
      </Form>
    </StyledCarNotLiked>
  )
}
