import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const RememberMe = styled.div`
  display: flex;
  justify-content: space-between;
  height: 21px;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  color: ${({ theme }) => theme.palette.neutral.grayChateau};
`

export const ForgotLink = styled(Link)`
  height: 21px;
  line-height: 21px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.regular};
  text-decoration-line: underline;
  color: ${({ theme }) => theme.palette.primary.dark};
`

export const CheckboxContainer = styled.span`
  margin-right: 10px;
`
