import React, { useCallback, useEffect, useState } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import useStyles from './newUserStyle'

import ChevronLeftIcon from '../../Loans/LoanDetails/svg/ChevronLeft'

import CircularProgress from '@material-ui/core/CircularProgress'
import Loader from '../../../components/Loader'
import { showSnackbar } from '../../../store/snackbar/actions'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import api from '../../../utils/api'
import { useSelector, useDispatch } from 'react-redux'
import {
  getUserToken,
  getUserId,
  getSelectedCountry
} from '../../../store/auth/selectors'

import { useHistory } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'

import { Link } from 'react-router-dom'
import {
  addLeadNote,
  getLeadNotes,
  getAllVehicleDetails,
  getCarData,
  updateLead,
  getCurrentActivity
} from '../../../store/leads/actions'
import SettingsMenu from '../SettingsMenu'
import { useTranslation } from 'react-i18next'

import {
  LoaderCover,
  SettingsContainer,
  ContentWrap,
  SettingsNavDummy
} from './styles'

const EditUser = (props: any) => {
  let serviceArray = [
    {
      name: 'Loan',
      active: false,
      serviceKey: 'FINANCING'
    },
    {
      name: 'Buy Car',
      active: false,
      serviceKey: 'BUY_CAR'
    },
    {
      name: 'Test-Drive Car',
      active: false,
      serviceKey: 'TEST_DRIVE_CAR'
    },
    {
      name: 'Fix Car',
      active: false,
      serviceKey: 'FIX_CAR'
    },

    {
      name: 'Sell Car',
      active: false,
      serviceKey: 'SELL_CAR'
    },

    {
      name: 'Buy Truck',
      active: false,
      serviceKey: 'BUY_TRUCK'
    },
    {
      name: 'Test-Drive Truck',
      active: false,
      serviceKey: 'TEST_DRIVE_TRUCK'
    },
    {
      name: 'Fix Truck',
      active: false,
      serviceKey: 'FIX_TRUCK'
    },
    {
      name: 'Sell Truck',
      active: false,
      serviceKey: 'SELL_TRUCK'
    },
    {
      name: 'After Sales',
      active: false,
      serviceKey: 'AFTER_SALES'
    }

    // {

    //     name:'Whatsapp',
    //     active:false
    // },
    // {

    //     name:'Loans',
    //     active:false
    // },
  ]

  const classes = useStyles()

  const token = useSelector(getUserToken)
  const [userLoanNote, setUserNoteText] = React.useState('')
  const [userId, setUserId] = React.useState('')
  const [fullname, setfullname] = React.useState('')

  const [email, setEmail] = React.useState('')

  const [loader, setLoader] = React.useState(false)
  const [apiRoleId, setApiRoleId] = React.useState('')
  const [checkStatus, setCheck] = React.useState(false)
  const [startRegistration, setStartRegisteration] = React.useState(false)

  const [agentRole, setAgentRole] = useState([])
  const selectedCountry = useSelector(getSelectedCountry)
  let history = useHistory()
  const dispatch = useDispatch()

  const country = useSelector(getSelectedCountry)
  const [services, setServices] = React.useState(serviceArray)

  const [customerAccept, setCustomerAccept] = React.useState(false)
  const [agent, setSavedAgent] = React.useState({
    id: ''
  })

  const { t } = useTranslation()

  const checkAllRoles = (e?: any) => {
    let allRoles = [] as any
    if (e.target.checked) {
      setCheck(true)

      allRoles = services.filter((role) => {
        role.active = true
        return role
      })
    }

    if (!e.target.checked) {
      setCheck(false)
      allRoles = services.filter((role) => {
        role.active = false
        return role
      })
    }
    setServices(allRoles)
  }

  const startReg = () => {
    setStartRegisteration(true)
  }

  const checkOneSingleRole = (roleName: string) => {
    let allRoles = [] as any

    allRoles = services.filter((role) => {
      if (roleName == role.name && !role.active) {
        role.active = true
      } else if (roleName == role.name && role.active) {
        role.active = false
      }

      return role
    })

    setServices(allRoles)
  }

  useEffect(() => {
    let savedAgent: any
    savedAgent = localStorage.getItem('agent')
    savedAgent = JSON.parse(savedAgent)
    setfullname(savedAgent.firstname + ' ' + savedAgent.lastname)
    setEmail(savedAgent.email)
    setSavedAgent(savedAgent)

    let allServices = [] as any

    allServices = services.filter((service) => {
      savedAgent?.serviceAreas?.filter((area: any) => {
        if (area === service.serviceKey) {
          service.active = true
        }
      })
      return service
    })

    setServices(allServices)
  }, [])

  const updateAgentService = useCallback(
    async (id, servicesFromEffect) => {
      try {
        // console.log(servicesFromEffect, 'service')

        let servicesArray = [] as any
        servicesFromEffect.filter((service: any) => {
          if (service.active) {
            servicesArray.push(service.serviceKey.trim())
          }
        })

        let query = {
          active: true,
          service_areas: servicesArray
        }

        const response = await api.registerAgent(token, query, id)
        dispatch(
          showSnackbar('Agent service area updated successfully', 'success')
        )
        history.push('/settings/agents')
        setStartRegisteration(false)
      } catch (e) {
        dispatch(showSnackbar('Cannot update agent service area', 'error'))
        setStartRegisteration(false)
      }
    },
    [token]
  )

  useEffect(() => {
    if (!startRegistration) return
    updateAgentService(agent.id, services)
  }, [startRegistration])

  return (
    <WithSideMenu>
      <SettingsContainer>
        <SettingsNavDummy />
        <SettingsMenu />
        <ContentWrap>
          <div
            style={{
              background: '#F6F7FB',
              minHeight: '100vh',
              padding: '1em 1.5em'
            }}
          >
            <Link
              to="/settings/agents"
              // onClick={history.goBack}
              style={{ marginLeft: '-7px' }}
              className={`${classes.headerSection} ${classes.backBtn}`}
            >
              <ChevronLeftIcon />
              {t('Agents')}
            </Link>
            <div style={{ margin: '1rem 0rem' }}>
              <span style={{ fontWeight: 'bold', fontSize: '22px' }}>
                {t('changeAgentServiceArea')}
              </span>
            </div>

            <div
              style={{
                background: '#fff',
                minHeight: '85vh',
                borderRadius: '10px',
                padding: '2em',
                border: '1px solid #E8E8E8'
              }}
            >
              <p
                style={{ fontSize: '18px', color: '#003049', marginTop: '2em' }}
              >
                {t('agentDetails')}
              </p>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginRight: '20rem'
                }}
              >
                <div style={{ flexBasis: '48%', marginBottom: '1rem' }}>
                  <div style={{ marginBottom: '7px' }}>
                    <span style={{ color: '#343861' }}>{t('agentName')}</span>
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                  <div>
                    <input
                      type="text"
                      disabled
                      value={fullname}
                      style={{
                        minWidth: '100%',
                        borderRadius: '3px',
                        height: '40px',
                        border: '1px solid rgba(49, 53, 91, 0.29)',
                        padding: '1em'
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginRight: '20rem'
                }}
              >
                <div style={{ flexBasis: '48%', marginBottom: '1rem' }}>
                  <div style={{ marginBottom: '7px' }}>
                    <span style={{ color: '#343861' }}>{t('email')}</span>
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                  <div>
                    <input
                      disabled
                      value={email}
                      type="email"
                      style={{
                        minWidth: '100%',
                        borderRadius: '3px',
                        height: '40px',
                        border: '1px solid rgba(49, 53, 91, 0.29)',
                        padding: '1em'
                      }}
                    />
                  </div>
                </div>
              </div>
              <p
                style={{ fontSize: '18px', color: '#003049', marginTop: '1em' }}
              >
                {t('service')}
              </p>

              <div
                style={{
                  display: 'flex',
                  flexFlow: 'row',
                  justifyContent: 'space-between',
                  flexWrap: 'wrap',
                  marginRight: '20rem'
                }}
              >
                <div style={{ flexBasis: '75%', marginBottom: '2rem' }}>
                  <div style={{ marginBottom: '7px' }}>
                    <span style={{ color: '#343861' }}>
                      {t('Select Services')}
                    </span>
                    <span style={{ color: 'red' }}>*</span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexWrap: 'wrap'
                    }}
                  >
                    {services?.map((role: any) => (
                      <div
                        className={[
                          classes.roleBtn,
                          role.active ? classes.activeRole : ''
                        ].join(' ')}
                        key={role.name}
                        onClick={() => checkOneSingleRole(role.name)}
                      >
                        {t(role.name)}
                      </div>
                    ))}
                  </div>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkStatus}
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        onChange={(e) => checkAllRoles(e)}
                        name="checkedA"
                      />
                    }
                    label={t('Select all')}
                  />
                </div>
                <div style={{ flexBasis: '20%', marginBottom: '2rem' }}>
                  <div>
                    {startRegistration ? (
                      <LoaderCover>
                        <CircularProgress size={20} />
                      </LoaderCover>
                    ) : (
                      <div
                        className={classes.saveBtn}
                        onClick={() => startReg()}
                      >
                        {t('save')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContentWrap>
      </SettingsContainer>
    </WithSideMenu>
  )
}

export default EditUser
