import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { useTranslation } from 'react-i18next'

type CustomStylesType = {
  bgColor?: string
  color?: string
  padding?: string
  fontSize?: string
  height?: string
  fontWeight?: number
  marginTop: number
}
type Props = {
  label?: string
  options: {
    label: string
    value: string
  }[]
  value: string
  onSelect: (event: any) => void
  name: string
  customStyles: CustomStylesType
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: 120,
    marginTop: (customStyles: CustomStylesType) => customStyles.marginTop
  },
  selectEmpty: {
    marginTop: 0
  },
  menuItem: {
    color: (customStyles: CustomStylesType) => customStyles.color,
    backgroundColor: (customStyles: CustomStylesType) => customStyles.bgColor,
    '&:before': {
      border: 'none!important;'
    },
    '&:after': {
      border: 'none!important;'
    },
    '&:focus': {
      border: 'none'
    },
    height: (customStyles: CustomStylesType) => customStyles.height,
    fontSize: (customStyles: CustomStylesType) => customStyles.fontSize,
    fontWeight: (customStyles: CustomStylesType) => customStyles.fontWeight,
    padding: 5,
    paddingLeft: 9
  },
  icon: {
    fill: (customStyles: CustomStylesType) => customStyles.color
  }
}))

const SimpleSelect = ({
  label,
  options,
  onSelect,
  value,
  customStyles,
  name
}: Props) => {
  const classes = useStyles(customStyles)

  const { t } = useTranslation()

  return (
    <FormControl className={classes.formControl}>
      {label && (
        <InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
      )}
      <Select
        value={value}
        name={name}
        onChange={onSelect}
        displayEmpty
        className={classes.menuItem}
        inputProps={{
          'aria-label': 'no label',
          classes: {
            icon: classes.icon
          }
        }}
      >
        {options.map((option) => (
          <MenuItem value={option.value} key={option.value}>
            {t(`${option.label}`)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default SimpleSelect
