import {
  SHOW_VEHICLE_MODAL,
  HIDE_VEHICLE_MODAL,
  SHOW_SELL_CAR_MODAL,
  HIDE_SELL_CAR_MODAL
} from './actions'

const InitialState = {
  showVehicleModal: false,
  showSellCarModal: false
}

const modal = (state = InitialState, action) => {
  switch (action.type) {
    case SHOW_VEHICLE_MODAL:
      return {
        ...state,
        showVehicleModal: true
      }
    case HIDE_VEHICLE_MODAL:
      return {
        ...state,
        showVehicleModal: false
      }
    case SHOW_SELL_CAR_MODAL:
      return {
        ...state,
        showSellCarModal: true
      }
    case HIDE_SELL_CAR_MODAL:
      return {
        ...state,
        showSellCarModal: false
      }
    default:
      return state
  }
}

export default modal
