import {
  call,
  all,
  put,
  select,
  takeLatest,
  takeEvery,
  delay
} from 'redux-saga/effects'
import api from '../../utils/api'
import { getSelectedCountry, getUserToken } from '../auth/selectors'
import { getAgentIds, getLeadIdsToAgent } from '../leads/selectors'
import {
  getCurrentLeadId,
  getAllBuyCarId,
  scheduleObject,
  loanCar,
  createCarBuyAppointmentLoader,
  getAllMarketPlaceCars,
  interestDataFromStore
} from './selectors'
import { showSnackbar } from '../snackbar/actions'
import { isFetching, doneFetching } from '../isFetching/actions'
import { addSchedule } from '../events/actions'
import {
  FETCH_LEADS,
  FETCH_LEAD,
  UPDATE_LEAD,
  FETCH_AGENTS,
  LINK_USER,
  CREATE_APPOINTMENT,
  GET_SERVICE_INTERESTS,
  FETCH_LOCATIONS,
  GET_CAR_DATA,
  GET_CAR_MEDIA,
  GET_CURRENT_ACTIVITY,
  GET_CAR_INSPECTION,
  GET_SIMILAR_CAR,
  GET_CAR_OWNER,
  GET_ALL_VEHICLE_DETAILS,
  GET_CARS_FROM_MARKET,
  GET_CAR_DEALER,
  GET_CAR_INSPECTOR,
  GET_SALES_OFFICERS,
  CREATE_LEAD,
  UPDATE_CRM_NOTIFICATION,
  UPDATE_SERVICE_INTEREST,
  DELETE_SERVICE_INTEREST,
  CREATE_BUY_CAR_APPOINTMENT,
  GET_CRM_NOTIFICATIONS,
  ASSIGN_LEADS_TO_AGENT,
  GET_LEAD_NOTES,
  ADD_LEAD_NOTE,
  updateServiceInterestFailure,
  createLeadSuccess,
  createLeadFailure,
  getCarDealerSuccess,
  getCarDealerError,
  getCarData,
  saveActivitySuccess,
  getCarOwner,
  getCarDealer,
  getCarMediaData,
  getSimilarCarDetails,
  getCarInspectionDetails,
  getAllVehicleDetailsSuccess,
  getAllVehicleDetailsError,
  getAllVehicleDetails,
  getSimilarCarSuccess,
  getSimilarCarError,
  getCarInspectionSuccess,
  getCarInspectionError,
  getCarMediaSuccess,
  getCarMediaError,
  getCarSuccess,
  getCarError,
  getCarOwnerSuccess,
  getMarketPlaceCarsSuccess,
  getCarOwnerError,
  fetchLocationsSucces,
  fetchLocationsError,
  getServiceInterestsSuccess,
  getServiceInterestsError,
  createAppointmentsFailure,
  createAppointmentsSuccess,
  saveActivityPagination,
  createBuyCarApp,
  stopActivityLoader,
  linkUserSucces,
  linkUserError,
  updateLeadSuccess,
  updateLeadFailure,
  fetchLeadsSuccess,
  fetchLeads,
  fetchLeadSuccess,
  fetchLeadsFailure,
  fetchLeadFailure,
  fetchAgentsSuccess,
  fetchAgentsFailure,
  getCarInspector,
  getCarInspectorSuccess,
  getCarInspectorError,
  updateServiceInterestSuccess,
  getMarketPlaceCars,
  saveSalesOfficerSuccess,
  saveMarketCarPagination,
  getServiceInterests,
  saveAgentsTasks,
  saveCrmNotifications,
  saveSummaryNotes,
  saveLeadNotes,
  saveLoanNotePagination,
  emptyAssignedList,
  sendAssignedLeadsToAgent
} from './actions'

import { getLeadDetails } from '../../store/leads/selectors'

function* createLead({ details, queries }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.createLead, token, details)

    yield put(createLeadSuccess(response))
    yield put(fetchLeads(queries))
  } catch (error) {
    yield put(createLeadFailure())
  }
}

function* getLeads({ queries }) {
  try {
    yield put(isFetching('leads'))
    const token = yield select(getUserToken)
    const response = yield call(api.fetchLeads, token, queries)
  
let dataFromApi = response

let filterLeads = response.leads.filter((data)=>{


  if(!data.source){

      data.source = data.serviceInterest[0].source? data.serviceInterest[0].source:''

  }
return data
})

// console.log(filterLeads, 'filter')

dataFromApi.leads = filterLeads
    yield put(fetchLeadsSuccess(dataFromApi))
    yield put(doneFetching('leads'))
  } catch (error) {
    yield put(fetchLeadsFailure())
  }
}

function* getLead({ id }) {
  try {
    yield put(isFetching('lead'))
    const token = yield select(getUserToken)
    const response = yield call(api.fetchLead, token, id)
  
    yield put(fetchLeadSuccess(response))
    yield put(doneFetching('lead'))
  } catch (error) {
    yield put(fetchLeadFailure())
  }
}

function* updateLead({ details, id }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.updateLead, token, details, id)

    yield put(updateLeadSuccess(response))
    yield put(showSnackbar('Updated Successfuly', 'success'))
  } catch (error) {
    yield put(updateLeadFailure())
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}

function* getAgents() {
  try {
    yield put(isFetching('agents'))
    const token = yield select(getUserToken)
    const country = yield select(getSelectedCountry)
    const response = yield call(api.fetchAgents, token, country)

    yield put(fetchAgentsSuccess(response))

    yield put(doneFetching('agents'))
  } catch (error) {
    yield put(fetchAgentsFailure())
  }
}
function* getMarketCars({ query }) {
  try {
    const token = yield select(getUserToken)

    const responseFromCarData = yield call(
      api.fetchMarketPlaceCarData,
      query,
      token
    )

    yield put(getMarketPlaceCarsSuccess(responseFromCarData.result))
    yield put(saveMarketCarPagination(responseFromCarData.pagination))
  } catch (error) {}
}

function* linkLeadToUser() {
  try {
    const token = yield select(getUserToken)
    const id = yield select(getCurrentLeadId)
    const response = yield call(api.linkLeadUser, token, id)

    yield put(linkUserSucces(response))
    yield put(showSnackbar('User Linked Successfully', 'success'))
  } catch (error) {
    yield put(linkUserError())
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}

function* createAppointment({ payload }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.createAppointment, token, payload)

    const scheduleDataFromAppointment = yield select(scheduleObject)
    scheduleDataFromAppointment.appointment_id = response.id

    yield put(createAppointmentsSuccess(response))
    yield put(addSchedule(scheduleDataFromAppointment))
    yield put(showSnackbar('created', 'success'))
  } catch (error) {
    yield put(createAppointmentsFailure())
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}
function* createBuyCarAppointment({ payload }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.createAppointment, token, payload)
    const lead = yield select(getLeadDetails)
    yield put(createAppointmentsSuccess(response))
    yield put(getServiceInterests(`${lead.id}`))
    yield put(showSnackbar('Interest added', 'success'))
  } catch (error) {
    yield put(createAppointmentsFailure())
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}

function* getSalesOfficer() {
  try {
    const token = yield select(getUserToken)

    const response = yield call(api.fetchSalesOfficer, token)
    yield put(saveSalesOfficerSuccess(response.users))
  } catch (error) {
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}
function* getSericeInterests({ id }) {
  try {
    yield put(isFetching('interests'))
    const token = yield select(getUserToken)

    const response = yield call(api.fetchServiceInterests, token, id)

    yield put(getServiceInterestsSuccess(response))
    // console.log(response, 'serviceeeeeeeeeeeee')
    const carIds = yield select(getAllBuyCarId)
    yield put(getAllVehicleDetails(carIds))
    yield put(doneFetching('interests'))
  } catch (error) {
    yield put(getServiceInterestsError())
  }
}

function* fetchAllLocations() {
  try {
    yield put(isFetching('locations'))
    const token = yield select(getUserToken)
    const country = yield select(getSelectedCountry)
    const response = yield call(api.fetchLocations, token, country)

    yield put(fetchLocationsSucces(response))
    yield put(doneFetching('locations'))
  } catch (error) {
    yield put(fetchLocationsError())
  }
}

function* getCar({ carId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCarData, token, carId)

    yield put(getCarOwner(response.ownerId, carId))
    yield put(getCarDealer(response.carManagerId, carId))
    yield put(getCarSuccess(response))

    const lead = yield select(getLeadDetails)

    let buyCarAppointment = {
      name: `${lead.firstName} ${lead.lastName}`,
      email: `${lead.email}`,
      phone: `${lead.phone}`,
      car_data: {
        make: response.model.make.name,
        model: response.model.name,
        year: response.year,
        body_type: response.bodyType.name,
        mileage: response.mileage,
        bodyType: 'N/A',
        fuelType: 'N/A',
        gearType: 'N/A',
        imported: true
      },

      pick_up_time: new Date(),
      pickUpTime: new Date(),
      pickUpDate: new Date(),
      pick_up_time_end: new Date(),
      pick_up_time_start: new Date(),
      service: 'BUY_CAR',
      service_detail: 'buy-car',

      car_id: response.id,
      pick_up_location: {
        state: response.state,
        city: response.city
      },
      source: 'crm',
      country: response.country
    }
    const startAppointment = yield select(createCarBuyAppointmentLoader)
    if (startAppointment) {
      yield put(createBuyCarApp(buyCarAppointment))
    
    }
  } catch (error) {
    yield put(getCarError())
  }
}

function* getCarMedia({ carId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCarMedia, token, carId)
    yield put(getCarMediaSuccess(response, carId))
  } catch (error) {
    yield put(getCarMediaError())
  }
}

function* getCarInspection({ carId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCarInspectionDetails, token, carId)
    yield put(getCarInspector(response.inspectorId, carId))
    yield put(getCarInspectionSuccess(response))
  } catch (error) {
    yield put(getCarInspectionError())
  }
}

function* getCarInspectorData({ id, carId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchUser, token, id)

    yield put(getCarInspectorSuccess(response, carId))
  } catch (error) {
    yield put(getCarInspectorError())
  }
}

function* getSimilarCar({ carId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchSimilarCars, token, carId)
    yield put(getSimilarCarSuccess(response, carId))
  } catch (error) {
    yield put(getSimilarCarError())
  }
}

function* getAllCarDetails({ carIds }) {
  try {
    yield put(isFetching('allCarDetails'))

    // const loanCarFromStore = yield select(loanCar)
    // if(loanCarFromStore){

    //   carIds.push(loanCarFromStore)
    // }
  
    //  console.log(carIds, 'carIdssssssss')
    yield all(carIds.map((carId) => put(getCarData(carId))))
    yield all(carIds.map((carId) => put(getCarMediaData(carId))))
    yield all(carIds.map((carId) => put(getCarInspectionDetails(carId))))
    yield all(carIds.map((carId) => put(getSimilarCarDetails(carId))))

    yield put(getAllVehicleDetailsSuccess())
    yield put(doneFetching('allCarDetails'))
  } catch (error) {
    yield put(getAllVehicleDetailsError())
  }
}

function* getCarOwnerDetails({ userId, carId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchUser, token, userId)

    yield put(getCarOwnerSuccess(response, carId))
  } catch (error) {
    yield put(getCarOwnerError())
  }
}
function* deleteServiceCarInterest({ id }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.deleteServiceInterestFromIndex, token, id)

    const lead = yield select(getLeadDetails)

    yield put(getServiceInterests(`${lead.id}`))
    yield put(showSnackbar('Interest deleted successfully', 'success'))
  } catch (error) {
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}

function* getCarDealerDetails({ userId, carId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCarDealer, token, userId)

    yield put(getCarDealerSuccess(response, carId))
  } catch (error) {
    yield put(getCarDealerError())
  }
}

function* getCrmNotifications({ payload }) {
  try {
    const token = yield select(getUserToken)
    const country = yield select(getSelectedCountry)
    payload.country = country
    const response = yield call(api.getAgentNotifications, payload, token)

    let notificatiodata = response.data

    notificatiodata.filter((item) => {
      return (item.content = JSON.parse(item.content))
    })


    notificatiodata.sort(function (a, b) {
      return new Date(b.updatedAt) - new Date(a.updatedAt)
    })
    yield put(saveAgentsTasks(response))
    yield put(saveCrmNotifications(notificatiodata))
  } catch (error) {
    yield put(getCarDealerError())
  }
}

function* updateCrmNotification({ payload, id }) {
  try {
    const token = yield select(getUserToken)

    const response = yield call(api.updateCrmNotif, payload, id, token)
  } catch (error) {
    yield put(getCarDealerError())
  }
}
function* sendLeadToagents() {
  try {
    // yield put(isFetching('leads'))
    const token = yield select(getUserToken)
    const getAssignedLeads = yield select(getLeadIdsToAgent)
    const getAssignedAgent = yield select(getAgentIds)

let lead_ids = [...getAssignedLeads]
let agent_ids = [getAssignedAgent]

    let bulkObject ={
      lead_ids,
      agent_ids
  }

    const response = yield call(api.bulkAgentTransfer,token, bulkObject )
    yield put(showSnackbar('Assigned leads successfully', 'success'))

    // yield put(doneFetching('leads'))
  } catch (error) {
    // console.log(error, 'error')
    yield put(showSnackbar('Leads failed to be assigned', 'error'))

    yield put(doneFetching('leads'))

  }
}
function* addLeadNote({ payload }) {
  try {
    const token = yield select(getUserToken)

    const response = yield call(api.addNote,token, payload)
    yield put(showSnackbar('Note added successfully', 'success'))
 
  } catch (error) {
    yield put(getCarDealerError())
  }
}
function* getLeadNotes({payload, typeOfNote, currentPage}) {
  try {
    const token = yield select(getUserToken)

    let response;
    const responseLoanNote = yield call(api.fetchNotes, payload, 'LOAN_NOTE', currentPage, token)
    if(typeOfNote !== 'LOAN_NOTE'){

     response = yield call(api.fetchNotes, payload, 'REGULAR', currentPage, token)

    }

  
    yield put(saveLoanNotePagination(responseLoanNote.pagination))
    yield put(saveSummaryNotes(responseLoanNote.note))
    yield put(saveLeadNotes(response.note))
  } catch (error) {
    yield put(getCarDealerError())
  }
}

function* getActivity({ queryType }) {
  try {
    const token = yield select(getUserToken)

    const country = yield select(getSelectedCountry)
    queryType.country = country
    queryType.page_size = 12
    const response = yield call(api.getActivityType, queryType, token)

    let dataFromSchedule = response.schedule.filter((data) => {
      return !data.completed
    })

    // console.log(dataFromSchedule, queryType, 'schedullleeeeeeeeeeeeee')
    dataFromSchedule.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt)
    })

    yield put(saveActivitySuccess(dataFromSchedule))
    yield put(saveActivityPagination(response.pagination))
  } catch (error) {
    yield put(stopActivityLoader())
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}

function* updateServiceInterest({ details, id, leadId }) {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.updateServiceInterest, token, details, id)

    yield put(updateLeadSuccess(response))
    yield put(showSnackbar('Updated Successfuly', 'success'))
    yield put(updateServiceInterestSuccess())
    yield delay(1000)
    window.location.reload()
  } catch (error) {
    yield put(updateServiceInterestFailure())
    yield put(showSnackbar('An Error Occured', 'error'))
  }
}

function* watchCreateLead() {
  yield takeLatest(CREATE_LEAD, createLead)
}

function* watchGetLeads() {
  yield takeLatest(FETCH_LEADS, getLeads)
}

function* watchGetLead() {
  yield takeLatest(FETCH_LEAD, getLead)
}

function* watchUpdateLead() {
  yield takeLatest(UPDATE_LEAD, updateLead)
}

function* watchGetAgents() {
  yield takeLatest(FETCH_AGENTS, getAgents)
}

function* watchLinkUser() {
  yield takeLatest(LINK_USER, linkLeadToUser)
}

function* watchCreateAppointment() {
  yield takeLatest(CREATE_APPOINTMENT, createAppointment)
}

function* watchGetSericeInterests() {
  yield takeLatest(GET_SERVICE_INTERESTS, getSericeInterests)
}

function* watchFetchAllLocations() {
  yield takeLatest(FETCH_LOCATIONS, fetchAllLocations)
}

function* watchGetCar() {
  yield takeEvery(GET_CAR_DATA, getCar)
}

function* watchGetCarMedia() {
  yield takeEvery(GET_CAR_MEDIA, getCarMedia)
}

function* watchGetCarInspection() {
  yield takeEvery(GET_CAR_INSPECTION, getCarInspection)
}

function* watchGetSimilarCar() {
  yield takeEvery(GET_SIMILAR_CAR, getSimilarCar)
}

function* watchGetAllCarDetails() {
  yield takeLatest(GET_ALL_VEHICLE_DETAILS, getAllCarDetails)
}

function* watchGetCarOwner() {
  yield takeEvery(GET_CAR_OWNER, getCarOwnerDetails)
}

function* watchGetCarDealer() {
  yield takeEvery(GET_CAR_DEALER, getCarDealerDetails)
}

function* watchGetCarInspectorData() {
  yield takeEvery(GET_CAR_INSPECTOR, getCarInspectorData)
}

function* watchUpdateServiceInterest() {
  yield takeLatest(UPDATE_SERVICE_INTEREST, updateServiceInterest)
}
function* watchGetMarketCars() {
  yield takeLatest(GET_CARS_FROM_MARKET, getMarketCars)
}
function* watchCreateBuyCarAppointment() {
  yield takeLatest(CREATE_BUY_CAR_APPOINTMENT, createBuyCarAppointment)
}
function* watchDeleteServiceInterest() {
  yield takeLatest(DELETE_SERVICE_INTEREST, deleteServiceCarInterest)
}
function* watchCurrentActivity() {
  yield takeLatest(GET_CURRENT_ACTIVITY, getActivity)
}
function* watchGetSalesOfficer() {
  yield takeLatest(GET_SALES_OFFICERS, getSalesOfficer)
}

function* watchGetCrmNotifications() {
  yield takeLatest(GET_CRM_NOTIFICATIONS, getCrmNotifications)
}
function* watchSendLeadsToagents() {
  yield takeLatest(ASSIGN_LEADS_TO_AGENT, sendLeadToagents)
}
function* watchUpdateCrmNotifications() {
  yield takeLatest(UPDATE_CRM_NOTIFICATION, updateCrmNotification)
}
function* watchAddLeadNote() {
  yield takeLatest(ADD_LEAD_NOTE, addLeadNote)
}
function* watchGetLeadNotes() {
  yield takeLatest(GET_LEAD_NOTES, getLeadNotes)
}

const leadsSagas = [
  watchCreateLead(),
  watchGetLeads(),
  watchGetLead(),
  watchGetCar(),
  watchAddLeadNote(),
  watchSendLeadsToagents(),
  watchGetLeadNotes(),
  watchUpdateLead(),
  watchGetAgents(),
  watchLinkUser(),
  watchGetCarMedia(),
  watchGetMarketCars(),
  watchDeleteServiceInterest(),
  watchGetCrmNotifications(),
  watchCurrentActivity(),
  watchGetSalesOfficer(),
  watchUpdateCrmNotifications(),
  watchGetSimilarCar(),
  watchCreateBuyCarAppointment(),
  watchGetCarDealer(),
  watchGetCarOwner(),
  watchGetAllCarDetails(),
  watchGetCarInspection(),
  watchCreateAppointment(),
  watchGetSericeInterests(),
  watchFetchAllLocations(),
  watchGetCarInspectorData(),
  watchUpdateServiceInterest()
]

export default leadsSagas
