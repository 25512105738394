import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import SearchI from '../../../images/search.svg'

export const CategoriesWrapper = styled.div`
  min-width: 242px;
  height: 100vh;
  background: ${({ theme }) => theme.palette.neutral.ghostWhite};
`

export const CategoriesTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: ${({ theme }) => theme.font.size.H4};
  font-weight: ${({ theme }) => theme.font.weight.bolder};
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
  padding-top: 27px;
  padding-left: 16px;
  padding-right: 8px;
  padding-bottom: 10px;
`

export const ConnectionIndicator = styled.div<{ connected: boolean }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ connected }) => (connected ? '#4caf50' : '#CAD0D9')};
`

export const CategoriesSearchBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme }) => theme.palette.background.default};
  height: 40px;
  padding: 10px;
  margin: 0 8px;
  border: 1px solid ${({ theme }) => theme.palette.neutral.solitude};
  border-radius: 4px;
  border-radius: 4px;

  & input {
    flex-grow: 1;
    font-size: ${({ theme }) => theme.font.size.md};
    color: ${({ theme }) => theme.palette.neutral.grayishBlue};
    background: transparent;
    border: none;
    height: 100%;
    outline: none;
    -moz-appearance: textfield;

    ::placeholder {
      color: ${({ theme }) => theme.palette.neutral.grayChateau};
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
`

export const SearchQuery = styled.div`
  display: flex;
  align-items: center;
  background: #e2e0ea;
  color: #474f58;
  font-size: 14px;
  padding: 5px;
  border-radius: 4px;

  span {
    font-weight: bold;
    color: #474f58;
    cursor: pointer;
  }
`

export const CategoriesList = styled.ul`
  margin-top: 20px;
  padding: 0;
`

export const CategoriesListItem = styled.li<{ active?: boolean }>`
  background: ${({ theme, active }) =>
    active ? '#C6C1D06b' : theme.palette.neutral.ghostWhite};
  cursor: pointer;

  &:hover {
    background: ${({ active }) => (active ? '#C6C1D06b' : '#C6C1D01A')};
  }
`

export const StyledCategoryLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 10px 16px;
  text-decoration: none;
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
`

export const CategoryName = styled.span`
  flex-grow: 1;
`

export const MessagesPerCategory = styled.span`
  margin-left: auto;
`

export const SearchIcon = styled.div`
  flex-position: flex-end;
  border-radius: 50%;
  height: 24px;
  width: 24px;
  padding: 2px;
  background-image: url(${SearchI});
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;

  &:hover {
    background-color: #f6f7fb;
  }
`

export const CategoryIcon = styled.div<{ icon: string }>`
  height: 20px;
  width: 20px;
  margin-right: 12px;
  background-image: ${({ icon }) => `url(${icon})`};
  background-repeat: no-repeat;
`
