export const SIGN_IN = 'SIGN_IN'
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS'
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_ERROR = 'LOGOUT_ERROR'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR'
export const SEND_OTP = 'SEND_OTP'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const SEND_OTP_ERROR = 'SEND_OTP_ERROR'
export const VERIFY_OTP = 'VERIFY_OTP'
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS'
export const VERIFY_OTP_ERROR = 'VERIFY_OTP_ERROR'
export const SET_COUNTRY = 'SET_COUNTRY'
export const GET_USER_LOCATION = 'GET_USER_LOCATION'
export const SAVE_USER_LOCATION = 'SAVE_USER_LOCATION'

export const saveUserLocation =(payload) => ({
  type: SAVE_USER_LOCATION,
  payload
})
export const getUserLocation =(country) => ({
  type: GET_USER_LOCATION,
  country
})
export const setCountry =(country) => ({
  type: SET_COUNTRY,
  country
})

export const signIn = (email, password, history) => ({
  type: SIGN_IN,
  email,
  password,
  history
})

export const signInSuccess = (payload) => ({
  type: SIGN_IN_SUCCESS,
  payload,
  
})

export const signInError = () => ({
  type: SIGN_IN_ERROR
})

export const logout = (history) => ({
  type: LOGOUT,
  history
})

export const logoutError = () => ({
  type: LOGOUT_ERROR
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
})

export const resetAccountPassword = (password, history) => ({
  type: RESET_PASSWORD,
  password,
  history
})

export const resetAccountPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS
})

export const resetAccountPasswordError = () => ({
  type: RESET_PASSWORD_ERROR
})

export const sendOTP = (payload) => ({
  type: SEND_OTP,
  payload
})

export const sendOTPSuccess = (payload) => ({
  type: SEND_OTP_SUCCESS,
  payload
})

export const sendOTPError = () => ({
  type: SEND_OTP_ERROR
})

export const verifyOTP = (otp, history) => ({
  type: VERIFY_OTP,
  otp,
  history
})

export const verifyOTPSuccess = (payload) => ({
  type: VERIFY_OTP_SUCCESS,
  payload
})

export const verifyOTPError = () => ({
  type: VERIFY_OTP_ERROR
})
