import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  getUserToken,
  isManager,
  isAdmin
} from '../../store/auth/selectors'
import { getLoanLeads, getLoansPagination } from '../../store/loans/selectors'

import { getLeadIdsToAgent, getAllAgents } from '../../store/leads/selectors'
import { ILoan } from '../../store/loans/types'
import { isFetching } from '../../store/isFetching/selectors'
import { IRootState } from '../../store/reducer.interface'
import WithSideMenu from '../../components/WithSideMenu'
import EmailIcon from '../Leads/svg/Email'
import LastSeenIcon from '../Leads/svg/LastSeenIcon'
import PersonIcon from '../Leads/svg/PersonIcon'
import PhoneIcon from '../Leads/svg/PhoneIcon'
import ServiceIcon from '../Leads/svg/ServiceIcon'
import StatusIcon from '../Leads/svg/StatusIcon'
import ValueIcon from '../Leads/svg/ValueIcon'
import Checkbox from '../../components/Checkbox'
import Loader from '../../components/Loader'
import Popover from '@material-ui/core/Popover'
import CheckboxMaterialUi from '@material-ui/core/Checkbox'
import { useTranslation } from 'react-i18next'
import PlusIcon from '../../images/Plus Icon.svg'

import { usePhoneStyles } from '../Leads/LeadDetails/LeadActions/styles'
import { makeStyles } from '@material-ui/core'

import {
  LeadsContainer,
  LeadsMiddleSection,
  LeadsMiddleSectionText,
  LeadsPageTitile,
  LeadsTopSection,
  LeadsMiddleSectionButtons,
  SearchContainer,
  SearchContentInactive,
  SearchIcon,
  Divider,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  HeaderIcon,
  HeaderText,
  HeaderIconText,
  CheckBoxWrap,
  ButtonsContainer,
  CreateLeadBtn,
  ClearButton,
  PaginationContainer,
  NewCreateLeadBtn,
  FiltersIcon,
  SubmitButton,
  FiltersBtn,
  TableOptionsIcon,
  TableOption
} from './styles'
import dayjs from 'dayjs'
import { fetchLoanLeads, fetchFilterLoanLeads } from '../../store/loans/actions'
import {
  sendAssignedLeadsToAgent,
  fetchAgents,
  saveAssignedAgent,
  saveMultiAgents,
  emptyAssignedList
} from '../../store/leads/actions'
import { showSnackbar } from '../../store/snackbar/actions'
import api from '../../utils/api'
import CreateLead from '../Leads/CreateLead'
import { getCountryCode } from '../../utils/country.util'
import ReactSelect from 'react-select'
// import { SelectAll } from '@material-ui/icons'

const tableHeader = [
  {
    icon: <PersonIcon />,
    text: 'NAME',
    longCell: true
  },
  {
    icon: <EmailIcon />,
    text: 'EMAIL',
    longCell: true,
    shortCell: false
  },
  {
    icon: <PhoneIcon />,
    text: 'PHONE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <ServiceIcon />,
    text: 'SERVICE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <StatusIcon />,
    text: 'LOAN STATUS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'SOURCE',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'STATUS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'PROGRESS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <ValueIcon />,
    text: 'VALUE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'LAST SEEN',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'FIRST SEEN',
    longCell: false,
    shortCell: false
  }
]

const agentHeader = [
  {
    icon: <PersonIcon />,
    text: 'NAME',
    longCell: true
  },
  {
    icon: <EmailIcon />,
    text: 'EMAIL',
    longCell: true,
    shortCell: false
  },
  {
    icon: <PhoneIcon />,
    text: 'PHONE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <ServiceIcon />,
    text: 'SERVICE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <StatusIcon />,
    text: 'LOAN STATUS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'SOURCE',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'STATUS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'PROGRESS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <ValueIcon />,
    text: 'VALUE',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'LAST SEEN',
    longCell: false,
    shortCell: false
  },
  {
    icon: <LastSeenIcon />,
    text: 'FIRST SEEN',
    longCell: false,
    shortCell: false
  },
  {
    icon: <PersonIcon />,
    text: 'ASSIGNED TO',
    longCell: false,
    shortCell: false
  }
]

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

let t1: any

interface TableHeaderContainerProps {
  userIsManager: boolean
  userIsAdmin: boolean
  handleSelectCheck: any
  selectAll: any
}

export const TableHeaderContainer = ({
  userIsManager,
  userIsAdmin,
  handleSelectCheck,
  selectAll
}: TableHeaderContainerProps) => {
  let headerToBeRendered =
    userIsAdmin || userIsManager ? agentHeader : tableHeader

  return (
    <TableHeader>
      {userIsAdmin || userIsManager ? (
        <CheckboxMaterialUi
          className="checkedBox"
          onChange={handleSelectCheck}
          checked={!(selectAll.length < 10)}
        />
      ) : null}

      {headerToBeRendered.map((header, index) => {
        return (
          <HeaderIconText
            long={header.longCell}
            short={header.shortCell}
            key={index + header.text}
            className={index == 0 ? 'newMargin' : ''}
          >
            <HeaderIcon>{header.icon}</HeaderIcon>
            <HeaderText>{t1(header.text)}</HeaderText>
          </HeaderIconText>
        )
      })}
    </TableHeader>
  )
}

interface ITableRowContainerProps {
  loans: any
  currency: string
  userIsManager: boolean
  userIsAdmin: boolean
  gotoLeads: any
  handleCheckBox: any
  selectAll: any
}

export const TableRowContainer = ({
  loans,
  currency,
  userIsManager,
  userIsAdmin,
  handleCheckBox,
  gotoLeads,
  selectAll
}: ITableRowContainerProps) => {
  return loans.map((loan: any) => {
    const {
      id,
      firstName,
      lastName,
      email,
      firstSeen,
      lastSeen,
      phone,
      loanData,
      status,
      loanAmount,
      type,
      createdAt,
      updatedAt,
      multipleAgents,
      lastContacted,
      source
    } = loan

    return (
      <TableRow
        onClick={() => {
          localStorage.setItem('loanId', id)
        }}
        key={id}
      >
        {userIsManager || userIsAdmin ? (
          <CheckboxMaterialUi
            onChange={(e) => handleCheckBox(e, id)}
            checked={selectAll.includes(id)}
          />
        ) : null}
        {/* <CheckBoxWrap>
          <Checkbox checked={false} handleChange={() => {}} />
        </CheckBoxWrap> */}
        <TableCell
          style={{ cursor: 'pointer', marginRight: '0px' }}
          long
          capitalize
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {firstName} {lastName}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          long
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {email?.toLowerCase()}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {phone}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {loanData[0]?.service ? loanData[0]?.service : 'N/A'}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          capitalize
          short
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {loanData[0]?.status ? loanData[0]?.status : 'N/A'}
        </TableCell>

        <TableCell
          style={{ cursor: 'pointer' }}
          capitalize
          short
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {source || 'N/A'}
        </TableCell>

        <TableCell
          style={{ cursor: 'pointer' }}
          capitalize
          short
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {lastContacted ? 'Contacted' : 'Not Contacted'}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          capitalize
          short
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {/* {loanData[0]?.progress} */}
          {loanData[0]?.progress ? loanData[0]?.progress : 'N/A'}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {loanData[0]?.value > 0 && currency}{' '}
          {loanData[0]?.value > 0 ? loanData[0]?.value.toLocaleString() : 'N/A'}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {dayjs(updatedAt).fromNow()}
        </TableCell>
        <TableCell
          style={{ cursor: 'pointer' }}
          onClick={(e) => gotoLeads(e, loanData[0]?.id, id)}
        >
          {dayjs(createdAt).fromNow()}
        </TableCell>

        {userIsAdmin || userIsManager ? (
          <TableCell>{multipleAgents[0]?.name}</TableCell>
        ) : null}
      </TableRow>
    )
  })
}

const initialLead = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  service: '',
  status: '',
  value: '',
  lead_type: '',
  country: '',
  country_code: '',
  source: '',
  assign_lead_to_self: undefined
}

const FilterPop = (props: any) => {
  const dispatch = useDispatch()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [status, setStatus] = React.useState('')
  const [newStatus, setNewStatus] = React.useState()
  const [progress, setProgress] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [endDate, setEndDate] = React.useState('')
  const [fromDate, setFromDate] = React.useState('')
  const [filterAgentId, setFilterAgentId] = React.useState('')
  const [minIncome, setMinIncome] = React.useState('')
  const [email, setEmail] = React.useState('')
  const agents = useSelector(getAllAgents)
  const [phone, setPhone] = React.useState('')
  const [clearFilterFromComp, setClearFilter] = React.useState(false)
  const userIsAdmin = useSelector(isAdmin)
  const userIsManager = useSelector(isManager)
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  let agentArray: any = []
  let users = agents.filter(function (data: any) {
    agentArray.push({ label: data.name, value: data.id })

    return data
  })

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const { t } = useTranslation()

  React.useEffect(() => {
    localStorage.setItem('loanType', 'REGULAR')

    dispatch(fetchAgents())

    let query: any = ''

    query = localStorage.getItem('userQuery')
    query = JSON.parse(query)

    if (query) {
      setClearFilter(true)
    }
  }, [])

  const handleClose = () => {
    setAnchorEl(null)
  }
  const saveSearch = (e: any) => {
    setSearch(e.target.value)
  }
  const saveProgress = (e: any) => {
    setProgress(e.target.value)
  }
  const saveAgent = (e: any) => {
    setFilterAgentId(e.value)
  }
  const saveStatus = (e: any) => {
    setStatus(e.target.value)
  }
  const saveNewStatus = (e: any) => {
    let value = e.target.value
    if (value === 'Contacted') {
      value = true
    }
    if (value === 'Not Contacted') {
      value = false
    }
    setNewStatus(value)
  }
  const saveMinIncome = (e: any) => {
    setMinIncome(e.target.value)
  }
  const saveFromDate = (e: any) => {
    setFromDate(e.target.value)
  }
  const savePhone = (e: any) => {
    setPhone(e.target.value)
  }
  const saveEmail = (e: any) => {
    setEmail(e.target.value)
  }
  const saveToDate = (e: any) => {
    setEndDate(e.target.value)
  }
  const clearFilterButton = (e: any) => {
    e.stopPropagation()
    setClearFilter(false)
    clearFilter()
  }
  const clearFilter = () => {
    setFromDate('')
    setEndDate('')
    setStatus('')
    setNewStatus(undefined)
    setProgress('')
    setEmail('')
    setPhone('')
    setClearFilter(false)
    setSearch('')
    setFilterAgentId('')
    setMinIncome('')
    props.clearFilter()
    localStorage.removeItem('userQuery')
    localStorage.removeItem('currentPagination')
    setAnchorEl(null)
  }
  const goFilter = () => {
    if (
      fromDate == '' &&
      endDate == '' &&
      search == '' &&
      filterAgentId == '' &&
      progress == '' &&
      status == '' &&
      newStatus == undefined &&
      minIncome == '' &&
      phone == '' &&
      email == ''
    ) {
      dispatch(showSnackbar('Please selected at least one filter', 'error'))
      return
    }
    localStorage.removeItem('currentPagination')

    setClearFilter(true)
    setAnchorEl(null)
    let query = {
      name: search,
      loan_progress: progress,
      first_seen_start_date: fromDate,
      last_seen_end_date: endDate,
      min_income: minIncome,
      email: email,
      phone: phone,
      loan_status: status,
      has_been_contacted: newStatus,
      assigned_agent_id: filterAgentId
    }
    localStorage.setItem('userQuery', JSON.stringify(query))
    const filteredQuery = {}
    for (let q in query) {
      //@ts-ignore
      if (query[q] !== '') {
        //@ts-ignore
        filteredQuery[q] = query[q]
      } else {
        continue
      }
    }
    dispatch(fetchFilterLoanLeads(filteredQuery))
  }
  return (
    <div>
      <SearchContainer onClick={handleClick}>
        {clearFilterFromComp ? (
          <button
            onClick={(e) => clearFilterButton(e)}
            style={{ cursor: 'pointer' }}
          >
            {t('Clear Filter')}
          </button>
        ) : null}

        <SearchIcon />
        <SearchContentInactive>{t('searchLeads')}</SearchContentInactive>
      </SearchContainer>
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'left'
        }}
      >
        <div style={{ minHeight: '480px', minWidth: '500px', padding: '10px' }}>
          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '100%' }}>
              <span style={{ color: '#8f99a6' }}>{t('name')}</span>
              <input
                type="text"
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',
                  border: '1px solid #E6EAF0'
                }}
                onChange={(e) => saveSearch(e)}
                value={search}
                //  placeholder="name, phone number, email"
              />
            </div>
          </div>
          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '100%' }}>
              <span style={{ color: '#8f99a6' }}> {t('email')} </span>
              <input
                type="text"
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',
                  border: '1px solid #E6EAF0'
                }}
                onChange={(e) => saveEmail(e)}
                value={email}
                //  placeholder="name, phone number, email"
              />
            </div>
          </div>
          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '100%' }}>
              <span style={{ color: '#8f99a6' }}> {t('phone')} </span>
              <input
                type="text"
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',
                  border: '1px solid #E6EAF0'
                }}
                onChange={(e) => savePhone(e)}
                value={phone}
                //  placeholder="name, phone number, email"
              />
            </div>
          </div>
          {userIsAdmin || userIsManager ? (
            <div style={{ display: 'flex', padding: '5px' }}>
              <div style={{ flexBasis: '100%' }}>
                <span style={{ color: '#8f99a6' }}>{t('agent')}</span>
                <ReactSelect
                  placeholder={t('selectAgent')}
                  options={agentArray}
                  onChange={saveAgent}
                />
              </div>
            </div>
          ) : null}

          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '100%' }}>
              <span style={{ color: '#8f99a6' }}>{t('loanStatus')}</span>
              <select
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',
                  border: '1px solid #E6EAF0'
                }}
                onChange={(e) => saveStatus(e)}
                value={status}
              >
                <option style={{ color: '#8f99a6' }}> </option>
                <option>{t('DRAFT')}</option>
                <option>{t('IN REVIEW')}</option>
                <option>{t('RECEIVED OFFERS')}</option>
                <option>{t('ACCEPTED OFFER')}</option>
                <option>{t('DECLINED')}</option>
                <option>{t('DISBURSED')}</option>
                <option>{t('ADDITIONAL DOCUMENT REQUESTED')}</option>
                <option>{t('OTHERS')}</option>
              </select>
            </div>
          </div>

          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '100%' }}>
              <span style={{ color: '#8f99a6' }}>{t('status')}</span>
              <select
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',
                  border: '1px solid #E6EAF0'
                }}
                onChange={(e) => saveNewStatus(e)}
                // value={newStatus}
              >
                <option style={{ color: '#8f99a6' }}></option>
                <option>{t('Contacted')}</option>
                <option>{t('Not Contacted')}</option>
              </select>
            </div>
          </div>

          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '100%' }}>
              <span style={{ color: '#8f99a6' }}>{t('Progress')}</span>
              <select
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',
                  border: '1px solid #E6EAF0'
                }}
                onChange={(e) => saveProgress(e)}
                value={progress}
                disabled={
                  status
                    ? false
                    : status == 'DRAFT' ||
                      status == 'IN REVIEW' ||
                      status == 'RECEIVED OFFERS' ||
                      status == 'ACCEPTED OFFER'
                }
              >
                {status == 'DRAFT' ? (
                  <>
                    <option style={{ color: '#8f99a6' }}> </option>
                    <option>{t('Personal Information')}</option>
                    <option>{t('Salary Earner')}</option>
                    <option>{t('Business Client')}</option>
                  </>
                ) : null}
                {status == 'IN REVIEW' ? (
                  <>
                    <option style={{ color: '#8f99a6' }}> </option>
                    <option>{t('Deposit paid')}</option>
                    <option>{t('Registration completed')}</option>
                  </>
                ) : null}
                {status == 'ACCEPTED OFFER' || status == 'RECEIVED OFFERS' ? (
                  <>
                    <option style={{ color: '#8f99a6' }}> </option>

                    <option>{t('Registration completed')}</option>
                  </>
                ) : null}

                {status == 'OTHERS' ? (
                  <>
                    <option style={{ color: '#8f99a6' }}> </option>

                    <option>{t('Basic Information')}</option>
                  </>
                ) : null}
              </select>
            </div>
          </div>

          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '100%' }}>
              <span style={{ color: '#8f99a6' }}>{t('Minimum income')}</span>
              <input
                type="number"
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',
                  border: '1px solid #E6EAF0'
                }}
                onChange={(e) => saveMinIncome(e)}
                value={minIncome}
                //  placeholder="name, phone number, email"
              />
            </div>
          </div>

          <div style={{ display: 'flex', padding: '5px' }}>
            <div style={{ flexBasis: '50%', marginRight: '5px' }}>
              <span style={{ color: '#8f99a6' }}>{t('Start date')}</span>
              <input
                name="first_seen_start_date"
                onChange={(e) => saveFromDate(e)}
                value={fromDate}
                type="date"
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',

                  border: '1px solid #E6EAF0'
                }}
              />
            </div>

            <div style={{ flexBasis: '50%', marginLeft: '5px' }}>
              <span style={{ color: '#8f99a6' }}>{t('End date')}</span>
              <input
                name="first_seen_start_date"
                onChange={(e) => saveToDate(e)}
                value={endDate}
                type="date"
                style={{
                  height: '40px',
                  borderRadius: '3px',
                  minWidth: '100%',
                  padding: '10px',

                  border: '1px solid #E6EAF0'
                }}
              />
            </div>
          </div>

          <ButtonsContainer>
            <ClearButton onClick={() => clearFilter()}>
              {t('Clear Selections')}
            </ClearButton>
            <SubmitButton onClick={() => goFilter()}>
              {t('search')}
            </SubmitButton>
          </ButtonsContainer>
        </div>
      </Popover>
    </div>
  )
}

const Loans = () => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const history = useHistory()
  const token = useSelector(getUserToken)
  const agents = useSelector(getAllAgents)
  const agentIds = useSelector(getLeadIdsToAgent)
  const loans = useSelector(getLoanLeads)
  const userIsAdmin = useSelector(isAdmin)
  const userIsManager = useSelector(isManager)
  const pagination = useSelector(getLoansPagination)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const [newLead, setNewLead] = useState({ ...initialLead, country })
  const [selectAll, setSelectAll] = useState<string[]>([])
  const [checked, setChecked] = React.useState(false)
  const [agentArrays, setAgentArrays] = useState<string[]>([])
  const isLoading = useSelector((state: IRootState) =>
    isFetching(state, 'loans')
  )

  const numOfPages = Math.ceil(pagination.total / pagination.pageSize)

  const gotoLeads = (e: any, loanId: string, id: string) => {
    e.preventDefault()
    window.location.href = `/loans/${loanId}?lead_id=${id}`
  }

  useEffect(() => {
    dispatch(fetchAgents())
    let pagination = localStorage.getItem('currentPagination')

    // if(pagination){

    //   localStorage.removeItem('currentPagination')
    // }

    let query: any = ''
    let page: any
    query = localStorage.getItem('userQuery')
    query = JSON.parse(query)
    query = {
      type: 'REGULAR'
    }
    if (pagination) {
      page = parseFloat(pagination)
    }
    if (query) {
      query.current_page = page
      query.assigned_agent_id = query.assigned_agent_id
        ? query.assigned_agent_id
        : agentIdFromStore

      let cleanObject = Object.entries(query).reduce(
        (a: any, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      )

      //  console.log(cleanObject, 'clean')
      dispatch(fetchFilterLoanLeads(cleanObject))
    } else if (page) {
      let query = { current_page: page, assigned_agent_id: agentIdFromStore }

      let cleanObject = Object.entries(query).reduce(
        (a: any, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      )
      dispatch(fetchFilterLoanLeads(cleanObject))
    } else {
      let query = {
        assigned_agent_id: agentIdFromStore
      }

      let cleanObject = Object.entries(query).reduce(
        (a: any, [k, v]) => (v ? ((a[k] = v), a) : a),
        {}
      )
      dispatch(fetchFilterLoanLeads(cleanObject))
      // dispatch(fetchLoanLeads())
    }
    dispatch(emptyAssignedList())
    return () => {
      dispatch(emptyAssignedList())
      localStorage.removeItem('userQuery')
    }
  }, [])

  const saveAssignedAgentInComp = (e: any) => {
    // console.log(e.value)
    dispatch(saveAssignedAgent(e.value))
    setAgentArrays([...agentArrays, e.value])
  }

  const handleCheckBox = (event: any, id: string) => {
    event.stopPropagation()

    if (selectAll.includes(id)) {
      const filteredIds = selectAll.filter((selectedId) => selectedId !== id)
      setSelectAll(filteredIds)
    } else {
      setSelectAll([...selectAll, id])
    }
  }

  const handleSelectCheck = () => {
    const loanIds = loans.map((loan) => loan.id)

    if (selectAll.length > 0) {
      setSelectAll([])
    } else {
      //@ts-ignore
      setSelectAll(loanIds)
    }
  }

  // console.log('loanIds-->', selectAll)
  // dispatch(saveMultiAgents(selectAll))

  const sendLeadsList = async () => {
    const data = { lead_ids: selectAll, agent_ids: agentArrays }

    try {
      const assignedLead = await api.bulkAgentTransfer(token, data)
      if (assignedLead) {
        dispatch(showSnackbar('Assigned leads successfully', 'success'))
      }
    } catch (err) {
      dispatch(showSnackbar('Leads failed to be assigned', 'error'))
    }

    setTimeout(() => {
      dispatch(fetchLoanLeads())
    }, 500)
  }

  let agentArray: any = []
  let users = agents.filter(function (data: any) {
    agentArray.push({ label: data.name, value: data.id })

    return data
  })

  const clearFetch = () => {
    dispatch(fetchLoanLeads())
  }

  const handlePageChange = (event: any, page: number) => {
    let query: any = ''

    query = localStorage.getItem('userQuery')
    query = JSON.parse(query)

    if (query) {
      query.assigned_agent_id = agentIdFromStore

      query.current_page = page
      dispatch(fetchFilterLoanLeads(query))
    } else {
      dispatch(fetchLoanLeads(page, agentIdFromStore))
    }

    localStorage.setItem('currentPagination', JSON.stringify(page))
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.currentTarget.name
    let value: string | number = event.target.value
    if (key === 'value') {
      value = parseInt(value)
    }

    setNewLead((lead) => ({ ...lead, [key]: value }))
  }

  const handleCheck = () => {
    if (checked == false) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  const handleCreateLead = async () => {
    setOpenModal(false)
    setNewLead({ ...initialLead, country })
    dispatch(showSnackbar('Creating lead...'))

    const phoneNumberWithCode = getCountryCode(country) + newLead.phone
    const leadInfo = {
      ...newLead,
      phone: phoneNumberWithCode,
      assign_lead_to_self: checked
    }

    try {
      const createdLead = await api.createLead(token, leadInfo)
      if (createdLead) {
        dispatch(showSnackbar('Succesfully created lead', 'success'))
        history.push('/leads')
      }
    } catch (err) {
      dispatch(showSnackbar('Error occured creating lead', 'error'))
    }
  }

  const openCreateLeadModal = () => {
    setOpenModal(true)
  }

  const { t } = useTranslation()
  t1 = t

  return (
    <WithSideMenu>
      <LeadsContainer>
        <LeadsTopSection>
          <LeadsPageTitile>{t('LOAN LEADS')}</LeadsPageTitile>

          <FilterPop clearFilter={() => clearFetch()} />
          {/* <FiltersBtn>
            <FiltersIcon />
            Filters
          </FiltersBtn> */}

          {/* <CreateLeadBtn onClick={openCreateLeadModal} /> */}
          <NewCreateLeadBtn onClick={openCreateLeadModal}>
            <img src={PlusIcon} alt="plus-icon" /> {t('Create new lead')}
          </NewCreateLeadBtn>
        </LeadsTopSection>
        <Divider />
        <div
          style={{ display: 'flex', minHeight: '70px', alignItems: 'center' }}
        >
          <div style={{ flexBasis: '50%' }}>
            {pagination.total} {t('items')}
          </div>

          {selectAll.length ? (
            <div style={{ display: 'flex', flexBasis: '50%' }}>
              <div style={{ width: '17%' }}>
                <ReactSelect
                  placeholder={t('selectAgent')}
                  options={agentArray}
                  onChange={saveAssignedAgentInComp}
                />
              </div>

              <button
                onClick={() => sendLeadsList()}
                style={{
                  background: '#FFC107',
                  color: '#003049',
                  cursor: 'pointer',
                  border: '1px solid #FFC107',

                  borderRadius: '6px',
                  width: '80px',
                  marginLeft: '10px'
                }}
              >
                {t('assign')}
              </button>
            </div>
          ) : null}
        </div>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Table>
              <TableHeaderContainer
                userIsManager={userIsManager}
                userIsAdmin={userIsAdmin}
                handleSelectCheck={handleSelectCheck}
                selectAll={selectAll}
              />
              {/* {TableHeaderContainer(userIsManager, userIsAdmin)} */}

              <TableRowContainer
                loans={loans}
                currency={currency}
                userIsAdmin={userIsAdmin}
                userIsManager={userIsManager}
                gotoLeads={gotoLeads}
                handleCheckBox={handleCheckBox}
                selectAll={selectAll}
              />
            </Table>
          </>
        )}
        <PaginationContainer>
          <Pagination
            page={pagination.currentPage}
            count={numOfPages}
            onChange={handlePageChange}
            color="secondary"
          />
        </PaginationContainer>
      </LeadsContainer>

      <CreateLead
        open={openModal}
        values={newLead}
        handleChange={handleInputChange}
        handleClose={() => setOpenModal(false)}
        handleCreate={handleCreateLead}
        handleCheck={handleCheck}
        checked={checked}
      />
    </WithSideMenu>
  )
}

export default Loans
