/* eslint-disable react-hooks/exhaustive-deps */
import { Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { getSchedules } from '../../../../../store/events/selectors'
import TitleWrapper from '../TitleWrapper'
import { useTodoStyles } from './styles'
import TodoCard from './TodoCard'
import { useTranslation } from 'react-i18next'

const Todo: React.FC = () => {
  const classes = useTodoStyles()
  const { pending } = useSelector(getSchedules)

  const { t } = useTranslation()
  return (
    <TitleWrapper title={t('To do')}>
      <React.Fragment>
        {!!!pending.length && (
          <Typography variant="body1" className={classes.noTask}>
            {t('notPendingTasks')}
          </Typography>
        )}
        {pending.map((activity) => (
          <TodoCard key={activity.id} activity={activity} />
        ))}
      </React.Fragment>
    </TitleWrapper>
  )
}

export default Todo
