/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useCallback, useEffect, useState } from 'react'
import { StyledCarAvailable } from './styles'
import { Rate, Switch, Button, Skeleton } from 'antd'
import { GaugeIcon, LocationIcon, ConditionIcon } from './svg'
import ScheduleModal from './ScheduleModal'
import SourceAlternateCar from '../SourceAlternateCar'
import BookInspectionModal from '../BookInspection'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import InspectionOutcomeModal from '../InspectionOutcome'
import api from '../../../utils/api'
import { useSelector, useDispatch } from 'react-redux'
import {
  getUserToken,
  getUserId,
  getSelectedCountry,
  getSelectedCountryCurrency,
  isAgent
} from '../../../store/auth/selectors'
import { CAR_STATUS } from './constants'
import { useQuery, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function CarAvailable({
  singleLoan,
  loanRefresh,
  singleLoanLoading,
  carOffers
}: any) {
  const { id } = useParams<IDetailParam>()
  const token = useSelector(getUserToken)
  const currency = useSelector(getSelectedCountryCurrency)
  const [visible, setVisible] = React.useState(false)
  const [visibleAlternateCar, setVisibleAlternateCar] = React.useState(false)
  const [visibleBookInsp, setVisibleBookInsp] = React.useState(false)
  const [inspOutcome, setInspOutcome] = React.useState(false)
  const [cars, setCars] = useState<Array<any>>([])
  const [carsDetails, setCarsDetails] = useState<any>({})
  const [checked, setChecked] = useState(false)
  const country = useSelector(getSelectedCountry)
  const { t } = useTranslation()
  // const queryClient = useQueryClient()

  function onChange(checked: any) {
    if (checked == false) {
      setChecked(true)
    } else if (checked == true) {
      setChecked(false)
    }
  }

  let carUserData

  const {
    data: franchiseData,
    isLoading: franchiseLoading,
    refetch: refetchFranchise,
    isError: franchiseError
  } = useQuery(
    ['fetch franchise user', singleLoan],
    () =>
      api.fetchCarDealer(
        token,
        singleLoan?.car?.carManagerId || singleLoan?.car?.ownerId
      ),
    {
      enabled: singleLoan ? true : false
    }
  )

  const {
    data: userData,
    isLoading: userLoading,
    refetch,
    isError: userError
  } = useQuery(
    ['fetch user', singleLoan],
    () =>
      api.fetchUser(
        token,
        singleLoan?.car?.carManagerId || singleLoan?.car?.ownerId
      ),
    {
      enabled: singleLoan && franchiseError ? true : false
    }
  )

  if (franchiseData) {
    carUserData = franchiseData
  } else if (userData) {
    carUserData = userData
  }

  const showModal = () => {
    setVisible(true)
  }

  const showAlternateCarModal = () => {
    setVisibleAlternateCar(true)
  }

  const showBookingInspection = () => {
    setVisibleBookInsp(true)
  }

  const showInspOutcome = () => {
    setInspOutcome(true)
  }

  const carID = singleLoan?.carId

  useEffect(() => {
    const fetchCarDetail = async () => {
      try {
        const response = await api.getCarByAdmin(token, carID)
        setCars(response)
        setCarsDetails(response)
      } catch (error) {
        console.log('error')
      }
    }
    fetchCarDetail()
  }, [id, carID, token])

  // const objEntries = Object.entries(cars)
  // const rates = Object.fromEntries(objEntries)?.gradeScore?.toFixed()

  const { data: workshops, isLoading: workshopsLoading } = useQuery(
    ['workshop'],
    () =>
      api.getInspectionWorkshop(token, {
        user_active: true,
        company_status: 'approved',
        inspection_type: 'both',
        country: country
      })
  )

  const checkFormatchingVin = carOffers?.find(
    (offer: any) => offer?.vin === singleLoan?.car.vin
  )
  const SOURCING_STATUS_ALLOWED = [
    'APPLICATION_COMPLETED',
    'OFFERS',
    'OFFER_ACCEPTED',
    'DECLINED',
    'DOCUMENT_REQUESTED',
    'DOCUMENT_REJECTED',
    'DOCUMENT_ACCEPTED',
    'PPI_FAIL'
  ]

  return (
    <StyledCarAvailable>
      <div className="thirdStep">
        {singleLoanLoading ? (
          <Skeleton />
        ) : (
          <>
            <div className="title mb-3">
              {t('Step 3')}: {t('carAvailability')}
            </div>

            <div className="d-flex mb-4">
              <div className="carImage me-4">
                <img
                  src={carsDetails?.imageUrl || 'N/A'}
                  className="img-fluid"
                  alt="car-image"
                />
              </div>
              <div className="d-flex">
                <div className="carDetail me-5">
                  <div className="mb-2">
                    <div className="name">
                      {carsDetails?.model?.make?.name || 'N/A'}{' '}
                      {carsDetails?.model?.name || 'N/A'}{' '}
                      {carsDetails?.year || 'N/A'}
                    </div>
                    <div className="rate">
                      <Rate
                        disabled
                        value={carsDetails?.gradeScore?.toFixed(1) || 0}
                      />
                      &nbsp; ({carsDetails?.gradeScore?.toFixed(1) || 0})
                    </div>
                  </div>
                  <div className="d-flex align-items-center detail mb-2">
                    <div className="me-2">
                      {GaugeIcon}{' '}
                      {carsDetails?.mileage?.toLocaleString() || 'N/A'}{' '}
                      {carsDetails?.mileageUnit}
                    </div>
                    <div className="me-2">
                      {LocationIcon} {carsDetails?.state || 'N/A'}
                    </div>
                    <div>
                      {ConditionIcon} {carsDetails?.sellingCondition || 'N/A'}
                    </div>
                  </div>
                  <div className="value mb-3">
                    {currency}{' '}
                    {carsDetails?.marketplacePrice?.toLocaleString() || 'N/A'}
                  </div>

                  {(singleLoan?.offer ||
                    singleLoan?.status == CAR_STATUS.APPLICATION_COMPLETED ||
                    singleLoan?.status == CAR_STATUS.OFFERS) && (
                    <>
                      {SOURCING_STATUS_ALLOWED.includes(singleLoan?.status) && (
                        <>
                          <div className="carAvailability d-flex align-items-center mb-4">
                            <div className="options me-4">
                              {t('isThisCarAvailable')}?
                            </div>
                            <div className="d-flex">
                              <div className="me-2 yesOption">{t('Yes')}</div>
                              <Switch defaultChecked onChange={onChange} />
                              <div className="ms-2 noOption">{t('No')}</div>
                            </div>
                          </div>
                          <div>
                            {checked === true && (
                              <div className="centralizeButton">
                                <Button
                                  className="schedule"
                                  onClick={showModal}
                                >
                                  {t('schedulePPI')}
                                </Button>
                              </div>
                            )}
                            {checked === false && (
                              <Button
                                className="sourceCar"
                                onClick={showAlternateCarModal}
                              >
                                {t('sourceCar')}
                              </Button>
                            )}
                          </div>
                        </>
                      )}

                      {/* all the call to action sections */}
                      <div className="d-flex mt-3">
                        {singleLoan?.status === CAR_STATUS.PPI_IN_PROGRESS && (
                          <div className="centralizeButton">
                            <Button className="scheduleInProgress">
                              {t('PPIcurrentlyInProgress')}
                            </Button>
                          </div>
                        )}

                        {singleLoan?.status === CAR_STATUS.CAR_SOURCING && (
                          <div className="centralizeButton">
                            <Button className="carSourcingComplete ">
                              {t('CarSourcingInProgress')}
                            </Button>
                          </div>
                        )}

                        {singleLoan?.status !== CAR_STATUS.CAR_SOURCING &&
                          singleLoan?.status !== CAR_STATUS.PPI_IN_PROGRESS &&
                          singleLoan?.status !==
                            CAR_STATUS.APPLICATION_COMPLETED && (
                            <div className="centralizeButton">
                              <Button className="carSourcingComplete ">
                                {/* {singleLoan?.status.replace('_', ' ')} */}
                                {t(singleLoan?.status)}
                              </Button>
                            </div>
                          )}
                      </div>
                      {/* all the call to action sections */}
                    </>
                  )}
                </div>

                <div className="franchiseDetail">
                  {(checkFormatchingVin || carUserData) && (
                    <>
                      {checkFormatchingVin && (
                        <div className="name mb-2">
                          {checkFormatchingVin?.type.toLowerCase() ===
                          'franchise'
                            ? t('Franchise')
                            : t('Owner')}{' '}
                          {t('details')}
                        </div>
                      )}
                      {carUserData && !checkFormatchingVin && (
                        <div className="name mb-2">{t('ownerDetails')}</div>
                      )}

                      <div
                        className="content"
                        style={{ textTransform: 'capitalize' }}
                      >
                        {checkFormatchingVin?.franchiseName ||
                          checkFormatchingVin?.ownerDetails?.name ||
                          `${
                            carUserData.firstname || carUserData.name || 'N/A'
                          } ${carUserData.lastname || ''}`}
                      </div>
                      <div className="content">
                        {checkFormatchingVin?.franchisePhoneNumber ||
                          checkFormatchingVin?.ownerDetails?.phoneNumber ||
                          carUserData.primaryPhone ||
                          carUserData.phonenumber}
                      </div>
                      <div className="content">
                        {checkFormatchingVin?.franchiseEmail ||
                          checkFormatchingVin?.ownerDetails?.address ||
                          carUserData.address}
                      </div>
                      <div className="content">
                        {checkFormatchingVin?.ownerDetails?.city}
                      </div>
                    </>
                  )}

                  {checkFormatchingVin &&
                    singleLoan?.status === CAR_STATUS.CAR_SOURCING && (
                      <Button
                        className="bookInspection "
                        onClick={showBookingInspection}
                      >
                        {t('bookInspection')}
                      </Button>
                    )}
                  {!checkFormatchingVin &&
                    singleLoan?.status === CAR_STATUS.PPI_PASS && (
                      <Button
                        className="bookInspection "
                        onClick={showBookingInspection}
                      >
                        {t('bookInspection')}
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </>
        )}

        <ScheduleModal
          visible={visible}
          setVisible={setVisible}
          workshops={workshops?.staff}
          workshopsLoading={workshopsLoading}
          loanData={singleLoan}
          loanRefresh={loanRefresh}
        />
        <SourceAlternateCar
          loanRefresh={loanRefresh}
          singleLoan={singleLoan}
          visibleAlternateCar={visibleAlternateCar}
          setVisibleAlternateCar={setVisibleAlternateCar}
        />

        <BookInspectionModal
          loanRefresh={loanRefresh}
          visibleBookInsp={visibleBookInsp}
          setVisibleBookInsp={setVisibleBookInsp}
          singleLoan={singleLoan}
          modalData={checkFormatchingVin}
        />
        <InspectionOutcomeModal
          inspOutcome={inspOutcome}
          setInspOutcome={setInspOutcome}
        />
      </div>
    </StyledCarAvailable>
  )
}
