import React from 'react'

const QuestionMarkIcon = () => {
  return (
    <svg
      width={22}
      height={22}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 8.50035C8.50009 8.04204 8.62616 7.59257 8.86443 7.20107C9.10271 6.80957 9.44402 6.4911 9.85107 6.28048C10.2581 6.06985 10.7152 5.97517 11.1724 6.00678C11.6297 6.03839 12.0694 6.19508 12.4436 6.45972C12.8178 6.72436 13.112 7.08677 13.2941 7.50734C13.4762 7.92791 13.5393 8.39045 13.4763 8.84441C13.4133 9.29837 13.2267 9.72628 12.937 10.0814C12.6472 10.4365 12.2654 10.7051 11.8333 10.8578C11.5895 10.944 11.3784 11.1037 11.2292 11.3149C11.0799 11.5261 10.9999 11.7784 11 12.037V12.8753"
        stroke="#A1AACE"
        stroke-width="1.66667"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 15.375C10.9382 15.375 10.8778 15.3933 10.8264 15.4277C10.775 15.462 10.7349 15.5108 10.7113 15.5679C10.6876 15.625 10.6814 15.6878 10.6935 15.7485C10.7056 15.8091 10.7353 15.8648 10.779 15.9085C10.8227 15.9522 10.8784 15.9819 10.939 15.994C10.9997 16.0061 11.0625 15.9999 11.1196 15.9762C11.1767 15.9526 11.2255 15.9125 11.2598 15.8611C11.2942 15.8097 11.3125 15.7493 11.3125 15.6875C11.3125 15.6046 11.2796 15.5251 11.221 15.4665C11.1624 15.4079 11.0829 15.375 11 15.375Z"
        fill="#A1AACE"
        stroke="#A1AACE"
        stroke-width="1.25"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11 20.375C16.1777 20.375 20.375 16.1777 20.375 11C20.375 5.82233 16.1777 1.625 11 1.625C5.82233 1.625 1.625 5.82233 1.625 11C1.625 16.1777 5.82233 20.375 11 20.375Z"
        stroke="#A1AACE"
        stroke-width="1.66667"
        stroke-miterlimit="10"
      />
    </svg>
  )
}

export default QuestionMarkIcon
