import isEmail from 'validator/lib/isEmail'
import isEmpty from 'validator/lib/isEmpty'
const format = /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/

export const validateName = (value: string) => {
  if (isEmpty(value)) return '*'
  if (format.test(value)) return '- only letters are allowed'
  return ''
}

export const validateEmail = (value: string) => {
  if (isEmpty(value)) return '*'
  if (!isEmail(value)) return '- invalid email'
  return ''
}

export const validateEmpty = (value: string) => {
  if (isEmpty(value)) return '*'
}

export const validatePhone = (value: string, country: string) => {
  if (isEmpty(value)) return '*'

  if (country === 'KE') {
    const kenyaPhoneFormat = /^[0-9]{9}$/
    if (!kenyaPhoneFormat.test(value)) return '- invalid format'
  }

  if (country === 'NG') {
    const nigeriaPhoneFormat = /^[0-9]{10}$/
    if (!nigeriaPhoneFormat.test(value)) return '- invalid format'
  }
}