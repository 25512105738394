import React from 'react'

const PhoneIcon = () => {
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.65321 6.68613L2.54588 7.57879C2.77567 7.80863 3.0485 7.99095 3.34877 8.11534C3.64903 8.23973 3.97086 8.30375 4.29588 8.30375C4.62089 8.30375 4.94272 8.23973 5.24299 8.11534C5.54325 7.99095 5.81608 7.80863 6.04588 7.57879L7.35854 6.26679C7.47346 6.15189 7.56462 6.01548 7.62682 5.86535C7.68901 5.71521 7.72102 5.5543 7.72102 5.39179C7.72102 5.22929 7.68901 5.06837 7.62682 4.91824C7.56462 4.76811 7.47346 4.63169 7.35854 4.51679L4.80588 1.96413C4.68111 1.83814 4.53075 1.74039 4.36497 1.67748C4.19919 1.61457 4.02184 1.58797 3.8449 1.59947C3.66795 1.61097 3.49554 1.66031 3.3393 1.74415C3.18305 1.82799 3.04662 1.94438 2.93921 2.08546C0.266542 5.71746 1.88454 9.54279 5.17121 12.8288C8.45788 16.1148 12.2825 17.7335 15.9145 15.0608C16.0556 14.9534 16.172 14.817 16.2559 14.6607C16.3397 14.5045 16.389 14.332 16.4005 14.1551C16.412 13.9782 16.3854 13.8008 16.3225 13.635C16.2596 13.4693 16.1619 13.3189 16.0359 13.1941L13.4839 10.6415C13.369 10.5265 13.2326 10.4354 13.0824 10.3732C12.9323 10.311 12.7714 10.279 12.6089 10.279C12.4464 10.279 12.2855 10.311 12.1353 10.3732C11.9852 10.4354 11.8488 10.5265 11.7339 10.6415L10.4212 11.9541C10.1914 12.1839 10.0091 12.4568 9.88466 12.757C9.76027 13.0573 9.69625 13.3791 9.69625 13.7041C9.69625 14.0291 9.76027 14.351 9.88466 14.6512C10.0091 14.9515 10.1914 15.2243 10.4212 15.4541L11.3239 16.3568"
        stroke="#A1AACE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}

export default PhoneIcon
