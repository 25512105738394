import styled from 'styled-components'
import PersonIcon from '../../images/person.svg'

export const SettingsContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.common.white};
`

export const SettingsNav = styled.div`
  width: 242px;
  height: 100vh;
  position: fixed;
  border-right:1px solid #e6e6e6;
  background-color: ${({ theme }) => theme.palette.neutral.ghostWhite};
`

export const SettingsNavDummy = styled.div`
  width: 242px;
  height: 100%;
`

export const SettingsTitle = styled.div`
  height: 36px;
  width: 93px;
  line-height: 36px;
  margin-left: 16px;
  margin-top: 21px;
  margin-bottom: 33px;
  font-size: ${({ theme }) => theme.font.size.H4};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.palette.neutral.grayishBlue};
`

export const ContentWrap = styled.div`
  max-width: 1024px;
  flex-grow: 1;
`

export const SettingsMenuSection = styled.div`
  margin-bottom: 41px;
`

export const SettingsMenuSectionTitle = styled.div`
  margin-bottom: 10px;
  margin-left: 18px;
  display: flex;
`

export const StyledPersonIcon = styled.div`
  height: 20px;
  width: 20px;
  margin-right: 12px;
  background-image: url(${PersonIcon});
  background-size: 20px 20px;
`

export const SettingsMenuTitle = styled.div`
  height: 21px;
  line-height: 21px;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  color: ${({ theme }) => theme.palette.neutral.raven};
`

export const SettingsMenuItem = styled.div<{ active: boolean }>`
  width: 100%;
  height: 38px;
  padding-left: 48px;
  line-height: 38px;
  font-size: ${({ theme }) => theme.font.size.md};
  color: ${({ theme }) => theme.palette.neutral.raven};
  ${({ theme, active }) =>
    active && `background-color: ${theme.palette.neutral.aliceBlue}`};
`
