import { createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import { persistStore, persistReducer } from 'redux-persist';
import localForage from "localforage";
import createSagaMiddleware from 'redux-saga'
import rootReducer from './reducers'
import rootSaga from './rootSaga'

const persistConfig = {
  key: 'user_data',
  storage: localForage,
  whitelist: ['auth']
};

const middlewares = []
const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)

// if (process.env.NODE_ENV === `development`) {
//   const { logger } = require(`redux-logger`)
//   middlewares.push(logger)
// }

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
)
sagaMiddleware.run(rootSaga)

const persistor = persistStore(store);
const reduxStore = {store, persistor}

export default reduxStore
