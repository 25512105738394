import styled from 'styled-components'

export const StyledLoanDetails = styled.div`
  background: #f8f8fb;
  /* min-height: 100vh;
  height: auto; */
  padding-bottom: 40px;
  [ant-click-animating-without-extra-node='true']::after {
    display: none;
  }
  a {
    text-decoration: none;
  }

  .navbarWrapper {
    background: #fff;
    padding: 25px 30px 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 10px 6px -6px #ededf6;

    .firstColumn {
      display: flex;
      align-items: center;
      .title {
        color: #0a1133;
        font-weight: bold;
        font-size: 18px;
        cursor: pointer;
        margin-right: 8px;
      }
      .id {
        font-weight: 500;
        font-size: 16px;
        color: #555555;
        margin-right: 10px;
        span {
          text-decoration: underline;
        }
      }
      a {
        color: #30355b;
        font-weight: 600;
        font-size: 12px;
        background: rgba(48, 53, 91, 0.15);
        border-radius: 30px;
        min-width: 165px;
        width: auto;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .secondColumn {
      display: flex;
      align-items: center;
      .buttonGroup {
        margin-right: 30px;
        [ant-click-animating-without-extra-node='true']::after {
          display: none;
        }
        .notInterested {
          border: 1px solid #30355b;
          border-radius: 5px;
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          margin-right: 15px;
        }
        .carSold {
          color: #30355b;
          font-weight: 500;
          background: #ffb619;
          box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
          border-radius: 5px;
          font-size: 14px;
          border: 0;
        }
      }
      .actionButtonGroup {
        background: rgba(187, 189, 205, 0.22);
        border-radius: 8px;
        [ant-click-animating-without-extra-node='true']::after {
          display: none;
        }
        .inbox,
        .log,
        .email {
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          border: none;
          border-right: 1px solid #dddddd;
          border-radius: 0px !important;
          background: transparent;
          .btnWrap {
            display: flex;
            align-items: center;
          }
          .btnWrap div {
            display: flex;
          }
          .btnImage {
            margin-right: 8px;
          }
        }
        .history {
          color: #30355b;
          font-weight: 500;
          font-size: 14px;
          border: 0;
          border-radius: 0px !important;
          background: transparent;
          .btnWrap {
            display: flex;
            align-items: center;
          }
          .btnWrap div {
            display: flex;
          }
          .btnImage {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .statusWrapper {
    padding: 15px 30px 15px 30px;
    .progress {
      height: 25px;
      /* background-color: #e0e1e8; */
      border-radius: 18px;
      .progress-bar {
        background-color: #30355b;
        font-size: 13px;
        font-weight: 500;
      }
    }
    .progressActive {
      background-color: #30355b !important;
    }

    .progressLabels {
      display: grid;
      grid-template-columns: repeat(8, 1fr);
      height: 25px;
      align-items: center;
      position: absolute;
      z-index: 1;
      /* width: 100vw; */
      width: calc(100vw - 125px);

      /* max-width: 1316px; */
      /* flex-grow: 1; */
    }

    .progressBarActive {
      position: relative;
    }
  }

  .userDetailWrapper {
    margin: 0px 30px 20px 30px;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    /* grid-template-columns: repeat(5, 1fr); */
    grid-gap: 20px;
    .userName {
      color: #30355b;
      .name {
        font-weight: 600;
        font-size: 16px;
      }
      .email,
      .tel {
        font-size: 15px;
      }
    }
    .loan {
      .name {
        font-weight: 600;
        font-size: 14px;
        color: #8c8f99;
      }
      .value {
        font-weight: bolder;
        font-size: 16px;
        color: #30355b;
      }
    }
    .carDetails {
      .carImage {
        width: 138px;
        height: 88px;
        background: transparent;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 15px;
      }
      .carDescription {
        color: #30355b;
        .name {
          font-size: 14px;
          font-weight: 500;
        }
        .value {
          font-size: 16px;
          font-weight: bolder;
        }
        a {
          color: #30355b;
          font-weight: 500;
          font-size: 12px;
          background: #ffffff;
          box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
            0px 2px 4px rgba(96, 97, 112, 0.16);
          border-radius: 30px;
          min-height: 25px;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    .currentStep {
      .name {
        color: #8c8f99;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .number {
        font-weight: bolder;
        font-size: 16px;
        color: #30355b;
      }
    }
    .stage {
      .name {
        color: #8c8f99;
        font-weight: 500;
        font-size: 14px;
      }
      .step {
        color: #30355b;
        font-weight: 500;
        font-size: 14px;
      }
      .agent {
        color: #8c8f99;
        font-size: 14px;
        span {
          color: #30355b;
          font-weight: bolder;
        }
      }
    }
  }

  .carSourcingWrapper {
    display: grid;
    grid-template-columns: auto 350px;
    grid-gap: 20px;
  }

  .leadsTabsContentWrapper {
    margin: 0px 30px 20px 30px;
    padding: 25px;
    background: #f8f8fb;
    /* background: red; */
    border-radius: 10px;
    .leadTabs {
      /* padding: 30px; */

      min-height: 385.11px;
      height: auto;
      position: relative;
      .nav-pills .nav-link {
        background: 0 0;
        border: 1px solid #30355b;
        box-sizing: border-box;
        border-radius: 4px;
        color: #30355b;
        font-weight: 500;
        margin-right: 15px;
        font-size: 14px;
      }
      .nav-pills .nav-link.active,
      .nav-pills .show > .nav-link {
        color: #fff;
        background-color: #30355b;
        font-weight: 500;
      }
    }
    .completedStages {
      padding: 30px 15px 30px 15px;
      background: #fff;
      border-radius: 10px;
      min-height: 410px;
      height: auto;
      /* min-height: 410px; */
      /* height: auto; */
      .title {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        text-align: center;
      }
      .stage {
        color: #333333;
        font-weight: 500;
        font-size: 14px;
        background: #ffffff;
        box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 8px;
        padding: 15px;
      }
    }
  }

  .timeElapsed {
    font-size: 12px;
  }
`

export const StyledCustomerDocument = styled.div`
  .documentWrap {
    background: #f8f8fb;
    border-radius: 10px;
    min-height: 400px;
    height: auto;
    padding: 25px;
    .header {
      color: #30355b;
      font-weight: bold;
      font-size: 14px;
    }
  }
`
