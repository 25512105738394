import React from 'react'

const CheckMarkIcon = () => {
  return (
<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.6665 15.7178L13.6665 19.7492L20.3332 11.6865" stroke="#00A010" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M14.9998 28.4838C22.3636 28.4838 28.3332 22.4675 28.3332 15.0459C28.3332 7.6244 22.3636 1.60806 14.9998 1.60806C7.63604 1.60806 1.6665 7.6244 1.6665 15.0459C1.6665 22.4675 7.63604 28.4838 14.9998 28.4838Z" stroke="#00A010" stroke-width="2"/>
</svg>

  )
}

export default CheckMarkIcon