import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIsAuth } from '../store/auth/selectors'

interface PrivateRouteProps extends RouteProps {
  component: any
}

const PrivateRoute: React.FC<PrivateRouteProps> = (props) => {
  const isAuth = useSelector(getIsAuth)
  const { component: Component, ...rest } = props

  return (
    <Route
      {...rest}
      render={(routeProps) =>
        isAuth ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={{ pathname: '/login' }} />
        )
      }
    />
  )
}

export default PrivateRoute
