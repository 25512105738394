import styled from 'styled-components'

export const StyledLoanTable = styled.div`
  background: #e5e5e5;
  min-height: 100vh;
  height: auto;
  .headerWrapper {
    background: #fff;
    padding: 25px 30px 20px 30px;
    display: flex;
    justify-content: space-between;
    .searchButton {
      display: flex;
      .ant-input-affix-wrapper {
        width: 486px;
        height: 47px;
        background: #f8f8fb;
        margin-right: 15px;
        border-radius: 5px;
      }
      .ant-input {
        background: #f8f8fb;
        font-size: 14px;
        font-weight: 400;
        color: #30345e;
      }
      .ant-input::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: #a6acbe;
      }
      button {
        height: 47px;
        min-width: 115px;
        width: auto;
        background: #ffffff;
        box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
          0px 2px 4px rgba(96, 97, 112, 0.16);
        border-radius: 5px;
        color: #30345e;
        border: 0;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .userWrapper {
      display: flex;
      align-items: center;
      .users {
        display: flex;
        border-right: 1px solid #eff4f7;
        padding: 5px 20px 5px 0px;
        .name {
          color: #30355b;
          font-size: 14px;
          font-weight: bold;
        }
        .role {
          color: #a6acbe;
          font-size: 14px;
        }
        .initials {
          background: rgba(113, 59, 219, 0.05);
          width: 48px;
          height: 48px;
          border-radius: 50%;
          color: #000000;
          font-weight: bolder;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 15px;
        }
      }
    }
    .bell {
      padding: 5px 0px 5px 20px;
      cursor: pointer;
    }
  }

  .tableWrapper {
    padding: 25px 30px 25px 30px;
    h3 {
      color: #0a1133;
      font-weight: bolder;
      font-size: 17px;
      margin-bottom: 25px;
    }
    th {
      color: #767676;
      font-size: 13px;
      background: #eff0f6;
      box-shadow: 0px 1px 0px #dadbe4;
    }
    tbody td {
      color: #30355b;
      font-size: 13px;
    }
  }
`
