/* eslint-disable react-hooks/exhaustive-deps */
import { Grid, InputBase } from '@material-ui/core'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  fetchSchedule,
  setSearchQuery
} from '../../../../../store/events/actions'
import {
  getSchedules,
  getSearchQuery,
  getTabValue
} from '../../../../../store/events/selectors'
import { getLeadDetails } from '../../../../../store/leads/selectors'
import SortMenu from './SortMenu'
import { useTlStyles } from './styles'
import TimelineCard from './TimelineCard'
import TLTabs from './TlTabs'
import TitleWrapper from '../TitleWrapper'
import { useTranslation } from 'react-i18next'
import PaginationRow from './Pagination'

const Timeline: React.FC = () => {
  const classes = useTlStyles()
  const dispatch = useDispatch()
  const lead = useSelector(getLeadDetails)
  const [value, setValue] = React.useState('')
  const { completed, pagination } = useSelector(getSchedules)
  const activityType = useSelector(getTabValue)
  const storeSearchQuery = useSelector(getSearchQuery)
  const [page, setPage] = React.useState(Number(pagination.currentPage))
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage)
    const data: any = {
      completed: true,
      currentPage: newPage,
      pageSize: 5
    }
    if (storeSearchQuery) data.searchQuery = storeSearchQuery
    if (activityType) data.activityType = activityType
    dispatch(fetchSchedule(lead.id, data))
  }

  const handleSearch = (searchQuery: string) => {
    setValue(searchQuery)
    dispatch(setSearchQuery(searchQuery))
    dispatch(
      fetchSchedule(lead.id, {
        lead_id: `${lead.id}`,
        completed: true,
        searchQuery,
        activityType,
        pageSize: 5
      })
    )
  }
  const { t } = useTranslation()

  return (
    <TitleWrapper title={t('Timeline')}>
      <React.Fragment>
        <Grid item xs={12} className={classes.searchWrapper}>
          <TLTabs />
        </Grid>
        <Grid item xs={12} className={classes.searchWrapper}>
          <InputBase
            name="searchQuery"
            type="search"
            value={value}
            onChange={(e) => handleSearch(e.target.value)}
            className={classes.searchInput}
            classes={{
              root: classes.searchInput
            }}
            placeholder={t('searchTimeline')}
          />
          <SortMenu />
        </Grid>
        {completed.map((activity, index) => (
          <TimelineCard key={activity.id} index={index} activity={activity} />
        ))}
        <PaginationRow
          total={pagination.total}
          page={page}
          handlePageChange={handleChangePage}
        />
      </React.Fragment>
    </TitleWrapper>
  )
}

export default Timeline
