import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { Props } from './types'

type LogoutProps = Props & RouteComponentProps

class Logout extends React.Component<LogoutProps, {}> {
  componentDidMount = () => {
    const { logout, history } = this.props

    logout(history)
  }

  render() {
    return <div>...logging you out</div>
  }
}

export default withRouter(Logout)
