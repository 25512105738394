import React from 'react'
import { StyledNoUpload } from './styles'
import DocumentUpload from './DocumentUpload'

interface IUpload {
  title: string
}

export default function EmptyUpload({ title }: IUpload) {
  return (
    <StyledNoUpload>
      <div className="uploadWrapper mt-3">
        <div className="title">{title}</div>

        <DocumentUpload />
      </div>
    </StyledNoUpload>
  )
}
