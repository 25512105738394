import React, { useState } from 'react'
import { StyledResponsesTable } from './styles'
// import { Input, Button, Table, Tag, Select } from 'antd'
import { Table, Tag, Button, Spin, message } from 'antd'
import { DownloadIcon } from '../../PortfolioManagement/PortfolioDetails/svgs'
import PPIStatusTag from './PPITag'
import { SOURCING_STATUS } from './constant'
import BookInspectionModal from '../BookInspection'
import { useSelector } from 'react-redux'
import {
  getUserToken,
  getSelectedCountry,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from '../../../utils/api'
import { useTranslation } from 'react-i18next'

interface IResponseProps {
  tableData: any
  dataLoading: boolean
  refreshLoanData: any
  singleLoan: any
}

const ResponsesTable: React.FC<IResponseProps> = ({
  tableData,
  dataLoading,
  refreshLoanData,
  singleLoan
}) => {
  const [activePage, setActivePage] = useState({
    currentPage: 1,
    pageSize: 10
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState()
  const [mutating, setMutating] = useState(false)
  const [responseRowData, setResponseRowData] = useState<any>()
  const { t } = useTranslation()

  const onChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page
    })
  }

  const token = useSelector(getUserToken)
  const country = useSelector(getSelectedCountry)
  const currency = useSelector(getSelectedCountryCurrency)
  const queryClient = useQueryClient()

  const handleActionButtonClick = (recordData: any) => {
    setModalData(recordData)
    setModalOpen(true)
  }

  const { data: car, isLoading: carLoading } = useQuery(
    ['car'],
    () =>
      api.getCar(token, {
        country: country,
        vin: responseRowData?.vin
        // vin: 'b3sbsfjttmpajl4t3'
      }),

    {
      onSuccess: (data: any) => {
        handleMutation(data)
      },
      enabled: responseRowData?.vin ? true : false
    }
  )

  const updateLoan: any = useMutation(
    (payload) => api.updateCarLoan(token, singleLoan?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loan-update')
        message.success('Loan updated')
        setMutating(false)
        refreshLoanData()
      },
      onError: (error: any) => {
        message.error(error?.response.data.error)
        setMutating(false)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  const handleCarReplace = async (_record: any) => {
    setResponseRowData(_record)
    setMutating(true)
  }

  const handleMutation = async (carInfo: any) => {
    setMutating(true)
    await updateLoan.mutateAsync({
      car_id: carInfo?.carList[0]?.id
    })
  }

  const columns = [
    {
      title: t('vehicle'),
      dataIndex: 'title'
      // width: 450
    },
    {
      title: 'VIN',
      dataIndex: 'vin'
      // width: 200
    },
    {
      title: 'Price',
      dataIndex: 'price',
      render: (val: string) => (
        <div>{`${currency} ${parseInt(val).toLocaleString()}`}</div>
      )
    },
    {
      title: t('status'),
      dataIndex: 'status',
      render: (val: string, record: any) => (
        <div className="d-flex">
          <PPIStatusTag status={record.titleCheckStatus}>
            {record.titleCheckStatus}
          </PPIStatusTag>
          <PPIStatusTag status={record.documentCheckStatus}>
            {record.documentCheckStatus}
          </PPIStatusTag>

          <PPIStatusTag status={record.ppiStatus}>
            {record.ppiStatus}
          </PPIStatusTag>

          {/* {record.documentCheckStatus === SOURCING_STATUS.DOCUMENT_ACCEPTED && (
            // record.status !== SOURCING_STATUS.CUSTOMER_PENDING && (
            <PPIStatusTag status={record.documentCheckStatus}>
              {record.documentCheckStatus}
            </PPIStatusTag>
          )} */}

          {record.status === SOURCING_STATUS.CUSTOMER_PENDING && (
            <PPIStatusTag status={record.status}>{record.status}</PPIStatusTag>
          )}
        </div>
      )
    },
    {
      title: t('action'),
      dataIndex: '',
      render: (val: string, record: any) => (
        <>
          {/* {record.ppiStatus === SOURCING_STATUS.PPI_PASS && (
            // record.status !== SOURCING_STATUS.CUSTOMER_PENDING &&
            <Button
              className="schedule"
              onClick={() => handleActionButtonClick(record)}
            >
              <span>Book Inspection</span>
            </Button>
          )} */}

          {record.ppiStatus === SOURCING_STATUS.PPI_PASS && (
            <Button
              className={
                singleLoan?.car.vin === record.vin
                  ? 'schedule  --inactive'
                  : 'schedule'
              }
              onClick={() => handleCarReplace(record)}
            >
              <span>{t('replaceCar')}</span>
            </Button>
          )}
        </>
      )
    }
  ]

  return (
    <StyledResponsesTable>
      <div className="responsesTableWrapper mt-4">
        <div className="header mb-3">{t('Responses')}</div>

        <Table
          dataSource={tableData}
          columns={columns}
          loading={dataLoading || mutating}
          pagination={{
            pageSize: activePage.pageSize,
            current: activePage.currentPage
          }}
          onChange={onChange}
          rowKey="id"
        />
      </div>
      <BookInspectionModal
        visibleBookInsp={modalOpen}
        setVisibleBookInsp={setModalOpen}
        modalData={modalData}
        singleLoan={singleLoan}
      />
    </StyledResponsesTable>
  )
}
export default ResponsesTable
