import React, { useState, useEffect } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import { StyledLoanDetails } from './styles'
import {
  ChevronRIcon,
  HistoryIcon,
  InboxIcon,
  PhoneIcon
} from '../../PortfolioManagement/PortfolioDetails/svgs'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Button } from 'antd'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Tabs, Tab } from 'react-bootstrap'
import TopNavigation from '../../../components/TopNav'
import CarDetailNav from '../../../components/DetailsNav'
import CustomerDocument from './CustomerDocument'
import CarSourcing from './CarSourcing'
import OffersUpload from '../../PortfolioManagement/PortfolioDetails/OffersUpload'
import OffersDocument from './OffersDocument'
import Equity from '../Equity'
import PayDealer from '../PayDealer'
import DisbursementDocument from '../Disbursement'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { getUserToken } from '../../../store/auth/selectors'
import api from '../../../utils/api'
import { CAR_STATUS } from '../AvailableCar/constants'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function CompletedLoanDetails() {
  let history = useHistory()
  const { id } = useParams<IDetailParam>()
  const location = useLocation()
  const [key, setKey] = useState('car')
  const token = useSelector(getUserToken)
  const queryClient = useQueryClient()
  const [step, setStep] = useState(0)
  const { t } = useTranslation()

  const { data: fetchOffer } = useQuery(
    'fetchOffer',
    () => api.fetchOffers(token, id),
    {
      onSuccess: () => {}
    }
  )

  const { data: fetchDocument } = useQuery(
    'document',
    () => api.fetchDocuments(token, id),
    {
      onSuccess: () => {}
    }
  )

  const { data: singleLoan } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    { keepPreviousData: true, staleTime: 5000 }
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
    }
  }, [singleLoan, queryClient, id])

  // console.log(CAR_STATUS)

  const handleSelect = (key: any) => {
    setKey(key)
    history.replace({
      hash: `${key}`
    })
  }

  // console.log(location.hash)
  useEffect(() => {
    if (singleLoan?.status === CAR_STATUS.APPLICATION_COMPLETED) {
      setStep(12.5)
    }
    if (singleLoan?.status === CAR_STATUS.OFFERS) {
      setStep(12.5)
    }
    if (singleLoan?.status === CAR_STATUS.OFFER_ACCEPTED) {
      setStep(12.5)
    }
    if (singleLoan?.status === CAR_STATUS.DOCUMENT_ACCEPTED) {
      setStep(25)
    }
    if (singleLoan?.status === CAR_STATUS.DOCUMENT_REJECTED) {
      setStep(25)
    }
    if (singleLoan?.status === CAR_STATUS.DOCUMENT_REQUESTED) {
      setStep(25)
    }
    if (singleLoan?.status === CAR_STATUS.CAR_SOURCING) {
      setStep(37.5)
    }
    if (singleLoan?.status === CAR_STATUS.CAR_SOURCING) {
      setStep(37.5)
    }
    if (singleLoan?.status === CAR_STATUS.CAR_ACCEPTED) {
      setStep(37.5)
    }
    if (singleLoan?.status === CAR_STATUS.PPI_IN_PROGRESS) {
      setStep(50)
    }
    if (singleLoan?.status === CAR_STATUS.PPI_PASS) {
      setStep(50)
    }
    if (singleLoan?.status === CAR_STATUS.PPI_FAILS) {
      setStep(50)
    }
    if (singleLoan?.status === CAR_STATUS.CUSTOMER_INSPECTION) {
      setStep(62.5)
    }
    if (singleLoan?.status === CAR_STATUS.EQUITY_REQUESTED) {
      setStep(75)
    }
    if (singleLoan?.status === CAR_STATUS.EQUITY_PAID) {
      setStep(75)
    }
    if (singleLoan?.status === CAR_STATUS.DISBURSED) {
      setStep(87.5)
    }
  }, [step, setStep, singleLoan])

  return (
    <WithSideMenu>
      <StyledLoanDetails>
        <TopNavigation />

        <div className="statusWrapper">
          <div className="progressLabels">
            <div
              style={{
                background: '0 0',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('offersReceived')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('documentReview')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('carAvailability')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              PPI
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('Cust. Inspection')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('equity')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('loanDisbursed')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('payDealer')}
            </div>
          </div>
          <ProgressBar className="progressBarActive" now={step} />
        </div>

        <CarDetailNav />

        <div
          className={
            location.hash === '#documents' ||
            location.hash === '#offers' ||
            location.hash === '#equity' ||
            location.hash === '#disbursement' ||
            location.hash === '#pay_dealer'
              ? 'leadsTabsContentWrapper bg-white'
              : 'leadsTabsContentWrapper'
          }
        >
          <div>
            <div className="leadTabs">
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={handleSelect}
                className="mb-4"
                variant="pills"
              >
                <Tab eventKey="car" title={t('car')}>
                  <CarSourcing />
                </Tab>
                <Tab eventKey="documents" title={t('Documents')}>
                  <CustomerDocument
                    title={
                      fetchDocument?.documents.length === 0
                        ? ''
                        : t('customerDocuments')
                    }
                  />
                </Tab>
                <Tab
                  eventKey="offers"
                  title={`${t('offers')}(${fetchOffer?.offers?.length || 0})`}
                >
                  <OffersDocument />
                </Tab>
                <Tab eventKey="equity" title={t('Equity')}>
                  <Equity
                    title={
                      singleLoan?.offer == undefined ? '' : t('receivedOffers')
                    }
                  />
                </Tab>
                <Tab eventKey="disbursement" title={t('disbursement')}>
                  <DisbursementDocument
                    title={
                      singleLoan?.offer === undefined ? '' : t('acceptedOffer')
                    }
                  />
                </Tab>
                <Tab eventKey="pay_dealer" title={t('payDealer')}>
                  <PayDealer />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      </StyledLoanDetails>
    </WithSideMenu>
  )
}
