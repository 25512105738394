import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import WithSideMenu from '../../../components/WithSideMenu'
import { StyledCompletedLoans } from './styles'
import { BellIcon, SearchIcon } from '../../PortfolioManagement/Dashboard/svgs'
import { Input, Button, Table, Tag, Select, Badge } from 'antd'
import {
  useHistory,
  withRouter,
  useLocation,
  useParams
} from 'react-router-dom'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  getUserToken,
  isManager,
  isAdmin,
  getUserId,
  getUserFirstName,
  getUserLastName,
  isLead,
  isPortfolio
} from '../../../store/auth/selectors'
import { getCountryCode } from '../../../utils/country.util'
import api from '../../../utils/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import Loader from '../../../components/Loader'
import { CAR_STATUS } from '../AvailableCar/constants'
import { paramsObjectToQueryString } from '../../../utils/paramObjectToQuery'
import queryString from 'query-string'
import NotificationModal from '../../../components/NotificationModal'
import { useTranslation } from 'react-i18next'

export default function CompletedLoans() {
  let history = useHistory()
  const params = useParams()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const firstName = useSelector(getUserFirstName)
  const lastName = useSelector(getUserLastName)
  const userIsAdmin = useSelector(isAdmin)
  const userIsManager = useSelector(isManager)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const { Option } = Select
  const [searchValue, setSearchValue] = useState('')
  const [loanTable, setLoanTable] = useState<any>([])
  const [activePage, setActivePage] = useState({
    currentPage: 1,
    pageSize: 10
  })
  const [getAgent, setGetAgent] = useState<any>({})
  const { search } = useLocation()
  const query = queryString.parse(search)
  const userId = useSelector(getUserId)
  const userIsLead = useSelector(isLead)
  const userIsPortfolio = useSelector(isPortfolio)
  const [isNotificationVisible, setIsNotificationVisible] = useState(false)
  const { t } = useTranslation()

  const { data: fetchLoans, status } = useQuery(
    ['fetchOrigin', activePage, country, query],
    () =>
      api.fetchOriginationLoans2(token, {
        ...query,
        country: country
        // page_size: activePage.pageSize,
        // current_page: activePage.currentPage
        // current_page: activePage.currentPage
      })
  )

  const { data: fetchAgents } = useQuery(['fetchAgent', country], () =>
    api.fetchOriginAgents(token, {
      country: country,
      role_name: 'LEAD_MANAGER'
    })
  )

  useEffect(() => {
    if (query.current_page && typeof query.current_page == 'string') {
      setActivePage({
        ...activePage,
        currentPage: parseInt(query.current_page)
      })
    }
  }, [])

  useEffect(() => {
    setLoanTable(fetchLoans?.loanLeads)
  }, [fetchLoans?.loanLeads, activePage])

  const onSearchChange = (e: any) => {
    setSearchValue(e.target.value)
  }

  const handleSearch = () => {
    history.push(
      `/completed-loans${paramsObjectToQueryString({
        ...query,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        first_name: searchValue
      })}`
    )
  }

  const onChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page.current
    })
    history.push(
      `/completed-loans${paramsObjectToQueryString({
        ...query,
        current_page: page.current,
        page_size: page.pageSize
      })}`
    )
  }

  function handleFilter(value: any) {
    history.push(
      `/completed-loans${paramsObjectToQueryString({
        ...query,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        status: value
      })}`
    )
  }

  function handleAgentFilter(value: any) {
    history.push(
      `/completed-loans${paramsObjectToQueryString({
        ...query,
        current_page: activePage.currentPage,
        page_size: activePage.pageSize,
        lead_manager_id: value
      })}`
    )
  }

  function capitalFirstLetter(str: any) {
    const arr = str?.split(' ')
    for (var i = 0; i < arr?.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr?.join(' ')
  }

  function getInitials(name: any) {
    const hasTokens = name.indexOf(' ') !== -1
    return (
      name.substring(0, hasTokens ? 1 : 2) +
      (hasTokens ? name.charAt(name.lastIndexOf(' ') + 1) : '')
    )
  }

  const dataType = loanTable?.map((el: any) => ({
    customerDetails: `${el?.firstName}, ${el?.lastName} ${el?.phone} ${el?.email}`,
    car: `${el?.carName || 'N/A'}`,
    loanValue: (
      <span>
        {currency}
        {el?.loanValue?.toLocaleString() || 0}
      </span>
    ),
    agent: <span>{capitalFirstLetter(el?.leadManager?.name) || 'N/A'}</span>,
    status: (
      <Tag
        color="rgba(88, 135, 255, 0.1)"
        style={{
          color: '#30355B',
          border: '1px solid #30355B',
          borderRadius: '100px',
          fontWeight: 500
        }}
      >
        {/* {el.status?.replace('_', ' ') || 'N/A'} */}
        {t(`${el?.status}`)}
      </Tag>
    ),
    id: `${el?.id}`,
    carId: `${el?.carId}`,
    country: `${el?.country}`
  }))

  const columns = [
    {
      title: t('customerDetails'),
      dataIndex: 'customerDetails'
      // width: 450
    },
    {
      title: t('car'),
      dataIndex: 'car'
      // width: 200
    },
    {
      title: t('loanValue'),
      dataIndex: 'loanValue'
    },
    {
      title: t('agent'),
      dataIndex: 'agent'
    },
    {
      title: t('status'),
      dataIndex: 'status'
    }
  ]

  const {
    data: fetchNotifications,
    status: notificationStatus,
    refetch
  } = useQuery(['notification'], () =>
    api.fetchCrmOriginationNotif(token, {
      agent_id: userId, //'byU7SkIg0'
      read: false,
      type: 'loan'
    })
  )

  const showNotificationModal = () => {
    setIsNotificationVisible(true)
  }

  return (
    <WithSideMenu>
      <StyledCompletedLoans>
        {status == 'loading' ? (
          <Loader />
        ) : (
          <>
            <div className="headerWrapper">
              <div className="searchButton">
                <Input
                  placeholder={t('searchByCustomerDetailsCar')}
                  bordered={false}
                  prefix={SearchIcon}
                  style={{ width: '486px' }}
                  onChange={onSearchChange}
                  value={searchValue}
                />
                <Button type="primary" onClick={handleSearch}>
                  {t('search')}
                </Button>
              </div>
              <div className="userWrapper">
                <div className="users">
                  <div style={{ textAlign: 'right' }}>
                    <div className="name">
                      {firstName} {lastName}
                    </div>
                    <div className="role">
                      {userIsPortfolio && t('portfolioManager')}
                      {userIsLead && t('leadManager')}
                      {(userIsAdmin || userIsManager) && t('CRMManager')}
                    </div>
                  </div>
                  <div className="initials">
                    {getInitials(`${firstName} ${lastName}`)}
                  </div>
                </div>
                <div className="bell" onClick={() => showNotificationModal()}>
                  <Badge count={fetchNotifications?.data?.length || 0} showZero>
                    <div>{BellIcon}</div>
                  </Badge>
                </div>
              </div>
            </div>
            <NotificationModal
              isNotificationVisible={isNotificationVisible}
              setIsNotificationVisible={setIsNotificationVisible}
              fetchNotifications={fetchNotifications}
              refetch={refetch}
            />

            <div className="tableWrapper">
              <h3>{t('LOAN LEADS')}</h3>

              <div className="filterWrapper d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="d-flex filter me-3">{t('filterBy')}:</div>
                  {userIsAdmin || userIsManager ? (
                    <div className="me-3">
                      <Select
                        placeholder={t('agent')}
                        style={{ width: 185 }}
                        onChange={handleAgentFilter}
                        size={'large'}
                      >
                        {fetchAgents?.users?.map((el: any, index: any) => (
                          <Option key={index} value={el?.id}>
                            {el?.firstname} {el?.lastname}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  ) : (
                    ''
                  )}

                  <div className="me-3">
                    <Select
                      placeholder={t('status')}
                      style={{ minWidth: 200 }}
                      onChange={handleFilter}
                      size={'large'}
                    >
                      <Option value="APPLICATION_COMPLETED">
                        {t('applicationCompleted')}
                      </Option>
                      <Option value="CAR_ACCEPTED">{t('carAccepted')}</Option>
                      <Option value="CAR_SOURCING">{t('carSourcing')}</Option>
                      <Option value="CUSTOMER_INSPECTION">
                        {t('customerInspection')}
                      </Option>
                      <Option value="DISBURSED">{t('disbursed')}</Option>
                      <Option value="DOCUMENT_ACCEPTED">
                        {t('documentAccepted')}
                      </Option>
                      <Option value="DOCUMENT_REJECTED">
                        {t('documentRejected')}
                      </Option>
                      <Option value="EQUITY_PAID">{t('equityPaid')}</Option>
                      <Option value="EQUITY_REQUESTED">
                        {t('equityRequested')}
                      </Option>
                      <Option value="PPI_IN_PROGRESS">
                        {t('PPIProgress')}
                      </Option>
                      <Option value="PPI_PASS">{t('PPIPass')}</Option>
                      <Option value="PPI_FAILS">{t('PPIFails')}</Option>
                      <Option value="OFFERS">{t('offers')}</Option>
                      <Option value="OFFER_ACCEPTED">
                        {t('offerAccepted')}
                      </Option>
                      <Option value="DECLINED">{t('declined')}</Option>
                      <Option value="DOCUMENT_REQUESTED">
                        {t('documentRequested')}
                      </Option>
                    </Select>
                  </div>
                  <div className="me-3 d-none">
                    <Select
                      defaultValue="1"
                      style={{ width: 185 }}
                      // onChange={handleChange}
                      size={'large'}
                    >
                      <Option value="1">Stage</Option>
                    </Select>
                  </div>
                </div>
              </div>

              <Table
                dataSource={dataType}
                columns={columns}
                pagination={{
                  current: activePage.currentPage,
                  pageSize: activePage.pageSize,
                  total: fetchLoans?.pagination?.total,
                  position: ['bottomCenter']
                }}
                onChange={onChange}
                rowKey="id"
                onRow={(data, rowIndex) => {
                  return {
                    onClick: (event) => {
                      history.push(`/completed-loans/${data.id}`)
                    } // click row
                  }
                }}
              />
            </div>
          </>
        )}
      </StyledCompletedLoans>
    </WithSideMenu>
  )
}
