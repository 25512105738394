import React from 'react'
import { Switch, Route } from 'react-router-dom'
import SignIn from '../views/Auth/SignIn'
import Logout from '../views/Auth/Logout'
import Leads from '../views/Leads'
import LeadDetails from '../views/Leads/LeadDetails'
import ResetPassword from '../views/Auth/ResetPassword'
import ForgotPassword from '../views/Auth/ForgotPassword'
import Conversations from '../views/Conversations'
import Activities from '../views/Activities'
import Users from '../views/SettingsSection/Users'
import WhatsAppAgents from '../views/SettingsSection/Users/WhatsAppAgents'
import NewUser from '../views/SettingsSection/Users/NewUser'
import BulkAssign from '../views/SettingsSection/Users/BulkAssign'
import EditUser from '../views/SettingsSection/Users/EditUser'
import PrivateRoute from './privateRoute'
import Settings from '../views/SettingsSection'
import Loans from '../views/Loans/Loans'
import DsaLoans from '../views/Loans/DsaLoans'
import FranchiseLoans from '../views/Loans/FranchiseLoans'
import LoanDetails from '../views/Loans/LoanDetails/LoanDetails'
import LoanTable from '../views/PortfolioManagement/LoanTable/index'
import LeadManagement from '../views/LeadManagement/LeadMgtDashboard'
import PortfolioDashboard from '../views/PortfolioManagement/Dashboard'
import PortfolioDetails from '../views/PortfolioManagement/PortfolioDetails'
import CompletedLoans from '../views/LeadManagement/CompletedLoans'
import CompletedLoanDetails from '../views/LeadManagement/CompletedLoanDetails'
import PartnerMapping from '../views/SettingsSection/Users/PartnerMapping'
import CreateWhatsappAgent from '../views/SettingsSection/Users/CreateWhatsappAgent'
import CreateAgent from '../views/SettingsSection/Users/CreateAgent'

const Routes = () => {
  return (
    <Switch>
      <Route path="/login" component={SignIn} />
      <Route path="/logout" component={Logout} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <PrivateRoute path="/reset-password" component={ResetPassword} />
      <PrivateRoute exact path="/leads" component={Leads} />
      <PrivateRoute exact path="/leads/:leadId" component={LeadDetails} />
      <PrivateRoute exact path="/activities" component={Activities} />

      <PrivateRoute
        path={[
          '/conversations/:status/:recipientPhone',
          '/conversations/:status',
          '/conversations'
        ]}
        component={Conversations}
      />
      <PrivateRoute exact path="/loans" component={Loans} />
      <PrivateRoute exact path="/dsa" component={DsaLoans} />
      <PrivateRoute exact path="/franchise" component={FranchiseLoans} />
      <PrivateRoute exact path="/loans/:id" component={LoanDetails} />
      <PrivateRoute exact path="/settings" component={Settings} />
      <PrivateRoute exact path="/portfolio-loans" component={LoanTable} />
      <PrivateRoute
        exact
        path="/portfolio-loans/:id"
        component={PortfolioDetails}
      />
      <PrivateRoute
        exact
        path="/portfolio-mgt"
        component={PortfolioDashboard}
      />
      <PrivateRoute exact path="/lead-dashboard" component={LeadManagement} />
      <PrivateRoute exact path="/completed-loans" component={CompletedLoans} />
      <PrivateRoute
        exact
        path="/completed-loans/:id"
        component={CompletedLoanDetails}
      />
      <PrivateRoute exact path="/settings/agents" component={Users} />
      <PrivateRoute
        exact
        path="/settings/partner-mapping"
        component={PartnerMapping}
      />
      <PrivateRoute
        exact
        path="/settings/agents/whatsapp/create"
        component={CreateWhatsappAgent}
      />
      {/* <PrivateRoute exact path="/settings/agents/create" component={NewUser} /> */}
      <PrivateRoute
        exact
        path="/settings/agents/create"
        component={CreateAgent}
      />
      <PrivateRoute exact path="/settings/agents/edit" component={EditUser} />
      <PrivateRoute exact path="/settings/agents/bulk" component={BulkAssign} />
      <PrivateRoute
        exact
        path="/settings/agents/whatsapp"
        component={WhatsAppAgents}
      />
      <Route path="/">
        <SignIn />
      </Route>
    </Switch>
  )
}

export default Routes
