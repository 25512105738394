import React from 'react'

const CaretDown = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.0014 15.333C9.83076 15.333 9.6601 15.2677 9.5301 15.1377L2.86343 8.47101C2.67276 8.28034 2.61543 7.99367 2.71876 7.74434C2.82143 7.49501 3.06543 7.33301 3.33476 7.33301H16.6681C16.9374 7.33301 17.1814 7.49501 17.2841 7.74434C17.3874 7.99367 17.3301 8.28034 17.1394 8.47101L10.4728 15.1377C10.3428 15.2677 10.1721 15.333 10.0014 15.333Z"
        fill="#26386D"
      />
    </svg>
  )
}

export default CaretDown
