import styled from 'styled-components'

export const StyledDisbursedOffer = styled.div`
  .acceptedOffer {
    .containers {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 15px;
    }
    .carSource {
      color: #05a660;
      font-weight: 600;
      font-size: 14px;
      background: rgba(5, 166, 96, 0.1);
      border-radius: 34px;
      min-width: 182px;
      width: auto;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .equityButton {
      .addedEquity {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        background: #ffb619;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        min-width: 187px;
        width: auto;
        height: 38px;
        border: 0;
      }
      .addedRequestEquity {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        background: #ffb619;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        min-width: 247px;
        width: auto;
        height: 38px;
        border: 0;
      }
      .recordDisburse {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        background: #ffffff;
        border: 1px solid #30355b;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        min-width: 247px;
        width: auto;
        height: 38px;
      }
      .pendingEquity {
        .pending {
          color: #ffb619;
          display: flex;
          align-items: center;
          justify-content: center;
          font-weight: 600;
          font-size: 13px;
          background: rgba(255, 182, 25, 0.28);
          border-radius: 45px;
          min-width: 187px;
          width: auto;
          height: 34px;
        }
        .amount {
          color: #000000;
          font-size: 13px;
          span {
            font-weight: 600;
          }
        }
      }
      .equityPaid {
        .amount {
          color: #333333;
          font-size: 13px;
          span {
            font-weight: 600;
          }
          .paid {
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: 600;
            font-size: 13px;
            background: #05a660;
            border-radius: 45px;
            min-width: 177px;
            width: auto;
            height: 30px;
          }
        }
        .pdfFile {
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.19);
          border-radius: 8px;
          min-width: 217px;
          width: auto;
          height: 52.05px;
          padding: 10px;
          .image {
            background: rgba(209, 55, 63, 0.1);
            border-radius: 2px;
            width: 24px;
            height: 24px;
            overflow: hidden;
          }
          .payment {
            color: #30355b;
            font-weight: 600;
            font-size: 10.2629px;
          }
          .size {
            font-weight: 500;
            font-size: 8.79681px;
            color: #787878;
          }
        }
        .links {
          a {
            color: #000000;
            font-weight: 600;
            font-size: 11px;
            text-decoration: underline;
          }
        }
      }
    }
  }
`

export const StyledDisbursedModal = styled.div`
  .equityDisburseModal {
    .header {
      color: #30355b;
      font-weight: 600;
      font-size: 16px;
    }

    form {
      label {
        color: #333333;
        font-size: 14px;
      }
      input {
        height: 47px;
      }
      .ant-input-number {
        background: #ffffff;
        border: 1px solid #c7c9d9;
        box-sizing: border-box;
        border-radius: 4px;
        height: 47px;
        font-size: 14px;
        width: 100%;
      }
      .cancelButton {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        background: #fff;
        border: 1px solid #30355b;
        border-radius: 5px;
        min-width: 187px;
        width: auto;
        height: 44px;
      }
      .requestButton {
        color: #30355b;
        font-weight: 600;
        font-size: 14px;
        background: #ffb619;
        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
        border: 0;
        min-width: 187px;
        width: auto;
        height: 44px;
      }
      .ant-select-single .ant-select-selector .ant-select-selection-item,
      .ant-select-single
        .ant-select-selector
        .ant-select-selection-placeholder {
        padding: 0;
        line-height: 45px;
        transition: all 0.3s;
      }
      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 47px;
        padding: 0 11px;
      }
      .ant-upload-text {
        font-size: 12px;
        color: #000000;
        font-weight: 600;
      }
    }
  }
`

export const StyledPaid = styled.div`
  .paid {
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 13px;
    background: #05a660;
    border-radius: 45px;
    width: 167px;
    height: 30px;
    margin-top: 10px;
  }
`
