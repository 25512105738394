import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getSelectedCountry,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import {
  showVehicleModal,
  showSellCarModal
} from '../../../store/modal/actions'
import Tooltip from '@material-ui/core/Tooltip'
import { Link } from 'react-router-dom'
import {
  getAllVehicleDetails,
  saveLoanCarId
} from '../../../store/leads/actions'
import { getAllBuyCarId } from '../../../store/leads/selectors'
import { useTranslation } from 'react-i18next'
import VehicleDetails from '../../Leads/LeadDetails/ServiceInterest/VehicleDetails'

let linKtoConsole =
  process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
    ? 'https://console.staging.myautochek.com/inventory/'
    : 'https://console.autochek.africa/inventory/'

const DUMMY_URL =
  'https://storage.googleapis.com/ng.img.autochek.africa/xxl/6_inventory2248780_1601107482.jpg'

const useStyles = makeStyles({
  carItem: {
    display: 'flex',
    padding: '5px',
    marginBottom: '1em',
    margin: '5px',
    width: '100%',
    border: '1px solid #A1AACE',
    borderRadius: '4px',
    transition: '0.9s',
    flexBasis: '32.4%',
    cursor: 'pointer',
    '&:hover': {
      transition: '0.9s',
      boxShadow: '0 12px 11px rgb(0 0 0 / 4%), 0 100px 80px rgb(0 0 0 / 7%)'
    }
  },
  carImage: {
    flexShrink: 0,
    width: '35%',
    borderRadius: '4px',
    objectFit: 'cover'
  },
  carSummary: {
    color: '#0F1A4F',
    width: '65%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: '0.5em',
    '& p': {
      margin: '0'
    }
  },
  carSummaryTop: {
    // overflow: 'hidden',
    '& > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },
  carSummaryBottom: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  carPrice: {
    fontWeight: 'bold'
  },
  carId: {
    fontSize: '12px',
    flexBasis: '60%'
  },
  viewDetailsBtn: {
    background: 'transparent',
    border: 'none',
    color: '#4B9FF2',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: 0
  },
  acceptedBlock: {
    background: '#3ED24D',
    borderRadius: '2px',
    padding: '.4em',
    width: '10em',
    marginLef: '1em',
    justifyContent: 'center',
    minHeight: '10px',
    color: '#fff'
  },
  inReview: {
    background: '#BAE9FF',

    borderRadius: '2px',
    padding: '.4em',
    width: '10em',
    marginLef: '1em',
    justifyContent: 'center',
    minHeight: '10px',
    color: '#000'
  },
  draft: {
    background: '#FCD98B',

    borderRadius: '2px',
    padding: '.4em',
    width: '10em',
    marginLef: '1em',
    justifyContent: 'center',
    minHeight: '10px',
    color: '#000'
  },
  declined: {
    background: '#FF8484',

    borderRadius: '2px',
    padding: '.4em',
    width: '10em',
    marginLef: '1em',
    justifyContent: 'center',
    minHeight: '10px',
    color: '#fff'
  }
})

const CarItem = (props: any) => {
  const classes = useStyles()
  const currency = useSelector(getSelectedCountryCurrency)
  const selectedCountry = useSelector(getSelectedCountry)
  const allCarId = useSelector(getAllBuyCarId)
  const carIds = useSelector(getSelectedCountry)
  const [selectedCarId, setSelectedCarId] = React.useState('')
  const dispatch = useDispatch()

  const { t } = useTranslation()

  const showModal = (carId: string) => {
    setSelectedCarId(carId)

    setTimeout(() => {
      dispatch(showVehicleModal())
    }, 600)
  }

  //   React.useEffect(() => {

  //   if(props.car.id){
  // let carId = [props.car.id]

  // dispatch(saveLoanCarId(props.car.id))
  // setTimeout(()=>{

  //   console.log('set time',props.car.id)
  // }, 3000)
  // dispatch(getAllVehicleDetails(carIds))
  // console.log('get',props.car.id)

  //   }

  //     },[props.car.id])

  return (
    <Tooltip title="Click to view loan stage" arrow>
      <div
        className={classes.carItem}
        onClick={() => props.getLoanStage(props.car.id, props.car)}
      >
        <div
          style={{
            backgroundImage: `url(${props.car.dataProvided?.imageUrl})`,
            flexBasis: '30%',
            backgroundSize: 'cover',
            height: '135px',
            marginRight: '10px',
            borderRadius: '3%'
          }}
        ></div>

        {/* <img src={props.car.imageUrl} alt="Selected car" className={classes.carImage} /> */}
        <div className={classes.carSummary}>
          <div className={classes.carSummaryTop}>
            <p className={classes.carPrice}>{props.carName}</p>
            <p>
              {currency}
              {props.car?.dataProvided?.price?.toLocaleString()}
            </p>
          </div>

          <div className={classes.carSummaryBottom}>
            <p className={classes.carId}>
              {t('Car ID')}: {props.car.carId}
            </p>
          </div>
          <div>
            <p className={classes.carId}>
              {t('location')}: {props.car?.dataProvided?.city}{' '}
              {props.car?.dataProvided?.address}, {props.car.country}
            </p>
          </div>
          <div>
            <p className={classes.carId}>
              {t('loanStatus')} : &nbsp;
              {props.car.status === 'OFFER_ACCEPTED' ? (
                <span className={classes.acceptedBlock}>
                  {props.car.status}
                </span>
              ) : null}
              {props.car.status === 'DECLINED' ? (
                <span className={classes.declined}>{props.car.status}</span>
              ) : null}
              {props.car.status === 'OFFER_RECEIVED' ? (
                <span className={classes.inReview}>{props.car.status}</span>
              ) : null}
              {props.car.status === 'APPLICATION_COMPLETED' ? (
                <span className={classes.inReview}>{props.car.status}</span>
              ) : null}
              {props.car.status === 'APPLICATION_NOT_COMPLETED' ? (
                <span className={classes.draft}>{props.car.status}</span>
              ) : null}
              {props.car.status === 'APPLICATION_NOT_COMPLETED' ? (
                <span className={classes.draft}>{props.car.status}</span>
              ) : null}
              {props.car.status === 'APPLICATION_NOT_COMPLETED' ? (
                <span className={classes.draft}>{props.car.status}</span>
              ) : null}
            </p>
          </div>
          <div className={classes.carSummaryBottom}>
            <a href={`${linKtoConsole + props.car.carId}`} target="/blank">
              <button className={classes.viewDetailsBtn}>
                {t('viewConsole')}
              </button>
            </a>
          </div>
        </div>
        {/* <VehicleDetails country={selectedCountry} carId={props.car.id} /> */}
      </div>
    </Tooltip>
  )
}

export default CarItem
