import React from 'react'
import { AutochekThemeOptions } from '../../../theme/types'

type Props = {
  theme: AutochekThemeOptions
  active: boolean
}

const MessagesIcon = ({ active, theme }: Props) => {
  const color = active
    ? theme.palette.neutral.kournikova
    : theme.palette.common.white
  return (
    <svg
      width={18}
      height={18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M16.3125 14.0625H8.4375L3.9375 17.4375V14.0625H1.6875V14.0625C1.06618 14.0625 0.5625 13.5588 0.5625 12.9375V1.6875V1.6875C0.5625 1.06618 1.06618 0.5625 1.6875 0.5625H16.3125V0.5625C16.9338 0.5625 17.4375 1.06618 17.4375 1.6875V1.6875V12.9375V12.9375C17.4375 13.5588 16.9338 14.0625 16.3125 14.0625C16.3125 14.0625 16.3125 14.0625 16.3125 14.0625V14.0625Z"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 5.91016H14.0625"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.3125 9.28516H14.0625"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.9375 5.625V5.625C3.78217 5.625 3.65625 5.75092 3.65625 5.90625C3.65625 6.06158 3.78217 6.1875 3.9375 6.1875C4.09283 6.1875 4.21875 6.06158 4.21875 5.90625V5.90625C4.21875 5.75092 4.09283 5.625 3.9375 5.625"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.9375 9V9C3.78217 9 3.65625 9.12592 3.65625 9.28125C3.65625 9.43658 3.78217 9.5625 3.9375 9.5625C4.09283 9.5625 4.21875 9.43658 4.21875 9.28125V9.28125C4.21875 9.12592 4.09283 9 3.9375 9"
          stroke={color}
          strokeWidth="1.25"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width={18} height={18} fill={color} />
        </clipPath>
      </defs>
    </svg>
  )
}

export default MessagesIcon
