import React from 'react'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import { useDispatch } from 'react-redux'
import { deleteSchedule } from '../../../../../store/events/actions'

const options = ['Delete']

interface IProps {
    id: string;
}

export default function DeleteMenu({ id }:IProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleDelete = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault()
    handleClose()
    dispatch(deleteSchedule(id))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            width: '20ch'
          }
        }}
      >
        {options.map((option) => (
          <MenuItem key={option} onClick={handleDelete}>
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
