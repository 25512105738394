import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector).use(initReactI18next).init({
  fallbackLng: 'en',
  // lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en.json')
    },
    fr: {
      translations: require('./locales/fr.json')
    }
  },
   // defaultVersion: '',

  ns: ['translations'],
  defaultNS: 'translations'
});

i18n.languages = ['en', 'fr'];

export default i18n;