import { call, put, select, takeEvery } from 'redux-saga/effects'
import api from '../../utils/api'
import { getUserToken, getUserLocation } from '../auth/selectors'
import { requestStatus } from '../events/actions'
import { showSnackbar } from '../snackbar/actions'
import { ELocationTypes } from './actions'

const ERROR_MSG = 'Unable to complete your request.'

export function* getStates(): any {
  try {
    const token = yield select(getUserToken)

    const userLocation = yield select(getUserLocation)
    const response = yield call(api.fetchStates, token, userLocation.id)
    yield put(requestStatus(ELocationTypes.GET_STATES_SUCCESS, response))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}

export function* getCity({ stateId }: any): any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCity, token, stateId)
    yield put(requestStatus(ELocationTypes.GET_CITY_SUCCESS, response))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}
export function* getHomeCity({ stateId }: any): any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCity, token, stateId)
    yield put(requestStatus(ELocationTypes.GET_CITY_HOME_SUCCESS, response))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}

function* watchState() {
  yield takeEvery(ELocationTypes.GET_STATES, getStates)
}

function* watchCity() {
  yield takeEvery(ELocationTypes.GET_CITY, getCity)
}

function* watchHomeCity() {
  yield takeEvery(ELocationTypes.GET_HOME_CITY, getHomeCity)
}

const locationSagas: any[] = [watchState(), watchCity(), watchHomeCity()]

export default locationSagas
