import { call, put, select, takeLatest } from 'redux-saga/effects'
import api from '../../utils/api'
import { getUserToken } from '../auth/selectors'
import { requestStatus } from '../events/actions'
import { showSnackbar } from '../snackbar/actions'
import { ECarTypes } from './actions'

const ERROR_MSG = 'Unable to complete your request.'

export function* getCarMake(): any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCarMakeOptions, token)
    yield put(requestStatus(ECarTypes.GET_CAR_MAKE_SUCCESS, response))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}

export function* getCarModel({ makeId }: any): any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCarModels, token, makeId)
    yield put(requestStatus(ECarTypes.GET_CAR_MODEL_SUCCESS, response))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}

export function* getCarBodyTypes(): any {
  try {
    const token = yield select(getUserToken)
    const response = yield call(api.fetchCarBodyTypes, token)
    yield put(requestStatus(ECarTypes.GET_CAR_BODY_SUCCESS, response))
  } catch (error) {
    yield put(showSnackbar(ERROR_MSG, 'error'))
  }
}

function* watchGetMake() {
  yield takeLatest(ECarTypes.GET_CAR_MAKE, getCarMake)
}

function* watchGetModel() {
  yield takeLatest(ECarTypes.GET_CAR_MODEL, getCarModel)
}

function* watchGetBodyTypes() {
  yield takeLatest(ECarTypes.GET_CAR_BODY, getCarBodyTypes)
}

const carSagas: any[] = [watchGetMake(), watchGetModel(), watchGetBodyTypes()]

export default carSagas
