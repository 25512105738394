import { connect } from 'react-redux'
import ForgotPasswordComponent from './ForgotPassword'
import { sendOTP, verifyOTP } from '../../../store/auth/actions'
import { getIsAuth, getRoles } from '../../../store/auth/selectors'
import { StateProps, DispatchProps } from './types'

export const mapStateToProps = (state: Object) => ({
  isAuth: getIsAuth(state),
  userRoles: getRoles(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  sendOTP: (payload: string) => dispatch(sendOTP(payload)),
  verifyOTP: (otp: string, history: Object) => dispatch(verifyOTP(otp, history))
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordComponent)
