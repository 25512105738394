import React from 'react'
import {
  FormHelperText,
  Grid,
  InputBase,
  TextField,
  Typography
} from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { usePhoneStyles } from './styles'
import CustomInput from './CustomInput'
import FormFooter from './FormFooter'
import { useDispatch, useSelector } from 'react-redux'
import { addSchedule, setEvent } from '../../../../store/events/actions'
import {
  getEvents,
  getLocationOptions,
  getServiceOptions
} from '../../../../store/events/selectors'
import { getLeadDetails } from '../../../../store/leads/selectors'

const required = Yup.string().required('This field is required.')
const validationSchema = Yup.object().shape({
  date: required,
  time: required,
  note: required,
  service: required,
  location: required
})
const initialValues = {
  date: '',
  time: '',
  note: '',
  service: '',
  location: ''
}

const StoreVisit: React.FC = () => {
  const classes = usePhoneStyles()
  const dispatch = useDispatch()
  const lead = useSelector(getLeadDetails)
  const event = useSelector(getEvents)
  const locationOptions = useSelector(getLocationOptions)
  const serviceOptions = useSelector(getServiceOptions)

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldTouched,
    setFieldValue,
    touched,
    errors
  } = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (values) => {
      const { date, time, note, service, location } = values
      dispatch(
        addSchedule({
          leadId: lead.id,
          activityType: event,
          completed: true,
          serviceRenderedId: service,
          locationId: location,
          date,
          time,
          note
        })
      )
      handleReset()
    }
  })
  const handleReset = () => {
    dispatch(setEvent(''))
  }
  return (
    <Grid container item xs={12}>
      <Grid item xs={12} className={classes.datetime}>
        <div>
          <Typography variant="body2" className={classes.label}>
            Date
          </Typography>
          <TextField
            id="date"
            name="date"
            onBlur={handleBlur}
            error={!!(errors.date && touched.date)}
            helperText={touched.date && errors.date}
            value={values.date}
            onChange={handleChange}
            variant="outlined"
            type="date"
            // inputProps={{
            //   min: dayjs(new Date()).format('YYYY-MM-DD')
            // }}
          />
        </div>
        <div>
          <Typography variant="body2" className={classes.label}>
            Time
          </Typography>
          <InputBase
            name="time"
            type="time"
            className={classes.timeInputWrapper}
            onBlur={handleBlur}
            error={!!(errors.time && touched.time)}
            value={values.time}
            onChange={handleChange}
          />
          {!!touched.time && <FormHelperText error>{errors.time}</FormHelperText>}
        </div>
      </Grid>
      <Grid item xs={7} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          Service rendered
        </Typography>
        <CustomInput
          name="service"
          handleBlur={setFieldTouched}
          handleChange={setFieldValue}
          width="26.5rem"
          error={!!(errors.service && touched.service)}
          helperText={touched.service && errors.service}
          value={values.service}
          options={serviceOptions}
        />
      </Grid>
      <Grid item xs={7} className={classes.text}>
        <Typography variant="body2" className={classes.label}>
          Location
        </Typography>
        <CustomInput
          handleBlur={setFieldTouched}
          handleChange={setFieldValue}
          name="location"
          width="26.5rem"
          error={!!(errors.location && touched.location)}
          helperText={touched.location && errors.location}
          value={values.location}
          options={locationOptions}
        />
      </Grid>
      <Grid item xs={12} className={classes.text}>
        <Typography variant="body2" className={classes.labelDark}>
          What was the outcome of the visit?
        </Typography>
        <TextField
          fullWidth
          multiline
          rows={3}
          onBlur={handleBlur}
          error={!!(errors.note && touched.note)}
          helperText={touched.note && errors.note}
          name="note"
          value={values.note}
          onChange={handleChange}
          id="note"
          variant="outlined"
        />
      </Grid>
      <FormFooter handleReset={handleReset} handleSubmit={handleSubmit} />
    </Grid>
  )
}

export default StoreVisit
