import React from 'react'

const StatusIcon = () => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 4.99805V10.998"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.5 7.49805L8 4.99805L10.5 7.49805"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.998C11.866 14.998 15 11.864 15 7.99805C15 4.13205 11.866 0.998047 8 0.998047C4.13401 0.998047 1 4.13205 1 7.99805C1 11.864 4.13401 14.998 8 14.998Z"
        stroke="#AEB6BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default StatusIcon
