import { connect } from 'react-redux'
import AccountPreference from './AccountPreference'
import {
  getUserEmail,
  getUserFirstName,
  getUserLastName,
  getUserPhone,
  getUserProfileImage
} from '../../../../store/auth/selectors'
import {
  uploadPhoto,
  updateUser
} from '../../../../store/settingsSection/actions'
import { resetAccountPassword } from '../../../../store/auth/actions'
import { StateProps, DispatchProps, Details } from './types'

export const mapStateToProps = (state: Object) => ({
  email: getUserEmail(state),
  firstname: getUserFirstName(state),
  lastname: getUserLastName(state),
  phone: getUserPhone(state),
  profileImage: getUserProfileImage(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  uploadPhoto: (file: File | null) => dispatch(uploadPhoto(file)),
  updateUser: (details: Details) => dispatch(updateUser(details)),
  resetPassword: (password: string) => dispatch(resetAccountPassword(password))
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(AccountPreference)
