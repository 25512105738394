import React from "react";
import { MuiThemeProvider } from "@material-ui/core";
import { ThemeProvider } from 'styled-components';
import { StylesProvider } from "@material-ui/core/styles";
import AutochekTheme from "./AutochekTheme";

type Props = {
  children?: React.ReactChild | React.ReactChild[];
}

const Provider: React.FC<Props> = ({ children }) => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={AutochekTheme}>
      <ThemeProvider theme={AutochekTheme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default Provider;
