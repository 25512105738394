import { IRootState } from '../reducer.interface'
import { TCategory } from './actions'

export const getNewMessageAlert = (state: IRootState) => {
  return state.conversations.newMessageAlert
}

export const getMessageStats = (state: IRootState) => {
  return state.conversations.stats
}

export const getAgentConversations = (
  state: IRootState,
  category: TCategory
) => {
  return state.conversations[category].data
}

export const getRecipientMessages = (
  state: IRootState,
  recipientPhone: string
) => {
  return state.conversations.conversationMessages[recipientPhone]?.data
}

export const getRecipientConvoMeta = (
  state: IRootState,
  recipientPhone: string
) => {
  return state.conversations.conversationMessages[recipientPhone]?.metaData
}

export const getConvoReadStatus = (
  state: IRootState,
  recipientPhone: string
) => {
  return state.conversations.conversationMessages[recipientPhone]?.metaData
    ?.read
}

export const getConvoOpenStatus = (state: IRootState, recipientPhone: string) =>
  state.conversations.conversationMessages[recipientPhone]?.metaData?.status

export const getConnectionStatus = (state: IRootState) =>
  state.conversations.webSocketConnected

export const getAssignedAgentData = (
  state: IRootState,
  recipientPhone: string
) =>
  state.conversations.conversationMessages[recipientPhone]?.metaData?.agentData

export const getRecipientName = (state: IRootState, recipientPhone: string) =>
  state.conversations.conversationMessages[recipientPhone]?.metaData?.contact

export const getRecipientLeadId = (state: IRootState, recipientPhone: string) =>
  state.conversations.conversationMessages[recipientPhone]?.metaData?.leadId

export const getUnreadConvoCount = (state: IRootState) =>
  state.conversations.stats.unread

export const getSearchResults = (state: IRootState) =>
  state.conversations.search.data

export const getMode = (state: IRootState) => state.conversations.mode

export const getPaginationData = (state: IRootState, recipient: string) =>
  state.conversations.conversationMessages[recipient]?.pagination

export const getConvoPaginationData = (
  state: IRootState,
  category: TCategory
) => state.conversations[category].pagination

export const getSearchPaginationData = (state: IRootState) =>
  state.conversations.search.pagination

export const getWhatsAppSearchQuery = (state: IRootState) =>
  state.conversations.search.query
