import React, { useCallback, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  getUserToken
} from '../../../store/auth/selectors'
import {
  getLoanLeads,
  getLoansPagination
} from '../../../store/loans/selectors'
import { IagentUsers } from '../../../store/users/types'
import { isFetching } from '../../../store/isFetching/selectors'
import { IRootState } from '../../../store/reducer.interface'
import WithSideMenu from '../../../components/WithSideMenu'
import EmailIcon from '../../Leads/svg/Email'
import LastSeenIcon from '../../Leads/svg/LastSeenIcon'
import PersonIcon from '../../Leads/svg/PersonIcon'
import PhoneIcon from '../../Leads/svg/PhoneIcon'
import ServiceIcon from '../../Leads/svg/ServiceIcon'
import StatusIcon from '../../Leads/svg/StatusIcon'
import ValueIcon from '../../Leads/svg/ValueIcon'
import Checkbox from '../../../components/Checkbox'
import Loader from '../../../components/Loader'
import Popover from '@material-ui/core/Popover'
import SettingsMenu from '../SettingsMenu'
import { useTranslation } from 'react-i18next'
import Person from '../../../images/person_profile.svg'
import { NewLeadBtn } from '../../Activities/styles'
import { usePhoneStyles } from '../../Leads/LeadDetails/LeadActions/styles'
import { makeStyles } from '@material-ui/core'
import { Popconfirm } from 'antd'

import {
  LeadsContainer,
  LeadsMiddleSection,
  LeadsMiddleSectionText,
  LeadsPageTitile,
  LeadsTopSection,
  LeadsMiddleSectionButtons,
  SearchContainer,
  SearchContentInactive,
  SearchIcon,
  Divider,
  StartAssignBtn,
  StopAssignBtn,
  Table,
  TableHeader,
  TableRow,
  TableCell,
  HeaderIcon,
  HeaderText,
  HeaderIconText,
  CheckBoxWrap,
  ButtonsContainer,
  CreateLeadBtn,
  SettingsContainer,
  ContentWrap,
  SettingsNavDummy,
  BulkAssignBtn,
  FiltersBtn,
  TableOptionsIcon,
  TableOption,
  NewBulkAssignBtn,
  NewStartAssignBtn,
  NewStopAssignBtn,
  DeleteAgent
} from './styles'
import dayjs from 'dayjs'
import {
  fetchLoanLeads,
  fetchFilterLoanLeads
} from '../../../store/loans/actions'
import { showSnackbar } from '../../../store/snackbar/actions'
import api from '../../../utils/api'
import CreateLead from '../../Leads/CreateLead'
import { getCountryCode } from '../../../utils/country.util'

const tableHeader = [
  {
    icon: <PersonIcon />,
    text: 'NAME',
    longCell: true
  },
  {
    icon: <EmailIcon />,
    text: 'EMAIL',
    longCell: true,
    shortCell: false
  },
  {
    icon: <PhoneIcon />,
    text: 'PHONE',
    longCell: false,
    shortCell: false
  },

  {
    icon: <ServiceIcon />,
    text: 'SERVICE',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'STATUS',
    longCell: false,
    shortCell: true
  },
  {
    icon: <StatusIcon />,
    text: 'ACTION',
    longCell: false,
    shortCell: true
  },
  {
    icon: '',
    text: '',
    longCell: false,
    shortCell: true
  }
]

const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

export const TableHeaderContainer = () => {
  const { t } = useTranslation()

  return (
    <TableHeader>
      {/* <CheckBoxWrap>
        <Checkbox checked={false} handleChange={() => {}} />
      </CheckBoxWrap> */}
      {tableHeader.map((header, index) => {
        return (
          <HeaderIconText
            long={header.longCell}
            short={header.shortCell}
            key={index + header.text}
          >
            <HeaderIcon>{header.icon}</HeaderIcon>
            <HeaderText>{t(`${header.text}`)}</HeaderText>
          </HeaderIconText>
        )
      })}
    </TableHeader>
  )
}

interface ITableRowContainerProps {
  user: IagentUsers
}

export const TableRowContainer = (props: any) => {
  const token = useSelector(getUserToken)
  const country = useSelector(getSelectedCountry)
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const handleDelete = async () => {
    let users = { ...props.user }
    try {
      const response = await api.deleteAgent(token, users.id)
      if (response) {
        dispatch(showSnackbar('Agent deleted successfully', 'success'))
        props.getAllAgents(country)
      }
    } catch (err) {
      dispatch(showSnackbar('Error deleting agent user', 'error'))
    }
  }

  const ModifyRole = (props: any) => {
    return (
      <div>
        {props.role === 'BUY_CAR' ? <span>{t('buyCar')}</span> : null}
        {props.role === 'SELL_CAR' ? <span>{t('sellCar')}</span> : null}
        {props.role === 'TEST_DRIVE_CAR' ? (
          <span>{t('testDriveCar')}</span>
        ) : null}
      </div>
    )
  }

  const modifyAssignment = useCallback(
    async (id: string, status: boolean) => {
      let query = {
        active: status
      }
      try {
        const response = await api.registerAgent(token, query, id)
        props.getAllAgents(country)

        status
          ? dispatch(showSnackbar('Agent assigned successfully', 'success'))
          : dispatch(showSnackbar('Agent unassigned successfully', 'success'))
        // console.log(response)
      } catch (e) {
        dispatch(showSnackbar('unable to carry out action', 'error'))
      }
    },
    [token]
  )

  const gotoAgentView = () => {
    window.location.href = `/settings/agents/edit`
    localStorage.setItem('agent', JSON.stringify(props.user))
  }

  const {
    id,

    firstname,
    lastname,
    email,
    serviceAreas,
    primaryPhone,
    active,
    createdAt,
    updatedAt
  } = props.user

  return (
    <TableRow>
      {/* <CheckBoxWrap>
          <Checkbox checked={false} handleChange={() => {}} />
        </CheckBoxWrap> */}
      <TableCell
        long
        capitalize
        style={{ cursor: 'pointer' }}
        onClick={() => gotoAgentView()}
      >
        {firstname} {lastname}
      </TableCell>
      <TableCell
        style={{ cursor: 'pointer' }}
        long
        onClick={() => gotoAgentView()}
      >
        {email?.toLowerCase()}
      </TableCell>
      <TableCell style={{ cursor: 'pointer' }} onClick={() => gotoAgentView()}>
        {primaryPhone ? primaryPhone : 'N/A'}
      </TableCell>

      <TableCell
        style={{ cursor: 'pointer' }}
        capitalize
        short
        onClick={() => gotoAgentView()}
      >
        {serviceAreas[0]?.split('_').join(' ')}
        {serviceAreas[0] ? null : 'N/A'}
        {/* {serviceAreas.filter((service:string) => service === 'AFTER_SALES')? 'After Sales': null} */}
      </TableCell>

      <TableCell
        style={{ cursor: 'pointer' }}
        capitalize
        short
        onClick={() => gotoAgentView()}
      >
        {active ? (
          <span style={{ color: '#00B612' }}>{t('Active')}</span>
        ) : (
          t('Inactive')
        )}
      </TableCell>
      <TableCell capitalize short>
        {active ? (
          <>
            {/* <StopAssignBtn onClick={() => modifyAssignment(id, false)} /> */}
            <NewStopAssignBtn onClick={() => modifyAssignment(id, false)}>
              {t('Stop Assign')}
            </NewStopAssignBtn>
          </>
        ) : (
          <>
            {/* <StartAssignBtn onClick={() => modifyAssignment(id, true)} /> */}
            <NewStartAssignBtn onClick={() => modifyAssignment(id, true)}>
              {t('Start Assign')}
            </NewStartAssignBtn>
          </>
        )}
      </TableCell>
      <TableCell capitalize short>
        <Popconfirm
          placement="topLeft"
          title={t('areYouSureWantDeleteAgent?')}
          onConfirm={handleDelete}
          okText={t('Yes')}
          cancelText={t('No')}
        >
          <DeleteAgent>{t('delete')}</DeleteAgent>
        </Popconfirm>
      </TableCell>
    </TableRow>
  )
}

const initialLead = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  service: '',
  status: '',
  value: '',
  lead_type: '',
  country: '',
  country_code: '',
  source: ''
}

const Users = () => {
  const dispatch = useDispatch()
  const [openModal, setOpenModal] = useState(false)
  const [paginationCount, setPaginationCount] = useState(0)
  const history = useHistory()
  const token = useSelector(getUserToken)
  const loans = useSelector(getLoanLeads)
  const pagination = useSelector(getLoansPagination)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const [newLead, setNewLead] = useState({ ...initialLead, country })
  const [agents, setAgents] = useState([])
  const [loader, startLoader] = useState(false)
  const isLoading = useSelector((state: IRootState) =>
    isFetching(state, 'loans')
  )

  const numOfPages = Math.ceil(pagination.total / pagination.pageSize)

  const getAllAgents = useCallback(
    async (country) => {
      startLoader(true)
      try {
        const response = await api.fetchAgents(token, country)

        // console.log(response)

        setAgents(response.agents)
        setPaginationCount(response.agents.length)
        startLoader(false)
      } catch (e) {
        startLoader(false)
      }
    },
    [token]
  )

  useEffect(() => {
    dispatch(fetchLoanLeads())

    getAllAgents(country)
  }, [country])

  const { t } = useTranslation()

  return (
    <WithSideMenu>
      <SettingsContainer>
        <SettingsNavDummy />
        <SettingsMenu />
        <ContentWrap>
          <LeadsContainer>
            <LeadsTopSection>
              <LeadsPageTitile>{t('Agents')}</LeadsPageTitile>

              <SearchContainer>
                <SearchIcon />
                <SearchContentInactive>
                  {t('searchUsers')}
                </SearchContentInactive>
              </SearchContainer>

              <Link to="/settings/agents/bulk">
                {/* <BulkAssignBtn /> */}
                <NewBulkAssignBtn>
                  <img src={Person} width="13px" alt="person" />
                  &nbsp;&nbsp;{t('Bulk Assign')}
                </NewBulkAssignBtn>
              </Link>

              <Link to="/settings/agents/create">
                <NewLeadBtn>{t('createAgent')}</NewLeadBtn>
              </Link>
              {/* <Link to="/settings/agents/create">
                <CreateLeadBtn />
              </Link> */}
            </LeadsTopSection>
            <Divider />
            <LeadsMiddleSection>
              <LeadsMiddleSectionText>
                {paginationCount} {t('items')}
              </LeadsMiddleSectionText>
              <LeadsMiddleSectionButtons>
                <TableOption>
                  <TableOptionsIcon />
                  {t('tableView')}
                </TableOption>

                <TableOption>
                  <TableOptionsIcon />
                  {t('editTable')}
                </TableOption>
              </LeadsMiddleSectionButtons>
            </LeadsMiddleSection>
            {loader ? (
              <Loader />
            ) : (
              <>
                <Table>
                  <TableHeaderContainer />
                  {agents?.map((user) => (
                    <TableRowContainer
                      getAllAgents={getAllAgents}
                      user={user}
                    />
                  ))}
                </Table>
              </>
            )}
            {/* <PaginationContainer>
          <Pagination
            page={pagination.currentPage}
            count={numOfPages}
            onChange={handlePageChange}
            color="secondary"
          />
        </PaginationContainer> */}
          </LeadsContainer>
        </ContentWrap>
      </SettingsContainer>
    </WithSideMenu>
  )
}

export default Users
