export enum ECarTypes {
  GET_CAR_MAKE = 'GET_CARE_MAKE',
  GET_CAR_MAKE_SUCCESS = 'GET_CAR_MAKE_SUCCESS',
  GET_CAR_MODEL = 'GET_CARE_MODEL',
  GET_CAR_MODEL_SUCCESS = 'GET_CAR_MODEL_SUCCESS',
  GET_CAR_BODY = 'GET_CAR_BODY',
  GET_CAR_BODY_SUCCESS = 'GET_CAR_BODY_SUCCESS'
}

export const getCarMake = () => ({
  type: ECarTypes.GET_CAR_MAKE,
})

export const getCarModel = (makeId: string|number) => ({
  type: ECarTypes.GET_CAR_MODEL,
  makeId
})

export const getCarBody = () => ({
  type: ECarTypes.GET_CAR_BODY,
})

