import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import { getShowSellCarModal } from '../../../../store/modal/selectors'
import { hideSellCarModal } from '../../../../store/modal/actions'
import {
  DetailItemTitle,
  DetailItemValue,
  DetailItemPhoneIcon,
  DetailItemMapIcon,
  DetailItem
} from '../styles'
import {
  ModalHeader,
  ModalTitle,
  CancelIcon,
  ModalBody,
  IconMenuVertical,
  Flex,
  ModalVehicleAssignedTo,
  ModalVehicleAssignedToText,
  CarGearIcon,
  GasPumpIcon,
  FlagIcon,
  ModalVehicleNameAmount,
  PersonIconThree,
  FullVehicleDetailsItemTitle,
  FullVehicleDetailsItemValue,
  FullVehicleDetailsTitle,
  Body,
  StyledModal,
  CarDealerOwnerInfo,
  CarInfoLeft,
  CarInfoTitle,
  CarRetroIcon,
  DetailItemGaugeIcon,
  ModalFooter,
  ModalCloseButton,
  DetailItemTimeIcon
} from './styles'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 853,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    borderRadius: '4px',
    padding: 0,
    top: 10,
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 0,
    overflowY: 'scroll',
    overflowX: 'hidden'
  }
}))

type Details = {
  carId: string
  country: string
  details: any
  type: string
}

const SellCarDetails = ({ country, carId, details, type }: Details) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const open = useSelector(getShowSellCarModal)
  const dispatch = useDispatch()
  let carDetails = {
    name: '',
    mileage: '',
    imported: false,
    fuelType: '',
    gearType: '',
    time: '',
    isDropOff: false,
    location: { name: '', phone: '', displayAddress: '', city: '' },
    pickUpLocation: { street: '', city: '' }
  }

  if (details.length > 0) {
    carDetails = details.find((car: any) => car.id === carId)
  }

  const hideModal = () => {
    dispatch(hideSellCarModal())
  }

  const body = (
    <div className={classes.paper}>
      <ModalHeader>
        <ModalTitle>
          {type === 'sell' ? t('SELL') : t('FIX')} {t('CAR DETAIL')}
        </ModalTitle>
        <CancelIcon onClick={hideModal} />
      </ModalHeader>
      <ModalBody>
        <Flex>
          <ModalVehicleNameAmount>
            {carDetails && carDetails.name}
          </ModalVehicleNameAmount>
          <IconMenuVertical />
        </Flex>
        <ModalVehicleNameAmount>
          {/* {currency} {price && price.toLocaleString()} */}
        </ModalVehicleNameAmount>
        <Flex>
          <ModalVehicleAssignedTo>
            <PersonIconThree />
            <ModalVehicleAssignedToText>
              {t('assignedTo')}:
            </ModalVehicleAssignedToText>
          </ModalVehicleAssignedTo>
        </Flex>
        <FullVehicleDetailsTitle>{t('vehicleDetails')}</FullVehicleDetailsTitle>
        <Flex marginBottom="24px" noJustify>
          <Flex noJustify marginRight="24px">
            <FlagIcon />
            <FullVehicleDetailsItemTitle>
              {t('Utility')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {carDetails && carDetails.imported === true
                ? t('Foreign Used')
                : t('Locally Used')}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify marginRight="24px">
            <GasPumpIcon />
            <FullVehicleDetailsItemTitle>
              {t('fuelType')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {carDetails && carDetails.fuelType}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify marginRight="24px">
            <CarGearIcon />
            <FullVehicleDetailsItemTitle>
              {t('gearType')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {carDetails && carDetails.gearType}
            </FullVehicleDetailsItemValue>
          </Flex>
          <Flex noJustify marginRight="24px">
            <DetailItemGaugeIcon />
            <FullVehicleDetailsItemTitle>
              {t('mileage')}
            </FullVehicleDetailsItemTitle>
            <FullVehicleDetailsItemValue>
              {carDetails && carDetails.mileage}
            </FullVehicleDetailsItemValue>
          </Flex>
        </Flex>
      </ModalBody>
      <CarDealerOwnerInfo>
        {carDetails && carDetails.isDropOff ? (
          <CarInfoLeft>
            <CarInfoTitle>{t('inspectionCenter')}</CarInfoTitle>
            <DetailItem isOwnerInfo>
              <CarRetroIcon />
              <DetailItemTitle>{t('centerName')}</DetailItemTitle>
              <DetailItemValue>
                {carDetails && carDetails.isDropOff
                  ? carDetails.location.name
                  : ''}
              </DetailItemValue>
            </DetailItem>
            <DetailItem isOwnerInfo>
              <DetailItemPhoneIcon />
              <DetailItemTitle>{t('phone')}</DetailItemTitle>
              <DetailItemValue>
                {carDetails && carDetails.isDropOff
                  ? carDetails.location.phone
                  : ''}
              </DetailItemValue>
            </DetailItem>
            <DetailItem isOwnerInfo>
              <DetailItemMapIcon />
              <DetailItemTitle>{t('location')}</DetailItemTitle>
              <DetailItemValue>
                {carDetails &&
                  carDetails.location &&
                  `${carDetails.location.displayAddress}, ${carDetails.location.city}`}
                {carDetails &&
                  carDetails.pickUpLocation &&
                  `${carDetails.pickUpLocation.street}, ${carDetails.pickUpLocation.city}`}
              </DetailItemValue>
            </DetailItem>
            <DetailItem isOwnerInfo>
              <DetailItemTimeIcon />
              <DetailItemTitle>{t('Time')}</DetailItemTitle>
              <DetailItemValue>{carDetails && carDetails.time}</DetailItemValue>
            </DetailItem>
          </CarInfoLeft>
        ) : (
          <CarInfoLeft>
            <CarInfoTitle>{t('pickupLocation')} </CarInfoTitle>
            <DetailItem isOwnerInfo>
              <DetailItemMapIcon />
              <DetailItemTitle>{t('location')}</DetailItemTitle>
              <DetailItemValue>
                {carDetails &&
                  carDetails.location &&
                  `${carDetails.location.displayAddress}, ${carDetails.location.city}`}
                {carDetails &&
                  carDetails.pickUpLocation &&
                  `${carDetails.pickUpLocation.street}, ${carDetails.pickUpLocation.city}`}
              </DetailItemValue>
            </DetailItem>
            <DetailItem isOwnerInfo>
              <DetailItemTimeIcon />
              <DetailItemTitle>{t('Time')}</DetailItemTitle>
              <DetailItemValue>{carDetails && carDetails.time}</DetailItemValue>
            </DetailItem>
          </CarInfoLeft>
        )}
      </CarDealerOwnerInfo>
      <Body></Body>

      <ModalFooter>
        <ModalCloseButton onClick={hideModal}>{t('Close')}</ModalCloseButton>
      </ModalFooter>
    </div>
  )

  return (
    <div>
      <StyledModal
        open={open}
        onClose={hideModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </StyledModal>
    </div>
  )
}

export default SellCarDetails
