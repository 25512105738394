import { ELoansActionTypes } from './actions'
import { IPagination, ILoansState, TLoansStateAction } from './types'

const initPagination: IPagination = {
  total: 0,
  currentPage: 1,
  pageSize: 10
}

const initialLoansState: ILoansState = {
  leads: [],
  pagination: initPagination
}

const loanReducer = (
  state: ILoansState = initialLoansState,
  action: TLoansStateAction
): ILoansState => {
  switch (action.type) {
    case ELoansActionTypes.FETCH_LOAN_LEADS_SUCCESS:
      return action.payload
    default:
      return state
  }
}

export default loanReducer
