import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import WithSideMenu from '../../components/WithSideMenu'
import SettingsMenu from './SettingsMenu'

import General from './General'
import { SettingsContainer, ContentWrap, SettingsNavDummy } from './styles'
import {
  Link,
  useLocation
} from 'react-router-dom'
import Users from './Users'
import BulkUsers from './Users/BulkAssign'

const Settings = () => {
  const location = useLocation()
  const isSettings = location.pathname.includes('settings')
  const isAgents = location.pathname.includes('agents')
  const isBulk = location.pathname.includes('bulk')

  return (
    <WithSideMenu>
      <SettingsContainer>
        <SettingsNavDummy />
        <SettingsMenu />
        <ContentWrap>
       
            <div>
            <General />
            </div>
          
        </ContentWrap>
      </SettingsContainer>
    </WithSideMenu>
  )
}

export default Settings
