import React, { useState, useEffect } from 'react'
import { StyledDetailNav } from './styles'
import { Select, Skeleton, Button, Form } from 'antd'
import { ChevronDIcon } from '../../views/PortfolioManagement/PortfolioDetails/svgs'
import api from '../../utils/api'
import {
  getUserToken,
  getUserId,
  getSelectedCountry,
  getSelectedCountryCurrency,
  isAgent,
  isPortfolio,
  isLead,
  isAdmin,
  isManager
} from '../../store/auth/selectors'
import { useSelector, useDispatch } from 'react-redux'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { CAR_STATUS } from '../../views/LeadManagement/AvailableCar/constants'
import { showSnackbar } from '../../store/snackbar/actions'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function CarDetailNav() {
  const [form] = Form.useForm()
  const { Option } = Select
  const location = useLocation()
  const { id } = useParams<IDetailParam>()
  const dispatch = useDispatch()
  const token = useSelector(getUserToken)
  const currency = useSelector(getSelectedCountryCurrency)
  const country = useSelector(getSelectedCountry)
  const [getSingleLoan, setGetSingleLoans] = useState<Array<object>>([])
  const queryClient = useQueryClient()
  const userIsAdmin = useSelector(isAdmin)
  const userIsManger = useSelector(isManager)
  const userIsPortfolio = useSelector(isPortfolio)
  const userIsLead = useSelector(isLead)
  const userId = useSelector(getUserId)
  const { t } = useTranslation()

  // console.log(userId)

  const { data: singleLoan, status, refetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    { keepPreviousData: true, staleTime: 5000 }
  )

  const { data: fetchAgents } = useQuery(['fetchAgent', country], () =>
    api.fetchOriginAgents(token, {
      country: country,
      role_name: 'LEAD_MANAGER'
    })
  )

  const onFinish = async (values: any) => {
    const _data = { ...values, loan_id: id }

    try {
      const response = await api.assignLeadManager(token, values.user_id, _data)
      if (response) {
        dispatch(showSnackbar('Assigned successfully', 'success'))
      }
    } catch (err) {
      dispatch(showSnackbar('Error assigning lead manager', 'error'))
    }
  }

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
      setGetSingleLoans(singleLoan)
    }
  }, [singleLoan, queryClient, id])

  function handleChange(value: any) {
    // console.log(`selected ${value}`)
  }

  function capitalFirstLetter(str: any) {
    const arr = str?.split(' ')
    for (var i = 0; i < arr?.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr?.join(' ')
  }

  let linkToConsole =
    process.env.REACT_APP_API_URL === `https://api.staging.myautochek.com`
      ? 'https://console.staging.myautochek.com/loan'
      : 'https://console.autochek.africa/loan'

  //https://console.staging.myautochek.com/loan?search=bm@mail.com

  return (
    <StyledDetailNav>
      <div className="userDetailWrapper">
        {status == 'loading' ? (
          <Skeleton />
        ) : (
          <>
            <div className="userName">
              <div className="name">
                {singleLoan?.firstName} {singleLoan?.lastName}
              </div>
              <div className="email">{singleLoan?.email || 'N/A'}</div>
              <div className="tel">{singleLoan?.phone || 'N/A'}</div>
            </div>
            <div className="loan">
              <div className="name">{t('loanValue')}</div>
              <div className="value">
                {currency}
                {singleLoan?.loanValue?.toLocaleString() || 'N/A'}
              </div>
            </div>
            <div className="carDetails d-flex">
              <div className="carImage">
                <img
                  src={singleLoan?.car?.imageUrl}
                  className="img-fluid"
                  alt="car-image"
                />
              </div>
              <div className="carDescription d-grid gap-1">
                <div className="name">
                  {singleLoan?.car?.model?.make.name || 'N/A'}{' '}
                  {singleLoan?.car?.model?.name || 'N/A'}{' '}
                  {singleLoan?.car?.year || 'N/A'}
                </div>
                <div className="value">
                  {currency}
                  {singleLoan?.car?.marketplacePrice?.toLocaleString() || 'N/A'}
                </div>
                <a
                  href={`${linkToConsole}?search=${singleLoan?.email}`}
                  target="_blank"
                >
                  {t('viewConsole')}
                </a>
              </div>
            </div>
            <div className="currentStep">
              <div className="name">{t('currentStep')}</div>
              <div className="number">
                {singleLoan?.status === CAR_STATUS.APPLICATION_COMPLETED &&
                  '01'}
                {singleLoan?.status === CAR_STATUS.OFFERS && '01'}
                {singleLoan?.status === CAR_STATUS.DECLINED && '00'}
                {singleLoan?.status === CAR_STATUS.OFFER_ACCEPTED && '01'}
                {singleLoan?.status === CAR_STATUS.DOCUMENT_ACCEPTED && '02'}
                {singleLoan?.status === CAR_STATUS.DOCUMENT_REJECTED && '02'}
                {singleLoan?.status === CAR_STATUS.DOCUMENT_REQUESTED && '02'}
                {singleLoan?.status === CAR_STATUS.CAR_SOURCING && '03'}
                {singleLoan?.status === CAR_STATUS.CAR_ACCEPTED && '03'}
                {location.pathname == `/portfolio-loans/${id}` ? (
                  <>
                    {singleLoan?.status === CAR_STATUS.PPI_IN_PROGRESS && '03'}
                  </>
                ) : (
                  <>
                    {singleLoan?.status === CAR_STATUS.PPI_IN_PROGRESS && '04'}
                  </>
                )}
                {location.pathname == `/portfolio-loans/${id}` ? (
                  <>{singleLoan?.status === CAR_STATUS.PPI_PASS && '03'}</>
                ) : (
                  <>{singleLoan?.status === CAR_STATUS.PPI_PASS && '04'}</>
                )}
                {location.pathname == `/portfolio-loans/${id}` ? (
                  <>{singleLoan?.status === CAR_STATUS.PPI_FAILS && '03'}</>
                ) : (
                  <>{singleLoan?.status === CAR_STATUS.PPI_FAILS && '04'}</>
                )}
                {singleLoan?.status === CAR_STATUS.CUSTOMER_INSPECTION && '05'}
                {location.pathname == `/portfolio-loans/${id}` ? (
                  <>
                    {singleLoan?.status === CAR_STATUS.EQUITY_REQUESTED && '04'}
                  </>
                ) : (
                  <>
                    {singleLoan?.status === CAR_STATUS.EQUITY_REQUESTED && '06'}
                  </>
                )}
                {location.pathname == `/portfolio-loans/${id}` ? (
                  <>{singleLoan?.status === CAR_STATUS.EQUITY_PAID && '05'}</>
                ) : (
                  <>{singleLoan?.status === CAR_STATUS.EQUITY_PAID && '06'}</>
                )}
                {location.pathname == `/portfolio-loans/${id}` ? (
                  <>{singleLoan?.status === CAR_STATUS.DISBURSED && '06'}</>
                ) : (
                  <>{singleLoan?.status === CAR_STATUS.DISBURSED && '07'}</>
                )}
              </div>
            </div>
            <div className="stage">
              <div className="name">{t('stage')}</div>
              <div className="step mb-2">
                {singleLoan?.status === CAR_STATUS.APPLICATION_COMPLETED &&
                  t('applicationCompleted')}
                {singleLoan?.status === CAR_STATUS.OFFERS && t('offers')}
                {singleLoan?.status === CAR_STATUS.OFFER_ACCEPTED &&
                  t('offersAccepted')}
                {singleLoan?.status === CAR_STATUS.DECLINED && t('declined')}
                {singleLoan?.status === CAR_STATUS.DOCUMENT_REQUESTED &&
                  t('documentRequested')}
                {singleLoan?.status === CAR_STATUS.CAR_SOURCING &&
                  t('carSourcing')}
                {singleLoan?.status === CAR_STATUS.DOCUMENT_ACCEPTED &&
                  t('documentReview')}
                {singleLoan?.status === CAR_STATUS.DOCUMENT_REJECTED &&
                  t('documentReview')}
                {singleLoan?.status === CAR_STATUS.DISBURSED &&
                  t('loanDisbursed')}
                {singleLoan?.status === CAR_STATUS.CUSTOMER_INSPECTION &&
                  t('customerInspection')}
                {singleLoan?.status === CAR_STATUS.EQUITY_PAID &&
                  t('equityPaid')}
                {singleLoan?.status === CAR_STATUS.EQUITY_REQUESTED &&
                  t('equityRequested')}
                {singleLoan?.status === CAR_STATUS.PPI_IN_PROGRESS &&
                  t('schedulePPI')}
                {singleLoan?.status === CAR_STATUS.CAR_ACCEPTED &&
                  t('carAvailability')}
                {singleLoan?.status === CAR_STATUS.PPI_PASS && t('PPIPass')}
                {singleLoan?.status === CAR_STATUS.PPI_FAILS && t('PPIFails')}
              </div>
              <div className="agent d-flex align-items-center">
                {userIsAdmin || userIsManger ? (
                  <>
                    <Form
                      form={form}
                      onFinish={onFinish}
                      layout="horizontal"
                      className="d-flex"
                    >
                      <Form.Item name="user_id" label={t('LeadManager')}>
                        <Select
                          placeholder={singleLoan?.leadManager?.name || 'N/A'}
                          style={{
                            minWidth: '150px',
                            width: 'auto'
                          }}
                          onChange={handleChange}
                          suffixIcon={<div>{ChevronDIcon}</div>}
                          bordered={false}
                        >
                          {fetchAgents?.users?.map((el: any, index: any) => (
                            <Option key={index} value={el?.id}>
                              {el?.firstname} {el?.lastname}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item>
                        <Button className="assignLeadManager" htmlType="submit">
                          {t('Assign')}
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                ) : (
                  ''
                )}
                {userIsPortfolio || userIsLead ? (
                  <>
                    {t('Agent')}:
                    <span className="ms-2">
                      {capitalFirstLetter(singleLoan?.leadManager?.name) ||
                        'N/A'}
                    </span>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </StyledDetailNav>
  )
}
