import { Dayjs } from 'dayjs'

export enum ENotificationTypes {
  ADD_NEW_MESSAGE_NOTIFICATION = 'ADD_NEW_MESSAGE_NOTIFICATION',
  SET_OLDER_NOTIFICATIONS = 'SET_OLDER_NOTIFICATIONS'
}

export interface INewMessage {
  agentId: string
  phone: string
  message?: {
    text: string
    type: 'TEXT' | 'IMAGE' | 'AUDIO' | 'VIDEO' | 'DOCUMENT'
  }
  time?: Dayjs
  status: 'new message'
  new?: boolean
}

export const newMessageNotification = (newMessage: INewMessage) => ({
  type: ENotificationTypes.ADD_NEW_MESSAGE_NOTIFICATION,
  payload: newMessage
})

export const setOlderNotifications = () => ({
  type: ENotificationTypes.SET_OLDER_NOTIFICATIONS
})
