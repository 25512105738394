import React, { useState, useEffect } from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import { StyledDetails } from './styles'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ChevronRIcon, HistoryIcon, InboxIcon, PhoneIcon } from './svgs'
import ProgressBar from 'react-bootstrap/ProgressBar'
import Documents from './Documents'
import Offers from './Offers'
import DisburseLoans from './DisburseLoans'
import TopNavigation from '../../../components/TopNav'
import CarDetailNav from '../../../components/DetailsNav'
import { Tab, Tabs } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import api from '../../../utils/api'
import { getUserToken } from '../../../store/auth/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { CAR_STATUS } from '../../LeadManagement/AvailableCar/constants'
import { useTranslation } from 'react-i18next'

interface IDetailParam {
  id: string
}

export default function PortfolioDetails() {
  let history = useHistory()
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const [key, setKey] = useState('documents')
  const queryClient = useQueryClient()
  const [step, setStep] = useState(0)
  const { t } = useTranslation()

  const { data: fetchOffer } = useQuery(
    'fetchOffer',
    () => api.fetchOffers(token, id),
    {
      onSuccess: () => {}
    }
  )

  const { data: fetchDocument } = useQuery(
    'document',
    () => api.fetchDocuments(token, id),
    {
      onSuccess: () => {}
    }
  )

  const { data: singleLoan, status, refetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    { keepPreviousData: true, staleTime: 5000 }
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
    }
  }, [singleLoan, queryClient, id])

  useEffect(() => {
    if (singleLoan?.status === CAR_STATUS.APPLICATION_COMPLETED) {
      setStep(16.6)
    }
    if (singleLoan?.status === CAR_STATUS.OFFERS) {
      setStep(16.6)
    }
    if (singleLoan?.status === CAR_STATUS.OFFER_ACCEPTED) {
      setStep(16.6)
    }
    if (singleLoan?.status === CAR_STATUS.DOCUMENT_ACCEPTED) {
      setStep(33.2)
    }
    if (singleLoan?.status === CAR_STATUS.DOCUMENT_REJECTED) {
      setStep(33.2)
    }
    if (singleLoan?.status === CAR_STATUS.DOCUMENT_REQUESTED) {
      setStep(33.2)
    }
    if (singleLoan?.status === CAR_STATUS.CAR_SOURCING) {
      setStep(49.8)
    }
    if (singleLoan?.status === CAR_STATUS.CAR_ACCEPTED) {
      setStep(49.8)
    }
    if (singleLoan?.status === CAR_STATUS.PPI_IN_PROGRESS) {
      setStep(49.8)
    }
    if (singleLoan?.status === CAR_STATUS.PPI_PASS) {
      setStep(49.8)
    }
    if (singleLoan?.status === CAR_STATUS.PPI_FAILS) {
      setStep(49.8)
    }
    if (singleLoan?.status === CAR_STATUS.CUSTOMER_INSPECTION) {
      setStep(49.8)
    }
    if (singleLoan?.status === CAR_STATUS.EQUITY_REQUESTED) {
      setStep(66.4)
    }
    if (singleLoan?.status === CAR_STATUS.EQUITY_PAID) {
      setStep(83)
    }
    if (singleLoan?.status === CAR_STATUS.DISBURSED) {
      setStep(100)
    }
  }, [step, setStep, singleLoan])

  return (
    <WithSideMenu>
      <StyledDetails>
        <TopNavigation />

        <div className="statusWrapper">
          <div className="progressLabels">
            <div
              style={{
                background: '0 0',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('offersReceived')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('Document Review/Request')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('carSourcing')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('requestEquity')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                borderRight: '3px solid #fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('equityPaid')}
            </div>
            <div
              style={{
                background: '0 0',
                textAlign: 'center',
                color: '#fff',
                height: '25px'
              }}
              className="d-flex align-items-center justify-content-center"
            >
              {t('loanDisbursed')}
            </div>
          </div>
          <ProgressBar className="progressBarActive" now={step} />
        </div>

        <CarDetailNav />

        <div className="tabWrapper">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k: any) => setKey(k)}
            className="mb-3"
            variant="pills"
          >
            <Tab eventKey="documents" title={t('Documents')}>
              <Documents
                title={
                  fetchDocument?.documents == undefined ||
                  fetchDocument?.documents?.length == 0
                    ? ''
                    : t('uploadedDocuments')
                }
              />
            </Tab>
            <Tab
              eventKey="offers"
              title={`${t('offers')} (${fetchOffer?.offers?.length || 0})`}
            >
              <Offers
                title={
                  fetchOffer?.offers == undefined ||
                  fetchOffer?.offers.length == 0
                    ? ''
                    : t('receivedOffers')
                }
              />
            </Tab>
            <Tab eventKey="disbursement" title={t('disbursement')}>
              <DisburseLoans
                title={
                  singleLoan?.offer == undefined ? '' : t('acceptedOffers')
                }
              />
            </Tab>
          </Tabs>
        </div>
      </StyledDetails>
    </WithSideMenu>
  )
}
