import React, { useState, useEffect } from 'react'
import { StyledAlternateCar } from './styles'
import { Modal, Button, Form, Input, Row, Col, message, Spin } from 'antd'
import { getUserToken, getSelectedCountry } from '../../../store/auth/selectors'
import api from '../../../utils/api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CAR_STATUS } from '../AvailableCar/constants'
import { useTranslation } from 'react-i18next'
import { getPartnerId } from '../../../utils/helpers'

interface IDetailParam {
  id: string
}

export default function SourceAlternateCar({
  visibleAlternateCar,
  setVisibleAlternateCar,
  title,
  singleLoan,
  loanRefresh
}: any) {
  const { id } = useParams<IDetailParam>()
  const [form] = Form.useForm()
  const token = useSelector(getUserToken)
  const [submitting, setSubmitting] = useState(false)
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const country = useSelector(getSelectedCountry)
  const partnerId = getPartnerId(process.env.REACT_APP_API_URL, country)

  const updateLoan: any = useMutation(
    (payload) => api.updateCarLoan(token, singleLoan?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loan-update')
        message.success('Loan updated')
        loanRefresh()
      },
      onError: (error: any) => {
        message.error(error?.response.data.error)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  const createCarSourcing: any = useMutation(
    (payload) => api.createCarSourcing(token, payload),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('car-sourcing')
        setSubmitting(false)
        message.success('Car sourcing  successfully')
        setVisibleAlternateCar(false)
        handleUpdate(res?.id)
      },
      onError: (error: any) => {
        setSubmitting(false)
        message.error(error?.response.data.error)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  const handleUpdate = async (source_id: any) => {
    const payload = {
      status: CAR_STATUS.CAR_SOURCING,
      car_id: singleLoan?.carId,
      source_id: source_id
    }
    await updateLoan.mutateAsync(payload)
  }

  // useEffect(() => {
  //   form.setFieldsValue({
  //     make: singleLoan?.car?.model?.make?.name,
  //     model: singleLoan?.car?.model?.name,
  //     year: singleLoan?.car?.year
  //   })
  // }, [singleLoan])

  const onFinish = async (values: any) => {
    setSubmitting(true)

    const payload = {
      min_price: values?.price,
      max_price: values?.price,
      min_year: singleLoan?.car?.year,
      max_year: values?.year,
      source_target: 1,
      model_id: singleLoan?.car?.model?.id,
      city: singleLoan?.car?.city,
      state: singleLoan?.car?.state,
      country: singleLoan?.car?.country,
      partner_id: partnerId, // fWYvOKJmBthis is autochek b2b partner id
      condition: values?.vehicleCondition
    }

    await createCarSourcing.mutateAsync(payload)
    form.resetFields()
  }

  const handleCancel = () => {
    setVisibleAlternateCar(false)
  }

  return (
    <Modal
      closable={false}
      visible={visibleAlternateCar}
      width="598px"
      footer={null}
    >
      <StyledAlternateCar>
        <div className="alternativeSourceWrapper">
          <div className="title mb-2">{title}</div>
          <div className="title">{t('sourceAlternativeCar')}</div>
          <div className="text mb-3">{t('fillDetailsSourceCar')}</div>
          <div className="header mb-2">{t('vehicleDetails')}</div>

          <Form
            form={form}
            layout="vertical"
            name="horizontal_login"
            requiredMark={false}
            onFinish={onFinish}
          >
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="make"
                  label={t('make')}
                  rules={[{ required: true, message: t('pleaseEnterCarMake') }]}
                >
                  <Input placeholder={t('enterCarMake')} />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="model"
                  label={t('model')}
                  rules={[
                    { required: true, message: t('pleaseEnterCarModel') }
                  ]}
                >
                  <Input placeholder={t('enterCarModel')} />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="year"
                  label={t('year')}
                  rules={[{ required: true, message: t('pleaseEnterCarYear') }]}
                >
                  <Input placeholder={t('enterCarYear')} type="number" />
                </Form.Item>
              </Col>
              <Col className="gutter-row" span={12}>
                <Form.Item
                  name="price"
                  label={t('price')}
                  rules={[
                    { required: true, message: t('pleaseEnterCarPrice') }
                  ]}
                >
                  <Input placeholder={t('enterCarPrice')} type="number" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              name="vehicleCondition"
              label={t('vehicleCondition')}
              rules={[
                { required: true, message: t('pleaseEnterVehicleCondition') }
              ]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>

            <Form.Item>
              <div className="d-flex justify-content-center">
                {submitting ? (
                  <Spin />
                ) : (
                  <>
                    <Button
                      className="cancel me-3"
                      type="primary"
                      onClick={handleCancel}
                    >
                      {t('Cancel')}
                    </Button>
                    <Button className="submit" type="primary" htmlType="submit">
                      {t('save')}
                    </Button>
                  </>
                )}
              </div>
            </Form.Item>
          </Form>
        </div>
      </StyledAlternateCar>
    </Modal>
  )
}
