import styled from 'styled-components'

export const StyledEquity = styled.div`
  .equityWrapper {
    h5 {
      color: #30355b;
      font-weight: bold;
      font-size: 14px;
    }
    .contentWrap {
      background: #f8f8fb;
      border-radius: 10px;
      min-height: 400px;
      height: auto;
      padding: 25px;
      .header {
        color: #30355b;
        font-weight: bold;
        font-size: 14px;
      }
      .offersUpload {
        .header {
          font-weight: bold;
          font-size: 14px;
          color: #000;
        }
        .offerDetails {
          display: grid;
          grid-template-columns: repeat(6, 1fr);
          grid-gap: 0px;
          padding: 25px;
          background: #ffffff;
          box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.05);
          border-radius: 8px;
          .head {
            color: #30355b;
            font-size: 14px;
          }
          .value {
            color: #30355b;
            font-size: 14px;
            font-weight: bolder;
          }
        }
        .equityRequested {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 15px;

          h5 {
            color: #30355b;
            font-weight: bold;
            font-size: 14px;
          }
          .equityDetails {
            div {
              font-size: 13px;
              color: #000000;
              font-weight: 400;
            }
            span {
              font-weight: 600;
              color: #30355b;
              font-size: 13px;
            }
            .pending {
              background: rgba(255, 182, 25, 0.28);
              border-radius: 45px;
              color: #ffb619;
              font-weight: 600;
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 35px;
            }
            .paid {
              background: #05a660;
              border-radius: 45px;
              color: #fff;
              font-weight: 600;
              font-size: 13px;
              display: flex;
              justify-content: center;
              align-items: center;
              height: 35px;
            }
          }
          .equityPaid {
            .ant-switch-checked {
              background-color: #fff;
            }
            .ant-switch {
              background-color: #fff;
              border: 1px solid #30355b;
            }
            .ant-switch-handle::before {
              background-color: #30355b;
            }
            .ant-switch-handle {
              top: 1px;
            }
            .uploadButton {
              button {
                background: #ffffff;
                border: 1px solid #c7c9d9;
                box-sizing: border-box;
                /* box-shadow: inset 0px 0.5px 4px rgba(96, 97, 112, 0.32); */
                border-radius: 4px;
                color: #30355b;
                font-weight: 500;
                font-size: 14px;
                min-width: 134px;
                width: auto;
                height: 35px;
              }
            }
          }
          .equityPayment {
            .pdfWrapper {
              background: #ffffff;
              box-shadow: 0px 2px 4px rgba(96, 97, 112, 0.05);
              border-radius: 8px;
              min-width: 217px;
              width: auto;
              height: 52px;
              display: flex;
              padding: 10px;
              .image {
                background: rgba(209, 55, 63, 0.1);
                border-radius: 2px;
                width: 23.46px;
                height: 23.46px;
                overflow: hidden;
              }
              .name {
                color: #30355b;
                font-weight: 500;
                font-size: 11px;
              }
              .size {
                color: #787878;
                font-weight: 500;
                font-size: 10px;
              }
            }
            .view {
              a {
                color: #000000;
                font-weight: 600;
                font-size: 12px;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
`

export const StyledEquityModal = styled.div`
  .equityHeader {
    color: #30355b;
    font-weight: 600;
    font-size: 16px;
  }

  form {
    label {
      color: #333333;
      font-weight: normal;
      font-size: 14px;
    }
    input {
      height: 44px;
    }
    .ant-input-number {
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
      height: 44px;
      font-size: 14px;
      width: 100%;
    }
    .buttonCancel {
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 150px;
      width: auto;
      height: 44px;
      color: #30355b !important;
      font-weight: 600;
      font-size: 14px;
    }
    .button {
      background: #ffb619 !important;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      min-width: 150px;
      width: auto;
      height: 44px;
      border: 0;
      color: #30355b !important;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .ant-upload.ant-upload-drag p.ant-upload-text {
    font-size: 14px;
    color: #000000;
    font-weight: 500;
  }
`
