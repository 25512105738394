const colors = {
  common: {
    white: '#FFFFFF',
    black: '#000000'
  },
  primary: {
    deepBlue: '#0A1133',
    lightBlue: '#4B9FF2'
  },
  secondary: {
    main: '#0F1A4F'
  },
  background: {
    paper: '#FFF',
    default: '#FFF'
  },
  neutral: {
    ghostWhite: '#F6F7FB',
    grayishBlue: '#474F58',
    lighGray: '#E5E5E5',
    whiteLilac: '#E7E5EB',
    cyanBluishGray: '#CAD0D9',
    cyanBluishWhite: '#F2F3F5',
    grayChateau: '#8F99A6',
    kournikova: '#FFCC5B',
    metallicBlue: '#404978',
    raven: '#747678',
    aliceBlue: '#E1F0FF',
    vividBlue: '#1D82FF',
    grayTint: '#ADADAD',
    black: '#262626',
    pattensBlue: '#D7E1EA',
    whisper: '#E9E9E9',
    gray93: '#EDEDED',
    manatee: '#828594',
    heather: '#AEB6BE',
    heather2: '#ABB2BC',
    someGray: '#FAFBFD',
    zircon: '#E6E7EA',
    lightSlateGrey: '#778699',
    regentGrey: '#757F8A',
    solitude: '#E6EAF0',
    badgeColor: '#6973A9',
  }
}

export default colors
