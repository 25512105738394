import React from 'react'
import {
  SettingsNav,
  SettingsTitle,
  SettingsMenuSectionTitle,
  SettingsMenuTitle,
  SettingsMenuSection,
  StyledPersonIcon,
  SettingsMenuItem
} from './styles'
import { Link, useLocation } from 'react-router-dom'
import BulkUsers from './Users/BulkAssign'
import { useSelector } from 'react-redux'

import { isAgent, isManager, isAdmin } from '../../store/auth/selectors'
import { useTranslation } from 'react-i18next'

const Account = () => {
  const location = useLocation()
  const isSettings = location.pathname.includes('settings')
  const isAgents = location.pathname.includes('agents')
  const isBulk = location.pathname.includes('bulk')
  const isWhatsApp = location.pathname.includes('whatsapp')
  const isPartnerMapping = location.pathname.includes('partner-mapping')
  const userIsManager = useSelector(isManager)
  const userIsAdmin = useSelector(isAdmin)
  const { t } = useTranslation()

  return (
    <SettingsNav>
      <SettingsTitle>{t('settings')}</SettingsTitle>
      <SettingsMenuSection>
        <SettingsMenuSectionTitle>
          <StyledPersonIcon />
          <SettingsMenuTitle>{t('GENERAL')}</SettingsMenuTitle>
        </SettingsMenuSectionTitle>
        <Link style={{ textDecoration: 'none' }} to="/settings">
          <SettingsMenuItem
            active={isSettings && !isAgents && !isBulk && !isPartnerMapping}
          >
            {t('accountPreference')}
          </SettingsMenuItem>
        </Link>

        {userIsAdmin || userIsManager ? (
          <Link to="/settings/agents" style={{ textDecoration: 'none' }}>
            <SettingsMenuItem active={(isAgents && !isWhatsApp) || isBulk}>
              {t('Agents')}
            </SettingsMenuItem>
          </Link>
        ) : null}
        {userIsAdmin || userIsManager ? (
          <Link
            to="/settings/agents/whatsapp"
            style={{ textDecoration: 'none' }}
          >
            <SettingsMenuItem active={isWhatsApp}>
              {t('whatsappAgents')}
            </SettingsMenuItem>
          </Link>
        ) : null}
        {userIsAdmin || userIsManager ? (
          <Link
            to="/settings/partner-mapping"
            style={{ textDecoration: 'none' }}
          >
            <SettingsMenuItem active={isPartnerMapping}>
              Partner Bank Mapping
            </SettingsMenuItem>
          </Link>
        ) : null}
      </SettingsMenuSection>
    </SettingsNav>
  )
}

export default Account
