import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import { makeStyles } from '@material-ui/core/styles'

type Props = {
  checked: boolean
  name?: string
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const useStyles = makeStyles({
  root: {
    borderRadius: 3,
    padding: 0,
    height: 16,
    width: 16,
    '& .MuiSvgIcon-root': { fontSize: '16px' }
  }
})

const SimpleCheckbox = ({ checked, handleChange, name}: Props) => {
  const classes = useStyles()

  return (
    <Checkbox
      checked={checked}
      onChange={handleChange}
      classes={{ root: classes.root }}
      name={name}
    />
  )
}

export default SimpleCheckbox
