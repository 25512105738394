import React from 'react'
import { StyledTextField, StyledLabel, Container } from './styles'

type Props = {
  label?: string
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  name?: string
  value?: string
  type?: string
  placeholder?: string
  height?: string
  color?: string
  width?: string
  borderRadius?: string
  transparent?: boolean
  noBgColor?: boolean
  borderColor?: string
  labelColor?: string
  labelMarginBottom?: string
  labelMarginTop?: string
  marginTop?: string
  min?: string
}

const TextInput = ({
  label,
  onInputChange,
  name,
  value,
  color,
  labelColor,
  labelMarginBottom,
  labelMarginTop,
  width,
  min,
  ...rest
}: Props) => {
  return (
    <Container width={width}>
      {label && (
        <StyledLabel
          labelColor={labelColor}
          labelMarginBottom={labelMarginBottom}
          labelMarginTop={labelMarginTop}
        >
          {label}
        </StyledLabel>
      )}
      <StyledTextField
        classes={{ input: 'input-field-focused' }}
        inputProps={{
          min
        }}
        onChange={onInputChange}
        name={name}
        value={value}
        {...rest}
      />
    </Container>
  )
}

export default TextInput
