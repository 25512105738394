import styled from 'styled-components'

export const StyledDetailNav = styled.div`
  .userDetailWrapper {
    margin: 0px 30px 20px 30px;
    padding: 30px;
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    /* grid-template-columns: repeat(5, 1fr); */
    grid-gap: 20px;
    .userName {
      color: #30355b;
      .name {
        font-weight: 600;
        font-size: 16px;
      }
      .email,
      .tel {
        font-size: 14px;
      }
    }
    .loan {
      .name {
        font-weight: 600;
        font-size: 14px;
        color: #8c8f99;
      }
      .value {
        font-weight: bolder;
        font-size: 16px;
        color: #30355b;
      }
    }
    .carDetails {
      .carImage {
        width: 138px;
        height: 88px;
        background: transparent;
        border-radius: 6px;
        overflow: hidden;
        margin-right: 15px;
      }
      .carDescription {
        color: #30355b;
        .name {
          font-size: 14px;
          font-weight: 500;
        }
        .value {
          font-size: 16px;
          font-weight: bolder;
        }
        a {
          color: #30355b;
          font-weight: 500;
          font-size: 12px;
          background: #ffffff;
          box-shadow: 0px 0px 1px rgba(40, 41, 61, 0.04),
            0px 2px 4px rgba(96, 97, 112, 0.16);
          border-radius: 30px;
          min-height: 25px;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          width: 140px;
        }
      }
    }
    .currentStep {
      .name {
        color: #8c8f99;
        font-weight: 500;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .number {
        font-weight: bolder;
        font-size: 16px;
        color: #30355b;
      }
    }
    .stage {
      .name {
        color: #8c8f99;
        font-weight: 500;
        font-size: 14px;
      }
      .step {
        color: #30355b;
        font-weight: 500;
        font-size: 14px;
      }
      .agent {
        color: #8c8f99;
        font-size: 14px;
        span {
          color: #30355b;
          font-weight: bolder;
        }
      }
    }

    .assignLeadManager {
      color: #30355b;
      font-weight: 500;
      font-size: 14px;
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      width: auto;
      border: 0;
    }

    form {
      label {
        color: #8c8f99;
        font-size: 14px;
      }

      .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        width: 100%;
        height: 32px;
        /* padding: 0 11px; */
        padding-left: 0px;
      }
    }
  }
`
