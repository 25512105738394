import { years } from '../../views/Leads/LeadDetails/LeadActions/lead.utils'
import { IRootState } from '../reducer.interface'

export const getCarMakeOptions = (state: IRootState) =>
  state.cars.make.makeList.map((car) => ({
    label: car.name,
    value: car.id?.toString()
  }))

export const getCarModelOptions = (state: IRootState) =>
  state.cars.model.modelList.map((car) => ({
    label: car.name,
    value: car.name
  }))

export const getCarBodyOptions = (state: IRootState) =>
  state.cars.body.bodyTypeList.map((car) => ({
    label: car.name,
    value: car.name
  }))

export const getCarYears = () =>
  years.map((year) => ({
    label: year.toString(),
    value: year.toString()
  }))
