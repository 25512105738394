import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import debounce from 'lodash/debounce'
import {
  getAgentConversations,
  getMode,
  getNewMessageAlert,
  getWhatsAppSearchQuery,
  getSearchResults
} from '../../../store/conversations/selectors'
import {
  fetchConversations,
  searchConversations
} from '../../../store/conversations/actions'
import { IConversationsMatchParams } from '../Conversations'
import { groupConvosByDate } from './MessageList.utils'
import { deriveInitials, getDateTime } from '../Conversations.utils'
import {
  MessageListWrapper,
  MessageListTitle,
  MessageGroupDate,
  MessageGroup,
  MessageListItem,
  StyledMessageLink,
  MessageListItemInitials,
  MessageListRight,
  MessageListItemTop,
  EditNoteIcon,
  LastMessage,
  UnreadIndicator,
  NoSearchResults,
  MessageListObserverTarget,
  LoadingMoreConvos
} from './styles'
import { IRootState } from '../../../store/reducer.interface'
import dayjs from 'dayjs'
import { isFetching } from '../../../store/isFetching/selectors'
import { useTranslation } from 'react-i18next'

interface IMessageProps {
  sender: string
  recipient: string
  timeRecieved: string
  text: string
  read: boolean
}

const Message = ({
  sender,
  recipient,
  timeRecieved,
  text,
  read
}: IMessageProps) => {
  const { status, recipientPhone } = useParams<IConversationsMatchParams>()
  const isActiveConvo = recipient === recipientPhone

  const { t } = useTranslation()

  return (
    <MessageListItem active={isActiveConvo}>
      <StyledMessageLink
        to={{
          pathname: `/conversations/${status}/${recipient}`,
          state: { recipientName: sender }
        }}
      >
        <MessageListItemInitials active={isActiveConvo}>
          {deriveInitials(sender)}
        </MessageListItemInitials>
        <MessageListRight>
          <MessageListItemTop active={isActiveConvo} read={read}>
            <span>
              {sender?.length > 20 ? `${sender?.slice(0, 20)}...` : sender}
            </span>
            <span>{timeRecieved}</span>
          </MessageListItemTop>
          <LastMessage read={read}>
            {text ? (
              <p>{text?.length > 25 ? `${text?.slice(0, 25)}...` : text}</p>
            ) : (
              <p>{t('clickViewMessage')}</p>
            )}
            {!read && <UnreadIndicator>&nbsp;</UnreadIndicator>}
          </LastMessage>
        </MessageListRight>
      </StyledMessageLink>
    </MessageListItem>
  )
}

export default function MessageList() {
  const { status } = useParams<IConversationsMatchParams>()
  const conversations = useSelector((state: IRootState) =>
    getAgentConversations(state, status)
  )
  const newMessageAlert = useSelector(getNewMessageAlert)
  const searchResults = useSelector(getSearchResults)
  const mode = useSelector(getMode)
  const isFetchingConvos = useSelector((state: IRootState) =>
    isFetching(state, 'convos')
  )
  const searchQuery = useSelector(getWhatsAppSearchQuery)
  const dispatch = useDispatch()
  const [loadingMore, setLoadingMore] = useState(false)
  const observerRoot = useRef<HTMLDivElement>(null)
  const observerTarget = useRef<HTMLDivElement>(null)
  const { t } = useTranslation()

  const groups = groupConvosByDate(
    mode === 'search' ? searchResults : conversations
  )

  console.log(newMessageAlert)

  const groupMessages = mode === 'search' ? searchResults : conversations

  useEffect(() => {
    dispatch(fetchConversations(status, true))
    // observerRoot.current?.scrollTo({
    //   top: 0
    // })
  }, [dispatch, status, newMessageAlert.message?.text])

  useEffect(() => {
    if (isFetchingConvos) {
      setLoadingMore(true)
    } else {
      setLoadingMore(false)
    }
  }, [groupMessages, isFetchingConvos])

  useEffect(() => {
    let isInitialMount = true
    let observer: IntersectionObserver
    const target = observerTarget.current
    const debouncedConvoFetch = debounce(() => {
      if (mode === 'search') {
        dispatch(searchConversations(searchQuery))
      } else {
        dispatch(fetchConversations(status, false))
      }
    }, 200)

    if (target && observerRoot.current) {
      const observerOptions: IntersectionObserverInit = {
        root: observerRoot.current
      }

      observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          if (isInitialMount) {
            isInitialMount = false
          } else {
            if (entry.intersectionRatio > 0.9) {
              setLoadingMore(true)
              debouncedConvoFetch()
            }
          }
        })
      }, observerOptions)

      observer.observe(target)
    }

    return () => {
      if (target && observer) {
        observer.unobserve(target)
      }
    }
  }, [dispatch, status, mode, searchQuery])

  return (
    <MessageListWrapper ref={observerRoot}>
      <MessageListTitle>
        <p>
          {mode === 'search' ? t('Search Results') : t(statusNameMap[status])}
        </p>
        <EditNoteIcon />
      </MessageListTitle>
      {!loadingMore && Object.values(groups).length === 0 && (
        <NoSearchResults>{t('noResultsFound')}</NoSearchResults>
      )}
      {Object.keys(groups)
        .sort(
          (a, b) => groups[b].dateInMilliseconds - groups[a].dateInMilliseconds
        )
        .map((date) => {
          const currentGroup = groups[date]

          return (
            <React.Fragment key={currentGroup.displayString}>
              <MessageGroupDate>{currentGroup.displayString}</MessageGroupDate>
              <MessageGroup>
                {currentGroup.convos
                  .sort(
                    (a, b) =>
                      dayjs(b.last_message[0].createdAt).valueOf() -
                      dayjs(a.last_message[0].createdAt).valueOf()
                  )
                  .map(({ _id, name, last_message }) => {
                    const { text, createdAt, caption, read } = last_message[0]

                    return (
                      <Message
                        key={_id}
                        recipient={_id}
                        sender={name}
                        read={read}
                        timeRecieved={getDateTime(createdAt, 'time')}
                        text={text || caption}
                      />
                    )
                  })}
              </MessageGroup>
            </React.Fragment>
          )
        })}
      {loadingMore && <LoadingMoreConvos>{t('fetching')}...</LoadingMoreConvos>}
      <MessageListObserverTarget ref={observerTarget} />
    </MessageListWrapper>
  )
}

const statusNameMap = {
  queue: 'Queue',
  active: 'Active',
  closed: 'Closed',
  all: 'All conversations'
}
