import { AutocheckB2BPartner } from '../views/LeadManagement/Responses/constant'

export const getPartnerId = (envUrl?: string, country?: string) => {
  let url
  if (envUrl?.includes('staging')) {
    url = AutocheckB2BPartner.staging_partner_id
  } else {
    switch (country?.toUpperCase()) {
      case 'NG':
        url = AutocheckB2BPartner.ng_prod_partner_id
        break
      case 'GH':
        url = AutocheckB2BPartner.gh_prod_partner_id
        break
      case 'UG':
        url = AutocheckB2BPartner.ug_prod_partner_id
        break
      case 'KE':
        url = AutocheckB2BPartner.ke_prod_partner_id
        break
      case 'CI':
        url = AutocheckB2BPartner.ci_prod_partner_id
        break
      default:
        url = AutocheckB2BPartner.ng_prod_partner_id
        break
    }
  }
  return url
}
