import React, { useState } from 'react'
import { StyledResponsesTable } from './styles'
// import { Input, Button, Table, Tag, Select } from 'antd'
import { Table, Tag, Button, Space } from 'antd'
import { DownloadIcon } from '../../PortfolioManagement/PortfolioDetails/svgs'
import BookInspectionModal from '../BookInspection'
import api from '../../../utils/api'
import { useSelector } from 'react-redux'
import {
  getUserToken,
  getSelectedCountry,
  getUserId
} from '../../../store/auth/selectors'
import { useQuery } from 'react-query'
import InspectionOutcomeModal from '../InspectionOutcome'
import { useTranslation } from 'react-i18next'

interface IResponseProps {
  tableData: any
}

const AppointmentTable = ({ singleLoan, loanRefresh }: any) => {
  const [activePage, setActivePage] = useState({
    currentPage: 1,
    pageSize: 10
  })
  const [modalOpen, setModalOpen] = useState(false)
  const [modalData, setModalData] = useState()
  const country = useSelector(getSelectedCountry)
  const token = useSelector(getUserToken)
  const userId = useSelector(getUserId)
  const { t } = useTranslation()

  const onChange = (page: any) => {
    setActivePage({
      ...activePage,
      currentPage: page
    })
  }

  const {
    data: carInspection,
    isLoading: carInspectionLoading,
    refetch
  } = useQuery(['car-sourcing', country], () =>
    api.getSchedule(
      userId,
      { lead_id: singleLoan?.id, completed: false, pageSize: 5 },
      token
    )
  )

  const handleActionButtonClick = (recordData: any) => {
    setModalData(recordData)
    setModalOpen(true)
  }

  const columns = [
    {
      title: t('Date'),
      dataIndex: 'date',
      render: (val: any) => <div>{new Date(val).toLocaleDateString()}</div>
    },
    {
      title: t('Time'),
      dataIndex: 'time'
      // render: (val: any) => <div>{new Date(val).toLocaleTimeString()}</div>
    },
    {
      title: t('location'),
      dataIndex: 'inspectionAddress',
      render: (val: string, record: any) => (
        <div
          style={{ textTransform: 'capitalize' }}
        >{`${record.appointment.pickUpLocation.street} ${record.appointment.pickUpLocation.city} ${record.appointment.pickUpLocation.state}`}</div>
      )
    },
    {
      title: t('status'),
      dataIndex: 'completed',
      render: (val: boolean) => (
        <div style={{ textTransform: 'capitalize' }} className="d-flex">
          {val === false ? 'Not completed' : 'Completed'}
        </div>
      )
    },
    {
      title: t('action'),
      dataIndex: '',
      render: (val: string, record: any) => (
        <>
          {record.completed === false && (
            <Button
              className="schedule"
              onClick={() => handleActionButtonClick(record)}
            >
              <span>{t('update')}</span>
            </Button>
          )}
        </>
      )
    }
  ]

  return (
    <StyledResponsesTable>
      <div className="responsesTableWrapper mt-4">
        <div className="header mb-3">{t('appointment')}</div>

        <Table
          dataSource={carInspection?.schedule}
          loading={carInspectionLoading}
          columns={columns}
          pagination={{
            pageSize: activePage.pageSize,
            current: activePage.currentPage
          }}
          onChange={onChange}
          rowKey="id"
        />
      </div>

      <InspectionOutcomeModal
        inspOutcome={modalOpen}
        setInspOutcome={setModalOpen}
        modalData={modalData}
        refetch={() => refetch()}
        singleLoan={singleLoan}
        loanRefresh={loanRefresh}
      />
    </StyledResponsesTable>
  )
}
export default AppointmentTable
