import React from 'react'

const CheckMarkIcon = () => {
  return (
    <svg
      width={32}
      height={34}
      viewBox="0 0 32 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6667 17.7178L14.6667 21.7492L21.3334 13.6865"
        stroke="#E0E3F2"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.0001 30.4838C23.3639 30.4838 29.3334 24.4675 29.3334 17.0459C29.3334 9.6244 23.3639 3.60806 16.0001 3.60806C8.63628 3.60806 2.66675 9.6244 2.66675 17.0459C2.66675 24.4675 8.63628 30.4838 16.0001 30.4838Z"
        stroke="#E0E3F2"
        stroke-width="2"
      />
    </svg>
  )
}

export default CheckMarkIcon
