import {
  SIGN_IN_SUCCESS,
  LOGOUT_SUCCESS,
  VERIFY_OTP_SUCCESS,
  SEND_OTP_SUCCESS,
  SET_COUNTRY,
  SAVE_USER_LOCATION
} from './actions'
import { UPDATE_USER_SUCCESS } from '../settingsSection/actions'

const initialState = {
  isAuth: false,
  userRoles: '',
  otpToken: '',
  country: '',
  userLocation: {}
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_SUCCESS:
    case VERIFY_OTP_SUCCESS: {
      return {
        ...state,
        isAuth: true,
        userRoles: action.payload.authority.roles,
        otpToken: '',
        country: action.payload.user.country,
        ...action.payload
      }
    }
    case SEND_OTP_SUCCESS: {
      return {
        ...state,
        otpToken: action.payload
      }
    }
    case SAVE_USER_LOCATION: {
      return {
        ...state,
        userLocation: action.payload
      }
    }
    case UPDATE_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload
      }
    }
    case SET_COUNTRY: {
      return {
        ...state,
        country: action.country
      }
    }
    case LOGOUT_SUCCESS: {
      return initialState
    }
    default:
      return state
  }
}

export default reducer
