import React, { useState, useEffect } from 'react'
import { StyledDisbursedOffer, StyledPaid } from './styles'
import { Collapse, Button } from 'react-bootstrap'
import ReviewedDocument from '../../PortfolioManagement/PortfolioDetails/ReviewedDocument'
import {
  ChevronTIcon,
  ChevronBIcon,
  TaskIcon
} from '../../PortfolioManagement/PortfolioDetails/svgs'
import { StyledOffer } from '../../PortfolioManagement/PortfolioDetails/styles'
import RequestEquityModal from './RequestEquityModal'
import RecordModal from './RecordModal'
import { useParams } from 'react-router-dom'
import api from '../../../utils/api'
import {
  getUserToken,
  getSelectedCountryCurrency
} from '../../../store/auth/selectors'
import { useQuery, useQueryClient } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { CAR_STATUS } from '../../LeadManagement/AvailableCar/constants'

interface IDetailParam {
  id: string
}

export default function DisbursedOffer({ title }: any) {
  const token = useSelector(getUserToken)
  const { id } = useParams<IDetailParam>()
  const currency = useSelector(getSelectedCountryCurrency)
  const [open, setOpen]: any = useState(false)
  const [state, setState] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isRecordVisible, setIsRecordVisible] = useState(false)
  const [getOffers, setGetOffers] = useState<any>([])
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { data: singleLoan, status, refetch } = useQuery(
    'singleLoans',
    () => api.fetchSingleOriginationLoan(token, id),
    { keepPreviousData: true, staleTime: 5000 }
  )

  useEffect(() => {
    if (singleLoan) {
      queryClient.prefetchQuery(['singleLoans'], () =>
        api.fetchSingleOriginationLoan(token, id)
      )
      setGetOffers(singleLoan)
    }
  }, [singleLoan, queryClient, id])

  //@ts-ignore
  const statusPaid = getOffers?.equities?.some((item) => item?.amountPaid == 0)

  const showEquityModal = () => {
    setIsModalVisible(true)
  }

  const showRecordModal = () => {
    setIsRecordVisible(true)
  }

  function handleClick(id: any) {
    //@ts-ignore
    setOpen((prevState) => ({ ...prevState, [id]: !prevState[id] }))
    setState((prevState) => !prevState)
  }

  function getFileNameFromUrl(url: any) {
    var el = document.createElement('a')
    el.href = url
    return decodeURIComponent(el.pathname).split('/').pop()
  }

  return (
    <StyledDisbursedOffer>
      {getOffers?.offer == undefined ? (
        <div className="content">
          <div>{TaskIcon}</div>
          <div className="text ms-3">
            {t('thisCustomerDoesNotHaveAcceptedOffers')}.
          </div>
        </div>
      ) : (
        <div className="disbursementDocument mt-5">
          <StyledOffer>
            <div className="offersUpload d-grid gap-4">
              <div>
                <div className="header mb-2">
                  {getOffers?.offer?.partner?.name || 'N/A'}
                </div>
                <div className="offerDetails">
                  <div
                    className="text-center"
                    style={{ borderRight: '1px solid #e9e9e9' }}
                  >
                    <div className="head">{t('LoanAmount')}</div>
                    <div className="value">
                      {currency}
                      {getOffers?.offer?.approvedAmount.toLocaleString() ||
                        'N/A'}
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ borderRight: '1px solid #e9e9e9' }}
                  >
                    <div className="head">{t('Downpayment')}</div>
                    <div className="value">
                      {currency}
                      {Number(getOffers?.offer?.equity).toLocaleString() ||
                        'N/A'}
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ borderRight: '1px solid #e9e9e9' }}
                  >
                    <div className="head">{t('Tenure')}</div>
                    <div className="value">
                      {getOffers?.offer?.tenure || 'N/A'} mos.
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ borderRight: '1px solid #e9e9e9' }}
                  >
                    <div className="head">{t('InterestRate')}</div>
                    <div className="value">
                      {getOffers?.offer?.interest || 'N/A'}%
                    </div>
                  </div>
                  <div
                    className="text-center"
                    style={{ borderRight: '1px solid #e9e9e9' }}
                  >
                    <div className="head">{t('MonthlyEMI')}</div>
                    <div className="value">
                      {currency}
                      {Number(
                        getOffers?.offer?.monthlyRepayment
                      ).toLocaleString() || 'N/A'}
                    </div>
                  </div>
                  <div className="text-center">
                    <div className="head">{t('customerAcceptance')}</div>
                    <div className="value">{t('Yes')}</div>
                  </div>
                </div>

                <Button
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  className="mt-3 mb-2 viewDocument"
                >
                  <div className="d-flex align-items-center">
                    <div className="me-1">{t('viewDocuments')}</div>
                    <div>
                      {open ? (
                        <div>{ChevronTIcon}</div>
                      ) : (
                        <div>{ChevronBIcon}</div>
                      )}
                    </div>
                  </div>
                </Button>

                <Collapse in={open}>
                  <div id="example-collapse-text">
                    <div className="collapseContent">
                      <ReviewedDocument />
                    </div>
                  </div>
                </Collapse>
              </div>

              {/* car sourcing complete */}
              <div className="acceptedOffer">
                <div className="d-flex">
                  <div className="me-4 carSource px-2">
                    {t('carSourcingComplete')}
                  </div>
                  <div className="equityButton">
                    {getOffers?.equities?.length === 0 ? (
                      <div className="">
                        <Button
                          className="addedEquity"
                          onClick={showEquityModal}
                          disabled={
                            singleLoan?.status !== CAR_STATUS.CAR_ACCEPTED
                          }
                        >
                          {t('requestEquity')}
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <div className="containers">
                          {getOffers?.equities?.map((el: any, index: any) => (
                            <div
                              className="d-flex align-items-center equityPaid pendingEquity mb-4"
                              key={index}
                            >
                              {el?.amountPaid > 0 ? (
                                ''
                              ) : (
                                <div className="pending me-3 px-2">
                                  {t('pendingEquityPayment')}
                                </div>
                              )}
                              {el?.amountPaid > 0 ? (
                                <>
                                  <div className="amount me-4">
                                    <div>
                                      {t('amountRequested')}:{' '}
                                      <span>
                                        {currency}
                                        {el?.amountRequested.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="mb-2">
                                      {t('dateRequested')}:{' '}
                                      {new Date(
                                        el?.dateRequested
                                      ).toDateString()}
                                    </div>
                                    <div>
                                      {t('amountPaid')}:{' '}
                                      <span>
                                        {currency}
                                        {el?.amountPaid.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="mb-2">
                                      {t('datePaid')}:{' '}
                                      {new Date(el?.datePaid).toDateString()}
                                    </div>
                                    <div className="paid">
                                      {t('equityPaid')}
                                    </div>
                                  </div>
                                  <div className="me-4">
                                    <div className="pdfFile d-flex">
                                      <div className="image me-2"></div>
                                      <div>
                                        <div className="payment">
                                          {getFileNameFromUrl(el?.fileUrl) ||
                                            'N/A'}
                                        </div>
                                        <div className="size">N/A</div>
                                      </div>
                                    </div>
                                    <div className="links">
                                      <a
                                        href={el?.fileUrl}
                                        target="_blank"
                                        className="me-3"
                                      >
                                        {t('view')}
                                      </a>
                                      <a href={el?.fileUrl} download>
                                        {t('download')}
                                      </a>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="amount">
                                  {t('amountRequested')}:{' '}
                                  <span>
                                    {currency}
                                    {el?.amountRequested.toLocaleString()}
                                  </span>
                                  <div className="mb-2">
                                    {t('dateRequested')}:{' '}
                                    {new Date(el?.dateRequested).toDateString()}
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </div>

                        {getOffers?.disbursment == undefined ? (
                          <div className="">
                            <Button
                              className="addedRequestEquity mb-3"
                              onClick={showEquityModal}
                            >
                              {t('requestAdditonalEquity')}
                            </Button>
                          </div>
                        ) : (
                          ''
                        )}

                        <div className="buttonsWrapper mt-4">
                          <div className="">
                            {getOffers?.disbursment == undefined ? (
                              <>
                                {!statusPaid && (
                                  <div>
                                    <Button
                                      className="recordDisburse"
                                      onClick={showRecordModal}
                                    >
                                      {t('recordDisbursement')}
                                    </Button>
                                  </div>
                                )}
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* disbursement complete */}
              {getOffers?.disbursment !== undefined && (
                <div className="acceptedOffer">
                  <div className="d-flex">
                    <div className="me-4 carSource">{t('disbursement')}</div>
                    <div className="equityButton">
                      {/* completed disbursement */}
                      <div className="">
                        <div className="equityPaid d-flex">
                          <div className="amount me-4">
                            <div>
                              {t('amountDisbursed')}:{' '}
                              <span>
                                {currency}
                                {getOffers?.disbursment?.amount.toLocaleString()}
                              </span>
                            </div>
                            <div className="">
                              {t('Bank')}: {getOffers?.offer?.partner?.name}
                            </div>
                            <div>
                              {t('Date')}:{' '}
                              {new Date(
                                getOffers?.disbursment?.dateDisbursed
                              ).toDateString()}
                            </div>
                          </div>
                          <div className="me-4">
                            <div className="pdfFile d-flex">
                              <div className="image me-2"></div>
                              <div>
                                <div className="payment">
                                  {getFileNameFromUrl(
                                    getOffers?.disbursment?.fileUrl
                                  ) || 'N/A'}
                                </div>
                                <div className="size">N/A</div>
                              </div>
                            </div>
                            <div className="links">
                              <a
                                href={getOffers?.disbursment?.fileUrl}
                                target="_blank"
                                className="me-3"
                              >
                                {t('view')}
                              </a>
                              <a
                                href={getOffers?.disbursment?.fileUrl}
                                download
                              >
                                {t('download')}
                              </a>
                            </div>
                          </div>
                          <div className="buttonsWrapper">
                            <StyledPaid>
                              <div className="paid">{t('loanDisbursed')}</div>
                            </StyledPaid>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </StyledOffer>
          <RequestEquityModal
            isModalVisible={isModalVisible}
            setIsModalVisible={setIsModalVisible}
            refetch={refetch}
          />
          <RecordModal
            isRecordVisible={isRecordVisible}
            setIsRecordVisible={setIsRecordVisible}
          />
        </div>
      )}
    </StyledDisbursedOffer>
  )
}
