import React from 'react'
import PersonIcon from '../../../images/person4.svg'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCurrentActivity,
  startActivityLoader,
  saveFilterName,
  saveAgentFilteredArray,
  clearAgentArray
} from '../../../store/leads/actions'
import {
  agentsFromStore,
  activityTypeFromTheStore,
  activityFilterName,
  filteredAgentArray,
  filteredActivityDate
} from '../../../store/leads/selectors'
import {
  isAdmin,
  isManager,
  isAgent,
  getUserIdIfAgent
} from '../../../store/auth/selectors'

import * as Yup from 'yup'

import Popover from '@material-ui/core/Popover'
import Checkbox from '@material-ui/core/Checkbox'

import { AssigneeeDiv, AssignNameDiv } from '../styles'
import { useTranslation } from 'react-i18next'

export default function AssignedAgentPop(prop: any) {
  const dispatch = useDispatch()
  const activityTypeFromStore: any = useSelector(activityTypeFromTheStore)
  const agents: any = useSelector(agentsFromStore)
  const filterName: any = useSelector(activityFilterName)
  const filteredAgents: any = useSelector(filteredAgentArray)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [idArray, setidArray] = React.useState([] as any)
  const userIsAgent = useSelector(isAgent)
  const [setId, setIdFromApi] = React.useState('')
  const [agentIds, setAgentIds] = React.useState('')
  const [showCheckBox, setShowCheckBox] = React.useState(true)
  const userIsManager: any = useSelector(isManager)
  const userIsAdmin: any = useSelector(isAdmin)
  const [search, setSearch] = React.useState('')
  const [agentsFromHook, setAgents] = React.useState(agents)
  const [removeCheck, setCheckBox] = React.useState(false)
  const [removeSelectCheck, setSelectCheckBox] = React.useState(false)
  const agentIdFromStore: any = useSelector(getUserIdIfAgent)
  const activityDate: any = useSelector(filteredActivityDate)
  const { t } = useTranslation()

  React.useEffect(() => {
    let arr: any[] = agents
    if (agents) {
      setAgents([...agents])
    }
  }, [agents])

  React.useEffect(() => {
    function onlyUnique(value: any, index: any, self: any) {
      return self.indexOf(value) === index
    }

    let unique

    unique = idArray.filter(onlyUnique)

    let agentIdsToApi = unique.join(',')

    let passedActivityType
    if (
      activityTypeFromStore == 'PHONE_CALL' ||
      activityTypeFromStore == 'LOAN_REMINDER'
    ) {
      passedActivityType =
        activityTypeFromStore == 'PHONE_CALL' ? 'PHONE_CALL' : 'LOAN_REMINDER'
    }
    let queryTypeForPhone = {
      activityType: passedActivityType
    }
    let queryTypeBuySaleFix = {
      appointmentType:
        activityTypeFromStore == 'BUY_CAR'
          ? 'BUY_CAR,TEST_DRIVE_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK'
          : activityTypeFromStore
    }

    let queryData =
      activityTypeFromStore == 'PHONE_CALL' ||
      activityTypeFromStore == 'LOAN_REMINDER'
        ? queryTypeForPhone
        : queryTypeBuySaleFix
    let query = {
      ...queryData,
      agentId: agentIdsToApi.toString(),
      completed: false,
      start_date: activityDate.startDate,
      end_date: activityDate.endDate
    }

    if (!activityDate.startDate) {
      delete query.start_date
      delete query.end_date
    }

    if (idArray.length > 0) {
      dispatch(startActivityLoader())
      dispatch(getCurrentActivity(query))
    }
  }, [idArray])

  React.useEffect(() => {
    if (removeSelectCheck) setidArray([])
  }, [removeSelectCheck])

  const filterAgents = (e: any, id: string, name: any) => {
    setSelectCheckBox(false)
    if (e.target.checked) {
      dispatch(saveAgentFilteredArray([...idArray, id]))
      setidArray([...idArray, id])
    } else {
      let newarr = idArray.filter((data: string) => {
        return data !== id
      })
      setidArray(newarr)
      dispatch(saveAgentFilteredArray(newarr))
    }

    setIdFromApi(id)
    dispatch(saveFilterName(name))
  }

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const removeAllCheckbox = (e: any) => {
    setCheckBox(true)
    setShowCheckBox(false)

    setTimeout(() => {
      setShowCheckBox(true)
    }, 100)

    prop.handleChange(e, prop.page)
    setSelectCheckBox(!removeSelectCheck)
    setidArray([])
    dispatch(clearAgentArray())
    let queryTypeForPhone = {
      activityType: activityTypeFromStore
    }
    let queryTypeBuySaleFix = {
      appointmentType:
        activityTypeFromStore == 'BUY_CAR'
          ? 'BUY_CAR,TEST_DRIVE_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK'
          : activityTypeFromStore
    }
    let queryData =
      activityTypeFromStore == 'PHONE_CALL'
        ? queryTypeForPhone
        : queryTypeBuySaleFix
    let query = {
      ...queryData,
      completed: false,
      start_date: activityDate.startDate,
      end_date: activityDate.endDate
    }
    if (!activityDate.startDate || !activityDate.endDate) {
      delete query.start_date
      delete query.end_date
    }
    dispatch(startActivityLoader())
    dispatch(getCurrentActivity(query))
  }

  const filterAgentsFromHook = (e: any) => {
    const test = agents.filter((agento: any) => {
      return agento.firstname
        .toLowerCase()
        .includes(e.target.value.toLowerCase())
    })

    setAgents(test)
    setSearch(e.target.value)
  }
  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  return (
    <div style={{ minWidth: '200px' }}>
      {userIsManager || userIsAdmin ? (
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
          <span>
            {t('filterByAgents')} :{' '}
            <span style={{ color: '#a1aace' }}>
              {removeSelectCheck ? (
                <span>{t('all')}</span>
              ) : (
                <span>{filterName}</span>
              )}
            </span>
          </span>
        </div>
      ) : null}

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <AssignNameDiv>
          <input
            placeholder={t('search')}
            onChange={(e) => filterAgentsFromHook(e)}
            style={{
              background: '#F6F7FB',
              border: '1px solid #F6F7FB',
              borderRadius: '7px',
              padding: '6px',

              minWidth: '100%',
              minHeight: '40px'
            }}
            value={search}
          />

          <AssigneeeDiv>
            <p
              style={{ color: '#4B9FF2', flexBasis: '50%' }}
              onClick={(e) => removeAllCheckbox(e)}
            >
              {t('clearAllAgents')}
            </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexBasis: '50%'
              }}
              onClick={(e) => removeAllCheckbox(e)}
            >
              <Checkbox
                checked={removeSelectCheck}
                onChange={(e) => removeAllCheckbox(e)}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
            </div>
          </AssigneeeDiv>

          <div>
            {agentsFromHook?.map((agent: any) => (
              <div key={agent.id} style={{ display: 'flex' }}>
                <AssigneeeDiv
                  onClick={(e) =>
                    filterAgents(e, agent.id, agent.firstname + agent.lastname)
                  }
                >
                  <img src={PersonIcon} style={{ paddingRight: '10px' }} />
                  {agent.firstname} {agent.lastname}
                </AssigneeeDiv>

                {showCheckBox ? (
                  <Checkbox
                    checked={
                      filteredAgents
                        ? idArray.find(
                            (agentFromFilter: any) =>
                              agent.id === agentFromFilter
                          )
                        : false
                    }
                    onChange={(e) =>
                      filterAgents(
                        e,
                        agent.id,
                        agent.firstname + agent.lastname
                      )
                    }
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                ) : null}
              </div>
            ))}
          </div>
        </AssignNameDiv>
      </Popover>
    </div>
  )
}
