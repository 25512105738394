import React from 'react';


import  dayjs from 'dayjs'
import { useDispatch, useSelector, connect } from 'react-redux'
import {getCurrentActivity , startActivityLoader, saveActivityType, fetchLocations , fetchAgents,saveAgentFilteredArray, getSalesOfficer, clearAgentArray, saveAgentFilterDate, getAgentTasks} from '../../../store/leads/actions'
import {
 
  activityTypeFromTheStore,
  getUserTimeZone,
  salesOfficersData

} from '../../../store/leads/selectors'
import {  isAdmin, isManager, isAgent, getUserIdIfAgent, isFulfillmentAgent} from '../../../store/auth/selectors'
import calendar from '../../../images/calendar-dark.svg'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { Button, Typography,  FormHelperText,
  InputBase,
  TextField, Grid } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import {
  makeStyles
} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { usePhoneStyles } from '../../Leads/LeadDetails/LeadActions/styles'

import {updateSchedule } from '../../../store/events/actions'

import {
  
    PopOverDiv,
   
  } from '../styles'

  import Select from 'react-select'

  const showPreselectedValue = [
    { label: 'Car condition different from inspection report', value: 'Car condition different from inspection report' },
    { label: 'Not interested', value: 'Not interested' },
    { label: 'Price Changed', value: 'Price Changed' },
    { label: 'Show me more options', value: 'Show me more options' },
    { label: 'Low Budget', value: 'Low Budget' },
    { label: ' Car Loan needed', value: ' Car Loan needed' },
    { label: 'Ready to buy', value: ' Dealer was rude' },
    { label: ' Need more time - Proxy Inspection', value: ' Need more time - Proxy Inspection' },
    { label: '  Vehicle. was not. available', value: ' Vehicle. was not. available' },
    { label: 'Others', value: 'Others' },
  ]
  

  const required = 'This field is required.'
  const validationSchema = Yup.object().shape({
    date: Yup.string().required(required),
    time: Yup.string().required(required),
    note: Yup.string().required(required)
  })
  const initialValues = {
    date: '',
    time: '',
    note: ''
  }

  const useStyles = makeStyles (theme => ({
    popoverRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }));
  


    export default function RescheduleButton(prop:any){
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = usePhoneStyles()
 
    const agentIdFromStore: any = useSelector(getUserIdIfAgent)
    const userIsFulfilment = useSelector(isFulfillmentAgent)
    const userIsAgent = useSelector(isAgent)
    const userIsManager = useSelector(isManager)
    const userIsAdmin = useSelector(isAdmin)
    const [showSelectedValue, setShowSelectedValue] = React.useState('');
  const [appointment, setAppointment] = React.useState(false)
  const [showReschedule, setShowReschedule] = React.useState(false)
  const salesOfficers = useSelector(salesOfficersData)
  const [salesOfficerId, setSalesOfficerId] = React.useState('');
  const [salesOfficersFromHook, setSalesOfficers] = React.useState([] as any);
    const activityTypeFromStore: any = useSelector(activityTypeFromTheStore)
    const [showValue, setShowValue] = React.useState(false)


    let dataForTasks = {
        agentId:agentIdFromStore,
        completed:false
      }


    React.useEffect(() => {


      if(salesOfficers){
    
        let salesOfficersArray:any = []
        let  users = salesOfficers.filter(function ( data :any) {
          salesOfficersArray.push({ label: data.firstname+ ' ' +data.lastname, value: data.id });
         
           return data;
       });
       setSalesOfficers(salesOfficersArray)
      }
     
      
      }, [])


      const handlePreSelectedValue = (e:any) =>{

        setShowSelectedValue(e.value)
       }
    
       const handleShow = (e:any) => {
        e.target.value == 'no_show'?
        setShowValue(false) :  setShowValue(true)
        setAppointment(true)
     };
    const handleClick = (event:any) => {
      setAnchorEl(event.currentTarget);
      setShowReschedule(true);
    }
    const handleUpdateSchedule = (value:any, time:any, date:any, id:any) => {

        let showNote = showSelectedValue ? showSelectedValue+ ' : '+ value : value

      dispatch(startActivityLoader())
   


      if(appointment && showSelectedValue){
        dispatch(updateSchedule({ note: showNote, completed: true },id))
      }

      else if(appointment && !showSelectedValue){

        dispatch(updateSchedule({ note: value, completed: true },id))
      }

      else{
        dispatch(updateSchedule({ note: value, time:time+getUserTimeZone(), date:date, sales_officer_id:salesOfficerId, show:false, completed: false },id))
      }
    

  

      setTimeout(()=>{
        dispatch(getAgentTasks(dataForTasks))
        dispatch(startActivityLoader())
        let  passedActivityType; 
        if(activityTypeFromStore == 'PHONE_CALL' || activityTypeFromStore == 'LOAN_REMINDER' ){

            passedActivityType = activityTypeFromStore == 'PHONE_CALL' ? 'PHONE_CALL'  : 'LOAN_REMINDER'
        }


        dispatch(saveActivityType(activityTypeFromStore == 'PHONE_CALL' || activityTypeFromStore == 'LOAN_REMINDER' ? passedActivityType : prop.activity.appointment.type))
    
        let query = {
          appointment_type:prop.activity.appointment? prop.activity.appointment.type :'',
          agentId:agentIdFromStore,
          activity_type:activityTypeFromStore == 'BUY_CAR'? 'BUY_CAR,TEST_DRIVE_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK' : activityTypeFromStore,
          completed:false
     
        }
        activityTypeFromStore == 'PHONE_CALL' || activityTypeFromStore == 'LOAN_REMINDER' ? delete query.appointment_type : delete query.activity_type


        if( userIsAdmin || userIsManager) {

          delete query.agentId
        }
     
        dispatch(getCurrentActivity(query))

      }, 2500)
      setShowSelectedValue('')
    }
  
  
    const handleClose = () => {
      setAnchorEl(null);
      setAppointment(false)
      setShowReschedule(false);
      setShowValue(false)
    }


    const completeAppointment = (event:any) => {
      setAnchorEl(event.currentTarget);

      setAppointment(true)
    }
    const showAppointment = (event:any) => {
      setAnchorEl(event.currentTarget);

      setShowValue(true)
    }
  
    const noShowSchedule = () => {
    
      handleDialogClose()
      dispatch(startActivityLoader())
   
      dispatch(updateSchedule({ note: 'no show', show:false, completed: true }, prop.activity.id))
      setTimeout(()=>{
      
        dispatch(startActivityLoader())
        dispatch(saveActivityType(prop.activity.appointment.type))
    
        let query = {
          appointment_type:activityTypeFromStore == 'BUY_CAR'? 'BUY_CAR,TEST_DRIVE_CAR,TEST_DRIVE_TRUCK,BUY_TRUCK' : activityTypeFromStore,
          agentId:agentIdFromStore,
          completed:false
        }
        if( userIsAdmin || userIsManager) {

          delete query.agentId
        }
        dispatch(getAgentTasks(dataForTasks))
        dispatch(getCurrentActivity(query))

      }, 200)
   
    }
    const {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      
      touched,
      errors
    } = useFormik({
      initialValues,
      validationSchema,
      enableReinitialize: true,
      validateOnBlur: true,
      validateOnChange: true,
      onSubmit: (values) => {
        const { date, time, note } = values
  
     
  // dispatch(createAppointment(AppointmentData))
  

  
      }
    })
    const classes2 = useStyles();


    const [dialogOpen, setOpen] = React.useState(false);

    const handleSaleOfficerChange = (e:any) =>{

      setSalesOfficerId(e.value)
     }
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleDialogClose = () => {
      setOpen(false);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
  
    return (
    
     <div style={{minWidth:'100%'}}>

<Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
   
        <DialogContent>
       
        Are you sure the appointment was a no show?
        
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={noShowSchedule} color="primary" autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>






 {
      userIsAgent && prop.activity.agent.userId == agentIdFromStore
      
      || userIsFulfilment && activityTypeFromStore == 'BUY_CAR'
      || userIsFulfilment && activityTypeFromStore == 'SELL_CAR'
      ?
       <div>
{
  
activityTypeFromStore == 'PHONE_CALL' || activityTypeFromStore == 'LOAN_REMINDER'  ? null : 
<FormControl component="fieldset">

<RadioGroup row aria-label="position" onChange={handleShow}  name="position" defaultValue="">
      
<FormControlLabel    control={<Radio color="primary" />} onClick={showAppointment}  label="SHOW" />
<FormControlLabel onClick={handleClickOpen} control={<Radio color="secondary" />}  label="NO SHOW" />
</RadioGroup>
</FormControl>
}
      
</div> :null
  }





 
{
      userIsAgent && prop.activity.agent.userId == agentIdFromStore
      || userIsFulfilment && activityTypeFromStore == 'BUY_CAR'
      || userIsFulfilment && activityTypeFromStore == 'SELL_CAR'
      
      ?
 <div style={{minWidth:'100%'}}>
{
  activityTypeFromStore == 'PHONE_CALL' || activityTypeFromStore == 'LOAN_REMINDER'  ?
  <div style={{minWidth:'100%'}}>
<Button  variant="outlined" style={{minWidth:'100%', marginBottom:'10px'}}  onClick={completeAppointment}>Complete</Button>
<Button  variant="contained" style={{minWidth:'100%'}}  onClick={handleClick}>Reschedule</Button>
  </div>

:

<Button  variant="contained" style={{minWidth:'335px'}}  onClick={handleClick}>Reschedule</Button>
}
</div>
: null}
        <Popover
          id={id}
          open={open}
    
          onClose={handleClose}
          anchorReference={"none"}
          classes={{
            root: classes2.popoverRoot,
           }}
        >
<PopOverDiv>
<Grid item  xs={12}>

  {!appointment ?
<Grid item  xs={12}>
          <Grid container className={classes.root} justify="center">
      <Grid item xs={12} className={classes.titleWrapper}>
        <img
    src={calendar}
          alt="calendar"
          className={classes.icon}
        />
{

  appointment ?   <Typography variant="body1" className={classes.title}>
  Complete Appointment
  </Typography> :    <Typography variant="body1" className={classes.title}>
        Reschedule Appointment
        </Typography>
      
}

     
      </Grid>
    </Grid>
    <Grid item xs={12} className={classes.datetime}>
        <div>
          <Typography variant="body2" className={classes.label}>
            Date
          </Typography>
          <TextField
            id="date"
            name="date"
            onBlur={handleBlur}
            error={!!(errors.date && touched.date)}
            helperText={touched.date && errors.date}
            value={values.date}
            onChange={handleChange}
            variant="outlined"
            type="date"
            inputProps={{
             
                min: dayjs(new Date()).format('YYYY-MM-DD')
            }}
          />
        </div>
        <div>
          <Typography variant="body2" className={classes.label}>
            Time
          </Typography>
          <InputBase
            name="time"
            type="time"
            className={classes.timeInputWrapper}
            onBlur={handleBlur}
            error={!!(errors.time && touched.time)}
            value={values.time}
            onChange={handleChange}
          />
          {!!touched.time && (
            <FormHelperText error>{errors.time}</FormHelperText>
          )}
        </div>
      </Grid> 



      {
  activityTypeFromStore == 'PHONE_CALL' || activityTypeFromStore == 'LOAN_REMINDER'  ? null :

  <Grid item xs={6} className={classes.text}>
      <Typography variant="body2" style={{marginTop:'10px',}} className={classes.label}>
      Select Sales officer
        </Typography>
        <Select onChange={handleSaleOfficerChange} options={salesOfficersFromHook}/>
     </Grid>


      }


     </Grid>
 : null}

{
  showValue  && !showReschedule ? <Grid xs={6}  className={classes.text} style={{marginTop:10, marginBottom:10}}>
  <Typography variant="body2" className={classes.label}>
  What was the outcome of your appointment?
            </Typography> 
  <Select onChange={handlePreSelectedValue}   options={showPreselectedValue}/>
  </Grid>
: null  
}


      <Grid item xs={12} className={classes.text}>

      {
  activityTypeFromStore == 'PHONE_CALL' && appointment ||   activityTypeFromStore == 'LOAN_REMINDER' && appointment  ?

  <Typography variant="body2" className={classes.label}>
  What was the outcome of your phone call?
  </Typography> :  null

      }
      {
 activityTypeFromStore !== 'PHONE_CALL' && !showReschedule ||   activityTypeFromStore == 'LOAN_REMINDER' && !showReschedule ?

<Typography variant="body2" className={classes.label}>
Note
</Typography>: null

      }

      {

showReschedule &&  activityTypeFromStore !== 'PHONE_CALL' &&  activityTypeFromStore !== 'LOAN_REMINDER' ?       <Typography variant="body2" className={classes.label}>
        Why are you rescheduling the appointment?
            </Typography> : null
  

      }
      {

showReschedule &&  activityTypeFromStore == 'PHONE_CALL' || showReschedule &&  activityTypeFromStore == 'LOAN_REMINDER'  ?       <Typography variant="body2" className={classes.label}>
        Why are you rescheduling the phone call?
            </Typography> : null
  

      }


       
        <TextField
          fullWidth
          multiline
          rows={3}
          onBlur={handleBlur}
          error={!!(errors.note && touched.note)}
          helperText={touched.note && errors.note}
          name="note"
          value={values.note}
          onChange={handleChange}
          id="note"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} className={classes.formFooter}>
      <Button variant="outlined" onClick={() => handleClose()}>
        Cancel
      </Button>
      <Button
        variant="contained"
        disableElevation
        onClick={(event) => handleUpdateSchedule(values.note, values.time, values.date, prop.activity.id)}
      >
        Save
      </Button>
    </Grid>
    </Grid>
</PopOverDiv>

        </Popover>
      </div>
    );
  }







