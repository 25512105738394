import styled from 'styled-components'

export const StyledInspectionOutcome = styled.div`
  .bookInspectionWrapper {
    .title {
      font-size: 18px;
      color: #30355b;
      font-weight: bold;
    }
    .nav-pills .nav-link {
      border-radius: 0;
      color: #000;
      font-size: 14px;
      padding-left: 0px;
      padding-right: 0px;
    }
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      color: #000;
      background-color: transparent;
    }
  }
`

export const StyledNoShow = styled.div`
  .header {
    color: #30355b;
    font-size: 16px;
    font-weight: bold;
  }
  form {
    width: 500px;
    label {
      color: #30355b;
      font-size: 14px;
    }
    .ant-picker {
      width: 100%;
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
      height: 46px;
    }
    .cancel {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
    }
    .submit {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
      border: 0;
    }
  }
`

export const StyledNotAvailable = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .text {
    color: #555770;
    font-size: 14px;
  }
  .header {
    color: #30355b;
    font-weight: bold;
    font-size: 16px;
  }
  .vehicleHeader {
    color: #30355b;
    font-weight: bold;
    font-size: 14px;
  }
  form {
    width: 500px;
    label {
      color: #30355b;
      font-size: 14px;
    }
    input {
      height: 46px;
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
    }
    textArea {
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 18px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      pointer-events: none;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      padding: 0;
      line-height: 42px;
      transition: all 0.3s;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 46px;
      padding: 0 11px;
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .cancel {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
    }
    .submit {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
      border: 0;
    }
  }
`

export const StyledCarNotLiked = styled.div`
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .text {
    color: #555770;
    font-size: 14px;
  }
  .header {
    color: #30355b;
    font-weight: bold;
    font-size: 16px;
  }
  .vehicleHeader {
    color: #30355b;
    font-weight: bold;
    font-size: 14px;
  }
  .paragraph {
    color: #555770;
    font-size: 14px;
  }
  form {
    /* width: 500px; */
    .ant-checkbox-wrapper {
      padding-top: 40px;
    }

    label {
      color: #30355b;
      font-size: 14px;
    }
    input {
      height: 46px;
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
    }
    textArea {
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item,
    .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
      padding-right: 18px;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      pointer-events: none;
    }
    .ant-select-single .ant-select-selector .ant-select-selection-item,
    .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
      padding: 0;
      line-height: 42px;
      transition: all 0.3s;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      width: 100%;
      height: 46px;
      padding: 0 11px;
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
    }

    .cancel {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
    }
    .submit {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
      border: 0;
    }
  }
`
