import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import styled from 'styled-components'
export const useActionBtnStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdownIcon: {
      marginLeft: '1rem'
    },
    actionBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '.8rem 1rem',
      border: '1px solid #2DB6F5',
      background: '#EAF8FE',
      textTransform: 'none',
      fontWeight: 800,  
      width: '14rem',
      borderRadius: '0.25rem',
      color: '#2DB6F5',
      '&:hover': {
        background: '#EAF8FE !important',
        color: '#2DB6F5'
      }
    },
    rotate: {
      transform: 'rotate(180deg)',
      transition: '400ms'
    },
    popupWrapper: {
      marginTop: '0.625rem',
      boxShadow: 'none'
    },
    popupPaper: {
      boxShadow: '0 0.3595rem 1.2583rem rgba(59, 59, 59, 0.15)',
      borderRadius: '0.25rem'
    },
    popup: {
      padding: '1rem 0.8rem',
      boxSizing: 'border-box',
      width: '14rem',
    },
    title: {
      color: '#AEB6BE',
      fontSize: 12
    },
    linkBtn: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginTop: '.5rem',
      color: '#31355',
      fontWeight: 500,
      fontSize: '0.875rem',
      textTransform: 'none'
    }
  })
)

export const SearchContainerDiv = styled.div`
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid #e5e5e5;
  & input{
    min-width:100%;
    padding:10px;
    border: 1px solid #E5E5E5;
  }
`


export const useActionStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      background: '#fff',
      maxWidth: '48.25rem',
      border: '1px solid #E8E9ED',
      borderRadius: '0.25rem'
    },
    containerInner: {
      display: 'flex',
      flexDirection: 'column',
      padding: '1.5rem'
    },
    btnWrapper: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    divider: {
      margin: '1.5rem 0'
    },
    noteBtn: {
      display: 'flex',
      justifyContent: 'flex-start',
      padding: '.8rem 1.5rem',
      textTransform: 'none',
      fontWeight: 500,
      borderRadius: '0.25rem',
      color: '#2DB6F5'
    },

    selectArea:{
      display: 'flex',
      padding: '.8rem 1.5rem',
      justifyContent: 'space-between'
    },
    selectAreaChild:{
     flexBasis:'30%',
   
    },
  })
)

export const usePhoneStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      borderTop: '1px solid #E8E9ED',
      padding: '1.5rem'
    },
    titleWrapper: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      marginBottom: '1rem'
    },
    title: {
      color: '#0F1A4F',
      fontWeight: 800,
      fontSize: 14,
      marginLeft: '1rem'
    },
    icon: {
      width: '1rem',
      height: '1rem'
    },
    section: {
      marginTop: '1rem'
    },
    sectionTitle: {
      color: '#0F1A4F',
      fontWeight: 800,
      fontSize: 14,
      marginLeft: '2rem'
    },
    datetime: {
      display: 'flex',
      marginLeft: '1rem',
      '& > *': {
        margin: '1rem',
        width: '25ch'
      }
    },
    label: {
      color: '#A1AACE',
      fontWeight: 800,
      margin: '.3rem 0'
    },
    labelDark: {
      color: '#0A1133',
      fontWeight: 800,
      margin: '.3rem 0'
    },
    text: {
      marginLeft: '2rem',
      marginTop: '1rem'
    },
    formFooter: {
      marginTop: '1.2rem',
      display: 'flex',
      justifyContent: 'flex-end',
      '& > *': {
        margin: '.5rem',
        '&:last-of-type': {
          marginRight: 0
        }
      }
    },
    timeInputWrapper: {
      border: '1px solid #CAD0D9',
      background: '#fff',
      borderRadius: 4,
      paddingLeft: '1rem',
      paddingRight: '1rem',
      width: '12.3rem',
      '&:focus': {
        background: '#fff',
      }
    },
    timeInput: {
      border: '1px solid #CAD0D9',
      background: 'transparent',
      '&:focus': {
        background: 'transparent',
      }
    }
  })
)
