export const IS_FETCHING = 'IS_FETCHING'
export const DONE_FETCHING = 'DONE_FETCHING'

export const isFetching = (payload) => ({
  type: IS_FETCHING,
  payload
})

export const doneFetching = (payload) => ({
  type: DONE_FETCHING,
  payload
})
