import React from 'react'
import WithSideMenu from '../../../components/WithSideMenu'
import Select from '../../../components/SimpleSelect'
import Loader from '../../../components/Loader'
import TextInput from '../../../components/TextField'
import Popover from '@material-ui/core/Popover'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Actions from './Actions'
import LeadsLocation from './LeadActions/LeadsLocation'
import Details from './Details'
import ServiceInterest from './ServiceInterest'
import { useSelector } from 'react-redux'
import { isAdmin, isManager, isAgent } from '../../../store/auth/selectors'

// import { useTranslation } from 'react-i18next'
import { withTranslation } from 'react-i18next'

import {
  PageContainer,
  Header,
  HeaderTopRight,
  GoBack,
  GoBackIcon,
  GoBackTitle,
  QuestionMark,
  Avatar,
  LeadInfoSection,
  LeadInfoName,
  LeadInfoNameType,
  LeadInfoSectionLeft,
  LeadInfoSectionRight,
  AssignedAgentSection,
  AgentIcon,
  AgentName,
  AssignedAgent,
  DownIcon,
  AgentListOverlay,
  SearchIcon,
  SearchInputContainer,
  SearchInput,
  Agent,
  AgentListIcon,
  AgentListText,
  StyledGrid,
  StyledBackLink
} from './styles'
import { State, Props } from './types'
import { statusOptions } from '../lead.util'

class leadDetails extends React.Component<Props, State> {
  state = {
    leadStatus: this.props.details.status,
    anchorEl: null,
    reasonFormOpen: false,
    agents: [],
    searchTerm: '',
    firstname: undefined,
    lastname: undefined,
    statusReason: null,
    carsFromMarket: this.props.carsFromMarket
  }

  componentDidMount = () => {
    const {
      getLead,
      getAgents,
      fetchLocations,
      getServiceInterests,
      getSchedules,
      fetchServiceOptions,
      fetchCarMake,
      fetchCarBodyType,
      getLeadNotesFromStore,
      match: {
        params: { leadId }
      }
    } = this.props
    getLead(leadId)
    getAgents()
    fetchLocations()
    getServiceInterests(leadId)
    getLeadNotesFromStore(leadId, 'REGULAR', 1)

    getSchedules(leadId, { lead_id: leadId, completed: false, pageSize: 5 })
    getSchedules(leadId, { lead_id: leadId, completed: true, pageSize: 5 })
    fetchServiceOptions()
    fetchCarMake()
    fetchCarBodyType()
  }

  handleInputChange = (event: any) => {
    const target = event.target as HTMLInputElement
    const key = target.name
    const value = target.value

    this.setState({
      [key]: value
    })
  }

  handleStatusChange = (event: any) => {
    const {
      updateDetails,
      match: {
        params: { leadId }
      }
    } = this.props
    const target = event.target as HTMLInputElement
    const key = target.name
    const value = target.value

    this.setState({
      [key]: value
    })

    const details = {
      status: value,
      status_reason: ''
    }

    if (value !== 'NOT_INTERESTED') {
      updateDetails(details, leadId)
    } else {
      this.setState({
        reasonFormOpen: true
      })
    }
  }

  handleNameChange = (event: any) => {
    const target = event.target as HTMLInputElement
    const key = target.name
    const value = target.value

    this.setState({
      [key]: value
    })
  }

  updateLead = () => {
    const {
      updateDetails,
      match: {
        params: { leadId }
      }
    } = this.props
    const { firstname, lastname } = this.state

    const details = {
      first_name: firstname,
      last_name: lastname
    }

    updateDetails(details, leadId)
  }

  handleAgentNameClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    const { userIsAgent } = this.props

    let target = event.currentTarget as HTMLInputElement
    if (!userIsAgent) {
      this.setState({
        anchorEl: target
      })
    }
  }

  handleAgentOverlayClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleReasonFormOpen = () => {
    this.setState({
      reasonFormOpen: true
    })
  }

  handleReasonFormClose = () => {
    this.setState({
      reasonFormOpen: false
    })
  }

  handleReasonFormChange = (event: any) => {
    const target = event.target as HTMLInputElement
    const value = target.value

    this.setState({
      statusReason: value
    })
  }

  handleReasonFormSubmit = () => {
    const { statusReason, leadStatus } = this.state
    const {
      updateDetails,
      match: {
        params: { leadId }
      }
    } = this.props

    const details = {
      status: leadStatus,
      status_reason: statusReason
    }

    this.setState({
      statusReason: null,
      reasonFormOpen: false
    })

    updateDetails(details, leadId)
  }

  handleAgentChange = (agentId: string) => {
    const {
      updateDetails,
      match: {
        params: { leadId }
      }
    } = this.props

    const details = {
      assigned_agent_id: agentId
    }

    updateDetails(details, leadId)
    this.handleAgentOverlayClose()
  }

  getLeadSelectColor = (status: string) => {
    return status === 'HOT' || status === 'VERY_HOT'
      ? '#E84A29'
      : status === 'SCHEDULED_INSPECTION'
      ? '#ff8c00'
      : '#9BCDFF'
  }

  onSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    this.setState({
      searchTerm: name
    })

    const { agents } = this.props

    if (name.length > 1) {
      const filteredAgents = agents.filter(
        (agent: any) =>
          `${agent.name}`.toUpperCase().indexOf(name.toUpperCase()) >= 0
      )

      this.setState({
        agents: filteredAgents
      })
    }

    if (name.length <= 1) {
      this.setState({
        agents: this.props.agents
      })
    }
  }

  getAgents = () => {
    let { agents } = this.state
    agents = agents.length > 0 ? agents : this.props.agents

    return agents.map((agent: any) => {
      return (
        <Agent key={agent.id} onClick={() => this.handleAgentChange(agent.id)}>
          <AgentListIcon />
          <AgentListText>{agent.name}</AgentListText>
        </Agent>
      )
    })
  }

  getIsFetching = () => {
    const {
      isFetchingLead,
      isFetchingAgents,
      isFetchingLocations,
      isFetchingInterests,
      isFetchingVehicles
    } = this.props

    if (
      isFetchingLead ||
      isFetchingAgents ||
      isFetchingLocations ||
      isFetchingInterests ||
      isFetchingVehicles
    )
      return true
    return false
  }

  render() {
    const {
      userProfileImg,
      details,
      assignedAgent,
      serviceInterests,
      leadUserId,
      assignedAgentFromStore,
      getAllAgentsAssignedToLead,
      userIsAgent,
      linkUser
    } = this.props

    if (this.getIsFetching())
      return (
        <WithSideMenu>
          <Loader />
        </WithSideMenu>
      )

    const { name, status, firstName, lastName } = details

    const {
      anchorEl,
      searchTerm,
      firstname,
      lastname,
      reasonFormOpen,
      statusReason
    } = this.state
    let fName = firstname === undefined ? firstName : firstname
    let lName = lastname === undefined ? lastName : lastname

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined
    const inputProps = {
      width: '180px',
      height: '25px'
    }

    // const { t } = useTranslation()
    //@ts-ignore
    const { t } = this.props

    return (
      <WithSideMenu>
        <PageContainer>
          <Header>
            <StyledBackLink to="/leads">
              <GoBack>
                <GoBackIcon />
                <GoBackTitle>{t('LEADS')}</GoBackTitle>
              </GoBack>
            </StyledBackLink>
            <HeaderTopRight>
              <QuestionMark />
              <Avatar>
                <img src={userProfileImg} alt="avatar" />
              </Avatar>
            </HeaderTopRight>
          </Header>
          <LeadInfoSection>
            <LeadInfoSectionLeft>
              <LeadInfoNameType>
                <LeadInfoName>{name}</LeadInfoName>
                <Select
                  value={status || ''}
                  name="leadStatus"
                  options={statusOptions}
                  onSelect={this.handleStatusChange}
                  customStyles={{
                    bgColor: this.getLeadSelectColor(status),
                    color: 'white',
                    fontSize: '10px',
                    height: '24px',
                    fontWeight: 800,
                    marginTop: 5
                  }}
                />
                <Dialog
                  open={reasonFormOpen}
                  onClose={this.handleReasonFormClose}
                  aria-labelledby="form-dialog-title"
                >
                  <DialogTitle id="form-dialog-title">
                    {t('Reason')}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      {t('PleaseReasonMarkingNOTINTERESTED')}
                    </DialogContentText>
                    <TextField
                      autoFocus
                      margin="dense"
                      id="name"
                      type="text"
                      fullWidth
                      value={statusReason}
                      onChange={this.handleReasonFormChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={this.handleReasonFormClose}
                      color="secondary"
                      variant="outlined"
                    >
                      {t('Cancel')}
                    </Button>
                    <Button
                      onClick={this.handleReasonFormSubmit}
                      color="secondary"
                      variant="outlined"
                      disabled={!Boolean(statusReason)}
                    >
                      {t('submit')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </LeadInfoNameType>
              <AssignedAgentSection>
                <AgentIcon />
                <AssignedAgent>
                  {t('assignedTo')}:{' '}
                  <AgentName onClick={this.handleAgentNameClick}>
                    {userIsAgent
                      ? assignedAgentFromStore && assignedAgentFromStore.name
                      : getAllAgentsAssignedToLead?.map((agentTolead: any) => {
                          return (
                            agentTolead.name +
                            (getAllAgentsAssignedToLead.length > 1
                              ? ' , '
                              : ' ')
                          )
                        })}
                  </AgentName>
                </AssignedAgent>
                <DownIcon onClick={this.handleAgentNameClick} />
              </AssignedAgentSection>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={this.handleAgentOverlayClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <AgentListOverlay>
                  <SearchInputContainer>
                    <SearchInput>
                      <SearchIcon />
                      <TextInput
                        name="search"
                        type="text"
                        value={searchTerm}
                        placeholder={t('search')}
                        onInputChange={this.onSearchInputChange}
                        {...inputProps}
                      />
                    </SearchInput>
                  </SearchInputContainer>
                  {this.getAgents()}
                </AgentListOverlay>
              </Popover>
            </LeadInfoSectionLeft>
            <LeadInfoSectionRight></LeadInfoSectionRight>
          </LeadInfoSection>

          <StyledGrid container spacing={2} justify="space-between">
            <Grid item xs={5}>
              <Details
                details={details}
                firstName={fName}
                lastName={lName}
                handleChange={this.handleNameChange}
                updateDetails={this.updateLead}
                userId={leadUserId}
                linkUser={linkUser}
                serviceInterests={serviceInterests}
              />
              <ServiceInterest />
            </Grid>
            <Grid item xs={7}>
              <LeadsLocation />
              <Actions />
            </Grid>
          </StyledGrid>
        </PageContainer>
      </WithSideMenu>
    )
  }
}

export default withTranslation()(leadDetails)
