import styled from 'styled-components'

export const StyledLogActivity = styled.div`
  header {
    color: #31355b;
    font-weight: 600;
    font-size: 16px;
  }

  .nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 0rem;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
    color: #b3b3b3;
  }

  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    color: #000000;
    background-color: 0 0;
    font-weight: 500;
    font-size: 14px;
  }
`

export const StyledNewCall = styled.div`
  form {
    label {
      color: #30355b;
      font-size: 14px;
    }
    textarea {
      border-radius: 4px;
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
    }
    input {
      border-radius: 4px;
      background: #ffffff;
      box-sizing: border-box;
      height: 43px;
    }
    .ant-picker {
      width: 100%;
      background: #ffffff;
      border: 1px solid #c7c9d9;
      box-sizing: border-box;
      border-radius: 4px;
      height: 46px;
    }
    .cancel {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffffff;
      border: 1px solid #30355b;
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
    }
    .submit {
      color: #30355b;
      font-weight: 600;
      font-size: 14px;
      background: #ffb619;
      box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.12);
      border-radius: 5px;
      min-width: 170px;
      width: auto;
      height: 40px;
      border: 0;
    }
  }
`
