import React, { FC } from 'react'

type Status = {
  'ppi pending': string
}

interface ITagProps {
  status:
    | 'ppi pending'
    | 'ppi pass'
    | 'ppi scheduled'
    | 'ppi fail'
    | 'document pending'
    | 'document accepted'
    | 'document rejected'
    | 'customer pending'
    | 'customer accepted'
    | 'customer rejected'
}

const STATUSTAG = {
  'ppi pending': 'Pending PPI',
  'ppi pass': 'PPI',
  'ppi scheduled': 'PPI Scheduled',
  'ppi fail': 'PPI',
  'document pending': 'Document Pending',
  'document accepted': 'Documents',
  'document rejected': 'Documents',
  'customer pending': 'Customer Pending',
  'customer accepted': 'Customer',
  'customer rejected': 'Customer'
}

const PPIStatusTag: FC<ITagProps> = ({ status }) => (
  <div
    className={`sourcing__ppi-tag d-flex ${
      status.includes('pending') && '--pending'
    } ${status.includes('scheduled') && '--scheduled'} ${
      status.includes('pass') && '--pass'
    } ${status.includes('accepted') && '--pass'} ${
      status.includes('rejected') && '--rejected'
    } ${status.includes('fail') && '--rejected'}`}
  >
    <div>{STATUSTAG[status]}</div>
    {/* {(status.includes('rejected') || status.includes('fail')) && (
      <img src="/static/images/cancel.svg" className="ml-2" alt="" />
    )}

    {status.includes('accepted') && (
      <img src="/static/images/green_check.svg" className="ml-2" alt="" />
    )}
    {status.includes('pass') && (
      <img src="/static/images/green_check.svg" className="ml-2" alt="" />
    )} */}
  </div>
)

export default PPIStatusTag
