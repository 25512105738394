import React from 'react'
import { Grid } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

interface IProps {
  handlePageChange: (...arg: any[]) => void
  page: number
  total: number
}
export default function PaginationRow(props: IProps) {
  const { handlePageChange, page, total } = props
  const count = Math.round(total / 5)
  if (count < 1) return null
  return (
    <Grid container item xs={12} justify="center" style={{ marginTop: '1rem' }}>
      <Pagination
        count={count}
        page={page}
        variant="outlined"
        color="primary"
        onChange={handlePageChange}
      />
    </Grid>
  )
}
