import React, { useState } from 'react'
import { StyledPartnerMapping } from './styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  getSelectedCountry,
  getSelectedCountryCurrency,
  getUserIdIfAgent,
  getUserToken
} from '../../../../store/auth/selectors'
import WithSideMenu from '../../../../components/WithSideMenu'
import { SettingsContainer } from '../../styles'
import { SettingsNavDummy } from '../styles'
import SettingsMenu from '../../SettingsMenu'
import { Input, Button, Table, Tag, Select, message, Spin } from 'antd'
import {
  SearchIcon,
  PlusMIcon
} from '../../../PortfolioManagement/Dashboard/svgs'
import MapAgentModal from './MapAgentModal'
import { useQuery, useMutation, useQueryClient } from 'react-query'
import { TaskIcon } from '../../../PortfolioManagement/PortfolioDetails/svgs'
import api from '../../../../utils/api'
import EditAgentModal from './EditAgentModal'

export const Loan_List = {
  loanLeads: [
    {
      id: 'dfsfdsfs',
      email: 'ridwan@onikoyi.com',
      firstName: 'Ridwan',
      lastName: 'Onikoyi',
      status: 'Offer Accepted',
      bank: ['GT Bank', 'UBA Bank']
    }
  ],
  pagination: {
    total: 1,
    currentPage: 1,
    pageSize: 10
  }
}

export default function PartnerMapping() {
  const dispatch = useDispatch()
  const country = useSelector(getSelectedCountry)
  const token = useSelector(getUserToken)

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [isEditModalVisible, setIsEditModalVisible] = useState(false)
  const [loanTable, setLoanTable] = useState(Loan_List?.loanLeads)
  const [modalData, setModalData] = useState()
  const [deleting, setDeleting] = useState<boolean>(false)

  const showModal = () => {
    setIsModalVisible(true)
  }
  const queryClient = useQueryClient()

  const {
    data: partnerLists,
    isLoading: partnerListsLoading,
    refetch: refresh
  } = useQuery(['partner-lists', country], () =>
    api.getMappedPortfolioManagers(token, {
      country: country
    })
  )

  const deletePartnerMapping: any = useMutation(
    (__userId) => api.deletePortfolioPartner(token, __userId),
    {
      onSuccess: (res: any) => {
        queryClient.invalidateQueries('delete-partner')
        setDeleting(false)
        message.success('Delete successful')
        refresh()
      },
      onError: (error: any) => {
        setDeleting(false)
        message.error(error?.response.data.error)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  const data = loanTable.map((el: any) => ({
    agent: `${el?.firstName} ${el?.lastName}`,
    email: `${el?.email}`,
    banks: <span>{el.bank}</span>,
    action: <span>N/A</span>
  }))

  const handleDelete = async (record: any) => {
    setDeleting(true)
    await deletePartnerMapping.mutateAsync(record.userId)
  }

  const columns = [
    {
      title: 'Agent',
      dataIndex: 'name'
      // width: 450
    },
    {
      title: 'Email',
      dataIndex: 'email'
      // width: 200
    },
    {
      title: 'Banks',
      dataIndex: 'partnerNames',
      render: (partners: any) => {
        return (
          <div className="d-flex">
            {partners?.map((partnerName: string, i: number) => (
              <div>
                {`${partnerName}${i + 1 < partners?.length ? `,` : ''}`}&nbsp;
              </div>
            ))}
          </div>
        )
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_: any, data: any) => (
        <>
          <Button
            className="editButton me-3"
            onClick={() => {
              setModalData(data)
              setIsEditModalVisible(true)
            }}
          >
            Edit
          </Button>
          <Button className="removeButton" onClick={() => handleDelete(data)}>
            Remove
          </Button>
        </>
      )
    }
  ]

  return (
    <WithSideMenu>
      <SettingsContainer>
        <SettingsNavDummy />
        <SettingsMenu />
        <StyledPartnerMapping>
          <div className="partnerMapping">
            <div className="headerSection d-flex align-items-center">
              <div className="head me-3">Partner Bank Mapping</div>
              <div className="me-3">
                <Input
                  size="large"
                  placeholder="Search user"
                  prefix={SearchIcon}
                  bordered={false}
                />
              </div>
              <div className="mapButton">
                <Button onClick={showModal}>
                  <span className="me-2">{PlusMIcon}</span> Map New Agent
                </Button>
              </div>
            </div>
            <MapAgentModal
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
              handleRefresh={() => refresh()}
            />
            <EditAgentModal
              isModalVisible={isEditModalVisible}
              setIsModalVisible={setIsEditModalVisible}
              handleRefresh={() => refresh()}
              modalData={modalData}
            />

            {/* <div className="noPartner d-flex justify-content-center align-items-center">
              <div className="text-center">
                <div className="mb-2">{TaskIcon}</div>
                <div className="title">Create dummy record</div>
              </div>
            </div> */}

            <div className=" mt-3">
              <Table
                dataSource={partnerLists?.portfolioManagers}
                columns={columns}
                pagination={false}
                loading={partnerListsLoading || deleting}
                rowKey="id"
              />
            </div>
          </div>
        </StyledPartnerMapping>
      </SettingsContainer>
    </WithSideMenu>
  )
}
