import React from 'react'
import ActionPane from '../LeadActions/ActionPane'
import Todo from '../LeadActions/Todo'
import Timeline from '../LeadActions/Timeline';
import NoteTab from '../LeadActions/Timeline/Notes';
import { LeadDetailsSectionTitle } from '../styles'

const Actions = () => {
  return (
    <div style={{ maxWidth: '48.25rem' }}>
      <LeadDetailsSectionTitle>Actions</LeadDetailsSectionTitle>
      <ActionPane />
      <Todo />
      <Timeline />
      <NoteTab />
    </div>
  )
}

export default Actions
