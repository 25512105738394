import React from 'react'
import { StyledPayDealer } from './styles'
import { Form, Input, Button, Checkbox, message } from 'antd'
import { useTranslation } from 'react-i18next'

export default function PayDealer() {
  const { t } = useTranslation()

  const onFinish = (values: any) => {
    // console.log('Success:', values)
    message.warning('Please complete previous stages')
  }

  return (
    <StyledPayDealer>
      <div className="pay-dealer-wrapper">
        <div className="content-wrap">
          <div className="header mb-4">{t('dealerDetails')}</div>
          <div className="d-flex">
            <div className="franchise me-5 pe-3">
              <div className="mb-1">{t('Franchise')}: Mayfair Auto</div>
              <div className="mb-1">08123456789</div>
              <div>mayfair@gmail.com</div>
            </div>
            <div className="kissflow">
              <Form
                layout="vertical"
                onFinish={onFinish}
                requiredMark={false}
                autoComplete="off"
              >
                <div className="d-flex">
                  <Form.Item
                    label={t('kissflowRequestID')}
                    name="username"
                    rules={[
                      { required: true, message: t('pleaseEnterKissflowID') }
                    ]}
                    className="me-4"
                  >
                    <Input placeholder={t('enterKissflowID')} />
                  </Form.Item>

                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      {t('requestPayment')}
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </StyledPayDealer>
  )
}
