import React from 'react'
import { Modal, Button, Form, Select, message, Spin } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { StyledCarAvailable } from './styles'
import { useMutation, useQueryClient } from 'react-query'
import { Upload } from 'antd'
import api from '../../../utils/api'
import { getUserToken, getSelectedCountry } from '../../../store/auth/selectors'
import { useSelector } from 'react-redux'
import { CAR_STATUS } from './constants'
import { useTranslation } from 'react-i18next'

const { Option } = Select

export default function ScheduleModal({
  visible,
  setVisible,
  workshops,
  workshopsLoading,
  loanData,
  loanRefresh
}: any) {
  const [submitting, setSubmitting] = React.useState(false)
  const [form] = Form.useForm()
  const token = useSelector(getUserToken)
  const country = useSelector(getSelectedCountry)
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const schedulePPI: any = useMutation(
    (payload) => api.createWarrantyRequest(token, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('car-sourcing')
      },
      onError: (error: any) => {
        message.error(
          error?.response?.data?.error ||
            'Uh oh, something went wrong, please try again'
        )
      }
    }
  )

  const updateLoan: any = useMutation(
    (payload) => api.updateCarLoan(token, loanData?.id, payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('loan-update')
        message.success('Loan updated')
        loanRefresh()
      },
      onError: (error: any) => {
        message.error(error?.response.data.error)
        // message.error('Uh oh, something went wrong, please try again')
      }
    }
  )

  const handleSchedulePPI = async (_data: any) => {
    setSubmitting(true)
    const schedulePayload = {
      assignee_id: _data.workshop_id,
      year: loanData.car.year || '',
      vin: loanData.car.vin,
      model_id: loanData.car.model.id,
      country: country,
      inspection_type: 'warranty',
      car_id: loanData.car?.id,
      user_id: null,
      // car_offer: loanData?.id,
      source: '',
      note: _data.note,
      loan_id: loanData?.id
      // add source, either franchise or third party, also add franchise id if it is franchise
    }
    if (loanData.car.ownerType === 'individual' && loanData.car.ownerId) {
      schedulePayload.user_id = loanData.car.ownerId
      schedulePayload.source = 'user'
    } else {
      schedulePayload.user_id = loanData.car.carManagerId
      schedulePayload.source = 'franchise'
    }

    try {
      const scheduleResponse = await schedulePPI.mutateAsync(schedulePayload)
      if (scheduleResponse?.data?.error) {
        message.error(scheduleResponse?.data?.error)
        setSubmitting(false)
      } else {
        setSubmitting(false)
        form.resetFields()
        message.success('PPI Schedule Successful')
        setVisible(false)
        handleUpdate()
      }
    } catch (error: any) {
      setSubmitting(false)
    }
  }

  const handleUpdate = async () => {
    const payload = {
      status: CAR_STATUS.PPI_IN_PROGRESS
    }
    await updateLoan.mutateAsync(payload)
  }

  return (
    <Modal closable={false} visible={visible} width="540px" footer={null}>
      <StyledCarAvailable>
        <div className="modalWrapper">
          <div className="title mb-2">{t('schedulePPI')}</div>
          <div className="text mb-3">
            {t('kindlyUploadCarCustomDocumentSchedule')} <br /> {t('forPPI')}
          </div>

          <Form
            layout="vertical"
            requiredMark={false}
            onFinish={handleSchedulePPI}
          >
            <Form.Item label={t('selectWorkshop')}>
              <Form.Item
                name="workshop_id"
                valuePropName="fileList"
                noStyle
                rules={[
                  {
                    required: true,
                    message: t('selectAWorkshop')
                  }
                ]}
              >
                <Select>
                  {workshops?.map((workshop: any, index: any) => (
                    <Option key={index} value={workshop.id}>
                      {`${workshop.firstname || ''} ${
                        workshop.lastname || ''
                      } (${workshop.workshopName || ''})`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form.Item>
            <Form.Item name="note">
              <label>{t('Note (Optional)')}</label>
              <TextArea name="Note" placeholder={t('Note')} />
            </Form.Item>
            <div className="d-flex justify-content-center mt-4">
              {submitting ? (
                <Spin />
              ) : (
                <>
                  <Button
                    className="cancel me-3"
                    type="primary"
                    onClick={() => {
                      setVisible(false)
                    }}
                  >
                    {t('Cancel')}
                  </Button>
                  <Button className="saveButton" htmlType="submit">
                    {t('submit')}
                  </Button>
                </>
              )}
            </div>
          </Form>
        </div>
      </StyledCarAvailable>
    </Modal>
  )
}
