import { connect } from 'react-redux'
import Snackbar from './Snackbar'
import {
  getMessage,
  getSeverity,
  getShow
} from '../../store/snackbar/selectors'
import { hideSnackbar } from '../../store/snackbar/actions'
import { StateProps, DispatchProps } from './types'

const mapStateToProps = (state: Object) => ({
  message: getMessage(state),
  show: getShow(state),
  severity: getSeverity(state)
})

const mapDispatchToProps = (dispatch: any) => ({
  hideSnackbar: () => dispatch(hideSnackbar())
})

export default connect<StateProps, DispatchProps>(
  mapStateToProps,
  mapDispatchToProps
)(Snackbar)
